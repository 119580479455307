import { Component} from '@angular/core';
import {getEnv, getEnvFsmone} from '@share/services/product-search.service';
import {RouteConstant} from "@constant";

@Component({
	selector : 'price-alert-button',
	templateUrl: './price-alert-button.component.html',
	styleUrls : []
})

export class PriceAlertButtonComponent{
	
	priceAlertLink: string = '';
	
	constructor(){
		
	}

	goToPriceAlert() {
		let link = getEnvFsmone() +  '/' + RouteConstant.WATCHLIST + '/' + RouteConstant.ALERTS;
		window.open(link, '_blank', 'noreferrer');
	}
}