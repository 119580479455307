import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ChangeDetectionStrategy,
	SimpleChanges,
	OnChanges,
	AfterViewChecked,
	ElementRef,
	ViewChild,
	OnDestroy
} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {GlobalState} from "src/app/core/store";
import {Observable, Subscription} from 'rxjs';
import {AccountOpeningConstant} from 'src/app/constant';
import {SupportRestService} from 'src/app/api/rest/support.rest.service';
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import {ChatBotMsgModel,ChatReplyModel} from '@api-module/model/chat-bot/chat-bot-message.model'
import { IResponse } from "@api-module/model/common/i-response";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { DomSanitizer} from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthState } from '@auth-module/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'live-chat-modal',
  styleUrls: ['./live-chat-modal.component.scss'],
  templateUrl: './live-chat-modal.component.html',
})
export class LiveChatModalComponent implements OnInit, OnChanges, AfterViewChecked {
	
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  @Input() visibility: boolean;
  @Output() visibilityEvent = new EventEmitter<boolean>();
  @Output() closeModel = new EventEmitter<boolean>();
  isLoggedIn: boolean = false;
  isPersonalAcctCreated: boolean = false;
  isOtpRegistered: boolean = false;
  accountType: string = AccountOpeningConstant.PERSONAL;
  liveChatOn:boolean = false;
  liveChatUrl:string = "";
  chatList: ChatBotMsgModel[]=[];
  latestOption: any[]=[];
  typing:boolean = true;
  msgForm: FormGroup;
  feedbackForm: FormGroup;
  additionalFeedbackId:number;
  needMoreFeedback:boolean =false;

	AWS = 'https://shrewdchat-backend-dev.ap-southeast-1.elasticbeanstalk.com';
	AWS_UAT = 'https://shrewdchat-backend-dev.ap-southeast-1.elasticbeanstalk.com';
	AWS_LIVE = 'https://chat.fundsupermart.com';
	
	locale: string = 'ch';
  @ViewChild('scrollHere') private myScrollContainer: ElementRef;

  constructor(
  	private supportRestService: SupportRestService,
	private fb: FormBuilder,
	private globalDataStorage:GlobalDataStorage,
	private sanitizer: DomSanitizer, 
	private http : HttpClient,
	private store: Store
  ) { }

  ngAfterViewChecked() {        
  	//this.scrollToBottom();        
  } 

  scrollToBottom(): void {
  try {
  	this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  } catch(err) { }
  }

  ngOnInit(): void {
	this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
	this.LiveChat();
	this.buildForm();
  }

  buildForm() 
  {
    this.msgForm = this.fb.group
    ({
      content: [null, Validators.required]
    });

	this.feedbackForm = this.fb.group({
		feedback: [null, Validators.required]
	});
  }

  ngOnChanges(changes: SimpleChanges): void {
	if (!this.activePanel && this.visibility) {
		this.activePanel = true;
	}
  }

  handleClose() {
    this.closeModel.emit(false);
  }

  validateTwoFaAccount(): void {
	
  }

  LiveChat()
  {
		this.supportRestService.isBusinessHour().subscribe((response:IResponse<Boolean>)=>{
			if(response.data) {
				
				/*if(environment.dev) {
					this.AWS = this.AWS_UAT;
				} else {
					this.AWS = this.AWS_LIVE;
				}*/
//				if(window.location.href.indexOf('hktrial')>-1 || window.location.href.indexOf('hktest')>-1 || window.location.href.indexOf('localhost')>-1){
//					this.liveChatOn = false;
//					return;
//				}
				this.liveChatUrl = this.AWS_LIVE + '/chatV2/?channelId=7&appId=fsm&authAppId=fsm&funnel=fsm2.0&navBar=Y&isMobile=N&locale='+this.locale+'&country=hk';
				console.log(this.liveChatUrl);
				this.http.get(this.AWS_LIVE+'/webhook/check-available-moderator/7',{}).subscribe((response:any)=>{
					let res = response;
					if(res.s ==='f'){
						//no moderator
					} else {
						this.liveChatOn = true;
					}
				})
			}
		})
	
	/*this.supportRestService.getLiveChatService().subscribe((response: any) =>
	{
		if (AppConstant.RESPONSE_SUCCESS == response.status) {
			if(response.data.open=="Y"){
				this.liveChatOn = true;
				this.liveChatUrl = response.data.url;
			}
        }
	})*/
	
	if(this.liveChatOn==false){
		this.supportRestService.initiateChatBotConversation().subscribe((response: IResponse<ChatBotMsgModel>) =>
		{
			if (AppConstant.RESPONSE_SUCCESS == response.status)
			{
				response.data.isBot = true;
				this.chatList.push(response.data);
				if(response.data.options!=null){
					this.latestOption = response.data.options;
				}
        	}
		 	this.typing = false;
		})
	}
  }

  talkToChatBot(paramtext,payload,category,shortQ,topQ){
	this.typing = true;
	
	this.chatList.push({
		text: paramtext,
		messageId:null,
		finalNode: null,
		options:null,
		buttons:null,
		isBot:false
	});
	
	this.supportRestService.talkToChatBot({
	text:payload,
	category: category,
	shortQuestion: shortQ,
	topQuestion: topQ,
	platform:"fsmhk",
	localeId:this.globalDataStorage.getStorage('locale')
	}).subscribe((response: IResponse<ChatBotMsgModel>) =>
	{
		if (AppConstant.RESPONSE_SUCCESS == response.status)
		{
			response.data.isBot = true;
			this.chatList.push(response.data);
			if(response.data.options!=null){
				this.latestOption = response.data.options;
			}
        }
this.scrollToBottom();
		this.typing = false;
	})
  }

  sendFeedback(msgId,respond,position){
	this.typing = true;
	if(respond == 'Y'){
		this.chatList[position].finalNode = 'feedback_Y';	
	}else{
		this.chatList[position].finalNode = 'feedback_N';
	}
	
	this.supportRestService.sendFeedback({
		messageId:msgId,
		userFeedback:respond
	}).subscribe((response: IResponse<any>) =>
	{
		if (AppConstant.RESPONSE_SUCCESS == response.status)
		{
			console.log("success submit");
        }
		this.typing = false;
	})
	this.scrollToBottom();
  }

  submitForm(event){
 	if (this.msgForm.valid) {
		let content = this.msgForm.get('content')?.value;
		this.talkToChatBot(content,content,null,null,null);
	}
	this.msgForm.reset();
	this.scrollToBottom();
  }

  submitAdditionalFeedback(id,position){
	this.needMoreFeedback = true;
	this.additionalFeedbackId = id;
	this.chatList[position].finalNode = 'feedback_N_submitted';
	this.scrollToBottom();
  }

  submitAdditionalFeedbackForm(){
	if (this.feedbackForm.valid) {
		let feedback = this.feedbackForm.get('feedback')?.value;
		
		this.supportRestService.sendFeedback({
		messageId:this.additionalFeedbackId,
		userFeedback:feedback
		},true).subscribe((response: IResponse<any>) =>
		{
			if (AppConstant.RESPONSE_SUCCESS == response.status)
			{
				console.log("success submit additional feedback");
	        }
			this.typing = false;
		})
		this.additionalFeedbackId = null;
		this.needMoreFeedback = false;
		this.feedbackForm.reset();
		this.msgForm.reset();
		this.scrollToBottom();
	}
  }
	activePanel: boolean  = true;
	
	changeActive(event) {
		this.activePanel = false;
		this.visibilityEvent.emit(false);
	}
	
	closeModal(event) {
		this.closeModel.emit(true);
	}
}
