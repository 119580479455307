<!--  <nz-tabset [nzAnimated]="false"> -->
<!-- 	<nz-tab nzTitle="{{'fsmone.foreign.fx.live.title' | translate}}" > -->
		<h4 class="mb-1" translate="fsmone.foreign.fx.intro.content.1"></h4>
		<h5 class="text-black-7 mb-2" translate="fsmone.foreign.fx.link.1.2"></h5>
		<h5 translate="fsmone.foreign.fx.disclaimer"></h5>
		<small class="text-right d-block mb-2">{{'fsmone.foreign.fx.live.last.updated' | translate}}: {{lastUpdatedTime | hkDate:localeOption:'medium' }}</small>
		<nz-spin [nzSpinning]="isLoading">
			<nz-table [nzData]="['']" [nzShowPagination]="false">
				<thead>
					<tr>
						<th class="w-30"><span translate="fsmone.foreign.fx.table.column.currency" translate-default="Currency"></span></th>
						<th class="w-20"><span translate="fsmone.foreign.fx.table.column.unit" translate-default="Unit"></span></th>
						<th class="w-20"><span translate="fsmone.foreign.fx.table.column.bid" translate-default="Offer (Investor Buys)"></span></th>
						<th class="w-20"><span translate="fsmone.foreign.fx.table.column.offer" translate-default="Bid (Investor Sells)"></span></th>
						<th class="w-10 text-center"><span translate="fsmone.bond.home.button.trade.now" translate-default="Trade Now"></span></th>
					</tr>
				</thead>
				<tbody  *ngFor="let currency of currency_list">
					<td class="border-bottom" [attr.rowspan] = "currency_list.length+1">{{ currency | lowercase | translate}}</td>
					<tr *ngFor="let inner_currency of currency_list">
						<td *ngIf= "inner_currency!=currency">
							1 {{ currency | lowercase | translate}} = {{ inner_currency | lowercase | translate}}
						</td>
						<td *ngIf= "inner_currency!=currency ">
							<ng-container *ngIf="liveFxRateMap[currency] && liveFxRateMap[currency][inner_currency].rate">{{1 / liveFxRateMap[currency][inner_currency].rate  | number: '1.6-6'}}</ng-container>
							<ng-container *ngIf="!liveFxRateMap[currency] || !liveFxRateMap[currency][inner_currency].rate">-</ng-container>
						</td>
						<td *ngIf= "inner_currency!=currency">
							<ng-container *ngIf="liveFxRateMap[currency] && liveFxRateMap[inner_currency][currency].rate">{{liveFxRateMap[inner_currency][currency].rate | number: '1.6-6'}}</ng-container>
							<ng-container *ngIf="!liveFxRateMap[currency] || !liveFxRateMap[inner_currency][currency].rate">-</ng-container>
						</td>
						<td *ngIf= "inner_currency!=currency" class="text-center">
							<button nz-button nzType="success" [nzSize]="'small'" (click)="navigateToFx()"><img width="20" [src]="BUTTON_ICON_IMAGES_PATH + 'buy-icon.svg'" alt="Trade"/></button>
						</td>
					</tr>
				</tbody>
			</nz-table>   
		</nz-spin>
<!-- 	</nz-tab> -->
<!-- 	<nz-tab nzTitle="{{'fsmone.foreign.fx.other.title' | translate}}"> -->
<!-- 		<h4 class="mb-1" translate="fsmone.foreign.fx.other.title"></h4> -->
<!-- 		<h5 translate="fsmone.foreign.fx.disclaimer"></h5> -->
<!-- 		<nz-spin [nzSpinning]="isLoading"> -->
<!-- 			<nz-table [nzData]="['']" [nzShowPagination]="false" class="mt-4"> -->
<!-- 				<thead> -->
<!-- 					<tr> -->
<!-- 						<th class="w-30"><span translate="fsmone.foreign.fx.table.column.currency" translate-default="Currency"></span></th> -->
<!-- 						<th class="w-15"><span translate="fsmone.foreign.fx.table.column.unit" translate-default="Unit"></span></th> -->
<!-- 						<th class="w-20"><span translate="fsmone.foreign.fx.table.column.bid" translate-default="Offer (Investor Buys)"></span></th> -->
<!-- 						<th class="w-20"><span translate="fsmone.foreign.fx.table.column.offer" translate-default="Bid (Investor Sells)"></span></th> -->
<!-- 						<th class="w-15"><span translate="fsmone.foreign.fx.table.column.date" translate-default="Transacted Date"></span></th> -->
<!-- 					</tr> -->
<!-- 				</thead> -->
<!-- 				<tbody  *ngFor="let currency of currency_list"> -->
<!-- 					<td class="border-bottom" [attr.rowspan] = "currency_list.length+1">{{ currency | lowercase | translate}}</td> -->
<!-- 					<tr *ngFor="let inner_currency of currency_list"> -->
<!-- 						<td *ngIf= "inner_currency!=currency"> -->
<!-- 							1 {{ currency | lowercase | translate}} = {{ inner_currency | lowercase | translate}} -->
<!-- 						</td> -->
<!-- 						<td *ngIf="inner_currency!=currency"> -->
<!-- 							<ng-container *ngIf= "nonLiveFxRateMap[inner_currency][currency].rate" class="font-weight-bold mt-2">{{nonLiveFxRateMap[inner_currency][currency].rate  | number: '1.6-6'}}</ng-container> -->
<!-- 							<ng-container *ngIf= "!nonLiveFxRateMap[inner_currency][currency].rate" class="font-weight-bold mt-2">-</ng-container> -->
<!-- 						</td> -->
<!-- 						<td *ngIf="inner_currency!=currency"> -->
<!-- 							<ng-container *ngIf= "nonLiveFxRateMap[currency][inner_currency].rate">{{1 / nonLiveFxRateMap[currency][inner_currency].rate  | number: '1.6-6'}}</ng-container> -->
<!-- 							<ng-container *ngIf= "!nonLiveFxRateMap[currency][inner_currency].rate">-</ng-container> -->
<!-- 						</td> -->
<!-- 						<td *ngIf="inner_currency!=currency"> -->
<!-- 							<ng-container *ngIf= "nonLiveFxRateMap[currency][inner_currency].currencyRatesPk && nonLiveFxRateMap[currency][inner_currency].currencyRatesPk.rateDate">{{nonLiveFxRateMap[currency][inner_currency].currencyRatesPk.rateDate | hkDate:localeOption }}</ng-container> -->
<!-- 							<ng-container *ngIf= "!nonLiveFxRateMap[currency][inner_currency].currencyRatesPk || !nonLiveFxRateMap[currency][inner_currency].currencyRatesPk.rateDate">-</ng-container> -->
<!-- 						</td> -->
<!-- 					</tr> -->
<!-- 				</tbody> -->
<!-- 			</nz-table> -->
<!-- 		</nz-spin> -->
<!-- 	</nz-tab> -->
<!-- </nz-tabset> -->
      
<ng-template #rangeTemplate let-range="range" let-total> <span translate="show" translate-default="show"></span> {{ range[0] }}-{{ range[1] }} , <span translate="fsmone.fsm.smart.total" translate-default="Total"></span> {{ total }} <span translate="fsmone.results" translate-default="Results"></span></ng-template>
  
<ng-template #prefixIconSearch>
	<i nz-icon nzType="search"></i>
</ng-template>
  
<ng-template #suffixIconClose>
	<i nz-icon nzType="close" nzTheme="outline" ></i>
</ng-template>
  