export class StockScreenerConstant {
  // General
  public static readonly RESPONSE_SUCCESS: string = 'SUCCESS';
  public static readonly RESPONSE_FAILED: string = 'FAILED';
  public static readonly YES_FLAG: string = 'Y';
  public static readonly YES_DISPLAY: string = 'Yes';
  public static readonly NO_FLAG: string = 'N';
  public static readonly NO_DISPLAY: string = 'No';
  public static readonly DATE_FORMAT: string = 'dd MMM yyyy';
  public static readonly LOCALE_EN: string = 'en';
  public static readonly PERCENT: string = '%';
  public static readonly MY: string = 'MY';
  public static readonly MYFSM: string = "MYFSM";
  public static readonly LANGUAGE_CODE_EN_US: string = "en_us";
  public static readonly LIVE_URL: string = 'https://www.fundsupermart.com.my/main'
  public static readonly MALAYSIA: string = "Malaysia";

  public static readonly AGRICULTURAL_INPUTS_DISPLAY = "agricultural.inputs";
  public static readonly AGRICULTURAL_INPUTS_KEY = "10101001";
  public static readonly BUILDING_MATERIALS_DISPLAY = "building.materials";
  public static readonly BUILDING_MATERIALS_KEY = "10102002";
  public static readonly CHEMICALS_DISPLAY = "chemicals";
  public static readonly CHEMICALS_KEY = "10103003";
  public static readonly SPECIALTY_CHEMICALS_DISPLAY = "specialty.chemicals";
  public static readonly SPECIALTY_CHEMICALS_KEY = "10103004";
  public static readonly COAL_DISPLAY = "coal";
  public static readonly COAL_KEY = "10104005";
  public static readonly LUMBER_AND_WOOD_PRODUCTION_DISPLAY = "lumber.and.wood.production";
  public static readonly LUMBER_AND_WOOD_PRODUCTION_KEY = "10105006";
  public static readonly PAPER_AND_PAPER_PRODUCTS_DISPLAY = "paper.and.paper.products";
  public static readonly PAPER_AND_PAPER_PRODUCTS_KEY = "10105007";
  public static readonly ALUMINIUM_DISPLAY = "aluminium";
  public static readonly ALUMINIUM_KEY = "10106008";
  public static readonly COPPER_DISPLAY = "copper";
  public static readonly COPPER_KEY = "10106009";
  public static readonly GOLD_DISPLAY = "gold";
  public static readonly GOLD_KEY = "10106010";
  public static readonly INDUSTRIAL_METALS_AND_MINERALS_DISPLAY = "industrial.metals.and.minerals";
  public static readonly INDUSTRIAL_METALS_AND_MINERALS_KEY = "10106011";
  public static readonly SILVER_DISPLAY = "silver";
  public static readonly SILVER_KEY = "10106012";
  public static readonly STEEL_DISPLAY = "steel";
  public static readonly STEEL_KEY = "10107013";
  public static readonly ADVERTISING_AGENCIES_DISPLAY = "advertising.agencies";
  public static readonly ADVERTISING_AGENCIES_KEY = "10208014";
  public static readonly MARKETING_SERVICES_DISPLAY = "marketing.services";
  public static readonly MARKETING_SERVICES_KEY = "10208015";
  public static readonly AUTO_AND_TRUCK_DEALERSHIPS_DISPLAY = "auto.and.truck.dealerships";
  public static readonly AUTO_AND_TRUCK_DEALERSHIPS_KEY = "10209016";
  public static readonly AUTO_MANUFACTURERS_DISPLAY = "auto.manufacturers";
  public static readonly AUTO_MANUFACTURERS_KEY = "10209017";
  public static readonly AUTO_PARTS_DISPLAY = "auto.parts";
  public static readonly AUTO_PARTS_KEY = "10209018";
  public static readonly RECREATIONAL_VEHICLES_DISPLAY = "recreational.vehicles";
  public static readonly RECREATIONAL_VEHICLES_KEY = "10209019";
  public static readonly RUBBER_AND_PLASTICS_DISPLAY = "rubber.and.plastics";
  public static readonly RUBBER_AND_PLASTICS_KEY = "10209020";
  public static readonly BROADCASTING_RADIO_DISPLAY = "broadcasting.radio";
  public static readonly BROADCASTING_RADIO_KEY = "10210021";
  public static readonly BROADCASTING_TV_DISPLAY = "broadcasting.tv";
  public static readonly BROADCASTING_TV_KEY = "10210022";
  public static readonly MEDIA_DIVERSIFIED_DISPLAY = "media.diversified";
  public static readonly MEDIA_DIVERSIFIED_KEY = "10210023";
  public static readonly RESIDENTIAL_CONSTRUCTION_DISPLAY = "residential.construction";
  public static readonly RESIDENTIAL_CONSTRUCTION_KEY = "10211024";
  public static readonly TEXTILE_MANUFACTURING_DISPLAY = "textile.manufacturing";
  public static readonly TEXTILE_MANUFACTURING_KEY = "10211025";
  public static readonly APPAREL_MANUFACTURING_DISPLAY = "apparel.manufacturing";
  public static readonly APPAREL_MANUFACTURING_KEY = "10212026";
  public static readonly FOOTWEAR_AND_ACCESSORIES_DISPLAY = "footwear.and.accessories";
  public static readonly FOOTWEAR_AND_ACCESSORIES_KEY = "10212027";
  public static readonly HOME_FURNISHINGS_AND_FIXTURES_DISPLAY = "home.furnishings.and.fixtures";
  public static readonly HOME_FURNISHINGS_AND_FIXTURES_KEY = "10212028";
  public static readonly PACKAGING_AND_CONTAINERS_DISPLAY = "packaging.and.containers";
  public static readonly PACKAGING_AND_CONTAINERS_KEY = "10213029";
  public static readonly PERSONAL_SERVICES_DISPLAY = "personal.services";
  public static readonly PERSONAL_SERVICES_KEY = "10214030";
  public static readonly PUBLISHING_DISPLAY = "publishing";
  public static readonly PUBLISHING_KEY = "10215031";
  public static readonly RESTRAURANTS_DISPLAY = "restraurant";
  public static readonly RESTRAURANTS_KEY = "10216032";
  public static readonly APPAREL_STORES_DISPLAY = "apparel.stores";
  public static readonly APPAREL_STORES_KEY = "10217033";
  public static readonly DEPARTMENT_STORES_DISPLAY = "department.stores";
  public static readonly DEPARTMENT_STORES_KEY = "10217034";
  public static readonly HOME_IMPROVEMENT_STORES_DISPLAY = "home.improvement.stores";
  public static readonly HOME_IMPROVEMENT_STORES_KEY = "10217035";
  public static readonly LUXURY_GOODS_DISPLAY = "luxury.goods";
  public static readonly LUXURY_GOODS_KEY = "10217036";
  public static readonly SPECIALTY_RETAIL_DISPLAY = "specialty.retail";
  public static readonly SPECIALTY_RETAIL_KEY = "10217037";
  public static readonly GAMBLING_DISPLAY = "gambling";
  public static readonly GAMBLING_KEY = "10218038";
  public static readonly LEISURE_DISPLAY = "leisure";
  public static readonly LEISURE_KEY = "10218039";
  public static readonly LODGING_DISPLAY = "lodging";
  public static readonly LODGING_KEY = "10218040";
  public static readonly RESORTS_AND_CASINOS_DISPLAY = "resorts.and.casinos";
  public static readonly RESORTS_AND_CASINOS_KEY = "10218041";
  public static readonly ASSET_MANAGEMENT_DISPLAY = "asset.management";
  public static readonly ASSET_MANAGEMENT_KEY = "10319042";
  public static readonly BANKS_GLOBAL_DISPLAY = "banks.global";
  public static readonly BANKS_GLOBAL_KEY = "10320043";
  public static readonly BANKS_REGIONAL_AFRICA_DISPLAY = "banks.regional.africa";
  public static readonly BANKS_REGIONAL_AFRICA_KEY = "10320044";
  public static readonly BANKS_REGIONAL_ASIA_DISPLAY = "banks.regional.asia";
  public static readonly BANKS_REGIONAL_ASIA_KEY = "10320045";
  public static readonly BANKS_REGIONAL_AUSTRALIA_DISPLAY = "banks.regional.australia";
  public static readonly BANKS_REGIONAL_AUSTRALIA_KEY = "10320046";
  public static readonly BANKS_REGIONAL_CANADA_DISPLAY = "banks.regional.canada";
  public static readonly BANKS_REGIONAL_CANADA_KEY = "10320047";
  public static readonly BANKS_REGIONAL_EUROPE_DISPLAY = "banks.regional.europe";
  public static readonly BANKS_REGIONAL_EUROPE_KEY = "10320048";
  public static readonly BANKS_REGIONAL_LATIN_AMERICA_DISPLAY = "banks.regional.latin.america";
  public static readonly BANKS_REGIONAL_LATIN_AMERICA_KEY = "10320049";
  public static readonly BANKS_REGIONAL_US_DISPLAY = "banks.regional.us";
  public static readonly BANKS_REGIONAL_US_KEY = "10320050";
  public static readonly SAVINGS_AND_COOPERATIVE_BANKS_DISPLAY = "savings.and.cooperative.banks";
  public static readonly SAVINGS_AND_COOPERATIVE_BANKS_KEY = "10320051";
  public static readonly SPECIALTY_FINANCE_DISPLAY = "specialty.finance";
  public static readonly SPECIALTY_FINANCE_KEY = "10320052";
  public static readonly CAPITAL_MARKETS_DISPLAY = "capital.markets";
  public static readonly CAPITAL_MARKETS_KEY = "10321053";
  public static readonly FINANCIAL_EXCHANGES_DISPLAY = "financial.exchanges";
  public static readonly FINANCIAL_EXCHANGES_KEY = "10321054";
  public static readonly INSURANCE_BROKERS_DISPLAY = "insurance.brokers";
  public static readonly INSURANCE_BROKERS_KEY = "10321055";
  public static readonly CREDIT_SERVICES_DISPLAY = "credit.services";
  public static readonly CREDIT_SERVICES_KEY = "10322056";
  public static readonly INSURANCE_DIVERSIFIED_DISPLAY = "insurance.diversified";
  public static readonly INSURANCE_DIVERSIFIED_KEY = "10323057";
  public static readonly INSURANCE_LIFE_DISPLAY = "insurance.life";
  public static readonly INSURANCE_LIFE_KEY = "10324058";
  public static readonly INSURANCE_PROPERTY_AND_CASUALTY_DISPLAY = "insurance.property.and.casualty";
  public static readonly INSURANCE_PROPERTY_AND_CASUALTY_KEY = "10325059";
  public static readonly INSURANCE_REINSURANCE_DISPLAY = "insurance.reinsurance";
  public static readonly INSURANCE_REINSURANCE_KEY = "10326060";
  public static readonly INSURANCE_SPECIALTY_DISPLAY = "insurance.specialty";
  public static readonly INSURANCE_SPECIALTY_KEY = "10326061";
  public static readonly REAL_ESTATE_GENERAL_DISPLAY = "real.estate.general";
  public static readonly REAL_ESTATE_GENERAL_KEY = "10427062";
  public static readonly REAL_ESTATE_SERVICES_DISPLAY = "real.estate.services";
  public static readonly REAL_ESTATE_SERVICES_KEY = "10427063";
  public static readonly REIT_DIVERSIFIED_DISPLAY = "reit.diversified";
  public static readonly REIT_DIVERSIFIED_KEY = "10428064";
  public static readonly REIT_HEALTHCARE_FACILITIES_DISPLAY = "reit.healthcare.facilities";
  public static readonly REIT_HEALTHCARE_FACILITIES_KEY = "10428065";
  public static readonly REIT_HOTEL_AND_MOTEL_DISPLAY = "reit.hotel.and.motel";
  public static readonly REIT_HOTEL_AND_MOTEL_KEY = "10428066";
  public static readonly REIT_INDUSTRIAL_DISPLAY = "reit.industrial";
  public static readonly REIT_INDUSTRIAL_KEY = "10428067";
  public static readonly REIT_OFFICE_DISPLAY = "reit.office";
  public static readonly REIT_OFFICE_KEY = "10428068";
  public static readonly REIT_RESIDENTIAL_DISPLAY = "reit.residential";
  public static readonly REIT_RESIDENTIAL_KEY = "10428069";
  public static readonly REIT_RETAIL_DISPLAY = "reit.retail";
  public static readonly REIT_RETAIL_KEY = "10428070";
  public static readonly BEVERAGES_BREWERS_DISPLAY = "beverages.brewers";
  public static readonly BEVERAGES_BREWERS_KEY = "20529071";
  public static readonly BEVERAGES_WINERIES_AND_DISTILLERIES_DISPLAY = "beverages.wineries.and.distilleries";
  public static readonly BEVERAGES_WINERIES_AND_DISTILLERIES_KEY = "20529072";
  public static readonly BEVERAGES_SOFT_DRINKS_DISPLAY = "beverages.soft.drinks";
  public static readonly BEVERAGES_SOFT_DRINKS_KEY = "20530073";
  public static readonly CONFECTIONERS_DISPLAY = "confectioners";
  public static readonly CONFECTIONERS_KEY = "20531074";
  public static readonly FARM_PRODUCTS_DISPLAY = "farm.products";
  public static readonly FARM_PRODUCTS_KEY = "20531075";
  public static readonly HOUSEHOLD_AND_PERSONAL_PRODUCTS_DISPLAY = "household.and.personal.products";
  public static readonly HOUSEHOLD_AND_PERSONAL_PRODUCTS_KEY = "20531076";
  public static readonly PACKAGED_FOODS_DISPLAY = "packaged.food";
  public static readonly PACKAGED_FOODS_KEY = "20531077";
  public static readonly EDUCATION_AND_TRAINING_SERVICES_DISPLAY = "education.and.training.services";
  public static readonly EDUCATION_AND_TRAINING_SERVICES_KEY = "20532078";
  public static readonly DISCOUNT_STORES_DISPLAY = "discount.stores";
  public static readonly DISCOUNT_STORES_KEY = "20533079";
  public static readonly PHARMACEUTICAL_RETAILERS_DISPLAY = "phamaceutical.retailers";
  public static readonly PHARMACEUTICAL_RETAILERS_KEY = "20533080";
  public static readonly FOOD_DISTRIBUTION_DISPLAY = "food.distribution";
  public static readonly FOOD_DISTRIBUTION_KEY = "20533081";
  public static readonly GROCERY_STORES_DISPLAY = "grocery.stores";
  public static readonly GROCERY_STORES_KEY = "20533082";
  public static readonly TOBACCO_DISPLAY = "tobacco";
  public static readonly TOBACCO_KEY = "20534083";
  public static readonly BIOTECHNOLOGY_DISPLAY = "biotechnology";
  public static readonly BIOTECHNOLOGY_KEY = "20635084";
  public static readonly DRUG_MANUFACTURERS_MAJOR_DISPLAY = "drug.manufacturers.major";
  public static readonly DRUG_MANUFACTURERS_MAJOR_KEY = "20636085";
  public static readonly DRUG_MANUFACTURERS_SPECIALTY_AND_GENERIC_DISPLAY = "drug.manufacturers.specialty.and.generic";
  public static readonly DRUG_MANUFACTURERS_SPECIALTY_AND_GENERIC_KEY = "20636086";
  public static readonly HEALTH_CARE_PLANS_DISPLAY = "health.care.plans";
  public static readonly HEALTH_CARE_PLANS_KEY = "20637087";
  public static readonly LONG_TERM_CARE_FACILITIES_DISPLAY = "long.term.care.facilities";
  public static readonly LONG_TERM_CARE_FACILITIES_KEY = "20638088";
  public static readonly MEDICAL_CARE_DISPLAY = "medical.care";
  public static readonly MEDICAL_CARE_KEY = "20638089";
  public static readonly MEDICAL_DEVICES_DISPLAY = "medical.devices";
  public static readonly MEDICAL_DEVICES_KEY = "20639090";
  public static readonly DIAGNOSTICS_AND_RESEARCH_DISPLAY = "diagnostics.and.research";
  public static readonly DIAGNOSTICS_AND_RESEARCH_KEY = "20640091";
  public static readonly MEDICAL_DISTRIBUTION_DISPLAY = "medical.distribution";
  public static readonly MEDICAL_DISTRIBUTION_KEY = "20641092";
  public static readonly MEDICAL_INSTRUMENTS_AND_SUPPLIES_DISPLAY = "medical.instruments.and.supplies";
  public static readonly MEDICAL_INSTRUMENTS_AND_SUPPLIES_KEY = "20642093";
  public static readonly UTILITIES_INDEPENDENT_POWER_PRODUCERS_DISPLAY = "utilities.independent.power.producers";
  public static readonly UTILITIES_INDEPENDENT_POWER_PRODUCERS_KEY = "20743094";
  public static readonly UTILITIES_DIVERSIFIED_DISPLAY = "utilities.diversified";
  public static readonly UTILITIES_DIVERSIFIED_KEY = "20744095";
  public static readonly UTILITIES_REGULATED_ELECTRIC_DISPLAY = "utilities.regulated.electric";
  public static readonly UTILITIES_REGULATED_ELECTRIC_KEY = "20744096";
  public static readonly UTILITIES_REGULATED_GAS_DISPLAY = "utilities.regulated.gas";
  public static readonly UTILITIES_REGULATED_GAS_KEY = "20744097";
  public static readonly UTILITIES_REGULATED_WATER_DISPLAY = "utilities.regulated.water";
  public static readonly UTILITIES_REGULATED_WATER_KEY = "20744098";
  public static readonly PAY_TV_DISPLAY = "pay.tv";
  public static readonly PAY_TV_KEY = "30845099";
  public static readonly TELECOM_SERVICES_DISPLAY = "telecom.services";
  public static readonly TELECOM_SERVICES_KEY = "30845100";
  public static readonly OIL_AND_GAS_DRILLING_DISPLAY = "oil.and.gas.drilling";
  public static readonly OIL_AND_GAS_DRILLING_KEY = "30946101";
  public static readonly OIL_AND_GAS_E_AND_P_DISPLAY = "oil.and.gas.e.and.p";
  public static readonly OIL_AND_GAS_E_AND_P_KEY = "30947102";
  public static readonly OIL_AND_GAS_INTEGRATED_DISPLAY = "oil.and.gas.integrated";
  public static readonly OIL_AND_GAS_INTEGRATED_KEY = "30948103";
  public static readonly OIL_AND_GAS_MIDSTREAM_DISPLAY = "oil.and.gas.midstream";
  public static readonly OIL_AND_GAS_MIDSTREAM_KEY = "30949104";
  public static readonly OIL_AND_GAS_REFINING_AND_MARKETING_DISPLAY = "oil.and.gas.refining.and.marketing";
  public static readonly OIL_AND_GAS_REFINING_AND_MARKETING_KEY = "30950105";
  public static readonly OIL_AND_GAS_EQUIPMENT_AND_SERVICES_DISPLAY = "oil.and.gas.equipment.and.services";
  public static readonly OIL_AND_GAS_EQUIPMENT_AND_SERVICES_KEY = "30951106";
  public static readonly AEROSPACE_AND_DEFENSE_DISPLAY = "aerospace.and.defense";
  public static readonly AEROSPACE_AND_DEFENSE_KEY = "31052107";
  public static readonly AIRLINES_DISPLAY = "airlines";
  public static readonly AIRLINES_KEY = "31053108";
  public static readonly BUSINESS_SERVICES_DISPLAY = "business.services";
  public static readonly BUSINESS_SERVICES_KEY = "31054109";
  public static readonly CONGLOMERATES_DISPLAY = "conglomerates";
  public static readonly CONGLOMERATES_KEY = "31055110";
  public static readonly RENTAL_AND_LEASING_SERVICES_DISPLAY = "rental.and.leasing.services";
  public static readonly RENTAL_AND_LEASING_SERVICES_KEY = "31056111";
  public static readonly SECURITY_AND_PROTECTION_SERVICES_DISPLAY = "security.and.protection.services";
  public static readonly SECURITY_AND_PROTECTION_SERVICES_KEY = "31056112";
  public static readonly STAFFING_AND_OUTSOURCING_SERVICES_DISPLAY = "staffing.and.outsourcing.services";
  public static readonly STAFFING_AND_OUTSOURCING_SERVICES_KEY = "31057113";
  public static readonly ENGINEERING_AND_CONSTRUCTION_DISPLAY = "engineering.and.construction";
  public static readonly ENGINEERING_AND_CONSTRUCTION_KEY = "31058114";
  public static readonly INFRASTRUCTURE_OPERATIONS_DISPLAY = "infrastructure.operations";
  public static readonly INFRASTRUCTURE_OPERATIONS_KEY = "31058115";
  public static readonly FARM_AND_CONSTRUCTION_EQUIPMENT_DISPLAY = "farm.and.construction.equipment";
  public static readonly FARM_AND_CONSTRUCTION_EQUIPMENT_KEY = "31059116";
  public static readonly INDUSTRIAL_DISTRIBUTION_DISPLAY = "industrial.distribution";
  public static readonly INDUSTRIAL_DISTRIBUTION_KEY = "31060117";
  public static readonly BUSINESS_EQUIPMENT_DISPLAY = "business.equipment";
  public static readonly BUSINESS_EQUIPMENT_KEY = "31061118";
  public static readonly DIVERSIFIED_INDUSTRIALS_DISPLAY = "diversified.industrial";
  public static readonly DIVERSIFIED_INDUSTRIALS_KEY = "31061119";
  public static readonly METAL_FABRICATION_DISPLAY = "metal.fabrication";
  public static readonly METAL_FABRICATION_KEY = "31061120";
  public static readonly POLLUTION_AND_TREATMENT_CONTROLS_DISPLAY = "pollution.and.treament.control";
  public static readonly POLLUTION_AND_TREATMENT_CONTROLS_KEY = "31061121";
  public static readonly TOOLS_AND_ACCESSORIES_DISPLAY = "tools.and.accessories";
  public static readonly TOOLS_AND_ACCESSORIES_KEY = "31061122";
  public static readonly AIRPORTS_AND_AIR_SERVICES_DISPLAY = "airports.and.air.services";
  public static readonly AIRPORTS_AND_AIR_SERVICES_KEY = "31062123";
  public static readonly INTEGRATED_SHIPPING_AND_LOGISTICS_DISPLAY = "integrated.shipping.and.logistics";
  public static readonly INTEGRATED_SHIPPING_AND_LOGISTICS_KEY = "31062124";
  public static readonly RAILROADS_DISPLAY = "railroads";
  public static readonly RAILROADS_KEY = "31062125";
  public static readonly SHIPPING_AND_PORTS_DISPLAY = "shipping.and.ports";
  public static readonly SHIPPING_AND_PORTS_KEY = "31062126";
  public static readonly TRUCKING_DISPLAY = "trucking";
  public static readonly TRUCKING_KEY = "31062127";
  public static readonly TRUCK_MANUFACTURING_DISPLAY = "truck.manufacturing";
  public static readonly TRUCK_MANUFACTURING_KEY = "31063128";
  public static readonly WASTE_MANAGEMENT_DISPLAY = "waste.management";
  public static readonly WASTE_MANAGEMENT_KEY = "31064129";
  public static readonly ELECTRONIC_GAMING_AND_MULTIMEDIA_DISPLAY = "electronic.gaming.and.multimedia";
  public static readonly ELECTRONIC_GAMING_AND_MULTIMEDIA_KEY = "31165130";
  public static readonly HEALTH_INFORMATION_SERVICES_DISPLAY = "health.information.services";
  public static readonly HEALTH_INFORMATION_SERVICES_KEY = "31165131";
  public static readonly INFORMATION_TECHNOLOGY_SERVICES_DISPLAY = "information.technology.services";
  public static readonly INFORMATION_TECHNOLOGY_SERVICES_KEY = "31165132";
  public static readonly SOFTWARE_APPLICATION_DISPLAY = "software.application";
  public static readonly SOFTWARE_APPLICATION_KEY = "31165133";
  public static readonly SOFTWARE_INFRASTRUCTURE_DISPLAY = "software.infrastructure";
  public static readonly SOFTWARE_INFRASTRUCTURE_KEY = "31165134";
  public static readonly COMMUNICATION_EQUIPMENT_DISPLAY = "communication.equipment";
  public static readonly COMMUNICATION_EQUIPMENT_KEY = "31166135";
  public static readonly COMPUTER_DISTRIBUTION_DISPLAY = "computer.distribution";
  public static readonly COMPUTER_DISTRIBUTION_KEY = "31167136";
  public static readonly COMPUTER_SYSTEMS_DISPLAY = "computer.systems";
  public static readonly COMPUTER_SYSTEMS_KEY = "31167137";
  public static readonly CONSUMER_ELECTRONICS_DISPLAY = "consumer.electronics";
  public static readonly CONSUMER_ELECTRONICS_KEY = "31167138";
  public static readonly CONTRACT_MANUFACTURERS_DISPLAY = "contract.manufacturers";
  public static readonly CONTRACT_MANUFACTURERS_KEY = "31167139";
  public static readonly DATA_STORAGE_DISPLAY = "data.storage";
  public static readonly DATA_STORAGE_KEY = "31167140";
  public static readonly ELECTRONIC_COMPONENTS_DISPLAY = "electronic.components";
  public static readonly ELECTRONIC_COMPONENTS_KEY = "31167141";
  public static readonly ELECTRONICS_DISTRIBUTION_DISPLAY = "electronics.distribution";
  public static readonly ELECTRONICS_DISTRIBUTION_KEY = "31167142";
  public static readonly SCIENTIFIC_AND_TECHNICAL_INSTRUMENTS_DISPLAY = "scientific.and.technical.instruments";
  public static readonly SCIENTIFIC_AND_TECHNICAL_INSTRUMENTS_KEY = "31167143";
  public static readonly INTERNET_CONTENT_AND_INFORMATION_DISPLAY = "internet.content.and.information";
  public static readonly INTERNET_CONTENT_AND_INFORMATION_KEY = "31168144";
  public static readonly SEMICONDUCTOR_EQUIPMENT_AND_MATERIALS_DISPLAY = "semiconductor.equipment.and.materials";
  public static readonly SEMICONDUCTOR_EQUIPMENT_AND_MATERIALS_KEY = "31169145";
  public static readonly SEMICONDUCTOR_MEMORY_DISPLAY = "semiconductor.memory";
  public static readonly SEMICONDUCTOR_MEMORY_KEY = "31169146";
  public static readonly SEMICONDUCTORS_DISPLAY = "semiconductors";
  public static readonly SEMICONDUCTORS_KEY = "31169147";
  public static readonly SOLAR_DISPLAY = "solar";
  public static readonly SOLAR_KEY = "31169148";

  //Exchange
  public static readonly SGX = "SES";
  public static readonly HKEX = "HKG";
  public static readonly NASDAQ = "NAS";
  public static readonly NYSE = "NYS";
  public static readonly AMEX = "ASE";
  public static readonly BATS = "BATS";
  public static readonly KLSE = "KLS";
  public static readonly SSE = "SHG";
  public static readonly SZSE = "SHE";
  public static EXCHANGE_LIST = [
    {key: StockScreenerConstant.HKEX, id: "HKEX", value: "HKEX"},
    {key: StockScreenerConstant.NASDAQ, id: "NASDAQ", value: "NASDAQ"},
    {key: StockScreenerConstant.NYSE, id: "NYSE", value: "NYSE"},
    {key: StockScreenerConstant.AMEX, id: "AMEX", value: "AMEX"},
    {key: StockScreenerConstant.BATS, id: "BATS", value: "BATS"},
    {key: StockScreenerConstant.SGX, id: "SGX", value: "SGX"},
    {key: StockScreenerConstant.SSE, id: "SSE", value: "SSE"},
    {key: StockScreenerConstant.SZSE, id: "SZSE", value: "SZSE"}
  ];

  public static INDUSTRY_LIST = [
    {
      key: StockScreenerConstant.ADVERTISING_AGENCIES_DISPLAY,
      value: StockScreenerConstant.ADVERTISING_AGENCIES_DISPLAY
    },
    {key: StockScreenerConstant.ADVERTISING_AGENCIES_KEY, value: StockScreenerConstant.ADVERTISING_AGENCIES_DISPLAY},
    {key: StockScreenerConstant.AEROSPACE_AND_DEFENSE_KEY, value: StockScreenerConstant.AEROSPACE_AND_DEFENSE_DISPLAY},
    {key: StockScreenerConstant.AGRICULTURAL_INPUTS_KEY, value: StockScreenerConstant.AGRICULTURAL_INPUTS_DISPLAY},
    {key: StockScreenerConstant.AIRLINES_KEY, value: StockScreenerConstant.AIRLINES_DISPLAY},
    {
      key: StockScreenerConstant.AIRPORTS_AND_AIR_SERVICES_KEY,
      value: StockScreenerConstant.AIRPORTS_AND_AIR_SERVICES_DISPLAY
    },
    {key: StockScreenerConstant.ALUMINIUM_KEY, value: StockScreenerConstant.ALUMINIUM_DISPLAY},
    {key: StockScreenerConstant.APPAREL_MANUFACTURING_KEY, value: StockScreenerConstant.APPAREL_MANUFACTURING_DISPLAY},
    {key: StockScreenerConstant.APPAREL_STORES_KEY, value: StockScreenerConstant.APPAREL_STORES_DISPLAY},
    {key: StockScreenerConstant.ASSET_MANAGEMENT_KEY, value: StockScreenerConstant.ASSET_MANAGEMENT_DISPLAY},
    {
      key: StockScreenerConstant.AUTO_AND_TRUCK_DEALERSHIPS_KEY,
      value: StockScreenerConstant.AUTO_AND_TRUCK_DEALERSHIPS_DISPLAY
    },
    {key: StockScreenerConstant.AUTO_MANUFACTURERS_KEY, value: StockScreenerConstant.AUTO_MANUFACTURERS_DISPLAY},
    {key: StockScreenerConstant.AUTO_PARTS_KEY, value: StockScreenerConstant.AUTO_PARTS_DISPLAY},
    {key: StockScreenerConstant.BANKS_GLOBAL_KEY, value: StockScreenerConstant.BANKS_GLOBAL_DISPLAY},
    {key: StockScreenerConstant.BANKS_REGIONAL_AFRICA_KEY, value: StockScreenerConstant.BANKS_REGIONAL_AFRICA_DISPLAY},
    {key: StockScreenerConstant.BANKS_REGIONAL_ASIA_KEY, value: StockScreenerConstant.BANKS_REGIONAL_ASIA_DISPLAY},
    {
      key: StockScreenerConstant.BANKS_REGIONAL_AUSTRALIA_KEY,
      value: StockScreenerConstant.BANKS_REGIONAL_AUSTRALIA_DISPLAY
    },
    {key: StockScreenerConstant.BANKS_REGIONAL_CANADA_KEY, value: StockScreenerConstant.BANKS_REGIONAL_CANADA_DISPLAY},
    {key: StockScreenerConstant.BANKS_REGIONAL_EUROPE_KEY, value: StockScreenerConstant.BANKS_REGIONAL_EUROPE_DISPLAY},
    {
      key: StockScreenerConstant.BANKS_REGIONAL_LATIN_AMERICA_KEY,
      value: StockScreenerConstant.BANKS_REGIONAL_LATIN_AMERICA_DISPLAY
    },
    {key: StockScreenerConstant.BANKS_REGIONAL_US_KEY, value: StockScreenerConstant.BANKS_REGIONAL_US_DISPLAY},
    {key: StockScreenerConstant.BEVERAGES_BREWERS_KEY, value: StockScreenerConstant.BEVERAGES_BREWERS_DISPLAY},
    {key: StockScreenerConstant.BEVERAGES_SOFT_DRINKS_KEY, value: StockScreenerConstant.BEVERAGES_SOFT_DRINKS_DISPLAY},
    {
      key: StockScreenerConstant.BEVERAGES_WINERIES_AND_DISTILLERIES_KEY,
      value: StockScreenerConstant.BEVERAGES_WINERIES_AND_DISTILLERIES_DISPLAY
    },
    {key: StockScreenerConstant.BIOTECHNOLOGY_KEY, value: StockScreenerConstant.BIOTECHNOLOGY_DISPLAY},
    {key: StockScreenerConstant.BROADCASTING_RADIO_KEY, value: StockScreenerConstant.BROADCASTING_RADIO_DISPLAY},
    {key: StockScreenerConstant.BROADCASTING_TV_KEY, value: StockScreenerConstant.BROADCASTING_TV_DISPLAY},
    {key: StockScreenerConstant.BUILDING_MATERIALS_KEY, value: StockScreenerConstant.BUILDING_MATERIALS_DISPLAY},
    {key: StockScreenerConstant.BUSINESS_EQUIPMENT_KEY, value: StockScreenerConstant.BUSINESS_EQUIPMENT_DISPLAY},
    {key: StockScreenerConstant.BUSINESS_SERVICES_KEY, value: StockScreenerConstant.BUSINESS_SERVICES_DISPLAY},
    {key: StockScreenerConstant.CAPITAL_MARKETS_KEY, value: StockScreenerConstant.CAPITAL_MARKETS_DISPLAY},
    {key: StockScreenerConstant.CHEMICALS_KEY, value: StockScreenerConstant.CHEMICALS_DISPLAY},
    {key: StockScreenerConstant.COAL_KEY, value: StockScreenerConstant.COAL_DISPLAY},
    {
      key: StockScreenerConstant.COMMUNICATION_EQUIPMENT_KEY,
      value: StockScreenerConstant.COMMUNICATION_EQUIPMENT_DISPLAY
    },
    {key: StockScreenerConstant.COMPUTER_DISTRIBUTION_KEY, value: StockScreenerConstant.COMPUTER_DISTRIBUTION_DISPLAY},
    {key: StockScreenerConstant.COMPUTER_SYSTEMS_KEY, value: StockScreenerConstant.COMPUTER_SYSTEMS_DISPLAY},
    {key: StockScreenerConstant.CONFECTIONERS_KEY, value: StockScreenerConstant.CONFECTIONERS_DISPLAY},
    {key: StockScreenerConstant.CONGLOMERATES_KEY, value: StockScreenerConstant.CONGLOMERATES_DISPLAY},
    {key: StockScreenerConstant.CONSUMER_ELECTRONICS_KEY, value: StockScreenerConstant.CONSUMER_ELECTRONICS_DISPLAY},
    {
      key: StockScreenerConstant.CONTRACT_MANUFACTURERS_KEY,
      value: StockScreenerConstant.CONTRACT_MANUFACTURERS_DISPLAY
    },
    {key: StockScreenerConstant.COPPER_KEY, value: StockScreenerConstant.COPPER_DISPLAY},
    {key: StockScreenerConstant.CREDIT_SERVICES_KEY, value: StockScreenerConstant.CREDIT_SERVICES_DISPLAY},
    {key: StockScreenerConstant.DATA_STORAGE_KEY, value: StockScreenerConstant.DATA_STORAGE_DISPLAY},
    {key: StockScreenerConstant.DEPARTMENT_STORES_KEY, value: StockScreenerConstant.DEPARTMENT_STORES_DISPLAY},
    {
      key: StockScreenerConstant.DIAGNOSTICS_AND_RESEARCH_KEY,
      value: StockScreenerConstant.DIAGNOSTICS_AND_RESEARCH_DISPLAY
    },
    {key: StockScreenerConstant.DISCOUNT_STORES_KEY, value: StockScreenerConstant.DISCOUNT_STORES_DISPLAY},
    {
      key: StockScreenerConstant.DIVERSIFIED_INDUSTRIALS_KEY,
      value: StockScreenerConstant.DIVERSIFIED_INDUSTRIALS_DISPLAY
    },
    {
      key: StockScreenerConstant.DRUG_MANUFACTURERS_MAJOR_KEY,
      value: StockScreenerConstant.DRUG_MANUFACTURERS_MAJOR_DISPLAY
    },
    {
      key: StockScreenerConstant.DRUG_MANUFACTURERS_SPECIALTY_AND_GENERIC_KEY,
      value: StockScreenerConstant.DRUG_MANUFACTURERS_SPECIALTY_AND_GENERIC_DISPLAY
    },
    {
      key: StockScreenerConstant.EDUCATION_AND_TRAINING_SERVICES_KEY,
      value: StockScreenerConstant.EDUCATION_AND_TRAINING_SERVICES_DISPLAY
    },
    {key: StockScreenerConstant.ELECTRONIC_COMPONENTS_KEY, value: StockScreenerConstant.ELECTRONIC_COMPONENTS_DISPLAY},
    {
      key: StockScreenerConstant.ELECTRONIC_GAMING_AND_MULTIMEDIA_KEY,
      value: StockScreenerConstant.ELECTRONIC_GAMING_AND_MULTIMEDIA_DISPLAY
    },
    {
      key: StockScreenerConstant.ELECTRONICS_DISTRIBUTION_KEY,
      value: StockScreenerConstant.ELECTRONICS_DISTRIBUTION_DISPLAY
    },
    {
      key: StockScreenerConstant.ENGINEERING_AND_CONSTRUCTION_KEY,
      value: StockScreenerConstant.ENGINEERING_AND_CONSTRUCTION_DISPLAY
    },
    {
      key: StockScreenerConstant.FARM_AND_CONSTRUCTION_EQUIPMENT_KEY,
      value: StockScreenerConstant.FARM_AND_CONSTRUCTION_EQUIPMENT_DISPLAY
    },
    {key: StockScreenerConstant.FARM_PRODUCTS_KEY, value: StockScreenerConstant.FARM_PRODUCTS_DISPLAY},
    {key: StockScreenerConstant.FINANCIAL_EXCHANGES_KEY, value: StockScreenerConstant.FINANCIAL_EXCHANGES_DISPLAY},
    {key: StockScreenerConstant.FOOD_DISTRIBUTION_KEY, value: StockScreenerConstant.FOOD_DISTRIBUTION_DISPLAY},
    {
      key: StockScreenerConstant.FOOTWEAR_AND_ACCESSORIES_KEY,
      value: StockScreenerConstant.FOOTWEAR_AND_ACCESSORIES_DISPLAY
    },
    {key: StockScreenerConstant.GAMBLING_KEY, value: StockScreenerConstant.GAMBLING_DISPLAY},
    {key: StockScreenerConstant.GOLD_KEY, value: StockScreenerConstant.GOLD_DISPLAY},
    {key: StockScreenerConstant.GROCERY_STORES_KEY, value: StockScreenerConstant.GROCERY_STORES_DISPLAY},
    {key: StockScreenerConstant.HEALTH_CARE_PLANS_KEY, value: StockScreenerConstant.HEALTH_CARE_PLANS_DISPLAY},
    {
      key: StockScreenerConstant.HEALTH_INFORMATION_SERVICES_KEY,
      value: StockScreenerConstant.HEALTH_INFORMATION_SERVICES_DISPLAY
    },
    {
      key: StockScreenerConstant.HOME_FURNISHINGS_AND_FIXTURES_KEY,
      value: StockScreenerConstant.HOME_FURNISHINGS_AND_FIXTURES_DISPLAY
    },
    {
      key: StockScreenerConstant.HOME_IMPROVEMENT_STORES_KEY,
      value: StockScreenerConstant.HOME_IMPROVEMENT_STORES_DISPLAY
    },
    {
      key: StockScreenerConstant.HOUSEHOLD_AND_PERSONAL_PRODUCTS_KEY,
      value: StockScreenerConstant.HOUSEHOLD_AND_PERSONAL_PRODUCTS_DISPLAY
    },
    {
      key: StockScreenerConstant.INDUSTRIAL_DISTRIBUTION_KEY,
      value: StockScreenerConstant.INDUSTRIAL_DISTRIBUTION_DISPLAY
    },
    {
      key: StockScreenerConstant.INDUSTRIAL_METALS_AND_MINERALS_KEY,
      value: StockScreenerConstant.INDUSTRIAL_METALS_AND_MINERALS_DISPLAY
    },
    {
      key: StockScreenerConstant.INFORMATION_TECHNOLOGY_SERVICES_KEY,
      value: StockScreenerConstant.INFORMATION_TECHNOLOGY_SERVICES_DISPLAY
    },
    {
      key: StockScreenerConstant.INFRASTRUCTURE_OPERATIONS_KEY,
      value: StockScreenerConstant.INFRASTRUCTURE_OPERATIONS_DISPLAY
    },
    {key: StockScreenerConstant.INSURANCE_BROKERS_KEY, value: StockScreenerConstant.INSURANCE_BROKERS_DISPLAY},
    {key: StockScreenerConstant.INSURANCE_DIVERSIFIED_KEY, value: StockScreenerConstant.INSURANCE_DIVERSIFIED_DISPLAY},
    {key: StockScreenerConstant.INSURANCE_LIFE_KEY, value: StockScreenerConstant.INSURANCE_LIFE_DISPLAY},
    {
      key: StockScreenerConstant.INSURANCE_PROPERTY_AND_CASUALTY_KEY,
      value: StockScreenerConstant.INSURANCE_PROPERTY_AND_CASUALTY_DISPLAY
    },
    {key: StockScreenerConstant.INSURANCE_REINSURANCE_KEY, value: StockScreenerConstant.INSURANCE_REINSURANCE_DISPLAY},
    {key: StockScreenerConstant.INSURANCE_SPECIALTY_KEY, value: StockScreenerConstant.INSURANCE_SPECIALTY_DISPLAY},
    {
      key: StockScreenerConstant.INTEGRATED_SHIPPING_AND_LOGISTICS_KEY,
      value: StockScreenerConstant.INTEGRATED_SHIPPING_AND_LOGISTICS_DISPLAY
    },
    {
      key: StockScreenerConstant.INTERNET_CONTENT_AND_INFORMATION_KEY,
      value: StockScreenerConstant.INTERNET_CONTENT_AND_INFORMATION_DISPLAY
    },
    {key: StockScreenerConstant.LEISURE_KEY, value: StockScreenerConstant.LEISURE_DISPLAY},
    {key: StockScreenerConstant.LODGING_KEY, value: StockScreenerConstant.LODGING_DISPLAY},
    {
      key: StockScreenerConstant.LONG_TERM_CARE_FACILITIES_KEY,
      value: StockScreenerConstant.LONG_TERM_CARE_FACILITIES_DISPLAY
    },
    {
      key: StockScreenerConstant.LUMBER_AND_WOOD_PRODUCTION_KEY,
      value: StockScreenerConstant.LUMBER_AND_WOOD_PRODUCTION_DISPLAY
    },
    {key: StockScreenerConstant.LUXURY_GOODS_KEY, value: StockScreenerConstant.LUXURY_GOODS_DISPLAY},
    {key: StockScreenerConstant.MARKETING_SERVICES_KEY, value: StockScreenerConstant.MARKETING_SERVICES_DISPLAY},
    {key: StockScreenerConstant.MEDIA_DIVERSIFIED_KEY, value: StockScreenerConstant.MEDIA_DIVERSIFIED_DISPLAY},
    {key: StockScreenerConstant.MEDICAL_CARE_KEY, value: StockScreenerConstant.MEDICAL_CARE_DISPLAY},
    {key: StockScreenerConstant.MEDICAL_DEVICES_KEY, value: StockScreenerConstant.MEDICAL_DEVICES_DISPLAY},
    {key: StockScreenerConstant.MEDICAL_DISTRIBUTION_KEY, value: StockScreenerConstant.MEDICAL_DISTRIBUTION_DISPLAY},
    {
      key: StockScreenerConstant.MEDICAL_INSTRUMENTS_AND_SUPPLIES_KEY,
      value: StockScreenerConstant.MEDICAL_INSTRUMENTS_AND_SUPPLIES_DISPLAY
    },
    {key: StockScreenerConstant.METAL_FABRICATION_KEY, value: StockScreenerConstant.METAL_FABRICATION_DISPLAY},
    {key: StockScreenerConstant.OIL_AND_GAS_DRILLING_KEY, value: StockScreenerConstant.OIL_AND_GAS_DRILLING_DISPLAY},
    {key: StockScreenerConstant.OIL_AND_GAS_E_AND_P_KEY, value: StockScreenerConstant.OIL_AND_GAS_E_AND_P_DISPLAY},
    {
      key: StockScreenerConstant.OIL_AND_GAS_EQUIPMENT_AND_SERVICES_KEY,
      value: StockScreenerConstant.OIL_AND_GAS_EQUIPMENT_AND_SERVICES_DISPLAY
    },
    {
      key: StockScreenerConstant.OIL_AND_GAS_INTEGRATED_KEY,
      value: StockScreenerConstant.OIL_AND_GAS_INTEGRATED_DISPLAY
    },
    {key: StockScreenerConstant.OIL_AND_GAS_MIDSTREAM_KEY, value: StockScreenerConstant.OIL_AND_GAS_MIDSTREAM_DISPLAY},
    {
      key: StockScreenerConstant.OIL_AND_GAS_REFINING_AND_MARKETING_KEY,
      value: StockScreenerConstant.OIL_AND_GAS_REFINING_AND_MARKETING_DISPLAY
    },
    {key: StockScreenerConstant.PACKAGED_FOODS_KEY, value: StockScreenerConstant.PACKAGED_FOODS_DISPLAY},
    {
      key: StockScreenerConstant.PACKAGING_AND_CONTAINERS_KEY,
      value: StockScreenerConstant.PACKAGING_AND_CONTAINERS_DISPLAY
    },
    {
      key: StockScreenerConstant.PAPER_AND_PAPER_PRODUCTS_KEY,
      value: StockScreenerConstant.PAPER_AND_PAPER_PRODUCTS_DISPLAY
    },
    {key: StockScreenerConstant.PAY_TV_KEY, value: StockScreenerConstant.PAY_TV_DISPLAY},
    {key: StockScreenerConstant.PERSONAL_SERVICES_KEY, value: StockScreenerConstant.PERSONAL_SERVICES_DISPLAY},
    {
      key: StockScreenerConstant.PHARMACEUTICAL_RETAILERS_KEY,
      value: StockScreenerConstant.PHARMACEUTICAL_RETAILERS_DISPLAY
    },
    {
      key: StockScreenerConstant.POLLUTION_AND_TREATMENT_CONTROLS_KEY,
      value: StockScreenerConstant.POLLUTION_AND_TREATMENT_CONTROLS_DISPLAY
    },
    {key: StockScreenerConstant.PUBLISHING_KEY, value: StockScreenerConstant.PUBLISHING_DISPLAY},
    {key: StockScreenerConstant.RAILROADS_KEY, value: StockScreenerConstant.RAILROADS_DISPLAY},
    {key: StockScreenerConstant.REAL_ESTATE_GENERAL_KEY, value: StockScreenerConstant.REAL_ESTATE_GENERAL_DISPLAY},
    {key: StockScreenerConstant.REAL_ESTATE_SERVICES_KEY, value: StockScreenerConstant.REAL_ESTATE_SERVICES_DISPLAY},
    {key: StockScreenerConstant.RECREATIONAL_VEHICLES_KEY, value: StockScreenerConstant.RECREATIONAL_VEHICLES_DISPLAY},
    {key: StockScreenerConstant.REIT_DIVERSIFIED_KEY, value: StockScreenerConstant.REIT_DIVERSIFIED_DISPLAY},
    {
      key: StockScreenerConstant.REIT_HEALTHCARE_FACILITIES_KEY,
      value: StockScreenerConstant.REIT_HEALTHCARE_FACILITIES_DISPLAY
    },
    {key: StockScreenerConstant.REIT_HOTEL_AND_MOTEL_KEY, value: StockScreenerConstant.REIT_HOTEL_AND_MOTEL_DISPLAY},
    {key: StockScreenerConstant.REIT_INDUSTRIAL_KEY, value: StockScreenerConstant.REIT_INDUSTRIAL_DISPLAY},
    {key: StockScreenerConstant.REIT_OFFICE_KEY, value: StockScreenerConstant.REIT_OFFICE_DISPLAY},
    {key: StockScreenerConstant.REIT_RESIDENTIAL_KEY, value: StockScreenerConstant.REIT_RESIDENTIAL_DISPLAY},
    {key: StockScreenerConstant.REIT_RETAIL_KEY, value: StockScreenerConstant.REIT_RETAIL_DISPLAY},
    {
      key: StockScreenerConstant.RENTAL_AND_LEASING_SERVICES_KEY,
      value: StockScreenerConstant.RENTAL_AND_LEASING_SERVICES_DISPLAY
    },
    {
      key: StockScreenerConstant.RESIDENTIAL_CONSTRUCTION_KEY,
      value: StockScreenerConstant.RESIDENTIAL_CONSTRUCTION_DISPLAY
    },
    {key: StockScreenerConstant.RESORTS_AND_CASINOS_KEY, value: StockScreenerConstant.RESORTS_AND_CASINOS_DISPLAY},
    {key: StockScreenerConstant.RESTRAURANTS_KEY, value: StockScreenerConstant.RESTRAURANTS_DISPLAY},
    {
      key: StockScreenerConstant.SAVINGS_AND_COOPERATIVE_BANKS_KEY,
      value: StockScreenerConstant.SAVINGS_AND_COOPERATIVE_BANKS_DISPLAY
    },
    {
      key: StockScreenerConstant.SCIENTIFIC_AND_TECHNICAL_INSTRUMENTS_KEY,
      value: StockScreenerConstant.SCIENTIFIC_AND_TECHNICAL_INSTRUMENTS_DISPLAY
    },
    {key: StockScreenerConstant.SEMICONDUCTOR_MEMORY_KEY, value: StockScreenerConstant.SEMICONDUCTOR_MEMORY_DISPLAY},
    {
      key: StockScreenerConstant.SECURITY_AND_PROTECTION_SERVICES_KEY,
      value: StockScreenerConstant.SECURITY_AND_PROTECTION_SERVICES_DISPLAY
    },
    {
      key: StockScreenerConstant.SEMICONDUCTOR_EQUIPMENT_AND_MATERIALS_KEY,
      value: StockScreenerConstant.SEMICONDUCTOR_EQUIPMENT_AND_MATERIALS_DISPLAY
    },
    {key: StockScreenerConstant.SEMICONDUCTORS_KEY, value: StockScreenerConstant.SEMICONDUCTORS_DISPLAY},
    {key: StockScreenerConstant.SHIPPING_AND_PORTS_KEY, value: StockScreenerConstant.SHIPPING_AND_PORTS_DISPLAY},
    {key: StockScreenerConstant.SILVER_KEY, value: StockScreenerConstant.SILVER_DISPLAY},
    {key: StockScreenerConstant.SOFTWARE_APPLICATION_KEY, value: StockScreenerConstant.SOFTWARE_APPLICATION_DISPLAY},
    {
      key: StockScreenerConstant.SOFTWARE_INFRASTRUCTURE_KEY,
      value: StockScreenerConstant.SOFTWARE_INFRASTRUCTURE_DISPLAY
    },
    {key: StockScreenerConstant.SOLAR_KEY, value: StockScreenerConstant.SOLAR_DISPLAY},
    {key: StockScreenerConstant.SPECIALTY_CHEMICALS_KEY, value: StockScreenerConstant.SPECIALTY_CHEMICALS_DISPLAY},
    {key: StockScreenerConstant.SPECIALTY_FINANCE_KEY, value: StockScreenerConstant.SPECIALTY_FINANCE_DISPLAY},
    {key: StockScreenerConstant.SPECIALTY_RETAIL_KEY, value: StockScreenerConstant.SPECIALTY_RETAIL_DISPLAY},
    {
      key: StockScreenerConstant.STAFFING_AND_OUTSOURCING_SERVICES_KEY,
      value: StockScreenerConstant.STAFFING_AND_OUTSOURCING_SERVICES_DISPLAY
    },
    {key: StockScreenerConstant.STEEL_KEY, value: StockScreenerConstant.STEEL_DISPLAY},
    {key: StockScreenerConstant.TELECOM_SERVICES_KEY, value: StockScreenerConstant.TELECOM_SERVICES_DISPLAY},
    {key: StockScreenerConstant.TEXTILE_MANUFACTURING_KEY, value: StockScreenerConstant.TEXTILE_MANUFACTURING_DISPLAY},
    {key: StockScreenerConstant.TOBACCO_KEY, value: StockScreenerConstant.TOBACCO_DISPLAY},
    {key: StockScreenerConstant.TOOLS_AND_ACCESSORIES_KEY, value: StockScreenerConstant.TOOLS_AND_ACCESSORIES_DISPLAY},
    {key: StockScreenerConstant.TRUCK_MANUFACTURING_KEY, value: StockScreenerConstant.TRUCK_MANUFACTURING_DISPLAY},
    {key: StockScreenerConstant.TRUCKING_KEY, value: StockScreenerConstant.TRUCKING_DISPLAY},
    {key: StockScreenerConstant.UTILITIES_DIVERSIFIED_KEY, value: StockScreenerConstant.UTILITIES_DIVERSIFIED_DISPLAY},
    {
      key: StockScreenerConstant.UTILITIES_INDEPENDENT_POWER_PRODUCERS_KEY,
      value: StockScreenerConstant.UTILITIES_INDEPENDENT_POWER_PRODUCERS_DISPLAY
    },
    {
      key: StockScreenerConstant.UTILITIES_REGULATED_ELECTRIC_KEY,
      value: StockScreenerConstant.UTILITIES_REGULATED_ELECTRIC_DISPLAY
    },
    {
      key: StockScreenerConstant.UTILITIES_REGULATED_GAS_KEY,
      value: StockScreenerConstant.UTILITIES_REGULATED_GAS_DISPLAY
    },
    {
      key: StockScreenerConstant.UTILITIES_REGULATED_WATER_KEY,
      value: StockScreenerConstant.UTILITIES_REGULATED_WATER_DISPLAY
    },
    {key: StockScreenerConstant.WASTE_MANAGEMENT_KEY, value: StockScreenerConstant.WASTE_MANAGEMENT_DISPLAY}
  ];

  public static readonly MARKET_CAP_ID = "1";
  public static readonly MARKET_CAP_DISPLAY = "stock.market.cap.in.millions.usd";
  public static readonly PRICE_ID = "2";
  public static readonly PRICE_DISPLAY = "price";
  public static readonly TARGET_PRICE_ID = "3";
  public static readonly TARGET_PRICE_DISPLAY = "target.price";
  public static readonly DIVIDEND_YIELD_ID = "4";
  public static readonly DIVIDEND_YIELD_DISPLAY = "dividend.yield.percent";
  public static readonly FORWARD_DIVIDEND_YIELD_ID = "5";
  public static readonly FORWARD_DIVIDEND_YIELD_DISPLAY = "forward.dividend.yield.percent";
  public static readonly PE_RATIO_ID = "6";
  public static readonly PE_RATIO_DISPLAY = "pe.ratio";
  public static readonly FORWARD_PE_RATIO_ID = "7";
  public static readonly FORWARD_PE_RATIO_DISPLAY = "forward.pe.ratio";
  public static readonly PB_RATIO_ID = "8";
  public static readonly PB_RATIO_DISPLAY = "pb.ratio";
  public static readonly ROA_ID = "9";
  public static readonly ROA_DISPLAY = "roa";
  public static readonly ROE_ID = "10";
  public static readonly ROE_DISPLAY = "roe";
  public static readonly PEG_RATIO_ID = "11";
  public static readonly PEG_RATIO_DISPLAY = "peg.ratio";
  public static readonly ROIC_ID = "12";
  public static readonly ROIC_DISPLAY = "roic";
  public static readonly GROSS_MARGIN_ID = "13";
  public static readonly GROSS_MARGIN_DISPLAY = "gross.margin.percent";
  public static readonly NET_MARGIN_ID = "14";
  public static readonly NET_MARGIN_DISPLAY = "net.margin.percent";
  public static readonly TOTAL_DEBT_TO_EQUITY_RATIO_ID = "15";
  public static readonly TOTAL_DEBT_TO_EQUITY_RATIO_DISPLAY = "total.debt.to.equity.ratio";
  public static readonly CURRENT_RATIO_ID = "16";
  public static readonly CURRENT_RATIO_DISPLAY = "current.ratio";
  public static readonly QUICK_RATIO_ID = "17";
  public static readonly QUICK_RATIO_DISPLAY = "quick.ratio";
  public static readonly FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID = "18";
  public static readonly FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY = "five.year.annualised.net.income.growth.percent";
  public static readonly FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_ID = "19";
  public static readonly FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY = "five.year.annualised.revenue.growth.percent";
  public static readonly AVERAGE_VOLUME_ID = "20";
  public static readonly AVERAGE_COLUME_DISPLAY = "average.volume";
  public static readonly CURRENT_VOLUME_ID = "21";
  public static readonly CURRENT_VOLUME_DISPLAY = "current.volume";
  public static readonly THREE_YEAR_DIVIDEND_GROWTH_ID = "22";
  public static readonly THREE_YEAR_DIVIDEND_GROWTH_DISPLAY = "three.year.dividend.growth";
  public static readonly FIVE_YEAR_DIVIDEND_GROWTH_ID = "23";
  public static readonly FIVE_YEAR_DIVIDEND_GROWTH_DISPLAY = "five.year.dividend.growth";
  public static readonly DIVIDEND_PER_SHARE_ID = "24";
  public static readonly DIVIDEND_PER_SHARE_DISPLAY = "dividend.per.share";
  public static readonly PAYOUT_RATIO_ID = "25";
  public static readonly PAYOUT_RATIO_DISPLAY = "payout.ratio";
  public static readonly ASSET_TURNOVER_ID = "26";
  public static readonly ASSET_TURNOVER_DISPLAY = "asset.turnover";
  public static readonly CASH_CONVERSION_CYCLE_ID = "27";
  public static readonly CASH_CONVERSION_CYCLE_DISPLAY = "cash.conversion.cycle";
  public static readonly DAYS_INVENTORY_ID = "28";
  public static readonly DAYS_INVENTORY_DISPLAY = "days.inventory";
  public static readonly INVENTORY_TURNOVER_ID = "30";
  public static readonly INVENTORY_TURNOVER_DISPLAY = "inventory.turnover";
  public static readonly RECEIVABLE_TURNOVER_ID = "31";
  public static readonly RECEIVABLE_TURNOVER_DISPLAY = "receivable.turnover";
  public static readonly CAPITAL_EXPENDITURE_TO_SALES_ID = "32";
  public static readonly CAPITAL_EXPENDITURE_TO_SALES_DISPLAY = "capital.expenditure.to.sales";
  public static readonly FINANCIAL_LEVERAGE_ID = "33";
  public static readonly FINANCIAL_LEVERAGE_DISPLAY = "financial.leverage";
  public static readonly INTEREST_COVERAGE_ID = "34";
  public static readonly INTEREST_COVERAGE_DISPLAY = "interest.coverage";
  public static readonly ONE_YEAR_NET_INCOME_GROWTH_ID = "35";
  public static readonly ONE_YEAR_NET_INCOME_GROWTH_DISPLAY = "one.year.net.income.growth.percent";
  public static readonly ONE_YEAR_OPERATING_INCOME_GROWTH_ID = "36";
  public static readonly ONE_YEAR_OPERATING_INCOME_GROWTH_DISPLAY = "one.year.operating.income.growth.percent";
  public static readonly ONE_YEAR_REVENUE_GROWTH_ID = "37";
  public static readonly ONE_YEAR_REVENUE_GROWTH_DISPLAY = "one.year.revenue.growth.percent";
  public static readonly THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID = "38";
  public static readonly THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY = "stock.three.year.annualised.net.income.growth.percent";
  public static readonly THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID = "39";
  public static readonly THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY = "stock.three.year.annualised.operating.income.growth.percent";
  public static readonly THREE_YEAR_ANNUALISED_REVENUE_GROWTH_ID = "40";
  public static readonly THREE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY = "stock.three.year.annualised.revenue.growth.percent";
  public static readonly FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID = "41";
  public static readonly FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY = "stock.five.year.annualised.operating.income.growth.percent";
  public static readonly FREE_CASH_FLOW_TO_NET_INCOME_ID = "42";
  public static readonly FREE_CASH_FLOW_TO_NET_INCOME_DISPLAY = "free.cash.flow.to.net.income";
  public static readonly FREE_CASH_FLOW_TO_SALES_ID = "43";
  public static readonly FREE_CASH_FLOW_TO_SALES_DISPLAY = "free.cash.flow.to.sales";
  public static readonly OPERATING_MARGIN_ID = "44";
  public static readonly OPERATING_MARGIN_DISPLAY = "operating.margin.percent";
  public static readonly FIVE_YEAR_AVERAGE_PE_RATIO_ID = "45";
  public static readonly FIVE_YEAR_AVERAGE_PE_RATIO_DISPLAY = "five.year.average.pe.ratio";
  public static readonly PCF_RATIO_ID = "46";
  public static readonly PCF_RATIO_DISPLAY = "pcf.ratio";
  public static readonly PFCF_RATIO_ID = "47";
  public static readonly PFCF_RATIO_DISPLAY = "pfcf.ratio";
  public static readonly PS_RATIO_ID = "48";
  public static readonly PS_RATIO_DISPLAY = "ps.ratio";
  public static readonly THREE_YEAR_ANNUALISED_VOLATILITY_ID = "49";
  public static readonly THREE_YEAR_ANNUALISED_VOLATILITY_DISPLAY = "stock.three.year.volatility";
  public static readonly THREE_YEAR_MAX_DRAWDOWN_ID = "50";
  public static readonly THREE_YEAR_MAX_DRAWDOWN_DISPLAY = "three.year.max.drawdown";
  public static readonly STOCK_STYLE_ID = "52";
  public static readonly STOCK_STYLE_DISPLAY = "stock.style";
  public static readonly ONE_YEAR_ANNUALISED_RETURN_ID = "54";
  public static readonly ONE_YEAR_ANNUALISED_RETURN_DISPLAY = "stock.one.year.return";
  public static readonly THREE_YEAR_ANNUALISED_RETURN_ID = "55";
  public static readonly THREE_YEAR_ANNUALISED_RETURN_DISPLAY = "stock.three.year.return";


  public static readonly SCREENER_CRITERIA_HASHMAP = [
    {key: StockScreenerConstant.MARKET_CAP_ID, value: StockScreenerConstant.MARKET_CAP_DISPLAY},
    {key: StockScreenerConstant.DIVIDEND_YIELD_ID, value: StockScreenerConstant.DIVIDEND_YIELD_DISPLAY},
    {key: StockScreenerConstant.FORWARD_DIVIDEND_YIELD_ID, value: StockScreenerConstant.FORWARD_DIVIDEND_YIELD_DISPLAY},
    {key: StockScreenerConstant.PE_RATIO_ID, value: StockScreenerConstant.PE_RATIO_DISPLAY},
    {key: StockScreenerConstant.FORWARD_PE_RATIO_ID, value: StockScreenerConstant.FORWARD_PE_RATIO_DISPLAY},
    {key: StockScreenerConstant.PB_RATIO_ID, value: StockScreenerConstant.PB_RATIO_DISPLAY},
    {key: StockScreenerConstant.ROA_ID, value: StockScreenerConstant.ROA_DISPLAY},
    {key: StockScreenerConstant.ROE_ID, value: StockScreenerConstant.ROE_DISPLAY},
    {key: StockScreenerConstant.PEG_RATIO_ID, value: StockScreenerConstant.PEG_RATIO_DISPLAY},
    {key: StockScreenerConstant.ROIC_ID, value: StockScreenerConstant.ROIC_DISPLAY},
    {key: StockScreenerConstant.GROSS_MARGIN_ID, value: StockScreenerConstant.GROSS_MARGIN_DISPLAY},
    {key: StockScreenerConstant.NET_MARGIN_ID, value: StockScreenerConstant.NET_MARGIN_DISPLAY},
    {
      key: StockScreenerConstant.TOTAL_DEBT_TO_EQUITY_RATIO_ID,
      value: StockScreenerConstant.TOTAL_DEBT_TO_EQUITY_RATIO_DISPLAY
    },
    {key: StockScreenerConstant.CURRENT_RATIO_ID, value: StockScreenerConstant.CURRENT_RATIO_DISPLAY},
    {key: StockScreenerConstant.QUICK_RATIO_ID, value: StockScreenerConstant.QUICK_RATIO_DISPLAY},
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_DIVIDEND_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_DIVIDEND_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_DIVIDEND_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_DIVIDEND_GROWTH_DISPLAY
    },
    {key: StockScreenerConstant.DIVIDEND_PER_SHARE_ID, value: StockScreenerConstant.DIVIDEND_PER_SHARE_DISPLAY},
    {key: StockScreenerConstant.PAYOUT_RATIO_ID, value: StockScreenerConstant.PAYOUT_RATIO_DISPLAY},
    {key: StockScreenerConstant.ASSET_TURNOVER_ID, value: StockScreenerConstant.ASSET_TURNOVER_DISPLAY},
    {key: StockScreenerConstant.CASH_CONVERSION_CYCLE_ID, value: StockScreenerConstant.CASH_CONVERSION_CYCLE_DISPLAY},
    {key: StockScreenerConstant.DAYS_INVENTORY_ID, value: StockScreenerConstant.DAYS_INVENTORY_DISPLAY},
    {key: StockScreenerConstant.INVENTORY_TURNOVER_ID, value: StockScreenerConstant.INVENTORY_TURNOVER_DISPLAY},
    {key: StockScreenerConstant.RECEIVABLE_TURNOVER_ID, value: StockScreenerConstant.RECEIVABLE_TURNOVER_DISPLAY},
    {
      key: StockScreenerConstant.CAPITAL_EXPENDITURE_TO_SALES_ID,
      value: StockScreenerConstant.CAPITAL_EXPENDITURE_TO_SALES_DISPLAY
    },
    {key: StockScreenerConstant.FINANCIAL_LEVERAGE_ID, value: StockScreenerConstant.FINANCIAL_LEVERAGE_DISPLAY},
    {key: StockScreenerConstant.INTEREST_COVERAGE_ID, value: StockScreenerConstant.INTEREST_COVERAGE_DISPLAY},
    {
      key: StockScreenerConstant.ONE_YEAR_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.ONE_YEAR_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.ONE_YEAR_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FREE_CASH_FLOW_TO_NET_INCOME_ID,
      value: StockScreenerConstant.FREE_CASH_FLOW_TO_NET_INCOME_DISPLAY
    },
    {
      key: StockScreenerConstant.FREE_CASH_FLOW_TO_SALES_ID,
      value: StockScreenerConstant.FREE_CASH_FLOW_TO_SALES_DISPLAY
    },
    {key: StockScreenerConstant.OPERATING_MARGIN_ID, value: StockScreenerConstant.OPERATING_MARGIN_DISPLAY},
    {
      key: StockScreenerConstant.FIVE_YEAR_AVERAGE_PE_RATIO_ID,
      value: StockScreenerConstant.FIVE_YEAR_AVERAGE_PE_RATIO_DISPLAY
    },
    {key: StockScreenerConstant.PCF_RATIO_ID, value: StockScreenerConstant.PCF_RATIO_DISPLAY},
    {key: StockScreenerConstant.PFCF_RATIO_ID, value: StockScreenerConstant.PFCF_RATIO_DISPLAY},
    {key: StockScreenerConstant.PS_RATIO_ID, value: StockScreenerConstant.PS_RATIO_DISPLAY},
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_VOLATILITY_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_VOLATILITY_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_MAX_DRAWDOWN_ID,
      value: StockScreenerConstant.THREE_YEAR_MAX_DRAWDOWN_DISPLAY
    },
    {key: StockScreenerConstant.STOCK_STYLE_ID, value: StockScreenerConstant.STOCK_STYLE_DISPLAY},
    {
      key: StockScreenerConstant.ONE_YEAR_ANNUALISED_RETURN_ID,
      value: StockScreenerConstant.ONE_YEAR_ANNUALISED_RETURN_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_RETURN_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_RETURN_DISPLAY
    }
  ]


  public static readonly SCREENER_CRITERIA_DIVIDEND_HASHMAP = [
    {
      key: StockScreenerConstant.THREE_YEAR_DIVIDEND_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_DIVIDEND_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_DIVIDEND_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_DIVIDEND_GROWTH_DISPLAY
    },
    {key: StockScreenerConstant.DIVIDEND_PER_SHARE_ID, value: StockScreenerConstant.DIVIDEND_PER_SHARE_DISPLAY},
    {key: StockScreenerConstant.DIVIDEND_YIELD_ID, value: StockScreenerConstant.DIVIDEND_YIELD_DISPLAY},
    {key: StockScreenerConstant.FORWARD_DIVIDEND_YIELD_ID, value: StockScreenerConstant.FORWARD_DIVIDEND_YIELD_DISPLAY},
    {key: StockScreenerConstant.PAYOUT_RATIO_ID, value: StockScreenerConstant.PAYOUT_RATIO_DISPLAY}
  ];

  public static readonly SCREENER_CRITERIA_EFFICIENCY_RATIO_HASHMAP = [
    {key: StockScreenerConstant.ASSET_TURNOVER_ID, value: StockScreenerConstant.ASSET_TURNOVER_DISPLAY},
    {key: StockScreenerConstant.CASH_CONVERSION_CYCLE_ID, value: StockScreenerConstant.CASH_CONVERSION_CYCLE_DISPLAY},
    {key: StockScreenerConstant.DAYS_INVENTORY_ID, value: StockScreenerConstant.DAYS_INVENTORY_DISPLAY},
    {key: StockScreenerConstant.INVENTORY_TURNOVER_ID, value: StockScreenerConstant.INVENTORY_TURNOVER_DISPLAY},
    {key: StockScreenerConstant.RECEIVABLE_TURNOVER_ID, value: StockScreenerConstant.RECEIVABLE_TURNOVER_DISPLAY}
  ];

  public static readonly SCREENER_CRITERIA_FINANCIAL_STRENGTH_HASHMAP = [
    {
      key: StockScreenerConstant.CAPITAL_EXPENDITURE_TO_SALES_ID,
      value: StockScreenerConstant.CAPITAL_EXPENDITURE_TO_SALES_DISPLAY
    },
    {key: StockScreenerConstant.CURRENT_RATIO_ID, value: StockScreenerConstant.CURRENT_RATIO_DISPLAY},
    {key: StockScreenerConstant.FINANCIAL_LEVERAGE_ID, value: StockScreenerConstant.FINANCIAL_LEVERAGE_DISPLAY},
    {key: StockScreenerConstant.INTEREST_COVERAGE_ID, value: StockScreenerConstant.INTEREST_COVERAGE_DISPLAY},
    {
      key: StockScreenerConstant.TOTAL_DEBT_TO_EQUITY_RATIO_ID,
      value: StockScreenerConstant.TOTAL_DEBT_TO_EQUITY_RATIO_DISPLAY
    },
    {key: StockScreenerConstant.QUICK_RATIO_ID, value: StockScreenerConstant.QUICK_RATIO_DISPLAY}
  ];

  public static readonly SCREENER_CRITERIA_GROWTH_RATES_HASHMAP = [
    {
      key: StockScreenerConstant.ONE_YEAR_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.ONE_YEAR_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.ONE_YEAR_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY
    }
  ];

  public static readonly SCREENER_CRITERIA_PROFITABILITY_RATIO_HASHMAP = [
    {
      key: StockScreenerConstant.FREE_CASH_FLOW_TO_NET_INCOME_ID,
      value: StockScreenerConstant.FREE_CASH_FLOW_TO_NET_INCOME_DISPLAY
    },
    {
      key: StockScreenerConstant.FREE_CASH_FLOW_TO_SALES_ID,
      value: StockScreenerConstant.FREE_CASH_FLOW_TO_SALES_DISPLAY
    },
    {key: StockScreenerConstant.GROSS_MARGIN_ID, value: StockScreenerConstant.GROSS_MARGIN_DISPLAY},
    {key: StockScreenerConstant.NET_MARGIN_ID, value: StockScreenerConstant.NET_MARGIN_DISPLAY},
    {key: StockScreenerConstant.OPERATING_MARGIN_ID, value: StockScreenerConstant.OPERATING_MARGIN_DISPLAY},
    {key: StockScreenerConstant.ROIC_ID, value: StockScreenerConstant.ROIC_DISPLAY},
    {key: StockScreenerConstant.ROA_ID, value: StockScreenerConstant.ROA_DISPLAY},
    {key: StockScreenerConstant.ROE_ID, value: StockScreenerConstant.ROE_DISPLAY}];

  public static readonly SCREENER_CRITERIA_VALUATION_HASHMAP = [
    {
      key: StockScreenerConstant.FIVE_YEAR_AVERAGE_PE_RATIO_ID,
      value: StockScreenerConstant.FIVE_YEAR_AVERAGE_PE_RATIO_DISPLAY
    },
    {key: StockScreenerConstant.FORWARD_PE_RATIO_ID, value: StockScreenerConstant.FORWARD_PE_RATIO_DISPLAY},
    {key: StockScreenerConstant.PB_RATIO_ID, value: StockScreenerConstant.PB_RATIO_DISPLAY},
    {key: StockScreenerConstant.PCF_RATIO_ID, value: StockScreenerConstant.PCF_RATIO_DISPLAY},
    {key: StockScreenerConstant.PE_RATIO_ID, value: StockScreenerConstant.PE_RATIO_DISPLAY},
    {key: StockScreenerConstant.PFCF_RATIO_ID, value: StockScreenerConstant.PFCF_RATIO_DISPLAY},
    {key: StockScreenerConstant.PS_RATIO_ID, value: StockScreenerConstant.PS_RATIO_DISPLAY},
    {key: StockScreenerConstant.PEG_RATIO_ID, value: StockScreenerConstant.PEG_RATIO_DISPLAY}];


  public static readonly SCREENER_CRITERIA_STOCK_INFO_HASHMAP = [
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_VOLATILITY_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_VOLATILITY_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_MAX_DRAWDOWN_ID,
      value: StockScreenerConstant.THREE_YEAR_MAX_DRAWDOWN_DISPLAY
    },
    {key: StockScreenerConstant.STOCK_STYLE_ID, value: StockScreenerConstant.STOCK_STYLE_DISPLAY},
    {
      key: StockScreenerConstant.ONE_YEAR_ANNUALISED_RETURN_ID,
      value: StockScreenerConstant.ONE_YEAR_ANNUALISED_RETURN_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_RETURN_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_RETURN_DISPLAY
    },
    {key: StockScreenerConstant.MARKET_CAP_ID, value: StockScreenerConstant.MARKET_CAP_DISPLAY}
  ];


  public static readonly SCREENER_FUNDAMENTAL_CRITERIA_HASHMAP = [
    {key: StockScreenerConstant.DIVIDEND_YIELD_ID, value: StockScreenerConstant.DIVIDEND_YIELD_DISPLAY},
    {key: StockScreenerConstant.FORWARD_DIVIDEND_YIELD_ID, value: StockScreenerConstant.FORWARD_DIVIDEND_YIELD_DISPLAY},
    {key: StockScreenerConstant.PE_RATIO_ID, value: StockScreenerConstant.PE_RATIO_DISPLAY},
    {key: StockScreenerConstant.FORWARD_PE_RATIO_ID, value: StockScreenerConstant.FORWARD_PE_RATIO_DISPLAY},
    {key: StockScreenerConstant.PB_RATIO_ID, value: StockScreenerConstant.PB_RATIO_DISPLAY},
    {key: StockScreenerConstant.ROA_ID, value: StockScreenerConstant.ROA_DISPLAY},
    {key: StockScreenerConstant.ROE_ID, value: StockScreenerConstant.ROE_DISPLAY},
    {key: StockScreenerConstant.PEG_RATIO_ID, value: StockScreenerConstant.PEG_RATIO_DISPLAY},
    {key: StockScreenerConstant.ROIC_ID, value: StockScreenerConstant.ROIC_DISPLAY},
    {key: StockScreenerConstant.GROSS_MARGIN_ID, value: StockScreenerConstant.GROSS_MARGIN_DISPLAY},
    {key: StockScreenerConstant.NET_MARGIN_ID, value: StockScreenerConstant.NET_MARGIN_DISPLAY},
    {
      key: StockScreenerConstant.TOTAL_DEBT_TO_EQUITY_RATIO_ID,
      value: StockScreenerConstant.TOTAL_DEBT_TO_EQUITY_RATIO_DISPLAY
    },
    {key: StockScreenerConstant.CURRENT_RATIO_ID, value: StockScreenerConstant.CURRENT_RATIO_DISPLAY},
    {key: StockScreenerConstant.QUICK_RATIO_ID, value: StockScreenerConstant.QUICK_RATIO_DISPLAY},
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_DIVIDEND_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_DIVIDEND_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_DIVIDEND_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_DIVIDEND_GROWTH_DISPLAY
    },
    {key: StockScreenerConstant.DIVIDEND_PER_SHARE_ID, value: StockScreenerConstant.DIVIDEND_PER_SHARE_DISPLAY},
    {key: StockScreenerConstant.PAYOUT_RATIO_ID, value: StockScreenerConstant.PAYOUT_RATIO_DISPLAY},
    {key: StockScreenerConstant.ASSET_TURNOVER_ID, value: StockScreenerConstant.ASSET_TURNOVER_DISPLAY},
    {key: StockScreenerConstant.CASH_CONVERSION_CYCLE_ID, value: StockScreenerConstant.CASH_CONVERSION_CYCLE_DISPLAY},
    {key: StockScreenerConstant.DAYS_INVENTORY_ID, value: StockScreenerConstant.DAYS_INVENTORY_DISPLAY},
    {key: StockScreenerConstant.INVENTORY_TURNOVER_ID, value: StockScreenerConstant.INVENTORY_TURNOVER_DISPLAY},
    {key: StockScreenerConstant.RECEIVABLE_TURNOVER_ID, value: StockScreenerConstant.RECEIVABLE_TURNOVER_DISPLAY},
    {
      key: StockScreenerConstant.CAPITAL_EXPENDITURE_TO_SALES_ID,
      value: StockScreenerConstant.CAPITAL_EXPENDITURE_TO_SALES_DISPLAY
    },
    {key: StockScreenerConstant.FINANCIAL_LEVERAGE_ID, value: StockScreenerConstant.FINANCIAL_LEVERAGE_DISPLAY},
    {key: StockScreenerConstant.INTEREST_COVERAGE_ID, value: StockScreenerConstant.INTEREST_COVERAGE_DISPLAY},
    {
      key: StockScreenerConstant.ONE_YEAR_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.ONE_YEAR_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.ONE_YEAR_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.ONE_YEAR_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_NET_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_REVENUE_GROWTH_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_REVENUE_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_ID,
      value: StockScreenerConstant.FIVE_YEAR_ANNUALISED_OPERATING_INCOME_GROWTH_DISPLAY
    },
    {
      key: StockScreenerConstant.FREE_CASH_FLOW_TO_NET_INCOME_ID,
      value: StockScreenerConstant.FREE_CASH_FLOW_TO_NET_INCOME_DISPLAY
    },
    {
      key: StockScreenerConstant.FREE_CASH_FLOW_TO_SALES_ID,
      value: StockScreenerConstant.FREE_CASH_FLOW_TO_SALES_DISPLAY
    },
    {key: StockScreenerConstant.OPERATING_MARGIN_ID, value: StockScreenerConstant.OPERATING_MARGIN_DISPLAY},
    {
      key: StockScreenerConstant.FIVE_YEAR_AVERAGE_PE_RATIO_ID,
      value: StockScreenerConstant.FIVE_YEAR_AVERAGE_PE_RATIO_DISPLAY
    },
    {key: StockScreenerConstant.PCF_RATIO_ID, value: StockScreenerConstant.PCF_RATIO_DISPLAY},
    {key: StockScreenerConstant.PFCF_RATIO_ID, value: StockScreenerConstant.PFCF_RATIO_DISPLAY},
    {key: StockScreenerConstant.PS_RATIO_ID, value: StockScreenerConstant.PS_RATIO_DISPLAY},
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_VOLATILITY_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_VOLATILITY_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_MAX_DRAWDOWN_ID,
      value: StockScreenerConstant.THREE_YEAR_MAX_DRAWDOWN_DISPLAY
    },
    {key: StockScreenerConstant.STOCK_STYLE_ID, value: StockScreenerConstant.STOCK_STYLE_DISPLAY},
    {
      key: StockScreenerConstant.ONE_YEAR_ANNUALISED_RETURN_ID,
      value: StockScreenerConstant.ONE_YEAR_ANNUALISED_RETURN_DISPLAY
    },
    {
      key: StockScreenerConstant.THREE_YEAR_ANNUALISED_RETURN_ID,
      value: StockScreenerConstant.THREE_YEAR_ANNUALISED_RETURN_DISPLAY
    }
  ];

  public static readonly SCREENER_SECTOR_BASIC_MATERIAL_DISPLAY = "basic.material";
  public static readonly SCREENER_SECTOR_BASIC_MATERIAL_ID = 101;
  public static readonly SCREENER_SECTOR_CONSUMER_CYCLICAL_DISPLAY = "consumer.cyclical";
  public static readonly SCREENER_SECTOR_CONSUMER_CYCLICAL_ID = 102;
  public static readonly SCREENER_SECTOR_FINANCIAL_SERVICES_DISPLAY = "financial.services";
  public static readonly SCREENER_SECTOR_FINANCIAL_SERVICES_ID = 103;
  public static readonly SCREENER_SECTOR_REAL_ESTATE_DISPLAY = "real.estate";
  public static readonly SCREENER_SECTOR_REAL_ESTATE_ID = 104;
  public static readonly SCREENER_SECTOR_CONSUMER_DEFENSIVE_DISPLAY = "consumer.defensive";
  public static readonly SCREENER_SECTOR_CONSUMER_DEFENSIVE_ID = 205;
  public static readonly SCREENER_SECTOR_HEALTHCARE_DISPLAY = "healthcare";
  public static readonly SCREENER_SECTOR_HEALTHCARE_ID = 206;
  public static readonly SCREENER_SECTOR_UTILITIES_DISPLAY = "utilities";
  public static readonly SCREENER_SECTOR_UTILITIES_ID = 207;
  public static readonly SCREENER_SECTOR_COMMUNICATION_SERVICES_DISPLAY = "communication.services";
  public static readonly SCREENER_SECTOR_COMMUNICATION_SERVICES_ID = 308;
  public static readonly SCREENER_SECTOR_ENERGY_DISPLAY = "energy";
  public static readonly SCREENER_SECTOR_ENERGY_ID = 309;
  public static readonly SCREENER_SECTOR_INDUSTRIALS_DISPLAY = "industrials";
  public static readonly SCREENER_SECTOR_INDUSTRIALS_ID = 310;
  public static readonly SCREENER_SECTOR_TECHNOLOGY_DISPLAY = "technology";
  public static readonly SCREENER_SECTOR_TECHNOLOGY_ID = 311;
  public static readonly SCREENER_SECTOR_ALL_DISPLAY = "select.all";
  public static readonly SCREENER_SECTOR_ALL_ID = [101, 102, 103, 104, 205, 206, 207, 308, 309, 310, 311];

  public static readonly SCREENER_SECTOR_HASHMAP = [
    {
      display: "All",
      key: StockScreenerConstant.SCREENER_SECTOR_ALL_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_ALL_DISPLAY
    },
    {
      display: "Basic Materials",
      key: StockScreenerConstant.SCREENER_SECTOR_BASIC_MATERIAL_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_BASIC_MATERIAL_DISPLAY
    },
    {
      display: "Consumer Cyclical",
      key: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_CYCLICAL_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_CYCLICAL_DISPLAY
    },
    {
      display: "Financial Services",
      key: StockScreenerConstant.SCREENER_SECTOR_FINANCIAL_SERVICES_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_FINANCIAL_SERVICES_DISPLAY
    },
    {
      display: "Real Estate",
      key: StockScreenerConstant.SCREENER_SECTOR_REAL_ESTATE_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_REAL_ESTATE_DISPLAY
    },
    {
      display: "Consumer Defensive",
      key: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_DEFENSIVE_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_DEFENSIVE_DISPLAY
    },
    {
      display: "Healthcare",
      key: StockScreenerConstant.SCREENER_SECTOR_HEALTHCARE_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_HEALTHCARE_DISPLAY
    },
    {
      display: "Utilities",
      key: StockScreenerConstant.SCREENER_SECTOR_UTILITIES_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_UTILITIES_DISPLAY
    },
    {
      display: "Communication Services",
      key: StockScreenerConstant.SCREENER_SECTOR_COMMUNICATION_SERVICES_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_COMMUNICATION_SERVICES_DISPLAY
    },
    {
      display: "Energy",
      key: StockScreenerConstant.SCREENER_SECTOR_ENERGY_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_ENERGY_DISPLAY
    },
    {
      display: "Industrials",
      key: StockScreenerConstant.SCREENER_SECTOR_INDUSTRIALS_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_INDUSTRIALS_DISPLAY
    },
    {
      display: "Technology",
      key: StockScreenerConstant.SCREENER_SECTOR_TECHNOLOGY_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_TECHNOLOGY_DISPLAY
    }
  ];

  public static readonly SCREENER_SECTOR_HASHMAP_TW = [
    {
      key: StockScreenerConstant.SCREENER_SECTOR_BASIC_MATERIAL_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_BASIC_MATERIAL_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_CYCLICAL_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_CYCLICAL_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_FINANCIAL_SERVICES_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_FINANCIAL_SERVICES_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_REAL_ESTATE_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_REAL_ESTATE_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_DEFENSIVE_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_CONSUMER_DEFENSIVE_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_HEALTHCARE_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_HEALTHCARE_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_UTILITIES_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_UTILITIES_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_COMMUNICATION_SERVICES_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_COMMUNICATION_SERVICES_DISPLAY
    },
    {key: StockScreenerConstant.SCREENER_SECTOR_ENERGY_ID, value: StockScreenerConstant.SCREENER_SECTOR_ENERGY_DISPLAY},
    {
      key: StockScreenerConstant.SCREENER_SECTOR_INDUSTRIALS_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_INDUSTRIALS_DISPLAY
    },
    {
      key: StockScreenerConstant.SCREENER_SECTOR_TECHNOLOGY_ID,
      value: StockScreenerConstant.SCREENER_SECTOR_TECHNOLOGY_DISPLAY
    }

  ];

  public static readonly stockSectorValueMap = new Map<string, string>([
    [StockScreenerConstant.SCREENER_SECTOR_BASIC_MATERIAL_ID.toString(), "Basic Materials"],
    [StockScreenerConstant.SCREENER_SECTOR_CONSUMER_CYCLICAL_ID.toString(), "Consumer Cyclical"],
    [StockScreenerConstant.SCREENER_SECTOR_FINANCIAL_SERVICES_ID.toString(), "Financial Services"],
    [StockScreenerConstant.SCREENER_SECTOR_REAL_ESTATE_ID.toString(), "Real Estate"],
    [StockScreenerConstant.SCREENER_SECTOR_CONSUMER_DEFENSIVE_ID.toString(), "Consumer Defensive"],
    [StockScreenerConstant.SCREENER_SECTOR_HEALTHCARE_ID.toString(), "Healthcare"],
    [StockScreenerConstant.SCREENER_SECTOR_UTILITIES_ID.toString(), "Utilities"],
    [StockScreenerConstant.SCREENER_SECTOR_COMMUNICATION_SERVICES_ID.toString(), "Communication Services"],
    [StockScreenerConstant.SCREENER_SECTOR_ENERGY_ID.toString(), "Energy"],
    [StockScreenerConstant.SCREENER_SECTOR_INDUSTRIALS_ID.toString(), "Industrials"],
    [StockScreenerConstant.SCREENER_SECTOR_TECHNOLOGY_ID.toString(), "Technology"],
    ["9999999", "Others"]
  ]);
}
