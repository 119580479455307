export class SetMobileWebAction {
  static readonly type: string = '[Global] Toggle Mobile Web';

  constructor(public isMobileWeb: boolean) {}
}

export class SetPlatformAction {
  static readonly type: string = '[Global] Set Platform';

  constructor(public platform: string, public browser: string, public iPadUser: boolean) {}
}

export class SetPrintAction {
  static readonly type: string = '[Global] Set Print';

  constructor(public printId: string) {}
}

export class DeclareSIAction {
  static readonly type: string = '[Global] Accept SInvestor Declaration';
}

export class ToggleFooter {
  static readonly type: string = '[Global] Toggle Footer Display';

  constructor(public show: boolean) {}
}

export class UpdatePrint {
  static  readonly type = '[Global] Print';

  constructor(public printPdf?: boolean) {}
}

export class UpdateFsmPostLoginEnabled {
  static  readonly type = '[Global] Set FSMONE_POSTLOGIN';

  constructor(public fsmPostLoginEnabled?: boolean) {}
}
