import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UicCardComponent } from './uic-card.component';



@NgModule({
  declarations: [UicCardComponent],
  exports: [UicCardComponent],
  imports: [
    CommonModule
  ]
})
export class UicCardModule { }
