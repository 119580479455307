import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
  selector: 'app-product-ranking',
  templateUrl: './product-ranking.component.html',
  styles: [`
      .product-ranking-img {
        height: 35px;
        min-width: 35px;
      }
    `]
})

export class ProductRankingComponent {
	@Input() title? : string;
	@Input() lists : any;
	@Input() tab? : any;
	@Input() indicator? : string;
	@Input() horizontal : boolean = false;
	@Input() listValuePrefix? : string;
	
	@Output() navigate : EventEmitter<string> = new EventEmitter<string>();
	
	currentIndex : number = 0; 
	localeOption: string = 'zh-TW';

  	readonly iconSet: number = (new Date().getDay()) + 1;

	constructor(
		private globalDataStorage: GlobalDataStorage
	) {
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}

	}

	onNav(value = '') {
        this.navigate.emit(value);
    }
}
