import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMobileFullScreenComponent } from './ui-mobile-full-screen.component';
import {NzModalModule} from 'ng-zorro-antd';


@NgModule({
  declarations: [UiMobileFullScreenComponent],
  imports: [
    CommonModule,
    NzModalModule
  ],
  exports: [UiMobileFullScreenComponent],
})
export class UiMobileFullScreenModule { }
