<ng-container *ngIf="!isMobileView || (isMobileView && (fsmPostLoginWhitelist || (fsmPostLoginEnabled$ | async)))">
	<b2cweb-header [auth]="(auth||authCas) && auth2FA" [auth2FA]="auth2FA" [newMenuEnabled]="fsmPostLoginWhitelist || (fsmPostLoginEnabled$ | async)"
		[country]="{ title: 'Hong Kong', titleKey: 'hong.kong' }"
		[langTemplates]="[langTemplate]"  
		[appBarTemplates]="[openAccountTemplate, loginTemplate]"
		topWrapperId="announcement" [structure]="headerSiteMap" (navigate)="navigate($event)"
		([search])="search" [siteMapTemplate]="[siteMapTemplate]" [isPostloginMenuCollapsed]="isPostloginMenuCollapsed" (updatePostloginCollapse)="togglePostloginMenuCollapsed($event)">
		<global-search ([search])="search"></global-search>
	</b2cweb-header>
	<post-login-menu *ngIf="(auth||authCas) && auth2FA" [postMenu]="postMenu" [locale]="locale" [accountSettingMenu]="accountSettingMenu" [isCollapsed]="isPostloginMenuCollapsed" (updatePostloginCollapse)="togglePostloginMenuCollapsed($event)"></post-login-menu>
</ng-container>
<ng-container *ngIf="isMobileView && !(fsmPostLoginWhitelist || (fsmPostLoginEnabled$ | async))">
	<mobile-header [auth]="(auth||authCas) && auth2FA" [menu]="headerSiteMap" [accountSettingMenu]="accountSettingMenu" [langTemplates]="mobileLangTemplate" [postMenu]="mobilePostMenu"></mobile-header>
</ng-container>

<ng-template #langTemplate>
  <div class="text-sm font-weight-semibold">
    <span class="text-black-7 active-lang">{{localeDisplay}}</span>
    <span *ngFor="let option of _langOptions" class="text-black-7 pointer" (click)="changeLanguage(option.value)">
		<span class="mx-1">|</span>{{option.label}}
	</span>
  </div>
</ng-template>

<ng-template #mobileLangTemplate>
	<div class="menu-item lang-option d-inline-block">
		<i nz-icon nzType="translation" nzTheme="outline"></i>
		<a nz-dropdown [nzDropdownMenu]="langOption" class=" text-white ml-1 menu"> {{localeDisplay}} <i nz-icon nzType="down"></i>
		</a>
		<nz-dropdown-menu #langOption="nzDropdownMenu">
			<ul nz-menu nzSelectable>
				<li nz-menu-item *ngFor="let option of _langOptions" (click)="changeLanguage(option.value)">{{option.label}}</li>
			</ul>
		</nz-dropdown-menu>
	</div>
</ng-template>

<ng-template #openAccountTemplate>
	<button nz-button nzType="warning" nzSize="small" (click)="goToAccountOpening()" *ngIf="!(auth||authCas)">{{'fsmone.open.an.account' | translate}}</button>
</ng-template>

<ng-template #loginTemplate>
  <button nz-button nzType="primary" nzSize="small" [routerLink]="loginLink" *ngIf="!(auth||authCas)">
    <i nz-icon nzType="lock" nzTheme="outline"></i>{{'fsmone.login' | translate}}
  </button>
  <button nz-button nzType="theme" nzSize="small" (click)="logout()" *ngIf="(auth||authCas)">
    <i nz-icon nzType="unlock" nzTheme="outline"></i>{{'fsmone.logout' | translate}}
  </button>  
</ng-template>

<ng-template #faqTemplate>
  <a class="pointer " [routerLink]="['/' + RouteConstant?.SUPPORT + '/' + RouteConstant?.FAQ]">Frequently Ask Questions</a>
</ng-template>

<ng-template #siteMapTemplate> 
	<a class="text-decoration-none mr-1 text-black-7 sitemap-wrapper" (click)="toggleSiteMap()">
		<i class="text-xs mr-1" nz-icon nzType="menu" nzTheme="outline"></i>
		<span translate="fsmone.sitemap.1" translate-default="SITEMAP"></span>
		<i nz-icon nzType="caret-down" nzTheme="fill" class="ml-1 text-xs"></i>
	</a>
	<nz-card (click)="toggleSiteMap()" *ngIf="siteMap" class="text-sm d-inline-block sitemap-overlay">
		<div nz-row nzJustify="space-between" nzType="flex" class="mb-2">
			<div nz-col class="sitemap-col" *ngFor="let menu of headerSiteMap">
				<a class="text-theme font-weight-semibold d-block mb-1" (click)="navigateByUrl(menu.url)">{{ menu.titleKey | translate}}</a>
				<ng-container *ngFor="let item of menu.children">
					<span class="font-weight-semibold text-underline d-block mt-2 mb-1">{{item.titleKey | translate}}</span>
					<a *ngFor="let child of item.children" class="text-black-7 d-block mb-1" (click)="navigateByUrl(child.url)" >{{child.titleKey|translate}}</a>
				</ng-container>
			</div>
		</div>
		<a class="text-xs text-black-7" (click)="toggleSiteMap()">{{ 'back.to.home' | translate }}</a>
	</nz-card>
</ng-template>

 <open-account-modal [visibility]="openAccountModelVisibility" (closeModel)="closeAccountModel($event)"></open-account-modal>
