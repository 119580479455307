import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
//import {etfSelectorService} from '../../service/etf-selector.service';
import {IDropdownModel} from '@api-module/model/common/i-dropdown.model';
import {tap} from 'rxjs/operators';
import {IBondChartCenterFilterOptionModel} from '@api-module/model/bond/i-bond-chart-centre.model';
import {AppConstant} from '../../../../../constant';
import {EtfRestService} from '@api-module/rest/etf.rest.service';
import { Subscription } from 'rxjs';
import { HeaderStorageService } from '@share/services/header/header-storage.service';

@Component({
  selector: ' app-etf-chart-centre-selector',
  templateUrl: './etf-chart-centre-selector.component.html',
  providers: [SubHandlingService],
})
export class EtfChartCentreSelectorComponent implements OnInit, OnChanges {
  @Input() totalETFSelected: any[] = [];
  @Output() selectedETF = new EventEmitter<{ etfName: string, tickerno: string }>();
  @Input() allETFOptions: any[] = [];
  @Output() clearAllETF = new EventEmitter<{ clear: boolean }>();
  @Output() selectedMainOption = new EventEmitter<string>();

  chartForm: FormGroup;
  mainOptions: IDropdownModel[] = [] as IDropdownModel[];
  secondOptions: IDropdownModel[] = [] as IDropdownModel[];
  etfOptions: IDropdownModel[] = [] as IDropdownModel[];
  etfFilterOption: IBondChartCenterFilterOptionModel;

  loading = false;
  showExtraOptions = false;
  isLoading = false;
  mobileScrolled = false;
  labelList: string;
  secondValue: string;
  secondList: IDropdownModel[] = [] as IDropdownModel[];
  mainValue: string;
  mainList: IDropdownModel[] = [] as IDropdownModel[];
  showFundDrawer = false;
  showMainDrawer = false;
  showSecondDrawer = false;
  etfValue: string;
  etfList: IDropdownModel[] = [] as IDropdownModel[];
  isetfChartCenterInitLoading = false;
  indexetf: any[] = [];
  headerSubscription: Subscription;
  headerHeight = 0;

  constructor(
    private fb: FormBuilder,
    private subHandlingService: SubHandlingService,
    private etfRest: EtfRestService,
    private headerStorageService: HeaderStorageService) {
    this.headerSubscription = this.headerStorageService.headerHeight.subscribe(height => {
      this.headerHeight = height;
    });
  }

  ngOnInit(): void {
    this.chartForm = this.fb.group({
      //category: ['ALL', [Validators.required]],
      //secondCategory: [null, [Validators.required]],
      etf: [null, [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.headerSubscription?.unsubscribe();
}

  /*initDefaultOptions(): void {
    this.mainOptions = [
      {
        label: 'All',
        value: 'ALL',
      },
      {
        label: 'Index Data',
        value: 'indexData',
      },
      {
        label: 'etf Issuer',
        value: 'etfIssuer',
      },
      {
        label: 'etf Sector',
        value: 'sector',
      },
      {
        label: 'etf Sub Sector',
        value: 'subSector',
      },
      {
        label: 'etf Type',
        value: 'etfType',
      },
      {
        label: 'etf Currency',
        value: 'etfCurrencyCode',
      },
      {
        label: 'Coupon Frequency',
        value: 'couponFrequency',
      },
      {
        label: 'Exchange Listed',
        value: 'exchangeListed',
      },
      {
        label: 'Credit Rating',
        value: 'creditRating',
      },
      {
        label: 'etf Registration',
        value: 'etfRegistration',
      },
      {
        label: 'Geographical Sectors',
        value: 'geographicalSectors',
      }
    ];
  }*/

	private initiateQueryParamEtfList(tickernos: string){
		this.isLoading = true;
		let fromQueryList = tickernos.split(',');
		for(let fromQuery of fromQueryList){
			for(let etf of this.allETFOptions){
				if(fromQuery===etf.tickerNo){
					this.selectedETF.emit({
						etfName: etf.stockName,
						tickerno: etf.tickerNo
					})
				}
			}
		}
	}

  initetfChartCenterSelector() {
    this.isetfChartCenterInitLoading = true;
    /*this.etfRestService.findetfSelectorFilterOption().subscribe(resp => {
        this.etfFilterOption = resp.data;
        this.subscribeFormSelect();
      });*/
		this.subscribeFormSelect();
  }

  subscribeFormSelect(): void {
    
    let label: string;
    this.subHandlingService.subscribe(
      this.etf.valueChanges.pipe(
        tap(value => {
	      for (const item of this.etfOptions) {
            if (item.value === value) {
              label = item.label;
            }
          }

          if (this.etf.value !== null) {
            this.selectedETF.emit({
              etfName: label,
              tickerno: value,
            });
          }
        })
      )
    );
  }

  /*findetfMarketTypeList() {
    this.isLoading = true;
    this.etfRestService.getetfMarketTypeList().subscribe(res => {
      if (AppConstant.RESPONSE_SUCCESS !== res.status) {
        console.error('Something has went wrong');
        return;
      }
      console.log(res);
      this.indexetf = res.data;
      for (const data of this.indexetf) {
        this.etfOptions = this.indexetf.map(value => {
          return {
            label: value.etfName,
            value: value.issueCode,
          };
        })
          .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      }
      this.isLoading = false;

    }).add(() => {
      this.isLoading = false;
    });
  }*/

  getFilteredetfList(category: string, secondCategory: string): void {
    this.isLoading = true;
    if (category === 'ALL') {
      this.etfOptions = this.allETFOptions.map(value => {
          return {
            label: value.etfName,
            value: value.tickerNo,
          };
        })
        .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    } else {
      const filteretfList = this.allETFOptions.filter(etf => etf[category] === secondCategory);
      this.etfOptions = filteretfList.map(value => {
          return {
            label: value.etfName,
            value: value.tickerNo,
          };
        })
        .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    }
    this.isLoading = false;
  }

  onClickClear(): void {
    /*this.category.setValue('ALL');
    this.secondCategory.setValue(null);*/
    this.etf.setValue(null);
    this.clearAllETF.emit({clear: true});
  }

  /*get category(): AbstractControl {
    return this.chartForm.get('category');
  }

  get secondCategory(): AbstractControl {
    return this.chartForm.get('secondCategory');
  }*/

  get etf(): AbstractControl {
    return this.chartForm.get('etf');
  }

  onScroll(value: boolean) {
    this.mobileScrolled = value;
  }
/*
  openMainDrawer(): void {
    this.mainValue = this.chartForm.get('category').value;
    this.mainList = this.mainOptions;
    this.showMainDrawer = true;
  }

  openSecondDrawer(): void {
    this.secondValue = this.chartForm.get('secondCategory').value;
    this.secondList = this.secondOptions;
    this.showSecondDrawer = true;
  }*/

  openEtfDrawer(): void {
    this.etfValue = this.chartForm.get('etf').value;
    this.etfList = this.etfOptions;
    this.showFundDrawer = true;
  }

  onViewChange(value: boolean) {
    this.showMainDrawer = value;
    this.showFundDrawer = value;
    this.showSecondDrawer = value;
  }

  onClassChange(value: string) {
    this.etfValue = value;
    for (const data of value) {
      this.etf.setValue(data);
    }
    return;
  }

  onSecondClassChange(value: string) {
    this.secondValue = value;
    //this.secondCategory.setValue(value);
    return;
  }

  onMainClassChange(value: string) {
    this.mainValue = value;
    //this.category.setValue(value);
    if (value !== 'ALL' && value !== 'indexData') {
      this.showExtraOptions = true;
    }
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //this.initDefaultOptions();
    this.initetfChartCenterSelector();
    this.getFilteredetfList('ALL', null);
  }

}
