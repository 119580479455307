import {Component, OnInit, Input, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {IRouteData, IRouteNavData} from 'src/app/core/model/i-route.model';
import {GlobalState} from 'src/app/core/store';
import { TranslateService } from '@ngx-translate/core';
import { RouteConstant } from 'src/app/constant';
import * as _ from 'lodash';

@Component({
  selector: 'app-route-subheader',
  templateUrl: './route-subheader.component.html',
  styleUrls: ['./route-subheader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RouteSubheaderComponent implements OnInit {
  @Input() readonly auto: boolean = false;
  @Input() title?: string = '';
  @Input() navigationSteps: Array<IRouteBreadcrumbModel> = [];
	@Input() readonly coverImage: string = 'assets/images/banner/support_banner.png'
  @Input() isBreadcrumbOnly: boolean = false;
  @Input() meta: string;
	@Input() size: string;
	@Input() h1Title?: string;
	@Input() showBreadcrumb = true;
	@Input() isH2 : boolean = false;

  	mobile: boolean = false;
    readonly RouteConstant = RouteConstant;
    titleHasLink = [RouteConstant.FUNDS, RouteConstant.BONDS, RouteConstant.ETFS, RouteConstant.STOCKS, RouteConstant.DPMS, RouteConstant.CYBER_SECURITY];
    disablePages = [RouteConstant.ARTICLE_LIST];

 	constructor(
    private route: ActivatedRoute, 
    private cdRef: ChangeDetectorRef, 
    private store: Store,
    private translate:TranslateService,
    private router: Router,
    ) {

    }

  	ngOnInit(): void {
		if (!this.auto) {
			this.getRouteDataRecursively(this.route.snapshot);
			
			let key = (this.route.snapshot?.data?.nav?.titleKey?this.route.snapshot?.data?.nav?.titleKey:this.route.snapshot?.data?.nav?.title);
			let translateTitle = this.translateWithDefault(key,this.route.snapshot?.data?.nav?.title);
	      	this.title = this.title.length ? this.title : ((translateTitle) || '');
	    }

    	if (this.navigationSteps.length) {
			let translateTitle = this.translateWithDefault('fsmone.home','Home');
      		this.navigationSteps.unshift({title: translateTitle, titleKey:'fsmone.home', link: '/'});
    	}

    	if (!this.auto) {
      		this.navigationSteps = this.generateBreadcrumb();
    	}

   	 	this.cdRef.markForCheck();

    	this.store.select(GlobalState.isMobile).pipe(
      		tap((isMobile: boolean) => {
        		this.mobile = isMobile;
        		this.cdRef.markForCheck();
      		})
    	).subscribe();
  	}

  private getRouteDataRecursively(snapshot: ActivatedRouteSnapshot) {
    const data: IRouteData = snapshot.data;

    try {
      if (data.nav) {
        this.navigationSteps.push(data.nav);

        if (snapshot.parent) {
          this.getRouteDataRecursively(snapshot.parent);
        } else {
          this.navigationSteps = this.navigationSteps.reverse();
        }
      } else {
        this.navigationSteps = this.navigationSteps.reverse();
      }
    } catch (_) {
      this.navigationSteps = this.navigationSteps.reverse();
    }
  }

  private generateBreadcrumb(): Array<IRouteBreadcrumbModel> {
    return this.navigationSteps.map((step: IRouteNavData, index: number) => {
      let routerLink: string | Array<string>;
      let routerLinkList = this.navigationSteps.slice();
      let link = step.link;
      let url: string = this.router.url; //current route Url 
      let urlList: Array<string> = _.split(url, '/');
      let urlByStep = _.cloneDeep(urlList);
      urlByStep = urlByStep.slice(1, index + 1).join('/');

      if (index === 0) {
        routerLink = '/';
      } else {
        routerLink = this.navigationSteps.slice(1, index + 1).map(({link}) => link).join('/'); 
        if (index != 0 && index < this.navigationSteps.length - 1) { 
          if (routerLink == urlByStep) { //for the case use ActivatedRouteSnapshot
            routerLink = _.includes(this.titleHasLink, routerLinkList[index].link) ? `/${ routerLink}` : null; //if the category page link is not activated = null
          } else {
            routerLink = _.includes(this.titleHasLink, urlList[index]) ? `/${urlByStep}` : null;
          }
        } else if (index == this.navigationSteps.length - 1) {
          if (routerLink == url) {
            routerLink = _.includes(this.disablePages, link) ? null : `/${routerLink}`;
          } else {
            routerLink = _.includes(this.disablePages, link) ? null : `${url}`;
          }
        } else {
          routerLink = null;
        }
      }

      return {
        ...step,
        routerLink: routerLink
      }
    });
  }

	translateWithDefault(value: any,defaultValue?:string){
		if (!value) {
      		return '-';
    	}
		
		let ret: string = this.translate.instant(value);
		if(ret && ret.indexOf("[MISSING-") == -1){
			return ret;
		}
		
		return defaultValue;
	}

  goTo(routerLink: string){
    // this.router.navigate([routerLink]);
    window.open(routerLink,'_blank');
  }
}

export interface IRouteBreadcrumbModel extends IRouteNavData {
  routerLink?: Array<string> | string;
}
