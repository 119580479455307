<div class="container">
	<h3 class="mb-2 text-md font-weight-normal leading-md"
		innerHTML="{{'fsmone.selector.bond.top.description' | translate}}">
	</h3>
	<nz-card *ngIf="!isLoading">
		<div nz-row nzGutter="12" nzType="flex" nzAlign="middle">
			<div nz-col nzXs="24" nzSm="24" nzXl="0" class="text-right mb-2">
				<ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="20" class="mb-2">
				<div
					*ngTemplateOutlet="bondOptionFilterContent; context: {filterselector: filterEnum.BOND_ISSUER, placeholder: 'fsmone.bonds.bond.issuer'  }">
				</div>
			</div>
			<div nz-col nzXs="0" nzSm="0" nzXl="4" class="text-right mb-2">
				<ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="10" class="mb-2">
				<div
					*ngTemplateOutlet="bondOptionFilterContent; context: {filterselector: filterEnum.BOND_TYPE, placeholder: 'fsmone.bonds.bond.category'  }">
				</div>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="10" class="mb-2">
				<div
					*ngTemplateOutlet="bondOptionFilterContent; context: {filterselector: filterEnum.BOND_CURRENCY_CODE, placeholder: 'fsmone.bonds.bond.currency'  }">
				</div>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="10" class="mb-2">
				<div
					*ngTemplateOutlet="bondOptionFilterContent; context: {filterselector: filterEnum.COUPON_FREQUENCY, placeholder: 'fsmone.bonds.coupon.frequency'  }">
				</div>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="10" class="mb-2">
				<div
					*ngTemplateOutlet="bondOptionFilterContent; context: {filterselector: filterEnum.GEO_SECTOR, placeholder: 'fsmone.bonds.geographical.location'  }">
				</div>
			</div>
		</div>
		<div nz-row nzGutter="12" nzType="flex" nzAlign="middle" *ngIf="moreFilter">
			<div nz-col nzXs="24" nzSm="24" nzXl="10" class="mb-2">
				<div
					*ngTemplateOutlet="bondOptionFilterContent; context: {filterselector: filterEnum.BOND_SUITABILITY, placeholder: 'fsmone.bonds.investment.objective'  }">
				</div>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3">
				<p class="font-weight-bold">{{'fsmone.bonds.special.feature' | translate}}</p>
				<nz-checkbox-group [(ngModel)]="features" (ngModelChange)="onChangeFeature(features)"></nz-checkbox-group>
			</div>
		</div>

		<div nz-row nzGutter="12" nzType="flex" nzAlign="middle" class="mt-2">
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3">
				<p class="font-weight-bold">{{'fsmone.bonds.net.yield.to.maturity' | translate}} (%)</p>
				<ngx-slider [(value)]="nytmRange[0]" [(highValue)]="nytmRange[1]" [options]="nytmOptions" (userChangeEnd)="onChangeValue($event, 'nytm')"></ngx-slider>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3">
				<p class="font-weight-bold">{{'fsmone.bonds.years.to.maturity' | translate}}</p>
				<ngx-slider [(value)]="yearRange[0]" [(highValue)]="yearRange[1]" [options]="yearOptions" (userChangeEnd)="onChangeValue($event, 'years')"></ngx-slider>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3" *ngIf="moreFilter">
				<p class="font-weight-bold">{{'fsmone.bonds.annual.coupon.rate' | translate}} (%)</p>
				<ngx-slider [(value)]="couponRange[0]" [(highValue)]="couponRange[1]" [options]="couponOptions" (userChangeEnd)="onChangeValue($event, 'coupon')"></ngx-slider>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3" *ngIf="moreFilter">
				<p class="font-weight-bold">{{'fsmone.bonds.min.investment.amount' | translate}}</p>
				<ngx-slider [(value)]="investAmtRange[0]" [(highValue)]="investAmtRange[1]" [options]="investAmtOptions" (userChangeEnd)="onChangeValue($event, 'invAmt')"></ngx-slider>
			</div>
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3" *ngIf="moreFilter">
				<p class="font-weight-bold">{{'fsmone.bonds.credit.rating' | translate}}</p>
				<ngx-slider [(value)]="creditRange[0]" [(highValue)]="creditRange[1]" [options]="creditOptions" (userChangeEnd)="onChangeValue($event, 'credit')"></ngx-slider>
			</div>
		</div>
		<div nz-row nzGutter="12" nzType="flex" class="mt-2">
			<div nz-col nzXs="24" nzSm="24" nzXl="24" class="mb-3 text-center">
				<button nz-button nzType=primary (click)="loadResult()">{{'fsmone.filter' | translate}}</button>
			</div>
		</div>
	</nz-card>
	<nz-card *ngIf="isLoading">
		<nz-spin nz-col></nz-spin>
	</nz-card>
</div>

<div class="container-fluid">
	<div class="pt-3 pb-5" *ngIf="!fromOutside && !gotResult">
		<div *ngIf="isLoading==false; else elseBlock"></div>
	</div>
	<div class="pt-3 pb-5" *ngIf="!fromOutside && gotResult">
		<div *ngIf="isLoading==false; else elseBlock">
			<app-bond-selector-table [bondList]="bondList"></app-bond-selector-table>
		</div>
	</div>
</div>

<ng-template #bondOptionFilterContent let-filterselector="filterselector" let-placeholder="placeholder">
	<nz-select nzShowSearch nzServerSearch nzMode="multiple" [nzMaxTagCount]="0" [nzMaxTagPlaceholder]="tagPlaceHolder" (nzOnSearch)="onSearch($event,filterselector)" class="w-100"
		nzPlaceHolder="{{placeholder |translate}} - {{selectorSelected[filterselector].length}}"
		[nzNotFoundContent]="null" (nzOpenChange)="dropdownModal($event)">
		<nz-option nzCustomContent>
			<label nz-checkbox *ngIf="bondFilterSearch[filterselector] == false"
				[(ngModel)]="bondFilterSelectedAll[filterselector]" (ngModelChange)="updateAll(filterselector)"
				class="p-2 d-block fund-selector-option-container">
				{{'all'|translate}}
			</label>
			<nz-checkbox-wrapper *ngIf="bondFilterSearch[filterselector] == false"
				(nzOnChange)="clearAll(filterselector)">
				<label *ngFor="let item of selectorFilter[filterselector]" nz-checkbox [nzValue]="item?.code"
					[(ngModel)]="item.check" class="p-2 d-block m-0">
					{{ item.label}}
				</label>
			</nz-checkbox-wrapper>
			<nz-checkbox-wrapper *ngIf="bondFilterSearch[filterselector] == true"
				(nzOnChange)="clearAll(filterselector)">
				<label *ngFor="let item of selectorFilterSearch[filterselector]" nz-checkbox [nzValue]="item?.code"
					[(ngModel)]="item.check" class="p-2 d-block m-0">
					{{ item.label}}
				</label>
			</nz-checkbox-wrapper>
		</nz-option>
		<ng-template #tagPlaceHolder>{{placeholder |translate}} - {{selectorSelected[filterselector].length}}</ng-template>
	</nz-select>
</ng-template>

<ng-template #bondOptionFilterContent2 let-filterselector="filterselector" let-placeholder="placeholder">
	<app-web-scaffold>
		<nz-select class="w-100" nzPlaceHolder="{{placeholder |translate}}" [nzNotFoundContent]="null">
			<nz-option *ngFor="let element of selectorFilter[filterselector]" [nzValue]="element?.code"
				[nzLabel]="element?.label"></nz-option>
		</nz-select>
	</app-web-scaffold>
	<app-mobile-scaffold>
	</app-mobile-scaffold>
</ng-template>

<ng-template #elseBlock>
	<nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>

<ng-template #actionTemplate>
	<a class="font-weight-bold mx-2" *ngIf="!moreFilter" (click)="emitNavigation()" target="_blank">{{ 'fsmone.tools.selector.more.filter' | hkTranslate:'More Filter'}}</a>
	<a class="font-weight-bold mx-2" *ngIf="moreFilter" (click)="toggleFilter()" target="_blank">{{ 'fsmone.tools.selector.less.filter' | hkTranslate:'Less Filter'}}</a>
	<a class="font-weight-bold mx-2" (click)="reset()" target="_blank">{{ 'fsmone.reset' | hkTranslate: 'Reset' }}</a>
</ng-template>
