import {Component, Input, OnInit,OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {AppConstant, RouteConstant} from 'src/app/constant';
import {SubHandlingService} from "@api-module/service/common/subscription-handling.service";
import {GlobalState} from '../../../../core/store';
import {Store, Select} from '@ngxs/store';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import { ResponsiveService } from '@core/service/responsive.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'auto-sweep-calculator',
  templateUrl: './auto-sweep-calculator.component.html',
	styles: ['./auto-sweep-calculator.component.scss'],
  providers: [SubHandlingService]
})

export class AutoSweepCalculatorComponent implements OnInit {
	@Input() data: any;
	@Input() updateDate: Date;
	@Input() locale: string = 'ch';
	
	annualisedYield: number;
	projectedGain: any;
	projectedAmount: any;
	isMobileView: boolean = false;
	asCurr: string = 'hkd';
	form: FormGroup;
	monthOptions = [1,2,3,4,5,6,7,8,9,10,11,12];
	pieChartData: any;
	pieChartTitle: any;
	calculating: boolean = false;
	
	constructor(
		private responsiveService: ResponsiveService,
		private fb: FormBuilder,
		private translate: TranslateService,
		){}
	
	ngOnInit(){
		this.isMobileView = this.responsiveService.isMobileView();
		this.form = this.initForm();
		
//		this.depositAmount.valueChanges.subscribe(value=>{
//			console.log(value)
//			if(value){
//				this.calculateProjectedAmount();
//			}
//		});
		this.annualisedYield = this.data['HKD'].annualisedReturn;
		
		this.period.valueChanges.subscribe(value=>{
			console.log(value);
			if(value){
				this.calculateProjectedAmount();
			}
		});
		
		this.calculateProjectedAmount();
	}
	
	initForm(): FormGroup {
		return this.fb.group({
			depositAmount: [10000, null],
			period: [6, null]
		});
	}
	
	get depositAmount(): AbstractControl{
		return this.form.get('depositAmount');
	}
	get period(): AbstractControl{
		return this.form.get('period');
	}
	
	changeAutoSweepCurrency(currency: string){
		let curr = currency.toUpperCase();
		this.annualisedYield = this.data[curr].annualisedReturn;
		this.calculateProjectedAmount();
	}
	
	onBlurDepositAmount(event){
		this.calculateProjectedAmount();
	}
	
	changePeriod(event){
		
	}
	
	changeAsCurr(currency){
		this.asCurr = currency;
		this.annualisedYield = this.data[currency.toUpperCase()].annualisedReturn;
		this.calculateProjectedAmount();
	}
	
	calculateProjectedAmount(){
		this.calculating = true;
		this.pieChartTitle = '';
		this.projectedGain = this.period.value/12*(this.annualisedYield/100)*this.depositAmount.value;
		this.projectedAmount = ( 1+(this.period.value/12*(this.annualisedYield/100)) )*this.depositAmount.value;
		let total = this.projectedGain + this.projectedAmount;
		this.pieChartData = [
			{
				"name": "Projected Gains",
        "y": this.projectedGain/ total,
        "realValue": this.projectedGain,
        "color": "#2F8DFA",
        "msgKey": "fsmone.auto.sweep.account.projected.gain",
		"localeName": this.translate.instant("fsmone.auto.sweep.account.projected.gain"),
				"currency": this.asCurr
			},
			{
				"name": "Projected Amount",
        "y": this.projectedAmount/ total,
        "realValue": this.projectedAmount,
        "color": "#FFC0CB",
        "msgKey": "fsmone.auto.sweep.account.projected.amount",
        "localeName": this.translate.instant("fsmone.auto.sweep.account.projected.amount"),
				"currency": this.asCurr
			}
		];
		this.pieChartTitle = this.translate.instant('fsmone.total.amount')+'<br>'+total.toFixed(2);
		this.calculating = false;
	}
	
	activeCurrencyTab(value: string){
		if(this.asCurr == value){
			return {'background':'#e8f2ff','color':'#0073CF','border-color':'#e8f2ff'};
		} else {
			return {};
		}
	}
	
	getPunctuation(side: string){
		if(this.locale == 'en'){
			if(side == 'left'){
				return '(';
			} else {
				return ')';
			}
		} else {
			if(side == 'left'){
				return '（';
			} else {
				return '）';
			}
		}
	}
	
}