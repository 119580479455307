import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'number-wrapper',
  templateUrl: './number-wrapper.component.html',
  styleUrls: ['./number-wrapper.component.scss']
})
export class NumberWrapperComponent implements OnInit, OnChanges {
  @Input() value: number;
  @Input() excludeValues: number[] = [];
  @Input() profitLossClass: boolean = true;
  @Input() prefix: string;
  @Input() postfix: string;
  @Input() showArrow: boolean = false;

  displayValue: number;

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.value && changes.value.currentValue
      && this.excludeValues.indexOf(this.value) < 0){
      this.displayValue = this.value;
    }
  }
}
