<nz-modal
  [nzVisible]="visibility"
  [nzTitle]="openAccountTitle"
  [nzFooter]="null"
  [nzWidth]="836"
  (nzOnCancel)="handleClose()"
  nzWrapClassName="vertical-center-modal"
  (nzAfterOpen)="validateTwoFaAccount()">

  <div class="container">
  <!-- <h1>hi</h1> -->
    <nz-row>
      <nz-col>

      </nz-col>
    </nz-row>
  <!--
    <nz-row>
      <nz-col nzSpan="1">
        <img [src]="HEADER_IMG_PATH + 'info-circle.svg'" alt="information icon">
      </nz-col>
      <nz-col nzSpan="22" class="pt-2 pl-4">
        <ng-container
          *ngIf="accountType === accountOpeningConstant.PERSONAL || accountType === accountOpeningConstant.BENEFICIARY">
          <div [ngClass]="(isMobile$ | async) ? 'pl-2' : ''">
            <h4 class="mb-4">Supporting Documents Required</h4>
            <div class="text-sm">
              <div class="mb-2">Kindly ensure that you have the following soft copy of documents ready before you
                proceed:
              </div>
              <ol class="pl-3">
                <li class="py-2">NRIC/Passport</li>
                <li>
                  Any <strong>ONE</strong> of the supporting documents <strong>where we can find your name and mailing address as per application.</strong>  We accept driving license, bank statement,
                  utility bill or EPF Statement.
                  <strong>The documents must be latest or not later than 3 months.</strong>
                </li>
              </ol>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="accountType === accountOpeningConstant.CORPORATE">
          <div [ngClass]="(isMobile$ | async) ? 'pl-2' : ''">

            <h4 class="mb-3">Corporate Account Form</h4>
            <div class="text-sm">
              <p>
                Kindly download corporate account opening form. Once you have completed the form, kindly mail it to:
              </p>
              <p class="text-primary">
                Attn: FSMOne CIS Department<br>Level 28, Menara AIA Sentral,<br>No. 30, Jalan Sultan Ismail, 50250,
                Kuala Lumpur.
              </p>
            </div>
          </div>
        </ng-container>
      </nz-col>
      <nz-col nzSpan="24" class="text-center py-3">
        <button
          nz-button
          nzType="theme"
          *ngIf="accountType === accountOpeningConstant.PERSONAL"
          (click)="openPersonalAccount()"
          [nzLoading]="isLoading || isLoadingOtp">
          Proceed
        </button>
        <button
          nz-button
          nzType="theme"
          *ngIf="accountType === accountOpeningConstant.BENEFICIARY"
          (click)="openBeneficiaryAccount()"
          [nzLoading]="isLoading || isLoadingOtp">
          Proceed
        </button>
        <button
          nz-button
          nzType="theme"
          *ngIf="accountType === accountOpeningConstant.CORPORATE"
          (click)="formPdfNavigation()">
          Download Form
        </button>
      </nz-col>
    </nz-row>-->
  </div>
</nz-modal>

<ng-template #openAccountTitle>
    <div class="p-3">
        <h4 class="mb-4">Select An Investment Account Type</h4>
        <nz-row nzGutter="16" nzType="flex" class="h-100">
            <nz-col class="h-100 align-items-stretch" *ngIf="!isPersonalAcctCreated" [nzSpan]="(isMobile$ | async) ? 12 : 6">
                <nz-card class="text-center h-100" role="button"
                         [ngClass]="{'border border-info': accountType === accountOpeningConstant.PERSONAL}"
                         (click)="accountTypeOnChange('personal')">
                    <img [src]="ACCOUNT_IMG_PATH + 'personal.svg'" alt="personal account icon">
                    <h4 class="text-lg mt-2">Personal Account</h4>
                </nz-card>
            </nz-col>
            <nz-col *ngIf="!isLoggedIn" [nzSpan]="(isMobile$ | async) ? 12 : 6">
                <nz-card class="text-center" role="button"
                         [ngClass]="{'border border-info': accountType === accountOpeningConstant.BENEFICIARY}"
                         (click)="accountTypeOnChange('beneficiary')">
                    <img [src]="ACCOUNT_IMG_PATH + 'corporate.svg'" alt="beneficiary account icon">
                    <h4 class="mt-2">Beneficiary Account</h4>
                </nz-card>
            </nz-col>
            <nz-col *ngIf="!isLoggedIn" [nzSpan]="(isMobile$ | async) ? 12 : 6">
                <nz-card class="text-center" role="button"
                         [ngClass]="{'border border-info': accountType === accountOpeningConstant.CORPORATE}"
                         (click)="accountTypeOnChange('corporate')">
                    <img [src]="ACCOUNT_IMG_PATH + 'corporate.svg'" alt="corporate account icon">
                    <h4 class="mt-2">Corporate Account</h4>
                </nz-card>
            </nz-col>
            <nz-col *ngIf="!isLoggedIn" [nzSpan]="(isMobile$ | async) ? 12 : 6">
                <nz-card class="text-center" role="button"
                         [ngClass]="{'border border-info': accountType === accountOpeningConstant.CORPORATE}"
                         (click)="accountTypeOnChange('corporate')">
                    <img [src]="ACCOUNT_IMG_PATH + 'corporate.svg'" alt="corporate account icon">
                    <h4 class="mt-2">Corporate Account</h4>
                </nz-card>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
  
<!--<ng-template #openAccountTitle>-->
<!--  <div class="p-3">-->
<!--    <h4 class="mb-4"><span translate="fsmone.choose.the.statement.that.best.describes.you" translate-default="Choose the statement that best describes you"></span></h4>-->
<!-- -->
<!--    <nz-row nzGutter="16">-->
<!--      <nz-col *ngIf="!isPersonalAcctCreated" [nzSpan]="(isMobile$ | async) ? 12 : 8">-->
<!--        &lt;!&ndash;  <nz-card class="text-center" role="button"-->
<!--                 [ngClass]="{'border border-info': accountType === AccountOpeningConstant.PERSONAL}"-->
<!--                 (click)="accountTypeOnChange('personal')">&ndash;&gt;-->
<!--        <nz-card class="text-center" role="button"-->
<!--                 (click)="accountTypeOnChange('personal')">-->
<!--                          -->
<!--          <img [src]="ACCOUNT_IMG_PATH + 'personal.svg'" alt="personal account icon">-->
<!--          <h4 class="mt-2"><span translate="fsmone.account.type.personal" translate-default="Personal Account"></span></h4>-->
<!--        </nz-card>-->
<!--      </nz-col>-->
<!--      <nz-col *ngIf="!isLoggedIn" [nzSpan]="(isMobile$ | async) ? 12 : 8">-->
<!--       &lt;!&ndash; <nz-card class="text-center" role="button"-->
<!--                   [ngClass]="{'border border-info': accountType === AccountOpeningConstant.CORPORATE}"-->
<!--                 (click)="accountTypeOnChange('corporate')">&ndash;&gt;  -->
<!--                 <nz-card class="text-center" role="button"-->
<!--                 (click)="accountTypeOnChange('beneficiary')">-->
<!--          <img [src]="ACCOUNT_IMG_PATH + 'bene.svg'" alt="beneficiary account icon">-->
<!--          <h4 class="mt-2"><span translate="fsmone.account.type.beneifical" translate-default="Beneficiary Account"></span></h4>-->
<!--        </nz-card>-->
<!--      </nz-col>-->
<!--      <nz-col *ngIf="!isLoggedIn" [nzSpan]="(isMobile$ | async) ? 12 : 8">-->
<!--        &lt;!&ndash;  <nz-card class="text-center" role="button"-->
<!--               [ngClass]="{'border border-info': accountType === AccountOpeningConstant.BENEFICIARY}"-->
<!--                 (click)="accountTypeOnChange('beneficiary')">&ndash;&gt;-->
<!--                 <nz-card class="text-center" role="button"-->
<!--                 (click)="accountTypeOnChange('discretionary')">-->
<!--          <img [src]="ACCOUNT_IMG_PATH + 'corporate.svg'" alt="corporate account icon">-->
<!--          <h4 class="mt-2"><span translate="fsmone.account.type.discretionary" translate-default="Discretionary Account"></span></h4>-->
<!--        </nz-card>-->
<!--      </nz-col>-->
<!--    </nz-row>-->
<!--  </div>-->
<!--</ng-template>-->

<!--
<nz-modal (nzOnCancel)="onCloseSetupOtpMsgModal()"
          [nzClosable]="true"
          [nzFooter]="null"
          [nzVisible]="showSetupOtpMsgModal"
          nzWidth=500
          nzWrapClassName="vertical-center-modal">
  <i class="text-warning d-inline-block text-lg" nz-icon nzTheme="fill" nzType="exclamation-circle"></i>
  <h3 class="d-inline-block pt-1 pl-2">Error</h3>
  <p>Kindly Proceed to setup OTP before you open an additional account</p>
  <div class="text-right">
    <button
      nz-button
      nzType="theme"
      (click)="navigateToSetupOTP()">
      Setup OTP Now
    </button>
  </div>
</nz-modal>

<ng-template #loadingTemplate>
  <div class="container">
    <nz-skeleton [nzLoading]="isLoading" [nzActive]="true"></nz-skeleton>
  </div>
</ng-template>

-->
