<div class="d-flex">
  <div [class.mx-auto]="position === 'center'">
    <div class="rounded-icon-wrapper rounded-circle border" [ngClass]="{'no-shadow': shadow == false}">
      <div class="w-100 h-100 d-flex">
        <img height="60" class="m-auto" [src]="imgPath" alt="rounded-icon" />
      </div>
    </div>
  </div>
</div>
<div [class.text-center]="position === 'center'">
  <p class="title-font" [innerHTML]="description"></p>
  <p class="details-font" *ngIf="details">{{details}}</p>
</div>


