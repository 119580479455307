import {Injectable} from "@angular/core";
import {Store} from '@ngxs/store';
import {AuthConstant} from 'src/app/constant';
import {LocalStorageConstant} from 'src/app/constant/local.storage.constant';
import {getStorage, removeItemFromStorage, setStorage} from '@util/local-storage';
import {AuthState, AuthStateModel, ResumeAuth, Logout} from '@auth-module/store';
import {concatMap, skip, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { Idle } from '@ng-idle/core';

@Injectable({
  providedIn: 'root'
})
export class SyncAuthService 
{
	broadcastChannel: BroadcastChannel;	
	
	constructor(private store: Store, 
	private idle: Idle)
	{

	}

  	start() 
	{
		const authContext: AuthStateModel = this.getStoredAuthSession();
    	let dispatchAction$: Observable<any> = of(null);
    	let skipCount: number = 0;

		if (!this.store.selectSnapshot(AuthState.isAuthenticated) && authContext && (authContext.authenticated || authContext.authByToken))
		{
      		//console.info('[start] Auth context at local storage detected, resuming session.');
			dispatchAction$ = this.store.dispatch(new ResumeAuth(authContext));
			skipCount = 1;
		}

		//console.info('[start] checkBroadcastChannelAvailability()');
		//console.info(this.checkBroadcastChannelAvailability());

	    if (this.checkBroadcastChannelAvailability()) 
		{
			this.broadcastChannel = new BroadcastChannel(AuthConstant.CHANNEL_NAME);
			
			//console.info('[start] this.broadcastChannel');
			//console.info(this.broadcastChannel);
			
			this.broadcastChannel.onmessage = ((event: MessageEvent) => this.logoutCurrentTab(event));
    	}

		dispatchAction$.pipe
		(
      		concatMap(() => this.syncState(skipCount))
    	).subscribe();
  	}

	private syncState(skipCount = 0) 
	{
		return this.store.select(AuthState.getAuthData).pipe(
		skip(skipCount),
			tap((state: AuthStateModel) => {
				try
				{
      				//console.info('[syncState] state..');
      				//console.info(state);

					if (state && (state.authenticated || state.authByToken)) 
					{
					    //console.info('[syncState] Setting auth data to storage..');
						setStorage(LocalStorageConstant.AUTH_DATA, state);
					}
					else 
					{
			            const storedAuthSession = this.getStoredAuthSession();
            			
          				//console.info('[syncState] ELSE this.broadcastChannel..');
          				//console.info(this.broadcastChannel);

          				//console.info('[syncState] ELSE storedAuthSession..');
          				//console.info(storedAuthSession);

						if (this.broadcastChannel && storedAuthSession && (storedAuthSession.authenticated || storedAuthSession.authByToken)) 
						{						
	          				//console.info('[syncState] Blast message to logout out all tabs..');
	         				this.broadcastChannel.postMessage(AuthConstant.LOGOUT_ALL_TAB);
							
						    //console.info('[syncState] Removing auth data from storage..');
						    removeItemFromStorage(LocalStorageConstant.AUTH_DATA);
						}
					}
		        }
		        catch(err) 
				{
					console.error('Error in syncing auth state: ', err);
		        }
			})
		);
	}

	private getStoredAuthSession() 
	{
		return getStorage(LocalStorageConstant.AUTH_DATA);
	}

	private checkBroadcastChannelAvailability(): boolean 
	{
		const win: any = window;
		return !!win.BroadcastChannel;
	}

	private logoutCurrentTab(evt: MessageEvent) 
	{
    	//console.info('[logoutCurrentTab] Log out at another tab detected, logging out..');

    	const authState: any = this.store.selectSnapshot(AuthState.isAuthenticated);
    	const authStateCas: any = this.store.selectSnapshot(AuthState.isCasAuthenticated);

	    //console.info('[logoutCurrentTab] authState ?');
		//console.info(authState);

		if ((authStateCas || authState) && evt.data === AuthConstant.LOGOUT_ALL_TAB) 
		{
		    //console.info('[logoutCurrentTab] Dispatch empty state..');
			const actions: Array<any> = [Logout];
		    this.store.dispatch(actions.map((action => new action())));	
			this.idle.stop();

		    //console.info('[logoutCurrentTab] Removing auth data from storage..');
		    removeItemFromStorage(LocalStorageConstant.AUTH_DATA);		
    	}
  	}

	rememberUsername(username: string) 
	{
		setStorage(LocalStorageConstant.USER_ID, username);
	}

	forgetUsername() 
  	{
		removeItemFromStorage(LocalStorageConstant.USER_ID);
	}

 	getUsername()
  	{
		return getStorage(LocalStorageConstant.USER_ID)
	}
}
