<div style="background-color:#f3e6e6">
<div class="p-4">
<h4 class="mb-3" translate="fsmone.risk.disclosure.statements.for.bonds" translate-default="Risk Disclosure Statements for Bonds"></h4>

<h6 class="font-weight-semibold" translate="fsmone.key.risks.of.investing.in.bonds" translate-default="Key risks of investing in bonds"></h6>
<ul class="listing">
	<li><h6 translate="fsmone.key.risks.of.investing.in.bonds.1" translate-default="Credit risk - bonds are subject to the risk of the issuer defaulting on its obligations. It should also be noted that credit ratings assigned by credit rating agencies do not guarantee the creditworthiness of the issuer;"></h6></li>
	<li><h6 translate="fsmone.key.risks.of.investing.in.bonds.2" translate-default="Liquidity risk - some bonds may not have active secondary markets and it would be difficult or impossible for investors to sell the bond before its maturity; and"></h6></li>
	<li><h6 translate="fsmone.key.risks.of.investing.in.bonds.3" translate-default="Interest rate risk - bonds are more susceptible to fluctuations in interest rates and generally prices of bonds will fall when interest rates rise; and"></h6></li>
	<li><h6 translate="fsmone.key.risks.of.investing.in.bonds.4" translate-default="Exchange rate risk - If the bond is denominated in a foreign currency, you face an exchange rate risk. Any fall in the foreign currency will reduce the amount you receive when you convert a payment of interest or principal back into your local currency; and"></h6></li>
	<li><h6 translate="fsmone.key.risks.of.investing.in.bonds.5" translate-default="Event risk - A corporate event such as a merger or takeover may lower the credit rating of the bond issuer. In case the corporate restructurings are financed by the issuance of a large amount of new debt-burden, the company's ability to pay off existing bonds will be weakened."></h6></li>
</ul>

<h6 class="font-weight-semibold" translate="fsmone.key.risks.of.investing.in.high.yield.bonds" translate-default="Key risks of investing in high-yield bonds"></h6>
<ul class="listing">
	<li><h6 translate="fsmone.key.risks.of.investing.in.high.yield.bonds.1" translate-default="Higher credit risk - since they are typically rated below investment grade or are unrated and as such are often subject to a higher risk of issuer default;"></h6></li>
	<li><h6 translate="fsmone.key.risks.of.investing.in.high.yield.bonds.2" translate-default="Vulnerability to economic cycles - during economic downturns such bonds typically fall more in value than investment grade bonds as (i) investors become more risk averse and (ii) default risk rises."></h6></li>
</ul>

<h6 class="font-weight-semibold" translate="fsmone.bonds.with.special.features" translate-default="Bonds with special features"></h6>
<h6 translate="fsmone.some.bonds.may.contain.special.features.and.risks.that" translate-default="Some bonds may contain special features and risks that warrant special attention. These include bonds:"></h6>
<ul class="listing">
	<li><h6 translate="fsmone.bonds.with.special.features.1" translate-default="That are perpetual in nature and interest pay-out depends on the viability of the issuer in the very long term;"></h6></li>
	<li><h6 translate="fsmone.bonds.with.special.features.2" translate-default="That have subordinated ranking and in case of liquidation of the issuer, investors can only get back the principal after other senior creditors are paid;"></h6></li>
	<li><h6 translate="fsmone.bonds.with.special.features.3" translate-default="That are callable and investors face reinvestment risk when the issuer exercises its right to redeem the bond before it matures;"></h6></li>
	<li><h6 translate="fsmone.bonds.with.special.features.4" translate-default="That have variable and/or deferral of interest payment terms and investors would face uncertainty over the amount and time of the interest payments to be received;"></h6></li>
	<li><h6 translate="fsmone.bonds.with.special.features.5" translate-default="That have extendable maturity dates and investors would not have a definite schedule of principal repayment;"></h6></li>
	<li><h6 translate="fsmone.bonds.with.special.features.6" translate-default="That are convertible or exchangeable in nature and investors are subject to both equity and bond investment risk; and/or"></h6></li>
	<li><h6 translate="fsmone.bonds.with.special.features.7" translate-default="That have contingent write down or loss absorption feature and the bond may be written-off fully or partially or converted to common stock on the occurrence of a trigger event."></h6></li>
</ul>

<h6 class="font-weight-semibold" translate="fsmone.remarks" translate-default="Remarks"></h6>
<ul class="listing">
	<li><h6 translate="fsmone.risk.disclosure.remarks.1" translate-default="Warning for bonds that are unauthorised by SFC: The contents of this document have not been reviewed by any regulatory authority in Hong Kong. You are advised to exercise caution in relation to the offer. If you are in any doubt about any of the contents of this document, you should obtain professional advice."></h6></li>
	<li><h6 translate="fsmone.risk.disclosure.remarks.2" translate-default="SFC authorization is not a recommendation or endorsement of a product nor does it guarantee the commercial merits of a product or its performance. It does not mean the product is suitable for all investors nor is it an endorsement of its suitability for any particular investor or class of investors"></h6></li>
	<li><h6 translate="fsmone.risk.disclosure.remarks.3" translate-default="These quotes are only indicative prices and are subject to change"></h6></li>
</ul>
</div>
</div>
