import { EventEmitter, Injectable } from '@angular/core';
import { TaChartStatus } from '@share/models/stocks/stock-ta-chart.model';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StockService {
	orderpadVisible = false;
	updateVisibleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	orderpadInfo: any;
	updateOrderpadInfoEvent: EventEmitter<any> = new EventEmitter<any>();

	isSubscribedLs = false;
	currentLiveSnapStockObj = {
		exchange: '',
		stockCode: '',
		msInstrumentId: ''
	}
	
	liveSnapProcessingCallback: EventEmitter<any> = new EventEmitter<any>();
	liveSnapErrorFallBack: EventEmitter<any> = new EventEmitter<any>();

	factsheetData = new BehaviorSubject<any>({});
	orderpadFactsheetData = new BehaviorSubject<any>({});

	streamingInfo: EventEmitter<any> = new EventEmitter<any>();

	orderSubmittedEvent: EventEmitter<void> = new EventEmitter<void>();

	reloadOrderpad : EventEmitter<any> = new EventEmitter<any>();

	usLivePriceActivateLaterEvent: EventEmitter<any> = new EventEmitter<any>();

	priceChangeEvent: EventEmitter<PriceChangeEvent> = new EventEmitter<PriceChangeEvent>();

	statusUpdateEvent: EventEmitter<TaChartStatus> = new EventEmitter<TaChartStatus>();

	private status: TaChartStatus;

  refreshWatchlistEmitter: EventEmitter<void> = new EventEmitter<void>();

	constructor(
	) {
		this.updateVisibleEvent.subscribe((value: boolean) => {
			this.orderpadVisible = value;
		});

		this.updateOrderpadInfoEvent.subscribe((value: any[]) => {
			this.orderpadInfo = value;
		});
	}

	getOrderpadVisible() {
		return this.orderpadVisible;
	}

	getStockOrderpadInfo() {
		return this.orderpadInfo;
	}

	updateTaChartStatus(status: TaChartStatus): void {
        if (status.countdownTimer !== undefined) {
          // Not update timer if current status is no longer 'TIMER'
          if (this.status && this.status.statusType != 'TIMER') {
            return;
          }
          if(this.status) {
            status.statusType = this.status.statusType;
          }
        }
        this.status = status;

        this.statusUpdateEvent.emit(status);
    }
}
export class PriceChangeEvent {
	public symbol: string;
	public fieldName: string;
	public value: number;
	constructor(symbol: string, fieldName: string, value: number) {
	  this.symbol = symbol;
	  this.fieldName = fieldName;
	  this.value = value;
	}
}
