import { Component, OnInit } from '@angular/core';
import { AccountUpdateRestService } from '@api-module/rest/account-update.rest.service';
import { WatchlistRestService } from '@api-module/rest/watchlist.rest.service';
import { AppConstant } from '@constant';
import { TranslateService } from '@ngx-translate/core';
import { StockService } from '@share/services/stock/stock.service';
import { GlobalDataStorage } from '@share/storages/global-data.storage';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import * as _ from 'lodash';


@Component({
  selector: 'app-auto-add-watchlist-modal',
  templateUrl: './auto-add-watchlist-modal.component.html',
  styleUrls: ['./auto-add-watchlist-modal.component.scss']
})
export class AutoAddWatchlistModalComponent implements OnInit {
  isLoading = false;
  stockAutoAddWatchlist = this.globalDataStorage.stockAutoAddWatchlist;
  stockDefaultWatchlist = this.globalDataStorage.stockDefaultWatchlist;
  watchlist = [];
  isLoadingWatchlist = false;
  createNewWatchlistVisible = false;
  isCreateNewWatchlistLoading: boolean;
  newWatchlistName: string ='';

  constructor(
    private globalDataStorage: GlobalDataStorage,
    private watchlistRestService: WatchlistRestService,
    private accountUpdateService: AccountUpdateRestService,
    private modalService: NzModalService,
    private translate: TranslateService,
    private stockService: StockService,
    private modal: NzModalRef,
  ) { }

  ngOnInit(): void {
    this.findAllWatchlist();
  }

  findAllWatchlist(): void {
    this.isLoadingWatchlist = true;
    this.watchlistRestService.findWatchlistByAccountId().toPromise().then(res => {
      if (res.status === AppConstant.RESPONSE_SUCCESS) {
          this.watchlist = res.data;
          console.log(this.watchlist);
      }
    }).finally(() => {
      this.isLoadingWatchlist = false;
    });
  }

  toggleCreateNewWatchlist(state: boolean) {
    this.createNewWatchlistVisible = state;
    this.newWatchlistName = '';
  }

  createNewWatchlist() {
    if (typeof this.newWatchlistName === 'string' && this.newWatchlistName.length > 0) {
      this.isCreateNewWatchlistLoading = true;
      // console.log('auto add watchlist newWatchlistName' , this.newWatchlistName)
      // return
       this.watchlistRestService.addWatchlist(this.newWatchlistName).subscribe(
       () => {
         this.modalService.success({
           nzWrapClassName: 'vertical-center-modal modal-footer-shadow full-screen-md',
           nzContent:  this.translate.instant('fsmone.successfully.created.new.watchlist'),
           nzOnOk: () =>  {
             this.newWatchlistName = '';
             this.createNewWatchlistVisible = false;
             this.findAllWatchlist();
             this.stockService.refreshWatchlistEmitter.emit();
           }
         })
       },
       (error) => {
         this.modalService.error({
           nzWrapClassName: 'vertical-center-modal modal-footer-shadow full-screen-md',
           nzContent: this.translate.instant('watchlist.add.error')
         })
       }
     ).add(() => {
       this.isCreateNewWatchlistLoading = false;
     });
    } else {
       this.modalService.error({
        nzWrapClassName: 'vertical-center-modal modal-footer-shadow full-screen-md',
         nzContent: this.translate.instant('fsmone.please.enter.watchlist.name')
       })
    }
  }

  save(): void {
    if (this.stockAutoAddWatchlist == 'Y' && (_.every(this.watchlist, item => item.listId !== this.stockDefaultWatchlist) || this.stockDefaultWatchlist == 'null')) {
      this.modalService.info({
        nzWrapClassName: 'vertical-center-modal modal-footer-shadow full-screen-md',
        nzTitle: this.translate.instant("fsmone.please.select.watchlist.folder")
      });
      return;
    }
    this.isLoading = true;
    this.accountUpdateService.updateClientStockWatchlistPreference(this.stockAutoAddWatchlist, this.stockDefaultWatchlist).subscribe(result => {
      if (result.status === AppConstant.RESPONSE_SUCCESS) {
        this.globalDataStorage.stockAutoAddWatchlist = this.stockAutoAddWatchlist;
        this.globalDataStorage.stockDefaultWatchlist = this.stockDefaultWatchlist;
      }
    },
    error => {},
    () => {
      this.isLoading = false;
      this.modal.destroy(true);
    }
  )};

  closeModal() {
    this.modal.destroy();
  }

}
