export class ImagePathConstant {
  public static readonly BASE_PATH: string = "assets/images";

  public static readonly FUND_HOUSE_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/fund-house/';
  public static readonly FUND_SELECTOR_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/fund-selector/';
  public static readonly FUND_BASIC_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/fund-basic/';
  public static readonly ARTICLES_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/articles/';
  public static readonly FUND_FINDER_IMAGE_PATH: string = ImagePathConstant.BASE_PATH + '/fund-finder/';
  public static readonly CASH_MANAGEMENT_FUND_IMG_PATH = ImagePathConstant.BASE_PATH + '/cash-management-fund/';
  public static readonly BOND_101_IMG_PATH = ImagePathConstant.BASE_PATH + '/bond-101/';
  public static readonly FIND_MY_FUNDS_IMG_PATH = ImagePathConstant.BASE_PATH + '/find-my-funds/';
  public static readonly FIND_MY_STOCK_IMG_PATH = ImagePathConstant.BASE_PATH + '/find-my-stock/';
  public static readonly FIND_MY_ETFS_IMG_PATH = ImagePathConstant.BASE_PATH + '/find-my-etfs/';
  public static readonly TRANSFER_IN_PROGRAM = ImagePathConstant.BASE_PATH + '/transfer-in-program/';
  public static readonly FIND_MY_PORTFOLIO = ImagePathConstant.BASE_PATH + '/find-my-portfolio/';
  public static readonly ABOUT_MP_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/about-managed-portfolio/';
  public static readonly FIND_MY_BOND_IMG_PATH = ImagePathConstant.BASE_PATH + '/find-my-bond/';
  public static readonly BANNER_IMAGE = ImagePathConstant.BASE_PATH + '/landing/';
  public static readonly DPMS_INVESTMENT_METHODOLOGY_IMG_PATH = ImagePathConstant.BASE_PATH + '/dpms-investment-methodology/';
  public static readonly FEEDBACK_IMAGE_PATH = ImagePathConstant.BASE_PATH + '/feedback/';
  public static readonly BOND_FACTSHEET_IMAGE_PATH = ImagePathConstant.BASE_PATH + '/bond-factsheet/';
  public static readonly TRANSACTION_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/transaction/';
  public static readonly ACCOUNT_OPENING_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/account-opening/';
  public static readonly BOND_LANDING_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/landing/';
  public static readonly ORDER_PLACE_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/order-placed/';
  public static readonly ENQUIRY_FORM_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/enquiry-form/';
  public static readonly TRANSACTION_SELECTOR_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/transaction-selector/';
  public static readonly CONTACT_US_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/contact-us/';
  public static readonly GET_INVESTMENT_ADVICE_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/get-investment-advice/';
  public static readonly GENERAL_LANDING_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/landing/';
  public static readonly USER_PANEL_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/user-panel/';
  public static readonly UPLOAD_BOX_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/upload-box/';
  public static readonly ACCOUNT_SELECTOR_PATH: string = ImagePathConstant.BASE_PATH + '/account-selector/';
  public static readonly CASH_ACCOUNT_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/cash-account/';
  public static readonly ACCOUNT_UPDATE_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/account-update/';
  public static readonly STOCKS_IMG_PATH :string = ImagePathConstant.BASE_PATH + '/stocks/';
  public static readonly STOCKS_101_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/stocks-101/';
  public static readonly PRS_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/prs/';
  public static readonly INSURANCE_LANDING_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/landing/';
  public static readonly INSURANCE_101_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/insurance-101/';
  public static readonly INSURANCE_PLAN_INFORMATION_PATH: string = ImagePathConstant.BASE_PATH + '/insurance-plan-information/';
  public static readonly FIND_MY_INSURANCE_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/find-my-insurance/';
  public static readonly HOLDINGS_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/holdings/';
  public static readonly SHARED_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/shared/';
  public static readonly BOND_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/bond/';
  public static readonly REFERRAL_PROGRAM_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/referral-program/';
  public static readonly RECOMMENDED_FUNDS_PATH: string = ImagePathConstant.BASE_PATH + '/recommended-funds/';
  public static readonly ETFS_101_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/etfs-101/';
  public static readonly ETFS_FOCUS_LIST: string = ImagePathConstant.BASE_PATH + '/etfs-focus-list/';
  public static readonly WATCHLIST_IMG_PATH: string = ImagePathConstant.BASE_PATH + '/watchlist/';
}
