import { SubscribeBarsCallback, Bar } from 'src/assets/charting_library_v2/datafeed-api';
import { LightStreamerService } from './light-streamer.service';
import { StocksInfoRestService } from '@api-module/rest/stocks-info.rest.service';
import { StockTransactionRestService } from '@api-module/rest/stock.transaction.rest.service';
import { TaChartSubscription, TaChartUpdater } from '@share/models/stocks/stock-ta-chart.model';
import { StockService } from '@share/services/stock/stock.service';
import { LocalStorageConstant } from 'src/app/constant/local.storage.constant';
import { getStorage, setStorage } from '@util/local-storage';

export class StockTaChartUpdaterDailyStreaming implements TaChartUpdater {

  private module: string;
  private msInstrumentId: string;
  private exchange: string;
  private stockCode: string;
  private onTick: SubscribeBarsCallback;
  private stockService: StockService;
  private stocksInfoRestService: StocksInfoRestService;
  private stockTransactionRestService: StockTransactionRestService;
  private lightStreamerService: LightStreamerService;
  private priceQuote = {};
  private level: string;

  constructor(module: string, subscription: TaChartSubscription, stockService: StockService, stocksInfoRestService: StocksInfoRestService, stockTransactionRestService: StockTransactionRestService, lightStreamerService: LightStreamerService, isLive: boolean) {
    this.module = module;
    this.stockService = stockService;
    this.msInstrumentId = subscription.symbolInfo.msInstrumentId || '';
    this.onTick = subscription.onTick;
    this.stocksInfoRestService =  stocksInfoRestService;
    this.stockTransactionRestService = stockTransactionRestService;
    this.exchange = subscription.symbolInfo.exchange || '';
    this.stockCode = subscription.symbolInfo.stockCode || '';
    this.level = isLive ? 'L1' : 'DELAY';
    this.lightStreamerService = lightStreamerService;
  }

  subscribeBars(): void {
    if (!this.msInstrumentId || !this.onTick || !this.stocksInfoRestService) {
      return;
    }

    this.stockTransactionRestService.getTickerInfoByExchangeAndCode(this.exchange, this.stockCode).subscribe((stocks) => {
      if (!stocks || !stocks.data) {
        return;
      }

      // Initial update of price
      this.updatePrice();

      // Subscribe for future updates of price
      this.subscribePriceQuote(this.level, this.msInstrumentId);
    });
  }

  private updatePrice(): void {
    let now = new Date();
    now.setUTCHours(0);
    now.setUTCMinutes(0);
    now.setUTCSeconds(0);
    now.setUTCMilliseconds(0);
    // Skip update on weekends
    let dayOfWeek = now.getUTCDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return;
    }

    // Update bar in chart
    let bar = {
      time: now.getTime(),
      volume: 0,
      open: 0,
      high: 0,
      low: 0,
      close: 0
    }

    let targetStockQuote = this.priceQuote['PRICE_QUOTE-' + this.level + '-' + this.msInstrumentId];
    if (targetStockQuote) {
      bar = {
        time: now.getTime(),
        volume: parseFloat(targetStockQuote.cumulativeVolume),
        open: parseFloat(targetStockQuote.openPrice),
        high: parseFloat(targetStockQuote.dayHigh),
        low: parseFloat(targetStockQuote.dayLow),
        close: !this.isEmpty(targetStockQuote.lastPrice) ? parseFloat(targetStockQuote.lastPrice) : !this.isEmpty(targetStockQuote.closePrice) ? parseFloat(targetStockQuote.closePrice) : parseFloat(targetStockQuote.askPrice)
      }
    }

    if (bar.open === 0) {
      return;
    }

    var lastUpdateDate = (now.getTime()/1000).toString();
    if (getStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE) !== lastUpdateDate){
      setStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE, lastUpdateDate)
    }
    this.onTick(bar);
  }

  unsubscribeBars(): void {
    if (!this.msInstrumentId || !this.lightStreamerService) {
     return;
    }
    this.lightStreamerService.unsubscribePriceQuoteWithModule(this.module, [this.msInstrumentId]);
  }

  private subscribePriceQuote(snapLevel, msInstrumentId): any {
    if (this.stockService.isSubscribedLs) {
      this.lightStreamerService.subscribePriceQuoteWithModule(this.module, snapLevel, [msInstrumentId], this.priceQuote, {
        updateCallback: (data) => {
          this.updatePrice();
        }
      });
    }
  }

  isEmpty(value) {
    return (!value || Number(value) === 0);
  }
}
