import { HeaderMetaModel } from 'src/app/share/models/header/header.model';
import {AppConstant, RouteConstant} from 'src/app/constant';
import {genRouterLink} from '@util/route';

export const PostMenu: Array<HeaderMetaModel> = [
	{
		title: 'MY ACCOUNT',
		titleKey: 'fsmone.my.account',
		url: genRouterLink('holdings','investment'),
		clickable: true,
		icon: 'user',
		children: [
			{
				title: 'Investment Holdings',
				titleKey: 'fsmone.investment.holdings',
				url: genRouterLink('holdings','details/'),
			},
			{
				title: 'Regular Saving Plan',
				titleKey: 'fsmone.regular.saving.plan',
				url: genRouterLink('my-rsp','main'),
			},
			{
				title: 'Cash Account',
				titleKey: 'fsmone.cash.account',
				url: genRouterLink('cash-account','cash-account-holding-summary'),
			},
			{
				title: 'Transaction History',
				titleKey: 'fsmone.historical.transaction',
				url: genRouterLink('holdings','historical','investment/'),
			},
			{
				title: 'Processing/Pending Transaction',
				titleKey: 'fsmone.processing.pending.transaction',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Monthly Statement',
				titleKey: 'fsmone.monthly.statement',
				url: genRouterLink('monthly-statement'),
			},
			{
				title: 'DPMS Valuation Report',
				titleKey: 'fsmone.valuation.report',
				url: genRouterLink('holdings','valuation-report')
			},
			{
				title: 'Platform Fee Enquiry',
				titleKey: 'fsmone.platform.fee.enquiry',
				url: genRouterLink('fee','platform-fee-enquiry'),
			}
		]
	},
	{
		title: 'FUNDS',
		titleKey: 'fsmone.post.login.menu.funds.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Buy',
				titleKey: 'fsmone.buy',
				url: genRouterLink('transaction','transact-now?transType=buy&prodType=UT'),
			},
			{
				title: 'Sell',
				titleKey: 'fsmone.sell',
				url: genRouterLink('transaction','transact-now?transType=sell&prodType=UT'),
			},
			{
				title: 'Switch',
				titleKey: 'fsmone.switch',
				url: genRouterLink('transaction','transact-now?transType=switch&prodType=UT'),
			},
			{
				title: 'Regular Saving Plan Application',
				titleKey: 'fsmone.regular.saving.plan.application',
				url: genRouterLink('transaction','transact-now?transType=rsp&prodType=UT'),
			},
			{
				title: 'Regular Saving Plan Amendment/Termination',
				titleKey: 'fsmone.regular.saving.plan.amendment',
				url: genRouterLink('my-rsp','main'),
			},
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink('cash-account','cash-account-edda-application'),
			},
			{
				title: 'Cancel Order',
				titleKey: 'fsmone.cancel.order',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transaction','transfer-in-funds'),
			}
		]
	},
	{
		title: 'CASH ACCOUNT',
		titleKey: 'fsmone.cash.account',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink('cash-account','cash-account-edda-application'),
			},
			{
				title: 'Deposit with eDDA',
				titleKey: 'fsmone.deposti.with.edda',
				url: genRouterLink('cash-account','cash-account-edda-deposit'),
			},
			{
				title: 'Deposit (PPS Payment)',
				titleKey: 'fsmone.deposit.pps.payment',
				url: genRouterLink('cash-account','cash-account-deposit'),
			},
			{
				title: 'Deposti with FPS (QR code)',
				titleKey: 'fsmone.deposit.fps.payment',
				url: genRouterLink('cash-account','cash-account-deposit-fps'),
			},
			{
				title: 'WithDrawal',
				titleKey: 'fsmone.withdrawal',
				url: genRouterLink('cash-account','cash-account-withdrawal'),
			},
			{
				title: 'Cash Transfer',
				titleKey: 'fsmone.cash.account.transfer',
				url: genRouterLink('cash-account','cash-account-transfer'),
			},
			{
				title: 'FX Conversion',
				titleKey: 'fsmone.fx.conversion',
				url: genRouterLink('cash-account','cash-account-fx'),
			},
			{
				title: 'Upload Payment Proof',
				titleKey: 'fsmone.upload.payment.proof',
				url: genRouterLink('cash-account','upload-transaction-record'),
			},
			{
				title: 'Cancel Order',
				titleKey: 'fsmone.cancel.order',
				url: genRouterLink(''),
			},
			{
				title: 'Historical Transaction',
				titleKey: 'fsmone.historical.transaction',
				url: genRouterLink('holdings','historical','cash-account-transaction-history'),
			}
		]
	},
	{
		title: 'BONDS',
		titleKey: 'fsmone.menu.bonds',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Buy',
				titleKey: 'fsmone.buy',
				url: genRouterLink('bond-transaction','buy/'),
			},
			{
				title: 'Sell',
				titleKey: 'fsmone.sell',
				url: genRouterLink('bond-transaction','sell'),
			},
			{
				title: 'Bond Express',
				titleKey: 'fsmone.bond.express',
				url: genRouterLink('bonds','Bond-Express'),
			},
			{
				title: 'Newly Issued Bond',
				titleKey: 'fsmone.newly.issued.bond',
				url: genRouterLink('bonds','initial-public-offerings'),
			},
			{
				title: 'Amend/Cancel Processing Order',
				titleKey: 'fsmone.amend.cancel.processing.order',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transfer-in-bonds'),
			},
			{
				title: 'Corporate Action',
				titleKey: 'fsmone.bond.corporate.action',
				url: genRouterLink('bond-transaction', 'corporate-action'),
			}
		]
	},
	{
		title: 'Managed Portfolios',
		titleKey: 'fsmone.managed.portfolios',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Subscription/Top Up',
				titleKey: 'fsmone.subscription.top.up',
				url: genRouterLink('transaction','transact-now?transType=buy&prodType=DPMS'),
			},
			{
				title: 'Termination and Partial Redemption',
				titleKey: 'fsmone.termination.and.partial.redemption',
				url: genRouterLink('transaction','transact-now?transType=sell&prodType=DPMS'),
			},
			{
				title: 'RSP Amendment/Suspension',
				titleKey: 'fsmone.rsp.amendment.suspension',
				url: genRouterLink('my-rsp','edit-rsp-portfolio'),
			},
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink('cash-account','cash-account-edda-application'),
			},
			{
				title: 'Cancel Order',
				titleKey: 'fsmone.cancel.order',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Factsheets',
				titleKey: 'fsmone.factsheets',
				url: genRouterLink('dpms', 'portfolio-materials/factsheets'),
			},
		]
	},
	{
		title: 'HK Stocks & Etfs',
		titleKey: 'fsmone.post.login.menu.hk.stocks.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Trade',
				titleKey: 'fsmone.trade',
				url: genRouterLink('trade','stock'),
			},
			{
				title: 'Stock IPOs Subscription',
				titleKey: 'fsmone.stock.ipo.subscription',
				url: genRouterLink('stocks','stock-ipo-listing'),
			},
			{
				title: 'View applied Stock IPOs',
				titleKey: 'fsmone.view.applied.ipo',
				url: genRouterLink('ipo-subscription','subscription-listing'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transaction','transfer-in-stocks?market=HKEX'),
			},
			// {
			// 	title: 'Regular Saving Plan Application',
			// 	titleKey: 'fsmone.regular.saving.plan.application',
			// 	url: genRouterLink(''),
			// },
			// {
			// 	title: 'Regular Saving Plan Amendment/Termination',
			// 	titleKey: 'fsmone.regular.saving.plan.amendment',
			// 	url: genRouterLink(''),
			// },
			// {
			// 	title: 'DDA Application (Standalone)',
			// 	titleKey: 'fsmone.dda.application',
			// 	url: genRouterLink(''),
			// }
		]
	},
	{
		title: 'US Stocks & Etfs',
		titleKey: 'fsmone.post.login.menu.us.stocks.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Trade',
				titleKey: 'fsmone.trade',
				url: genRouterLink('trade','us-stock'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transaction','transfer-in-stocks?market=US'),
			}
		]
	},
	{
		title: 'China A Shares',
		titleKey: 'fsmone.post.login.menu.cn.stocks.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Trade',
				titleKey: 'fsmone.trade',
				url: genRouterLink('trade','main','stock','CN'),
			}
		]
	},
	{
		title: 'SG Stocks & Etfs',
		titleKey: 'fsmone.post.login.menu.sg.stocks.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Trade',
				titleKey: 'fsmone.trade',
				url: genRouterLink('trade','main','stock','SG'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transaction','transfer-in-stocks?market=SGX'),
			}
		]
	}
]

export const AccountSetting = [
	{
		title: 'Account Setting',
		titleKey: 'fsmone.menu.account.settings',
		url: genRouterLink('account-setting'),
		clickable: true,
		children: [
			{
				title: 'Account Details Update',
				titleKey: 'fsmone.account.details.update',
				url: genRouterLink(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU,'update-account-personal-details'),
			},
			{
				title: 'Change of Address',
				titleKey: 'fsmone.change.of.address',
				url: genRouterLink(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU,'change-of-address'),
			},
			{
				title: 'Direct Credit Instruction Update',
				titleKey: 'fsmone.direct.credit.instruction.update',
				url: genRouterLink(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU,'update-bank-details'),
			},
			{
				title: 'Risk Profiling Update',
				titleKey: 'fsmone.risk.profiling.update',
				url: genRouterLink(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU,'risk-profiling-update'),
			},
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink(RouteConstant.CASH_ACCOUNT,'cash-account-edda-application'),
			},
			{
				title: 'Derivative Knowledge Assessment',
				titleKey: 'fsmone.derivative.knowledge.assessment',
				url: genRouterLink(RouteConstant.ACCOUNT_SETTING,'derivative-knowledge-assessment'),
			},
			{
				title: 'Virtual Asset Knowledge Assessment',
				titleKey: 'fsmone.virtual.asset.knowledge.assessment',
				url: genRouterLink(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU,'vaka'),
			},
			{
				title: 'Manage Two Factor Authentication',
				titleKey: 'fsmone.manage.2fa',
				url: genRouterLink('two-fa','manage-two-fa'),
			},
			{
				title: 'Open Additional Account',
				titleKey: 'fsmone.open.addtional.account',
				url: genRouterLink('account-opening','main'),
			},
			{
				title: 'Professional Investor Declaration',
				titleKey: 'fsmone.professional.investor.declaration',
				url: genRouterLink(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU,'pi-declaration'),
			},
			{
				title: 'Log out of Account',
				titleKey: 'fsmone.logout.account',
				url: genRouterLink('fsmLogout'),
			}
		]
	}
]

export const MobilePostMenu: Array<HeaderMetaModel> = [
	{
		title: 'MY ACCOUNT',
		titleKey: 'fsmone.my.account',
		url: genRouterLink(''),
		clickable: true,
		icon: 'user',
		children: [
			{
				title: 'Account Summary',
				titleKey: 'fsmone.account.summary',
				url: genRouterLink('holdings','investment'),
			},
			{
				title: 'Investment Holdings',
				titleKey: 'fsmone.investment.holdings',
				url: genRouterLink('holdings','details'),
			},
			{
				title: 'Regular Saving Plan',
				titleKey: 'fsmone.regular.saving.plan',
				url: genRouterLink('my-rsp','main'),
			},
			{
				title: 'Cash Account',
				titleKey: 'fsmone.cash.account',
				url: genRouterLink('cash-account','cash-account-holding-summary'),
			},
			{
				title: 'Transaction History',
				titleKey: 'fsmone.historical.transaction',
				url: genRouterLink('holdings','historical','investment/'),
			},
			{
				title: 'Processing/Pending Transaction',
				titleKey: 'fsmone.processing.pending.transaction',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Monthly Statement',
				titleKey: 'fsmone.monthly.statement',
				url: genRouterLink('monthly-statement'),
			},
			{
				title: 'DPMS Valuation Report',
				titleKey: 'fsmone.valuation.report',
				url: genRouterLink('holdings','valuation-report'),
			},
			{
				title: 'Platform Fee Enquiry',
				titleKey: 'fsmone.platform.fee.enquiry',
				url: genRouterLink('fee','platform-fee-enquiry'),
			}
		]
	},
	{
		title: 'FUNDS',
		titleKey: 'fsmone.post.login.menu.funds.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Buy',
				titleKey: 'fsmone.buy',
				url: genRouterLink('transaction','transact-now?transType=buy&prodType=UT'),
			},
			{
				title: 'Sell',
				titleKey: 'fsmone.sell',
				url: genRouterLink('transaction','transact-now?transType=sell&prodType=UT'),
			},
			{
				title: 'Switch',
				titleKey: 'fsmone.switch',
				url: genRouterLink('transaction','transact-now?transType=switch&prodType=UT'),
			},
			{
				title: 'Regular Saving Plan Application',
				titleKey: 'fsmone.regular.saving.plan.application',
				url: genRouterLink('transaction','transact-now?transType=rsp&prodType=UT'),
			},
			{
				title: 'Regular Saving Plan Amendment/Termination',
				titleKey: 'fsmone.regular.saving.plan.amendment',
				url: genRouterLink('my-rsp','main'),
			},
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink('cash-account','cash-account-edda-application'),
			},
			{
				title: 'Cancel Order',
				titleKey: 'fsmone.cancel.order',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transaction','transfer-in-funds'),
			}
		]
	},
	{
		title: 'CASH ACCOUNT',
		titleKey: 'fsmone.cash.account',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink('cash-account','cash-account-edda-application'),
			},
			{
				title: 'Deposit with eDDA',
				titleKey: 'fsmone.deposti.with.edda',
				url: genRouterLink('cash-account','cash-account-edda-deposit'),
			},
			{
				title: 'Deposit (PPS Payment)',
				titleKey: 'fsmone.deposit.pps.payment',
				url: genRouterLink('cash-account','cash-account-deposit'),
			},
			{
				title: 'Deposti with FPS (QR code)',
				titleKey: 'fsmone.deposit.fps.payment',
				url: genRouterLink('cash-account','cash-account-deposit-fps'),
			},
			{
				title: 'WithDrawal',
				titleKey: 'fsmone.withdrawal',
				url: genRouterLink('cash-account','cash-account-withdrawal'),
			},
			{
				title: 'Cash Transfer',
				titleKey: 'fsmone.cash.account.transfer',
				url: genRouterLink('cash-account','cash-account-transfer'),
			},
			{
				title: 'FX Conversion',
				titleKey: 'fsmone.fx.conversion',
				url: genRouterLink('cash-account','cash-account-fx'),
			},
			{
				title: 'Upload Payment Proof',
				titleKey: 'fsmone.upload.payment.proof',
				url: genRouterLink('cash-account','upload-transaction-record'),
			},
			{
				title: 'Cancel Order',
				titleKey: 'fsmone.cancel.order',
				url: genRouterLink(''),
			},
			{
				title: 'Historical Transaction',
				titleKey: 'fsmone.historical.transaction',
				url: genRouterLink('holdings','historical','cash-account-transaction-history'),
			}
		]
	},
	{
		title: 'BONDS',
		titleKey: 'fsmone.menu.bonds',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Buy',
				titleKey: 'fsmone.buy',
				url: genRouterLink('bond-transaction','buy/'),
			},
			{
				title: 'Sell',
				titleKey: 'fsmone.sell',
				url: genRouterLink('bond-transaction','sell'),
			},
			{
				title: 'Bond Express',
				titleKey: 'fsmone.bond.express',
				url: genRouterLink('bonds','Bond-Express'),
			},
			{
				title: 'Newly Issued Bond',
				titleKey: 'fsmone.newly.issued.bond',
				url: genRouterLink('bonds','initial-public-offerings'),
			},
			{
				title: 'Amend/Cancel Processing Order',
				titleKey: 'fsmone.amend.cancel.processing.order',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transfer-in-bonds'),
			},
			{
				title: 'Corporate Action',
				titleKey: 'fsmone.bond.corporate.action',
				url: genRouterLink('bond-transaction', 'corporate-action'),
			}
		]
	},
	{
		title: 'Managed Portfolios',
		titleKey: 'fsmone.managed.portfolios',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Subscription/Top Up',
				titleKey: 'fsmone.subscription.top.up',
				url: genRouterLink('transaction','transact-now?transType=buy&prodType=DPMS'),
			},
			{
				title: 'Termination and Partial Redemption',
				titleKey: 'fsmone.termination.and.partial.redemption',
				url: genRouterLink('transaction','transact-now?transType=sell&prodType=DPMS'),
			},
			{
				title: 'RSP Amendment/Suspension',
				titleKey: 'fsmone.rsp.amendment.suspension',
				url: genRouterLink('my-rsp','edit-rsp-portfolio'),
			},
			{
				title: 'eDDA Application/eDDA Status',
				titleKey: 'fsmone.edda.application.status',
				url: genRouterLink('cash-account','cash-account-edda-application'),
			},
			{
				title: 'Cancel Order',
				titleKey: 'fsmone.cancel.order',
				url: genRouterLink('holdings','pending/'),
			},
			{
				title: 'Factsheets',
				titleKey: 'fsmone.factsheets',
				url: genRouterLink('dpms', 'portfolio-materials/factsheets'),
			}
		]
	},
	{
		title: 'Stocks',
		titleKey: 'fsmone.post.login.menu.hk.stocks.trading',
		url: genRouterLink(''),
		clickable: true,
		children: [
			{
				title: 'Trade',
				titleKey: 'fsmone.trade',
				url: genRouterLink('trade','stock'),
			},
			{
				title: 'Stock IPOs Subscription',
				titleKey: 'fsmone.stock.ipo.subscription',
				url: genRouterLink('stocks','stock-ipo-listing'),
			},
			{
				title: 'View applied Stock IPOs',
				titleKey: 'fsmone.view.applied.ipo',
				url: genRouterLink('ipo-subscription','subscription-listing'),
			},
			{
				title: 'HK Stocks Transfer In',
				titleKey: 'fsmone.transfer.in',
				url: genRouterLink('transaction','transfer-in-stocks?market=HKEX'),
			},
			{
				title: 'US Stock/ETF Trading',
				titleKey: 'fsmone.regular.saving.plan.application',
				url: genRouterLink('trade','us-stock'),
			},
			{
				title: 'US Stocks Transfer In',
				titleKey: 'fsmone.regular.saving.plan.amendment',
				url: genRouterLink('transaction','transfer-in-stocks?market=US'),
			}
		]
	},
		{
		title: 'Watchlist and Alert',
		titleKey: 'fsmone.watchlist.and.alert',
		url: genRouterLink('track', 'main'),
		clickable: false
	},
]

export function isStockRspActivated(){
	
}