<div id="country-selection">
  <span class="country">
    <a class="text-sm text-black-7 font-weight-bold">
      <i nz-icon nzType="global" nzTheme="outline" class="mr-1"></i>
      {{ _country?.titleKey | translate }}
      <i nz-icon nzType="caret-down" nzTheme="fill" class="ml-1"></i>
    </a>
    <div class="country-selection p-3 bg-black-1">
      <div class="title">
        <h5 class="text-uppercase text-theme font-weight-bold ml-2">
          {{ 'select.country' | translate }}
        </h5>
      </div>
      <ul class="pl-0 mb-0">
        <li class="ml-2 mb-2" *ngFor="let country of _countries">
          <span class="nav-icon mr-2">
            <a [href]="country?.external" target="_blank" class="country-item" rel="noreferrer noopener" [title]="country?.title">
              {{ country?.titleKey | translate }}
            </a>
          </span>
        </li>
      </ul>
    </div>
  </span>
</div>