import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IArticleRcmsTwModel} from '@api-module/model/article/i-article-rcms-tw.model';
import {Select} from '@ngxs/store';
import {GlobalState} from '../../../../core/store';
import {Observable} from 'rxjs';
import {AppConstant, FundConstant, RouteConstant} from 'src/app/constant';
import {Router} from '@angular/router';
import {ArticleCategory} from '@api-module/enum/i-fund-article-category.enum';
import {genRouterLink} from "@util/route";
import {SubHandlingService} from "@api-module/service/common/subscription-handling.service";
import {ArticleRestService} from 'src/app/api/rest/article.rest.service';

@Component({
    selector: 'webcast-container',
    templateUrl: './webcast-container.component.html',
    styleUrls: ['./webcast-container.component.scss'],
    providers: [SubHandlingService]

})
export class WebcastContainerComponent implements OnChanges {

    @Input() title: string;
    @Input() articleList: IArticleRcmsTwModel[] = [];
    @Input() viewMoreCategory: ArticleCategory = {} as ArticleCategory;
    @Input() linkToInsuranceDetails = genRouterLink(RouteConstant.INSURANCE, RouteConstant.INSURANCE_ARTICLE);
    @Input() includeInsurance: boolean = false;
    @Input() linkToArticleDetails = genRouterLink(RouteConstant.FUNDS, RouteConstant.RESEARCH, RouteConstant.ARTICLE_DETAILS);
    @Input() appendTitleToLink: boolean = false;
    @Input() hideHeader: boolean = false;
    routeConstant = RouteConstant;
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    readonly RouteConstant = RouteConstant;
    readonly FundConstant = FundConstant;
    readonly AppConstant = AppConstant;

    mainArticle: IArticleRcmsTwModel;
    subArticleList: IArticleRcmsTwModel[] = [];
    ARTICLE_IMAGE_PATH = 'assets/images/article/';

    constructor(private router: Router, private subHandlingService: SubHandlingService, private articleService: ArticleRestService) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.articleList && this.articleList && this.articleList.length > 0) {
            this.mainArticle = this.articleList.length > 0 ? this.articleList[0] : null;
            this.subArticleList = this.articleList.length > 1 ? this.articleList.slice(1) : [];
        }
    }

    redirectToWebcastHome(): void {
        let route = RouteConstant.WEBCAST;
        this.router.navigate([route]);
        //var data = genRouterLink('research','webcast');
        //let FSM = '/fsm';
        //window.open(getEnv()+FSM+data);
    }

    setWebcastUrl(articleNo: string, title?: string): void {
        let route = RouteConstant.WEBCAST + '/' + RouteConstant.ARTICLE_DETAILS + '/' + articleNo + '/' + title.replace(/[^\u4E00-\u9FFFp{L}a-zA-Z0-9]+/gi, "-");
        window.open(route, '_blank', 'noreferrer');
        //var data = genRouterLink('research','webcast','watch',articleNo);
        //let FSM = '/fsm';
        //window.open(getEnv()+FSM+data);
    }

    formWebcastUrl(articleNo: string): string {
        return RouteConstant.WEBCAST + '/' + RouteConstant.WEBCAST_DETAILS + '/' + articleNo;
        // window.open(route, '_blank', 'noreferrer');
        //var data = genRouterLink('research','webcast','watch',articleNo);
        //let FSM = '/fsm';
        //window.open(getEnv()+FSM+data);
    }

    redirectToAuthorPage(authorId) {
        this.router.navigate([RouteConstant.AUTHOR_DETAILS], {queryParams: {author: authorId}});
    }
}
