import { 
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter
	} from '@angular/core';
import {genRouterLink} from '@util/route';
import {RouteConstant} from 'src/app/constant/route.constant';

@Component({
  selector: 'app-best-worst-performers',
  templateUrl: './best-worst-performers-common.component.html',
})

export class BestWorstPerformersCommonComponent implements OnInit {
	
	PRODUCTS_ICON_IMAGES_PATH = 'assets/images/general/icons/product/';
	selected: string;
	stockPerformers = genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS);
	etfPerformers = genRouterLink(RouteConstant.ETFS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS);
	fundPerformers = genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS);
	bondPerformers = genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS);
	ngOnInit(){
		this.selected = this.product;
	}
	readonly FUNDS: string = 'FUNDS';
    readonly BONDS: string = 'BONDS';
    readonly STOCK: string = 'STOCK';
    readonly ETF: string = 'ETF';
	@Input() product: string;
	@Output() navigate: EventEmitter<any> = new EventEmitter<any>();
	
	changeCategory(cat){
		this.selected = cat;
		switch(cat){
			case 'FUNDS':
				this.navigate.emit(this.fundPerformers);
				break;
			case 'BONDS':
				this.navigate.emit(this.bondPerformers);
				break;
			case 'ETF':
				this.navigate.emit(this.etfPerformers);
				break;
			case 'STOCK':
				this.navigate.emit(this.stockPerformers);
				break;
		}
		
  }

}
