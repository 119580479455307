export function openNewTab(url: string | Array<string> | any, queryParams?: any) {
  let link: string;

  link = Array.isArray(url) && url.length > 0 ? url.join('/') : url;

  if (queryParams && Object.keys(queryParams).length) {
    link = link + '?';

    const queryParamsKeys: Array<any> = Object.keys(queryParams);

    queryParamsKeys.forEach((key: string, index: number) => {
      link = link + key + '=' + queryParams[key];

      if (index + 1 < queryParamsKeys.length) {
        link = link + '&';
      }
    });
  }

  if (link) {
    window.open(link, '_blank', 'noreferrer');
  }
}
