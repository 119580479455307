import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { ITwoFaModel } from '@api-module/model/authority/i-two-fa.model';
import {AuthConstant} from "src/app/constant";


@Injectable({
  providedIn: 'root'
})


export class CashAccountService {
  
  readonly BASE_URL: string = ApiConstant.CASH_ACCOUNT;
  readonly POST_LOGIN_BASE_URL: string = `protected${ApiConstant.CASH_ACCOUNT}`;
  
  constructor(private http: HttpClient, private paramService: HttpParamsService) {}

  findLatestInterestRate() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_ALL_INTEREST_RATE_LIST, {});
  }

  findAllFxRate(isLive?:boolean) {
	const params = this.paramService.buildHttpParams({isLive});
    return this.http.post(this.BASE_URL + ApiConstant.FIND_ALL_FX_RATE_LIST, {}, {params});
  }
  
  //for public access
  getFpsDetailsByRequestId(requestId) {
    const params = this.paramService.buildHttpParams({requestId});
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.GET_FPS_DETAILS_BY_REQUEST_ID, {}, {params});
  }

  getConsolidateIcashViewByRefno(paramRefno: string) {
    const params = this.paramService.buildHttpParams({paramRefno});
    return this.http.post(this.POST_LOGIN_BASE_URL + ApiConstant.GET_CONSOLIDATE_ICASH_VIEW_BY_REFNO,{}, {params});
  }

  getAllIcashSummaryList() {
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.GET_ALL_ICASHSUMMARY_LIST, {});
  }
  getAccountBalanceSummary() {
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.GET_ACC_BALANCE_SUMMAR, {});
  }
  isAccountBalanceNegative(refno: string, currency: string) {
    const params = this.paramService.buildHttpParams({ refno, currency });
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.IS_ACCOUNT_BALANCE_NEGATIVE, {}, { params });
  }
  getLatestIamTrustBankRate() {
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.LATEST_IAM_TRUST_BANK_RATE, {});
  }
  getAllActiveIamTrustAccList() {
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.GET_ALL_ACTIVE_IAMTRUST_ACCT_LIST, {});
  }

  findFxFavorite() {
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.FIND_FX_FAVOURITE,{}, {});
  }

  createUpdateFxFavorite(dto) {
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.CREATE_UPDATE_FX_FAVORITE, dto);
  }

  removeFxFavorite(favId: string) {
    const params = this.paramService.buildHttpParams({favId});
    return this.http.post<IResponse<any>>(this.POST_LOGIN_BASE_URL + ApiConstant.REMOVE_FX_FAVORITE,{}, {params});
  }

}