import {Component, Input, OnInit} from '@angular/core';
import {StockConstant} from "src/app/constant";

@Component({
  selector: 'app-stock-exchange-tag',
  template: `
    <span class="tag-css text-xs" [style.background-color]="color">{{exchange}}</span>
  `,
  styleUrls: ['./stock-exchange-tag.component.scss']
})
export class StockExchangeTagComponent implements OnInit {

  @Input()
  exchange: string = '-';
  color: string;

  constructor() {
  }

  ngOnInit(): void {
    this.exchange = this.exchange?.toUpperCase();
    this.color = this.getColor();
  }


  getColor(): string {
    switch (this.exchange) {
      case StockConstant.KLSE:
        return '#687ABC';
        break;
      case StockConstant.BURSA:
        return '#F48B85';
        break;
      case StockConstant.HKEX:
        return '#A3B958';
        break;
      case StockConstant.NYSE:
        return '#F48B85';
        break;
      case StockConstant.AMEX:
        return '#F48B85';
        break;
      case StockConstant.BATS:
        return '#F48B85';
        break;
      case StockConstant.NASDAQ:
        return '#F48B85';
        break;
      default:
        //sgx color
        return '#1DADCD';
        break;
    }
  }


}
