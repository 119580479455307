import { Pipe, PipeTransform } from '@angular/core';
import { AppConstant } from 'src/app/constant/app.constant';
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {

	constructor(private translate: TranslateService) {}
  	
	transform(value: string) {
		let yesDisplay = this.translateWithDefault('yes',AppConstant.YES_DISPLAY);
		let noDisplay = this.translateWithDefault('no',AppConstant.NO_DISPLAY);
    	return value === AppConstant.YES_FLAG ? yesDisplay : noDisplay;
  	}

	translateWithDefault(value: any,defaultValue?:string){
		if (!value) {
      		return '-';
    	}
		
		let ret: string = this.translate.instant(value);
		if(ret && ret.indexOf("[MISSING-") == -1){
			return ret;
		}
		
		return defaultValue;
	}
}
