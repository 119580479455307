import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {NgClassType} from 'ng-zorro-antd';

@Component({
  selector: 'ui-basic-card',
  templateUrl: './ui-basic-card.component.html',
  styleUrls: ['./ui-basic-card.component.scss']
})
export class UiBasicCardComponent implements OnInit {

  @Input() basicCardBody: TemplateRef<void>;
  @Input() nzClass: NgClassType;
  constructor() { }

  ngOnInit(): void {
  }

}
