import { Input,Output, OnInit, Directive } from '@angular/core';
import * as _ from 'lodash';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { WatchlistRestService } from '@api-module/rest/watchlist.rest.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppConstant } from '@constant';
import { IWatchlistItemRequestModel } from '@api-module/model/watchlist/i-watchlist-item-request.model';

@Directive({
  selector: 'add-to-watch-list',
})
export class AddToWatchListDirective implements OnInit {

  showWatchList: boolean = false;
  isVisible: boolean = false;
  isLoading: boolean = false;
  isLoadingAdd: boolean = false;
  watchlists: any = {};
  watchlistId: string = null;
  watchlistName: string = null;

  @Input()
  public productCode:string;

  @Input()
  public productType:string;

  @Input()
  public isHyperlink:string;

  @Input()
  public isIcon: boolean;

  @Input() 
  public iconWithBg: boolean;

  @Output() refreshWatchlistBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject<any>(true);

  watchListLoadingOnChange: Subject<boolean> = new Subject<boolean>();


  constructor(
    private watchlistRestService: WatchlistRestService,
    private translateService: TranslateService,
    private messageService: NzMessageService,
  ) { }

    ngOnInit() {

    }

    popWatchList() {
      this.isLoading = true;
      this.watchListLoadingOnChange.next(this.isLoading);
      this.watchlistId = null;
      this.showWatchList = true;
      this.watchlistRestService.findWatchlistByAccountId().toPromise().then(res => {
          if (res.status === AppConstant.RESPONSE_SUCCESS) {
              this.watchlists = res.data;
          }
      }).finally(() => {
          this.isVisible = true;
          this.isLoading = false;
          this.watchListLoadingOnChange.next(this.isLoading);
      });
  }


  addNewWatchlist() {
    this.isLoadingAdd = true;
    this.watchlistRestService.addWatchlist(this.watchlistName).toPromise().then(res => {
        if (res.status === AppConstant.RESPONSE_SUCCESS) {
            this.watchlistId = res.data.listId;
        } else {
          this.messageService.create('error', this.translateService.instant('fsmone.error'), {nzDuration: 10000 })
        }
    }).finally(() => {
        this.addWatchlistItem();
    });
  }

  addWatchlistItem() {
    this.isLoadingAdd = true;
    const watchlistItemRequestModel: IWatchlistItemRequestModel = {} as IWatchlistItemRequestModel;
    watchlistItemRequestModel.productCode = this.productCode;
    watchlistItemRequestModel.productType = this.productType;
    watchlistItemRequestModel.watchlistIdList = [this.watchlistId];

    this.watchlistRestService.addWatchlistItem(watchlistItemRequestModel).toPromise().then(res => {
        if (res.status === AppConstant.RESPONSE_SUCCESS) {
            this.messageService.create('success', this.translateService.instant('fsmone.watchlist.add.watchlist.item.success') + this.watchlistName, {nzDuration: 10000 });
            this.refreshWatchlistBehaviorSubject.next(true);
        } else {
          if (res.errorMessage === 'duplicated') {
            this.messageService.create('error', this.translateService.instant('fsmone.watchlist.add.duplicated.error'), {nzDuration: 10000 })
          } else {
            this.messageService.create('error', this.translateService.instant('fsmone.error'), {nzDuration: 10000 })
          }
        }
    }).finally(() => {
        this.showWatchList = false;
        this.isVisible = false;
        this.isLoadingAdd = false;
    });
  }

  handleCancel() {
    this.isVisible = false;
    this.showWatchList = false;
    this.watchlistName = null;
  }

}
