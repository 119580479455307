import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { AppConstant } from 'src/app/constant/app.constant';

@Pipe({ name: 'sdate' })
export class StandardDatePipe implements PipeTransform {
  transform(value: any) {
    if (!value) {
      return '-';
    }

    return formatDate(value, AppConstant.DATE_FORMAT, AppConstant.LOCALE_EN);
  }
}
