
<!--*ngIf="!fullscreen" -->
<div *ngIf="!loadingEtf else loadingTemplate">
  <div *ngIf="!onlyChart">
  <div *ngIf="!fullscreen else fullScreenTemplate">
     <app-etf-chart-centre-selector (selectedETF)="onChangeEtfList($event)" (clearAllETF)="onClearAllETF($event)"
      [allETFOptions]="allETFOptions"
      [totalETFSelected]="selectedETFList">
  	 </app-etf-chart-centre-selector>
  </div>
  </div>
  
  <div class="mt-3 container">
    <!-- <nz-tabset [nzAnimated]="false">
      <nz-tab nzTitle="{{'fsmone.bond.chart.returns' | translate}}" (nzClick)="changeChartDataOnGraphType(GRAFT_TYPE_RETURN)">
      </nz-tab>
      <nz-tab nzTitle="{{'fsmone.bond.market.chart.yield.title' | translate}}" (nzClick)="changeChartDataOnGraphType(GRAFT_TYPE_YIELD)">
      </nz-tab>
    </nz-tabset> -->

    <div *ngIf="isEmpty" class="h-100 w-100 text-center my-6 py-6">
      <img [src]="BOND_CHART_CENTRE_IMG_PATH + 'chart-center-empty-state.svg'" alt="empty-state" />
      <h3 class="text-gray pt-5 font-weight-normal">{{'fsmone.etf.chart.centre.placeholder.1'|translate}}</h3>
    </div>

    <app-web-scaffold>
      <nz-row nzType="flex" nzAlign="middle" prHelp prHide>
        <div *ngIf="!(isEmpty)" [nzXl]="21" class="mt-1 mb-1" nz-col nzXs="24">
          <app-bond-ranking-tab (clicked)="onPeriodChange($event)" [loading]="isLoading" [tabOptions]="tabPeriodOptions">
          </app-bond-ranking-tab>
        </div>
      </nz-row>
    </app-web-scaffold>

    <app-mobile-scaffold>
      <div *ngIf="!(isEmpty)" [nzXl]="17" nz-col nzXs="24" class="mt-1 mb-1">
        <app-bond-ranking-tab (clicked)="onPeriodChange($event)" (zoom)="onZoomChange($event)" [loading]="isLoading"
          [tabOptions]="tabPeriodOptions" mobileInChart=true></app-bond-ranking-tab>
      </div>
    </app-mobile-scaffold>
    
    <highcharts-chart *ngIf="!loadingChart; else loadingTemplate" (chartInstance)="getChartInstance($event)" [Highcharts]="Highcharts"
                      [(update)]="updateFlag"
                      [ngClass]="{'d-none': isEmpty, 'd-block': !isEmpty}"
                      [oneToOne]="true"
                      [options]="chartConfiguration"
                      [runOutsideAngular]="true"
                      class="d-block w-100 mw-100 my-3">
    </highcharts-chart>

  <div *ngIf="!onlyChart">
      <app-etf-chart-centre-table (clearETF)=onClearChartEtf($event) [isEmpty]="isEmpty" [isLoading]="isLoading"
      [tableData]="selectedETFList"></app-etf-chart-centre-table>
  </div>
  </div>
</div>

<ng-template #fullScreenTemplate>
  <div nz-row style="transform:rotate(90deg)"></div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="container">
    <nz-skeleton [nzLoading]="isLoading || loadingChart" [nzActive]="true"></nz-skeleton>
  </div>
</ng-template>
