import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AppConstant, StateConstant } from 'src/app/constant';
import { Injectable } from '@angular/core';
import {
  DeclareSIAction,
  SetMobileWebAction,
  SetPlatformAction,
  SetPrintAction,
  ToggleFooter, UpdateFsmPostLoginEnabled, UpdatePrint
} from '../action/global.action';

export interface GlobalStateModel {
  mobile: boolean;
  viewportHeight: number;
  viewportWidth: number;
  modalContentHeight: number;
  platform: string;
  browser: string;
  print: string;
  printPdf: boolean;
  aiDeclaration: boolean;
  showFooter: boolean;
  iPad: boolean;
  fsmPostLoginEnabled: boolean;
}

const stateDefaults: GlobalStateModel = {
  mobile: false,
  viewportHeight: 0,
  viewportWidth: 0,
  modalContentHeight: 0,
  platform: null,
  browser: null,
  print: null,
  printPdf: false,
  aiDeclaration: false,
  showFooter: true,
  iPad: false,
  fsmPostLoginEnabled: true
};

@State<GlobalStateModel>({
  name: StateConstant.GLOBAL,
  defaults: stateDefaults
})
@Injectable()
export class GlobalState {
  @Selector()
  static isMobile(state: GlobalStateModel) {
    return state.mobile;
  }

  @Selector()
  static hasDeclared(state: GlobalStateModel) {
    return state.aiDeclaration;
  }

  @Selector()
  static browser(state: GlobalStateModel) {
    return state.browser;
  }

  @Selector()
  static getViewportContext(state: GlobalStateModel) {
    return {
      viewportHeight: state.viewportHeight,
      viewportWidth: state.viewportWidth,
      modalContentHeight: state.modalContentHeight
    };
  }

  @Selector()
  static getFooterDisplay(state: GlobalStateModel) {
    return state.showFooter ?? true;
  }

  @Selector()
  static isIOS(state: GlobalStateModel) {
    return state.platform === AppConstant.IOS;
  }

  @Selector()
  static isAndroid(state: GlobalStateModel) {
    return state.platform === AppConstant.ANDROID;
  }

  @Selector()
  static getFsmPostLoginEnabled(state: GlobalStateModel) {
    return state.fsmPostLoginEnabled ?? true;
  }

  @Action(SetMobileWebAction)
  setMobileWebAction({ patchState }: StateContext<GlobalStateModel>, action: SetMobileWebAction) {
    let height: number = 0;
    let width: number = 0;

    try {
      height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }
    catch(_) {

    }

    patchState({
      mobile: action.isMobileWeb || false,
      viewportHeight: height,
      viewportWidth: width,
      modalContentHeight: height * 0.70
    });
  }

  @Action(SetPlatformAction)
  setPlatform({ patchState }: StateContext<GlobalStateModel>, action: SetPlatformAction) {
    patchState({
      platform: action.platform || stateDefaults.platform,
      browser: action.browser || stateDefaults.browser,
      iPad: action.iPadUser || stateDefaults.iPad
    });
  }

  @Action(SetPrintAction)
  setPrint({ patchState }: StateContext<GlobalStateModel>, action: SetPrintAction) {
    patchState({
      print: action.printId || null
    });
  }

  @Action(DeclareSIAction)
  acceptDeclaration({ patchState }: StateContext<GlobalStateModel>, action: DeclareSIAction) {
    patchState({
      aiDeclaration: true
    });
  }

  @Action(ToggleFooter)
  toggleFooterDisplay({ patchState }: StateContext<GlobalStateModel>, action: ToggleFooter) {
    patchState({
      showFooter: action.show ?? true
    });
  }

  @Action(UpdatePrint)
  updatePrint({ patchState }: StateContext<GlobalStateModel>, action: UpdatePrint) {
    patchState({
      printPdf: action.printPdf || false
    });
  }

  @Action(UpdateFsmPostLoginEnabled)
  updateFsmPostLoginEnabled({ patchState }: StateContext<GlobalStateModel>, action: UpdateFsmPostLoginEnabled) {
    patchState({
      fsmPostLoginEnabled: action.fsmPostLoginEnabled ?? true
    });
  }
}
