import { Injectable, ViewContainerRef } from '@angular/core';
import { UiPopMessageBoxComponent } from '@share/ui-components/pop-mesage-box/pop-message-box.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PopMessageInterface } from '../interface/general/pop-message.interface';

/* Always include providedIn root to services */
@Injectable({
	providedIn: 'root',
})
export class PopMessageService {

	constructor(
		private modal: NzModalService,
	) {
	}

	openPopOutModal( viewContainerRef: ViewContainerRef, popMessage: PopMessageInterface): Promise<any>{
		const modal = this.modal.create({
			nzWrapClassName: 'vertical-center-modal',
			nzWidth: popMessage.nzWidth ? popMessage.nzWidth : "480px",
			nzFooter: null,
			nzClosable: false,
			nzContent: UiPopMessageBoxComponent,
			nzComponentParams: popMessage,
      nzMaskClosable: popMessage.nzMaskClosable !== undefined ? popMessage.nzMaskClosable : true,
      nzOkType: popMessage.nzOkType ? popMessage.nzOkType : 'primary',
		});
		
		let promise = new Promise<boolean>(resolve => {
			modal.afterClose.subscribe(result => {
				return resolve(result);
			});
        });
        return promise;
	}

	// Open pop out on a new ViewContainerRef
	// Destroying original view/modal will not destroy this modal
	openNewPopOutModal(popMessage: PopMessageInterface): Promise<any> {
		return this.openPopOutModal(undefined, popMessage);
	}

}
