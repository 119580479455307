export class RcmsConstant {
	public static readonly TRAD_CH: string = 'zh-hk';
	public static readonly SIMP_CH: string = 'zh-cn';
	public static readonly EN: string = 'en_us';
	
	public static readonly CAT_ALL : string = 'All';
	public static readonly CAT_FUNDS : string = 'funds';
	public static readonly CAT_FH_DEPOSITORY : string = 'fund house depository';
	public static readonly CAT_BONDS : string = 'bonds';
	public static readonly CAT_ETFS : string = 'etfs';
	public static readonly CAT_DPMS : string = 'managed portfolio';
	public static readonly CAT_STOCKS : string = 'stocks';
	public static readonly CAT_BUZZ : string = 'buzz';
	public static readonly CAT_VIDEOS: string = 'videos';
	
	public static readonly ALL_VIDEOS_CAT: string[] = ['fund house interviews', 'investment analysis webcasts', 'fsmone managed portfolios updates', 'media interviews', 'webinar replay', 'web and app tutorials'];
}