
<ng-container *ngIf="vertical; else horizontalTemplate">
	<nz-row *ngFor="let icon of include">
		<nz-col>
			<share-buttons [theme]="roundedIcon ? 'circles-dark' : 'modern-dark'"
    [showIcon]="showIcon"
    [showText]="showText"
   	[url]="url"
   	[title]="title"
    [description]="description +'\n\n' +title"
    class="pt-5" [include]="[icon]"></share-buttons>
		</nz-col>
	</nz-row>
</ng-container>

<ng-template #horizontalTemplate>
	<share-buttons [theme]="roundedIcon ? 'circles-dark':'modern-dark'"
    [showIcon]="showIcon"
    [showText]="showText"
   	[url]="url"
   	[title]="title"
    [description]="description + '\n\n' + title"
    class="pt-5" [include]="include"></share-buttons>
</ng-template>