import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMobileFullScreenModalComponent } from './ui-mobile-full-screen-modal.component';
import {NzModalModule} from 'ng-zorro-antd';


@NgModule({
  declarations: [UiMobileFullScreenModalComponent],
  imports: [
    CommonModule,
    NzModalModule,
  ],
  exports: [
    UiMobileFullScreenModalComponent
  ]
})
export class UiMobileFullScreenModalModule { }
