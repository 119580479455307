import { LibrarySymbolInfo, ResolutionString, SubscribeBarsCallback } from "src/assets/charting_library_v2/datafeed-api";

export interface SymbolInfo extends LibrarySymbolInfo {
  tickerNo?: string;
  msInstrumentId?: string;
  stockCode?: string;
}

export class TaChartSubscription {
  symbolInfo: SymbolInfo;
  resolution: ResolutionString;
  onTick: SubscribeBarsCallback;
  listenerGuid: string;
  onResetCacheNeededCallback: () => void;
}

export interface TaChartUpdater {
  subscribeBars(): void;
  unsubscribeBars(): void;
}

declare type TaChartStatusType = 'LIVE' | 'DELAY' | 'TIMER';

export class TaChartStatus {
  statusType?: TaChartStatusType;
  countdownTimer?: number;
}