import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthState } from '@auth-module/store';
import { Store } from '@ngxs/store';
import { AesEncryptionService } from "@share/services/aes-encryption.service";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'igv-login-icon',
    template: `
        <form #form ngNoForm [action]="redirectUrl" target="_blank" method="POST" (click)="submitForm()">
            <a class="menu-icon" *ngIf="isIcon"><img src="assets/images/header/ifast-global-view-logo.svg"></a>
            <ng-container *ngIf="!isIcon">iFAST Global View</ng-container>
            <input type="hidden" id="loginPlatform" name="loginPlatform" value="HK_FSM_CLIENT_WEB" required>
            <input type="hidden" id="token" name="token" [(ngModel)]="token" required>
        </form>
    `
})
export class IgvLoginIconComponent implements OnInit {

    @Input() isIcon: boolean = true;

    @ViewChild('form') formElement?: ElementRef<HTMLFormElement>;
    token?: string;
    redirectUrl?: string;
    constructor(
        private store: Store,
        private aesEncryptionService: AesEncryptionService,
    ) { }

    ngOnInit(): void {
        this.redirectUrl = environment.ifastGlobalViewUrl + "/redirect";
        this.token = this.aesEncryptionService.aesEncrypt(this.store.selectSnapshot(AuthState.getToken), environment.ifastGlobalViewAesSecretKey);
    }

    submitForm() {
        this.formElement.nativeElement.submit();
    }
}