import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpoService {
  selectedIpoTypeSource =  new BehaviorSubject<string>('BOND');

  updateSelectedIpoType(type: string): void {
    this.selectedIpoTypeSource.next(type);
  }
}
