export class StateConstant {
  public static readonly GLOBAL: string = 'global';
  public static readonly AUTH: string = 'auth';
  public static readonly PUBLIC: string = 'public';
  public static readonly ACCOUNT: string = 'account';
  public static readonly TRANSACTION_BUY: string = 'buy';
  public static readonly TRANSACTION_SELL: string = 'sell';
  public static readonly TRANSACTION_SWITCH: string = 'switch';
  public static readonly TRADE: string = 'trade';
  public static readonly TRUST_ACCOUNT: string = 'trustAccount';
  public static readonly TRANSACTION_BUY_DPMS: string = 'buyDpms';
  public static readonly TRANSACTION_SELL_DPMS: string = 'sellDpms';
  public static readonly RISK_PROFILE_UPDATE: string = 'riskProfileUpdate';
  public static readonly RSP: string = 'rsp';
  public static readonly SELECTED_STOCK: string = 'selectedStock';
  public static readonly LIGHTSTREAMER_PERMISSION: string = 'lightstreamerPermission';
  public static readonly HOLDING: string = 'holding';
  public static readonly ETF_SELECTOR: string = 'etfSelector';
  public static readonly STOCK_SCREENER: string = 'stockScreener';
}
