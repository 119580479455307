import {Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ViewChild} from '@angular/core';
import {RouteConstant, AppConstant, AccountOpeningConstant} from 'src/app/constant';
import {environment} from '@env';
import {Select, Store} from '@ngxs/store';
import {GlobalState} from 'src/app/core/store';
import {Observable} from 'rxjs';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { getEnv } from '@share/services/product-search.service';
import { Router } from '@angular/router';
import { LiveChatComponent } from '@share/components/side-button/live-chat/live-chat.component'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import {SupportRestService} from '@api-module/rest/support.rest.service';
import { IResponse } from "@api-module/model/common/i-response";
import { AuthState } from '@auth-module/store';
import { map, tap } from 'rxjs/operators';
import { ResponsiveService } from '@core/service/responsive.service';
import { FooterStorageService } from '@share/services/footer/footer-storage.service';

@Component({
  selector: 'app-footer-fsmone',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FsmoneFooterComponent implements OnInit {
  
  @ViewChild('fsmoneCyberMessageBanner', { static: false }) fsmoneCyberMessageBanner: any;

  @Select(GlobalState.getFooterDisplay) footer$: Observable<boolean>;
  @Select(GlobalState.getFsmPostLoginEnabled) fsmPostLoginEnabled$: Observable<boolean>;

  APAC_POLICY_FILE_PATH = '/articleFiles/ABAC policy.pdf';
  TERMS_CON_FILE_PATH = '/articleFiles/terms_con.pdf';
  IMPORTANT_NOTES_FOR_STOCKS = '/articleFiles/Important_Notes_for_Transacting_Stocks.pdf';
  GENERAL_QRCODE_IMG_PATH = 'assets/images/general/qr-code/';

  readonly RouteConstant = RouteConstant;
  readonly AppConstant = AppConstant;
  readonly now = Date.now();

  locale: string = 'en';

  showModal :boolean = false;

  showCyberMessage = false;

  form: FormGroup;

  fsmPostLoginWhitelist = false;
  isMobileView: boolean = false;

  constructor(
    private globalDataStorage: GlobalDataStorage,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    private modalService: NzModalService,
    private supportRestService: SupportRestService,
    private store: Store,
    private responsiveService: ResponsiveService,
	private footerStorageService: FooterStorageService
  ) {
    this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
    this.showCyberMessage = this.globalDataStorage.getStorage('noticeBannerVisible') === 'true';
  }

  ngOnInit(): void {
    this.isMobileView = this.responsiveService.isMobileView();
	this.responsiveService.mobileViewEvent.subscribe(state => { this.isMobileView = state; });		

    if (environment.dev) {
      this.APAC_POLICY_FILE_PATH = AppConstant.LIVE_URL + this.APAC_POLICY_FILE_PATH;
      this.TERMS_CON_FILE_PATH = AppConstant.LIVE_URL + this.TERMS_CON_FILE_PATH;
    }
    this.form = this.initForm();
    this.store.select(AuthState.isAuthenticated).pipe(
			tap((auth: boolean) => {
			  this.fsmPostLoginWhitelist = auth;
			})
		).subscribe();
		
	if (this.showCyberMessage) {
       setTimeout(()=>{
        const cyberMessageHeight: number = this.fsmoneCyberMessageBanner.nativeElement.getBoundingClientRect().height;
		this.footerStorageService.cyberMessageOffset.next(cyberMessageHeight);
      }, 300);
    }
  }

  ngAfterViewInit(): void {
	const cyberMessageHeight: number = this.fsmoneCyberMessageBanner.nativeElement.getBoundingClientRect().height;
	this.footerStorageService.cyberMessageOffset.next(cyberMessageHeight);
  }

  initForm(): FormGroup {
    return this.fb.group({
      name:[null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(AccountOpeningConstant.REGEX_EMAIL)]]
    })
  }

  submitForm = ($event: any, value: any) => {
    console.log(value);
    this.form.markAllAsTouched();
    if (this.form.valid) {
      let name = this.form.get('name')?.value;
      let email = this.form.get('email')?.value;
      let lang = this.locale;
      this.supportRestService.subscribePublicEnews(name?name:"",email?email:"",lang?lang:"").subscribe((response: IResponse<any>) => {
	      if (AppConstant.RESPONSE_SUCCESS == response.status) {
            this.modalService.success({
              nzContent: this.translate.instant("fsmone.subscribe.success"),
              nzOkType: 'theme'
            })
            this.form.reset();
            for (const key in this.form.controls) {
              if (this.form.controls.hasOwnProperty(key)) {
                this.form.controls[key].markAsPristine();
                this.form.controls[key].updateValueAndValidity();
              }
            }
        } else {
            var message = response.errorMessage;
            this.modalService.error({
              nzContent: message,
              nzOkType: 'theme'
            })
          }
        })
    } else {
        Object.values(this.form.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
  }

  closeCyberMessage(): void {
    this.globalDataStorage.setStorage('noticeBannerVisible', 'false');
    this.showCyberMessage = false;
	this.footerStorageService.cyberMessageOffset.next(0);
  }
	
	//temp navigation
	redirectToUrl(path:string) {
		let fullUrl = getEnv() + '/fsm' + path;
		window.location.assign(fullUrl);
	}
	
	openLiveChat(){
		this.showModal = true;
	}
	
	closeLiveChat(){
		this.showModal = false;
	}

  routeToIdeaOfWeek(): void {
    this.router.navigate([RouteConstant.ARTICLE], { queryParams: { id: 'hot.invest', tags: 'Ideaoftheweek,IPO' } });
  }
}
