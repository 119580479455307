import { Injectable } from '@angular/core';
import { ProductRestService } from '@api-module/rest/product.rest.service';
import { map } from 'rxjs/operators';
import { IResponse } from '@api-module/model/common/i-response';
import { IProductSearchModel } from '@api-module/model/product/i-product-search.model';
import { openNewTab } from '@util/new-tab';
import { AppConstant, RouteConstant, StockConstant } from 'src/app/constant';
import { IProductEnum } from '@api-module/enum/i-product.enum';
import { getRoute } from '@util/factsheet';
import { Params } from '@angular/router';
import { getArticleRoute } from "@util/article";
import { Router } from '@angular/router';
import {genRouterLink} from "@util/route";

@Injectable({
	providedIn: 'root'
})
export class ProductSearchService {
	public static readonly STOCK_IPO_APPLY_URL: string = RouteConstant.TRADE+'/'+RouteConstant.IPOS+'/stock-ipo-subscription?stockIpoId=';

	constructor(private productRestService: ProductRestService,
		private router: Router) {

	}

	search(category: string, query: string, page?: number, limit?: number) {
		let product: string = category;

		return this.productRestService.findProductsByKeyword(product, query, page || 1, limit || 100).pipe(
			map((res: IResponse<Array<IProductSearchModel>>) => res.data || []),
			map((searchResults: Array<IProductSearchModel>) => {
				return searchResults.map((searchResult: IProductSearchModel) => {
					const ret: any = {
						...searchResult,
						label: searchResult.productName,
						publishedDate: searchResult.publishedDate,
					};

					if (category === 'BOND' && searchResult?.bondIssuer) {
						ret.subtitle = searchResult?.bondIssuer;
					}

					if (category === 'ETF' || category ==='STOCK_LI') {
						if (searchResult?.productCode != null) {
							let code = searchResult?.productCode.split(".");
							searchResult.productCode = code[2];
						}
						if (searchResult?.productName != null) {
							let name = searchResult?.productName.split("|");
							searchResult.productName = name[0].trim();
							searchResult.exchange = name[1].trim();
						}
						ret.label = searchResult?.productName;
					}

					if (category === 'STOCK') {
						let name = searchResult?.productName.split("|");
						ret.label = name[0].trim();
					}

					//Fund

					return ret;
				})
			})
		);
	}

	openProduct(data: IProductSearchModel) {
		const BONDS: string = 'Bonds';

		switch (data.productType) {
			case 'UT':
				const routeConfig = getRoute(IProductEnum.UNIT_TRUST, data.productCode, '', '');
				openNewTab(routeConfig.route, routeConfig.params);

				//this.router.navigate([RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FACTSHEET],{queryParams:{fund:data.productCode}});

				/*const fundFactsheet = getEnv()+'/fsm/'+RouteConstant.FUNDS + '/' + RouteConstant.FACTSHEET + '/' + data?.productCode;
				openNewTab(fundFactsheet);*/
				break;
			case 'BOND':
				const routeBondConfig = getRoute(IProductEnum.BOND, data.productCode, '', '');
				openNewTab(routeBondConfig.route, routeBondConfig.params);

				//open fundsupermart factsheet
				//const bondFactsheet = getEnv()+'/fsm/'+RouteConstant.BONDS + '/' + RouteConstant.FACTSHEET + '/' + data?.productCode;
				//openNewTab(bondFactsheet);
				break;
			case 'STOCK':
				let routeStockConfig;
				let tempStockRoute;
				routeStockConfig = getRoute(IProductEnum.STOCK_AND_ETF, data.productCode, '', '', data.exchange)
				//tempStockRoute = getEnv()+'/fsm/'+RouteConstant.STOCKS + '/'+ RouteConstant.FACTSHEET +'/' + data?.exchange + '.' + data?.productCode + '/stock';

				openNewTab(routeStockConfig.route, routeStockConfig.params);
				break;
			case 'ETF':
			case 'STOCK_LI':
				const routeETFConfig = getRoute(IProductEnum.ETF, data.productCode, '', '', data.exchange);
				openNewTab(routeETFConfig.route, routeETFConfig.params);
				break;
			case 'Article':
				// if (data.insuranceArticle === AppConstant.YES_FLAG) {
				//   const insuranceArticles = [RouteConstant.INSURANCE, RouteConstant.INSURANCE_ARTICLE];
				//   openNewTab(insuranceArticles, { paramArticleno: data.productCode, isInsurance: true });
				//   return;
				// }

				//remove this checking because, the bar search from rcms db, not rtim db, so article is still rcms article.

				if (data.articleCategory === BONDS) {
					const routeConfig = getArticleRoute(IProductEnum.BOND, data?.productCode, (data?.fromRcms ?? AppConstant.NO_FLAG), false, data?.productName, RouteConstant.BONDS)
					openNewTab(routeConfig.route, routeConfig.params);
				}
				else {
					const routeConfig = getArticleRoute(IProductEnum.UNIT_TRUST, data?.productCode, (data?.fromRcms ?? AppConstant.NO_FLAG), false, data?.productName, RouteConstant.FUNDS)
					openNewTab(routeConfig.route, routeConfig.params);
				}

				break;
			case 'INSURANCE':
				const planRoute = [RouteConstant.INSURANCE, RouteConstant.PLAN_INFORMATION];
				openNewTab(planRoute, { planId: data?.productCode, planType: data?.insPlanType });
				break;
			case 'DPMS':
				const managedPortfolios = [RouteConstant.MANAGED_PORTFOLIOS, RouteConstant.PORTFOLIO_INSIGHTS, RouteConstant.PORTFOLIO_FACTSHEET];
				openNewTab(managedPortfolios, { portfolioId: data?.productCode });
				break;
			case 'Faq':
				const { categoryId, taggingId, uniqueKey } = data;
				const params: Params = {
					routeCatId: taggingId,
					routeFaqId: categoryId,
					routeQuestUniqueKey: uniqueKey
				};

				const commands = [RouteConstant.SUPPORT, RouteConstant.CLIENT_SUPPORT, RouteConstant.FAQ];

				if (categoryId && taggingId && uniqueKey) {
					openNewTab(commands, params);
				}
				else {
					openNewTab(commands);
				}
				break;
			case 'ARTICLE':
				openNewTab([RouteConstant.ARTICLE,RouteConstant.ARTICLE_DETAILS,data?.productCode]);
				break;
			default:
				console.log(data.productType);
				break;
		}
	}
}

export function getFactsheetUrl(data: IProductSearchModel) {
	switch (data.productType) {
		case 'UT':
			return getEnvFsmone() + "/" + RouteConstant.FUNDS + "/" + RouteConstant.FUND_INFO + "/" + RouteConstant.FACTSHEET + "?fund=" + data.productCode;
		case 'BOND':
			return getEnvFsmone() + "/" + RouteConstant.BONDS + "/" + RouteConstant.BOND_FACTSHEET + "/" + data.productCode;
		case 'STOCK':
			if (data.securityType == 'STOCK_LI') {
				return getRoute(IProductEnum.ETF, data.productCode, '', '', data.exchange);
			} else {
				return getEnvFsmone() + "/" + RouteConstant.STOCKS + "/" + RouteConstant.TOOLS + "/" + RouteConstant.STOCKS_FACTSHEET + "?exchange=" + data.exchange + "&stock=" + data.productCode;
			}
		default:
			console.log(data.productType);
			break;
	}
}

export function getEnv() {
	if (window.location.href.indexOf("hktest") != -1) {
		return 'https://hktest.fundsupermart.com';
	}
	else if (window.location.href.indexOf("hktrial") != -1 || window.location.href.indexOf("local") != -1) {
		return 'https://hktrial.fundsupermart.com';
	}
	else {
		return 'https://secure.fundsupermart.com.hk';
	}
}

export function getEnvFsmone() {
	if (window.location.href.indexOf("hktest") != -1) {
		return 'https://hktest.fsmone.com.hk';
	}
	else if (window.location.href.indexOf("hktrial") != -1 || window.location.href.indexOf("local") != -1) {
		return 'https://hktrial.fsmone.com.hk';
	}
	else {
		return 'https://www.fsmone.com.hk';
	}
}

export function getEnvPostLogin() {
	if (window.location.href.indexOf("hktest") != -1) {
		return 'https://hktest.fundsupermart.com';
	}
	else if (window.location.href.indexOf("hktrial") != -1 || window.location.href.indexOf("local") != -1) {
		return 'https://hktrial.fundsupermart.com';
	}
	else {
		return 'https://secure.fundsupermart.com.hk';
	}
}

export function gotoStockBuy(symbol, exchange) {

	// if (exchange === StockConstant.HKEX) {
	// 	let buyUrl = getEnv() + '/fsm/trade/main/stock/HK?symbol=' + symbol;
	// 	window.open(buyUrl, '_blank', 'noreferrer');
	// } else if (exchange === StockConstant.NASDAQ || exchange === StockConstant.NYSE || exchange === StockConstant.AMEX || exchange === StockConstant.BATS) {
	// 	let buyUrl = getEnv() + '/fsm/trade/us-stock?symbol=' + symbol + '&exchange=' + exchange + '&action=Buy';
	// 	window.open(buyUrl, '_blank', 'noreferrer');
	// } else if (exchange === StockConstant.SSE || exchange === StockConstant.SZSE) {
	// 	let buyUrl = getEnv() + '/fsm/trade/main/stock/CN?symbol=' + symbol;
	// 	window.open(buyUrl, '_blank', 'noreferrer');
	// } else if (exchange === StockConstant.SGX) {
	// 	let buyUrl = getEnv() + '/fsm/trade/main/stock/SG?symbol=' + symbol;
	// 	window.open(buyUrl, '_blank', 'noreferrer');
	// }

	let url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.STOCKS + '/quick-trade' + '?prodCode='+ symbol + '&exchange=' + exchange;
	window.open(url, '_blank', 'noreferrer');
}

export function buyStockIpo(ipoId) {
	// let buyUrl = getEnv() + '/fsm/ipo-subscription/subscription-form/?stockIpoId=' + ipoId;

	let url = getEnvFsmone() + '/trade/ipo/stock-ipo-subscription' + '?stockIpoId='+ ipoId;
	window.open(url, '_blank', 'noreferrer');
}

export function gotoFundBuy(sedolnumber) {
	// let buyUrl = getEnv() + '/fsm/transaction/transact-now?transType=buy&prodType=UT&prodCode=' + sedolnumber;

	let url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.FUNDS + '/' + RouteConstant.BUY + '?prodCode='+ sedolnumber;
	window.open(url, '_blank', 'noreferrer');
}

export function gotoBondIpoBuy(prodId, refno) {
	// let bondIpoBuyUrl = getEnv() + '/fsm/bond-transaction/subscription/' + prodId + "/" + refno;

	let url = getEnvFsmone() + '/trade/ipo/current-ipos';
	window.open(url, '_blank', 'noreferrer');
}

export function gotoBuy(prodId, prodType) {
	var url = '';

	if (prodType == 'UT') {
		// url = getEnv() + '/fsm/transaction/transact-now?transType=buy&prodType=UT&prodCode=' + prodId;
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.FUNDS + '/' + RouteConstant.BUY + '?prodCode='+ prodId;
	}
	else if (prodType == 'bond') {
		// url = getEnv() + '/fsm/bond-transaction/buy/' + prodId;
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.BONDS + '/prices' + '?prodCode='+ prodId;
	}
	else if (prodType == 'bondExpress') {
		// url = getEnv() + '/fsm/bond-transaction/mobile-buy/?pageMode=1&issueCode=' + prodId + '&isBondExpressTrade=true';
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.BONDS + '/prices' + '?prodCode='+ prodId + '&bondExpress=true';
	}
	else if (prodType == 'treasuryNow') {
		// url = getEnv() + '/fsm/bond-transaction/mobile-buy/?pageMode=1&issueCode=' + prodId + '&isBondExpressTrade=true';
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.BONDS + '/prices' + '?prodCode='+ prodId + '&treasuryNow=true';
	}
	else if (prodType == 'rsp') {
		// url = getEnv() + '/fsm/transaction/transact-now?transType=rsp&prodType=UT';
		url = getEnvFsmone() + '/' + RouteConstant.HOLDINGS;
	}
	else if (prodType == 'dpms') {
		// url = getEnv() + '/fsm/transaction/transact-now?transType=buy&prodType=DPMS';
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.MANAGED_PORTFOLIO + '/' + RouteConstant.BUY;
	}

	window.open(url, '_blank', 'noreferrer');
}

export function gotoSell(prodId, prodType) {
	var url = '';
	if (prodType == 'UT') {
		// url = getEnv() + '/fsm/transaction/transact-now?transType=sell&prodType=UT&prodCode=' + prodId;
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.FUNDS + '/' + RouteConstant.SELL+ '?prodCode='+ prodId;
	} else if (prodType == 'bond') {
		// url = getEnv() + '/fsm/bond-transaction/sell/' + prodId;
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.BONDS + '/prices'+ '?prodCode='+ prodId + '&sell=true';
	} else if (prodType == 'bondExpress') {
		// url = getEnv() + '/fsm/bond-transaction/mobile-sell/?pageMode=1&issueCode=' + prodId + '&isBondExpressTrade=true';
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.BONDS + '/prices'+ '?prodCode='+ prodId + '&sell=true&bondExpress=true';
	} else if (prodType == 'treasuryNow') {
		// url = getEnv() + '/fsm/bond-transaction/mobile-buy/?pageMode=1&issueCode=' + prodId + '&isBondExpressTrade=true';
		url = getEnvFsmone() + '/' + RouteConstant.TRADE + '/' + RouteConstant.BONDS + '/prices' + '?prodCode='+ prodId + '&sell=true&treasuryNow=true';
	}
	window.open(url, '_blank', 'noreferrer');
}

export function bondIpoFactsheet(prelaunchId) {
	if (prelaunchId) {
		openNewTab([RouteConstant.BONDS, RouteConstant.BOND_IPO, prelaunchId]);
	}
}

export function goToWatchList() {
	let watchListUrl = getEnvFsmone() +'/'+ RouteConstant.WATCHLIST;
	window.open(watchListUrl, '_blank', 'noreferrer');
}

export function openFundFactsheet(sedolnumber: string) {
	const fund = '?fund=' + sedolnumber;
	openNewTab([RouteConstant.FUNDS, RouteConstant.FUND_INFO, RouteConstant.FACTSHEET + fund]);
}

export function goToCashAccFx() {
	let url = getEnvFsmone() + '/trade/cash-account/fx-conversion';
	window.open(url, '_blank','noreferrer');
}