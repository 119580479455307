<div class="bg-black-1">
<div class="p-4">
	<h4 class="mb-3" [innerHTML]="'fsmone.important.notes.for.transaction.bonds' | translate"></h4>
<h6 class="font-weight-semibold" translate="fsmone.remarks" translate-default="Remarks"></h6>
<ul>
	<li><h6 translate="fsmone.risk.disclosure.remarks.1" translate-default="Warning for bonds that are unauthorised by SFC: The contents of this document have not been reviewed by any regulatory authority in Hong Kong. You are advised to exercise caution in relation to the offer. If you are in any doubt about any of the contents of this document, you should obtain professional advice."></h6></li>
	<li><h6 translate="fsmone.risk.disclosure.remarks.2" translate-default="SFC authorization is not a recommendation or endorsement of a product nor does it guarantee the commercial merits of a product or its performance. It does not mean the product is suitable for all investors nor is it an endorsement of its suitability for any particular investor or class of investors"></h6></li>
	<li><h6 translate="fsmone.risk.disclosure.remarks.3" translate-default="These quotes are only indicative prices and are subject to change"></h6></li>
</ul>

<h6 class="font-weight-semibold" translate="fsmone.market.order" translate-default="Market Order"></h6>
<h6 class="mb-3" translate="fsmone.market.order.refers.to.the.customers.request.to.buy.or.sell" translate-default="Market Order refers to the Customer's request to buy or sell at the prevailing market price (accrued interest not inclusive). The orders are transacted through the Market Dealer according to the below transaction procedures"></h6>

<h6 class="font-weight-semibold" translate="fsmone.limit.order" translate-default="Limit Order"></h6>
<h6 class="mb-3" translate="fsmone.limit.order.refers.to.the.customers.request.to.buy.or.sell" translate-default="Limit Order refers to the Customer&#039;s request to buy or sell at a specific price (accrued interest not inclusive). A Limit Order can only take up to 3 business days to fulfill, after which the order expires."></h6>

<h6 class="font-weight-semibold" translate="fsmone.transaction.procedures" translate-default="Transaction Procedures"></h6>
<h6 translate="fsmone.orders.of.bonds.are.generated.and.placed" translate-default="Orders of bonds are generated and placed with the Market Dealer according to the following cut-off time:"></h6>
<ol >
	<li><h6 translate="fsmone.transaction.procedures.1" translate-default="For transactions with payment received by IFHK before 5 pm, orders will be placed to the Market Dealer on the same business day."></h6></li>
	<li><h6 translate="fsmone.transaction.procedures.2" translate-default="For transactions with payment received by IFHK after 5 pm, orders will be placed to the Market Dealer on the next business day."></h6></li>
	<li><h6 translate="fsmone.transaction.procedures.3" translate-default="The indicative price is a recently transacted price to give you an estimate."></h6></li>
	<li><h6 translate="fsmone.transaction.procedures.4" translate-default="The payment requested by IFHK will be a buffer above this indicative price to cater to any changes on the transaction day."></h6></li>
</ol>
<h6><span [innerHtml]="( 'fsmone.pricing.and.yield.details.are.based.on.the.last.available.market.price.and.should.be.used.for.indicative.purposes.only' | translate )"></span></h6>
<h6><span [innerHtml]="( 'fsmone.business.day.refers.to.monday.to.friday' | translate )"></span></h6>
<h6 class="mb-3" translate="fsmone.all.bond.orders.are.irrevocable.upon.confirmation.from.ifhk" translate-default="All bond orders are irrevocable upon confirmation from IFHK."></h6>

<h6 class="font-weight-semibold" translate="fsmone.buy.transaction" translate-default="Buy Transaction"></h6>
<h6 translate="fsmone.by.cash.account" translate-default="By Cash Account"></h6>
<ol>
	<li><h6 translate="fsmone.buy.transaction.1" translate-default="The Customer must have sufficient fund in the Cash Account."></h6></li>
	<li><h6 translate="fsmone.buy.transaction.2" translate-default="For Market Order Transactions, orders are placed with the Market Dealer according to the above-mentioned transaction procedures."></h6></li>
	<li><h6 translate="fsmone.buy.transaction.3" translate-default="For Limit Order Transactions, IFHK monitors the orders for 3 business days (inclusive of the submission day provided the order reaches IFHK before 5pm) and buys at the requested price or lower, after which the order expires. If IFHK is able to match against the price the Customer has indicated in the instruction, orders will be executed with the Market Dealer."></h6></li>
	<li><h6 translate="fsmone.buy.transaction.4" translate-default="The payment requested by IFHK will be a buffer above this indicative price to cater to any changes on the transaction day."></h6></li>
	<li><h6 translate="fsmone.buy.transaction.5" translate-default="A Buy Confirmation Note will be sent to the Customer after each fulfilled transaction."></h6></li>
</ol>
<h6 class="mb-3" translate="fsmone.the.customer.is.required.to.top.up.the.difference" translate-default="The Customer is required to top-up the difference should the funds that were earmarked for the transacted order is insufficient. Failure by the Customer to top up the difference by T+6 will result in a forced sale by IFHK and the Customer is liable to pay for any losses incurred."></h6>

<h6 class="font-weight-semibold" translate="fsmone.sell.transaction" translate-default="Sell Transaction"></h6>
<ol class="mb-3">
	<li><h6 translate="fsmone.sell.transaction.1" translate-default="For Market Order Transactions, orders are placed with the Market Dealer according to the above-mentioned transaction procedures."></h6></li>
	<li><h6 translate="fsmone.sell.transaction.2" translate-default="For Limit Order Transactions, IFHK monitors the orders for 3 business days (inclusive of the submission day provided the order reaches IFHK before 5pm) and sells at the requested price or higher, after which the order expires. If IFHK is able to match against the price the Customer has indicated in the instruction, orders will be generated and placed with the Market Dealer."></h6></li>
	<li><h6 translate="fsmone.sell.transaction.3" translate-default="A Sell Confirmation Note will be sent to the Customer after each fulfilled transaction."></h6></li>
	<li><h6 translate="fsmone.sell.transaction.4" translate-default="Under normal circumstances, sell Transactions take T+5 business days to credit the said redemption proceeds. The actual schedule may vary, depending on the payment method."></h6></li>
</ol>

<h6 class="font-weight-semibold" translate="fsmone.fees.and.charges" translate-default="Fees and Charges"></h6>
<ol class="mb-3">
	<li><h6><span [innerHtml]="( 'fsmone.fees.and.charges.1' | translate )"></span></h6></li>
	<li><h6><span [innerHtml]="( 'fsmone.fees.and.charges.2' | translate )"></span></h6></li>
</ol>

<h6 class="font-weight-semibold" translate="fsmone.coupon.maturity.or.other.corporate.action" translate-default="Coupon, Maturity or other Corporate Action"></h6>
<h6 class="mb-3" translate="fsmone.payment.will.be.credited.to.the.customers.cash.account" translate-default="Payment will be credited to the Customer's Cash Account in bond currency."></h6>

<h6 class="font-weight-semibold" translate="fsmone.frequency.of.account.statements" translate-default="Frequency of Account Statements"></h6>
<h6 class="mb-3" translate="fsmone.monthly.account.statement.will.be.sent" translate-default="Monthly account statement will be sent to the Customer by electronic means."></h6>

<h6 ng-hide="ctrl.isBondExpress || ctrl.buyBondDisp.orderType==ctrl.ORDER_TYPE_FIRM" class="font-weight-semibold" translate="fsmone.capacity" translate-default="Capacity"></h6>
<h6 ng-hide="ctrl.isBondExpress || ctrl.buyBondDisp.orderType==ctrl.ORDER_TYPE_FIRM" translate="fsmone.ifhk.is.acting.as.an.agent.in.bond.transaction" translate-default="IFHK is acting as an agent in the bond transaction executed on the Fundsupermart.com platform."></h6>

</div>
</div>
