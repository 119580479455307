import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    if (error instanceof Error) {
      console.error(error);
    }
    else {
      console.error(error);
    }
  }
}