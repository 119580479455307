import {Injectable} from "@angular/core";
import {ApiConstant} from "@api-module/api.endpoint.constant";
import {HttpClient} from "@angular/common/http";
import {HttpParamsService} from "@api-module/service/http-params.service";
import {IResponse} from "@api-module/model/common/i-response";
import {TwoFaRequestModel} from "../../two-fa/model/two-fa-request.model";

@Injectable({
    providedIn: 'root'
})

export class TwoFactorAuthRestService {
    readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.TWO_FACTOR_AUTH;

    constructor(private http: HttpClient, private paramService: HttpParamsService) {
    }

    getSmsOtpVerification(twoFaRequestModel: TwoFaRequestModel) {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/sms-otp-verification', twoFaRequestModel);
    }

    requestSendOneTimePassword(twoFaRequestModel: TwoFaRequestModel) {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/request-send-one-time-password', twoFaRequestModel);
    }

    authenticateOneTimePassword(twoFaRequestModel: TwoFaRequestModel) {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/authenticate-one-time-password', twoFaRequestModel);
    }

    unlinkFingerprint(fingerprintRequestModels: any[]) {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/unlink-fingerprint', fingerprintRequestModels);
    }

    generateSecretCode(fingerprintRequestModel: any) {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/generate-secret-code', fingerprintRequestModel);
    }

    confirmTrustedDeviceLogin(fingerprintRequestModel: any) {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/confirm-trusted-device-login', fingerprintRequestModel);
    }
}
