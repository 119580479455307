export class NotificationConstant {
    public static readonly ARTICLE = [
        'io',
        'ideaoftheweek',
        're',
        'research',
        'pf',
        'personalfinance',
        'an',
        'announcement',
        'in',
        'importantnotices',
        'us',
        'upcomingseminars',
        'fh',
        'fhdepository',
        'fa',
        'fadepository',
        'ps',
        'presentationslide',
        'bdNewsNewlyBond',
        'bdNewsGlobalBonds'
    ];
    public static readonly BOND = [
        'bdNewlyIssuedBonds',
        'bdNewBonds',
        'bdHighYieldBonds',
        'bdIndexDataYield',
        'bdIndexDataSpread',
        'bdIndexDataPerformance',
        'bdYieldCurve',
        'bdChartCenter',
        'bdSelector',
        'bdTransaction',
        'bdFactSheet',
        'bdRetailBond'
    ];
    public static readonly FUND = [
        'fdFundWatch',
        'fdPortfolio',
        'fdTopVolume',
        'fdTopGainers',
        'fdTopLosers',
        'fdChartCenter',
        'fdSelector',
        'fdSimulator',
        'fdFsmIndices',
        'fdEarningsTrend',
        'fdConfidenceIndex',
        'fdFactSheet',
        'fdMarketIndices',
        'fdWebcast',
    ];
    public static readonly NORMAL_MESSAGE = [
        'nm',
        'normalmessage'
    ];
    public static readonly FACTSHEET = [
        'fdFactSheet',
        'bdFactSheet',
    ];
}
