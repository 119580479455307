import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPieChartComponent } from './ui-pie-chart/ui-pie-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslateModule } from '@ngx-translate/core';
import { NzCardModule } from 'ng-zorro-antd/card';
import { PieChartInformationModalComponent } from './components/pie-chart-information-modal/pie-chart-information-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';



@NgModule({
	declarations: [
		UiPieChartComponent,
		PieChartInformationModalComponent
	],
	exports: [
		UiPieChartComponent
	],
	imports: [
		CommonModule,
		HighchartsChartModule,
		NzButtonModule,
		NzCardModule,
		NzDividerModule,
		NzGridModule,
		NzModalModule,
		TranslateModule,
	]
})
export class UiPieChartModule { }
