import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges, Input, TemplateRef } from '@angular/core';
import { HeaderMetaModel } from 'src/app/share/models/header/header.model';
import { getEnv, getEnvPostLogin } from '@share/services/product-search.service';
import { Router } from '@angular/router';
import {AuthService} from '@auth-module/service/auth.service';
import {AuthState} from '@auth-module/store';
import {Store} from '@ngxs/store';
import { genRouterLink } from '@util/route';
import {RouteConstant, AppConstant} from 'src/app/constant';

import { IDropdownModel } from '@api-module/model/common/i-dropdown.model';
//import {defaultCountry} from 'src/app/constant/country.constant';

import { AccountRestService } from '@api-module/rest/account.rest.service';
import { IResponse } from "@api-module/model/common/i-response";
import { tap } from 'rxjs/operators';

@Component({
	selector: 'mobile-header',
	templateUrl: './mobile-header.component.html',
	styleUrls: ['./mobile-header.component.scss']
})

export class MobileHeaderComponent implements OnInit, OnChanges, AfterViewInit {
	constructor(private router: Router, private store: Store, private authService: AuthService, private accountRestService: AccountRestService) { }

	@Input() menu: Array<HeaderMetaModel>;
	@Input() langTemplates: TemplateRef<any>;
	@Input() postMenu: Array<HeaderMetaModel>;
	@Input() accountSettingMenu: Array<HeaderMetaModel>;
	@Input() auth: boolean;
	@Input() authCas: boolean;
	
	showMenu: boolean = false;
	subList = [];
	showSubList: boolean = false;
	globalSearch: boolean = false;
	selectedSubListKey: string;
	postLoginMenu: boolean = false;
	subPostLoginMenu: boolean = false;
	postMenuSubList = [];
	loginLink: any = genRouterLink(RouteConstant.LOGIN);	

	langOptions: IDropdownModel[] = [] as IDropdownModel[];
	
	hasDAccount: boolean = false;
	dAccountMenu = ['fsmone.valuation.report'];

	ngAfterViewInit(): void {
		
	}
	ngOnChanges(changes: SimpleChanges): void {
		
	}
	
	ngOnInit(): void {
		this.store.select(AuthState.isAuthenticated).pipe(
			tap((auth: boolean) => this.auth = auth)
		  ).subscribe();
		this.store.select(AuthState.isCasAuthenticated).pipe(
			tap((authCas: boolean) => this.authCas = authCas)
		).subscribe();
		if (this.auth||this.authCas) {
			const uid = this.store.selectSnapshot(AuthState.getId);
			if (uid != null || uid != undefined) {
				this.accountRestService.hasDpmsAccount().subscribe(
					(response: IResponse<Boolean>) => {
						if (response.status === AppConstant.RESPONSE_SUCCESS) {
							this.hasDAccount = response.data;
						}
					}
				)
			}
		}
	}

	initDefaultOptions(): void {
		this.langOptions = [
			{
				label: 'EN',
				value: 'en',
			},
			{
				label: '繁',
				value: 'ch',
			},
			{
				label: '简',
				value: 'zh',
			}
		];
	}

  logout() {
    this.authService.logout();
  }

	openMenu() {
		this.showMenu = true;
	}
	closeMenu() {
		this.showMenu = false;
		this.showSubList = false;
		this.selectedSubListKey = '';
	}
	openSubList(list: Array<HeaderMetaModel>, key) {
		for (let item of list) {
			for (let child of item?.children) {
				this.subList.push(child);
			}
		}
		this.selectedSubListKey = key;
		this.showSubList = true;
		this.showMenu = false;
	}
	backToMenu() {
		this.subList = [];
		this.showMenu = true;
		this.showSubList = false;
		this.selectedSubListKey = '';
	}
	closeSubList() {
		this.subList = [];
		this.showSubList = false;
		this.showMenu = true;
		this.selectedSubListKey = '';
	}
	navigate(path: string, postLogin: boolean) {
		console.log(path);
		let url = '';
		
		/*if(postLogin){
			url = getEnvPostLogin() + '/fsm' + path;
		} else {
			url = getEnv() + '/fsm' + path;
		}*/
		
		url = getEnv() + '/fsm' + path;
		
		if (path.indexOf('www') != -1) {
			window.open(url, '_blank', 'noreferrer');
		} else if (path.indexOf('https') != -1) {
			window.open(path, '_blank', 'noreferrer');
		} else if (path == '/') {
			this.router.navigateByUrl(path);
		} else {
			if (postLogin) {
				window.location.assign(url);
			}
			else {
				this.router.navigate([path]);;
			}
		}
	}
	navigatePostLogin(path: string) {
		let url = getEnv() + '/fsm' + path;
		if (path.indexOf('www') != -1) {
			window.open(url, '_blank', 'noreferrer');
		} else if (path == '/') {
			this.router.navigateByUrl(path);
		} else {
			window.location.assign(url);
		}
	}
	openSearch() {
		this.globalSearch = true;
	}
	closeSearch() {
		this.globalSearch = false;
	}
	openPostLoginMenu(){
		this.postLoginMenu = true;
	}
	closePostMenu(){
		this.postLoginMenu = false;
		this.subPostLoginMenu = false
	}
	openPostMenuSubList(list: Array<HeaderMetaModel>,title){
		for (let item of list) {
			this.postMenuSubList.push(item);
		}
		this.selectedSubListKey = title;
		this.subPostLoginMenu = true;
		this.postLoginMenu = false;
	}
	closeSubPostMenu(){
		this.postMenuSubList= [];
		this.selectedSubListKey = '';
		this.subPostLoginMenu = false;
		this.postLoginMenu = true;
	}
	
	checkMenu(key: string): boolean {
		if(this.dAccountMenu.includes(key)){
			if(!this.hasDAccount) {
				return false;
			}
		}
		return true;
	}
	
}