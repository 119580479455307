<div class="login-wrapper">
  <nz-row nzType="flex" nzAlign="middle" nzJustify="start">	
    <nz-col nzXs="0" nzSm="0" nzXl="14" class="login-background" [ngClass]="{'bg-position-top': currentTime == 'afternoon'}" [ngStyle]="{ 'background-image': 'url(' + LOGIN_IMG_PATH + currentTime + '.jpg' + ')' }">
      <p class="login-background-text" innerHTML="{{ 'fsmone.login.page.slogan' | translate}}"></p>
    </nz-col>
    <nz-col nzXs="24" nzSm="24" nzXl="0" class="login-background" [ngStyle]="{ 'background-image': 'url(' + LOGIN_IMG_PATH + currentTime + '-mobile.jpg' + ')' }"></nz-col>
    <nz-col nzXs="0" nzSm="0" nzXl="10" class="login-panel-desktop"> 
      <ng-container *ngTemplateOutlet="loginPanel"></ng-container>
    </nz-col>
    <nz-col nzXs="24" nzSm="24" nzXl="0" class="login-panel-mobile">
      <nz-card class="panel">
        <ng-container *ngTemplateOutlet="loginPanel"></ng-container>
      </nz-card>
    </nz-col>
  </nz-row>
</div>

<ng-template #loginPanel>
  <div class="panel-logo">
    <a (click)="goToHomepage()"><img src="https://www.fsmone.com.hk/fsm/images/icon/fsm-one-actual.png"></a>
  </div>
  <div class="pt-2 pb-3">
    <h3 class="text-capitalize font-weight-semibold text-center" translate="fsmone.Login.to.your.account"
      translate-default="Login to your account"></h3>
  </div>
  <form nz-form [formGroup]="loginForm" (ngSubmit)="submit()">
    <nz-form-item>
      <nz-form-control>
        <ui-input-group nzLabelPlaceholder="{{'fsmone.user.id' | translate}}">
          <input class="w-100" ui-input formControlName="clientName" (ngModelChange)="capitalize($event, 'clientName')" />
          <nz-form-explain
            *ngIf="loginForm.get('clientName')?.dirty && loginForm.get('clientName')?.hasError('required')"><span
              translate="fsmone.please.enter.your.user.id" translate-default="Please enter your User ID"></span>
          </nz-form-explain>
        </ui-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <ui-input-group nzLabelPlaceholder="{{'fsmone.password' | translate}}" [nzSuffix]="suffixTemplate">
          <input [type]="passwordVisible ? 'text' : 'password'" class="w-100" ui-input formControlName="clientPassword" />
          <nz-form-explain
            *ngIf="loginForm.get('clientPassword')?.dirty && loginForm.get('clientPassword')?.hasError('required')"><span
              translate="please.enter.your.password." translate-default="Please enter your password."></span>
          </nz-form-explain>
        </ui-input-group>
        <ng-template #suffixTemplate>
          <i nz-icon class="pointer" [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
            (click)="passwordVisible = !passwordVisible"></i>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <div class="text-danger text-center" [ngSwitch]="errorMessage">
      <span *ngSwitchCase="'invalidPassword'" translate="fsmone.login.invalid.username.or.password" translate-default="Invalid Username or Password"></span>
      <span *ngSwitchCase="'accountLocked'" translate="fsmone.login.account.locked" translate-default="The account is locked. Please contact our Client Service Team at 852-3766 4321 for further assistance"></span>
      <span *ngSwitchCase="'dormant'" translate="fsmone.login.account.dormant" translate-default="The account is dormant. Please contact our Client Service Team at 852-3766 4321 for further assistance"></span>
      <span *ngSwitchCase="'notApproved'" translate="fsmone.login.account.not.approved" translate-default="The account is not approved. Please try again later"></span>
      <span *ngSwitchCase="'contactCs'" translate="fsmone.login.contact.customer.service" translate-default="Please contact our Client Service Team at 852-3766 4321 for further assistance"></span>
    </div>
    <button nz-button nzType="theme" nzSize="large" class="w-100" [nzLoading]="isLoading">
      <span translate="fsmone.login" translate-default="Login"></span><span *ngIf="isDev">{{currentEnv}}</span>
    </button>
  </form>
  <div class="pt-4 pb-2">
    <h5 class="font-weight-bold text-center"><a (click)="navigateByUrl('accountOpening')"
        translate="fsmone.sign.up.for.a.free.account" translate-default="Sign Up For A Free Account"></a></h5>
    <h5 class="text-center text-black-6">
      <span role="button" (click)="navigateForgotService('forgetID')" translate="fsmone.forgot.user.id"
        translate-default="Forgot User ID"></span>?
      | <span role="button" (click)="navigateForgotService('forgetPassword')" translate="fsmone.forgot.password"
        translate-default="Forgot Password"></span>?
      | <span role="button" (click)="navigateForgotService('corpFirstTimeLogin')" translate="fsmone.corp.acct.first.time.login"
        translate-default="Corporate Account First Time Login"></span>?
    </h5>
  </div>
</ng-template>

