import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalState } from '@core/store';
import { Select } from '@ngxs/store';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
	selector: 'ui-pop-message-box',
	templateUrl: './pop-message-box.component.html',
	styleUrls: ['./pop-message-box.component.scss']
})
export class UiPopMessageBoxComponent implements OnInit {
	
	constructor(private modal: NzModalRef) { }
	
	@Input() type: string;
	@Input() titleText: string;
	@Input() titleHtml: string;
	@Input() bodyText: string;
	@Input() bodyHtml: string;
	@Input() bodyAlignment: string;
	@Input() confirmBtn: boolean = false;
	@Input() confirmBtnHtml: string;
	@Input() cancelBtn: boolean = false;
	@Input() cancelBtnHtml: string;

	@Output() event: EventEmitter<any> = new EventEmitter<any>();

	textAlignment: string = 'text-center';
	buttonAlignment: string = 'text-center';

	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

	ngOnInit(): void {
		if (this.confirmBtn && this.cancelBtn) {
			this.textAlignment = 'text-left';
			this.buttonAlignment = 'text-right';
		}
		if (this.bodyAlignment) {
			this.textAlignment = this.bodyAlignment;
		}
		if (!this.confirmBtn && !this.cancelBtn) {
			this.confirmBtn = true;
		}
	}

	destroyModal(): void {
		this.modal.destroy();
	}
	
	emitData(data){
		this.modal.destroy(data);
	}
}
