import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'getExDate'})
export class GetExDatePipe implements PipeTransform {
  public constructor(){}

  transform(value: any, next?: boolean): any {
    if(value === undefined || value.length == 0 ){
      return null;
    }

    let exDate = null;
    let current = new Date();

    value.forEach((data: any) => {
        if(data.S750){
			var dateParts = data.S750.split('-');
          const newDate = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
          if(next){
            if(newDate > current){
              if(exDate === null){
                exDate = newDate;
              } else {
                if(newDate < exDate){
                  exDate = newDate;
                }
              }
            }
          } else {
            if(newDate <= current){
              if(exDate === null){
                exDate = newDate;
              } else {
                if(newDate > exDate){
                  exDate = newDate;
                }
              }
            }
          }
        }
    });
	if(exDate){
    	return exDate;
	} else {
		return '-';
	}
  }
}
