import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ResponsiveService } from 'b2c-ui/core';
import { tap, filter, debounceTime, takeUntil } from 'rxjs/operators';
import {
  B2CProductCataloqueModel,
  B2CProductSearchResultModel,
  B2CProductSearchQueryModel,
	defaultProductCataloque,
	OVERLAY_ID
} from 'b2c-ui/header';
import { I18nTransKey } from 'b2c-ui/i18n';
import { FormGroup, FormBuilder } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';

@Component({
  selector: 'product-search',
  templateUrl: './product-search.component.html'
})

export class ProductSearchComponent implements OnInit, OnDestroy {
	
	productCatalogue : Array<B2CProductCataloqueModel> = [
		{ label: 'mutual.funds', value: 'UT', icon: 'fundTypeIcon' },
	  { label: 'stock', value: 'STOCK', icon: 'stocksTypeIcon' },
	  { label: 'etf', value: 'ETF', icon: 'etfsTypeIcon' },
	  { label: 'bonds', value: 'BOND', icon: 'bondsTypeIcon' },
	  { label: 'Managed Portfolio', value: 'DPMS', icon: 'dpmsTypeIcon' },
	  { label: 'Insurance', value: 'INSURANCE', icon: 'insuranceTypeIcon' },
	  { label: 'Research Articles', value: 'Article', icon: 'researchArticleIcon' },
	  { label: 'FAQ', value: 'Faq', icon: 'faqIcon' }
	];

  @HostBinding ('class.w-100') fullWidth = true;

  @Input()
  set unitTrust(unitTrust: boolean) {
    this._unitTrust = coerceBooleanProperty(unitTrust);
  }

  @Input()
  set stock(stock: boolean) {
    this._stock = coerceBooleanProperty(stock);
    this.updateProductCatalogue();
  }

  @Input()
  set etf(etf: boolean) {
    this._etf = coerceBooleanProperty(etf);
    this.updateProductCatalogue();
  }

  @Input()
  set bond(bond: boolean) {
    this._bond = coerceBooleanProperty(bond);
    this.updateProductCatalogue();
  }

  @Input()
  set dpms(dpms: boolean) {
    this._dpms = coerceBooleanProperty(dpms);
    this.updateProductCatalogue();
  }

  @Input()
  set insurance(insurance: boolean) {
    this._insurance = coerceBooleanProperty(insurance);
    this.updateProductCatalogue();
  }

  @Input()
  set article(article: boolean) {
    this._article = coerceBooleanProperty(article);
    this.updateProductCatalogue();
  }

  @Input()
  set faq(faq: boolean) {
    this._faq = coerceBooleanProperty(faq);
    this.updateProductCatalogue();
  }

  @Input() results: Array<B2CProductSearchResultModel> = [
    { label: 'Eastspring Small Caps Fund', value: 'world' },
    { label: 'TA Global Tech Fund', value: 'world' },
    { label: 'Kenanga Growth Fund', value: 'world', subtitle: 'Kenanga Investment Berhad' },
    { label: 'Principal Asia Pacific Dynamic Income Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' },
    { label: 'United Global Fund', value: 'world' }
  ];

  @Input() searching: boolean = false;

  @Input() activationCount: number = 1;

  @Output() query: EventEmitter<B2CProductSearchQueryModel> = new EventEmitter<
    B2CProductSearchQueryModel
  >();
  @Output() resultClick: EventEmitter<B2CProductSearchResultModel> = new EventEmitter<
    B2CProductSearchResultModel
  >();

  @Output() nextPage: EventEmitter<any> = new EventEmitter<any>();

  _unitTrust: boolean = false;
  _stock: boolean = false;
  _etf: boolean = false;
  _bond: boolean = false;
  _dpms: boolean = false;
  _insurance: boolean = false;
  _article: boolean = false;
  _faq: boolean = false;

  mobile: boolean = false;
  productCataloque = this.productCatalogue;
  activeProductCataloque: Array<B2CProductCataloqueModel> = [];
  form: FormGroup;
  height = (Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)) * 0.70;

  @ViewChild('typeahead', { static: true }) typeaheadInput: ElementRef<HTMLElement>;

  readonly I18nTransKey = I18nTransKey;
  private readonly destroySubject = new Subject<any>();

  constructor(private responsiveService: ResponsiveService, private fb: FormBuilder) {
    this.responsiveService
      .isMobileWeb()
      .pipe(tap((mobile: boolean) => (this.mobile = mobile)))
      .subscribe();
  }

  ngOnInit(): void {
		let selectedValue = '';
		if(this._unitTrust) {
			selectedValue = 'UT';
		} else if (this._stock) {
			selectedValue = 'STOCK';
		} else if (this._bond) {
			selectedValue = 'BOND';
		} else if (this._etf) {
			selectedValue = 'ETF';
		}
    this.form = this.fb.group({
      category: [this.productCataloque.find(({ value }) => value === selectedValue)],
      query: ['']
    });

    this.form.valueChanges
      .pipe(
        filter((val: any) => val.query && val.query.length > (this.activationCount || 0) && val.category),
        debounceTime(1500),
        tap((val: any) => {
          const payload: B2CProductSearchQueryModel = {
            ...val,
            category: val.category.value
          };

          this.query.emit(payload);
        }),
        takeUntil(this.destroySubject)
      )
      .subscribe();

    const searchOverlay: Element = document.getElementById(OVERLAY_ID);

    if (searchOverlay) {
      fromEvent(searchOverlay, 'click')
        .pipe(
          tap(() => {
            this.form.get('query').reset();
            this.results = [];
          }),
          takeUntil(this.destroySubject)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  selectResult(result: B2CProductSearchResultModel) {
    this.resultClick.emit(result);
    this.form.get('query').reset();
    this.results = [];
  }

  public focusTypeaheadInput() {
    if (this.typeaheadInput) {
      setTimeout(() => {
        this.typeaheadInput.nativeElement.focus();
      }, 0);
    }
  }

  get queryField(): string {
    return this.form ? this.form.get('query').value : '';
  }

  private updateProductCatalogue() {
    const cataloque: Array<B2CProductCataloqueModel> = [];

    if (this._unitTrust) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'UT'));
    }

    if (this._stock) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'STOCK'));
    }

    if (this._bond) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'BOND'));
    }

    if (this._dpms) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'DPMS'));
    }

    if (this._insurance) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'INSURANCE'));
    }

    if (this._article) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'Article'));
    }
    
    if (this._faq) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'Faq'));
    }

    if (this._etf) {
      cataloque.push(this.productCataloque.find(({ value }) => value === 'ETF'));
    }

    this.activeProductCataloque = [...cataloque];
  }
}
