<ng-container *ngIf="newMenuEnabled">
  <div class="header-wrapper" #header>
    <div class="maintenance-banner" *ngIf="maintenanceBannerVisible">
      <div class="container-fluid">
          <div class="d-flex justify-content-between">
              <div class="banner-content" [innerHTML]="maintenanceContent"></div>
              <div><i (click)="onCloseMaintenanceBanner()" nz-icon nzType="close" nzTheme="outline"></i></div>
          </div>
      </div>
    </div>
    <div class="bg-black-1 py-2">
      <div class="px-4">
        <div nz-row nzType="flex" nzAlign="middle">
          <div nz-col nzSpan="12" *ngIf="!isMobileView">
            <a href="http://www.sfc.hk/publicregWeb/corp/AMY844/details" target="_blank" class="text-decoration-none">
              <small class="text-xs text-black-6 hover-black-7">{{ 'fsmone.licensed.dealer.and.investment.adviser' | translate }}</small>
            </a>
          </div>
          <div nz-col [nzSpan]="isMobileView ? '24' : '12'" class="d-inline-flex justify-content-end align-items-center">
            <ng-container *ngIf="siteMapTemplate && !isMobileView">
              <span class="text-sm pointer font-weight-bold mr-1" *ngFor="let template of siteMapTemplate">
                <ng-container *ngTemplateOutlet="template"></ng-container>
              </span>
            </ng-container>
            <ng-container *ngIf="langTemplates">
              <ng-container *ngFor="let template of langTemplates">
                <ng-container *ngTemplateOutlet="template"></ng-container>
              </ng-container>
            </ng-container>
            <span class="country pl-3 pointer position-relative">
              <div>
                <i nz-icon nzType="global" nzTheme="outline" class="mr-1"></i>
                <small class="text-uppercase font-weight-semibold text-black-7">{{ _country?.titleKey | translate }}</small>
                <i nz-icon nzType="caret-down" nzTheme="fill" class="ml-1"></i>
              </div>
              <div class="position-absolute country-selection">
                <div class="select-title mb-2">{{ 'select.country' | translate }}</div>
                <ng-container *ngFor="let country of _countries; let last = last">
                  <a [href]="country?.external" target="_blank" class="country-item hover-theme d-block" [ngClass]="{'mb-2': !last}" rel="noreferrer noopener">
                    {{ country?.titleKey | translate}}
                  </a>
                </ng-container>
              </div>
            </span>
            <small *ngIf="!isMobileView" class="pointer d-inline-block font-weight-bold ml-3 hover-theme" (click)="goToPage('FAQ')">{{ 'help.and.services' | translate }}</small>
            <small *ngIf="!(auth||authCas)" class="pointer d-inline-block font-weight-bold hover-theme ml-3" (click)="goToPage('LOGIN')">{{ 'fsmone.login' | translate }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-2">
      <div class="px-4">
        <div nz-row nzType="flex" nzJustify="space-between" nzAlign="center">
          <div nz-col nzXs="6" [nzSm]="search ? '3' : '17'" [nzXl]="search ? '3' : '20'" class="d-flex align-items-center">
            <a *ngIf="(auth||authCas) && auth2FA && !isMobileView" class="d-inline-block text-black mr-2 text-decoration-none" (click)="togglePostloginMenuCollapsed()">
              <i class="fsmicon-menu text-md"></i>
            </a>
            <a *ngIf="isMobileView" class="d-inline-block text-black mr-2 text-decoration-none" (click)="togglePreloginMobileMenu()">
              <i class="{{ showPreloginMobileMenu ? 'fsmicon-close' : 'fsmicon-menu' }} text-md"></i>
            </a>
            <a class="menu-fsm-logo" routerLink="/">
              <b2c-ui-icon name="fsmoneLogoIcon"></b2c-ui-icon>
            </a>
            <ng-container *ngIf="!search">
              <ng-container *ngIf="!isMobileView">
                <ul class="menu-wrapper ml-3 pr-0">
                  <li class="module position-relative" *ngFor="let item of structureWithBanner" (mouseover)="showPreloginMenu = true">
                    <ng-container *ngIf="item?.children; else withoutDropdownTemplate">
                      <a class="module-name" [routerLink]="item?.url">{{ item?.titleKey | translate }}</a>
                      <div id="dropdownMenu" class="dropdown" [ngClass]="{'d-none': !showPreloginMenu}">
                        <i class="dropdown-arrow fsmicon-arrow-dropup text-theme"></i>
                        <div class="dropdown-menu-wrapper">
                          <div class="bg-black-2 text-md font-weight-bold py-2">
                            <div class="px-4">
                              {{ item?.titleKey | translate | uppercase}}
                            </div>
                          </div>
                          <ng-container *ngIf="isLoadingMenu">
                            <div class="px-4 py-3">
                              <i class="anticon anticon-loading anticon-spin loading-icon mr-1"></i>
                              {{ 'widget.loading' | translate }}...
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!isLoadingMenu">
                            <div class="px-4 pt-3">
                              <div class="dropdown-menu" [ngStyle]="{'gridTemplateColumns': getGridTemplate(item?.children, item?.childrenGrid)}">
                                <ng-container *ngIf="item?.childrenGrid">
                                  <ng-container *ngFor="let grid of item.childrenGrid; let i = index">
                                    <div *ngIf="grid.length === 1">
                                      <div class="dropdown-menu-title" [ngClass]="{ 'py-3': !item?.children[i].titleKey }">{{ item?.children[i].titleKey | translate }}</div>
                                      <ng-container *ngFor="let subMenuItem of item?.children[i]?.children">
                                        <a [routerLink]="subMenuItem?.url" class="dropdown-menu-item" (click)="routeToPage(subMenuItem.url, subMenuItem.value)">
                                          {{ subMenuItem.titleKey | translate }}
                                          <i *ngIf="subMenuItem.postLogin" class="fsmicon-lock float-right pr-1"></i>
                                        </a>
                                        <small class="mr-2 text-xs text-black-6" *ngFor="let desc of subMenuItem.desc">{{ desc | translate | uppercase }}</small>
                                        <nz-divider class="my-2"></nz-divider>
                                      </ng-container>
                                    </div>
                                    <div *ngIf="grid.length > 1">
                                      <ng-container *ngFor="let gridIndexList of item.childrenGrid[i]; let gridLast = last">
                                        <div class="dropdown-menu" [ngClass]="item?.childrenGridClass ? item?.childrenGridClass[i] : ''" [ngStyle]="{'gridTemplateColumns': 'repeat(' + gridIndexList.length + ', 1fr)'}">
                                          <div *ngFor="let gridIndex of gridIndexList">
                                            <ng-container *ngIf="item?.children[gridIndex]?.bannerChildren">
                                              <div [ngClass]="{'mb-2': !last}" *ngFor="let banner of item?.children[gridIndex]?.bannerChildren; let last = last">
                                                <a *ngIf="getBannerName(banner.bannerImage)" (click)="bannerOnNavigate(banner.bannerImage)">
                                                  <img class="w-100" [ngClass]="item?.children[gridIndex]?.bannerClass" src="{{ BANNER_PATH }}{{ getBannerName(banner.bannerImage) }}" alt="FSMOne">
                                                </a>
                                              </div>
                                            </ng-container>
                                            <ng-container *ngIf="!item?.children[gridIndex]?.bannerChildren">
                                              <div class="dropdown-menu-title" [ngClass]="{ 'py-3': !item?.children[gridIndex].titleKey }">{{ item?.children[gridIndex].titleKey | translate }}</div>
                                              <ng-container *ngFor="let subMenuItem of item?.children[gridIndex]?.children">
                                                  <ng-container *ngIf="subMenuItem.title != '#FollowUsTemplate'">
                                                    <a [routerLink]="subMenuItem?.url" class="dropdown-menu-item" (click)="routeToPage(subMenuItem.url, subMenuItem.value)">
                                                      {{ subMenuItem.titleKey | translate }}
                                                      <i *ngIf="subMenuItem.postLogin" class="fsmicon-lock float-right pr-1"></i>
                                                    </a>
                                                    <small class="mr-2 text-xs text-black-6" *ngFor="let desc of subMenuItem.desc">{{ desc | translate | uppercase }}</small>
                                                  </ng-container>
                                                  <ng-container *ngIf="subMenuItem.title == '#FollowUsTemplate'">
                                                    <small class="d-block text-theme font-weight-bold">{{ 'fsmone.follow.us.on' | translate }}</small>
                                                    <div class="social-icon-wrapper pt-2">
                                                      <a class="social-icon" href="https://www.facebook.com/fsmone.hk">
                                                        <i class="facebook-icon" nz-icon nzType="facebook" nzTheme="fill"></i><small class="text-black-6 text-xs">Facebook</small>
                                                      </a>
                                                      <a class="social-icon" href="https://www.instagram.com/fsmone.hk/">
                                                        <img class="mb-1" src="assets/images/icon/icon-instagram.png" alt="Instagram" width="24">
                                                        <small class="text-black-6 text-xs">Instagram</small>
                                                      </a> 
                                                      <a class="social-icon" href="https://www.youtube.com/channel/UCqpMQhoQLdy9ycSIBCKa4iA">
                                                        <i class="fsmicon-youtube"></i><small class="text-black-6 text-xs">Youtube</small>
                                                      </a> 
                                                    </div>
                                                  </ng-container>
                                                  <nz-divider class="my-2"></nz-divider>
                                                </ng-container>
                                            </ng-container>
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!item?.childrenGrid">
                                  <div *ngFor="let child of item?.children">
                                    <ng-container *ngIf="child?.bannerChildren">
                                      <div *ngFor="let banner of child?.bannerChildren; let last = last">
                                        <a *ngIf="getBannerName(banner.bannerImage)" (click)="bannerOnNavigate(banner.bannerImage)">
                                          <img class="w-100" [ngClass]="child?.bannerClass" src="{{ BANNER_PATH }}{{ getBannerName(banner.bannerImage) }}" alt="FSMOne">
                                        </a>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngIf="!child?.bannerChildren">
                                      <div class="dropdown-menu-title" [ngClass]="{ 'py-3': !child.titleKey }">{{ child.titleKey | translate }}</div>
                                      <ng-container *ngFor="let subMenuItem of child?.children">
                                        <a [routerLink]="subMenuItem?.url" class="dropdown-menu-item" (click)="routeToPage(subMenuItem.url, subMenuItem.value)">
                                          {{ subMenuItem.titleKey | translate }}
                                          <i *ngIf="subMenuItem.postLogin" class="fsmicon-lock float-right pr-1"></i>
                                        </a>
                                        <small class="mr-2 text-xs text-black-6" *ngFor="let desc of subMenuItem.desc">{{ desc | translate | uppercase }}</small>
                                        <nz-divider class="my-2"></nz-divider>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #withoutDropdownTemplate>
                      <ng-container *ngIf="'iFAST Global View' === item?.title">
                        <igv-login-icon></igv-login-icon>
                      </ng-container>
                      <ng-container *ngIf="'iFAST Global View' !== item?.title">
                        <a class="menu-icon" *ngIf="item?.icon" [href]="item.url" target="_blank"><img [src]="item.icon"></a>
                        <a *ngIf="!item?.icon" (click)="emitNavigationEvent(item?.title, item?.url, item?.postLogin)" [routerLink]="item?.url">
                          {{ item?.titleKey | translate }}
                        </a>
                      </ng-container>
                    </ng-template>
                  </li>
                </ul>
              </ng-container>
            </ng-container>
          </div>
          <div nz-col nzXs="18" [nzSm]="search ? '21' : '7'" [nzXl]="search ? '21' : '4'" [ngClass]="[search ? 'text-right' : 'd-flex align-items-center justify-content-end']">
            <ng-container *ngIf="!search">
              <i class="fsmicon-search menu-search-button" (click)="toggleSearch()"></i>
              <button class="menu-auth-button" *ngIf="!(auth||authCas)" nz-button nzType="theme" [nzSize]="isMobileView ? 'default' : 'large'" (click)="goToPage('ACCOUNT_OPENING')">
                {{ 'fsmone.open.an.account' | translate }}
              </button>
              <button class="menu-auth-button" *ngIf="(auth||authCas)" nz-button nzType="theme" [nzSize]="isMobileView ? 'default' : 'large'" (click)="logout()">
                <i nz-icon nzType="unlock" nzTheme="outline"></i>
                {{ 'fsmone.logout' | translate | uppercase }}
              </button>
            </ng-container>
            <ng-container *ngIf="search">
              <div class="menu-search-bar-wrapper d-inline-block">
                <ng-container *ngTemplateOutlet="searchPlaceholder"></ng-container>
              </div>
              <div class="menu-search-close-wrapper text-right d-inline-block">
                <i class="text-md pointer" nz-icon nzType="close" nzTheme="outline" (click)="toggleSearch()"></i>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 d-block b2c-web-pusher" #pusher></div>
  <ng-container *ngIf="showPreloginMobileMenu && isMobileView">
    <div class="mobile-header-wrapper ui-scroll-bar">
      <ul class="menu-wrapper" [ngClass]="{'mobile-wrapper': !((auth||authCas) && auth2FA)}">
        <ng-container *ngFor="let item of structureWithBanner">
          <li [ngClass]="{'active': item.active}" (click)="onClickModule(item)">
            <ng-container *ngIf="item?.children; else withoutDropdownTemplate">
              {{ item?.titleKey | translate }}
              <i *ngIf="!item?.active" class="menu-arrow fsmicon-arrow-down-o"></i>
              <i *ngIf="item?.active" class="menu-arrow fsmicon-arrow-up-o"></i>
            </ng-container>
            <ng-template #withoutDropdownTemplate>
              <ng-container *ngIf="item?.icon">
                <ng-container *ngIf="'iFAST Global View' === item?.title">
                  <igv-login-icon [isIcon]="false"></igv-login-icon>
                </ng-container>
                <ng-container *ngIf="'iFAST Global View' !== item?.title">
                  {{ item.title }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!item?.icon">{{ item?.titleKey | translate }}</ng-container>
            </ng-template>
          </li>
          <ng-container *ngIf="item?.active">
            <ng-container *ngFor="let child of item?.children">
              <div class="sub-menu" [ngClass]="child.mobileMenuClass">
                <div class="sub-menu-title" *ngIf="child.titleKey">{{ child.titleKey | translate }}</div>
                <ng-container *ngFor="let subMenuItem of child?.children">
                  <div class="sub-menu-item" *ngIf="subMenuItem.titleKey" (click)="routeToPage(subMenuItem.url, subMenuItem.value)">
                    <i *ngIf="subMenuItem?.icon" class="{{ subMenuItem?.icon }} page-icon mr-3"></i>
                    <div class="page-title">{{ subMenuItem.titleKey | translate }}</div>
                    <i *ngIf="subMenuItem.postLogin" class="fsmicon-lock text-md ml-2"></i>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!newMenuEnabled">
  <div class="b2c-web-header-wrapper" #header *ngIf="(positionEvent$ | async) as posEvent" [ngStyle]="{'top': posEvent?.topHeight + 'px'}">
    <div class="b2c-web-header desktop-menu position-relative" [ngClass]="{'post-login-header': (auth||authCas)}">
      <div class="mx-4 header-top">
        <div class="d-inline-flex w-50 justify-content-start align-items-center">
          <a href="http://www.sfc.hk/publicregWeb/corp/AMY844/details" target="_blank">
            <small class="text-black-6 text-xs" translate="fsmone.licensed.dealer.and.investment.adviser" translate-default="SFC Licensed Dealer and Investment Adviser and Asset Manager"></small>
          </a>
        </div>
        <div class="d-inline-flex w-50 justify-content-end align-items-center">
          <ng-container *ngIf="siteMapTemplate">
            <span class="text-sm pointer" *ngFor="let template of siteMapTemplate">
              <ng-container *ngTemplateOutlet="template"></ng-container>
            </span>
          </ng-container>
          <ng-container *ngIf="langTemplates">
            <ng-container *ngFor="let template of langTemplates">
              <ng-container *ngTemplateOutlet="template"></ng-container>
            </ng-container>
          </ng-container>
          <span class="px-2 pointer country position-relative" style="padding-top: 2px;">
            <div class="text-xs">
              <i nz-icon nzType="global" nzTheme="outline" class="mr-1"></i>
              <span class="text-uppercase font-weight-semibold text-black-7">{{ _country?.titleKey | translate }}</span>
              <i nz-icon nzType="caret-down" nzTheme="fill" class="ml-1"></i>
            </div>
            <div class="position-absolute country-selection">
              <span class="select-title">{{ 'select.country' | translate }}</span>
              <ul class="pl-1 mb-0">
                <li *ngFor="let country of _countries">
                  <a [href]="country?.external" target="_blank" class="country-item" rel="noreferrer noopener">
                    {{ country?.titleKey | translate}}
                  </a>
                </li>
              </ul>
            </div>
          </span>
          <ng-container *ngIf="appBarTemplates && appBarTemplates.length">
            <span class="text-xs font-weight-bold pl-2 pointer" *ngFor="let template of appBarTemplates">
              <ng-container *ngTemplateOutlet="template"></ng-container>
            </span>
          </ng-container>
        </div>
      </div>

      <div class="mx-4">
        <div id="menu-nav">
          <div nz-row nzType="flex" nzJustify="space-between">
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzXl="4">
              <div class="fsm-logo my-2">
                <a routerLink="/">
                  <img src="https://www.fsmone.com.hk/fsm/images/icon/fsm-one-actual.png">
                </a>
              </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzXl="20" class="fsm-menu">
              <ul class="nav pre-login-menu d-inline-block mb-0 p-0" *ngIf="!search">
                <li *ngFor="let item of structure">
                  <ng-container *ngIf="item?.children; else withoutDropdownTemplate">
                    <a class="font-weight-bold text-sm pointer position-relative" 
                      [ngClass]="{'lang-ch': locale != 'en', 'unset-text-transform': item?.setTransform==false}" 
                      (click)="emitNavigationEvent(item?.title, item?.url, item?.postLogin, expandedMenu, arrow, true)"
                      [routerLink] = "item?.url"
                      (mouseout)="resetMenuState(expandedMenu, arrow)">
                      {{ item?.titleKey | translate }}
                      <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                    </a>
                    <div class="position-absolute w-100 menu" #expandedMenu>
                      <div class="dropdown-menu">
                        <div class="wrapper" [ngStyle]="{'gridTemplateColumns': calculateGrid(item?.children)}"> 
                          <div class="nav-list d-flex flex-column" [ngClass]="child?.image?'flex-row-reverse':''"
                            [ngStyle]="{'gridColumn':child?.gridCol , 'gridRow':child?.gridRow}"
                            *ngFor="let child of item?.children" role="navigation">
                            <div *ngIf="!child?.image" class="module"> <span [ngClass]="{'unset-text-transform': item?.setTransform==false}">{{child?.titleKey | translate}}</span></div>
                              <div *ngIf="!child?.image" class="item flex-column">
                                <div class="py-1" *ngFor="let grandChild of child?.children">
                                  <div *ngIf="!grandChild?.image">
                                    <a class="page" [ngClass]="{'unset-text-transform':!item?.setTransform}" (click)="emitNavigationEvent(grandChild?.title, grandChild?.url, grandChild?.postLogin, expandedMenu)"
                                        [routerLink]="grandChild?.url">
                                      {{grandChild?.titleKey | translate }}
                                    </a>
                                      <i *ngIf="grandChild?.postLogin" class="lock-icon" nz-icon nzType="lock" nzTheme="fill"></i>
                                    <label style="font-weight:lighter; font-size: small;text-transform:uppercase"
                                      *ngFor="let desc of grandChild?.desc"> {{desc|translate}} </label>
                                  </div>
                                  <a class="page" *ngIf="grandChild?.image"
                                    (click)="emitNavigationEvent(grandChild?.title, grandChild?.url, grandChild?.postLogin, expandedMenu)" [routerLink]="grandChild?.url">
                                    <menu-image [img]="grandChild?.image" [title]="grandChild?.titleKey"></menu-image>
                                  </a>
                                </div>
                              </div>
                              <a *ngIf="child?.image" (click)="emitNavigationEvent(child?.title, child?.url, child?.postLogin, expandedMenu)" [routerLink]="child?.url">
                                <menu-image [img]="child?.image" [title]="child?.title"></menu-image>
                              </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #withoutDropdownTemplate>
                    
                    <a class="ifast-tv" *ngIf="item?.icon" [href]="item.url" target="_blank"><img class="header-icon" [src]="item.icon"></a>
                  
                    <a *ngIf="!item?.icon" class="font-weight-bold text-sm" [ngClass]="{'lang-ch': locale != 'en'}" style="height: 62px;" (click)="emitNavigationEvent(item?.title, item?.url, item?.postLogin)" [routerLink]="item?.url"><span>{{item?.titleKey
                        | translate}}</span></a>
                  </ng-template>
                </li>
                <li class="menu-overlay">
            
                </li>
              </ul>
              <!-- <ng-container *ngIf="!search">
                <span *ngIf="auth" class="position-relative" (clickOutside)="clickedOutsideNotification()">
                  <i class="pointer text-lg" (click)="toggleNotification()" nz-icon nzType="bell" nzTheme="fill"></i>
                  <notification-box [(isVisible)]="isNotificationVisible"></notification-box>
                </span>
                <ng-container *ngIf="auth">
                  <span class="ml-3">
                    <avatar-profile></avatar-profile>
                  </span>
                </ng-container>
              </ng-container> -->
              <div *ngIf="!search" class="search-btn">
                <button nz-button nzType="default" (click)="toggleSearch()">
                  <span>
                    <span class="hidden-xl-down mr-1">{{'fsmone.search.fsm' | translate}}</span>
                    <i nz-icon nzType="search" nzTheme="outline"></i>
                  </span>
                </button>
              </div>
              <div *ngIf="search">
                <div class="d-inline-block w-95">
                  <ng-container *ngTemplateOutlet="searchPlaceholder"></ng-container>
                </div>
                <div class="float-right d-inline-block pointer py-3" (click)="toggleSearch()">
                  <i nz-icon nzType="close" nzTheme="outline"></i>
                </div>
              </div>
              <div >
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</ng-container>

<ng-template #searchPlaceholder>
  <ng-content></ng-content>
</ng-template>