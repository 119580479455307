import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Select} from "@ngxs/store";
import {GlobalState} from "src/app/core/store";
import {Observable} from "rxjs";
import {AccountOpeningConstant} from 'src/app/constant';
import { getEnv } from '@share/services/product-search.service';

@Component({
  selector: 'open-account-modal',
  templateUrl: './open-account-modal.component.html',
})
export class OpenAccountModalComponent implements OnInit {
	
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  @Input() visibility: boolean;
  @Output() closeModel = new EventEmitter<boolean>();

  readonly accountOpeningConstant = AccountOpeningConstant;
  isLoggedIn: boolean = false;
  isPersonalAcctCreated: boolean = false;
  isOtpRegistered: boolean = false;
  ACCOUNT_IMG_PATH ='assets/images/account/';
  accountType: string = AccountOpeningConstant.PERSONAL;
	
	baseUrl = getEnv() + '/fsm';
	
  constructor() { }

  ngOnInit(): void {
  }

  handleClose() {
    this.closeModel.emit(false);
  }

  validateTwoFaAccount(): void {
  }

  accountTypeOnChange(type:string){
		// let fullUrl = this.baseUrl;
        //
		// if(type == 'personal') {
		// 	fullUrl = fullUrl + '/account-opening/personal-account-opening?accountType=P&accountRole=P';
		// } else if(type == 'beneficiary') {
		// 	fullUrl = fullUrl + '/account-opening/beneficiary-account-opening?accountType=B&accountRole=P';
		// } else if(type == 'discretionary') {
		// 	fullUrl = fullUrl + '/account-opening/discretionary-account-opening?accountType=D&accountRole=P';
		// }
		//
		// window.location.assign(fullUrl);

      this.accountType = type;
  }
}
