export const environment = 
{
	dev: false,
  	production: true,
	testMessage: 'LIVE',
	apiUrl: 'https://www.fsmone.com.hk',
  	serverUrl: "/fsmmobilev2",
	/*ifastTvUrl: 'https://ifasttv-uat.ifast-gcp.com/now-showing',
	ifastTvEmbedUrl: 'https://ifasttv-uat.ifast-gcp.com/ifast-tv-now-showing-embed-external',
	ifastTvOrigin: 'https://ifasttv-uat.ifast-gcp.com'*/
	ifastTvUrl: 'https://ifasttv.com/now-showing',
	ifastTvEmbedUrl: 'https://ifasttv.com/ifast-tv-now-showing-embed-external',
	ifastTvOrigin: 'https://ifasttv.com',
	LS_URL: 'https://apiconnect.ifastcorp.com',
	ifastGlobalViewUrl: "https://www.ifastglobalview.com",
	ifastGlobalViewAesSecretKey: 'FVFdV+3zTEw^J&di'
};
