import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { IArticleRcmsTwModel} from '@api-module/model/article/i-article-rcms-tw.model';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
@Injectable({
  providedIn: 'root'
})


export class ArticleRestService {
  readonly BASE_URL: string = ApiConstant.ARTICLE;
  localeOption: string = ""
  constructor(private http: HttpClient, private paramService: HttpParamsService, private globalDataStorage: GlobalDataStorage) {
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}

  findArticleList(iArticleSimpleCriteriaModel: any) {
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_ALL_ARTICLE_LIST, iArticleSimpleCriteriaModel);
  }

  findLandingPageArticleList() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_LANDING_PAGE_ARTICLE_LIST, {});
  }

  findProductLandingPageArticleList(products?:string) {
	const params = this.paramService.buildHttpParams({products});
	return this.http.post(this.BASE_URL + ApiConstant.FIND_PRODUCT_PAGE_ARTICLE_LIST,{},{params});
  }

  findSchoolArticleList(product) {
    const params = this.paramService.buildHttpParams({product});
    return this.http.post(this.BASE_URL + ApiConstant.FIND_SCHOOL_ARTICLE_LIST, {}, {params});
  }

  findSchoolArticleListFromRCMS(product) {
    const params = this.paramService.buildHttpParams({product});
    return this.http.post(this.BASE_URL + ApiConstant.FIND_SCHOOL_ARTICLE_LIST_FROM_RCMS, {}, {params});
  }

  findArticleTagsList() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_ARTICLE_TAG_LIST, {});
  }

  findArticleDetails(articleNo: string, authState: boolean) {
	const params = this.paramService.buildHttpParams({
      articleNo
    });
	if(authState){
			return this.http.post(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.FIND_ARTICLE_DETAILS, {},{params});
		} else {
			return this.http.post(this.BASE_URL + ApiConstant.FIND_ARTICLE_DETAILS, {},{params});
		}
  }

  getRcmsArticleDtlNoClickCount(articleNo: string) {
	const params = this.paramService.buildHttpParams({
      articleNo
    });
    return this.http.post(this.BASE_URL + ApiConstant.GET_ARTICLE_DETAILS_NO_CLICK_COUNT, {},{params});
  }

  updateRcmsArticleViewCount(articleNo: string){
	const params = this.paramService.buildHttpParams({articleNo});
    return this.http.post(this.BASE_URL + ApiConstant.UPDATE_RCMS_VIEW_COUNT, {},{params});
  }

  findArticleDetailsFromDB(articleNo: string, authState: boolean) {
	const params = this.paramService.buildHttpParams({
      articleNo
    });
	if(authState){
			return this.http.post(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.FIND_ARTICLE_DETAILS_FROM_DB, {},{params});
		} else {
			return this.http.post(this.BASE_URL + ApiConstant.FIND_ARTICLE_DETAILS_FROM_DB, {},{params});
		}
    
  }

  getLatestRcmsArticleListByCategory(paramCategory: string, paramCategorysubheaderid: string,
                                     paramCategorysubid: string, paramLimit: string, paramChinese: boolean): Observable<IResponse<IArticleRcmsTwModel[]>> {
    const params = this.paramService.buildHttpParams({
      paramCategory,
      paramCategorysubheaderid,
      paramCategorysubid,
      paramLimit,
      paramChinese
    });
    return this.http.post<IResponse<IArticleRcmsTwModel[]>>(this.BASE_URL + ApiConstant.FIND_RCMS_ARTICLE_LIST, {}, {params});
  }

	getArticleByCategory(categoryId: number[], maxResult: number) {
		const params = this.paramService.buildHttpParams({categoryId,maxResult});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_ARTICLE_BY_CATEGORY,{},{params});
	}
	
	getMainArticleByCategory(categoryid:number, maxResult:number) {
		const params = this.paramService.buildHttpParams({categoryid, maxResult});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_MAIN_ARTICLE_WITH_BANNER_BY_CATEGORY_ID,{},{params});
	}
	
	getArticlePath(articleno:string, isPdf:boolean) {
		const params = this.paramService.buildHttpParams({isPdf});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_ARTICLE_PATH+'/'+articleno,{},{params});
	}
	
	findPortfolioMaterials(id: number, authState: boolean) {
		const params = this.paramService.buildHttpParams({id:id});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.FIND_PORTFOLIO_MATERIALS,{},{params});
	}
	
	getArticleListRCMS(iArticleSimpleCriteriaModel: any, authState: boolean) {
		if (authState){
			return this.http.post<IResponse<any>>(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.FIND_ALL_ARTICLE_LIST, iArticleSimpleCriteriaModel);
		} else {
			return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_ALL_ARTICLE_LIST, iArticleSimpleCriteriaModel);
		}
    
 	}

	getArticleLandingByCategory(categoryId: number[], maxResult: number, authState: boolean) {
		const params = this.paramService.buildHttpParams({categoryId,maxResult});
		if(authState){
			return this.http.post<IResponse<any>>(ApiConstant.PROTECTED + this.BASE_URL+ApiConstant.GET_ARTICLE_BY_CATEGORY,{},{params});
		} else {
			return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_ARTICLE_BY_CATEGORY,{},{params});
		}
		
	}
	
	getArticlesTagsFromRCMS(rcmsArticleTagsSearchCriteria: any) {
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.GET_ARTICLES_TAGS_FROM_RCMS , rcmsArticleTagsSearchCriteria);
	}
}