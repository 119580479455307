import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'uic-switch-v1-wrapper',
  templateUrl: './uic-switch-v1-wrapper.component.html',
  styleUrls: ['./uic-switch-v1-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UicSwitchV1WrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
