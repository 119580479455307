<div *ngIf="!fullScreen; else fullScreenTemplate ">
  <app-web-scaffold>
    <div class="row mt-4 mb-3">
      <div class="col-lg-6">
        <h3 class="mb-0">{{marketName}}</h3>
      </div>
      <div class="col-lg-6 text-right">
        <p class="text-xs text-black-6">
          <span translate="fsmone.last.updated.on" translate-default="Last updated on"></span> {{indexDate | hkDate:localeOption}}
        </p>
      </div>
    </div>
  </app-web-scaffold>
  <app-mobile-scaffold>
    <p class="text-black-6 mt-3 mb-2"><span translate="fsmone.last.updated.on"
        translate-default="Last updated on"></span> {{indexDate | hkDate:localeOption}}</p>
    <h3 class="font-weight-bold">{{marketName}}</h3>
  </app-mobile-scaffold>
  <nz-row>
    <nz-col [nzLg]="6">
      <div *ngFor="let item of periodList" class="rank-tab mr-2 rounded-circle d-inline-flex pointer mb-3"
        [ngClass]="item.value === activeButton ? 'active bg-theme text-white' : 'bg-lighter'"
        (click)="onClickPeriod(item.value)">
        <span class="px-2 py-1 text-sm">
          {{item.label}}
        </span>
      </div>
    </nz-col>
    <nz-col [nzXs]="3" [nzSm]="3" [nzMd]="2" [nzLg]="2" *ngIf="(isMobile$ | async)">
      <div class="rank-tab ml-1 rounded-circle d-inline-flex pointer mb-3 bg-lighter" (click)="onZoomChange()">
        <span class="px-2 py-1 text-sm">
          <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
        </span>
      </div>
    </nz-col>
  </nz-row>
  <!--  <h4>{{'fsmone.cumulative.performance' | translate}}</h4>-->
  <ng-container>
    <highcharts-chart (chartInstance)="getChartInstance($event)" [Highcharts]="Highcharts" [(update)]=updateFromInput
      [oneToOne]="true" [options]="chartConfiguration" [runOutsideAngular]="true" class="d-block w-100 mw-100 my-3 ">
    </highcharts-chart>
  </ng-container>
</div>
<ng-template #fullScreenTemplate>
  <div style="transform:rotate(90deg)">
    <nz-row>
      <nz-col [nzXs]="2" [nzSm]="2" [nzMd]="2" [nzLg]="2">
        <div nz-col nzSpan="1" (click)="onExitZoom()">X</div>
      </nz-col>
      <nz-col *ngFor="let item of periodList" [nzLg]="6">
        <div class="rank-tab mr-2 rounded-circle d-inline-flex pointer mb-3"
          [ngClass]="item.value === activeButton ? 'active bg-theme text-white' : 'bg-lighter'"
          (click)="onClickPeriod(item.value)">
          <span class="px-2 py-1 text-sm">
            {{item.label}}
          </span>
        </div>
      </nz-col>
    </nz-row>
    <div>
      <nz-spin [nzSpinning]="loading">
        <highcharts-chart (chartInstance)="getChartInstance($event)" [Highcharts]="Highcharts"
          [(update)]=updateFromInput [oneToOne]="true" [options]="chartConfiguration" [runOutsideAngular]="true"
          class="d-block w-100 mw-100 mh-100 h-100">
        </highcharts-chart>
      </nz-spin>
    </div>
  </div>
</ng-template>