<ng-container *ngIf="!fsmPostLoginWhitelist">
	<div class="b2c-web-header-wrapper" *ngIf="!search">
	<div class="b2c-web-header">
			<div id="post-menu-nav">
				<div class="container-fluid">
					<div nz-row nzType="flex">
						<div nz-col nzXs="24" nzSm="24" nzMd="24" nzXl="24" class="navbar">
							<ul class="nav navbar-nav p-0 m-0">
								<li *ngFor="let item of postMenu" class="dropdown position-relative">
									<a class="dropdown-toggle" [ngClass]="{'lang-ch': locale != 'en'}">
										<i *ngIf="item.icon" class="mr-1" nz-icon nzType="user" nzTheme="outline"></i>{{item?.titleKey | translate}}
									</a>
									<div class="position-absolute w-100 menu">
										<div class="dropdown-menu">
											<div *ngFor="let child of item.children">
												<a *ngIf="checkMenu(child?.titleKey)" (click)="navigate(child?.url)">{{child?.titleKey | translate}}</a>
											</div>
										</div>
									</div>
								</li>
							</ul>
							<ul class="nav navbar-nav p-0 m-0 float-right">
								<li class="dropdown">
									<a (click)="navigate('/track/main')" class="dropdown-toggle" [ngClass]="{'lang-ch': locale != 'en'}">
										{{'fsmone.watchlist.and.alert' | translate }}</a>
								</li>
								<li class="dropdown">
									<a (click)="navigate('/user-guide/list')" class="dropdown-toggle" [ngClass]="{'lang-ch': locale != 'en'}">
										{{'fsmone.user.guide' | translate }}</a>
								</li>
								<li class="dropdown">
									<a (click)="toggleNotification()" class="dropdown-toggle" [ngClass]="{'lang-ch': locale != 'en'}">
										<i class="mr-1" nz-icon nzType="bell" nzTheme="fill"></i>{{ 'fsmone.notifications' | translate }}
									</a>
									<div *ngIf="showNotification" id="notification-menu" class="position-absolute w-100 pull-right">
										<div class="dropdown-menu">
											<nz-spin *ngIf="loadingNotification" nzSimple></nz-spin>
											<div *ngIf="!loadingNotification">
												<div class="text-black" *ngFor="let notification of notifications">
													<h5>{{notification.categoryName}}</h5>
													<p>{{notification.message}} {{notification.message.length > 150? '...' : ''}}</p>
													<p class="pull-right">{{notification.sendDate}}</p>
												</div>
												<div *ngIf="notifications.length == 0">
													<div class="no-notification-title text-black">{{'fsmone.no.notifications' | translate}}</div>
												</div>
											</div>
										</div>
									</div>
								</li>
								<li *ngFor="let item of accountSettingMenu" class="dropdown">
									<a class="dropdown-toggle" [ngClass]="{'lang-ch': locale != 'en'}" [routerLink]="item?.url">
										<i class="mr-1" nz-icon nzType="setting" nzTheme="outline"></i>{{ item?.titleKey | translate}}
									</a>
									<div class="position-absolute w-100 menu pull-right">
										<div class="dropdown-menu">
											<div *ngFor="let child of item.children">
												<a (click)="navigate(child?.url)">{{child?.titleKey | translate}}</a>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="fsmPostLoginWhitelist">
	<ng-container *ngIf="!isMobileView">
		<div class="post-login-menu align-top wrapper d-inline-block ui-scroll-bar" [ngClass]="{'menu-collasped': isCollapsed}">
			<ul class="menu-wrapper scrollable" nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
				<ng-container *ngIf="!isCollapsed">
					<ng-container *ngTemplateOutlet="profileDetailTpl"></ng-container>
				</ng-container>
				<ng-container *ngFor="let module of postNewMenu; let i = index">
					<li nz-menu-item (click)="clickModule(module)" [ngClass]="{ 'menu-active': module.active, 'd-flex align-items-center': !isCollapsed, 'module-page': !module?.children }">
						<uic-fsmicon-wrapper>
							<i class="menu-icon position-relative {{ module.active ? module.activeIcon : module.icon }}" [ngClass]="{ 'text-theme': module.active }">
								<span *ngIf="module?.unreadNumber" class="unread-number">{{module?.unreadNumber > 100 ? '99+' : module?.unreadNumber}}</span>
							</i>
						</uic-fsmicon-wrapper>
						<span class="ml-2" *ngIf="!isCollapsed">{{ module?.titleKey | translate }}</span>
						<small class="module-name d-block" *ngIf="isCollapsed">{{ module?.titleKey | translate }}</small>
					</li>
					<ng-container *ngIf="!isCollapsed && module?.childrenExpanded">
						<ng-container *ngFor="let page of module?.children">
							<li *ngIf="checkMenu(page?.titleKey)" nz-menu-item class="page {{ page.active ? 'active' : '' }}" (click)="routeToPage(page.url, page.value)">{{ page?.titleKey | translate }}</li>
						</ng-container>
					</ng-container>
				</ng-container>
			</ul>
		</div>
	</ng-container>

	<ng-container *ngIf="isMobileView">
		<div class="mobile-post-login-menu bg-white">
			<div class="container-fluid">
				<div class="menu-wrapper">
					<ng-container *ngFor="let module of mobilePostMenu">
						<div class="mobile-menu-item" (click)="onClickMobileModule(module)">
							<i class="module-icon {{ module.active ? module.activeIcon : module.icon }}" [ngClass]="{ 'text-theme': module.active }"></i>
							<small class="module-name d-block" [ngClass]="{ 'text-theme font-weight-bold': module.active }">{{ module?.titleKey | translate }}</small>
						</div>
					</ng-container>
					<!-- <avatar-profile (click)="toggleMobileTradeMenu(false)"></avatar-profile> -->
				</div>
			</div>
		</div>
		<popup-full-screen-wrapper [isVisible]="showMobileSubMenu" >
			<div class="mobile-post-login-sub-menu bg-white ui-scroll-bar">
				<div class="sub-menu-wrapper">
					<i class="fsmicon-close-o ui-icon-hover text-md pointer mr-1" (click)="toggleMobileSubMenu(false)"></i>
					<span class="d-inline-block ml-1 menu-title font-weight-bold">{{ selectedSubMenu?.titleKey | translate }}</span>
					<ng-container *ngTemplateOutlet="profileDetailTpl"></ng-container>
					<ng-container *ngFor="let subModule of selectedSubMenu?.children; let first = first;">
						<ng-container *ngIf="!subModule?.children">
							<div [ngClass]="{ 'pt-3': first }">
								<div class="sub-menu-item" (click)="routeToPage(subModule?.url, subModule?.value)">
									<i *ngIf="subModule?.icon" class="{{ subModule?.icon }} page-icon mr-3"></i>
									<div>
										<span class="page-title d-block">{{ subModule.titleKey | translate }}</span>
										<!-- <small class="page-desc d-block">{{ subModule.descKey | translate }}</small> -->
									</div>
								</div>
								<nz-divider class="my-3"></nz-divider>
							</div>
						</ng-container>
						<ng-container *ngIf="subModule?.children">
							<ng-container *ngIf="subModule?.clickable">
								<div class="sub-menu-title" (click)="onClickSubModule(subModule)">
									{{ subModule.titleKey | translate }}
									<i *ngIf="!subModule?.active" class="menu-arrow fsmicon-arrow-down-o"></i>
									<i *ngIf="subModule?.active" class="menu-arrow fsmicon-arrow-up-o"></i>
								</div>
								<ng-container *ngIf="subModule?.active">
									<ng-container *ngFor="let subModuleItem of subModule?.children">
										<ng-container *ngIf="checkMenu(subModuleItem?.titleKey)">
											<div class="sub-menu-item" (click)="routeToPage(subModuleItem?.url, subModuleItem?.value)">
												<i *ngIf="subModuleItem?.icon" class="{{ subModuleItem?.icon }} page-icon mr-3"></i>
												<div>
													<span class="page-title d-block">{{ subModuleItem.titleKey | translate }}</span>
												</div>
											</div>
											<nz-divider class="my-3"></nz-divider>
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="!subModule?.clickable">
								<div [ngClass]="{ 'mt-3': first }">
									<p class="font-weight-bold text-default mb-2">{{ subModule.titleKey | translate }}</p>
									<div class="mb-3">
										<uic-card>
											<ng-container *ngFor="let subModuleItem of subModule?.children; let last = last">
												<ng-container *ngIf="checkMenu(subModuleItem?.titleKey)">
													<div class="sub-menu-item" (click)="routeToPage(subModuleItem?.url, subModuleItem?.value)">
														<i *ngIf="subModuleItem?.icon" class="{{ subModuleItem?.icon }} page-icon mr-3"></i>
														<div>
															<span class="page-title d-block">{{ subModuleItem.titleKey | translate }}</span>
														</div>
													</div>
													<nz-divider *ngIf="!last" class="my-3"></nz-divider>
												</ng-container>
											</ng-container>
										</uic-card>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</popup-full-screen-wrapper>
	</ng-container>
</ng-container>

<ng-template #profileDetailTpl>
	<div class="d-flex mt-3" [ngClass]="{'px-2':!isMobileView, 'mb-1': !showDeclarePiMessage && !showTopUpMessage}">
		<div style="width: 40px" class="flex-shrink-0">
			<img width="35" class="acc-holder-icon" [src]="'assets/images/icon/icon-' + accountBasicInfo?.iconStatus + '.png'" alt="icon">
		</div>
		<div>
			<h5 class="text-default word-break-all font-weight-semibold mb-1">{{ accountBasicInfo?.nricName }}</h5>
			<p class="text-sm text-black-6 mb-0">{{ accountBasicInfo?.riskAppetite | translate }}</p>
		</div>
	</div>
	<nz-divider *ngIf="showDeclarePiMessage || showTopUpMessage" class="my-2"></nz-divider>
	<div [ngClass]="{'px-3': !isMobileView}">
		<p class="mb-1 text-sm" *ngIf="showTopUpMessage">
			{{'fsmone.next.tier.message.1'|translate}} <b>{{'hkd'|translate}} {{ aumTopUp | number }}</b>
			{{'fsmone.next.tier.message.2'|translate}} 
			<a class="link text-sm text-decoration-underline" (click)="goToRewardPage()">{{ nextTierKey | translate }}</a>
			{{ 'fsmone.next.tier.message.3' | translate }}
			<i nz-tooltip class="fsmicon-info" [nzTooltipTitle]="tierDetailTpl">
				<ng-template #tierDetailTpl>
					{{'fsmone.new.interest.ratetime'|translate}}
					{{ grandAumLastUpdatedTime | hkDate:null: dateFormat }}.
					{{'fsmone.the.qualifying.amount.in.the.current.holdings.will.be.tabulated.on.a.per.account.basis'|translate}}
				</ng-template>
			</i>
		</p>
		<p class="mb-1 text-sm" *ngIf="showDeclarePiMessage">
			{{ 'fsmone.declare.as' | translate }}
			<a class="link text-sm text-decoration-underline" (click)="goToPiPage()">{{ 'fsmone.professional.investor' | translate }}</a>
			{{ 'fsmone.to.reach.platinum.plus.tier' | translate }}
		</p>
		<h5 class="text-sm mb-0">{{ accountBasicInfo?.status | translate }}</h5>
	</div>
	<nz-divider class="my-2"></nz-divider>
</ng-template>
