<nz-spin [nzSpinning]="isLoading">
  <app-web-scaffold>
    <form [formGroup]="chartForm" nz-form>
      <div class="container">
      <nz-card>
        <div nz-row nzAlign="middle" nzJustify="start" nzType="flex" nzGutter="16">
          <!--  
          <div  nz-col nzLg="6" nzMd="6" nzSm="24" >
            <nz-form-item class="w-100 d-inline-block align-middle mb-0">
              <nz-form-control>
                <ui-select [formControl]="category" class="w-100 text-base" nzLabelPlaceholder nzPlaceHolder="Sort By">
                  <ui-option *ngFor="let option of mainOptions" [nzLabel]="option.label" [nzValue]="option.value">
                  </ui-option>
                </ui-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="6" nzMd="6" nzSm="20"  *ngIf="showExtraOptions">
            <nz-form-item class="w-100 d-inline-block align-middle mb-0">
              <nz-form-control>
                <ui-select [formControl]="secondCategory" [nzPlaceHolder]="labelList" class="w-100 text-base"
                  nzLabelPlaceholder nzPlaceHolder="Category">
                  <ui-option *ngFor="let item of secondOptions" [nzLabel]="item.label" [nzValue]="item.value">
                  </ui-option>
                </ui-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          -->
          <div   nz-col nzLg="21" nzMd="20" nzSm="20" *ngIf="!(showExtraOptions); else spanShort">
            <nz-form-item class="w-100 d-inline-block align-middle mb-0">
              <nz-form-control>
                <nz-select [formControl]="etf" [nzDisabled]="isLoading" class="w-100 text-base" nzAllowClear
                  nzLabelPlaceholder nzPlaceHolder="{{'select' | hkTranslate:'Select'}} {{totalETFSelected.length}} - 7"
                  nzShowSearch>
                  <nz-option *ngFor="let item of etfOptions" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="3" nzMd="4" nzSm="4" class="text-center">
            <span class="font-weight-bold text-primary"><a (click)="onClickClear()">{{'fsmone.clear' | translate}}</a></span>
          </div>
        </div>
      </nz-card>
    </div>
    </form>
    <ng-template #spanShort>
      <div  nz-col nzLg="9" nzMd="8" nzSm="20">
        <nz-form-item class="w-100 d-inline-block align-middle mb-0">
          <nz-form-control>
            <nz-select [formControl]="etf" [nzDisabled]="isLoading" class="w-100 text-base" nzAllowClear
              nzPlaceHolder="Please search or select {{totalETFSelected.length}}  - 7 bonds" nzShowSearch>
              <nz-option *ngFor="let item of etfOptions" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-template>
  </app-web-scaffold>

  <app-mobile-scaffold>
    <nz-affix (nzChange)="onScroll($event)" [nzOffsetTop]="headerHeight">
      <div [ngClass]="{'w-100':mobileScrolled,'container mt-3 ':!mobileScrolled}">
        <!-- <form [formGroup]="chartForm" nz-form> -->
          <div id="selector" class="mobile-selector  border bg-white  w-100"
            [ngClass]="{'rounded':!mobileScrolled,'shadow': !mobileScrolled}">

            <nz-row nzGutter="12" class="p-2" nzType="flex" nzAlign="middle">
              <!-- <nz-col [nzSpan]="showExtraOptions ? 12 : 8" class="mb-2">
                <div (click)="!isLoading && openMainDrawer()" class="border p-2 rounded  align-middle text-black">
                  <ng-container *ngFor="let item of mainOptions">
                    <p class="m-0" *ngIf="item.value === category.value">
                      {{item.label.split(' ').slice(0, 2).join(' ')}}
                      <i class="float-right" nz-icon nzType="down" nzTheme="outline"></i>
                    </p>
                  </ng-container>
                </div>
              </nz-col>
              <nz-col *ngIf="showExtraOptions"  nzSpan="12" class="mb-2">
                <div (click)="!isLoading && openSecondDrawer()" class="border p-2 rounded  text-black">
                  <ng-container *ngFor="let item of secondOptions">
                    <p class="m-0" *ngIf="item.value === secondCategory.value">
                      {{item.label.split(' ').slice(0, 2).join(' ')}}
                      <i class="float-right" nz-icon nzType="down" nzTheme="outline"></i>
                    </p>
                  </ng-container>
                  <p *ngIf="secondCategory.value==null" class="m-0">
                    {{labelList}}
                    <i class="float-right" nz-icon nzTheme="outline" nzType="down"></i>
                  </p>
                </div>
              </nz-col> -->
              <nz-col nzSpan="12"  class="mb-2">
                <div (click)="!isLoading && openEtfDrawer()" class="border p-2 rounded  text-black">
                  <ng-container>
                    <p class="m-0">
                      Select {{totalETFSelected.length}} -7 Bonds<i class="float-right" nz-icon nzTheme="outline" nzType="down"></i>
                    </p>
                  </ng-container>
                </div>
              </nz-col>
              <nz-col nzSpan="3" class="mb-2">
                <span class="font-weight-bold text-md text-primary text-center">
                  <a (click)="onClickClear()">Clear</a>
                </span>
              </nz-col>
            </nz-row>
          </div>
        <!-- </form> -->
      </div>
    </nz-affix>
    <!--  
    <b2c-menu-drawer (filter)="onMainClassChange($event)" (viewChange)="onViewChange($event)" *ngIf="showMainDrawer"
      [itemList]="mainList" [radioValue]="mainValue" [show]="showMainDrawer" [title]="'Sort By'" [type]="'radio'">
    </b2c-menu-drawer>-->
    <!-- <b2c-menu-drawer (filter)="onSecondClassChange($event)" (viewChange)="onViewChange($event)" *ngIf="showSecondDrawer"
      [itemList]="secondList" [radioValue]="secondValue" [show]="showSecondDrawer" [title]="'Select Type'"
      [type]="'radio'"></b2c-menu-drawer> -->
    <b2c-menu-drawer (filter)="onClassChange($event)" (viewChange)="onViewChange($event)" *ngIf="showFundDrawer"
      [itemList]="etfList" [radioValue]="etfValue" [show]="showFundDrawer" [title]="'Select 2-7 Bonds'"
      [type]="'checkbox'"></b2c-menu-drawer>
  </app-mobile-scaffold>
</nz-spin>
