<div class="container">
	<nz-skeleton [nzActive]="true" [nzLoading]="loading">
	<h3>{{nzTitle}}</h3>
		<nz-collapse nzGhost="true" [nzBordered]="false" nzExpandIconPosition="left" >
			<nz-collapse-panel *ngFor="let faq of faqList" [nzHeader]="faq.question" id="{{faq.faqreportLocPk.uniqueKey}}" [nzExpandedIcon]="faq.faqreportLocPk.uniqueKey.nzActive? minusCircleIcon:plusCircleIcon ">
				<p [innerHTML]="faq.answer"></p>
			</nz-collapse-panel>
		</nz-collapse>
	</nz-skeleton>
	
	<div class="mt-4 ">
  	<a [routerLink]="faqLink" target="_blank" class="uppercase font-bold hover-to-translate"><i class="fsmicon-forward-arrow mr-2"></i>{{'fsmone.view.more' | hkTranslate:'View More'}}</a>
  </div>
</div>

<ng-template #plusCircleIcon> <i nz-icon class="d-inline-block text-info px-2" nzType="plus-circle" nzTheme="fill"> </i> </ng-template>
<ng-template #minusCircleIcon> <i nz-icon class="d-inline-block text-info px-2" nzType="minus-circle" nzTheme="fill"> </i></ng-template>