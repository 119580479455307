import {
  Component,
  Input,
	OnInit
} from '@angular/core';
// Uncomment the following line in development mode
// import { ShareService, ShareButtonsConfig, SHARE_BUTTONS } from '../../src/public-api';


@Component({
  selector: 'ui-share-buttons',
  templateUrl: './share.buttons.component.html',
  styleUrls: ['./share.buttons.component.scss']
})
export class ShareButtonsComponent implements OnInit {

//  @Input() theme = this._share.config.theme;

  /** Array of included buttons */
  @Input() include: string[];

  /** Array of excluded buttons */
  @Input() exclude: string[];

  /** Numbers of buttons to show */
  @Input() show: number;

  /** The sharing link */
  @Input() url: string;

  /** The title parameter */
  @Input() title: string = '';

  /** The description parameter */
  @Input() description: string = '';

  /** The image parameter for sharing on Pinterest */
  @Input() image: string;

  /** The tags parameter for sharing on Twitter and Tumblr */
  @Input() tags: string;

  /** Sets meta tags from document head, useful when SEO is available */
  @Input() autoSetMeta: boolean;

  /** Show buttons icons */
  @Input() showIcon = true;

  /** Show buttons text */
  @Input() showText = false;

  /** Buttons size */
  @Input() size = 0;

  /** A flag that indicates if the button's click is disabled */
  @Input() disabled: boolean;

	@Input() vertical: boolean;
	
	@Input() roundedIcon: boolean;

  /** Share dialog opened event */
//  @Output() opened = new EventEmitter<string>();

  /** Share dialog closed event */
//  @Output() closed = new EventEmitter<string>();

  constructor() {
	
  }

  ngOnInit() {
		
	}
	
	}

/**
 * Explanation of the above code:
 * ------------------------------
 Include buttons: includes only wanted buttons and excludes the rest
 Exclude buttons: excludes only the unwanted buttons
 User buttons = Include buttons - exclude buttons
 Selected Buttons = User buttons [shown number]
 */
