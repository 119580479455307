import {Component, Input} from '@angular/core';
import {ProductViewHistoryDataStorage} from 'src/app/share/storages/product-view-history-data.storage';
import {StocksInfoRestService} from '@api-module/rest/stocks-info.rest.service';
import { StockFactsheetHeaderModel } from 'src/app/share/models/stocks/stock-factsheet-header.model';
import { AppConstant } from 'src/app/constant';
import { Select } from '@ngxs/store';
import { GlobalState } from '../../../core/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-view-history',
  templateUrl: './product-view-history.component.html'
})

export class ProductViewHistoryComponent {
	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	@Input() productType? : string;
	
	stockList: StockFactsheetHeaderModel[] = [] as StockFactsheetHeaderModel[];

	constructor(
		private prodHistoryStorage: ProductViewHistoryDataStorage,
		private stocksInfoRestService: StocksInfoRestService
	) {
		prodHistoryStorage._stockList.subscribe((itemList) => {
			if(itemList){
				this.stockList = [] as StockFactsheetHeaderModel[];
				for(let stock of itemList){
					this.stocksInfoRestService.getStockQuoteByCodeExchange(stock.productCode, stock.exchange).subscribe((quoteRes)=>{
						var stockInfo = {} as StockFactsheetHeaderModel;
						stockInfo.stockName = stock.productName;
						stockInfo.exchange = stock.exchange;
						stockInfo.stockCode = stock.productCode;
						
						if (quoteRes.status === AppConstant.RESPONSE_SUCCESS && quoteRes.data) {
							stockInfo.lastPrice = quoteRes.data.D2;
							stockInfo.priceChange = quoteRes.data.H8;
							stockInfo.priceChangePercent = quoteRes.data.H14;
							stockInfo.tradingCurrency = quoteRes.data.S9;
						}
							
						this.stockList.push(stockInfo);
					});
				}
			}
		});
	}
}
