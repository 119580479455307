export class GlobalSearchConstant {
  public static readonly PRODUCT_TYPE_COLORS: any[] = [
    {productType:'UT',color:'#BD2E26'},
	{productType:'BOND',color:'#F5AD00'},
	{productType:'DPMS',color:'#005071'},
	{productType:'INSURANCE',color:'#00CB6C'},
	{productType:'STOCK',color:'#3F42B5'},
	{productType:'ETF',color:'#61C1C5'},
	{productType:'STOCK_LI',color:'#61C1C5'},
	{productType:'ARTICLE',color:'#012C5D'},
	{productType:'Faq',color:'#CC4778'}
  ];

  public static readonly STOCK_ETF_EXCHANGE_COLORS: any[] = [
    {exchange:'KLSE',color:'#687ABC'},
	{exchange:'HKEX',color:'#A3B958'},
	{exchange:'SZSE',color:'#A3B958'},
	{exchange:'SSE',color:'#A3B958'},
	{exchange:'NASDAQ',color:'#F48B85'},
	{exchange:'AMEX',color:'#F48B85'},
	{exchange:'NYSE',color:'#F48B85'},
	{exchange:'BATS',color:'#F48B85'},
	{exchange:'SGX',color:'#67B2DD'}
  ];
}