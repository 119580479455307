import {Injectable} from '@angular/core';
import {setStorage, getStorage} from '@util/local-storage';

@Injectable({
  providedIn: 'root'
})
export class MobileAppService {

  isFromMobileApp(): boolean {
    if (window.location.search?.indexOf('mobileApp=Y') > -1) {
      setStorage('isFromMobileApp', 'Y');
      return true;
    } else {
      setStorage('isFromMobileApp', 'N');
      return false
    }
  }

}
