import { AppConstant } from "./app.constant";
import { IDropdownModel } from "@api-module/model/common/i-dropdown.model";

export class TransactionConstant {
  public static readonly ADDED_TO_CART = 'addedToCart';
  public static readonly ORDER_PLACED = 'orderPlaced';
  public static readonly PAYMENT_METHOD_FPX = 'FPX';
  public static readonly PAYMENT_CURRENCY_MYR = 'MYR';
  public static readonly PAYMENT_CURRENCY_USD = 'USD';
  public static readonly PAYMENT_CURRENCY_HKD = 'HKD';
  public static readonly LOCAL_PAY_ONLINE = 'payOnline';
  public static readonly LOCAL_MAIL_IN_CHEQUE = 'mailInCheque';
  public static readonly RECOMMENDED_FUND = 'recommFund';
  public static readonly ALL = 'all';
  public static readonly FUND_HOUSE = 'fundHouse';
  public static readonly CASH_MANAGEMENT_FUNDS = 'cashFund';
  public static readonly PRS_FUND = 'prs';
  public static readonly PRS_GOV = 'prs_gov';
  public static readonly HOLDING = 'holding';
  public static readonly WATCHLIST = 'fundWatch';
  public static readonly RSP_FUNDS = 'rsp';
  public static readonly EPF_FUNDS = 'epf';
  public static readonly CMF_FUNDS = 'CMF';
  public static readonly CASH = 'cash';

  public static readonly TRUST_ACCOUNT = 'Trust Account';
  public static readonly STOCK_AND_ETF = 'Stocks / ETFs';
  public static readonly STOCK = 'Stocks';
  public static readonly ETF = 'ETFs';
  public static readonly CHEQUE_FPX = 'CHEQ';
  public static readonly CASH_MANAGEMENT_FUND = 'Cash Account';
  public static readonly CASH_FUND = 'Cash Fund';
  public static readonly GOPAYZ = 'GoPayz';
  public static readonly ADD = 'add';
  public static readonly UPDATE = 'update';
  public static readonly AMOUNT = 'amount';
  public static readonly UNITS = 'units';
  public static readonly UNIT = 'unit';

  public static readonly YES_NO_BOOLEAN_OPTIONS = [
    {
      label: AppConstant.YES_DISPLAY,
      value: true
    },
    {
      label: AppConstant.NO_DISPLAY,
      value: false
    },
  ];

  public static readonly LOCAL_BANK = 'Local';
  public static readonly OVERSEA_BANK = 'Overseas';

  public static readonly BANK_ACCOUNT_OPTION:IDropdownModel[] = [
    {
      label: 'Local Bank',
      value: 'Local'
    },
    {
      label: 'Oversea Bank',
      value: 'Overseas',
    }
  ];

  public static readonly ONLY_FOREIGN_OPTION: IDropdownModel[] = [
    {
      label: 'Oversea Bank',
      value: 'Overseas',
    }
  ];

  public static readonly ONLY_LOCAL_OPTION: IDropdownModel[] = [
    {
      label: 'Local Bank',
      value: 'Local',
    }
  ];

  public static readonly ACTIVE = 'active';

  public static readonly FIRST_TIME_APPLICATION = 'First Time Application';

  public static readonly SUBSCRIPTION = 'Subscription';

  public static readonly REDEMPTION = 'Redemption';

  public static readonly SWITCHING = 'Switching';

  public static readonly RHBCMF2 = 'MYOSKCMF';

  public static readonly CHEQUE = 'Cheque';

  public static readonly FORM_VALID = 'VALID';

  public static readonly FORM_INVALID = 'INVALID';

  public static readonly FORM_PENDING = 'PENDING';

  public static readonly UNIT_TRUST_DISPLAY = 'Funds';

  public static readonly BOND_DISPLAY = 'Bond';

  public static readonly DPMS_DISPLAY = 'Managed Portfolios';

  public static readonly FUND_FILTER_CATEGORY: IDropdownModel[] = [
    {label: 'All Funds', value: 'all'},
    {label: 'Fund Houses', value: 'fundHouse'},
    {label: 'Recommended Funds', value: 'recommFund'},
    {label: 'Cash Management Fund', value: 'cashFund'},
    {label: 'Private Retirement Funds', value: 'prs'},
    {label: 'Current Holdings', value: 'holding'},
    {label: 'EPF Approved Funds', value: 'epf'},
    {label: 'RSP Funds', value: 'rsp'}
  ];

  public static readonly RSP_UT_FILTER_CATEGORY: IDropdownModel[] = [
    {label: 'RSP Special List', value: 'rsp'},
    {label: 'Current Holdings', value: 'holding'}
  ];

  public static readonly RSP_DPMS_FILTER_CATEGORY: IDropdownModel[] = [
    {label: 'All Portfolio', value: 'all'},
    {label: 'Recommended Portfolio', value: 'recommFund'},
    {label: 'Top Up Existing Portfolio', value: 'holding'}
  ];

  public static readonly MANAGED_PORTFOLIO: string = 'Managed Portfolio';
  public static readonly CASH_INVESTMENTS = 'Cash Investments';

  public static readonly RECEIVED = 'received';


}


