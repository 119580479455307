import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {ITabModel} from '@api-module/model/common/i-tab.model';

@Component({
  selector: 'app-bond-ranking-tab',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-container *ngFor="let item of tabOptions">
    <div class="rank-tab mr-2 rounded-circle d-inline-flex pointer  "
         [ngClass]="{'bg-theme':item.active,'text-white':item.active,'bg-lighter':!item.active}"
         (click)="!loading && onClick(item.value, item.chartPeriod)">
<span *ngIf="item.langKey" class="px-2 py-1 text-sm">
        {{item.langKey|translate}}
      </span>
<span *ngIf="!item.langKey" class="px-2 py-1 text-sm">
        {{item.label}}
      </span>
    </div>
    </ng-container>
    <div class="rank-tab mr-2 rounded-circle d-inline-flex pointer   bg-lighter " *ngIf="mobileInChart" (click)="!loading && onZoom(true)">
      <span class="px-2 py-1 text-sm">
        <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
      </span>
    </div>

  `
})

export class BondRankingTabComponent {
  @Input() readonly tabOptions: ITabModel[];
  @Input() readonly loading: boolean;
  @Input() mobileInChart;
  @Output() readonly clicked = new EventEmitter<{rankPeriod: string, chartPeriod: string}>();
  @Output() readonly zoom = new EventEmitter<{fullscreen: boolean}>();
  onClick(rank: string,  chart: string) {
    this.clicked.emit({rankPeriod: rank, chartPeriod: chart});
  }
  onZoom($event): void {
    this.zoom.emit({fullscreen: $event});
  }
}
