import {Component,Input,Output,OnInit,OnChanges, SimpleChanges} from '@angular/core';

import {RouteConstant} from '@constant';

@Component({
	selector : 'app-faq',
	templateUrl: './app-faq.component.html',
	styleUrls: ['./app-faq.component.scss']
})

export class AppFaqComponent implements OnInit, OnChanges {
	
	@Input() faqList:any[] = [];
	@Input() relatedFaqLink: string = '';
	@Input() loading: boolean = false;
	@Input() nzTitle: string ='';
	faqLink: string = '/'+RouteConstant.SUPPORT+'/'+RouteConstant.CLIENT_SUPPORT+'/'+RouteConstant.FAQ+'/7/20/8723';
	
	constructor(){
		
	}
	
	ngOnInit(){
		
	}
	
	ngOnChanges(changes: SimpleChanges){
		
	}
	
}