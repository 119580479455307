<nz-spin [nzSpinning]="loading">
<div  *ngIf="!fullScreen; else fullScreenTemplate ">
<p class="mb-3"><b>{{selectedCountryName}} {{getMaturityMonthInText(selectedMonth)}} <span translate="fsmone.bond.yield.curves.government.yield" translate-default="Government Yield"></span></b></p>
<div class="mb-4">
	<ng-container *ngFor="let item of maturityMonth">
     <div class="d-inline-block mr-2 rounded-circle d-inline-flex pointer mb-2"
        [ngClass]="item[0] === activeButton ? 'active bg-theme text-white' : 'bg-lighter'"
        (click)="onClickMonth(item)">
        <span class="px-2 py-1 text-sm">
          {{locale == 'en'? item[0] : getMaturityMonthInText(item[2])}}
        </span>
      </div>
  </ng-container>  
  <ng-container *ngIf="(isMobile$ | async)">
    <div class="d-inline-block ml-1 rounded-circle d-inline-flex pointer mb-2 bg-lighter"
      (click)="onZoomChange()">
      <span class="px-2 py-1 text-sm">
        <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
      </span>
    </div>
  </ng-container>
</div>

<ng-container >
  <highcharts-chart
    (chartInstance)="getChartInstance($event)"	    
	[Highcharts]="Highcharts"
	[(update)]=updateFromInput
	[oneToOne]="true"
	[options]="chartOptions"
	[runOutsideAngular]="true"
	class="d-block w-100 mw-100 my-3 "
  >
  </highcharts-chart>
</ng-container>
</div>

</nz-spin>

<ng-template #fullScreenTemplate>
<div style="transform:rotate(90deg)">
<p><b>{{selectedCountryName}} {{selectedMonthText}} Government Yield</b></p>
  <nz-row >
  <nz-col  [nzXs]="2" [nzSm]="2" [nzMd]="2" [nzLg]="2">
	      <div nz-col nzSpan="1" (click)="onExitZoom()">X</div>
  </nz-col>   
	<div *ngFor="let item of maturityMonth">
	  <nz-col  [nzXs]="2" [nzSm]="2" [nzMd]="2" [nzLg]="2">
      <div class="rank-tab mr-2 rounded-circle d-inline-flex pointer mb-3"
        [ngClass]="item[0] === activeButton ? 'active bg-theme text-white' : 'bg-lighter'"
        (click)="onClickMonth(item)">
        <span class="px-2 py-1 text-sm">
          {{item[0]}}
        </span>
      </div>
	   </nz-col>    
	 </div>  
	</nz-row>
    <div >
      <highcharts-chart
        (chartInstance)="getChartInstance($event)"	    
		[Highcharts]="Highcharts"
		[(update)]=updateFromInput
		[oneToOne]="true"
		[options]="chartOptions"
		[runOutsideAngular]="true"
        class="d-block w-100 mw-100 mh-100 h-100">
      </highcharts-chart>
    </div>
 </div>
</ng-template>






    
