<div class="b2c-global-search-container w-100 py-1 position-relative overlay text-left" [ngClass]="{'ml-4': !isMobileView, 'px-3': !isMobileView, 'px-1': isMobileView}" [formGroup]="form">
  <nz-row nzType="flex" nzJustify="space-between" nzAlign="middle">
      <nz-col [nzXs]="2" [nzSm]="1" [nzXl]="1" [ngClass]="[isMobileView ? 'ml-1' : 'ml-3']"><i nz-icon nzType="search" nzTheme="outline"></i></nz-col>
      <nz-col [nzXs]="21" [nzSm]="22" [nzXl]="22" class="flex-grow-1">
        <nz-input-group  [nzSuffix]="clearSearchSuffixIconX" [class]="{'search-input-sm': isMobileView}">
          <input nz-input [placeholder]="(isMobileView ? 'fsmone.search' : 'search.or.enter.a.product.name')| translate" class="typeahead" formControlName="query" #typeahead />
        </nz-input-group>
      </nz-col>
      <!-- <nz-col [nzSpan]="2" class="text-right"><i class="pointer" nz-icon nzType="close" nzTheme="outline" (click)="toggleSearch()"></i></nz-col> -->
  </nz-row>
    
  <div class="py-1 px-3" [ngClass]="(isMobile$ | async) ?  'w-100 search-result-mobile position-absolute' : 'search-result-web position-absolute'" *ngIf="(queryField?.length > activationCount)">   
    <nz-tabset [(nzSelectedIndex)]="selectedIndex" (nzSelectChange)="onIndexChange()">
	   <ng-container *ngFor="let title of listOption">
         <nz-tab [nzTitle]="tabBarTitle"></nz-tab>
         <ng-template #tabBarTitle>
          {{title | hkTranslate:title}}
         </ng-template>
       </ng-container> 
 	</nz-tabset>
 	<div *ngIf="searchResults?.length && !searching">
	 	<nz-row class="mb-2">
	        	<nz-col nzLg="3" nzMd="3" nzXs="7" class="pl-3" [ngClass]="!(isMobile$|async)?'text-sm': 'text-base'" *ngIf="category==='All'||category==='STOCK' || category==='ETF'">
	        		<span *ngIf="category==='All'" translate="type" translate-default="Type"></span>
	        		<span *ngIf="category==='STOCK' || category==='ETF'">{{'exchange'|hkTranslate:'Exchange'}}</span>
	        	</nz-col>
	            <nz-col [nzLg]="(category==='All'||category==='STOCK' || category==='ETF')?'21':'24'" [nzMd]="(category==='All'||category==='STOCK' || category==='ETF')?'21':'24'" [nzXs]="(category==='All'||category==='STOCK' || category==='ETF')?'14':'24'"  [ngClass]="{'text-sm':!(isMobile$|async), 'text-base':(isMobile$|async),'pl-1':(category==='All'||category==='STOCK' || category==='ETF')
	            ,'pl-2':(category!=='All' && category!=='STOCK' && category!=='ETF')}">
	                <span *ngIf="category!=='ARTICLE'" class="pl-1">{{'product.name' | hkTranslate:'Product Name'}}</span>
	                <span *ngIf="category==='ARTICLE'" class="pl-1">{{'title' | translate}}</span>
	            </nz-col>
	     </nz-row>
	     <nz-divider class="m-0"></nz-divider>
    </div>
    <b2c-infinite-scroll (scrolled)="loadNext()" [maxHeight]="(isMobile$ | async) ? height : 400">
      <ng-container *ngIf="searchResults?.length && !searching; else statusTemplate">               
        <div *ngFor="let item of searchResults" class="my-2 pointer result pl-1" (click)="openProduct(item)">
          <nz-row>
	          <nz-col nzLg="3" nzMd="3" nzXs="7" *ngIf="category==='All'||category==='STOCK' || category==='ETF'">
		          <div class="rank-tab rounded-circle d-inline-flex pointer px-2"
				        [ngStyle]="{ 'background-color':  item.colorCode  }"
				        *ngIf="category==='All'">
				        <span class="text-white" [ngClass]="!(isMobile$|async)?'text-sm': 'text-base'">{{item.productTypeDisplay | translate}}</span>
				      </div>
				      <div class="rank-tab rounded-circle d-inline-flex pointer px-2"
				        [ngStyle]="{ 'background-color':  item?.exchangeColorCode  }"
				        *ngIf="category==='STOCK' || category==='ETF'">
				        <span class="text-white"  [ngClass]="!(isMobile$|async)?'text-sm': 'text-base'">{{item?.exchange}}</span>
				      </div>
			      </nz-col>
	          <nz-col [nzLg]="(category==='All'||category==='STOCK' || category==='ETF')?'21':'24'" [nzMd]="(category==='All'||category==='STOCK' || category==='ETF')?'21':'24'" [nzXs]="(category==='All'||category==='STOCK' || category==='ETF')?'14':'24'"  [ngClass]="!(isMobile$|async)?'text-sm': 'text-base'">
	          	<span [innerHTML]="item?.label | searchHit:queryField" style="float:left"></span>
	          	
	          	<span *ngIf="(category==='All'||category=='ARTICLE')&&item?.productType==='ARTICLE'&&item?.publishedDate" style="float:right">{{item?.publishedDate | hkDate:localeOption}}</span>          
		        <ng-container *ngIf="item?.subtitle">
			        <br>
			        <span [innerHTML]="item?.subtitle | searchHit:queryField"></span>   
		        </ng-container>
	          </nz-col>
          </nz-row>
        </div>
      </ng-container>
      <ng-template #statusTemplate>
        <div class="d-flex justify-content-center py-3" style="height: 50px;" *ngIf="searching; else noResultTemplate">
          <nz-spin nzSimple class="d-inline"></nz-spin>
          <span class='text-black-5 ml-2' translate="searching" translate-default="Searching"></span>
        </div>
      </ng-template>

      <ng-template #noResultTemplate>
        <div class="text-center text-black-5 py-3">
          <span translate="fsmone.no.result" translate-default="No Result"></span>
        </div>
      </ng-template>
      <div class="d-flex justify-content-center py-3" style="height: 50px;" *ngIf="loading">
          <nz-spin nzSimple class="d-inline"></nz-spin>
          <span class='text-black-5 ml-2' translate="widget.loading" translate-default="Loading"></span>
      </div>
      
    </b2c-infinite-scroll>
  </div>
</div>

<ng-template #searchPlaceHolder>
	<!-- <span translate="search.or.enter.a.product.name" translate-default="Search or enter a product name"></span> -->
	{{'search.or.enter.a.product.name'| translate}}
</ng-template>

<ng-template #clearSearchSuffixIconX>
    <span  nzTheme="outline" role="button" (click)="clearSearchField()" translate="clear" translate-default="Clear"></span>
    <!-- <i nz-icon nzType="close"></i> -->
</ng-template>