 import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as Highcharts from "highcharts";
import {Chart} from "highcharts";
import {AppConstant, HighchartsConstant, DateConstant} from 'src/app/constant';
import {formatDate} from '@angular/common';
import {Observable} from 'rxjs';
import {GlobalState} from '../../../../core/store';
import {Select} from '@ngxs/store';
import {BondService} from "@api-module/rest/bond.rest.service";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { TranslateService } from '@ngx-translate/core';
import {ChartCenterBase} from 'src/app/share/components/chart-centre/base/chart-centre-base'
import {deepCopy} from "@util/deep-copy";




@Component({
  selector: 'app-bond-index-data-chart',
  templateUrl: './bond-index-data-chart.component.html',
  styleUrls: ['./bond-index-data-chart.component.scss']
})


export class BondIndexDataChartComponent extends ChartCenterBase implements OnChanges {
  @Input() marketCode: string;
  @Input() marketData: any;
  @Input() indexDate: number;
  @Input() fullScreen:boolean;
  @Input() selectedCategory: any;
  @Output() readonly zoom=new EventEmitter<any>();
  @Output() readonly exitZoom=new EventEmitter<any>();
  marketName:string;
  marketType:string;
  Highcharts = Highcharts;

  chartList: any;
  chart: Highcharts.Chart;
  updateFromInput = false;
  loading:boolean=false;
  lastUpdateDate: string = "";
  date: string = "";

  periodList=[{
	      label: this.translateService.instant('fsmone.1.month'),
	      value: '1M'
	    },
	    {
	      label: this.translateService.instant('fsmone.6.months'),
	      value: '6M'
	    },
	    {
	      label: this.translateService.instant('fsmone.1.year'),
	      value: '1Y'
	    },
	    {
	      label: this.translateService.instant('fsmone.3.years'),
	      value: '3Y'
	    }];


  activeButton:string=this.periodList[0].value;
  localeOption:string = "zh-TW";
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  constructor(private cdRef: ChangeDetectorRef,private bondService: BondService, private globalDataStorage: GlobalDataStorage,private translateService: TranslateService) {
	super();
	this.setLocale();
  }


  ngOnChanges(): void {
	if(this.indexDate!=null)
	{
		this.Highcharts.setOptions({
		    lang: {   
		    	  noData: this.translateService.instant('fsmone.no.result'),
				  months: [this.translateService.instant('fsmone.bonds.factsheet.highcharts.january'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.febuary'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.march'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.apr'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.may'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.june'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.july'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.aug'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.september'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.october'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.november'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.december')],
				  shortMonths: [this.translateService.instant('fsmone.bonds.factsheet.highcharts.jan'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.feb'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.mar'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.apr'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.may'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.jun'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.jul'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.aug'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.sep'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.oct'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.nov'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.dec')],
				  weekdays: [this.translateService.instant('bond.factsheet.highcharts.sunday'),this.translateService.instant('bond.factsheet.highcharts.monday'),this.translateService.instant('bond.factsheet.highcharts.tuesday'),this.translateService.instant('bond.factsheet.highcharts.wednesday'),this.translateService.instant('bond.factsheet.highcharts.thursday'),this.translateService.instant('bond.factsheet.highcharts.friday'),this.translateService.instant('bond.factsheet.highcharts.saturday')],
				  shortWeekdays: [this.translateService.instant('bond.factsheet.highcharts.sunday'),this.translateService.instant('bond.factsheet.highcharts.monday'),this.translateService.instant('bond.factsheet.highcharts.tuesday'),this.translateService.instant('bond.factsheet.highcharts.wednesday'),this.translateService.instant('bond.factsheet.highcharts.thursday'),this.translateService.instant('bond.factsheet.highcharts.friday'),this.translateService.instant('bond.factsheet.highcharts.saturday')],
				  resetZoom: this.translateService.instant('reset.zoom'),
				  resetZoomTitle: this.translateService.instant("reset.zoom.level1")
				}
		 });
	
		 this.date =  formatDate(this.indexDate, 'dd-MMM-yyyy', AppConstant.LOCALE_EN);
	 	 if (this.marketData != null && this.marketCode != null) {
		 this.marketData.map(item => {
		    if (item.marketCode == this.marketCode) {
		       this.marketName = item.marketName;
		       this.marketType = item.marketType;
		    }
		  });
	   	 this.getIndexChartData();
	     }
	}
  }

  getIndexChartData() {
	  this.loading = true;
	  const selectedPeriod = this.getPeriod();

	this.bondService.findBondIndexChart(this.date, this.marketCode, selectedPeriod).subscribe((res: any) =>{
	    if (AppConstant.RESPONSE_SUCCESS == res.status) 
		{
	       this.chartList = res.data.data[this.marketCode];
	       this.lastUpdateDate = res.data.maxDate;
	       this.populateChart();
	    }
	});
  }

  populateChart() {
	 let seriesData: any[] = [];

	 if (this.chartList != undefined) {
	    this.chartList.map(item => {
	    item[0] = this.convertDate(item[0]);
	  });

	    seriesData.push({
	      name: this.marketName,
	      data: this.chartList
	    });

	    this.chartConfiguration.series = seriesData;
	  } else {
	    this.chartConfiguration.series = [];
	  }

		this.chartConfiguration.yAxis = {
      	title: {
        	text: this.getMarketType()
      	}
    	}

	  this.updateFromInput = true;
	  this.cdRef.markForCheck();
	  this.chart.reflow();
	  this.loading = false;
  }

  ngAfterViewInit(): void {
	  this.cdRef.detach();
	  this.cdRef.reattach();
  }

  getChartInstance(chart: Chart) {
	  this.chart = chart;
  }

  getMarketType() {
	  switch (this.marketType) {
	    case "SPREAD":
	      return this.translateService.instant('fsmone.bond.market.chart.spread.axis.y');
	    case "YIELD":
	      return this.translateService.instant('fsmone.bond.chart.centre.yield');
	    default:
	      return this.translateService.instant('fsmone.bond.market.chart.performance.axis.y');
	   }
  }

  convertDate(date: number | Date): number {
	 if (!(date instanceof Date)) {
	    date = new Date(date);
	 }
	 let hktDate = DateConstant.bypassTimeZone(date);
		return Date.UTC(hktDate.getFullYear(), hktDate.getMonth(), hktDate.getDate());
 }

 getPeriod() {
	 switch (this.activeButton) {
	   case '6M':
	     return "SIX_MTH";
	   case '1Y':
	     return "ONE_YEAR";
	   case '3Y':
	     return "THREE_YEAR";
	   case '1M':
	     return "ONE_MTH";
	 }
  }

 onClickPeriod(data){
	 this.activeButton=data;
	 this.getIndexChartData();

 }

 onZoomChange(){
	  this.zoom.emit({fullscreen:true});
 }

 onExitZoom(){
	  this.exitZoom.emit({exitZoom:true});
 }

 setLocale(){
	let locale = this.globalDataStorage.getStorage('locale') || 'ch';
	
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
  }

}
