import {Injectable} from '@angular/core';
import {IRSAEngine} from '../model/i-rsa-engine.model';
import {IPresessionModel} from '../model/i-presession.model';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, take, tap} from 'rxjs/operators';
import {AppConstant, AuthConstant, RouteConstant} from 'src/app/constant';
import {Store} from '@ngxs/store';
import {
    AuthState,
    AuthStateModel,
    LoginSuccess,
    Logout,
    SetAccountDetailsUpdate,
    SetDkaUpdate,
    SetEmisAcctUpdate,
    SetNum2FA,
    SetReRoute2FA,
    SetRiskProfileUpdate,
    SetRpqExpiry,
    SetPwdExpReminded,
    SetResetPasswordUpdated
} from '@auth-module/store';
import {IResponse} from '@api-module/model/common/i-response';
import {ActivatedRoute, NavigationExtras, Params, Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from '@api-module/service/common/cookie.service';
import {IValidationResultModel} from "@api-module/model/display/i-validation-result.model";
import {ForgetLoginRestService} from "@api-module/rest/forget-login.rest.service";
import {AccountUpdateRestService} from '@api-module/rest/account-update.rest.service';
import {CorpFirstLoginRestService} from "@api-module/rest/corp-first-login.rest.service";
import {TwoFactorAuthRestService} from "@api-module/rest/two-factor-auth.rest.service";
import {LoginRestService} from '@api-module/rest/login.rest.service';
import {IFsmPostLoginModel} from '@api-module/model/authority/i-fsm-post-login.model';
import {genRouterLink} from "@util/route";
import {SecurityRestService} from '@api-module/rest/security.rest.service';
import {IForgotLoginRequestModel} from "@api-module/model/account/i-forgot-login-request.model";
import {TwoFaRequestModel} from "../../two-fa/model/two-fa-request.model";
import {deepCopy} from "@util/deep-copy";
import {LocalStorageService} from '@util/store/storage/local-storage.service';
import { Idle } from '@ng-idle/core';
import { GlobalDataStorage } from '@share/storages/global-data.storage';


declare var RSAEngine: any;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public redirectTo: string;
	public TEST_PREFIX = 'TEST';

    constructor(
		private securityRestService: SecurityRestService,
        private loginRestService: LoginRestService,
        private router: Router,
        private store: Store,
        private cookieService: CookieService,
        private accountUpdateService : AccountUpdateRestService,
        private forgetLoginService: ForgetLoginRestService,
        private corpFirstLoginService: CorpFirstLoginRestService,
        private twoFactorAuthService: TwoFactorAuthRestService,
        private localStorageService: LocalStorageService,
        private idle: Idle,
        private globalDataStorage: GlobalDataStorage) {
    }

    getPresessionModel(): Observable<IPresessionModel> {
        return this.loginRestService.getPresession().pipe(
            map((res: Array<string>) => {
                if (res !== null) {
                    const data: Array<string> = res;
                    return {
                        e2eeSession: data[0],
                        e2eeRandom: data[1],
                        e2eePkey: data[2]
                    };
                }
                return null;
            })
        );
    }

    getNewRsaEngineWithKeys(e2eePkey: string, e2eeSession: string, e2eeRandom: string): IRSAEngine {
        const rsaEngine: IRSAEngine = new RSAEngine();
        rsaEngine.init(e2eePkey, e2eeSession, e2eeRandom);
        return rsaEngine;
    }

    getNewRsaEngine(): Observable<IRSAEngine> {
        return this.getPresessionModel().pipe(
            map((presession: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = presession;
                return this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
            })
        );
    }

    login(username: string, password: string) {
        return this.loginRestService.isModuleEnabled("FSMONE_POSTLOGIN",username).pipe(
            concatMap((res: any) => {
                if (res && res.status === AppConstant.RESPONSE_SUCCESS && res.data === true) {
                    this.globalDataStorage.setStorage('platinumPlusMessageVisible', 'Y');
                    this.globalDataStorage.setStorage('marginCallMessageVisible', 'Y');
                    return this.loginToken(username, password);
                }else{
                    return this.loginCas(username, password);
                }
            })
        );
    }

    loginToken(username: string, password: string) {
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                let hashedPassword: string;
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                if((!e2eePkey || !e2eeSession || !e2eeRandom) && window.location.href.indexOf('localhost')>-1){
                    hashedPassword = `${AuthConstant.DELIMITER}${password}`;
                }else{
                    const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                    hashedPassword = `${e2eeRandom}${AuthConstant.DELIMITER}${rsaEngine.encryptPIN1(password)}`;
                }
                return this.loginRestService.login(username, hashedPassword);
            }),
            tap((res: any) => {
                if (res && res.status === AppConstant.RESPONSE_SUCCESS) {
                    let target:IFsmPostLoginModel = res.data;
					this.store.dispatch(new LoginSuccess(target));
					this.store.dispatch(new SetNum2FA(''));
                    if(this.store.selectSnapshot(AuthState.getToken)){
                        this.idle.watch();
                    }
                    this.navigateTo2FA();
                    //this.loginSuccessHandler(null, null, null);
                    // this.router.navigate(['/']);
                }
            })
        );
    }

    loginCas(username: string, password: string) {
        let hashedPassword: string;
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                hashedPassword = `${e2eeRandom}${AuthConstant.DELIMITER}${rsaEngine.encryptPIN1(password)}`;
                return this.loginRestService.loginCas(username, hashedPassword);
            }),
            tap((res: any) => {
                if (res && res.status === AppConstant.RESPONSE_SUCCESS) {
                    this.store.dispatch(new LoginSuccess(res.data));
                    window.open(res.data.authenticationDisplay.target,"_self");
                }
            })
        );
    }

    getHashPassword(e2eeObj: any, password): string {
        const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eeObj.e2eePkey, e2eeObj.e2eeSession, e2eeObj.e2eeRandom);
        const hashedPassword: string = `${rsaEngine.encryptPIN1(password)}`;
        return hashedPassword;
    }

    logout() 
	{
        const authData: AuthStateModel = this.store.selectSnapshot(AuthState);
        const loginTime = authData?.loginTime;
        const extras: NavigationExtras = {state: {loginTime}};

		this.loginRestService.isModuleEnabled("FSMONE_POSTLOGIN",authData?.id).subscribe((res: IResponse<any>) => {
			if (res && res.status === AppConstant.RESPONSE_SUCCESS && res.data === true) {
				this.logoutNew(extras);
			}else{
				this.logoutOld(extras);
			}
		});    
    }

	logoutNew(extras: NavigationExtras){
		this.loginRestService.logout().subscribe();
		this.logoutClearState();
		this.logoutClearCookie();
        this.logoutClearLocalStorage();
        this.router.navigate([RouteConstant.LOGOUT], extras);
	}
	
	logoutOld(extras: NavigationExtras){
		this.securityRestService.logout().pipe(
			catchError((_) => of(null)),
				tap(() => {
			    	this.logoutClearStateOld();
				}), take(1)
		).subscribe();

		this.logoutClearCookie();
        this.logoutClearLocalStorage();
        this.router.navigate([RouteConstant.LOGOUT], extras);
	}

    logoutClearState() {
        const actions: Array<any> = [Logout];
        this.store.dispatch(actions.map((action => new action())));
    }

  	logoutClearStateOld() {
    	const actions: Array<any> = [
      		Logout
    	];

    	this.store.dispatch(actions.map((action => new action())));

		this.securityRestService.getFsmUrl().pipe(
			map((res: IResponse<any>) => {
				if (res && res.status === AppConstant.RESPONSE_SUCCESS) 
				{
					let url = res.data + "account/fsmoneLogout";
					window.open(url,"_self");
				}
			})
		).subscribe();

  }

    logoutClearCookie() {
        this.cookieService.delete('aiFlagDeclaration');
    }

    logoutClearLocalStorage() {
        this.localStorageService.remove('fundShoppingCarts');
        localStorage.removeItem('eddaStatus');
        localStorage.removeItem('originalFundShoppingCarts');
        localStorage.removeItem('eddaProcessedRefnoSet');
    }

    resetPassword(uid: string, oldPassword: string, newPassword: string) {
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                const hashedPassword = `${rsaEngine.encryptPIN2(oldPassword, newPassword)}`;
                const accountSettingModel = {
                    newPassword: hashedPassword,
                    e2eeRandom: e2eeRandom
                };
                return this.accountUpdateService.updatePassword(uid, JSON.stringify(accountSettingModel));
            }),
//      tap((res: IResponse<IFsmPostLoginModel>) => {
//        if (res.status === AppConstant.RESPONSE_SUCCESS) {
//          this.store.dispatch(new SetResetPasswordUpdated(false));
//          this.router.navigate([genRouterLink(RouteConstant.APP, RouteConstant.TRADE)]);
//        }
//      })
        );
    }

    resetFsmonePassword(uid: string, oldPassword: string, newPassword: string) {
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                const hashedPassword = `${rsaEngine.encryptPIN2(oldPassword, newPassword)}`;
                const accountSettingModel = {
                    newPassword: hashedPassword,
                    e2eeRandom
                };
                return this.accountUpdateService.updateFsmonePassword(uid, JSON.stringify(accountSettingModel));
            })
        );
    }

    setAccountDetailsUpdate(updateFlag) {
        this.store.dispatch(new SetAccountDetailsUpdate(updateFlag));
    }

    setRiskProfileUpdate(updateFlag) {
        this.store.dispatch(new SetRiskProfileUpdate(updateFlag));
    }

    setDkaUpdate(updateFlag) {
        this.store.dispatch(new SetDkaUpdate(updateFlag));
    }

    setRpqExpiry(updateFlag) {
        this.store.dispatch(new SetRpqExpiry(updateFlag));
    }

    setPwdExpiryReminded(reminded){
        this.store.dispatch(new SetPwdExpReminded(reminded));
    }
    
    setForceResetPwd(updateFlag){
		this.store.dispatch(new SetResetPasswordUpdated(updateFlag));
	}

    private navigateTo2FA() {
        this.accountUpdateService.get2faRegistrationStatus().subscribe(res => {
            if (res?.status === AppConstant.RESPONSE_SUCCESS) {
                this.store.dispatch(new SetReRoute2FA(res?.data?.isSmsVerified));
                const route: string[] = res?.data?.isSmsVerified ?
                    [RouteConstant.TWO_FA, RouteConstant.MAIN] :
                    [RouteConstant.TWO_FA, RouteConstant.REGISTRATION];
                this.router.navigate(route);
            }
        });
    }

    private navigateDefault() {
        return this.router.navigate([RouteConstant.APP, RouteConstant.HOLDINGS]);
    }

    loginSuccessHandler(route: ActivatedRoute, password?: string, ticket?:string) {

        this.setNum2FA();

        const authStateModel: AuthStateModel = this.store.selectSnapshot(AuthState.getAuthData);

        if (authStateModel.emailUpdate) {
            this.router.navigate([RouteConstant.EMAIL_UNVERIFIED]);
            return;
        }

        if (authStateModel.passwordUpdate) {
            this.router.navigate([RouteConstant.ACCOUNT_SETTING,RouteConstant.LOGIN_AND_SECURITY,RouteConstant.CHANGE_ACCOUNT_PASSWORD,RouteConstant.FORCE_RESET_PASSWORD]);
            return;
        }

        if (authStateModel.riskProfileUpdate) {
            this.router.navigate([RouteConstant.ACCOUNT_SETTING, RouteConstant.RISK_PROFILE]);
            return;
        }

        if (authStateModel.rpqExpiryReminderApi != null) {
            this.setRpqExpiry(authStateModel.rpqExpiryReminderApi)
        }

        if (authStateModel.dkaUpdate) {
            this.router.navigate([RouteConstant.ACCOUNT_SETTING, RouteConstant.DERIVATIVE_KNOWLEDGE_ASSESSMENT]);
            return;
        }

        if (authStateModel.passwordExpiryRemindedApi != null) {
            this.setPwdExpiryReminded(authStateModel.passwordExpiryRemindedApi);
        }

        if (authStateModel.emisAcctUpdate) {
            this.router.navigate([RouteConstant.APP, RouteConstant.ACCOUNT_UPDATE, RouteConstant.EMIS_UPDATE]);
            return;
        }


        if (this.redirectTo) {
            this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
                this.router.navigateByUrl(this.redirectTo).then();
            })
        } else {
            this.router.navigate(['/']);
        }
    }

    setNum2FA() {
        let id = this.store.selectSnapshot(AuthState.getId);
        const num2FA = this.encode2FANum(this.convertIdToNumber(id));

        this.store.dispatch(new SetNum2FA(num2FA));
    }

    convertIdToNumber(id : string): number {
        let returnNumber: number = 0;

        if (id) {
            for (let i = 0; i < id.length; i++) {
                let alphabet = id[i].toUpperCase();
                let asciiValue = alphabet.charCodeAt(0);
                let numericValue = (((asciiValue*2) + 28) * 2 - 3 + (asciiValue + 2))*2;
                returnNumber += numericValue;
            }
        }

        if (returnNumber && returnNumber !== 0 && typeof returnNumber === 'number') {
            returnNumber = Math.abs(returnNumber);
        }

        if (!returnNumber || typeof returnNumber !== 'number' || returnNumber <= 0 ) {
            returnNumber = 2282;
        }

        return returnNumber;
    }

    encode2FANum(num: number): string {
        let loginTime: Date = new Date();
        if (this.isValidDate(this.store.selectSnapshot(AuthState.getLoginTime))) {
             loginTime = new Date(this.store.selectSnapshot(AuthState.getLoginTime));
        }

        let currentTimeInMilliseconds = loginTime.getTime();
        let encodedNum: string = '';
        num = num + (currentTimeInMilliseconds * num);
        encodedNum = num.toString();
        encodedNum = btoa(encodedNum);
        return encodedNum;
    }

    isValidDate(value) {
        const parsedDate = Date.parse(value);
        return !isNaN(parsedDate);
    }

    is2FAValid(): boolean {
        let id = this.store.selectSnapshot(AuthState.getId);
        const convertedNum: number = this.convertIdToNumber(id);
        let num2FAStore: string = '';
        if (this.store.selectSnapshot(AuthState.getNum2FA) && typeof this.store.selectSnapshot(AuthState.getNum2FA) === 'string') {
            num2FAStore = deepCopy(this.store.selectSnapshot(AuthState.getNum2FA));
        } else {
            return false;
        }

        if (!num2FAStore) {
            return false;
        }
        try {
            num2FAStore = atob(num2FAStore);
        } catch (error) {
            return false;
        }

        const numericRegex = /^[0-9]+$/;

        if (!numericRegex.test(num2FAStore)) {
            return false;
        }
        let decodeNum2FA = Number(num2FAStore);
        decodeNum2FA = decodeNum2FA - convertedNum;
        decodeNum2FA = decodeNum2FA/convertedNum;

        const currentTime = new Date().getTime();

        const timeDifference = currentTime - decodeNum2FA;

        if (timeDifference < 0) {
            return false;
        }

        const timeAllowed = 59 * 60 * 1000;

        const isValid = timeAllowed > timeDifference;
        return isValid;
    }

    public checkLoginRedirect(authStateModel: AuthStateModel, isAuthenticated: boolean, state: RouterStateSnapshot) {
        const isValid = this.is2FAValid();

        let isSMSVerified: boolean = authStateModel.reRoute2FA;

        const twoFAroute: string[] = isSMSVerified ?
            [RouteConstant.TWO_FA, RouteConstant.MAIN] :
            [RouteConstant.TWO_FA, RouteConstant.REGISTRATION];

        if (isAuthenticated && !isValid) {
            if (genRouterLink(RouteConstant.TWO_FA, RouteConstant.MAIN) === state.url || genRouterLink(RouteConstant.TWO_FA, RouteConstant.REGISTRATION) === state.url || genRouterLink(RouteConstant.TWO_FA, RouteConstant.SETUP_OTP) === state.url) {
                return true;
            } else {
                this.router.navigate(twoFAroute);
            }
        }
        if (authStateModel.passwordUpdate) {
            if (genRouterLink(RouteConstant.ACCOUNT_SETTING,RouteConstant.LOGIN_AND_SECURITY,RouteConstant.CHANGE_ACCOUNT_PASSWORD,RouteConstant.FORCE_RESET_PASSWORD) === state.url) {
                return true;
            } else {
                this.router.navigate([
                    genRouterLink(RouteConstant.ACCOUNT_SETTING,RouteConstant.LOGIN_AND_SECURITY,RouteConstant.CHANGE_ACCOUNT_PASSWORD,RouteConstant.FORCE_RESET_PASSWORD)]);
            }
        }
        if (authStateModel.riskProfileUpdate) {
            if (genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.RISK_PROFILE) === state.url) {
                return true;
            } else {
                this.router.navigate([
                    genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.RISK_PROFILE)]);
            }
        }
        if (authStateModel.dkaUpdate) {
            if (genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.DERIVATIVE_KNOWLEDGE_ASSESSMENT) === state.url) {
                return true;
            } else {
                this.router.navigate([
                    genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.DERIVATIVE_KNOWLEDGE_ASSESSMENT)]);
            }
        }
        return true;
    }

    navigateRedirectOrDefault(route: ActivatedRoute) {
        if (!route) {
            this.navigateDefault();
        }
        const params: Params = route.snapshot.queryParams;
        const url: string = params[RouteConstant.REDIRECT];

        if (!params || Object.keys(params).length === 0 || !url || url.length === 0) {
            this.navigateDefault();
        }
        else {
            if (url && url.length > 0) {
                this.router.navigateByUrl(url, { replaceUrl: true });
            }
            else {
                this.navigateDefault();
            }
        }
    }

    setEmisAcctUpdate(updateFlag) {
        this.store.dispatch(new SetEmisAcctUpdate(updateFlag));
    }

    recoverLoginId(forgotLoginRequestModel: IForgotLoginRequestModel): Observable<IResponse<IValidationResultModel>>{
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                const hashedOTP: string = `${rsaEngine.encryptPIN1(forgotLoginRequestModel.encryptedOtp)}`;
                forgotLoginRequestModel.encryptedOtp = hashedOTP;
                forgotLoginRequestModel.e2eeRnd = e2eeRandom;
                return this.forgetLoginService.recoverLoginId(forgotLoginRequestModel);
            })
        );
    }

    resetPasswordSubmit(forgotLoginRequestModel: IForgotLoginRequestModel): Observable<IResponse<IValidationResultModel>>{
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                const hashedNewPwd: string = `${rsaEngine.encryptPIN1(forgotLoginRequestModel.encryptedPassword)}`;
                const hashedOTP: string = `${rsaEngine.encryptPIN1(forgotLoginRequestModel.encryptedOtp)}`;
                forgotLoginRequestModel.encryptedOtp = hashedOTP;
                forgotLoginRequestModel.encryptedPassword = hashedNewPwd;
                forgotLoginRequestModel.e2eeRnd = e2eeRandom;
                return this.forgetLoginService.resetPasswordSubmit(forgotLoginRequestModel);
            })
        );
    }

    corpFirstLoginSubmit(forgotLoginRequestModel: IForgotLoginRequestModel): Observable<IResponse<IValidationResultModel>>{
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                const hashedNewPwd: string = `${rsaEngine.encryptPIN1(forgotLoginRequestModel.encryptedPassword)}`;
                const hashedOTP: string = `${rsaEngine.encryptPIN1(forgotLoginRequestModel.encryptedOtp)}`;
                forgotLoginRequestModel.encryptedOtp = hashedOTP;
                forgotLoginRequestModel.encryptedPassword = hashedNewPwd;
                forgotLoginRequestModel.e2eeRnd = e2eeRandom;
                return this.corpFirstLoginService.corpFirstLoginSubmit(forgotLoginRequestModel);
            })
        );
    }

    getE2eeAndHashedPwd(password: string): Observable <any> {
        return this.getPresessionModel().pipe(
            map((presession: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = presession;
                const rsaEngine: IRSAEngine = new RSAEngine();
                rsaEngine.init(e2eePkey, e2eeSession, e2eeRandom);
                const hashedPassword: string = `${rsaEngine.encryptPIN1(password)}`;
                const accountSettingModel = {
                    encryptedPw: hashedPassword,
                    e2eeRandom: e2eeRandom,
                };
                return accountSettingModel;
            })
        );
    }

    authenticateOtp(otpMode: string, mobileNo: string, event: any, otpId: string): Observable<IResponse<IValidationResultModel>> {
        return this.getPresessionModel().pipe(
            concatMap((res: IPresessionModel) => {
                const {e2eePkey, e2eeSession, e2eeRandom} = res;
                const rsaEngine: IRSAEngine = this.getNewRsaEngineWithKeys(e2eePkey, e2eeSession, e2eeRandom);
                const hashedOTP: string = `${rsaEngine.encryptPIN1(event)}`;

                let twoFaRequestModel: TwoFaRequestModel = {};
                twoFaRequestModel.paramOtpMode = otpMode;
                if (mobileNo) {
                    twoFaRequestModel.paramMobileNumber = mobileNo;
                }
                twoFaRequestModel.paramEncryptedPassword = hashedOTP;
                twoFaRequestModel.paramE2eeRandom = e2eeRandom;
                twoFaRequestModel.paramOtpId = otpId;
                return this.twoFactorAuthService.authenticateOneTimePassword(twoFaRequestModel);
            })
        );
    }

		checkPassword(password: string): Observable<any>{
			return this.getE2eeAndHashedPwd(password).pipe(
				concatMap(res=>{
					return this.loginRestService.checkPassword(res.encryptedPw,res.e2eeRandom);
				})
			)
		}
}
