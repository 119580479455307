import {Component, OnInit, Input, Output, OnChanges, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'as-calculator-pie-chart',
	templateUrl: './auto-sweep-calculator-pie-chart.component.html',
	styles: ['./auto-sweep-calculator-pie-chart.component.scss'],
})

export class AutoSweepCalculatorPieChartComponent implements OnInit, OnChanges{
	
	@Input() nzAllocationDataList: any=[];
	@Input() nzIsMobile: boolean = false;
	@Input() nzPieTitle: string = '';
	
	@Input() showLegend: boolean = true;
	
	highcharts= Highcharts;
	chart: Highcharts.Chart;
	chartOption;
	constructor(private translate: TranslateService){}
	
	ngOnInit(){
		console.log(this.nzAllocationDataList);
		this.chartOption = this.initPieChartConfig(this.nzPieTitle);
		this.amendChartConfig(this.chartOption, this.nzAllocationDataList, this.nzPieTitle);
	}
	
	ngOnChanges(change: SimpleChanges){
		console.log(change.nzAllocationDataList);
		this.chartOption = this.initPieChartConfig(this.nzPieTitle);
		this.amendChartConfig(this.chartOption, this.nzAllocationDataList, this.nzPieTitle);
	}
	
	getChartInstance(chart: Highcharts.Chart){
		this.chart = chart
	}
	
	initPieChartConfig(title: string) {
		let config = {
			chart: {
				type: 'pie',
				margin: 0,
				backgroundColor: 'transparent',
				spacingLeft: 0,
				spacingRight: 0,
				width:  200,
				height: 200,
				events: {
					load: function() {
						var chart = this,
							x = chart.plotLeft + (chart.series[0].center[0]),
							y = chart.plotTop + (chart.series[0].center[1]),
							box;

						chart.pieCenter = chart.renderer.text('<small><b>' + title + '</b></small>', x, y, true)
							.css({
								fontFamily: 'SF Pro Text',
								'font-weight': 200,
								width: '100px',
								transform: 'translateY(38%)',
								fontSize: '100px',
								'text-align': 'center',
								'word-wrap': 'break-word'
							}).add();

						box = chart.pieCenter.getBBox();
						chart.pieCenter.attr({
							x: x - box.width / 2,
							y: y - box.height / 4
						});
					},
					redraw: function() {
						var chart = this,
							x = chart.plotLeft + (chart.series[0].center[0]),
							y = chart.plotTop + (chart.series[0].center[1]),
							box;

						chart.pieCenter = chart.renderer.text('<small><b>' + title + '</b></small>', x, y, true)
							.css({
								fontFamily: 'SF Pro Text',
								'font-weight': 200,
								width: '100px',
								transform: 'translateY(38%)',
								fontSize: '18px',
								'text-align': 'center',
								'word-wrap': 'break-word'
							}).add();

						box = chart.pieCenter.getBBox();
						chart.pieCenter.attr({
							x: x - box.width / 2,
							y: y - box.height / 4
						});
					}
				}

			},
			exporting: {
				enabled: false
			},
			title: {
				text: null
			},
			subtitle: {
				text: ''
			},
			legend: {
				borderRadius: 0,
				enabled: true,
				floating: true,
				symbolWidth: 8,
				symbolHeight: 8,
				symbolRadius: 8,
				itemMarginBottom: 10
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: false
					},
					dataGrouping: {
						enabled: false
					}
				},
				pie: {
					shadow: false,
					center: ['50%', '50%'],
//					colors: this.highchartColorList
				}
			},
			series: [],
			drilldown: {},
			tooltip: {
				shared: true,
				useHTML: true,
				outside: true,
				backgroundColor: 'rgba(255,255,255,1)',
				headerFormat: '<span style="color:{point.color}">\u25CF {point.point.localeName}</span><br/>',
				pointFormat: '{point.percentage:.2f}%',
				footerFormat: '',
				valueDecimals: 2,
			}


		};
		return config;
	}
	
	amendChartConfig(pieChartConfig, seriesData: any, title: string) {
		pieChartConfig.series = [{
			dataLabels: {
				enabled: false
			},
			innerSize: '60%',
			animation: false,
			colorByPoint: true,
			//name: this.translateService.instant(title),
			data: seriesData
		}];

	}
	
}