<nz-table #indexDataTable [nzData]="marketNameList" class="table-even" nzFrontPagination="false" nzShowPagination="false"
          >
  <thead >
  <tr >
    <th class="bg-lighter"><span translate="fsmone.bonds.category" translate-default="Bond Category"></span></th>
    <th class="bg-lighter" *ngIf="selectedCategory === 'yield'"><span translate="fsmone.bond.yield.latest" translate-default="Latest %"></span></th>
    <th class="bg-lighter" *ngIf="selectedCategory === 'spread'"><span translate="fsmone.bond.spread.bps" translate-default="Spreads(bps)"></span></th>
    <th class="bg-lighter text-right"><span translate="fsmone.bond.change.one.wk" translate-default="Chg 1 Wk"></span></th>
    <th class="bg-lighter text-right"><span translate="fsmone.bond.change.one.mth" translate-default="Chg 1 Mth"></span></th>
  </tr>
  </thead>

  <tbody>
  <ng-template ngFor let-data [ngForOf]="indexDataTable.data">
    <tr>
      <td>{{data.marketName}}</td>
      <td *ngIf="selectedCategory !== 'performance' && marketIndexes[data.marketCode]?.latest < 0" class="text-loss">
        {{marketIndexes[data.marketCode]?.latest.toFixed(3)}}<span *ngIf="selectedCategory === 'yield'">%</span>
      </td>
      <td *ngIf="selectedCategory !== 'performance' && marketIndexes[data.marketCode]?.latest >= 0" class="text-profit">
        {{marketIndexes[data.marketCode]?.latest.toFixed(3)}}<span *ngIf="selectedCategory === 'yield'">%</span>
      </td>
      <td *ngIf="marketIndexes[data.marketCode]?.oneWeek < 0" class="text-loss text-right text-nowrap">
        <i class="anticon anticon-caret-down"></i>{{marketIndexes[data.marketCode]?.oneWeek.toFixed(3)}}
      </td>
      <td *ngIf="marketIndexes[data.marketCode]?.oneWeek >= 0" class="text-profit text-right text-nowrap">
        <i class="anticon anticon-caret-up"></i>{{marketIndexes[data.marketCode]?.oneWeek.toFixed(3)}}
      </td>
      <td *ngIf="marketIndexes[data.marketCode]?.oneMonth < 0" class="text-loss text-right text-nowrap">
        <i class="anticon anticon-caret-down"></i>{{marketIndexes[data.marketCode]?.oneMonth.toFixed(3)}}
      </td>
      <td *ngIf="marketIndexes[data.marketCode]?.oneMonth >= 0" class="text-profit text-right text-nowrap">
        <i class="anticon anticon-caret-up"></i>{{marketIndexes[data.marketCode]?.oneMonth.toFixed(3)}}
      </td>
    </tr>
  </ng-template>
  </tbody>
</nz-table>


