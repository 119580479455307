<div class="b2c-global-search-container w-100 py-1 mt-3 mb-4  d-flex position-relative" [formGroup]="form">
  <div *ngIf="mobile; else webTemplate">
    <b2c-mobile-select formControlName="category" title="Select Search Category" 
      [single]="true" [selectedTemplate]="customMobileSelectTemplate" [transparent]="true">
      <b2c-mobile-select-option *ngFor="let item of activeProductCataloque" [value]="item">
        <span>
          <b2c-ui-icon [name]="item?.icon" class="b2c-search-category mr-3"></b2c-ui-icon>
          {{ item?.label | translate}}
        </span>
      </b2c-mobile-select-option>
    </b2c-mobile-select>

    <ng-template #customMobileSelectTemplate let-selected="selected">
      <span>
        <b2c-ui-icon [name]="selected?.value?.icon"></b2c-ui-icon>
      </span>
    </ng-template>
  </div>
  <ng-template #webTemplate>
    <div>
      <nz-select style="width: 250px;" [nzCustomTemplate]="customSelectTemplate" formControlName="category">
        <nz-option nzCustomContent [nzLabel]="item?.label" [nzValue]="item" *ngFor="let item of activeProductCataloque" class="py-2">
          <b2c-ui-icon [name]="item?.icon" class="b2c-search-category mr-3"></b2c-ui-icon>
          {{ item?.label | translate}}
        </nz-option>
      </nz-select>
  
      <ng-template #customSelectTemplate let-selected>
        <b2c-ui-icon [name]="selected?.nzValue?.icon" class="b2c-search-category mr-3"></b2c-ui-icon>
        <span class="text-black" *ngIf="!mobile">{{ selected?.nzLabel | translate}}</span>
      </ng-template>
    </div>
  </ng-template>
  
  <div class="flex-grow-1 ml-2">
    <input nz-input placeholder="{{'search.or.enter.a.product.name'|translate}}" class="typeahead" formControlName="query" #typeahead/>
  </div>

  <div class="position-absolute" style="z-index: 1000" [ngClass]="mobile ? 'search-result-mobile' : 'search-result-web'" *ngIf="(queryField?.length > activationCount)">
    <b2c-infinite-scroll (scrolled)="nextPage.emit()" [maxHeight]="mobile ? height : 400">
      <ng-container *ngIf="results?.length && !searching; else statusTemplate">
        <div *ngFor="let item of results" class="my-2 pointer p-1 result" (click)="selectResult(item)">
          <span [innerHTML]="item?.label | searchHit:queryField"></span>
          <ng-container *ngIf="item?.subtitle">
            <br>
            <span>{{ item?.subtitle }}</span>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #statusTemplate>
        <div class="d-flex justify-content-center py-4" style="height: 60px;" *ngIf="searching; else noResultTemplate">
          <nz-spin nzSimple class="d-inline"></nz-spin>
          <span class='text-black-5 ml-2'>{{ 'searching' | translate }}</span>
        </div>
      </ng-template>

      <ng-template #noResultTemplate>
        <div class="text-center text-black-5 py-3">
          {{ 'the.search.returned.no.results.' | translate }}
        </div>
      </ng-template>
      
    </b2c-infinite-scroll>
  </div>
</div>