export function setStorage(key: string, data: any) {
  if (typeof data !== 'string') {
    data = JSON.stringify(data);
  }

  window.localStorage.setItem(key, data);
}

export function getStorage(key: string) {
  const item = window.localStorage.getItem(key);

  if (item === null) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (_) {
    return item;
  }
}

export function removeItemFromStorage(key: string) {
  window.localStorage.removeItem(key);
}

export function removeItemsFromStorage(...keys: Array<string>) {
  keys.forEach(key => window.localStorage.removeItem(key));
}
