import {Injectable} from "@angular/core";
import {Observable, throwError} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthRestService} from '@api-module/rest/auth.rest.service';
import {AuthState, SetAuthToken} from '@auth-module/store';
import {concatMap, share, tap} from 'rxjs/operators';
import {IResponse} from '@api-module/model/common/i-response';
import {IOAuthTokenModel} from '@api-module/model/authority/i-oauth-token-model';
import {AppConstant, AuthConstant} from 'src/app/constant';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiConstant } from '@api-module/api.endpoint.constant';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {
  private readonly refreshTokenCache: Map<string, Observable<any>> = new Map();
  private readonly EXCLUDE_LIST: Array<string> = [
    ApiConstant.LOGOUT,
    ApiConstant.LOGIN
  ];

  constructor(private store: Store, private authRestService: AuthRestService, private authService: AuthService) {

  }

  tryRefreshToken(err: any, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string = req.headers.get(AuthConstant.X_AUTH_HEADER);

    if (this.EXCLUDE_LIST.indexOf(req.url) > -1 || !accessToken) {
      return throwError(err);
    }

    let refreshToken$: Observable<any> = this.refreshTokenCache.get(accessToken);

    if (!refreshToken$) {
      this.refreshTokenCache.set(accessToken, this.refreshToken());
      refreshToken$ = this.refreshTokenCache.get(accessToken);
    }

    return refreshToken$.pipe(
      concatMap((res: IResponse<IOAuthTokenModel>) => {
        if (this.checkResponse(res)) {
          const header = {};
          header[AuthConstant.X_AUTH_HEADER] = res.data.access_token;

          return next.handle(req.clone({
            setHeaders: header
          }));
        }

        return throwError(err);
      })
    );
  }

  private refreshToken() {
    const refreshToken: string = this.store.selectSnapshot(AuthState.getRefreshToken);

    return this.authRestService.refreshToken(refreshToken).pipe(
      tap((res: IResponse<IOAuthTokenModel>) => {
        if (this.checkResponse(res)) {
          const { access_token, refresh_token } = res.data;

          this.store.dispatch(new SetAuthToken(access_token, refresh_token));
        }
        else {
          this.authService.logout();
        }
      }),
      share()
    );
  }

  private checkResponse(res: IResponse<IOAuthTokenModel>) {
    return res.status === AppConstant.RESPONSE_SUCCESS && res?.data?.access_token && res?.data?.refresh_token;
  }

}
