import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IArticleBasicModel } from '@api-module/model/article/i-article-basic-model';
import { Select, Store } from '@ngxs/store';
import { GlobalState } from '../../../../core/store';
import { Observable, Subscription } from 'rxjs';
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse } from "@api-module/model/common/i-response";
import { IArticleDisplayModel } from '@api-module/model/article/i-article-display.model';
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import { ArticleRestService } from 'src/app/api/rest/article.rest.service';
import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";
import { B2CSharePlacementEnum } from 'b2c-ui/share';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/core/service/page.service';

import {NgAddToCalendarService, ICalendarEvent} from '@trademe/ng-add-to-calendar'
import {SeoService} from "@share/services/seo.service";
import { HeaderStorageService } from '@share/services/header/header-storage.service';
import { AuthState } from '@auth-module/store';
import { tap } from 'rxjs/operators';
import { WatchlistRestService } from "@api-module/rest/watchlist.rest.service";
import { NzMessageService } from 'ng-zorro-antd';

@Component({
	selector: 'article-details-container',
	templateUrl: './article-details.component.html',
	styleUrls: ['./article-details.component.scss'],
	providers: [SubHandlingService,SeoService, NzMessageService]
})

export class ArticleDetailsComponent implements OnInit, OnChanges {

	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	readonly RouteConstant = RouteConstant;
	readonly FundConstant = FundConstant;
	readonly AppConstant = AppConstant;
	readonly B2CSharePlacementEnum = B2CSharePlacementEnum;
	highlightArticle: IArticleBasicModel;
	topArticles: IArticleBasicModel[] = [];
	bottomArticles: IArticleBasicModel[] = [];
	relatedArticleList: IArticleBasicModel[] = [];
	sideRelatedArticleHeader = "";

	articleNo: string;
	horizontal: boolean = true;
	safeUrl: SafeHtml;
	ARTICLE_IMAGE_PATH = 'assets/images/article/';
	articleDisplayModel: IArticleDisplayModel = {} as IArticleDisplayModel;
	isPdf: boolean = false;
	isWebcast: boolean = false;
	isLoading: boolean = false;
	localeOption: string = "zh-TW";
	isRCMS: boolean = true;
	relatedArticleDisplay = false;

	isPdf2: boolean = false;
	isWebcast2: boolean = false;
	safeUrl2: SafeHtml;

	isOpen: boolean = true;
	relatedArticle: IArticleDisplayModel = {} as IArticleDisplayModel;
	otherRelatedArticle: any[] = [];

	videoArticle: any;
	urlShared: string;
	
	isEvent: boolean = false;
	event: any;
	eventLink: string = '';
	eventStatus: string = '';
	addToCalendarUrl: SafeUrl ;
	
	articleSaved: boolean = false;
	auth: boolean = false;
	
	newEvent:any = {
        // Event title
        title: '',
        // Event start date
        start: new Date('January 01, 1970 09:00'),
        // Event duration (IN MINUTES)
        duration: 0,
        // If an end time is set, this will take precedence over duration (optional)
        end: new Date('January 01, 1970 09:00'),
        // Event Address (optional)
//        address: '',
        // Event Description (optional)
        description: ''
    };

	headerSubscription: Subscription;
    headerHeight = 0;

	constructor(
		private router: Router,
		private articleService: ArticleRestService,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private globalDataStorage: GlobalDataStorage,
		private translate: TranslateService,
		private pageService: PageService,
		private addToCalService: NgAddToCalendarService,
		private seoService:SeoService,
		private store: Store,
		private watchlistRestService: WatchlistRestService,
		private message: NzMessageService,
		private headerStorageService: HeaderStorageService
	) {
		this.urlShared = window.location.href;
		this.headerSubscription = this.headerStorageService.headerHeight.subscribe(height => {
            this.headerHeight = height;
        });
	}

	ngOnInit(): void {
		this.store.select(AuthState.isAuthenticated).pipe(
      		tap((auth: boolean) => { 
	        this.auth = auth;
	      })
	    ).subscribe();

		this.articleNo = this.route.snapshot.params['articleno'];
		if (this.articleNo.charAt(0) == 'd') {
			this.isRCMS = false;
			this.articleNo = this.articleNo.replace('d', '');
		}
		if (this.articleNo.includes('rcms')) {
			this.isRCMS = true;
			this.articleNo = this.articleNo.replace('rcms', '');
		}
		if(this.articleNo.includes('-')) {
			let _idStrings = this.articleNo.split('-');
			this.articleNo = _idStrings[_idStrings.length -1];
		}
		this.seoService.updateCanonicalURL(location.origin + '/article/article-details/' + this.articleNo);
		if(localStorage.getItem('isEvent') && localStorage.getItem('isEvent')=='true'){
			
			this.isEvent = true;
			localStorage.removeItem('isEvent');
			
			this.event = JSON.parse(localStorage.getItem('event'));
			localStorage.removeItem('event');
			
			if(localStorage.getItem('event.status')){
				this.eventStatus = localStorage.getItem('event.status');
				localStorage.removeItem('event.status');
			}
			
			if(localStorage.getItem('event.link')) {
				this.eventLink = localStorage.getItem('event.link');
				localStorage.removeItem('event.link');
			}
			
			this.newEvent = {
				title: this.event?.title,
		        start: new Date(this.event?.startDate),
		        duration: 0,
		        end: new Date(this.event?.endDate),
		        description: this.event?.eventCategory + ' <br> ' + this.event?.venue + ' <br> ' +  this.event?.webinarLink
			}
		}
		
		
		
		this.getArticleDetails();
		this.setLocale();
		
		this.addToCalendarUrl = this.sanitizer.bypassSecurityTrustUrl(this.addToCalService.getHrefFor(this.addToCalService.calendarType.iCalendar,this.newEvent))
	}

	ngOnChanges(changes: SimpleChanges): void {

	}

	ngOnDestroy() {
		this.seoService.removeCanonicalURL();
		this.headerSubscription?.unsubscribe();
	}

	getArticleDetails() {
		this.isLoading = true;
		if (this.router.url.indexOf('webcast') != -1) {
			this.isWebcast = true;
			this.router.navigate([RouteConstant.WEBCAST, RouteConstant.VIDEOS, this.articleNo]);
		} else if (this.isRCMS) {
			this.isLoading = true;
			this.articleService.findArticleDetails(this.articleNo, this.auth).subscribe((res: IResponse<any>) => {
				if (AppConstant.RESPONSE_FAILED === res.status || (AppConstant.RESPONSE_SUCCESS === res.status && res.data!=null && Object.keys(res.data).length===0)) {
					this.getLocalDBArticle(this.articleNo);
				} else if (AppConstant.RESPONSE_SUCCESS === res.status) {
					this.isLoading = false;
					if (res.data.publishedDate) {
						this.articleDisplayModel.articleLogDate = new Date(res.data.publishedDate);
					}

					this.articleDisplayModel.articleTitle = res.data.title;
					this.articleDisplayModel.category = res.data.category;
					this.articleDisplayModel.articleSynopsis = res.data.synopsis;
					this.articleDisplayModel.articleHtml = res.data.content;
					this.articleDisplayModel.featuredImageName = res.data.featuredImage;
					this.articleDisplayModel.articleTag = res.data.tags;
					this.articleDisplayModel.articleAuthor = res.data.authorName;
					this.articleDisplayModel.articleAuthorImage = res.data.authorImage;
					this.articleDisplayModel.products = res.data.products;
					this.articleDisplayModel.translatedCategory = res.data.translatedCategory;
					this.articleDisplayModel.disclaimer = res.data.disclaimer;
					this.articleDisplayModel.saved = res.data.saved;	
					this.articleDisplayModel.articleNo = res.data.articleId;				

					this.setMetaTag(this.articleDisplayModel.articleTitle, this.articleDisplayModel.articleSynopsis, this.articleDisplayModel.featuredImageName);

					var containPdf = res.data.isPdf;
					if (containPdf == "Y") {
						let _content: string = res.data.content;
						if (_content.includes('fundsupermart')) {
							let _index: number = _content.indexOf('/ext/');
							this.articleDisplayModel.articleHtml = this.getEnv() + _content.substring(_index);
						}
						this.isPdf = true;
					}
					var webcast = res.data.webcast;
					if (webcast == "1") {
						this.isWebcast = true;
						this.safeUrl = this.parseSafeHtml(res.data.content);
					}

					if (this.articleDisplayModel.category != null && this.articleDisplayModel.category != undefined) {
						this.findTop4RelatedArticles(this.articleDisplayModel.category);
					}
							
					this.getRcmsRelatedArticle(this.articleNo);
					
					if(this.articleDisplayModel.saved === "Y"){
						this.articleSaved = true;
					} 

				}
			});
		} else {
			this.getLocalDBArticle(this.articleNo);
		}
	}
	
	getLocalDBArticle(articleId){
		this.isLoading = true;
			this.articleService.findArticleDetailsFromDB(this.articleNo, this.auth).subscribe((res: IResponse<any>) => {
				if (AppConstant.RESPONSE_SUCCESS === res.status) {
					if (!res.data.details) {
						return;
					}
					if(res.data.details.logDate){
						this.articleDisplayModel.articleLogDate = new Date(res.data.details.logDate);
					}else{
						this.articleDisplayModel.articleLogDate = null;
					}
					
					this.articleDisplayModel.articleTitle = res.data.details.title;
					this.articleDisplayModel.articleSynopsis = res.data.details.synopsis;
					this.articleDisplayModel.articleHtmlfile = res.data.details.htmlFile;
					this.articleDisplayModel.articleAuthor = res.data.details.author;
					this.articleDisplayModel.articleAuthorImage = this.getEnv() + '/ext/author/fsmonehk_761.jpeg';
					this.articleDisplayModel.category = res.data.details.categoryid;
					this.articleDisplayModel.saved = res.data.saved;
					this.articleDisplayModel.articleNo = res.data.details.articleno;	

					this.setMetaTag(this.articleDisplayModel.articleTitle, this.articleDisplayModel.articleSynopsis, this.articleDisplayModel.featuredImageName);

					if (res.data.articlePath) {
						this.articleDisplayModel.articleHtml = res.data.articlePath;
						this.isPdf = true;
					} else if (res.data.htmlFile) {
						let _htmlFile = res.data.htmlFile;
						if (_htmlFile.indexOf('fundsupermart') != -1) {
							_htmlFile = _htmlFile.replaceAll('fundsupermart', 'fsmone');
						}
						this.articleDisplayModel.articleHtml = _htmlFile;

						this.isPdf = false;
					}

					if (this.articleDisplayModel.category != null && this.articleDisplayModel.category != undefined) {
						this.findTop4RelatedArticles(this.checkArticleCategory(this.articleDisplayModel.category));
					}
							
					this.getRcmsRelatedArticle(this.articleNo);
					
					if(this.articleDisplayModel.saved === "Y"){
						this.articleSaved = true;
					} 

					this.isLoading = false;
				}
			});
	}
	
	getRcmsRelatedArticle(mainArticleId){
		this.articleService.findArticleList({
			withPosition: true,
			categories: ["funds", "bonds", "managed portfolio", "etfs", "stocks", "buzz", "fund house depository"],
			relatedId: mainArticleId,
			webContent: 0,
			country: "HK"
		}).subscribe((response: IResponse<any>) => {
			if (AppConstant.RESPONSE_SUCCESS === response.status) {
				if (response.data.length > 0) {
					if(response.data.length>=1){
						response.data.forEach(article => {
							this.otherRelatedArticle.push(article);
						});
						this.otherRelatedArticle.shift();
					}
							
					this.articleService.getRcmsArticleDtlNoClickCount(response.data[0].articleId).subscribe((response2: IResponse<any>) => {
						if (AppConstant.RESPONSE_SUCCESS === response2.status) {
							
							this.relatedArticle.articleNo = response.data[0].articleId;
							this.relatedArticle.articleLogDate = new Date(response2.data.publishedDate);
							this.relatedArticle.articleTitle = response2.data.title;
							this.relatedArticle.category = response2.data.category;
							this.relatedArticle.articleSynopsis = response2.data.synopsis;
							this.relatedArticle.articleHtml = response2.data.content;
							this.relatedArticle.featuredImageName = response2.data.featuredImage;
							this.relatedArticle.articleTag = response2.data.tags;
							this.relatedArticle.articleAuthor = response2.data.authorName;
							this.relatedArticle.articleAuthorImage = response2.data.authorImage;
							this.relatedArticle.products = response2.data.products;
							this.relatedArticle.translatedCategory = response2.data.translatedCategory;
							this.relatedArticle.disclaimer = response2.data.disclaimer;

							var containPdf = response2.data.isPdf;
							if (containPdf == "Y") {
								let _content: string = response2.data.content;
								if (_content.includes('fundsupermart')) {
									let _index: number = _content.indexOf('/ext/');
									this.relatedArticle.articleHtml = this.getEnv() + _content.substring(_index);
								}
								this.isPdf2 = true;
							}
							var webcast = response2.data.webcast;
							if (webcast == "1") {
								this.isWebcast2 = true;
								this.safeUrl2 = this.parseSafeHtml(response2.data.content);
							}
						}

					});
				}
			}
		});
	}

	setMetaTag(title: string, description: string, imageUrl: string) {
		const currentUrl = window.location.href;
		this.pageService.setTitle(title);
		this.pageService.setDescription(
			[
				{ name: "description", content: description },
				{ property: "og:title", content: title },
				{ proprety: "og:description", content: description },
				{ property: "og:image", content: imageUrl },
				{ name: "twitter:title", content: title },
				{ name: "twitter:description", content: description },
				{ name: "twitter:image", content: imageUrl }
			]
		);
	}

	tryGetArticleFromDB(articleno: string) {
		return this.articleService.findArticleDetailsFromDB(this.articleNo, this.auth);
	}


	downloadForm(url?) {
		window.open(url, '_blank');
	}

	articleNotFound() {

	}

	parseSafeHtml(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	findTop4RelatedArticles(cat) {
		this.articleService.findArticleList({
			withPosition: true,
			category: cat,
			tags: [],
			maxResult: 5,
			webContent: 0,
			country: "HK"
		}).subscribe((response: any) => {
			if (response.status !== AppConstant.RESPONSE_SUCCESS) {
				return;
			}
			this.relatedArticleList = response.data;
			if (cat.toLowerCase() == "funds") {
				this.sideRelatedArticleHeader = this.translate.instant('fsmone.article.latest.in.funds');
			} else if (cat.toLowerCase() == "bonds") {
				this.sideRelatedArticleHeader = this.translate.instant('fsmone.article.latest.in.bonds');
			} else if (cat.toLowerCase() == "etfs") {
				this.sideRelatedArticleHeader = this.translate.instant('fsmone.article.latest.in.etfs');
			} else if (cat.toLowerCase() == "dpms") {
				this.sideRelatedArticleHeader = this.translate.instant('fsmone.article.latest.in.portfolio');
			} else if (cat.toLowerCase() == "stocks") {
				this.sideRelatedArticleHeader = this.translate.instant('fsmone.article.latest.in.stocks');
			} else if (cat.toLowerCase() == "buzz") {
				this.sideRelatedArticleHeader = this.translate.instant('fsmone.article.latest.in.fsm.buzz');
			}
		})
	}

	setLocale() {
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}

	navigateLatestArticle(articleNo, title) {
		let route = RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + articleNo + '/' + title.replace(/[^\u4E00-\u9FFFp{L}a-zA-Z0-9]+/gi, "-").toLowerCase();
		window.open(route, '_blank', 'noreferrer');
	}

	formLastestArticleUrl(articleNo):string {
		return RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + articleNo;
	}

	navigateRelatedProduct(url) {
		window.open(url, '_blank', 'noreferrer');
	}

	navigateDpmsLanding() {
		this.router.navigate([RouteConstant.MANAGED_PORTFOLIOS]);
	}



	getEnv() {
		if (window.location.href.indexOf("hktest") != -1) {
			return 'https://hktest.fsmone.com.hk';
		}
		else if (window.location.href.indexOf("hktrial") != -1 || window.location.href.indexOf("local") != -1) {
			return 'https://hktrial.fsmone.com.hk';
		}
		else {
			return 'https://www.fsmone.com.hk';
		}
	}

	findArticleWithTag(tag) {
		this.router.navigate([RouteConstant.ARTICLE], { queryParams: { tags: [tag] } });
	}

	showFullRelatedArticle() {
		this.relatedArticleDisplay = true;
		
		if(this.relatedArticle && this.relatedArticle.articleNo){
			this.articleService.updateRcmsArticleViewCount(this.relatedArticle.articleNo).subscribe();
		}
	}

	checkArticleCategory(category: number | string): string {
		let result = "";
		switch (category) {
			case 52:
			case 53:
			case 54:
			case 56:
				result = 'funds';
				break;
			case 55:
				result = 'fund house depository'
				break;
			case 61:
			case 62:
			case 63:
			case 64:
				result = 'bonds';
				break;
			case 81:
				result = 'stocks';
				break;
			case 71:
			case 72:
			case 44:
				result = 'managed portfolio';
				break;
			default:
				result = 'funds';
				break;
		}
		return result;
	}
	
	shareUrl: string;
	shareVisibility: boolean = false;
	description: string;
	shareTitle: string;
	articleUrl: string;
	shareLink(articleTitle) : void {
		this.shareUrl  = window.location.href;
		this.shareTitle = articleTitle;
		this.description = this.translate.instant("fsmone.share.article.description");
		this.shareVisibility = true;
	}
	
	handleClose() {
		this.shareVisibility = false;
	}
	
	checkSavedArticle(articleNo: string): void {
    	if(this.articleSaved){
      		this.deleteArticle(articleNo);
	 		this.articleSaved = false;
	    }
	    else {
	      this.saveArticle(articleNo);
		  this.articleSaved = true;
	    }
	}

	saveArticle(articleNo) {
        this.watchlistRestService.addSavedArticle(articleNo).subscribe((response) => {
              if (AppConstant.RESPONSE_SUCCESS == response.status) {
                this.message.create('success', this.translate.instant('fsmone.watchlist.save.article.success'),{
                  nzDuration: 5000
                });
                
              }
            }, error => {
              	console.log(error);
				this.message.create('error', this.translate.instant('fsmone.watchlist.save.article.unauthenticated.error'),{
                  nzDuration: 5000
                });
            })
  	}
	
	deleteArticle(articleNo) {
        this.watchlistRestService.deleteSavedArticle(articleNo).subscribe((response) => {
              if (AppConstant.RESPONSE_SUCCESS == response.status) {
                this.message.create('success', this.translate.instant('fsmone.watchlist.remove.article.success'),{
							nzDuration: 5000
                });
                 
              }
        })
  	}
	
	
}
