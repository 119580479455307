<!-- <nz-modal -->
<!--   [nzVisible]="visibility" -->
<!--   nzTitle="{{'fsmone.chat.with.us' | translate}}" -->
<!--   [nzFooter]="null" -->
<!--   [nzWidth]="800" -->
<!--   (nzOnCancel)="handleClose()" -->
<!--   nzWrapClassName="vertical-center-modal" -->
<!--   (nzAfterOpen)="validateTwoFaAccount()"> -->
<nz-collapse id="liveChatCollapse" [ngClass]="{ 'd-none': !activePanel }">
	<nz-collapse-panel nzDisabled [nzHeader]="header" [nzExtra]="extra" [nzShowArrow]="false" [nzActive]="true" [ngClass]="activePanel ? 'vh-70' : '' ">
	<ng-container>
		<div *ngIf="liveChatOn">
		<div class="livechat-scrollable">
			<iframe id="liveChat" class="h-100 w-100" [src]="liveChatUrl | safeUrl"></iframe>
		</div>
		</div>
	
		<div *ngIf="liveChatOn==false">
			<nz-spin [nzSpinning]="typing">
			<div *ngIf="chatList!=null && chatList.length>0">
			<div class="livechat-scrollable" #scrollHere >
				<nz-row nzType="flex" nzAlign="middle" class="py-2" [nzJustify]="item.isBot?'start':'end'" *ngFor="let item of chatList; let i = index">	
				<nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="12" nzXXl="24" > 
					<nz-card [class] = "item.isBot?'':'bg-black-3'">
						<div [innerHTML] = "item.text | safeHtml"></div>
						<div *ngIf="item.buttons!=null && item.buttons.length>0">
							<nz-divider></nz-divider>
							<div *ngFor="let button of item.buttons">
								<a (click) ="talkToChatBot(button.title,button.payload,false,false,true)">{{button.title}}</a>
							</div>
						</div>
						<div *ngIf="item.finalNode==true">
							<nz-divider></nz-divider>
							<p translate="fsmone.chatbot.feedback.query" translate-default="Did you find this answer helpful?"></p>
							<button nz-button nzType="outline"  (click)="sendFeedback(item.messageId,'Y',i)"><span translate="yes" translate-default="Yes"></span></button>&nbsp;&nbsp;&nbsp;
							<button nz-button nzType="outline"  (click)="sendFeedback(item.messageId,'N',i)"><span translate="no" translate-default="No"></span></button>
						</div>
						<div *ngIf="item.finalNode=='feedback_Y'">
							<nz-divider></nz-divider>
							<p translate="fsmone.chatbot.positive.feedback.answer" translate-default="Thank you for your feedback!"></p>
						</div>
						<div *ngIf="item.finalNode=='feedback_N' || item.finalNode=='feedback_N_submitted'">
							<nz-divider></nz-divider>
							<p translate="fsmone.chatbot.negative.feedback.answer" translate-default="Thank you for your feedback! Please contact us if you are unsatisfied with the given answer."></p>
							<div *ngIf="item.finalNode=='feedback_N'">
								<button nzSize="small"  nz-button nzType="outline"  (click)="submitAdditionalFeedback(item.messageId,i)"><span translate="fsmone.chatbot.additional.feedback" translate-default="Send your feedback!"></span></button>
							</div>
						</div>
					</nz-card>
				</nz-col>
				</nz-row>
				<nz-row nzType="flex" nzAlign="middle" class="py-2" nzJustify="start">
				<div *ngIf="latestOption!=null && latestOption.length>0">
				<nz-col  *ngFor="let opt of latestOption">
					<button *ngIf="opt.action=='category'" nzSize="small"  nz-button nzType="outline"  (click)="talkToChatBot(opt.title,opt.payload,true,false,false)"><span class="text-black" [innerHTML]="opt.title | safeHtml"></span></button>
					<button *ngIf="opt.action=='short_question'" nzSize="small"  nz-button nzType="outline"  (click)="talkToChatBot(opt.title,opt.payload,false,true,false)"><span class="text-black" [innerHTML]="opt.title | safeHtml"></span></button>
				</nz-col>
				</div>
				</nz-row>
			</div>
				
				<nz-row nzType="flex" nzAlign="middle" class="py-2" nzJustify="start">
				<nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzXXl="24"> 
					<div *ngIf="needMoreFeedback==false">
					<form nz-form [formGroup]="msgForm" (keyup.enter)="msgForm.valid && msgForm.valid && submitForm($event)">
					<nz-form-item>
						<nz-form-control> 
							<ui-input-group nzLabelPlaceholder="{{'please.enter.your.message' | translate}}"> 
								<input class="w-100" ui-input formControlName="content" /> 
								<nz-form-explain *ngIf="msgForm.get('content')?.dirty && msgForm.get('content')?.hasError('required')">{{'please.enter.your.message' | translate}}</nz-form-explain>
							</ui-input-group>
						</nz-form-control> 
					</nz-form-item>
					</form>
					</div>
				
					<div *ngIf="needMoreFeedback==true">
					<form nz-form [formGroup]="feedbackForm" (keyup.enter)="feedbackForm.valid && feedbackForm.valid && submitAdditionalFeedbackForm()">
					<nz-form-item>
						<nz-form-control> 
							<ui-input-group nzLabelPlaceholder="{{'fsmone.chatbot.write.additional.feedback' | translate}}"> 
								<input class="w-100" ui-input formControlName="feedback" /> 
								<nz-form-explain *ngIf="msgForm.get('feedback')?.dirty && msgForm.get('feedback')?.hasError('required')">{{'fsmone.chatbot.write.additional.feedback' | translate}}</nz-form-explain>
							</ui-input-group>
						</nz-form-control> 
					</nz-form-item>
					</form>
					</div>
				</nz-col>
				</nz-row>
			</div>
			</nz-spin>
		</div>
	</ng-container>
  </nz-collapse-panel>
</nz-collapse>
<!-- </nz-modal> -->

<ng-template #header>
	<span class="container text-md text-white" translate="fsmone.chat.with.us" transate-default="Chat with us"></span>
</ng-template>

<ng-template #extra>
	<span class="text-white" role="button" (click)="changeActive(!activePanel)"><i nz-icon [nzType]="activePanel ? 'minus' : 'plus'" nzTheme="outline"></i></span>
	<span class="text-white" role="button" (click)="closeModal($event)"><i nz-icon nzType="close" nzTheme="outline"></i></span>
</ng-template>

