import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighter'
})
export class HighlighterPipe implements PipeTransform {

  transform(value: any, args: any, type?: string): unknown {

    if (!args) return value;

    // Escape special characters in the search term
    const escapedArgs = args.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    if (type === 'full') {
      const re = new RegExp("\\b(" + escapedArgs + "\\b)", 'igm');
      if (value) {
        value = value.replace(re, '<span class="highlighted-text">$1</span>');
      }
    } else {
      const re = new RegExp(escapedArgs, 'igm');
      if (value) {
        value = value.replace(re, '<span class="highlighted-text">$&</span>');
      }
    }
    return value;  
  }

}
