import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { ITwoFaModel } from '@api-module/model/authority/i-two-fa.model';
import {AuthConstant} from "src/app/constant";
import {ChatReplyModel,ChatBotFeedbackModel} from '@api-module/model/chat-bot/chat-bot-message.model'


@Injectable({
  providedIn: 'root'
})
export class SupportRestService {
  
  readonly BASE_URL: string = ApiConstant.SUPPORT;
  
  constructor(private http: HttpClient, private paramService: HttpParamsService) {}

  findUserGuideList(){
    return this.http.post(this.BASE_URL + ApiConstant.FIND_USER_GUIDE_LIST, {});
  }

  findMakingPaymentsHTMLList(){
    return this.http.post(this.BASE_URL + ApiConstant.FIND_MAKING_PAYMENTS_HTML_LIST, {});
  }

  getLiveChatService(){
    return this.http.post(this.BASE_URL + ApiConstant.GET_LIVE_CHAT_SERVICE, {});
  }

  initiateChatBotConversation(){
    return this.http.post(this.BASE_URL + ApiConstant.CHAT_BOT_INITIATE_CONVERSATION, {});
  }

  talkToChatBot(msg:ChatReplyModel){
    return this.http.post(this.BASE_URL + ApiConstant.TALK_TO_CHAT_BOT, msg);
  }

  sendFeedback(feedback:ChatBotFeedbackModel,additional?:boolean){
	if(additional==true){
	    const params = this.paramService.buildHttpParams({additional});
	    return this.http.post(this.BASE_URL + ApiConstant.CHAT_BOT_SEND_FEEDBACK, feedback,{params});
	}else{
	    return this.http.post(this.BASE_URL + ApiConstant.CHAT_BOT_SEND_FEEDBACK, feedback);	
	}
  }

  getHTMLFile(filePath){
    return this.http.get(this.BASE_URL + filePath, {});
  }

  subscribePublicEnews(name : string ,email : string,lang : string){
	
	let body = new FormData();
	body.append('name',name);
	body.append('email',email);
	body.append('lang',lang);
	
    return this.http.post(this.BASE_URL + ApiConstant.SUBSCRIBE_ENEWS_PUBLIC, body,{});
  }

  removePublicSubscription(paramEmail : string){
	const params = this.paramService.buildHttpParams({paramEmail});
    return this.http.post(this.BASE_URL + ApiConstant.REMOVE_PUBLIC_SUBSCRIPTION, {},{params});
  }

  aboutUsMilestone(){
	   return this.http.post(this.BASE_URL + ApiConstant.ABOUT_US_MILESTONE, {});
  }

  submitFsmoneEnquiry(topic, subject, name, email, contactNo, description, files, refno){

	let formData = new FormData();
	formData.append('topic',topic);
	formData.append('subject',subject);
	formData.append('name',name);
	formData.append('email',email);
	formData.append('contactNo',contactNo);
	formData.append('description',description);
	formData.append('refno',refno);
	
	for (const file of files) 
	{
    	formData.append('files', file)
    }

	return this.http.post(this.BASE_URL + ApiConstant.SUBMIT_FSMONE_ENQUIRY, formData);
  }

	isBusinessHour(){
		return this.http.post(this.BASE_URL + ApiConstant.IS_BUSINESS_HOUR,{});
	}
	
  submitAppointmentRequest(refno,email,contactNo,prefDate,prefTime,enquirySubject,msg){
	
	let data = new FormData();
	data.append('refno',refno);
	data.append('email',email);
	data.append('contactNo',contactNo);
	data.append('prefDate',prefDate);
	data.append('prefTime',prefTime);
	data.append('enquirySubject',enquirySubject);
	data.append('msg',msg);
	return this.http.post(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.SUBMIT_CIS_APMT, data);
  }

  getAvailableTimeslot(apmtDate){
	const params = this.paramService.buildHttpParams({apmtDate});
	return this.http.post(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.GET_CIS_AVAILABLE_TIMESLOT, {},{params});
  }

	getCADocumentsList(paramCaId){
		const params = this.paramService.buildHttpParams({paramCaId});
	return this.http.post(this.BASE_URL + ApiConstant.GET_BOND_CA_FILE_LIST, {},{params});
	}
	
	findAllFaqreportMakePayment(){
		return this.http.post(this.BASE_URL + '/find-all-faqreport-make-payment',{});
	}
	
	findAllAutoSweepFaq(maxResult?: number){
		const params = this.paramService.buildHttpParams({maxResult});
		return this.http.post(this.BASE_URL + ApiConstant.FIND_AUTO_SWEEP_FAQ, {}, {params});
	}
	
}