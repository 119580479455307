import {IDropdownModel} from '@api-module/model/common/i-dropdown.model';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

export class StockConstant {
  public static readonly SECTOR_DEFAULT: string = 'ALL';
  public static readonly PRICE_RANGE_DEFAULT: string = 'ALL';
  public static readonly MYR: string = 'MYR';
  public static readonly PRODUCT: string = 'PRODUCT';

  public static readonly KLSE: string = 'KLSE';
  public static readonly BURSA: string = 'BURSA';
  public static readonly HKEX: string = 'HKEX';
  public static readonly NASDAQ: string = 'NASDAQ';
  public static readonly NYSE: string = 'NYSE';
  public static readonly AMEX: string = 'AMEX';
  public static readonly BATS: string = 'BATS';
  public static readonly USOTC: string = 'USOTC';
  public static readonly SSE: string = 'SSE';
  public static readonly SZSE: string = 'SZSE';
  public static readonly SGX: string = 'SGX';

  public static readonly BUY: string = 'BUY';
  public static readonly SELL: string = 'SELL';
  public static readonly SIP: string = 'SIP';
  public static readonly EIP: string = 'EIP';
  public static readonly STOCK_CURRENCY: string = 'Stock_Currency';
  public static readonly COMMON_STOCK_SECURITY_TYPE: string = 'COMMON_STOCK';
  public static readonly WTS_SECURITY_TYPE: string = 'WTS';
  public static readonly ETF_SECURITY_TYPE: string = 'ETF';
  public static readonly STOCK_PRODUCT_TYPE: string = 'STOCK';
  public static readonly ETF_PRODUCT_TYPE: string = 'ETF';
  //Only 1 ETF Bond Based, temporary hard code since no ticker info for ETF Based Type
  public static readonly BOND_ETF_LIST: string[] = ['0800EA'];
  public static readonly lotSize: number = 100;
  public static readonly STOCK_SEARCH_LIMIT: number = 20;
  public static readonly STOCK_COMPANY_ANNOUNCEMENT_CATEGORY: IDropdownModel[] = [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Capital Distribution',
      value: 'CAPD'
    },
    {
      value: 'ACTV',
      label: 'Trading Requote'
    },
    {
      value: 'BIDS',
      label: 'Repurchase Offer'
    },
    {
      value: 'BONU',
      label: 'Bonus Issue'
    },
    {
      value: 'CHAN',
      label: 'Change'
    },
    {
      value: 'DECR',
      label: 'Decrease in Value'
    },
    {
      value: 'DLST',
      label: 'Delisted'
    },
    {
      value: 'DRIP',
      label: 'Dividend Reinvestment'
    },
    {
      value: 'DVCA',
      label: 'Cash Dividend'
    },
    {
      value: 'DVOP',
      label: 'Dividend Option'
    },
    {
      value: 'DVSE',
      label: 'Stock Dividend'
    },
    {
      value: 'EXOF',
      label: 'Exchange'
    },
    {
      value: 'EXRI',
      label: 'Right Exercise'
    },
    {
      value: 'EXWA',
      label: 'Warrant Exercise'
    },
    {
      value: 'INFO',
      label: 'Info'
    },
    {
      value: 'INTR',
      label: 'Interest Payment'
    },
    {
      value: 'LIQU',
      label: 'Liquidation Dividend'
    },
    {
      value: 'MCAL',
      label: 'Full Call'
    },
    {
      value: 'MRGR',
      label: 'Merger'
    },
    {
      value: 'PCAL',
      label: 'Partial Call Without Pool Factor'
    },
    {
      value: 'PRED',
      label: 'Partial Call With Pool Factor'
    },
    {
      value: 'PRIO',
      label: 'Priority Issue'
    },
    {
      value: 'REDM',
      label: 'Full Maturity'
    },
    {
      value: 'RHDI',
      label: 'Immediate Security Distribution'
    },
    {
      value: 'SOFF',
      label: 'Spinoff'
    },
    {
      value: 'SPLF',
      label: 'Stock Split'
    },
    {
      value: 'SPLR',
      label: 'Reverse Stock Split'
    },
    {
      value: 'SUSP',
      label: 'Suspend'
    },
    {
      value: 'TEND',
      label: 'Tender'
    },
    {
      value: 'GMET',
      label: 'General Meeting'
    },
    {
      value: 'XMET',
      label: 'Extraordinary Meeting'
    },
    {
      value: 'MIXD',
      label: 'Mixed Meeting'
    },
    {
      value: 'SPCL',
      label: 'Special Meeting'
    },
    {
      value: 'BMET',
      label: 'Bond Holder Meeting'
    },
    {
      value: 'IPOA',
      label: 'IPO Announcement'
    },
    {
      value: 'IPOT',
      label: 'IPO Timetable'
    },
    {
      value: 'CHPO',
      label: 'Change in Principal Officer'
    },
    {
      value: 'CHBR',
      label: 'Change in Boardroom'
    },
    {
      value: 'CHBC',
      label: 'Change in Board Committee'
    },
    {
      value: 'CHAC',
      label: 'Change in Audit Committee'
    },
    {
      value: 'CHAD',
      label: 'Change of Address'
    },
    {
      value: 'CHRG',
      label: 'Change of Registrar'
    },
    {
      value: 'CHCS',
      label: 'Change of Company Secretary'
    },
    {
      value: 'SSIN',
      label: 'Substantial Shareholder Interest Notice'
    },
    {
      value: 'SSIC',
      label: 'Substantial Shareholder Interest Change'
    },
    {
      value: 'SSIX',
      label: 'Notice of Person Ceasing To Be Substantial Shareholder Pursuant'
    },
    {
      value: 'DRIC',
      label: 'Director Interest Pursuant Change'
    },
    {
      value: 'CFYE',
      label: 'Change in Financial Year End'
    },
    {
      value: 'QRFR',
      label: 'Financial Results'
    },
    {
      value: 'SBBI',
      label: 'Share Buy Back Immediate Announcement'
    },
    {
      value: 'SBBC',
      label: 'Share Buy Back Company Pursuant'
    },
    {
      value: 'TSSC',
      label: 'Sale or Cancellation of Treasury Shares Pursuant'
    },
    {
      value: 'TSIC',
      label: 'Immediate Announcement of Changes in Treasury Shares'
    },
    {
      value: 'SPEC',
      label: 'Special Announcement'
    },
    {
      value: 'LICI',
      label: 'Listing Circular'
    },
    {
      value: 'INVA',
      label: 'Investor Alert'
    },
    {
      value: 'GAPL',
      label: 'General Announcement for PLC'
    },
    {
      value: 'GANP',
      label: 'General Announcement for Non Listed Issuer'
    },
    {
      value: 'LIPF',
      label: 'Listing Information and Profile'
    },
    {
      value: 'EMTS',
      label: 'Expiry Maturity Termination of Securities'
    },
    {
      value: 'ALAN',
      label: 'Additional Listing Announcement'
    },
  ];

  public static readonly stockSlideAnimation = trigger('scrollY', [
    state('void', style({
      transform: 'translateY(-100%)',
      opacity: 0,

    })),
    state('initialNext', style({
      transform: 'translateY(100%)',
      opacity: 0,
    })),
    state('initialBack', style({
      transform: 'translateY(-100%)',
      opacity: 0,
    })),
    state('initial', style({
      transform: 'translateY(0%)',
      opacity: 1,
    })),
    state('next', style({
      transform: 'translateY(-100%)',
      opacity: 0,
    })),
    state('back', style({
      transform: 'translateY(100%)',
      opacity: 0,

    })),
    transition('* => initial', animate('300ms ease-in')),
    transition('* => back', animate('400ms ease-out', keyframes([
      style({transform: 'translateY(-30%)'}),
      style({transform: 'translateY(0%)'}),

      style({transform: 'translateY(100%)', opacity: 0})
    ]))),
    transition('* => next', animate('300ms ease-out', keyframes([
      style({transform: 'translateY(30%)'}),
      style({transform: 'translateY(0%)'}),
      style({transform: 'translateY(-100%)', opacity: 0})
    ]))),
  ]);

  public static readonly MOST_ACTIVE: string = 'mostActive';
  public static readonly TOP_GAINERS: string = 'topGainers';
  public static readonly TOP_LOSERS: string = 'topLosers';
  public static readonly EXCHANGE_LIST: IDropdownModel[] = [
    {checked : false,label: StockConstant.HKEX, value: StockConstant.HKEX},
    {checked : false,label: StockConstant.NASDAQ, value: StockConstant.NASDAQ},
    {checked : false,label: StockConstant.NYSE, value: StockConstant.NYSE},
    {checked : false,label: StockConstant.AMEX, value: StockConstant.AMEX},
    {checked : false,label: StockConstant.BATS, value: StockConstant.BATS},
    {checked : false,label: StockConstant.SSE, value: StockConstant.SSE},
    {checked : false,label: StockConstant.SZSE, value: StockConstant.SZSE},
    {checked : false,label: StockConstant.SGX, value: StockConstant.SGX},
  ];

	public static readonly US_EXCHANGE: string[] = [
		StockConstant.AMEX,
		StockConstant.BATS,
		StockConstant.NASDAQ,
		StockConstant.NYSE
	]
}


