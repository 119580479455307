<ng-container *ngIf="profitLossClass; else plainTextTemplate">
  <span [ngClass]="displayValue >= 0 ? 'text-profit' : 'text-loss'">
    <i nz-icon nzType="caret-up" nzTheme="fill" *ngIf=" displayValue > 0 && showArrow"></i>
    <i nz-icon nzType="caret-down" nzTheme="fill" *ngIf=" displayValue < 0 && showArrow"></i>
    <span *ngIf="displayValue">{{prefix}}</span>
    {{ (displayValue | number: '1.2-2') || '-' }}
    <span *ngIf="displayValue">{{postfix}}</span>
  </span>
</ng-container>

<ng-template #plainTextTemplate>
  <span>
    <span *ngIf="displayValue">{{prefix}}</span>
    {{ (displayValue | number: '1.2-2') || '-' }}
    <span *ngIf="displayValue">{{postfix}}</span>
  </span>
</ng-template>


