export class ColorConstant {
  public static readonly HIGHCHART_COLOR: Array<string> = [
    '#2F8DFA',
    '#1FD0BF',
    '#EB648B',
    '#F8C753',
    '#EB7E30',
    '#A93790',
    '#049587',
    '#1553B6',
    '#55008C',
    '#8B572A',
    '#F0B900',
    '#868686',
    '#A0B990',
    '#00B2A9',
    '#E8D4A2',
    '#FF00BF',
    '#DD0000',
    '#C9C3E6',
    '#70b4d0',
    '#A8BECE',
    '#4bad31',
    '#f5dc00',
    '#813772',
    '#d6006e',
    '#00b27f',
    '#ff4500',
    '#d3ffce',
    '#0ba6ab',
    '#98afc0',
    '#6b0f1a',
    '#0e5fd8',
    '#ffc3a0',
    '#133337',
    '#3cc47c',
    '#e24242',
    '#4484ce',
    '#6e3667',
    '#f81b84',
    '#0b3c5d',
    '#FF7600',
    '#3C61FF',
    '#61DE1E'
  ];

  // Portfolio Planner plot color
  public static readonly PORTFOLIO_PLOT_COLOR: Array<string> = [
    '#FF7600',
    '#3C61FF',
    '#61DE1E'


  ];
  public static readonly ISLAMIC_BACKTEST_CHART_COLOR: Array<string> = [
    '#D800FF',
    '#23AEB4',
  ];
}

