import { Injectable } from "@angular/core";

@Injectable()
export class GlobalDataStorage {
    private _entity: string;
    private _username: string;
    private _lastLoginDate: string;
    private _companyCode: string;
    private _platform: string;
    private _moduleInfoList: Array<any>;
    private _pageList: Array<string>;
    private _noticeBannerVisible: string;
	private _locale: string;
    private _platinumPlusMessageVisible: string;
    private _marginCallMessageVisible: string;
    private _selectedAccountNo: string;
    private _showNewStockOrderpad: string;
    private _usLivePriceTnc: string;
    private _stockAutoAddWatchlist: string;
    private _stockDefaultWatchlist: string;


    public get entity(): string {
        this._entity = localStorage.getItem("_entity");
        return this._entity;
    }

    public set entity(value: string) {
        localStorage.setItem("_entity", value);
    }

    public get username(): string {
        this._username = localStorage.getItem("_username");
        return this._username;
    }
    public set username(value: string) {
        localStorage.setItem("_username", value);
    }

    public get lastLoginDate(): string {
        this._lastLoginDate = localStorage.getItem("_lastLoginDate");
        return this._lastLoginDate;
    }
    public set lastLoginDate(value: string) {
        localStorage.setItem("_lastLoginDate", value);
    }

    public get companyCode(): string {
        this._companyCode = localStorage.getItem("_companyCode");
        return this._companyCode;
    }
    public set companyCode(value: string) {
        localStorage.setItem("_companyCode", value);
    }

    public get platform(): string {
        this._platform = localStorage.getItem("_platform");
        return this._platform;
    }
    public set platform(value: string) {
        localStorage.setItem("_platform", value);
    }

    public get moduleInfoList(): Array<any> {
        this._moduleInfoList = JSON.parse(localStorage.getItem("_moduleInfoList"));
        return this._moduleInfoList;
    }
    public set moduleInfoList(moduleInfoList: Array<any>) {
        this._moduleInfoList = moduleInfoList;
        localStorage.setItem("_moduleInfoList", JSON.stringify(this._moduleInfoList));
    }

    public get pageList(): Array<string> {
        this._pageList = JSON.parse(localStorage.getItem("_pageList"));
        return this._pageList;
    }
    public set pageList(pageList: Array<string>) {
        this._pageList = pageList;
        localStorage.setItem("_pageList", JSON.stringify(this._pageList));
    }

    public get noticeBannerVisible(): string {
        this._noticeBannerVisible = localStorage.getItem("_noticeBannerVisible");
        return this._noticeBannerVisible;
    }

    public set noticeBannerVisible(value: string) {
        localStorage.setItem("_noticeBannerVisible", value);
    }

	public get locale(): string {
		this._locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : 'ch';
		return this._locale;
	}
	
	public get getLocaleISO639_1(): string {
		var locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : 'ch';
		
		if ("en" === locale) {
			return "en-US";
		} else if ("ch" === locale) {
			return "zh-TW";
		} else if ("zh" === locale) {
			return "zh-CN";
		}
	}

    public get platinumPlusMessageVisible(): string {
        this._platinumPlusMessageVisible = localStorage.getItem("_platinumPlusMessageVisible");
        return this._platinumPlusMessageVisible;
    }

    public set platinumPlusMessageVisible(value: string) {
        localStorage.setItem("_platinumPlusMessageVisible", value);
    }

    public get marginCallMessageVisible(): string {
        this._marginCallMessageVisible = localStorage.getItem("_marginCallMessageVisible");
        return this._marginCallMessageVisible;
    }

    public set marginCallMessageVisible(value: string) {
        localStorage.setItem("_marginCallMessageVisible", value);
    }

    public get selectedAccountNo(): string {
        this._selectedAccountNo = localStorage.getItem("_selectedAccountNo");
        return this._selectedAccountNo;
    }

    public set selectedAccountNo(value: string) {
        localStorage.setItem("_selectedAccountNo", value);
    }

    public get showNewStockOrderpad(): string {
        this._showNewStockOrderpad = localStorage.getItem("_showNewStockOrderpad");
        return this._showNewStockOrderpad;
    }

    public set showNewStockOrderpad(value: string) {
        localStorage.setItem("_showNewStockOrderpad", value);
    }

    public get usLivePriceTnc(): string {
        this._usLivePriceTnc = localStorage.getItem("_usLivePriceTnc");
        return this._usLivePriceTnc;
    }

    public set usLivePriceTnc(value: string) {
        localStorage.setItem("_usLivePriceTnc", value);
    }

    public set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public set locale(value: string) {
        localStorage.setItem("locale", value);
    }

    public get(key: string) {
        return localStorage.getItem(key);
    }

    public setStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getStorage(key: string) {
        return localStorage.getItem(key);
    }

    public setSessionStorage(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    public getSessionStorage(key: string) {
        return sessionStorage.getItem(key);
    }

    public get stockAutoAddWatchlist(): string {
      this._stockAutoAddWatchlist = this.getStorage("_stockAutoAddWatchlist");
      return this._stockAutoAddWatchlist;
    }
    public set stockAutoAddWatchlist(value: string) {
      this.setStorage("_stockAutoAddWatchlist", value);
    }
  
    public get stockDefaultWatchlist(): string {
      this._stockDefaultWatchlist = this.getStorage("_stockDefaultWatchlist");
      return this._stockDefaultWatchlist;
    }
    public set stockDefaultWatchlist(value: string) {
      this.setStorage("_stockDefaultWatchlist", value);
    }

    public clearStorage() {
        localStorage.removeItem("_entity");
        localStorage.removeItem("_username");
        localStorage.removeItem("_lastLoginDate");
        localStorage.removeItem("_companyCode");
        localStorage.removeItem("_moduleInfoList");
        localStorage.removeItem("_pageList");
        localStorage.removeItem("_platinumPlusMessageVisible");
        localStorage.removeItem("_marginCallMessageVisible");
        localStorage.removeItem("_selectedAccountNo");
        localStorage.removeItem("_showNewStockOrderpad");
        localStorage.removeItem("_usLivePriceTnc");
        localStorage.removeItem("_stockAutoAddWatchlist");
        localStorage.removeItem("_stockDefaultWatchlist");
    }
}
