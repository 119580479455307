// import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

declare var Hammer: any;

@Injectable({
  providedIn: 'root'
})
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'swipe': { direction: Hammer.DIRECTION_ALL  },
    'pinch': { enable: false },
    'rotate': { enable: false },
    'pan': { direction: Hammer.DIRECTION_ALL  },
  };

  buildHammer(element: HTMLElement) {
    const hammer = new Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Swipe, {
          direction: Hammer.DIRECTION_HORIZONTAL
        }]
      ]
    });

    return hammer;
  }
}