import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@api-module/api.endpoint.constant';
import {Observable} from 'rxjs';
import {IMobilePhoneCodeModel} from '@api-module/model/authority/i-mobile-phone-code.model';
import {IResponse} from '@api-module/model/common/i-response';
import {HttpParamsService} from '@api-module/service/http-params.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRestService {

  readonly BASE_URL: string = ApiConstant.AUTHORITY;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }

  findMobilePhoneCode(): Observable<object | IResponse<Array<IMobilePhoneCodeModel>>> {
    return this.http.post(this.BASE_URL + ApiConstant.MOBILE_PHONE_CODE + ApiConstant.READ, {});
  }

  refreshToken(refreshToken: string) {
    const params = this.paramService.buildHttpParams({ refreshToken });

    return this.http.post(this.BASE_URL + ApiConstant.TOKEN + ApiConstant.REFRESH, {}, { params });
  }
}
