import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Store} from "@ngxs/store";
import {UpdatePrint} from "../../core/store";
import {environment} from "@env";
import {AppConstant} from "@constant";
import {concatMap, delay, map, tap} from "rxjs/operators";
import {Observable, of, Subscription} from "rxjs";

/**
 * examples of using [print] directive
 *
 * 1. print 'table-id' by default
 * <tag print nzPrintingId="table-id"></tag>
 *
 * */

@Directive({
  selector: '[print]'
})
export class PrintDirective {
  @Input() nzPrintTitle: string;
  @Input() nzPrintingId: string;
  @Input() nzSwapClasses: any = {};
  @Input() nzAddClasses: boolean = false;
  @Input() nzRemoveClasses: boolean = false;
  @Input() nzChart: boolean = false;
  @Input() nzChartLoadingDelay: boolean = false;
  @Input() nzIconUrl: string;
  @Input() nzHeaderTitle: string;

  @Output() readonly loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly nzPrintWindowClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _styleSheetFile = '';
  private readonly origin: string = window.location.origin;

  @Input()
  set styleSheetFile(cssList: string) {
    const linkTagFn = cssFileName => `<link rel="stylesheet" type="text/css" href="${cssFileName}">`;
    if (cssList.indexOf(',') !== -1) {
      const valueArr = cssList.split(',');
      for (const val of valueArr) {
        this._styleSheetFile = this._styleSheetFile + linkTagFn(val);
      }
    } else {
      this._styleSheetFile = linkTagFn(cssList);
    }
  }

  constructor(private store: Store) {

  }

  ngOnInit(): void {
    this.store.dispatch(new UpdatePrint());
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UpdatePrint());
  }

  private returnStyleSheetLinkTags() {
    return this._styleSheetFile;
  }

  private getElementTag(tag: keyof HTMLElementTagNameMap): string {
    const html: string[] = [];
    const elements = document.getElementsByTagName(tag);
    for (let index = 0; index < elements.length; index++) {
      let outerHtml: string = elements[index].outerHTML;

      try {
        if (tag === 'link' && outerHtml && outerHtml.indexOf('href') > -1 && environment.production) {
          const regex: RegExp = /(?:href=)("\w.*)(?:>)/gm;
          const results: Array<string> = regex.exec(outerHtml);
          console.log('results: ', results);

          if (results && Array.isArray(results) && results.length > 0) {
            const css = results[1];
            console.log('css: ', css);
            const cssLink: string = `${this.origin}/${css}`;
            console.log('css link: ', cssLink);
            outerHtml = outerHtml.replace(css, cssLink).replace(/"/gm, '');
            console.log('outerHTML: ', outerHtml);
          }
        }
      }
      catch(e) {
        console.log('Something went wrong in getting styles: ', e);
      }

      html.push(outerHtml);
    }
    return html.join('\r\n');
  }

  private processDOM(chartImages?: any) {
    const node: any = document.getElementById(this.nzPrintingId).cloneNode(true);
    const klass = this;

    if (this.nzSwapClasses && Object.keys(this.nzSwapClasses).length) {
      const elements: NodeList = node.querySelectorAll(`.${AppConstant.PR_REPLACE}`);

      elements.forEach((element: Element) => {
        element.className = replaceClasses(element.className);
      });
    }

    if (this.nzRemoveClasses) {
      const elements: NodeList = node.querySelectorAll(`.${AppConstant.PR_REMOVE}`);

      elements.forEach((element: Element) => {
        if (element.className.search(AppConstant.PR_REMOVE_ALL) > -1) {
          element.className = '';
        } else {
          element.className = removeClasses(element.className);
        }
      });
    }

    if (this.nzAddClasses) {
      const elements: NodeList = node.querySelectorAll(`.${AppConstant.PR_ADD}`);

      elements.forEach((element: Element) => {
        element.className = addClasses(element.className);
      });
    }

    if (this.nzChart && chartImages) {
      const highchartElements: NodeList = node.querySelectorAll(`highcharts-chart.${AppConstant.PR_CHART}`);

      highchartElements.forEach((element: Node, index: number) => {
        const img = document.createElement('img');
        img.setAttribute('src', chartImages[index]);
        img.setAttribute('alt', 'chart image');
        img.className = 'img-fluid print-chart-image no-process';

        element.parentNode.replaceChild(img, element);
      });
    }

    function replaceClasses(className: string): string {
      if (checkEmpty(className)) {
        return '';
      }

      let temp: string = className.slice();

      Object.keys(klass.nzSwapClasses).forEach((key: string) => {
        temp = temp.replace(key, klass.nzSwapClasses[key]);
      });

      return temp.replace(AppConstant.PR_REPLACE, '');
    }

    function addClasses(className: string): string {
      return checkEmpty(className) ? '' : className.slice().replace(/__(.*?)__/ig, '$1').replace(AppConstant.PR_ADD, '').replace('  ', ' ');
    }

    function removeClasses(className: string): string {
      if (checkEmpty(className)) {
        return '';
      }

      const regex = /---(.*?)---/g;
      const result = className.match(regex);

      if (result === null || (result && !Array.isArray(result))) {
        return className;
      }

      let classNameResult: string = className.slice();

      result.forEach((res: string) => {
        classNameResult = classNameResult.replace(res, '');
        res = res.replace(/---/g, '');
        classNameResult = classNameResult.replace(res, '');
      });

      return classNameResult.replace(AppConstant.PR_REMOVE, '');
    }

    function checkEmpty(className: string) {
      return !className || (className && className.length === 0);
    }

    return node.innerHTML;
  }

  private processImages(htmlString: string): string {
    return this.nzChart ? htmlString : htmlString.replace(/(<img.*src=")(.*)"/gm, `$1${this.origin}/$2"`);
  }

  private processPrintDialog(printContent: string) {
    const popupWindow = window.open('', '_blank');
    const styles = this.getElementTag('style');
    const links = this.getElementTag('link');

    // printContent = this.processImages(printContent);
    popupWindow.document.open();
    popupWindow.document.write(`
      <!DOCTYPE html>
      <html>
          <head>
              <img id="headerImg" src="${this.nzIconUrl ? this.nzIconUrl : null}">
              <title>${this.nzPrintTitle ? this.nzPrintTitle : ''}</title>
              ${this.returnStyleSheetLinkTags()}
              ${styles}
              ${links}
              <h2 class="mt-2 mb-2">${this.nzHeaderTitle ? this.nzHeaderTitle : ''}</h2>
          </head>
          <body onload="setTimeout(() => {window.print();window.close();})" class="print-directive-layout">${printContent}</body>
			</html>`);
    if (!this.nzIconUrl) {
      popupWindow.document.getElementById('headerImg').style.display = 'none';
    }
    popupWindow.document.close();
    popupWindow.onafterprint = () => {
      this.nzPrintWindowClosed.emit(true);
    };
  }

  @HostListener('click')
  public print(): void {
    this.loading.emit(true);

    let timeout = this.nzChartLoadingDelay ? 1000 : 100;
    const subs: Subscription = this.store.dispatch(new UpdatePrint(true)).pipe(concatMap(() => {
      const observable: Observable<any> = of(null);

      return observable.pipe(
        delay(timeout),
        map((images?: Array<any>) => {
          if (this.nzRemoveClasses || this.nzChart || this.nzAddClasses || (this.nzSwapClasses && Object.keys(this.nzSwapClasses).length)) {
            return this.processDOM(images);
          }

          return document.getElementById(this.nzPrintingId).innerHTML;
        }),
        tap((printContent: string) => {
          this.loading.emit(false);
          this.processPrintDialog(printContent);
        }),
        concatMap(() => this.store.dispatch(new UpdatePrint())));
    })).subscribe(() => {
      if (subs) {
        subs.unsubscribe();
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  keydown(e: KeyboardEvent): void {
    if (!e.isTrusted) {
      return;
    }

    if ((e.metaKey || e.ctrlKey) && e.keyCode === 80 && this.nzPrintingId) {
      e.preventDefault();

      this.print();
    }
  }
}
