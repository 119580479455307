import { Injectable } from '@angular/core';
import { BondRmoLightstreamerService } from './lightstreamer-bond-connection.service';
import Lightstreamer from 'lightstreamer-client';
import { BondConstant } from '../../../constant/bond.constant';

@Injectable({
  providedIn: 'root'
})

export class LightstreamerBondService {
	
	private digestQueue: any;
	
	constructor() {}
	
	getItemName(adapterType:string, id:string, option?:string) {
    	if (option) {
      		return adapterType + '-' + id + '-' + option;
    	} else {
     		return adapterType + '-' + id;
    	}
  	}

  	errorCallback(code: number, message: string) {
    	alert(`Subscription error: [${code}] ${message}`);
  	}

  	digestCallback() {
    	if (!this.digestQueue) {
      		this.digestQueue = setTimeout(() => {
        	this.digestQueue = undefined;
      		}, 100);
    	}
  	}

  	getSymbol(externalBondId:string, lotType):string{
	    let symbol = '8000.9.'+externalBondId;
	    if(BondConstant.FULL_LOT === lotType){
	      	return symbol;
	    }else if(BondConstant.ODD_LOT === lotType){
	      	return symbol+'/O';
	    }
	    return '';
  	}
	
	subscribeMarketDepth(lsClient: Lightstreamer, symbol: string, callback: (itemUpdate: any) => void){
		let itemName = this.getItemName(BondConstant.BEX_PRICE, symbol);
//		console.log('itemName: ' + itemName);
		let fields = ['symbol', 'change', 'changePercentage', 'lastPrice', 'cumulativeVolume', 'bidPrice', 'bidVolume', 'offerPrice', 'offerVolume', 'referencePrice','openPrice','closePrice','bestBidYield','bestOfferYield','norminalPrice', 
			'ask1','ask2','ask3','ask4','ask5','ask6','ask7','ask8','ask9','ask10',
			'ask11','ask12','ask13','ask14','ask15','ask16','ask17','ask18','ask19','ask20',
			'ask21','ask22','ask23','ask24','ask25','ask26','ask27','ask28','ask29','ask30',
			'ask31','ask32','ask33','ask34','ask35','ask36','ask37','ask38','ask39','ask40',
			'askVolume1','askVolume2','askVolume3','askVolume4','askVolume5',
			'askVolume6','askVolume7','askVolume8','askVolume9','askVolume10',
			'askVolume11','askVolume12','askVolume13','askVolume14','askVolume15',
			'askVolume16','askVolume17','askVolume18','askVolume19','askVolume20',
			'askVolume21','askVolume22','askVolume23','askVolume24','askVolume25',
			'askVolume26','askVolume27','askVolume28','askVolume29','askVolume30',
			'askVolume31','askVolume32','askVolume33','askVolume34','askVolume35',
			'askVolume36','askVolume37','askVolume38','askVolume39','askVolume40',
			'bid1','bid2','bid3','bid4','bid5','bid6','bid7','bid8','bid9','bid10',
			'bid11','bid12','bid13','bid14','bid15','bid16','bid17','bid18','bid19','bid20',
			'bid21','bid22','bid23','bid24','bid25','bid26','bid27','bid28','bid29','bid30',
			'bid31','bid32','bid33','bid34','bid35','bid36','bid37','bid38','bid39','bid40',
			'bidVolume1','bidVolume2','bidVolume3','bidVolume4','bidVolume5',
			'bidVolume6','bidVolume7','bidVolume8','bidVolume9','bidVolume10',
			'bidVolume11','bidVolume12','bidVolume13','bidVolume14','bidVolume15',
			'bidVolume16','bidVolume17','bidVolume18','bidVolume19','bidVolume20',
			'bidVolume21','bidVolume22','bidVolume23','bidVolume24','bidVolume25',
			'bidVolume26','bidVolume27','bidVolume28','bidVolume29','bidVolume30',
			'bidVolume31','bidVolume32','bidVolume33','bidVolume34','bidVolume35',
			'bidVolume36','bidVolume37','bidVolume38','bidVolume39','bidVolume40',
			'bidYield1','bidYield2','bidYield3','bidYield4','bidYield5',
			'bidYield6','bidYield7','bidYield8','bidYield9','bidYield10',
			'bidYield11','bidYield12','bidYield13','bidYield14','bidYield15',
			'bidYield16','bidYield17','bidYield18','bidYield19','bidYield20',
			'bidYield21','bidYield22','bidYield23','bidYield24','bidYield25',
			'bidYield26','bidYield27','bidYield28','bidYield29','bidYield30',
			'bidYield31','bidYield32','bidYield33','bidYield34','bidYield35',
			'bidYield36','bidYield37','bidYield38','bidYield39','bidYield40',
			'askYield1','askYield2','askYield3','askYield4','askYield5',
			'askYield6','askYield7','askYield8','askYield9','askYield10',
			'askYield11','askYield12','askYield13','askYield14','askYield15',
			'askYield16','askYield17','askYield18','askYield19','askYield20',
			'askYield21','askYield22','askYield23','askYield24','askYield25',
			'askYield26','askYield27','askYield28','askYield29','askYield30',
			'askYield31','askYield32','askYield33','askYield34','askYield35',
			'askYield36','askYield37','askYield38','askYield39','askYield40'];
		let subscription = new Lightstreamer.Subscription('MERGE', itemName, fields);
		subscription.setDataAdapter(BondConstant.BEX_PRICE);
		subscription.addListener({
			// LS return updateInfo as object 
			onItemUpdate: (updateInfo: any) => {
//				console.log(updateInfo);
				callback(updateInfo);
			}
		})
		lsClient.subscribe(subscription);
		return subscription;
	}
	
	
	subscribeLSPriceQuote(lsClient: Lightstreamer, symbol: string, callback: (itemUpdate: any) => void){
//		console.log('symbol: ' + symbol);

		let fields = ['bidPrice','offerPrice','bidYtm','offerYtm','bidYtw','offerYtw'];
		let adapter = BondConstant.BOND_OTC_PRICE;
		
		if(symbol.includes(BondConstant.BOND_PRICE)){
			fields = ['bondName', 'status', 'bondCurrencyCode', 'issuer', 'bidPrice', 'lastBidPrice', 'offerPrice', 'lastOfferPrice',
				'bidVolume', 'offerVolume', 'bidYield', 'lastBidYield', 'offerYield', 'lastOfferYield', 'sgAccruedInt', 'myAccruedInt', 'hkAccruedInt'];
			adapter = BondConstant.BOND_PRICE;
		} else if (symbol.includes(BondConstant.BEX_PRICE)){
			fields = ['bondName','country','productCategory','fullSymbol','bidPrice','bidSize','askPrice','askSize','bidYield','askYield','currency','closePrice','lotSize','companyName','cumQty','openPrice',
				'highPrice','lowPrice','lastPrice','tradingFlag','tradeWoAccrdInt','tradeVoteRight','norminalPrice','netChange','lastVolume','netChangePercent','bondCategory','issuePrice','issueYield','rdt','exchangeId',
				'ticker','lastYield', 'openYieldPrice','previousYieldPrice','yieldNetChange','yieldNetChangePercent','bidNetChange','bidNetChangePercent','askNetChange','askNetChangePercent','issueCode','isin','cusip'];
			adapter = BondConstant.BEX_PRICE;
		}
		let subscription = new Lightstreamer.Subscription('MERGE', symbol, fields);
		subscription.setDataAdapter(adapter);
		subscription.addListener({
			// LS return updateInfo as object 
			onItemUpdate: (updateInfo: any) => {
//				console.log(updateInfo);
				callback(updateInfo);
			}
		})
		lsClient.subscribe(subscription);
		return subscription;
	}
	
	subscribeRanking(lsClient: Lightstreamer, rankingType:string, subType:string, callback: (itemUpdate: any) => void) {
		
	    let itemName = BondConstant.RANKING+ '-' + subType + '-' + rankingType;
//		console.log('itemName: ' + itemName);
		let fields = ['key', 'command', 'symbol', 'bondIssuer', 'amount', 'updateTime','apiType','url', 'sector', 'distFlag'];
		
		
	    const config = {
	      digestCallback: () => this.digestCallback(),
	      errorCallback: (code: number, message: string) => this.errorCallback(code, message),
	    };

	    let subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
	    subscription.setDataAdapter(BondConstant.RANKING);
	    subscription.addListener({
	      onItemUpdate: (updateObject: any) => {
	        callback(updateObject);
	      },
	      onSubscriptionError: (code, message) => {
	      }
	    })
	    lsClient.subscribe(subscription);
	    return subscription;
	}

	subscribeTimeAndSale(lsClient: Lightstreamer, symbol: string, callback: (itemUpdate: any) => void){
		let itemName = this.getItemName(BondConstant.TIME_AND_SALE, symbol);
//		console.log('itemName: ' + itemName);
		let fields = ['key', 'command', 'symbol', 'exchange', 'stockCode', 'level', 'tradeTime', 'size', 'price', 'side', 'lastBid', 'lastAsk', 'seq', 'tradeType'];
		let subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
		subscription.setDataAdapter(BondConstant.TIME_AND_SALE);		
		subscription.addListener({
			// LS return updateInfo as object 
			onItemUpdate: (updateInfo: any) => {
//				console.log(updateInfo);
				callback(updateInfo);
			}
		})
		lsClient.subscribe(subscription);
		return subscription;
	}
	

	subscribeTradeSummary(lsClient: Lightstreamer, symbol: string, callback: (itemUpdate: any) => void) {
		let itemName = this.getItemName(BondConstant.TRADE_SUMMARY, symbol);
//		console.log('itemName: ' + itemName);
		let fields = ['key', 'command', 'symbol', 'exchange', 'stockCode', 'level', 'price', 'buyCount', 'sellCount', 'unknownCount', 'buyVolume', 'sellVolume', 'unknownVolume'];
		let subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
		subscription.setDataAdapter(BondConstant.TRADE_SUMMARY);
		subscription.addListener({
			// LS return updateInfo as object 
			onItemUpdate: (updateInfo: any) => {
//				console.log(updateInfo);
				callback(updateInfo);
			}
		})
		lsClient.subscribe(subscription);
		return subscription;
	}
	

	
	/*commandUpdateListener(data, config) {
        var commandUpdateListener = {
            onItemUpdate: (info) => {
              // console.log('commandUpdateListener=', info, data);
                var key = parseInt(info.getValue('key'));
                var command = info.getValue('command');
                    if (command == 'DELETE') {
                        if (config.deleteCallback) {
                            config.deleteCallback(data[key]);
                        }
                        delete data[key];
                } else {
                    var oldData = {};
                    if (command == 'ADD') {
                        data[key] = {};
                    } else {
                        oldData = data[key];
                        data[key] = {};
                    }
                    info.forEachField((fieldName, fieldPos, val) => {
                        if (fieldName == 'key' || fieldName == 'command') {
                            return true;
                        }
                        if (!val) {
                            return true;
                        }
                        data[key][fieldName] = val;
                    });
                    if (command == 'ADD') {
                        if (config.addCallback) {
                            config.addCallback(data[key]);
                        }
                    } else {
                        if (config.updateCallback) {
                            config.updateCallback(oldData, data[key]);
                        }
                    }
                }
                config.digestCallback;
            },
        };
        return commandUpdateListener;
    }
*/
	
	
}
