import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PreLoginLayoutComponent, PostLoginLayoutComponent } from './layout';
import { PageConstant, RouteConstant } from './constant';
import { AuthenticationComponent } from './layout/authentication/authentication-template.component';

import { LAYOUT_ROUTES } from './routes/routes'
import { AUTHENTICATION_ROUTES } from './routes/authentication.routes';
import { PageAccessGuard } from './share/guards/page.access.guard';

import { PageNotFoundComponent } from './landing/page/page-not-found/page-not-found.component';
import { LogoutPageComponent } from '@auth-module/logout/logout.component';
import { LoginPageComponent } from '@auth-module/login/login.component';
import {skip} from 'rxjs/operators';
import {TwoFaGuard} from "./two-fa/guard/two-fa.guard";
import {DeviceDetectorService} from "ngx-device-detector";
import { AuthGuard } from '@share/guards/auth.guard';

const appRoutes: Routes = [
	{
		path: '',
		component: PreLoginLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
			},
			{
				path: RouteConstant.FUNDS,
				loadChildren: () => import('./funds/funds.module').then(m => m.FundsModule),
				data: {
					nav: {
						title: 'Funds',
						titleKey: 'funds',
						link: RouteConstant.FUNDS
					},
					title: PageConstant.FUNDS
				}
			},
			{
				path: RouteConstant.BONDS,
				loadChildren: () => import('./bonds/bonds.module').then(m => m.BondsModule),
				data: {
					nav: {
						title: PageConstant.BONDS,
						titleKey: 'fsmone.menu.bonds',
						link: RouteConstant.BONDS
					},
					title: PageConstant.BONDS
				}
			},
			{
				path: RouteConstant.DPMS,
				loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule),
				data: {
					nav: {
						title: PageConstant.DPMS,
						titleKey: 'fsmone.managed.portfolios',
						link: RouteConstant.DPMS
					},
					title: PageConstant.DPMS
				}
			},
			{
				path: RouteConstant.ETFS,
				loadChildren: () => import('./etfs/etfs.module').then(m => m.EtfsModule),
				data: {
					nav: {
						title: PageConstant.ETFS,
						titleKey: 'fsmone.general.etfs',
						link: RouteConstant.ETFS
					},
					title: PageConstant.STOCKS
				}
			},
			{
				path: RouteConstant.STOCKS,
				loadChildren: () => import('./stocks/stocks.module').then(m => m.StocksModule),
				data: {
					nav: {
						title: PageConstant.STOCKS,
						titleKey: 'fsmone.general.stocks',
						link: RouteConstant.STOCKS
					},
					title: PageConstant.STOCKS
				}
			},
			{
				path: RouteConstant.SUPPORT,
				loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
				data: {
					nav: {
						title: PageConstant.SUPPORT,
						titleKey: "fsmone.menu.our.services",
						link: RouteConstant.SUPPORT
					},
					title: PageConstant.SUPPORT
				}
			},
			{
				path: RouteConstant.ARTICLE,
				loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
				data: {
					nav: {
						title: PageConstant.ARTICLE,
						titleKey: 'articles',
						link: RouteConstant.ARTICLE
					},
					title: PageConstant.ARTICLE
				}
			},
			{
				path: RouteConstant.WEBCAST,
				loadChildren: () => import('./webcast/webcast.module').then(m => m.WebcastModule),
				data: {
					nav: {
						title: PageConstant.WEBCAST,
						titleKey: 'fsmone.webcasts',
						link: RouteConstant.WEBCAST
					},
					title: PageConstant.WEBCAST
				}
			},
			{
				path: RouteConstant.INSIGHT,
				loadChildren: () => import('./insight/insight.module').then(m => m.InsightModule),
				data: {
					nav: {
						title: PageConstant.INSIGHT,
						titleKey: 'fsmone.menu.insight',
						link: RouteConstant.INSIGHT
					},
					title: PageConstant.INSIGHT
				}
			},
			{
				path: '',
				loadChildren: () => import('./disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
			},
			{
				path: RouteConstant.ACCOUNT_OPENING,
				loadChildren: () => import('./account-opening/account-opening.module').then(m => m.AccountOpeningModule),
			},
			{
				path: RouteConstant.SMART_ACCOUNT,
				loadChildren: () => import('./smart-account/smart-account.module').then(mod => mod.SmartAccountModule),
			},
			// {
			// 	path: RouteConstant.HOLDING_DETAILS,
			// 	loadChildren: () => import('./holding-details/holding-details.module').then(m => m.HoldingDetailsModule),
			// 	canActivate: [AuthGuard],
			// },
			{
				path: RouteConstant.FPS,
				loadChildren: () => import('./fps/fps.module').then(m => m.FpsModule),
			},
			{
				path: '',
				loadChildren: () => import('./pre-login-account-management/pre-login-account-management.module').then(m => m.PreLoginAccountManagementModule),
			},
			{
				path: RouteConstant.CYBER_SECURITY,
				loadChildren: () => import('./cyber-security/cyber-security.module').then(m => m.CyberSecurityModule)
			},
		],
		canActivate: [TwoFaGuard, PageAccessGuard],
	},
	{
		path: '',
		component: PostLoginLayoutComponent,
		children: [
			{
				path: RouteConstant.TRADE,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./trades/trade.module').then(m => m.TradeModule),
				data: {
					nav: {
						title: PageConstant.TRADE,
						titleKey: 'fsmone.trade',
						link: RouteConstant.TRADE,
					},
					title: PageConstant.TRADE,
					breadcrumb: 'fsmone.trade'
				}
			},
			{
				path: RouteConstant.ACCOUNT_SUMMARY,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./account-summary/account-summary.module').then(m => m.AccountSummaryModule),
			},
			{
				path: RouteConstant.WATCHLIST,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./watchlist/watchlist.module').then(m => m.WatchlistModule),
				data: {
					nav: {
						title: 'watchlist',
						titleKey: 'watchlist',
						link: RouteConstant.WATCHLIST,
					},
					title: 'watchlist'
				}
			},
			{
				path: RouteConstant.NOTIFICATION,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
				data: {
					nav: {
						title: PageConstant.NOTIFICATION,
						titleKey: 'notification',
						link: RouteConstant.NOTIFICATION,
					},
					title: PageConstant.NOTIFICATION,
					breadcrumb: 'notification'
				}
			},
			{
				path: RouteConstant.MONTHLY_STATEMENT,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./monthly-statement/monthly-statement.module').then(m => m.MonthlyStatementModule),
				data: {
					nav: {
						title: PageConstant.MONTHLY_STATEMENT,
						titleKey: 'fsmone.monthly.statement',
						link: RouteConstant.MONTHLY_STATEMENT,
					},
					title: PageConstant.MONTHLY_STATEMENT,
					breadcrumb: 'fsmone.monthly.statement'
				}
			},
			{
				path: RouteConstant.TWO_FA,
				loadChildren: () => import('./two-fa/two-fa.module').then(m => m.TwoFaModule),
			},
			{
				path: RouteConstant.ACCOUNT_SETTING,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./account-setting/account-setting.module').then(m => m.AccountSettingModule),
				data: {
					nav: {
						title: PageConstant.ACCOUNT_SETTING,
						titleKey: 'fsmone.account.settings',
						link: RouteConstant.ACCOUNT_SETTING,
					},
					title: PageConstant.ACCOUNT_SETTING,
					breadcrumb: 'fsmone.account.settings'
				}
			},
			{
				path: RouteConstant.TRANSACTION_RECORD,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./transaction-record/transaction-record.module').then(m => m.TransactionRecordModule),
				data: {
					breadcrumb: 'fsmone.a.c.summary'
				}
			},
			{
				path: RouteConstant.HOLDINGS,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./holdings/holdings.module').then(m => m.HoldingsModule),
			},
      {
				path: RouteConstant.MARGIN,
				canActivate: [TwoFaGuard],
				loadChildren: () => import('./margin/margin.module').then(m => m.MarginModule),
			},

		],
		canActivateChild: [PageAccessGuard],
		canActivate: [PageAccessGuard,AuthGuard]
	},
	{
		path: RouteConstant.PAGE_NOT_FOUND,
		component: PageNotFoundComponent
	},
	{
		path: RouteConstant.LOGOUT,
		component: LogoutPageComponent,
		children: []
	},
	{
		path: RouteConstant.FSM_LOGOUT,
		component: LogoutPageComponent,
		children: []
	},
	{
		path: '**',
		redirectTo: RouteConstant.PAGE_NOT_FOUND
	},
	{
		path: RouteConstant.LOGIN,
		component: LoginPageComponent,
	},
	/*
	{
		path: '',
		component: AuthenticationComponent,
		data: { title: "content Views" },
		children: AUTHENTICATION_ROUTES
	},
	{
		path: "post-login",
		component: PostLoginComponent,
		data: { title: "content Views" },
		children: LAYOUT_ROUTES,
		canActivateChild: [PageAccessGuard]
	}*/
]

@NgModule({
	imports: [RouterModule.forRoot(
		appRoutes, {
		preloadingStrategy: PreloadAllModules,
		onSameUrlNavigation: 'reload',
		useHash: false,
		scrollPositionRestoration: 'top'
	}
	)],
	exports: [RouterModule],
	providers: [DeviceDetectorService],
})
export class AppRoutingModule { }
