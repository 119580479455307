<ng-container>
<p><b>{{selectedCountry}}</b></p>
  <highcharts-chart
    (chartInstance)="getChartInstance($event)"	    
	[Highcharts]="Highcharts"
	[(update)]=updateFromInput
	[oneToOne]="true"
	[options]="chartOptions"
	[runOutsideAngular]="true"
    class="d-block w-100 mw-100 my-3 "
  >
  </highcharts-chart>
</ng-container>