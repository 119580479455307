import {
	Component,
	OnInit,
	Input
} from '@angular/core';
import { Select } from "@ngxs/store";
import { GlobalState } from "../../../../core/store";
import { Observable } from "rxjs";
import { filter } from 'rxjs/operators';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { 
	AppConstant,
	RouteConstant 
	} from 'src/app/constant';
import { WebcastRestService } from '@api-module/rest/webcast.rest.service';
import { TranslateService } from '@ngx-translate/core';
import { IResponse } from "@api-module/model/common/i-response";
import { IArticleDisplayModel } from '@api-module/model/article/i-article-display.model';
import { 
	DomSanitizer,
	SafeHtml
} from "@angular/platform-browser";
import {ActivatedRoute, Router} from '@angular/router';
import {ITabModel} from '@api-module/model/common/i-tab.model';

@Component({
	selector: 'article-video-container',
	templateUrl: './article-video.component.html',
	styleUrls: ['./article-video.component.scss']
})

export class ArticleVideoComponent implements OnInit {
	constructor(
		private globalDataStorage: GlobalDataStorage,
		private webcastService: WebcastRestService,
		private sanitizer: DomSanitizer,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService
	) {
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}

	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	@Input() articleNo: string; 
	articleDisplayModel: IArticleDisplayModel = {} as IArticleDisplayModel;
	loading: boolean = false;
	videoArticle: any;
	safeUrl: SafeHtml = null;
	localeOption: string = 'zh-TW';
	
	relatedArticles = [];
	latestArticles = [];
	
	related_res: boolean = false;
	latest_res: boolean = false;
	latest: boolean = false;
	tabOptions: ITabModel[]= [
		{
			label: this.translateService.instant('related.videos'),
			value: 'related',
			active: true
		},
		{
			label: this.translateService.instant('latest.videos'),
			value: 'latest'
		}
	];

	ngOnInit() {
		this.loading = true;
		this.articleNo = this.route.snapshot.params['articleno'];
		this.route.params.subscribe(routeParams=>{
			if(routeParams.articleno != null && routeParams.articleno != undefined){
				this.articleNo = routeParams.articleno;
			}
			this.webcastService.getVideoDetails(this.articleNo).subscribe((response: IResponse<any>)=>{
				if( AppConstant.RESPONSE_SUCCESS === response.status ){
					this.videoArticle = response.data[0];
					let result = response.data[0];
					if(result.videoPath != null){
						let videoPath = result.videoPath.replace('/v/', '/embed/');
						this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoPath);
					}
					
					this.articleDisplayModel.articleLogDate = result.broadcastDate == null ? null : new Date(result.broadcastDate);
					this.articleDisplayModel.articleTitle = result.title || '';
					this.articleDisplayModel.articleSynopsis = result.sypnosis || '';
					this.articleDisplayModel.articleHtml = result.description || '';
					this.articleDisplayModel.featuredImageName = result.featuredImage || '';
					this.articleDisplayModel.category = result.catSubHeaderId || '';
					this.articleDisplayModel.hitsFsm = result.hitsFsm || '';
					this.articleDisplayModel.videoLength = result.videoLength || '';
				}
				
				this.webcastService.findSubHeaderName().subscribe((response2: IResponse<any>)=>{
					if( AppConstant.RESPONSE_SUCCESS === response2.status ) {
						for(let item of response2.data) {
							if(item.categorySubHeaderLocPk.categorysubheaderid == this.articleDisplayModel.category) {
								this.articleDisplayModel.translatedCategory = item.categorySubHeaderName;
							}
						}
					}
					this.loading = false;
				})
				
				this.webcastService.findVideoByCategorySubHeaderId(Number(this.articleDisplayModel.category)).subscribe((response2: IResponse<any>)=>{
					if(AppConstant.RESPONSE_SUCCESS == response2.status) {
						this.relatedArticles = response2.data.filter(x=>x.articleno != this.articleNo);
						this.relatedArticles = this.relatedArticles.splice(0,5);
						this.related_res = true;
					}
				})
			})
		})
		
		
		this.webcastService.findLatestWebcastList(5).subscribe((response: IResponse<any>)=>{
			if(AppConstant.RESPONSE_SUCCESS == response.status) {
				this.latestArticles = response.data;
				this.latest_res = true;
			}
		})
	}//oninit
	
	onClickPeriod(event) {
		if(event.rankPeriod == 'latest') {
			this.latest = true;
		} else {
			this.latest = false;
		}
		
		this.changeActivePeriod(event.rankPeriod);
	}//onclickperiod
	
	changeActivePeriod(period: string) {
		for (const option of this.tabOptions) {
			option.value === period
				? option.active = true
				: option.active = false;
		}
	}//changeactiveperiod

	gotoWebCast(articleno: string): string {
		return "/"+RouteConstant.WEBCAST+"/"+RouteConstant.WEBCAST_DETAILS+"/"+articleno;
	}

}