import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NZ_I18N, zh_TW,NzIconModule } from 'ng-zorro-antd';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
//import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { ShareModule } from '@ngx-share/core';
//import { TemplateModule } from './template/template.module';

import { GlobalDataStorage } from './share/storages/global-data.storage';
//import { PostLoginComponent } from './layout/post-login/post-login.component';
import { AuthenticationComponent } from './layout/authentication/authentication-template.component';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SharedCommonModule} from './share/common.module';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@util/store/storage/local-storage.service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { BreadcrumbModule, BreadcrumbService } from "xng-breadcrumb";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DatePipe } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
	missingKeyObj = [];

	handle(params: MissingTranslationHandlerParams) {
		if (false) {
			return params.key;
		} else {
			if (this.missingKeyObj.indexOf(params.key) === -1) {
				this.missingKeyObj.push(params.key);
			}
			return `[MISSING-${params.key}]`;
		}
	}
}

const cookieConfig: NgcCookieConsentConfig = {
	cookie: { 
		domain: window.location.hostname
	},
	theme: 'edgeless',
	type: 'info',
	layout: 'cookies-layout',
	layouts: {
		"cookies-layout": '{{message}}{{compliance}}'
	},
	palette: {
		button: {
			"background": "transparent",
			"text": "#000",
			"border": "transparent"
		}
	},
	elements: {
		message: `
			<p class="cookies-title">{{title}}</p>
			<span>
				{{message1}}
				<a target="_blank" href="{{privacyPolicyHref}}" class="cookies-text-warning"><strong>{{privacyPolicy}}</strong></a>
				{{message2}}
			</span>
		`,
	},
	content: {
		title: 'We use cookies',
		message1: 'If you close this message or continue to use this site, you will consent to the use of Cookies, unless you choose to disable them. Click on our ',
		message2: ' to understand more.',
		privacyPolicy: 'Privacy Policy',
		privacyPolicyHref: '/personal-info-collection-statement',
		dismiss: '<i _ngcontent-docs-c168="" nz-icon="" class="anticon anticon-close"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="close" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></i>'
	}
};

@NgModule({
	declarations: [
		AppComponent,
		//PostLoginComponent,
		AuthenticationComponent

	],
	imports: [
		BreadcrumbModule,
		BrowserModule,
    	NzIconModule,
    	CoreModule.forRoot(),
		AppRoutingModule,
		NzModalModule,
		NzButtonModule,
		SharedCommonModule,
		NgcCookieConsentModule.forRoot(cookieConfig),
		TranslateModule.forRoot({
			missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		NgIdleKeepaliveModule.forRoot()
	],
	providers: [
		  { provide: NZ_I18N, useValue: zh_TW },
		  {
		    provide: APP_INITIALIZER,
		    useFactory: appInitializerFactory,
		    deps: [TranslateService, GlobalDataStorage],
		    multi: true
		  },
		GlobalDataStorage,
		CookieService,
		LocalStorageService,
		BreadcrumbService,
    DatePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }


export function appInitializerFactory(translate: TranslateService, storage: GlobalDataStorage) 
{
	return () => new Promise<any>((resolve: any) => 
	{
		var langToSet = 'ch';	      

		if (storage.getStorage("locale"))
		{
			langToSet = storage.getStorage("locale");
		}
		
		translate.setDefaultLang(langToSet);
		translate.use(langToSet).subscribe(() => 
		{
        	console.info(`Successfully initialized '${langToSet}' language.'`);
      	}, err => 
		{
        	console.error(`Problem with '${langToSet}' language initialization.'`);
      	}, () => 
		{
        	resolve(null);
      	});	
	});
}
