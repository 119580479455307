import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UicFsmiconWrapperComponent } from './uic-fsmicon-wrapper.component';



@NgModule({
  declarations: [UicFsmiconWrapperComponent],
  exports: [UicFsmiconWrapperComponent],
  imports: [
    CommonModule
  ]
})
export class UicFsmiconWrapperModule { }
