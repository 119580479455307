<div *ngIf="item=='iOSQR'" class="qrcode">
	<img alt="Apple QR" [src]="GENERAL_QR_IMG_PATH + 'appleQR.png'"/>
</div>

<div *ngIf="item=='androidQR'" class="qrcode">
	<img alt="android QR" [src]="GENERAL_QR_IMG_PATH + 'androidQR.png'" />
</div>

<div *ngIf="item=='huaweiQR'" class="qrcode">
	<img alt="Huawei QR" [src]="GENERAL_QR_IMG_PATH + 'huaweiQR.png'" />
</div>

<div *ngIf="item=='iOSStore'">
    <a href="https://apps.apple.com/app/apple-store/id427129018?pt=497385&ct=fsmonehk-website&mt=8" target="_blank">
        <img alt="app store tag" [src]="GENERAL_QR_IMG_PATH + 'app-store-tag.png'" />
    </a>
</div>

<div *ngIf="item=='androidStore'">
	<a href="https://play.google.com/store/apps/details?id=com.ifast.fsm&hl=zh_HK" target="_blank">
         <img alt="google play tag" [src]="GENERAL_QR_IMG_PATH + 'google-play-tag.png'" />
     </a>
</div>

<div *ngIf="item=='huaweiStore'">
	 <a href="https://appgallery1.huawei.com/#/app/C102220535" target="_blank">
         <img alt="huawei tagt" [src]="GENERAL_QR_IMG_PATH + 'huawei-tag.png'" />
     </a>
</div>