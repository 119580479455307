<ng-container *ngIf="event.registrationStatus=='concluded' && event.webcastsUrlLink">
	<b2c-event-card [ngClass]="{'isMobile':isMobile$}"
					[eventImage]="event.bannerUrl" [date]="event.startDate | hkDate:localeOption" [eventStatus]="('dpms.free' |translate)"
                    [title]="event.title" [time]="event.time"
                    [location]="event?.location"
                    [isMobile]="true"
		            [customDateField]="event.displayToday === 'Y' ? showEventToday: customDate"
                    button="{{ 'fsmone.events.replay' | translate}}" (buttonEvent)="replayWebminar(event)"
                    (titleEvent)="navigateEvent(event)" (imageEvent)="navigateEvent(event)" titleUrl=""
                    imageUrl="//" [buttonDisabled]=false [buttonTooltipText]="null">
	</b2c-event-card>
</ng-container>

<ng-container *ngIf="event.registrationStatus!='concluded' || !event.webcastsUrlLink">
	<b2c-event-card [ngClass]="{'isMobile':isMobile$}"
					[eventImage]="event.bannerUrl" [date]="event.startDate | hkDate:localeOption" [eventStatus]="('dpms.free' |translate)"
                    [title]="event.title" [time]="event.time"
                    [location]="event?.location"
                    [isMobile]="true"
		            [customDateField]="event.displayToday === 'Y' ? showEventToday: customDate"
                    button="{{ 'fsmone.events.registernow' | translate}}" (buttonEvent)="registerEvent(event)"
                    (titleEvent)="navigateEvent(event)" (imageEvent)="navigateEvent(event)" titleUrl=""
                    imageUrl="//" [buttonDisabled]="event.registrationStatus==='registernow'?false:true" [buttonTooltipText]="event.registrationStatus==='registernow'?null:(hitMaxMsgKey|translate)">
	</b2c-event-card>
</ng-container>
            
<ng-template #customDate>
		<span [ngClass]="{'text-md': (isMobile$ | async) || showMobileWording}">{{event.startDate | hkDate:localeOption}}</span>
</ng-template>

<ng-template #showEventToday>
  <span [ngClass]="{'text-md': (isMobile$ | async) || showMobileWording}">{{'today' | translate}}</span>
</ng-template>