import {IValidationResultModel} from "@api-module/model/display/i-validation-result.model";
import {Injectable} from "@angular/core";
import {ApiConstant} from "@api-module/api.endpoint.constant";
import {HttpClient} from "@angular/common/http";
import {HttpParamsService} from "@api-module/service/http-params.service";
import {IResponse} from "@api-module/model/common/i-response";
import {IForgotLoginRequestModel} from "@api-module/model/account/i-forgot-login-request.model";


@Injectable({
    providedIn: 'root'
})
export class ForgetLoginRestService {
    readonly FORGOT_LOGIN_ID: string = ApiConstant.FORGOT_LOGIN_ID;
    readonly FORGOT_PASSWORD: string = ApiConstant.FORGOT_PASSWORD;

    constructor(private http: HttpClient, private paramService: HttpParamsService) {
    }

    forgetLoginIdChecking(forgotLoginRequestModel: IForgotLoginRequestModel) {
        return this.http.post<IResponse<IValidationResultModel>>(this.FORGOT_LOGIN_ID + ApiConstant.FORGOT_LOGIN_ID_CHECKING, forgotLoginRequestModel);
    }

    requestSendSmsOtp(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.FORGOT_LOGIN_ID + ApiConstant.REQUEST_SEND_OTP,forgotLoginRequestModel);
    }

    recoverLoginId(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.FORGOT_LOGIN_ID + ApiConstant.RECOVER_LOGIN_ID,forgotLoginRequestModel);
    }

    forgetPasswordChecking(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.FORGOT_PASSWORD + ApiConstant.FORGOT_PASSWORD_CHECKING ,forgotLoginRequestModel);
    }

    requestOtpPassword(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.FORGOT_PASSWORD + ApiConstant.REQUEST_OTP_PASSWORD,forgotLoginRequestModel);
    }

    resetPasswordSubmit(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.FORGOT_PASSWORD + ApiConstant.RESET_PASSWORD_SUBMIT,forgotLoginRequestModel);
    }
}

