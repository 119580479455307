import { isNumber } from 'src/app/util/number';
import { deepCopy } from 'src/app/util/deep-copy';

/**
 * sort array via single
 * @param sortAttribute {key: property of the object, value: 'ascend' or 'descend'}
 * @param inputData
 */
export function sort(sortAttribute: { key: string; value: string }, inputData: any[]) {
  const dataArr = deepCopy(inputData);

  if (sortAttribute.key === '' || sortAttribute.value === null) {
    return dataArr;
  }

  const outputDataList = dataArr.sort((a, b) => {
    const isAsc = sortAttribute.value === 'ascend';
    switch (sortAttribute.key) {
      case sortAttribute.key:
        return compare(
          typeof a[sortAttribute.key] !== 'string'
            ? a[sortAttribute.key]
            : a[sortAttribute.key].toUpperCase(),
          typeof b[sortAttribute.key] !== 'string'
            ? b[sortAttribute.key]
            : b[sortAttribute.key].toUpperCase(),
          isAsc
        );
      default:
        return 0;
    }
  });

  return outputDataList;
}

export function sortFiguresList<T>(sortAttribute: { key: string, value: string }, inputData: Array<T>) {
  if (!inputData || inputData?.length <= 1 || sortAttribute.key === '' || sortAttribute.value === null) {
    return inputData;
  }

  const dataList: Array<T> = deepCopy(inputData);
  const { key, value } = sortAttribute;

  return dataList.sort((a: T, b: T) => {
    const isAsc: boolean = value === 'ascend';
    let firstElement = a[key];
    let secondElement = b[key];

    if (isNumber(firstElement) && isNumber(secondElement)) {
      firstElement = +firstElement;
      secondElement = +secondElement;

      return compare(firstElement, secondElement, isAsc);
    }
    else if (typeof firstElement === 'string' && typeof secondElement === 'string') {
      return compare(firstElement?.toUpperCase(), secondElement?.toUpperCase(), isAsc);
    }
    else {
      return compare(firstElement, secondElement, isAsc);
    }
  });
}

/**
 * if isAsc is true
 * a > b    = 1
 * a === b  = 0
 * a < b    = -1
 *
 * if isAsc is false
 * a > b    = -1
 * a === b  = 0
 * a < b    = 1
 *
 * @param a
 * @param b
 * @param isAsc
 */
export function compare(a, b, isAsc: boolean) {
  // null value is - (dash)
  if (!a && a != 0) a = '-';
  if (!b && b != 0) b = '-';

  // whole number is date
  // if (typeof a == 'number' && a % 1 == 0) a = formatDate(a, 'yyyy-MM-dd HH:mm:ss', 'en');
  // if (typeof b == 'number' && b % 1 == 0) b = formatDate(b, 'yyyy-MM-dd HH:mm:ss', 'en');

  if (a === b) return 0;

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
