import {Component, Input, OnInit, SimpleChanges, OnDestroy} from "@angular/core";
import {IBondExpressListDetailsModel} from "../../../../api/model/bond/i-bond-express-list-details-model";
import {NavigationExtras, Router} from "@angular/router";
import {RouteConstant} from "../../../../constant";
import {UtilService} from "@api-module/service/common/util.service";
import {gotoBuy, gotoSell} from '@share/services/product-search.service';
import {TranslateService} from '@ngx-translate/core';
import {Select} from '@ngxs/store';
import {GlobalState} from 'src/app/core/store';
import {Observable} from 'rxjs';
import {BondConstant} from '@constant';
import {LightstreamerBondService} from 'src/app/share/services/bond/lightstreamer-bond.service';
import {cloneDeep} from 'lodash';

@Component({
    selector: 'app-bond-express-table',
    templateUrl: './bond-express-table.component.html',
    providers: [UtilService]

})

export class BondExpressTableComponent implements OnInit, OnDestroy {

    @Input() bondExpressList: IBondExpressListDetailsModel[];
    @Input() isFirstLoad: boolean;
    @Input() isOpenMarket: boolean = false;
    @Input() tab: string;
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    @Input() showSearchFilter?: boolean = true;
	@Input() isRMOEnabled: boolean;
	@Input() lsClient: any;

    hoverBondExpressData: any[] = [];
    searchValue: string = '';
    bondExpressDisplayList: IBondExpressListDetailsModel[] = [];
    sortAttribute: any;
    GENERAL_ICON_PATH = 'assets/images/general/icons/';
	BOND_EXPRESS_ICON_PATH = 'assets/images/bond-express/';
    factsheetPath = "/" + RouteConstant.BONDS + '/' + RouteConstant.BOND_FACTSHEET + "/";

	isRmoPhase2Enabled: boolean = BondConstant.RMO_PHASE_2;
	isRmoPhase3Enabled: boolean = BondConstant.RMO_PHASE_3;
	isViewSmallLot: boolean = true;
    fullLotRMOList: IBondExpressListDetailsModel[] = [];
    smallLotRMOList: IBondExpressListDetailsModel[] = [];
	subscriptionList: any [] = [];
	bexPriceSymbolList: any [] = [];
	
    columnDisplayList = [];
    allColumnDisplayList = [
        {
            label: this.translateService.instant("fsmone.bonds.bond.annual.coupon.rate"),
            value: 'annualCoupon',
            minWidth: null,
            checked: true,
			sortable: true
        },
        {
            label: this.translateService.instant("fsmone.bonds.years.to.maturity.years"),
            value: 'yearsToMaturity',
            minWidth: null,
            checked: true,
			sortable: true
        },
        // {label: this.translateService.instant("fsmone.minimum.investment.amount"), value: 'minAmount', minWidth: null, checked: true},
        // {label: this.translateService.instant("fsmone.minimum.investment.amount.pi"), value: 'minAmountPI', minWidth: null, checked: true},
        {
            label: this.translateService.instant("fsmone.ask.yield.bond.express") + "(% p.a.)",
            value: 'offerYield',
            minWidth: null,
            checked: true,
			sortable: true
        },
        {
            label: this.translateService.instant("fsmone.new.bonds.on.board.ask.price.investor.buys"),
            value: 'offerPrice',
            minWidth: '175px',
            checked: true,
			sortable: false
        },
        {
            label: this.translateService.instant("fsmone.bond.express.bid.price.investor.sells"),
            value: 'bidPrice',
            minWidth: '175px',
            checked: true,
			sortable: false
        },
        {label: this.translateService.instant("related.articles"), value: 'articles', cminWidth: null, checked: true, sortable: false}
    ];
    piColumnDisplayList = [
        {
            label: this.translateService.instant("fsmone.bonds.bond.annual.coupon.rate"),
            value: 'annualCoupon',
            minWidth: null,
            checked: true,
			sortable: true
        },
        {
            label: this.translateService.instant("fsmone.bonds.years.to.maturity.years"),
            value: 'yearsToMaturity',
            minWidth: null,
            checked: true,
			sortable: true
        },
        //{label: this.translateService.instant("fsmone.minimum.investment.amount"), value: 'minAmount', minWidth: null, checked: true},
        {
            label: this.translateService.instant("fsmone.minimum.investment.amount.pi"),
            value: 'minAmountPI',
            minWidth: null,
            checked: true,
			sortable: true
        },
        {
            label: this.translateService.instant("fsmone.ask.yield.bond.express") + "(% p.a.)",
            value: 'offerYield',
            minWidth: null,
            checked: true,
			sortable: true
        },
        {
            label: this.translateService.instant("fsmone.new.bonds.on.board.ask.price.investor.buys"),
            value: 'offerPrice',
            minWidth: '175px',
            checked: true,
			sortable: false
        },
        {
            label: this.translateService.instant("fsmone.bond.express.bid.price.investor.sells"),
            value: 'bidPrice',
            minWidth: '175px',
            checked: true,
			sortable: false
        },
        {label: this.translateService.instant("related.articles"), value: 'articles', minWidth: null, checked: true, sortable: false}
    ];


    constructor(
        private router: Router,
        private utilService: UtilService,
        private translateService: TranslateService,
		private lightstreamerBondService: LightstreamerBondService
    ) {
    }

    ngOnInit(): void {
        this.columnDisplayList = (this.tab == 'pi') ? this.piColumnDisplayList : this.allColumnDisplayList;
    }

    ngOnChanges(changes: SimpleChanges) {
		if (this.isRMOEnabled) {
			this.addListToRMO();
		}
        this.search();
        if (this.sortAttribute) {
            this.sort(this.sortAttribute);
        }
    }

	ngOnDestroy() {
		this.unsubscribeBexPrice();
	}

    getIsHideColumn(columnName: string): boolean {
        let column = this.columnDisplayList.find(column => column.value === columnName);
        return column ? !column.checked : true;
    }

    showHideWatchlistButton(index): boolean {
        for (let i = 0; i < this.hoverBondExpressData.length; i++) {
            if (i !== index) {
                this.hoverBondExpressData[i] = false;
            }
        }
        this.hoverBondExpressData[index] = !this.hoverBondExpressData[index];
        return this.hoverBondExpressData[index];
    }

    sort(sort: { key: string; value: any }): void {
		this.bondExpressDisplayList = this.customSort(sort, this.bondExpressDisplayList);
    }

    navigateToBondFactsheet(issueCode: string, bondName: string) {
        let route = RouteConstant.BONDS + '/' + RouteConstant.BOND_FACTSHEET + "/" + issueCode;
        window.open(route, '_blank', 'noreferrer');
    }

    getToBondFactsheetUrl(issueCode: string, bondName: string):string {
        return RouteConstant.BONDS + '/' + RouteConstant.BOND_FACTSHEET + "/" + issueCode;
    }

    navigateToBondWatchlist(bondData: any) {
        const bondName: string = bondData.bondName;
        const issueCode: string = bondData.issueCode;
        const bondIssuer: string = bondData.issuer;
        const extras: NavigationExtras = {
            queryParams: {bondName, issueCode, bondIssuer},
        };
        // this.router.navigate([RouteConstant.BOND_TOOL, RouteConstant.WATCHLIST], extras);
    }

    search(): void {
		if (this.isRMOEnabled) {
	        if (this.searchValue) {
				if (this.isViewSmallLot) {
					 this.bondExpressDisplayList = this.smallLotRMOList.filter(
	                (item: IBondExpressListDetailsModel) => item.bondName.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1 || item.issuer.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1);
				} else {
					 this.bondExpressDisplayList = this.fullLotRMOList.filter(
	                (item: IBondExpressListDetailsModel) => item.bondName.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1 || item.issuer.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1);
				}
	        } else {
				if (this.isViewSmallLot) {
					this.bondExpressDisplayList = this.smallLotRMOList;
				} else {
					this.bondExpressDisplayList = this.fullLotRMOList;				
				}
	        }

			if ((this.isRmoPhase2Enabled && !this.isViewSmallLot) || this.isRmoPhase3Enabled) {
				this.allColumnDisplayList[2].label = this.translateService.instant("fsmone.ask.yield") + "(% p.a.)";
			} else {
				this.allColumnDisplayList[2].label = this.translateService.instant("fsmone.ask.yield.bond.express") + "(% p.a.)";
			}
		} else {
			if (this.searchValue) {
	            this.bondExpressDisplayList = this.bondExpressList.filter(
	                (item: IBondExpressListDetailsModel) => item.bondName.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1 || item.issuer.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1);
	        } else {
	            this.bondExpressList = this.customSort({key: 'bondName', value: 'ascend'}, this.bondExpressList);
	            this.bondExpressDisplayList = this.bondExpressList;
	        }
		}
    }

    clearSearchText(): void {
        this.searchValue = '';
        this.search();
    }

    buyNow(issueCode) {
		if (this.isRMOEnabled && !this.isViewSmallLot) {
			gotoBuy(issueCode, 'bond');
		} else {
			gotoBuy(issueCode, 'bondExpress');
		}
        //https://hktrial.fundsupermart.com/fsm/bond-transaction/buy/HK0000646957
//	var data = genRouterLink('bond-transaction','buy',issueCode);
//	let FSM = '/fsm';
//	window.open(getEnv()+FSM+data);
    }

    sellNow(issueCode) {
		if (this.isRMOEnabled && !this.isViewSmallLot) {
			gotoSell(issueCode, 'bond');
		} else {
			gotoSell(issueCode, 'bondExpress');
		}
        //https://hktrial.fundsupermart.com/fsm/bond-transaction/sell
//	var data = genRouterLink('bond-transaction','sell');
//	let FSM = '/fsm';
//	window.open(getEnv()+FSM+data);
    }

//    redirectToArticle(id, bondName) {
//        if (id.indexOf("rcms") > -1) {
//            id = id.replace("rcms", "");
//        }
//
//        let route = RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + id ;
//        window.open(route, '_blank', 'noreferrer');
//        //window.open(route, '_blank', 'noreferrer');
//        //var data = genRouterLink('article','view',id);
//        //window.open(getEnv()+data);
//    }

    getRelatedArticleUrl(id, bondName): string {
        if (id.indexOf("rcms") > -1) {
            id = id.replace("rcms", "");
        }

        return RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + id ;
    }

    clearInput() {
        this.searchValue = '';
        this.search();
    }

    //check yearsToMaturity is number
    isNumber(yearsToMaturity) {
        return !isNaN(Number(yearsToMaturity));
    }

	isSortableColumn(columnValue: string): boolean {
	    const column = this.columnDisplayList.find(col => col.value === columnValue);
	    return !!column && column.sortable;
	}
	
	customSort(sortAttribute: { key: string; value: string }, inputData: any[]): any[] {
	    const dataArr = this.utilService.deepCopy(inputData);
	    if (sortAttribute.key === '' || sortAttribute.value === null) {
	        return dataArr;
	    }
	
	    let outputDataList = dataArr.sort((a, b) => {
	        const isAsc = sortAttribute.value === 'ascend';
	
	        if (sortAttribute.key === 'yearsToMaturity') {
	            // Treat "Matured/ Called" as the smallest value
	            if (a[sortAttribute.key] === "Matured/ Called") return isAsc ? -1 : 1;
	            if (b[sortAttribute.key] === "Matured/ Called") return isAsc ? 1 : -1;
			}
	
	        if (!isNaN(Number(a[sortAttribute.key]))) {
	            return this.utilService.compareNumber(
	                a[sortAttribute.key],
	                b[sortAttribute.key],
	                isAsc
	            );
	        } else {
	            return this.utilService.compare(
	                typeof a[sortAttribute.key] !== 'string' ? a[sortAttribute.key] : a[sortAttribute.key].toUpperCase(),
	                typeof b[sortAttribute.key] !== 'string' ? b[sortAttribute.key] : b[sortAttribute.key].toUpperCase(),
	                isAsc
	            );
	        }
	    });
	
	    return outputDataList;
	}
	
	addListToRMO() {
		this.bondExpressList = this.customSort({key: 'bondName', value: 'ascend'}, this.bondExpressList);
		// create deep copies of filtered items
		this.smallLotRMOList = this.utilService.deepCopy(this.bondExpressList);
		
		if (this.isRmoPhase2Enabled) {
			this.fullLotRMOList = this.bondExpressList.filter((item: IBondExpressListDetailsModel) => item.fullLotRMO === "Y").map(item => cloneDeep(item));
			this.subscribeBexPrice();
		}
	}
	
	subscribeBexPrice() {
		this.unsubscribeBexPrice();
		
		if (this.isRMOEnabled && this.lsClient) {
			if (this.smallLotRMOList.length > 0) {
				for (let bond of this.smallLotRMOList) {
					if (bond?.smallLotRMO === 'Y') {
						bond.bexPriceSymbol = this.lightstreamerBondService.getItemName(BondConstant.BEX_PRICE, '8000.9.' + bond.externalBondId + '/O');
						
						if (bond?.bexPriceSymbol && !this.bexPriceSymbolList.includes(bond?.bexPriceSymbol)) {
							const subscription = this.lightstreamerBondService.subscribeLSPriceQuote(this.lsClient, bond?.bexPriceSymbol, (itemUpdate) =>{						
								
								bond.offerYield = parseFloat(itemUpdate.getValue("askYield"));
								bond.offerPrice = parseFloat(itemUpdate.getValue("askPrice"));
								bond.askPriceChange = parseFloat(itemUpdate.getValue("askNetChange"));
								
								bond.bidPrice = parseFloat(itemUpdate.getValue("bidPrice"));
								bond.bidPriceChange = parseFloat(itemUpdate.getValue("bidNetChange"));
							});
							
							this.bexPriceSymbolList.push(bond?.bexPriceSymbol);
							this.subscriptionList.push(subscription);
						}						
					}
				}
			}
			
			if (this.fullLotRMOList.length > 0) {
				for (let bond of this.fullLotRMOList) {
					if (bond?.fullLotRMO === 'Y') {
						bond.bexPriceSymbol = this.lightstreamerBondService.getItemName(BondConstant.BEX_PRICE, '8000.9.' + bond.externalBondId);
						
						if (bond?.bexPriceSymbol && !this.bexPriceSymbolList.includes(bond?.bexPriceSymbol)) {
							const subscription = this.lightstreamerBondService.subscribeLSPriceQuote(this.lsClient, bond?.bexPriceSymbol, (itemUpdate) =>{
								
//								console.log("ask yield - " + itemUpdate.getValue("askYield"));
//								console.log("ask price - " + itemUpdate.getValue("askPrice"));
//								console.log("ask price change - " + itemUpdate.getValue("askNetChange"));
//								console.log("bid price - " + itemUpdate.getValue("bidPrice"));
//								console.log("bid price change - " + itemUpdate.getValue("bidNetChange"));
								
								bond.offerYield = parseFloat(itemUpdate.getValue("askYield"));
								bond.offerPrice = parseFloat(itemUpdate.getValue("askPrice"));
								bond.askPriceChange = parseFloat(itemUpdate.getValue("askNetChange"));
								
								bond.bidPrice = parseFloat(itemUpdate.getValue("bidPrice"));
								bond.bidPriceChange = parseFloat(itemUpdate.getValue("bidNetChange"));
							});
							
							this.bexPriceSymbolList.push(bond?.bexPriceSymbol);
							this.subscriptionList.push(subscription);
						}						
					}
				}
			}
//			console.log(this.bexPriceSymbolList);
			
		}
	}
	
	unsubscribeBexPrice() {
		if (this.subscriptionList.length > 0) {
			this.subscriptionList.forEach(item => {
				this.lsClient.unsubscribe(item);
			});
			this.subscriptionList = [];
			this.bexPriceSymbolList = [];
		}
	}
}
