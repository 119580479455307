import { Injectable } from "@angular/core";
import {IAccountOpeningModel} from '@api-module/model/account/i-account-opening.model';
import { BehaviorSubject } from "rxjs";

const PERSONAL_ACCT = '_pAcctOpen';
const SMART_ACCT = '_smartAcctOpen';
const DISCRETIONARY_ACCT = '_dAcctOpen';
const BENE_ACCT = '_bAcctOpen';

const ADD_PERSONAL_ACCT = '_pAddAcctOpen';
const ADD_SMART_ACCT = '_smartAddAcctOpen';
const ADD_DISCRETIONARY_ACCT = '_dAddAcctOpen';
const ADD_BENE_ACCT = '_bAddAcctOpen';

@Injectable({
	providedIn: 'root'
})

export class AccountOpeningStorage {

	selectedAoType = new BehaviorSubject<string>('');

	public getPAcctDtl(isAddAcc): IAccountOpeningModel {
		var acctDtl = null;
		if(isAddAcc){
			acctDtl = JSON.parse(sessionStorage.getItem(ADD_PERSONAL_ACCT));
		} else {
			acctDtl = JSON.parse(sessionStorage.getItem(PERSONAL_ACCT));
		}
        return acctDtl;
	}
	
	public getDAcctDtl(isAddAcc): IAccountOpeningModel {
		var acctDtl = null;
		if(isAddAcc){
			acctDtl = JSON.parse(sessionStorage.getItem(ADD_DISCRETIONARY_ACCT));
		} else {
			acctDtl = JSON.parse(sessionStorage.getItem(DISCRETIONARY_ACCT));
		}
        return acctDtl;
	}
	
	public getBAcctDtl(isAddAcc): IAccountOpeningModel {
		var acctDtl = null;
		if(isAddAcc){
			acctDtl = JSON.parse(sessionStorage.getItem(ADD_BENE_ACCT));
		} else {
			acctDtl = JSON.parse(sessionStorage.getItem(BENE_ACCT));
		}
        return acctDtl;
	}
	
	public getSmartAcctDtl(isAddAcc): IAccountOpeningModel {
		var acctDtl = null;
		if(isAddAcc){
			acctDtl = JSON.parse(sessionStorage.getItem(ADD_SMART_ACCT));
		} else {
			acctDtl = JSON.parse(sessionStorage.getItem(SMART_ACCT));
		}
        return acctDtl;
	}
	
    public setPAcctDtl(pAcctDtl: IAccountOpeningModel, isAddAcc) {
		if(isAddAcc){
			sessionStorage.setItem(ADD_PERSONAL_ACCT, JSON.stringify(pAcctDtl));
		} else {
			sessionStorage.setItem(PERSONAL_ACCT, JSON.stringify(pAcctDtl));
		}
    }
	
    public setDAcctDtl(dAcctDtl: IAccountOpeningModel, isAddAcc) {
		if(isAddAcc){
			sessionStorage.setItem(ADD_DISCRETIONARY_ACCT, JSON.stringify(dAcctDtl));
		} else {
			sessionStorage.setItem(DISCRETIONARY_ACCT, JSON.stringify(dAcctDtl));
		}
    }
	
    public setBAcctDtl(bAcctDtl: IAccountOpeningModel, isAddAcc) {
		if(isAddAcc){
			sessionStorage.setItem(ADD_BENE_ACCT, JSON.stringify(bAcctDtl));
		} else {
			sessionStorage.setItem(BENE_ACCT, JSON.stringify(bAcctDtl));
		}
    }
	
    public setSmartAcctDtl(acctDtl: IAccountOpeningModel, isAddAcc) {
		if(isAddAcc){
			sessionStorage.setItem(ADD_SMART_ACCT, JSON.stringify(acctDtl));
		} else {
			sessionStorage.setItem(SMART_ACCT, JSON.stringify(acctDtl));
		}
    }
	
	public removeAcctDtl(acctType, isAddAcc) {
		if(isAddAcc){
			if("P"==acctType){
				sessionStorage.removeItem(ADD_PERSONAL_ACCT);
			} else if("D"==acctType){
				sessionStorage.removeItem(ADD_DISCRETIONARY_ACCT);
			} else if("B"==acctType){
				sessionStorage.removeItem(ADD_BENE_ACCT);
			} else if("WP"==acctType){
				sessionStorage.removeItem(ADD_SMART_ACCT);
			}
		} else {
			if("P"==acctType){
				sessionStorage.removeItem(PERSONAL_ACCT);
			} else if("D"==acctType){
				sessionStorage.removeItem(DISCRETIONARY_ACCT);
			} else if("B"==acctType){
				sessionStorage.removeItem(BENE_ACCT);
			} else if("WP"==acctType){
				sessionStorage.removeItem(SMART_ACCT);
			}
		}
	}
}