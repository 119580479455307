import { 
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter
	} from '@angular/core';
import {genRouterLink} from '@util/route';
import {RouteConstant} from 'src/app/constant/route.constant';

@Component({
	selector: 'product-selector-tools',
	styleUrls: ['./selector.component.scss'],
	templateUrl: './selector.component.html'
})

export class SelectorComponent implements OnInit {
	
	PRODUCTS_ICON_IMAGES_PATH = 'assets/images/general/icons/product/';

	selected: string;
	stockSelector = genRouterLink(RouteConstant.STOCKS,RouteConstant.TOOLS,RouteConstant.STOCKS_SELECTOR);
	etfSelector = genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.ETFS_SELECTOR);
	fundSelector = genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_SELECTOR);
	bondSelector = genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_SELECTOR);
	
	ngOnInit(){
		this.selected = this.product;
	}
	readonly FUNDS: string = 'FUNDS';
  readonly BONDS: string = 'BONDS';
  readonly STOCK: string = 'STOCK';
  readonly ETF: string = 'ETF';
	@Input() product: string;
	@Output() navigate: EventEmitter<any> = new EventEmitter<any>();
	
	changeCategory(cat){
		this.selected = cat;
		switch(cat){
			case 'FUNDS':
				this.navigate.emit(this.fundSelector);
				break;
			case 'BONDS':
				this.navigate.emit(this.bondSelector);
				break;
			case 'ETF':
				this.navigate.emit(this.etfSelector);
				break;
			case 'STOCK':
				this.navigate.emit(this.stockSelector);
				break;
		}
		
  }

}
