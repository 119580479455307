<!--  <div class="left">-->
<!--  </div>-->
<!--  <div class="right my-5">-->
<!--    <div class="d-flex flex-column align-items-center justify-content-center">-->
<!--      <img class="my-3" style="width: 180px" src="https://secure.fundsupermart.com.hk/fsm/images/icon/fsm-one-actual.png">-->
<!--      <p class="text-lg mb-2 font-weight-bold text-black"><span translate="you.have.successfully.logged.out"></span></p>-->
<!--      <p class="text-black-7 font-weight-semibold text-center pb-3">-->
<!--        <span translate="thank.you.for.using.fsmone.platform"></span> <br>-->
<!--      </p>-->
<!--      <button class="login-again-btn mb-3" [routerLink]="loginLink">-->
<!--        <span translate="login.again"></span>-->
<!--      </button>-->
<!--      <a (click)="goToHomepage()"><span translate="back.to.home.page"></span></a>-->
<!--    </div>-->
<!--  </div>-->

<ui-mobile-full-screen [isVisible]="isMobileView">
  <ng-container body>
    <div class="container">
      <div class="py-5">
        <ng-container *ngTemplateOutlet="bodyTpl"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="container py-2">
      <div class="wrapper">
        <ng-container *ngTemplateOutlet="btnBackLogin"></ng-container>
        <div class="mb-2"></div>
        <ng-container *ngTemplateOutlet="btnBackHomepage"></ng-container>
      </div>
    </div>
  </ng-container>
</ui-mobile-full-screen>

<ng-container *ngIf="!isMobileView">
  <div class="min-vh-100">
    <div class="container">
      <div class="empty-space-top"></div>
      <div class="py-5">
        <ng-container *ngTemplateOutlet="bodyTpl"></ng-container>
        <div class="wrapper">
          <nz-row class="gutter-y-2" nzGutter="16" nzType="flex" nzJustify="center">
            <nz-col class="flex-grow-1"><ng-container *ngTemplateOutlet="btnBackHomepage"></ng-container></nz-col>
            <nz-col class="flex-grow-1"><ng-container *ngTemplateOutlet="btnBackLogin"></ng-container></nz-col>
          </nz-row>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #bodyTpl>
  <div class="wrapper">
    <div class="text-center">
      <div class="mb-4">
        <img width="140" src="https://www.fsmone.com.hk/fsm/images/icon/fsm-one-actual.png">
      </div>
      <h4 class="title mb-2" translate="you.have.successfully.logged.out"></h4>
      <p class="mb-0 font-weight-semibold text-default" translate="thank.you.for.investing.with.fsm"></p>
      <p [ngClass]="isMobileView?'mb-4':'mb-3'" class="font-weight-semibold text-default" translate="as.an.added.security.measure.please.clear.your.cache.after.each.session."></p>
    </div>
    <ui-basic-card [nzClass]="'px-4 py-2 mb-3'">
      <uic-horizontal-list-item nzClass="py-2">
        <p class="text-sm mb-0" translate="fsmone.login"></p>
        <p class="text-sm mb-0 text-right">{{ loginTime | hkDate:null: 'd MMM y h:mm:ss a' }}</p>
      </uic-horizontal-list-item>
      <nz-divider class="my-0"></nz-divider>
      <uic-horizontal-list-item nzClass="py-2">
        <p class="text-sm mb-0" translate="fsmone.logout"></p>
        <p class="text-sm mb-0 text-right">{{ currentDate | hkDate:null: 'd MMM y h:mm:ss a' }}</p>
      </uic-horizontal-list-item>
      <nz-divider class="my-0"></nz-divider>
      <uic-horizontal-list-item nzClass="py-2">
        <p class="text-sm mb-0" translate="duration"></p>
        <p class="text-sm mb-0 text-right">{{ (loginTime)?convertMillisecondsToHoursMinutesSeconds(currentDate.getTime()-loginTime.getTime()):'-' }}</p>
      </uic-horizontal-list-item>
    </ui-basic-card>
  </div>
</ng-template>

<ng-template #btnBackHomepage>
  <a nz-button
     href="/"
     [nzBlock]="true"
     nzType="default"
     [nzSize]="isMobileView?'default':'small'"
     class="btn-base btn-outline-primary font-weight-bold" translate="back.to.homepage"></a>
</ng-template>

<ng-template #btnBackLogin>
  <a nz-button
     [href]="loginLink"
     [nzBlock]="true"
     nzType="theme"
     [nzSize]="isMobileView?'default':'small'"
     class="btn-base font-weight-bold" translate="login.again"></a>
</ng-template>
