<div *ngIf="isWrapperVisible" class="support-side-button-wrapper support-side-button-dropdown" [ngClass]="{'mobile-wrapper mb-2': (auth||authCas) && auth2FA && isMobileView}">
    
    <div class="close-icon-container">
     	<i class="pointer fsmicon-close text-sm close-icon" (click)="closeSupportSideButtonWrapper()"></i>
    </div>
    
    <!-- <a nz-dropdown [(nzVisible)]="sideButtonVisible" [nzDropdownMenu]="menu" [nzPlacement]="'topCenter'" [nzTrigger]="'click'" [nzOverlayClassName]="'support-side-button-dropdown'">
        
        <div class="support-side-button">
            <div class="side-button-inner" [ngClass]="{ 'side-button-invisible': !sideButtonVisible }">
                <ng-container *ngIf="sideButtonVisible">
                    <i class="fsmicon-close text-lg"></i>
                </ng-container>
                <ng-container *ngIf="!sideButtonVisible">
                    <i class="fsmicon-support text-lg"></i>
                    <div class="icon-text">{{ 'support' | translate }}</div>
                </ng-container>
            </div>
        </div>
    </a> -->
    <!-- <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="side-button mb-2" (click)="navigate('MOBILE_APP')">
            <div class="side-button-inner" [ngClass]="{'pt-1': !isMobileView}">
                <img class="icon" src="assets/images/general/icons/fsm-app.svg" alt="FSMOne App"/>
                <div class="icon-text">{{ 'fsmone.fsmoneapp' | translate }}</div>
            </div>
        </div>
	</nz-dropdown-menu> -->
	<div class="side-button mb-2" (click)="navigate('DEPOSIT')">
	    <div class="side-button-inner">
	        <i class="fsmicon-fx-o text-info text-lg"></i>
	        <div class="icon-text">{{ 'fsmone.how.to.deposit' | translate }}</div>
	    </div>
	</div>
	<app-live-chat></app-live-chat>
    
</div>
