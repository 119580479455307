<div class="my-4">
  <nz-row>
    <nz-col [nzXs]="16" [nzSm]="16" [nzMd]="6" [nzLg]="6">
      <h3>{{title}}</h3>
    </nz-col>
    <nz-col
      [nzXs]="{span: 6, offset: 2}"
      [nzSm]="{span: 6, offset: 2}"
      [nzMd]="{span: 4, offset: 14}"
      [nzLg]="{span: 4, offset: 14}">
      <div class="float-right">
        <a (click)="redirectToArticleSearch()">{{'fsmone.view.more' | translate}}</a>
      </div>
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="16" nzType="flex">
    <nz-col *ngFor="let article of articleList | slice:0:8" [nzLg]="columnSpan" [nzMd]="columnSpan" [nzSm]="24"
            [nzXs]="24">
		<div class="b2c-articles">
		<div >
		<div nz-row [nzGutter]="isMobile ? 10 : 30" [ngClass]="{'isMobile': isMobile}">
			<div nz-col>
				<ng-container *ngTemplateOutlet="articleImg"></ng-container>
				<ng-container *ngTemplateOutlet="articlesContent"></ng-container>
			</div>
		</div>
			
		<ng-template #articleImg>  
			<div *ngIf="webcast" class="webcast-thumb">    
				<div class="webcast-img">      
					<img *ngIf="article.featuredImage" (click)="redirectToArticleDetails(article?.articleno, article.fromRcms)" class="img-fluid pointer rounded" [src]="article.featuredImage" [alt]="article.title"/>      
					<div class="webcast-overlay text-center rounded">        
						<i nz-icon nzType="play-circle" nzTheme="fill" class="webcast-play-icon"></i>
					</div>    
				</div> 
			</div>  
			<div *ngIf="!webcast" class="webcast-img">
				<img *ngIf="article.featuredImage" (click)="redirectToArticleDetails(article?.articleno, article.fromRcms)" class="img-fluid pointer rounded" [src]="article.featuredImage" [alt]="article.title"/>
			</div>
		</ng-template>
		
		<ng-template #articlesContent> 
			<div class="b2c-article-content">
				<div [ngClass]="{'pt-2' : !isMobile}">
					<div [ngClass]="{'b2c-article-title-cover-default' : !highLight, 'b2c-article-title-cover-highLight' : highLight}">
					<a class="b2c-article-title" (click)="redirectToArticleDetails(article?.articleno, article.fromRcms)">{{ (article.title.length>80)? (article.title | slice:0:80)+'..':(article.title) }}</a>
					</div>
				</div>
				<div class="b2b-article-meta">
					<div>
						<span class="b2c-article-author" *ngIf="article?.authorId">By {{ article.author }}</span>
						
						<span class="text-black-7 font-weight-normal">
							<i nz-icon nzType="calendar" nzTheme="outline"></i>
							<span class="pl-1 b2c-article-date">{{ article.logDate | hkDate:localeOption }}</span>
							<span class="ml-1">
								<i class="mx-1" nz-icon nzType="eye" nzTheme="outline"></i>
								<span>{{article.hits | number : '1.0-0'}} {{article.hits > 1 ? ('views' | translate) : ('views' | translate)}}</span>
							</span>
						</span>
					</div>
				</div>  
			</div>
		</ng-template>
		</div>
		</div>
      <nz-divider *ngIf="(isMobile$ | async)"></nz-divider>
    </nz-col>
  </nz-row>
</div>
