import {HTTP_INTERCEPTORS} from '@angular/common/http'
import {APIInterceptor} from './api.interceptor';
import {AuthInterceptor} from './auth.interceptor';
import {SeoInterceptor} from "@core/interceptor/seo.interceptor";

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SeoInterceptor, multi: true },

];
