class RiskRatingConstant {
	static readonly riskRatingMap = {
		0:"lowest.risk",
		1:"lower.risk",
		2:"low.risk",
		3:"moderately.lower.risk",
		4:"moderately.low.risk",
		5:"moderate.risk",
		6:"moderately.high.risk",
		7:"moderately.higher.risk",
		8:"high.risk",
		9:"higher.risk",
		10:"highest.risk",
		N:"not.sold.by.fsm"
	}
}

export function getRiskMapping(risk: any){
	let rating = Number(risk);
	
	if(isNaN(rating)){
		return null;
	}
	
	let mapping = RiskRatingConstant.riskRatingMap;
	return mapping[rating];
	
}