export class AppConstant {
  	// General
  	public static readonly RESPONSE_SUCCESS: string = 'SUCCESS';
  	public static readonly RESPONSE_FAILED: string = 'FAILED';
  	public static readonly YES_FLAG: string = 'Y';
  	public static readonly YES_DISPLAY: string = 'Yes';
  	public static readonly NO_FLAG: string = 'N';
  	public static readonly NO_DISPLAY: string = 'No';
  	public static readonly DATE_FORMAT: string = 'dd MMM yyyy';
  	public static readonly DATE_MEDIUM_FORMAT: string = 'mediumDate';
  	public static readonly LOCALE_EN: string = 'en';
  	public static readonly PERCENT: string = '%';
  	public static readonly MY: string = 'MY';
  	public static readonly MYFSM: string = "MYFSM";
  	public static readonly LANGUAGE_CODE_EN_US: string = "en_us";
 	public static readonly LIVE_URL: string = 'https://secure.fundsupermart.com.hk/fsm'
  	public static readonly MALAYSIA: string = "Malaysia";
 	public static readonly TEST_URL: string = 'https://fmytest.fsmone.com.my';
  	public static readonly TRIAL: string = 'https://fmytrial.fsmone.com.my';
  	public static readonly FSM_CO_CODE: string = 'FMY00001';
  	public static readonly LIGHTSTREAMER_LOGIN_APPEND: string = 'FMY-';
  	// file MIME type
  	public static readonly FILE_MIME_TYPE = {
    	PDF: 'application/pdf',
  	};
  	public static readonly AI_FLAG_COOKIE: string = 'aiFlagDeclaration';

  	//KLSE stock Leveraged & Inverse Risk Assessment
  	public static readonly LEVERAGED_INVERSE_STOCK_RISK_ASSESSMENT: string = 'leveragedInverseStockRiskAssessment';

  	// Mobile Apps
  	public static readonly PLAYSTORE_URL: string = 'https://play.google.com/store/apps/details?id=com.ifast.fsm&hl=zh_HK';
  	public static readonly IOS_URL: string = 'https://apps.apple.com/app/apple-store/id427129018?pt=497385&ct=fsmonehk-website&mt=8';

  	// Platform
  	public static readonly WEB: string = 'WEB';
  	public static readonly ANDROID: string = 'Android';
  	public static readonly IOS: string = 'iOS';

	public static readonly BASE_CURRENCY: string = 'HKD';

	// Printing
	public static readonly PR_REPLACE = 'print-replace';
	public static readonly PR_ADD = 'print-add';
	public static readonly PR_REMOVE = 'print-remove';
	public static readonly PR_REMOVE_ALL = 'print-remove-all-classes';
	public static readonly PR_CHART = 'print-chart';

	public static readonly MODULE_AS = "AUTO_SWEEP_FSM";
	public static readonly MODULE_RMO = "BOND_RMO";

	public static readonly MODULE_CHK_LEGACY = ['FSMONE_POSTLOGIN'];
}
