<div id="container">
	<app-hide-from-mobile-app>
		<app-header-fsmone (updatePostloginCollapse)="isPostloginMenuCollapsed = $event"></app-header-fsmone>
	</app-hide-from-mobile-app>
	<div [ngClass]="{'post-login-content-wrapper': fsmPostLoginWhitelist && (auth||authCas) && auth2FA && !isMobileView, 'menu-collasped': isPostloginMenuCollapsed }">
		<router-outlet></router-outlet>
		<app-hide-from-mobile-app>
			<app-footer-fsmone></app-footer-fsmone>
		</app-hide-from-mobile-app>
	</div>
	<!-- <ng-container *ngIf="fsmPostLoginWhitelist"> -->
		<app-hide-from-mobile-app>
			<app-support-side-button></app-support-side-button>
		</app-hide-from-mobile-app>
	<!-- </ng-container> -->
	<!-- <ng-container *ngIf="!fsmPostLoginWhitelist">
		<app-web-scaffold>
			<app-hide-from-mobile-app>
				<app-fsm-side-button></app-fsm-side-button>
				<app-deposit-side-button></app-deposit-side-button>
				<app-watchlist-side-button></app-watchlist-side-button>
				<app-enquiry-side-button></app-enquiry-side-button>
				<app-appointment-side-button></app-appointment-side-button>
				<app-live-chat></app-live-chat>
			</app-hide-from-mobile-app>
		</app-web-scaffold>
		<app-mobile-scaffold>
			<app-hide-from-mobile-app>
				<app-mobile-side-button-container></app-mobile-side-button-container>
				<app-live-chat></app-live-chat>
			</app-hide-from-mobile-app>
		</app-mobile-scaffold>
	</ng-container> -->
</div>