import {Component, Input, OnInit} from "@angular/core";
import { UtilService } from "@api-module/service/common/util.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bond-feature-description',
  templateUrl: './bond-feature-description.component.html',
  providers: [UtilService]

})

export class BondFeatureDescriptionComponent implements OnInit {
	@Input() bondFeatureList: any ;
	@Input() additionalFeature?: any;
	@Input() callableList?: any;
	@Input() makeWholeCallList?: any;
	
	callableDescription : string = "";
	perpetualDescription : string = "";
	puttableDescription : string = "";
	wholeCallDescription : string = "";
	lossAbsorptionDescription : string = "";
	certificateDepositDescription : string = "";
	
	constructor(
    			private translate:TranslateService
	) {}
	
	ngOnInit(): void {
		this.callableDescription = this.translate.instant('fsmone.bonds.special.feature.callable.desc');
		this.perpetualDescription = this.translate.instant('fsmone.bonds.special.feature.perpetual.desc');
		this.puttableDescription = this.translate.instant('fsmone.bonds.special.feature.puttable.desc');
		this.wholeCallDescription = this.translate.instant('fsmone.bonds.special.feature.make.whole.call.desc');
		this.lossAbsorptionDescription = this.translate.instant('fsmone.bonds.special.feature.loss.absorption.desc');
		this.certificateDepositDescription = this.translate.instant('fsmone.bonds.special.feature.certificate.deposit.desc');
	}
}