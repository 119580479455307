import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SubHandlingService } from '@api-module/service/common/subscription-handling.service';
import { interval, Observable } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { GlobalState } from 'src/app/core/store/state/global.state';
import { AuthState } from '@auth-module/store';

declare const lpTag: any;

@Component({
  selector: 'app-live-chat',
  styleUrls: ['./live-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="side-button mb-2" (click)="openLiveChat()">
      <div class="side-button-inner">
        <i class="fsmicon-livechart-o text-info text-lg"></i>
        <div class="icon-text">{{ 'fsmone.live.chat' | translate }}</div>
      </div>
    </div>
    <div *ngIf="showModal==true">
      <live-chat-modal [visibility]="modalVisible" (closeModel)="closeAccountModel($event)" (visibilityEvent)="modalVisible = $event"></live-chat-modal>
    </div>
  `,
  providers: [SubHandlingService]
})

export class LiveChatComponent implements OnInit {
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  showIcon: boolean = false;
  showModal: boolean = false;
  modalVisible = false;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.showIcon = true;
  }

  openLiveChat() {
    this.showModal = true;
    this.modalVisible = true;
  }

  closeAccountModel(value: boolean) {
    this.showModal = false;
  }
}
