import {Injectable} from "@angular/core";
import {ApiConstant} from "@api-module/api.endpoint.constant";
import {HttpClient} from "@angular/common/http";
import {HttpParamsService} from "@api-module/service/http-params.service";
import {IResponse} from "@api-module/model/common/i-response";
import { UploadFile } from "ng-zorro-antd";

@Injectable({
    providedIn: 'root'
})
export class StockTransactionRestService {
    readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.STOCKS + ApiConstant.TRANSACTION;

    constructor(private http: HttpClient, private paramService: HttpParamsService) {
    }

    getTickerInfoByExchangeAndCode(exchange?: string, code?: string) {
        const params = this.paramService.buildHttpParams({ exchange, code });
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_TICKET_INFO_BY_EXCHANGE_AND_CODE, {}, {params});
    }

    clientCashBalance(refno :string,currency?: string, exchange? : string, paymentMode?:string, paymentModeCurrency?:string) {
        const params = this.paramService.buildHttpParams({refno,currency,exchange, paymentMode, paymentModeCurrency});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CLIENT_CASH_BALANCE, {}, {params});
    }

    clientCashBalanceGroupByMarket(refno :string, market : string) {
        const params = this.paramService.buildHttpParams({refno,market});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CLIENT_CASH_BALANCE_GROUP_BY_MARKET, {}, {params});
    }

    viewStockHoldings(exchange? : string,refno?: string) {
        const params = this.paramService.buildHttpParams({exchange,refno});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.VIEW_STOCK_HOLDINGS, {}, {params});
    }

    viewClientPortfolioUS(refno?: string) {
        const params = this.paramService.buildHttpParams({refno});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CLIENT_PORTFOLIO_US, {}, {params});
    }

    verifyUsLivePriceSubscription() {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.VERIFY_US_LIVE_PRICE_SUBSCRIPTION, {}, {params});
    }

    transferInStock(files: UploadFile[], transferInList, selectedRefno, market, counterpartyId, counterpartyContact,
            counterpartyContactMethod,counterpartyAccNumber) {
        const formData = new FormData();
        files.forEach((file: any) => {
            formData.append('files', file);
        });
        const params = this.paramService.buildHttpParams({selectedRefno, market, counterpartyId, counterpartyContact,
            counterpartyContactMethod,counterpartyAccNumber,transferInList});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.TRANSFER_IN_STOCK, formData, {params});
    }

    checkErrorMessage(platform,market) {
        const params = this.paramService.buildHttpParams({platform,market});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CHECK_ERROR_MESSAGE, {}, {params});
    }

    getStockAvailableOrderType(selectedOperation: string,transactionType: string,exchange: string) {
        const params = this.paramService.buildHttpParams({selectedOperation,transactionType,exchange});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.STOCK_AVAILABLE_ORDER_TYPE, {}, {params});
    }
    
    usPreTradeChecking(dto) {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.US_PRE_TRADE_CHECKING, dto, {params});
    }

    preTradeChecking(dto) {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.PRE_TRADE_CHECKING, dto, {params});
    }

    confirmPlaceOrder(dto) {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CONFIRM_PLACE_ORDER, dto, {params});
    }
    
    getStockAvailableSellQuantity(refno: string, stockCode: string,exchange: string) {
        const params = this.paramService.buildHttpParams({refno,stockCode,exchange});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.STOCK_AVAILABLE_SELL_QUANTITY, {}, {params});
    }

    getOrderBook(numOfDays, exchange?) {
        const params = this.paramService.buildHttpParams({numOfDays,exchange});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.SHOW_ORDER_BOOK_LIST, {}, {params});
    }

    getOrderDetail(orderId: string ,accountNo: string,isHistoryRecord?: boolean) {
        const params = this.paramService.buildHttpParams({orderId,accountNo,isHistoryRecord});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.ORDER_DETAIL, {}, {params});
    }

    subscribeUsLivePrice(dto) {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.SUBSCRIBE_US_LIVE_PRICE, dto, {params});
    }

    usLivePriceTncAcceptLater() {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.US_LIVE_PRICE_TNC_ACCEPT_LATER, {}, {params});
    }
    
    checkW8BenAddressValidity(paramRefnoAry:string[]) {
		const params = this.paramService.buildHttpParams({paramRefnoAry});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CHECK_W8_BEN_ADDRESS_VALIDITY, {}, {params});
	}
	
    getOrderTotalConsideration(dto) {
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ORDER_TOTAL_CONSIDERATION, dto, {params});
    }
}