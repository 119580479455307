<nz-spin [nzSpinning]="isLoading">
  <app-web-scaffold>
    <form [formGroup]="chartForm" nz-form>
      <div class="container">
      <nz-card>
      	<div nz-row nzAlign="middle" nzJustify="start" nzType="flex" nzGutter="16">
      		<div nz-col nzLg="6" nzMd="20" nzSm="20">
      			<h5>{{'criteria' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="mainFilter"
					      [nzMaxMultipleCount] = "3"
					      nzMode="multiple"
					      [nzPlaceHolder]="'fsmone.please.select' | translate"
					      [(ngModel)]="selectedMainFilterList"
					    >
					    	<nz-option *ngFor="let item of mainFilterList" [nzLabel]="item.value | translate" [nzValue]="item.key"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
			</div>
			
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showIssuer && bondSelectorModel?.bondInfoIssuerModel">
      			<h5>{{'fsmone.bonds.bond.issuer' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="issuer"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedIssuer"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondInfoIssuerModel | keyvalue" [nzLabel]="item.value" [nzValue]="item.key"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showSector && bondSelectorModel?.bondSectorModel">
      			<h5>{{'fsmone.bonds.bond.sector' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="sector"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedSector"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondSectorModel" [nzLabel]="translateBondItem(item, 'fsmone.bonds.sector.')" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showSubSector && bondSelectorModel?.bondSectorSubModel">
      			<h5>{{'fsmone.bond.chart.centre.bond.sector.sub' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="subSector"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedSubSector"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondSectorSubModel" [nzLabel]="translateBondItem(item, 'fsmone.bonds.subsector.')" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showType && bondSelectorModel?.bondTypeModel">
      			<h5>{{'fsmone.bonds.type' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="type"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedType"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondTypeModel" [nzLabel]="translateBondItem(item, 'fsmone.bonds.type.')" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showCurrency && bondSelectorModel?.bondCurrencyModel">
      			<h5>{{'fsmone.bonds.bond.currency' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="currency"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedCurrency"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondCurrencyModel" [nzLabel]="translateBondItem(item, 'fsmone.bonds.direct.bond.all.currency.')" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showFrequency && bondSelectorModel?.couponFrequencyModel">
      			<h5>{{'fsmone.bonds.coupon.frequency' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="frequency"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedFrequency"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.couponFrequencyModel" [nzLabel]="translateBondItem(item, 'fsmone.bonds.coupon.frequency.')" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showExchange && bondSelectorModel?.bondExchangeListedModel">
      			<h5>{{'fsmone.bonds.exchange.listed' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="exchange"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedExchange"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondExchangeListedModel" [nzLabel]="translateBondItem(item, 'fsmone.bonds.exchange.listed.')" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showCredit && bondSelectorModel?.bondCreditRatingModel">
      			<h5>{{'fsmone.bonds.credit.rating' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="credit"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedCredit"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondCreditRatingModel | keyvalue" [nzLabel]="translateBondItem(item, 'fsmone.bond.credit.rating.')" [nzValue]="item.key"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      		
      		<div nz-col nzLg="6" nzMd="20" nzSm="20" *ngIf="showGeo">
      			<h5>{{'fsmone.bond.chart.centre.geographical.sectors' | translate}}</h5>
      			<nz-form-item class="w-100 d-inline-block align-middle mb-0">
              		<nz-form-control>
						<nz-select [formControl]="geo"
					      nzPlaceHolder="Please select" nzLabelPlaceholder
					      [(ngModel)]="selectedGeo"
					    >
					    	<nz-option *ngFor="let item of bondSelectorModel.bondExchangeListedModel" [nzLabel]="item.listedName" [nzValue]="item.code"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
      		</div>
      	</div>
      	
      	 <nz-row>
              <nz-col [nzSpan]="24" class="py-3">
                <button nz-button nzType="theme" (click)="filterBondList()">
                  {{'search' | translate}}
                </button>
              </nz-col>
            </nz-row>
      	
        <div nz-row nzAlign="middle" nzJustify="start" nzType="flex" nzGutter="16" class="py-3">
          <div   nz-col nzLg="21" nzMd="20" nzSm="20" *ngIf="!(showExtraOptions); else spanShort">
          	<nz-spin nz-col *ngIf="isLoading"></nz-spin>
            <nz-form-item class="w-100 d-inline-block align-middle mb-0" *ngIf="!isLoading">
              <nz-form-control>
                <nz-select [formControl]="bond" [nzDisabled]="isLoading" class="w-100 text-base" nzAllowClear
                  nzLabelPlaceholder nzPlaceHolder="{{'fsmone.bond.chart.centre.placeholder.1'|translate}}"
                  nzShowSearch>
                  <nz-option *ngFor="let item of bondOptions" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="3" nzMd="4" nzSm="4" class="text-center">
            <span class="font-weight-bold text-primary"><a (click)="onClickClear()">{{'fsmone.clear' | translate}}</a></span>
          </div>
        </div>
      </nz-card>
    </div>
    </form>
    <ng-template #spanShort>
      <div  nz-col nzLg="9" nzMd="8" nzSm="20">
        <nz-form-item class="w-100 d-inline-block align-middle mb-0">
          <nz-form-control>
            <nz-select [formControl]="bond" [nzDisabled]="isLoading" class="w-100 text-base" nzAllowClear
              nzPlaceHolder="Please search or select {{totalBondSelected.length}}  - 7 bonds" nzShowSearch>
              <nz-option *ngFor="let item of bondOptions" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-template>
  </app-web-scaffold>

  <app-mobile-scaffold>
    <nz-affix (nzChange)="onScroll($event)" [nzOffsetTop]="headerHeight">
      <div [ngClass]="{'w-100':mobileScrolled,'container mt-3 ':!mobileScrolled}">
        <!-- <form [formGroup]="chartForm" nz-form> -->
          <div id="selector" class="mobile-selector  border bg-white  w-100"
            [ngClass]="{'rounded':!mobileScrolled,'shadow': !mobileScrolled}">

            <nz-row nzGutter="12" class="p-2" nzType="flex" nzAlign="middle">
<!--               <nz-col [nzSpan]="showExtraOptions ? 12 : 8" class="mb-2"> -->
<!--                 <div (click)="!isLoading && openMainDrawer()" class="border p-2 rounded  align-middle text-black"> -->
<!--                   <ng-container *ngFor="let item of mainOptions"> -->
<!--                     <p class="m-0" *ngIf="item.value === category.value"> -->
<!--                       {{item.label.split(' ').slice(0, 2).join(' ')}} -->
<!--                       <i class="float-right" nz-icon nzType="down" nzTheme="outline"></i> -->
<!--                     </p> -->
<!--                   </ng-container> -->
<!--                 </div> -->
<!--               </nz-col> -->
<!--               <nz-col *ngIf="showExtraOptions"  nzSpan="12" class="mb-2"> -->
<!--                 <div (click)="!isLoading && openSecondDrawer()" class="border p-2 rounded  text-black"> -->
<!--                   <ng-container *ngFor="let item of secondOptions"> -->
<!--                     <p class="m-0" *ngIf="item.value === secondCategory.value"> -->
<!--                       {{item.label.split(' ').slice(0, 2).join(' ')}} -->
<!--                       <i class="float-right" nz-icon nzType="down" nzTheme="outline"></i> -->
<!--                     </p> -->
<!--                   </ng-container> -->
<!--                   <p *ngIf="secondCategory.value==null" class="m-0"> -->
<!--                     {{labelList}} -->
<!--                     <i class="float-right" nz-icon nzTheme="outline" nzType="down"></i> -->
<!--                   </p> -->
<!--                 </div> -->
<!--               </nz-col> -->
              <nz-col nzSpan="12"  class="mb-2">
                <div (click)="!isLoading && openBondDrawer()" class="border p-2 rounded  text-black">
                  <ng-container>
                    <p class="m-0">
                      Select {{totalBondSelected.length}} -7 Bonds<i class="float-right" nz-icon nzTheme="outline" nzType="down"></i>
                    </p>
                  </ng-container>
                </div>
              </nz-col>
              <nz-col nzSpan="3" class="mb-2">
                <span class="font-weight-bold text-md text-primary text-center">
                  <a (click)="onClickClear()">Clear</a>
                </span>
              </nz-col>
            </nz-row>
          </div>
        <!-- </form> -->
      </div>
    </nz-affix>
    <!--  
    <b2c-menu-drawer (filter)="onMainClassChange($event)" (viewChange)="onViewChange($event)" *ngIf="showMainDrawer"
      [itemList]="mainList" [radioValue]="mainValue" [show]="showMainDrawer" [title]="'Sort By'" [type]="'radio'">
    </b2c-menu-drawer>-->
<!--     <b2c-menu-drawer (filter)="onSecondClassChange($event)" (viewChange)="onViewChange($event)" *ngIf="showSecondDrawer" -->
<!--       [itemList]="secondList" [radioValue]="secondValue" [show]="showSecondDrawer" [title]="'Select Type'" -->
<!--       [type]="'radio'"></b2c-menu-drawer> -->
    <b2c-menu-drawer (filter)="onClassChange($event)" (viewChange)="onViewChange($event)" *ngIf="showFundDrawer"
      [itemList]="bondList" [radioValue]="bondValue" [show]="showFundDrawer" [title]="'Select 2-7 Bonds'"
      [type]="'checkbox'"></b2c-menu-drawer>
  </app-mobile-scaffold>
</nz-spin>
