import { Pipe, PipeTransform } from '@angular/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'millionDivider' })
export class MillionDividerPipe implements PipeTransform {
	locale: string = 'ch';
	
	constructor(private globalDataStorage: GlobalDataStorage, private decimalPipe : DecimalPipe) {
		this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
	}

	transform(value: any,withWord:boolean,includeBillion?:boolean) {
		
    	if (!value) {
      		return '-';
    	}
		
		if(this.locale==null || this.locale=='en'){
            if(includeBillion){
                if(value > 1000000000 || value < -1000000000){
                  if(withWord){
                    return this.decimalPipe.transform((value / 1000000000), '1.0-2') + ' billion';
                  } else {
                    return this.decimalPipe.transform((value / 1000000000), '1.0-2');
                  }
                }
            }
            if(withWord){
            	return this.decimalPipe.transform((value / 1000000), '1.0-2') + ' million';
            } else {
            	return this.decimalPipe.transform((value / 1000000), '1.0-2');
            }
    	}
    	else if(this.locale=='zh'){
            if(includeBillion){
                if(value > 100000000 || value < -100000000){
                  if(withWord){
                    return this.decimalPipe.transform((value / 100000000), '1.0-2') + '亿';
                  } else {
                    return this.decimalPipe.transform((value / 100000000), '1.0-2');
                  }
                }
            }
            if(withWord){
            	return this.decimalPipe.transform((value / 10000), '1.0-2') + ' 万';
            } else {
            	return this.decimalPipe.transform((value / 10000), '1.0-2');
            }
    	}
    	else if(this.locale=='ch'){
            if(includeBillion){
                if(value > 100000000 || value < -100000000){
                  if(withWord){
                    return this.decimalPipe.transform((value / 100000000), '1.0-2') + '億';
                  } else {
                    return this.decimalPipe.transform((value / 100000000), '1.0-2');
                  }
                }
            }
            if(withWord){
            	return this.decimalPipe.transform((value / 10000), '1.0-2') + '萬';
            } else {
            	return this.decimalPipe.transform((value / 10000), '1.0-2');
            }
    	}
  	}
}