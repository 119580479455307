import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import Lightstreamer from 'lightstreamer-client';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { TranslateService } from '@ngx-translate/core';
import { PopMessageService } from '@core/service/pop-message.service';
import { HttpParamsService } from '@api-module/service/http-params.service';
import { IResponse } from '@api-module/model/common/i-response';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { NzModalService } from 'ng-zorro-antd';
import { AuthState } from '@auth-module/store';
import { Store } from '@ngxs/store';
import { AuthService } from '@auth-module/service/auth.service';
import { Router } from '@angular/router';

/*declare var Lightstreamer : any;
declare var LightstreamerClient : any;
declare var Subscription : any;*/

@Injectable({
    providedIn: 'root'
})
export class LightStreamerService{

    readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.STOCKS + ApiConstant.TRANSACTION;

    constructor( 
        private store: Store,
        private http: HttpClient, 
        private authService: AuthService,
        private popMessageService: PopMessageService,
        private translateService: TranslateService,
        private globalDataStorage: GlobalDataStorage,
        private modalService: NzModalService,
        private router: Router
    ) {}

    client = null;
    lsStatus = {
        loginSuccess : null,
        loginError : null,
        connectionError : null,
        duplicateLoginError : null,
        subscriptionError : null
    };

    /**
     * 0 - pre-login
     * 1 - login in process or already failed
     * 2 - login success
     */
    loginStatus = 0;

    loginInProgress = false; //for checking if running login to prevent duplicate

    defaultCallback = {
        loginSuccessCallback: () => {
            this.lsStatus.loginSuccess = true;
            this.loginInProgress = false;
        },
        loginErrorCallback: () => {
            this.lsStatus.loginError = true;
            this.loginInProgress = false;
        },
        connectionErrorCallback: (connectionError) => {
            this.lsStatus.connectionError = connectionError;
            if(connectionError) {
              this.loginInProgress = false;
            }
        },
        duplicateLoginCallback: () => {
          this.lsStatus.duplicateLoginError = true;
          this.loginInProgress = false;
          this.loginStatus = 0;
          for (const key in this.loginCallbacks) {
              if (key.includes('consol-watchlist')) {
                  return;
              }
          }

          this.modalService.error({
            nzContent: this.translateService.instant('stock.duplicate.login.found.errormsg'),
            nzOkText: this.translateService.instant('ok'),
            nzClosable: false,
            nzOkType: 'theme',
            nzOnOk: () => { this.authService.logout() },
          })
        },
        subscriptionErrorCallback: () => {
            this.lsStatus.subscriptionError = true;
        },
        snapProcessingCallback: (data) => {},
        snapErrorCallback: () => {},
        digestCallback: () => {},
    };

    loginCallbacks = {'default': this.defaultCallback};

    subscriptionList = {
        'priceQuote': {},
        'priceQuoteWithModule': {},
        'feedList': {},
        'timeAndSale': {},
        'marketDepth': {},
        'ranking' : {},
        'factsheetData': new Map<string, Subscription>(),
        'minuteBar': {}
    };

    subscriptionData = {
        'priceQuote': null,
        'priceQuoteWithModule': {},
        'feedList' : null,
        'timeAndSale' : null,
        'marketDepth' : null,
        'factsheetData' : null,
        'minuteBar': {}
    };

    permissionInfo = {
        'SGX': {
          //  'snapL1': 0,
           // 'snapL2': 0,
            'streamL1': true,
            'streamL2': false,
        },
        'HKEX': {
            'snapL1': 0,
            'snapL2': 0,
            'streamL1': false,
            'streamL2': false,
        },
        'AMEX': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'NYSE': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'NASDAQ': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'BATS': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'USOTC': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'LSE': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'SSE': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
        'SZSE': {
          'streamL1' : true,
          'streamL1Type': 'DELAY',
        },
    };

    // ==================================================================================
    // Helper functions (they are in private scope)
    // ==================================================================================
    getItemName(adapterType, level, id) {
        return adapterType + '-' + level + '-' + id;
    }

    /**
    * config: {
    *      digestCallback: callback for invoking $scope.$digest()
    *      updateCallback: callback for updating the data
    * }
    */
    mergeUpdateListener(data, config) {
      var mergeUpdateListener = {
        onItemUpdate: function (info) {
          var itemName = info.getItemName();
          if (!data[itemName]) {
            data[itemName] = {};
          }
          info.forEachChangedField(function(fieldName, fieldPos, val) {
            data[itemName][fieldName] = val;
          });

          config.updateCallback(data);
          config.digestCallback();
        },
        onSubscriptionError: config.errorCallback,
      };
      return mergeUpdateListener;
    }
    // mergeUpdateListener(data, config) {
    // var mergeUpdateListener = {
    //     onItemUpdate: function (info) {
    //     var itemName = info.getItemName();
    //     if (!data[itemName]) {
    //         data[itemName] = {};
    //     }
    //     info.forEachChangedField(function(fieldName, fieldPos, val) {
    //         data[itemName][fieldName] = val;
    //     });

    //     config.updateCallback(data);
    //     config.digestCallback();
    //     },
    //     onSubscriptionError: config.errorCallback,
    // };
    // return mergeUpdateListener;
    // }
     /**
     * config: {
     *       digestCallback:    callback for invoking $scope.$digest()
     *       addCallback:       callback when new entry is added to data
     *       updateCallback:    callback when existing entry is updated
     *       deleteCallback:    callback when existing entry is deleted
     * }
     */
     commandUpdateListener(data, config) {
        var commandUpdateListener = {
            onItemUpdate: (info) => {
              // console.log('commandUpdateListener=', info, data);
                var key = parseInt(info.getValue('key'));
                var command = info.getValue('command');
                    if (command == 'DELETE') {
                        if (config.deleteCallback) {
                            config.deleteCallback(data[key]);
                        }
                        delete data[key];
                } else {
                    var oldData = {};
                    if (command == 'ADD') {
                        data[key] = {};
                    } else {
                        oldData = data[key];
                        data[key] = {};
                    }
                    info.forEachField((fieldName, fieldPos, val) => {
                        if (fieldName == 'key' || fieldName == 'command') {
                            return true;
                        }
                        if (!val) {
                            return true;
                        }
                        data[key][fieldName] = val;
                    });
                    if (command == 'ADD') {
                        if (config.addCallback) {
                            config.addCallback(data[key]);
                        }
                    } else {
                        if (config.updateCallback) {
                            config.updateCallback(oldData, data[key]);
                        }
                    }
                }
                config.digestCallback;
            },
          onSubscriptionError: (code, message) => {
                _.forEach(this.loginCallbacks, (loginCallback) => {
                    loginCallback.subscriptionErrorCallback();
                    loginCallback.digestCallback();
                });
            },
        };
        return commandUpdateListener;
    }

    commandUpdateListenerForMinuteBar(data, config: LightStreamerCommandConfig) {
      return {
          onItemUpdate(info): void {
              let key = parseInt(info.getValue('key'));
              let command = info.getValue('command');
              if (command == 'ADD') {
                  data[key] = {};
                  info.forEachField((fieldName: string, fieldPos: number, value: string): void => {
                      if (fieldName == 'command') {
                          return;
                      }
                      if (!value) {
                          return;
                      }
                      data[key][fieldName] = value;

                      if (fieldName == 'symbol') {
                          data[key]['stockCode'] = this.getStockCode(data[key][fieldName]);
                      }
                  });

                  if (config.addCallback) {
                      config.addCallback(data[key]);
                  }
              } else if (command == 'DELETE') {
                  if (config.deleteCallback) {
                      config.deleteCallback(data[key]);
                  }
                  data.splice(key, 1);
              } else {
                  if (config.checkChangesCallback) {
                      let hasChanges = config.checkChangesCallback(data[key], info);
                      if (!hasChanges) {
                          return;
                      }
                  }
                  let oldData = data[key];
                  data[key] = {};
                  info.forEachField((fieldName: string, fieldPos: number, value: string): void => {
                      if (fieldName == 'command') {
                          return;
                      }
                      if (!value) {
                          return;
                      }
                      data[key][fieldName] = value;
                  });
                  if (config.updateCallback) {
                      config.updateCallback(oldData, data[key]);
                  }
              }
          },
          onSubscriptionError(code: number, message: string): void {
              this.loginCallbacks.forEach((loginCallback) => {
                  try {
                      loginCallback.subscriptionErrorCallback();
                  } catch (err) {
                      console.error(err);
                  }
              });
          }
      };
    }

    // ==================================================================================
    // Client
    // ==================================================================================
    /**
     * module
     * callbacks:
     *      loginSuccessCallback
     *      duplicateLoginCallback
     *      loginErrorCallback
     *      connectionErrorCallback(connectionError)
     *      subscriptionErrorCallback
     *      snapProcessingCallback(data)
     *      digestCallback
     */

    login(module, callbacks) {
        callbacks = callbacks || {};
        //var callbackTypes = ['loginSuccessCallback', 'duplicateLoginCallback', 'loginErrorCallback', 'connectionErrorCallback', 'subscriptionErrorCallback', 'snapProcessingCallback', 'snapErrorCallback', 'digestCallback'];
        var callbackTypes = ['loginSuccessCallback', 'duplicateLoginCallback', 'loginErrorCallback', 'connectionErrorCallback', 'subscriptionErrorCallback', 'snapProcessingCallback', 'digestCallback'];
        for (var i in callbackTypes) {    // add stub callback in case npe
          callbacks[callbackTypes[i]] = callbacks[callbackTypes[i]] || (() => {});
        }
        this.loginCallbacks[module] = callbacks;
        console.log('login', this.loginStatus);
        console.log('loginInProgress', this.loginInProgress);
        if (this.loginStatus == 0) {
            this.loginStatus = 1;
            this.loginInProgress = true;
            let tsToken = '';
            this.getLsToken().subscribe(
                result => {
                    if(result && result.status === 'SUCCESS' ) {
                        tsToken = result.data;
                    }else{
                        //TODO error
                    }
                },
                error => {
                    //TODO error
                }
            ).add(() => {

              // if navigate to other page before api returns, dont connect
              // if(this.router.url?.indexOf('trade/stocks') < 0 && this.router.url?.indexOf('/watchlist') < 0 && this.router.url !== '/') {
              //   return;
              // }

              this.client = new Lightstreamer.LightstreamerClient(environment.LS_URL, 'MARKET_DATA');
              var accountId = this.store.selectSnapshot(AuthState.getId); 
              var token = tsToken;
              this.client.connectionDetails.setUser('FHK-' + accountId);
              this.client.connectionDetails.setPassword(token);
              this.client.addListener({
                  onServerError: (errorCode, errorMessage) => {
                    if (errorCode == -1) {
                      _.forEach(this.loginCallbacks, (loginCallback) => {
                        loginCallback.duplicateLoginCallback();
                        loginCallback.digestCallback();
                      });
                    } else {
                      _.forEach(this.loginCallbacks, (loginCallback) => {
                        loginCallback.loginErrorCallback();
                        loginCallback.digestCallback();
                      });
                    }
                  },
                  onStatusChange: (status) => {                   
                    if (status.indexOf('CONNECTED:STREAM-SENSING') >= 0 && this.loginStatus == 1) {
                      this.subscribeSnap(0, true);
                    }
                    _.forEach(this.loginCallbacks, (loginCallback) => {
                      var disconnected = status.indexOf('DISCONNECTED') >= 0;
                      loginCallback.connectionErrorCallback(disconnected);
                      loginCallback.digestCallback();
                    });
                  },
                });
                this.client.connect();
            });
          } else if (this.loginStatus == 2) {
              callbacks.loginSuccessCallback();
          }
    }

    reset() {
        if (this.client) {
          this.client.disconnect();
          this.client.connectionDetails.setUser('');
          this.client.connectionDetails.setPassword('');
        }
        if(!this.loginInProgress) {
          this.loginStatus = 0;
        }
        this.loginCallbacks = {'default': this.defaultCallback};
        for (var key in this.lsStatus) {
          delete this.lsStatus[key];
        }
    }
  
    getPermissionInfo() {
      return this.permissionInfo;
    }

    getSubscriptionList() {
      return this.subscriptionList;
    }

    // ==================================================================================
    // Feed
    // ==================================================================================
    unsubscribeFeedList() {
        if (this.subscriptionList.feedList) {
          this.client.unsubscribe(this.subscriptionList.feedList);
          delete this.subscriptionList.feedList;
        }
        if (this.subscriptionData.feedList) {
          this.subscriptionData.feedList.length = 0;
        }
    }

    /**
     * feedListLevel
     * feedListName
     * data: an array to store the result
     * config: {
     *       digestCallback:    callback for invoking $scope.$digest()
     *       addCallback:       callback when new entry is added to data
     *       updateCallback:    callback when existing entry is updated
     *       deleteCallback:    callback when existing entry is deleted
     * }
     */

    subscribeFeedList(feedListLevel, feedListName, data, config) {
        this.unsubscribeFeedList();
  
        var itemName = this.getItemName('FEED_LIST', feedListLevel, feedListName);
        var fields = ['key', 'command', 'level', 'data', 'marketCode', 'up', 'down', 'unchanged', 'symbol', 'stockName', 'type', 'currency', 'amount', 'exDate', 'numDays', 'payDate', 'distFlag', 'change', 'changePercentage', 'direction'];
        var subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
        subscription.setDataAdapter('FEED_LIST');
        subscription.addListener(this.commandUpdateListener(data, config));
        this.subscriptionList.feedList = subscription;
        this.subscriptionData.feedList = data;
        this.client.subscribe(subscription);
    }

    // ==================================================================================
    // Price Quote
    // ==================================================================================
    unsubscribeAllPriceQuote(module) {
        if (this.subscriptionList.priceQuote[module]) {
          _.forEach(this.subscriptionList.priceQuote[module], (value, key) => {
            this.client.unsubscribe(value);
          });
          delete this.subscriptionList.priceQuote[module];
        }
    }

    unsubscribePriceQuote(module, priceQuotelevel, priceQuoteSymbol) {
        var itemName = this.getItemName('PRICE_QUOTE', priceQuotelevel, priceQuoteSymbol);
        if (this.subscriptionList.priceQuote[module] && this.subscriptionList.priceQuote[module][itemName]) {
          this.client.unsubscribe(this.subscriptionList.priceQuote[module][itemName]);
          delete this.subscriptionList.priceQuote[module][itemName];
        }
    }

    unsubscribePriceQuoteWithModule(module, priceQuoteSymbol) {
      if (this.subscriptionList.priceQuoteWithModule[module] && this.subscriptionList.priceQuoteWithModule[module][priceQuoteSymbol]) {
        this.client.unsubscribe(this.subscriptionList.priceQuoteWithModule[module][priceQuoteSymbol].subscription);
        delete this.subscriptionList.priceQuoteWithModule[module][priceQuoteSymbol];
      }

      if (this.subscriptionData.priceQuoteWithModule) {
        delete this.subscriptionData.priceQuoteWithModule;
      }
    }

    /**
     * priceQuotelevel
     * priceQuoteSymbol
     * data: an array to store the result
     * config: {
     *      digestCallback: callback for invoking $scope.$digest()
     *      updateCallback: callback for updating the data
     * }
     */
    // subscribePriceQuote(module, priceQuotelevel, priceQuoteSymbol, config) {
    //   if (!this.subscriptionList.priceQuote[module]) {
    //     this.subscriptionList.priceQuote[module] = {};
    //   }
    //   var itemName = this.getItemName('PRICE_QUOTE', priceQuotelevel, priceQuoteSymbol);
    //   if (!this.subscriptionList.priceQuote[itemName]) {
    //     var fields = ['symbol', 'exchange', 'exchangeCode', 'stockCode', 'level', 'lastPrice', 'lastVolume', 'cumulativeVolume', 'bidPrice', 'bidQuantity', 'askPrice', 'askQuantity',
    //       'openPrice', 'closePrice', 'dayHigh', 'dayLow', 'change', 'changePercentage', 'tradingStatus', 'suspension', 'updateTime'];
    //     var subscription = new Lightstreamer.Subscription('MERGE', itemName, fields);
    //     subscription.setDataAdapter('PRICE_QUOTE');
    //     subscription.addListener(this.mergeUpdateListener(config));
    //     this.subscriptionList.priceQuote[itemName] = subscription;
    //     this.client.subscribe(subscription);
    //     console.log(this.subscriptionList.priceQuote[itemName]);
    //   }
    // }

    subscribePriceQuote(priceQuotelevel, priceQuoteSymbol, data, config) {
      var itemName = this.getItemName('PRICE_QUOTE', priceQuotelevel, priceQuoteSymbol);
      var fields = ['symbol', 'exchange', 'stockCode', 'level', 'stockName', 'lotSize', 'currency', 'tickerNo', 'productType', 'securityType', 'lastPrice', 'lastVolume', 'cumulativeVolume', 'bidPrice', 'bidQuantity', 'askPrice', 'askQuantity', 'nominalPrice', 'openPrice', 'closePrice', 'dayClosePrice', 'tacPrice', 'dayHigh', 'dayLow', 'oneYearHigh', 'oneYearLow', 'change', 'changePercentage', 'remark', 'tradingStatus', 'suspension', 'updateTime', 'lastTradeTime'];
      var subscription = new Lightstreamer.Subscription('MERGE', itemName, fields);
      subscription.setDataAdapter('PRICE_QUOTE');
      subscription.addListener(this.mergeUpdateListener(data, config));
      this.subscriptionList.priceQuote = subscription;
      this.subscriptionData.priceQuote = data;
      this.client.subscribe(subscription);
    }

    subscribePriceQuoteWithModule(module, priceQuotelevel, priceQuoteSymbol, data, config) {
      if (!this.subscriptionList.priceQuoteWithModule[module]) {
        this.subscriptionList.priceQuoteWithModule[module] = [];
      }
      if (!priceQuoteSymbol) {
        return;
      }
      if (this.subscriptionList.priceQuoteWithModule[module][priceQuoteSymbol]) {
          return;
      }
      var itemName = this.getItemName('PRICE_QUOTE', priceQuotelevel, priceQuoteSymbol);
      var fields = ['symbol', 'exchange', 'stockCode', 'level', 'stockName', 'lotSize', 'currency', 'tickerNo', 'productType', 'securityType', 'lastPrice', 'lastVolume', 'cumulativeVolume', 'bidPrice', 'bidQuantity', 'askPrice', 'askQuantity', 'nominalPrice', 'openPrice', 'closePrice', 'dayClosePrice', 'tacPrice', 'dayHigh', 'dayLow', 'oneYearHigh', 'oneYearLow', 'change', 'changePercentage', 'remark', 'tradingStatus', 'suspension', 'updateTime', 'lastTradeTime'];
      var subscription = new Lightstreamer.Subscription('MERGE', itemName, fields);
      subscription.setDataAdapter('PRICE_QUOTE');
      subscription.addListener(this.mergeUpdateListener(data, config));
      this.subscriptionList.priceQuoteWithModule[module][priceQuoteSymbol] = {
        itemName: itemName,
        subscription: subscription,
        config: config
      };
      this.subscriptionData.priceQuoteWithModule = data;
      this.client.subscribe(subscription);
    }

    // ==================================================================================
    // Snap
    // ==================================================================================
    subscribeSnap(index, querySnapInfo) {
        var itemName = this.getItemName('SNAP', 'NONE', index);
        var fields = ['request', 'response', 'snapLimit', 'error'];
        var subscription = new Lightstreamer.Subscription('RAW', itemName, fields);
        subscription.setDataAdapter('SNAP');
        subscription.addListener({
          onItemUpdate: (info) => {
            var request = info.getValue('request');
            var response = JSON.parse(info.getValue('response'));
            var snapLimit = JSON.parse(info.getValue('snapLimit'));
            var error = info.getValue('error');
            var data = {
                request: request,
                response: response,
                snapLimit: snapLimit,
                error: error
            }
            if (data.error) {
              _.forEach(this.loginCallbacks, (loginCallback) => {
                loginCallback.subscriptionErrorCallback;
                loginCallback.snapErrorCallback();
                loginCallback.digestCallback;
              });
              return;
            }
            var type = data.request.split('-')[0];
            if (type == "SNAP_INFO"  || type == "PERMISSION") {
              _.forEach(data.response, (permission,exchange) => {
                if(permission.streamL2) {
                  this.permissionInfo[exchange].streamL2 = true;
                  this.permissionInfo[exchange].streamL1 = true;
                  this.permissionInfo[exchange].streamL1Type = 'L1';
                } else if (permission.streamL1) {
                  this.permissionInfo[exchange].streamL2 = false;
                  this.permissionInfo[exchange].streamL1 = true;
                  this.permissionInfo[exchange].streamL1Type = 'L1';
                } else if (permission.streamDelay) {
                  this.permissionInfo[exchange].streamL2 = false;
                  this.permissionInfo[exchange].streamL1 = true;
                  this.permissionInfo[exchange].streamL1Type = 'DELAY';
                } else {
                  this.permissionInfo[exchange].streamL2 = false;
                  this.permissionInfo[exchange].streamL1 = false;
                  this.permissionInfo[exchange].streamL1Type = 'DELAY';
                }
                this.permissionInfo[exchange].snapL1 = permission.snapL1;
                this.permissionInfo[exchange].snapL2 = permission.snapL2;
              });
              if (type == "SNAP_INFO") {
                this.loginStatus = 2;
                _.forEach(this.loginCallbacks, (loginCallback) => {
                  loginCallback.loginSuccessCallback();
                  loginCallback.digestCallback;
                });
              }else {
                _.forEach(this.loginCallbacks, (loginCallback) => {
                  if (loginCallback.permissionUpdateCallback) {
                    try {
                      loginCallback.permissionUpdateCallback(data.request);
                      loginCallback.digestCallback();
                    } catch (err) {
                      console.error(err);
                    }
                  }
                });

              }
            } else if (type == 'PRICE') {
              _.forEach(this.loginCallbacks, (loginCallback) => {
                loginCallback.snapProcessingCallback(data);
                loginCallback.digestCallback();
              });
            }
          },
          onSubscription: () => {
            if (querySnapInfo) {
              this.client.sendMessage('SNAP_INFO-NONE-');
            }
          },
          onSubscriptionError: (code, message) => {
            _.forEach(this.loginCallbacks, (loginCallback) => {
              loginCallback.loginErrorCallback();
              loginCallback.digestCallback();
            });
          },
        });
        this.client.subscribe(subscription);
    }

     /**
     * level
     * symbols
     * deniedCallback
     * errorCallback
     */
     snap(level, symbols, deniedCallback, errorCallback) {
        var groupSymbol = _.groupBy(symbols, function(symbol) {
          return symbol.split('.')[0];
        });
        if (_.size(groupSymbol) > 1) {
          _.forEach(groupSymbol, function(subGroup) {
            this.snap(level, subGroup, deniedCallback, errorCallback);
          });
          return;
        }
        var symbolString = '';
        _.forEach(symbols, (symbol) => {
          if (symbolString.length > 0) {
            symbolString = symbolString + ',';
          }
          symbolString = symbolString + symbol;
        });
        var item = 'PRICE-' + level + '-' + symbolString;
        if (this.loginStatus != 2){
            errorCallback();
            return;
        };
        this.client.sendMessage(
          item,
          'UNORDERED_MESSAGES',
          0,
          {
            onDeny: (originalMessage, code, message) => {
              if (code == -6) {
                deniedCallback();
              } else {
                errorCallback();
              }
            },
            onError: (originalMessage) => {
              _.forEach(this.loginCallbacks, (loginCallback) => {
                loginCallback.subscriptionErrorCallback();
                loginCallback.digestCallback();
              });
            },
          }
        );
    }

    // ==================================================================================
    // Time and Sale
    // ==================================================================================
    unsubscribeTimeAndSale() {
        if (this.subscriptionList.timeAndSale) {
          this.client.unsubscribe(this.subscriptionList.timeAndSale);
          delete this.subscriptionList.timeAndSale;
        }
        if (this.subscriptionData.timeAndSale) {
          this.subscriptionData.timeAndSale.length = 0;
        }
    }

    
    /**
     * timeAndSaleLevel
     * timeAndSaleSymbol
     * data: an array to store the result
     * config: {
     *       digestCallback:    callback for invoking $scope.$digest()
     * }
     */
    subscribeTimeAndSale(timeAndSaleLevel, timeAndSaleSymbol, data, config) {
         this.unsubscribeTimeAndSale();
  
        var itemName = this.getItemName('TIME_AND_SALE', timeAndSaleLevel, timeAndSaleSymbol);
        var fields = ['key', 'command', 'symbol', 'exchange', 'exchangeCode', 'stockCode', 'tradeTime', 'size', 'price', 'side', 'tradeType'];
        var subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
        subscription.setDataAdapter('TIME_AND_SALE');
        subscription.addListener(this.commandUpdateListener(data, config));
        this.subscriptionList.timeAndSale = subscription;
        this.subscriptionData.timeAndSale = data;
        this.client.subscribe(subscription);
    }

    // ==================================================================================
    // Market Depth
    // ==================================================================================
    unsubscribeMarketDepth(data) {
        if (this.subscriptionList.marketDepth) {
          this.client.unsubscribe(this.subscriptionList.marketDepth);
          delete this.subscriptionList.marketDepth;
        }
        if (this.subscriptionData.marketDepth) {
          this.subscriptionData.marketDepth.length = 0;
        }
    }
  
    /**
     * marketDepthLevel
     * marketDepthSymbol
     * data: an array to store the result
     * config: {
     *       digestCallback:    callback for invoking $scope.$digest()
     * }
     */
    subscribeMarketDepth(marketDepthLevel, marketDepthSymbol, data, config) {
    // this.unsubscribeMarketDepth(data);

    var itemName = this.getItemName('MARKET_DEPTH', marketDepthLevel, marketDepthSymbol);
    var fields = ['key', 'command', 'symbol', 'exchange', 'stockCode', 'level', 'type', 'volume', 'price', 'price', 'numberOfTrade', 'brokerQueue'];
    var subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
    subscription.setDataAdapter('MARKET_DEPTH');
    subscription.addListener(this.commandUpdateListener(data, config));
    this.subscriptionList.marketDepth = subscription;
    this.subscriptionData.marketDepth = data;
    this.client.subscribe(subscription);
    }

    unsubscribeFactsheetData(symbol: string): void {
      let subscription = this.subscriptionList.factsheetData.get(symbol);
      if (subscription) {
        this.client.unsubscribe(subscription);
        this.subscriptionList.factsheetData.delete(symbol);
      }
    }

    subscribeFactsheetData(symbol, data, config) {
      if (!symbol) {
        return;
      }
      var itemName = this.getItemName('FACTSHEET_DATA', 'NONE', symbol);
      var fields = [ 'averageDailyVolume2Week', 'averageDailyVolume1month', 'averageDailyVolume3month', 'changePostTrade', 'changePreTrade',
        'changeYtd', 'cumulativeVolumePostTrade', 'cumulativeVolumePreTrade', 'dividendYield', 'ebita',
        'high52Week', 'low52Week', 'marketCapDynamic', 'peRatioDynamic', 'pricePostTrade',
        'pricePreTrade', 'priceToBook', 'priceToTotalRevenue', 'sectorId', 'sharesOutstanding', 'turnover' ];
      var subscription = new Lightstreamer.Subscription('MERGE', itemName, fields);
      subscription.setDataAdapter('FACTSHEET_DATA');
      subscription.addListener(this.mergeUpdateListener(data, config));
      this.subscriptionList.factsheetData.set(symbol, subscription);
      this.client.subscribe(subscription);
    }

     // ==================================================================================
    // Minute Bar
    // ==================================================================================
    unsubscribeMinuteBar(minuteBarSymbol: string): void {
      if (this.subscriptionList.minuteBar[minuteBarSymbol]) {
        this.client.unsubscribe(this.subscriptionList.minuteBar[minuteBarSymbol]);
        delete this.subscriptionList.minuteBar[minuteBarSymbol];
      }
    }

    /**
       * minuteBarSymbol
       * minuteBarLevel
       * config: {
       *      addCallback: callback when new entry is added to data
       *      checkChangesCallback: callback to check new changes on the data
       *      updateCallback: callback for updating the data
       *      deleteCallback: callback when delete entry
       * }
       */
    subscribeMinuteBar(minuteBarSymbol: string, minuteBarLevel: string, config: LightStreamerCommandConfig): void {
      this.unsubscribeMinuteBar(minuteBarSymbol);
      var itemName = this.getItemName('MINUTE_BAR', minuteBarLevel, minuteBarSymbol);
      var fields = ['key', 'command', 'openPrice', 'closePrice', 'highPrice', 'lowPrice', 'volume'];
      var subscription = new Lightstreamer.Subscription('COMMAND', itemName, fields);
      subscription.setDataAdapter('MINUTE_BAR');
      subscription.addListener(this.commandUpdateListenerForMinuteBar({}, config));
      this.subscriptionList.minuteBar[minuteBarSymbol] = subscription;
      this.client.subscribe(subscription);
    }

    getLsToken() {
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_LS_TOKEN, {}, {});
    }
}

export interface LightStreamerCommandConfig {
  addCallback(data)
  checkChangesCallback(oldData, itemUpdate)
  updateCallback(oldData, newData)
  deleteCallback(data)
}
