<div class="container overlap-subheader-margin">
	<nz-row nzGutter="20" class="mb-3 d-flex flex-wrap">
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
    	<ng-container *ngTemplateOutlet="Template; context: { method: FUNDS, text: 'fsmone.fee.funds', path: 'fund-2x' , selectorurl: fundSelector}"></ng-container>
    </nz-col>
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
      <ng-container *ngTemplateOutlet="Template; context: { method: BONDS, text: 'fsmone.fee.bonds', path: 'bond-2x' , selectorurl: bondSelector}"></ng-container>
		</nz-col>
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
    	<ng-container *ngTemplateOutlet="Template; context: { method: ETF, text: 'fsmone.general.etf', path: 'etf-2x' , selectorurl: etfSelector}"></ng-container>
    </nz-col>
    <nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
    	<ng-container *ngTemplateOutlet="Template; context: { method: STOCK, text: 'fsmone.stocks', path: 'stock-2x' , selectorurl: stockSelector}"></ng-container>
    </nz-col>
  </nz-row>
</div>

<ng-template #Template let-method="method" let-text="text" let-path="path" let-selectorurl="selectorurl">
  <nz-card class="card-select-box h-100" [routerLink]="selectorurl" [ngClass]="{'active': selected == method}" nzHoverable role="button" (click)="changeCategory(method)">
    <div class="text-center">
      <img height="50" [src]="PRODUCTS_ICON_IMAGES_PATH + path + '.png'" [alt]="text + ' Icon'">
      <h5 class="mt-2 mb-0 font-weight-bold">{{ text | translate }}</h5>
    </div>
  </nz-card>
</ng-template>
