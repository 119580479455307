import { Component,Input, Output } from '@angular/core';
import {gotoFundBuy, goToWatchList,gotoStockBuy,gotoBuy,gotoBondIpoBuy } from '@share/services/product-search.service';

@Component({
	selector: 'app-buy-watchlist-button',
	templateUrl: './buy-watchlist-icon.component.html'
})


export class BuyWatchlistIconComponent {
	@Input() product: string;
	@Input() buyEnabled: boolean = true;
	@Input() productId: string;
	@Input() symbol: string;
	@Input() exchange: string;
	@Input() watchlistEnabled: boolean = true;
	
	fund: string = 'funds';
	bond: string = 'bond';
	etf: string = 'etf';
	stock: string = 'stock';
	
	buyIcon: string = 'assets/images/general/icons/buy-icon.svg';
	watchlist: string = 'assets/images/fund-house/Add-to-Watchlist.svg';

	constructor(){}
	
	buy(){
		switch(this.product){
			case this.fund:
				gotoFundBuy(this.productId);
				break;
			case this.bond:
				gotoBuy(this.productId,'bond');
				break;
			case this.etf:
			case this.stock:
				gotoStockBuy(this.symbol, this.exchange);
				break;
			default:
				break;
		}
	}
	
	addToWatchList(){
		goToWatchList();
	}
	
}