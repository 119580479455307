import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SeoService {

    constructor(@Inject(DOCUMENT) private doc) {}

    updateCanonicalURL(url: string) {
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('class', 'canonical-link');
        this.doc.head.appendChild(link);
        link.setAttribute('href', url);
    }

    removeCanonicalURL() {
        let gfg_down = document.getElementsByClassName('canonical-link')[0];
        gfg_down.parentNode.removeChild(gfg_down);
    }
}
