import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowResizeService {
    windowResizeEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
        window.addEventListener('resize', this.onWindowResize);
    }

    private onWindowResize = (): void => {
        this.windowResizeEvent.emit();
    }
}
