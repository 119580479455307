import { Bar, SubscribeBarsCallback } from 'src/assets/charting_library_v2/datafeed-api';
import { TaChartStatus, TaChartSubscription, TaChartUpdater } from '@share/models/stocks/stock-ta-chart.model';
import { LocalStorageConstant } from 'src/app/constant/local.storage.constant';
import { getStorage, setStorage } from '@util/local-storage';
import { StocksInfoRestService } from '@api-module/rest/stocks-info.rest.service';
import { StockService } from './stock.service';

const UPDATE_FREQUENCY: number = 60 * 1000; // 60 seconds

export class StockTaChartUpdaterOnInterval implements TaChartUpdater {

  private tickerNo: string;
  private exchange: string;
  private resolution: string;
  private onTick: SubscribeBarsCallback;
  private lastBar: Bar;
  private status: TaChartStatus;
  private stockService: StockService;
  private stocksInfoRestService: StocksInfoRestService;
  private countdownTimer: number;
  private intervalId: any;
  private isLive: string = 'false';

  constructor(subscription: TaChartSubscription, lastBar: Bar, stockService: StockService, stocksInfoRestService: StocksInfoRestService, isLive: string) {
    this.tickerNo = subscription.symbolInfo.tickerNo || '';
    this.exchange = subscription.symbolInfo.exchange || '';
    this.resolution = subscription.resolution || '';
    this.onTick = subscription.onTick;
    this.lastBar = lastBar;
    this.isLive = isLive;
    this.stockService = stockService;
    this.stocksInfoRestService = stocksInfoRestService;
  }

  subscribeBars(): void {
    // Check variables
    if (!this.tickerNo || !this.resolution || !this.onTick) {
      return;
    }
    // Already subscribed
    if (this.intervalId) {
      return;
    }
    this.getBarUpdate();
    this.intervalId = setInterval(this.getBarUpdateOnInterval.bind(this), 1000);
  }

  private getBarUpdateOnInterval(): void {
    if (!this.intervalId) {
      return;
    }
    this.countdownTimer = this.countdownTimer - 1000;
    this.stockService.updateTaChartStatus({ countdownTimer: (this.countdownTimer > 0 ? this.countdownTimer / 1000 : 0) });
    if (this.countdownTimer <= 0) {
      this.getBarUpdate();
    }
  }

  private getBarUpdate(): void {
    this.countdownTimer = UPDATE_FREQUENCY;
    this.stocksInfoRestService.getTaChartBarUpdate(this.tickerNo, this.exchange, this.resolution, this.isLive, "false").subscribe((response) => {
      if(response.data && response.data.bars){
        for (let i = 0; i < response.data.bars.length; i++) {
          // Will not update if existing last bar is newer
          let bar = response.data.bars[i];
          if (this.lastBar && this.lastBar.time && this.lastBar.time > bar.time) {
            continue;
          }
          // Adding missing bars between last bar and current bar
          if (this.lastBar && this.lastBar.time) {
            let timeDiff = parseInt(this.resolution) * 60000;
            let closePrice = this.lastBar.close || 0;
            for (let currTime = this.lastBar.time + timeDiff; currTime < bar.time; currTime += timeDiff) {
              this.onTick({
                time: currTime,
                volume: 0,
                open: closePrice,
                close: closePrice,
                low: closePrice,
                high: closePrice
              });

              var lastUpdateDate = (currTime/1000).toString();
              if (getStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE) !== lastUpdateDate){
                setStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE, lastUpdateDate);
              }
            }
          }
          // Set current bar
          this.onTick(bar);
          this.lastBar = bar;
        }
      }
    });
  }

  unsubscribeBars(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }
}
