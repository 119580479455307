import { Injectable, ElementRef, Renderer2 } from '@angular/core';
import { HeaderStorageService } from '@share/services/header/header-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderPositionService {

  constructor(
    private renderer: Renderer2,
    private headerStorageService: HeaderStorageService
  ) {}

  calculatePusherHeight(host: ElementRef<any>, pusher: ElementRef<any>) {
    if (host && pusher) {
      const hostHeight: number = host.nativeElement.getBoundingClientRect().height;
      this.renderer.setStyle(pusher.nativeElement, 'height', `${hostHeight}px`);
      this.headerStorageService.headerHeight.next(hostHeight);
    }
  }
}
