import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Observable, from} from 'rxjs';
import {takeUntil, take} from 'rxjs/operators';
import {formatDate} from '@angular/common';

import * as XLSX from 'xlsx';

@Injectable()
export class UtilService {
	constructor() {}

	deepCopy(object: any | any[]) {
		return JSON.parse(JSON.stringify(object));
	}

	/**
	 * sort array via single
	 * @param sortAttribute {key: property of the object, value: 'ascend' or 'descend'}
	 * @param inputData
	 */
	sort(sortAttribute: { key: string; value: string }, inputData: any[]) {
		const dataArr = this.deepCopy(inputData);
		if (sortAttribute.key === '' || sortAttribute.value === null) {
			return dataArr;
		}

		let outputDataList = dataArr.sort((a, b) => {
			const isAsc = sortAttribute.value === 'ascend';
			switch (sortAttribute.key) {
				case sortAttribute.key:
				if(!isNaN(Number(a[sortAttribute.key]))){
					return this.compareNumber(
						a[sortAttribute.key],
						b[sortAttribute.key],
						isAsc
					);
				}else{
					return this.compare(
						typeof a[sortAttribute.key] !== 'string' ? a[sortAttribute.key] : a[sortAttribute.key].toUpperCase(),
						typeof b[sortAttribute.key] !== 'string' ? b[sortAttribute.key] : b[sortAttribute.key].toUpperCase(),
						isAsc
					);					
				}

				default:
					return 0;
			}
		});
		return outputDataList;
	}

	/**
	 * Wild card search on all property of the object
	 * @param input
	 * @param inputData
	 */
	search(input: any, inputData: any[]) {
		const searchText = item => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}

				if (typeof item[key] == 'number' && item[key] != 0) {
					let date = formatDate(item[key], 'yyyy-MM-dd HH:mm:ss', 'en');
					if (date.indexOf(input.toString()) !== -1) {
						return true;
					}
					continue;
				}

				if (
					item[key]
						.toString()
						.toUpperCase()
						.indexOf(input.toString().toUpperCase()) !== -1
				) {
					return true;
				}
			}
		};
		inputData = inputData.filter(value => searchText(value));
		return inputData;
	}

	/**
	 * if isAsc is true
	 * a > b    = 1
	 * a === b  = 0
	 * a < b    = -1
	 *
	 * if isAsc is false
	 * a > b    = -1
	 * a === b  = 0
	 * a < b    = 1
	 *
	 * @param a
	 * @param b
	 * @param isAsc
	 */
	compare(a, b, isAsc: boolean) {
		// null value is - (dash)
		if (!a && a != 0) a = '-';
		if (!b && b != 0) b = '-';

		// whole number is date
		// if (typeof a == 'number' && a % 1 == 0) a = formatDate(a, 'yyyy-MM-dd HH:mm:ss', 'en');
		// if (typeof b == 'number' && b % 1 == 0) b = formatDate(b, 'yyyy-MM-dd HH:mm:ss', 'en');

		if (a === b) return 0;

		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	
	compareNumber(a, b, isAsc: boolean) {
		// null value is - (dash)
		if (!a && a != 0) a = '-';
		if (!b && b != 0) b = '-';

		// whole number is date
		// if (typeof a == 'number' && a % 1 == 0) a = formatDate(a, 'yyyy-MM-dd HH:mm:ss', 'en');
		// if (typeof b == 'number' && b % 1 == 0) b = formatDate(b, 'yyyy-MM-dd HH:mm:ss', 'en');

		if (a === b) return 0;
		
		if(isAsc){
			return a-b;
		}else{
			return b-a;
		}
	}
	

	maskTelHp(telHp: string): string{
	  if(!telHp){
	    return;
    }
	  const prefixHp: string = telHp.substr(0, 3);
	  const postFixHp: string = telHp.substr(telHp.length - 3, 3);
    const mask = telHp.substring(3, telHp.length - 3).replace(/\d/g,"*");
	  return prefixHp + mask + postFixHp;
  }

  formatNric(nric: string): string {
    if(nric !== '' && nric !== null && nric !== undefined) {
      return nric.length == 12 ? nric.substr(0, 6) + '-' + nric.substr(6, 2)
        + '-' + nric.substr(8, 4) : nric;
    } else {
      return nric;
    }
  }

  formatTelHp(telHp: string): string {
    if(telHp !== '' && telHp !== null && telHp !== undefined) {
      return telHp.length >= 8 ? telHp.substr(0, 2) + ' ' + telHp.substr(2, 3)
        + ' ' + telHp.substr(5) : telHp;
    } else {
      return telHp;
    }
  }

  scrollToError() {
    const offsetHeaderBanner: number = 150;
    const element: Element = document.querySelectorAll(':not(form).ng-invalid')[0];

    if (!element) {
      return null;
    }

    return setTimeout(() => {
      const top: number = element.getBoundingClientRect().top + window.pageYOffset - offsetHeaderBanner;

      window.scrollTo({top, behavior: 'smooth'})
    }, 80);
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/gm, ((matched: string) => {
      return matched.charAt(0).toUpperCase() + matched.substring(1).toLowerCase();
    }));
  }

  handleAlias(name: string) {
    const regex = new RegExp(/^(.*)\((.*)\)$/, 'mi');

    if (regex.test(name)) {
      const result = regex.exec(name);
      const originalName: string = result[1];
      const alias: string = result[2];

      if (!originalName || !alias) {
        return name;
      }

      const formattedAlias = alias.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase() ));

      return `${originalName}(${formattedAlias})`;
    }

    return name;
  }

  convertBase64(file: File): Observable<string | ArrayBuffer> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable<string | ArrayBuffer>(observer => {
      reader.onload = e => {
        observer.next(reader.result);
        observer.complete();
      };
      reader.onerror = function(error) {
        observer.error(error);
      };
    });
  }

	compareArray(arr1: any[], arr2: any[]){
		// return true when 2 array is identical
		return (
			arr1.length === arr2.length &&
    	arr1.every((value, index) => value === arr2[index])
		)
	}
	
	addDays(date: Date, days: number): Date{
		date.setDate(date.getDate()+days);
		return date;
	}
	
	convertUrlTitle(title: any) : string {
		return title.replaceAll(/[^\u4E00-\u9FFFp{L}a-zA-Z0-9]+/gi,"-");
	}
	
	exportToExcel(filename:string, element:any){
		if(filename.indexOf('.xlsx')<0){
			filename = filename+".xlsx";
		}
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb,ws,'Sheet1');
		XLSX.writeFile(wb,filename);
	}

}
