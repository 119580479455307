/**
 * unescape a given HTML string
 * @param {string} originalString
 * @return {string} unescaped HTML string
 */
export function unescapeHtml(originalString: string): string {
  if (!originalString || originalString.length === 0 || typeof originalString !== 'string') {
    return originalString;
  }

  return originalString.replace(/&#([0-9]{1,3});/gi, function(_, numStr) {
    const num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  }).replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/<br\/>/g, '\n');
}
