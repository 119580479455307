import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import { Observable } from "rxjs";
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {Select} from '@ngxs/store';
import {GlobalState} from '../../../../core/store';
import {ArticleRestService} from 'src/app/api/rest/article.rest.service';
import {IArticleRcmsTwModel} from '@api-module/model/article/i-article-rcms-tw.model';
import { UtilService } from "@api-module/service/common/util.service";


@Component({
  selector: 'app-school-articles',
  templateUrl: './school-article.component.html',
  styles: [],
  providers: [UtilService]
})

export class SchoolArticlesComponent  implements OnInit{
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  PRODUCTS_ICON_IMAGES_PATH = 'assets/images/general/icons/product/';
  SCHOOL_ARTICLE_IMAGES_PATH = 'assets/images/article/school-article/';
  ADVANCED = 'advanced';
  INTERMEDIATE = 'intermediate';
  BEGINNER = 'beginner';
  
  searchValue: string = '';
  selectedCategory: string = 'all';
  readonly FUNDS: string = 'FUNDS';
  readonly BONDS: string = 'BONDS';
  readonly STOCKS: string = 'STOCKS';
  readonly INVEST: string = 'INVEST';
  @Input() type: string;
  selected= this.FUNDS;
  showAllAction = true;
  article_1: IArticleRcmsTwModel[] = [];
  article_2: IArticleRcmsTwModel[] = [];
  article_3: IArticleRcmsTwModel[] = [];
  article: IArticleRcmsTwModel[] = [];
  articleDisplay: IArticleRcmsTwModel[] = [];
  loading = false;
  rcmsLoading = false;
  rcmsArticles: IArticleRcmsTwModel[] = [];

  constructor(private subHandlingService: SubHandlingService,
			  private articleRestService: ArticleRestService,
	 		  private router: Router,
    		  private utilService: UtilService) {}

  ngOnInit(): void {
	this.identifyType();
	this.getAllSchoolArticles();
  }

  identifyType(){
	this.selected = this.type;
  }

  getAllSchoolArticles(){
	this.loading = true;
	this.articleRestService.findSchoolArticleList(this.selected).subscribe((response: any) =>
	{	
		if (response.status !== AppConstant.RESPONSE_SUCCESS) {
            return;
         }
		var beginner = response.data.beginner;
		var intermediate = response.data.intermediate;
		var advanced = response.data.advanced;
				
		this.article = this.article.concat(beginner,advanced);
		this.getRcmsArticle();

		this.loading = false;
	})
  }

  getRcmsArticle(): void {
	this.rcmsLoading = true;
	this.articleRestService.findSchoolArticleListFromRCMS(this.selected).subscribe((response: any) =>
	{	
		if (response.status !== AppConstant.RESPONSE_SUCCESS) {
            return;
        } 
		this.rcmsArticles = response.data.rcmsArticles;
		this.changeRcmsTagToCategory(this.rcmsArticles);
		
		this.article = this.article.concat(this.rcmsArticles);
		this.setImage(this.article);
		
		this.article = this.utilService.sort({'key':'articleId','value':'descend'}, this.article);
		this.articleDisplay = this.article;
		
		this.rcmsLoading = false;
	}) 
	
  }

  changeRcmsTagToCategory(articleList): void {
	for (var article of articleList) {
		article.fromRcms = true;
		if ((article.tags.includes('Beginner')) || (article.tags.includes('beginner'))){
			article.category = 'beginner'
		} else if ((article.tags.includes('Advanced')) || (article.tags.includes('beginner'))){
			article.category = 'advanced'
		} else {
			article.category = 'beginner'
		}
	}
  } 

  changeCategory(cat){
	this.selected = cat;
	if(this.selected == this.FUNDS){
		this.router.navigate([RouteConstant.FUNDS, RouteConstant.EXPLORE, RouteConstant.FUND_101], {});
	}else if(this.selected == this.BONDS){
		this.router.navigate([RouteConstant.BONDS, RouteConstant.EXPLORE,RouteConstant.BOND_101], {});
	}else if(this.selected == this.STOCKS){
		this.router.navigate([RouteConstant.STOCKS, RouteConstant.EXPLORE,RouteConstant.STOCK_101], {});
	}else if(this.selected == this.INVEST){
		this.router.navigate([RouteConstant.BASIC_101], {});
	}
  }

  redirectToArticleDetails(articleNo: string, title?: string, rcms?: any): void {
	if (rcms) {
		articleNo = 'rcms' + articleNo;
	}
	let route = RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + articleNo + '/' + title.replace(/[^\u4E00-\u9FFFp{L}a-zA-Z0-9]+/gi,"-");
	window.open(route, '_blank', 'noreferrer');
  }

    getArticleDetailsUrl(articleNo: string): string 
	{
        return RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' +articleNo;
    }

  expendCloseAllAction() {
    this.showAllAction = !this.showAllAction;
  }

  setImage(articleList){
	for (var article of articleList) 
	{
		if(article.category.toLowerCase()==this.BEGINNER)
		{
			article.featuredImage = this.SCHOOL_ARTICLE_IMAGES_PATH + this.BEGINNER + "/"+ (Math.floor(Math.random()* 10)+1) + ".jpg";
		}else if(article.category.toLowerCase()==this.INTERMEDIATE)
		{
			article.featuredImage = this.SCHOOL_ARTICLE_IMAGES_PATH + this.INTERMEDIATE + "/"+ (Math.floor(Math.random()* 10)+1) + ".jpg";
		}else if(article.category.toLowerCase()==this.ADVANCED)
		{
			article.featuredImage = this.SCHOOL_ARTICLE_IMAGES_PATH + this.ADVANCED + "/"+ (Math.floor(Math.random()* 10)+1) + ".jpg";
		}
	}
  }

  search(): void {
    if (this.searchValue) {
      this.articleDisplay = this.article.filter(
        (item: any) => item.title.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1);
    } else {
      this.articleDisplay = this.article;
    }
  }

  onChangeCategory(): void {
    if (this.selectedCategory === 'all'){
      this.articleDisplay = this.article;
    } else {
      this.articleDisplay = this.article.filter((item: any) => item.category === this.selectedCategory);
    }
  }

  goToAlternativeInvestment(){
    this.router.navigate([RouteConstant.BONDS+"/"+RouteConstant.EXPLORE+"/"+RouteConstant.BOND_EXPRESS], {});
  }

    alternativeInvestmentUrl():string{
      return RouteConstant.BONDS+"/"+RouteConstant.EXPLORE+"/"+RouteConstant.BOND_EXPRESS
    }
}
