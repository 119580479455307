import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiImportantNoteComponent } from './ui-important-note/ui-important-note.component';

@NgModule({
  declarations: [UiImportantNoteComponent],
  imports: [
    CommonModule
  ],
  exports: [
    UiImportantNoteComponent
  ]
})
export class ImportantNoteModule { }
