import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalDataStorage} from './share/storages/global-data.storage';
import {CookieService} from 'ngx-cookie-service';
import {IDropdownModel} from '@api-module/model/common/i-dropdown.model';
import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeCh from '@angular/common/locales/zh-Hant';
import localeZh from '@angular/common/locales/zh-Hans';
import {en_US, NzI18nService, zh_CN, zh_TW} from 'ng-zorro-antd';
import {ResponsiveService} from 'src/app/core/service/responsive.service';
import {SyncAuthService} from '@auth-module/service/sync-auth.service';
import * as NProgress from 'nprogress';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as $ from 'jquery';
import {NgcCookieConsentService} from "ngx-cookieconsent";
import {PageService} from 'src/app/core/service/page.service';
import {BreadcrumbService} from 'xng-breadcrumb';
import {LoginRestService} from '@api-module/rest/login.rest.service';
import {AppConstant} from './constant/app.constant';
import {IResponse} from '@api-module/model/common/i-response';
import {UpdateFsmPostLoginEnabled} from '@core/store/action/global.action';
import {Store} from '@ngxs/store';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthState, Logout } from '@auth-module/store';
import { NzModalService } from 'ng-zorro-antd';
import {AuthConstant} from 'src/app/constant';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit 
{
	isAgreementModalVisible: boolean = true;
	langOptions: IDropdownModel[] = [] as IDropdownModel[];
	locale: string = 'ch';
	timeoutwarning: boolean = false;
	broadcastChannel: BroadcastChannel;	
	
	constructor(
		private responsiveService: ResponsiveService,
		private translate: TranslateService,
		private globalDataStorage: GlobalDataStorage,
		private cookie: CookieService,
		private i18n: NzI18nService,
		private syncAuthService: SyncAuthService,
		private router: Router,
		private ccService: NgcCookieConsentService,
		private route: ActivatedRoute,
		private pageService: PageService,
		private loginRestService: LoginRestService,
		private store: Store,
		private breadcrumbService: BreadcrumbService, // this is needed even though not used within this file, for breadcrumb to display properly
		private idle: Idle,
		private modal: NzModalService,
	) 
	{	
		this.locale = globalDataStorage.getStorage('locale') || 'ch';
		translate.setDefaultLang(globalDataStorage.getStorage('locale') || 'ch');
		translate.use(globalDataStorage.getStorage('locale') || 'ch');
		cookie.set('paramLocale', globalDataStorage.getStorage('locale') || 'ch');		
		
		this.ccService.getConfig().content = this.ccService.getConfig().content || {};
		this.ccService.getConfig().content.title = this.translate.instant('cookie.policy.banner.0');
		this.ccService.getConfig().content.message1 = this.translate.instant('cookie.policy.banner.1');
		this.ccService.getConfig().content.message2 = this.translate.instant('cookie.policy.banner.2');
		this.ccService.getConfig().content.privacyPolicy = this.translate.instant('privacy.policy');
		this.ccService.destroy();
		this.ccService.init(this.ccService.getConfig());
		
		this.initDefaultOptions();
		
		this.broadcastChannel = new BroadcastChannel(AuthConstant.CHANNEL_NAME);

		this.broadcastChannel.onmessage = (event) => {
	      if(event.data === 'Logout') {
 			this.router.navigate(['/login']);
			idle.stop();
	      }
	    }
		
	}

	ngOnInit(): void {
		this.loginRestService.isModuleEnabled("FSMONE_POSTLOGIN").subscribe((res: IResponse<any>) => {
			if (res && res.status === AppConstant.RESPONSE_SUCCESS && res.data === true) {
				this.store.dispatch(new UpdateFsmPostLoginEnabled(true));
			} else {
				this.store.dispatch(new UpdateFsmPostLoginEnabled(false));
			}
		});
		this.syncAuthService.start();
		this.responsiveService.observe();
		this.pageService.listen();
		localStorage.removeItem('maintenanceBannerDisplayed');
		
		this.route.queryParams.subscribe(param => 
		{
			if ("en" === param?.locale || "ch" === param?.locale || "zh" === param?.locale) 
			{
				console.log("[Locale] Resetting from param : " + param.locale);

				this.locale = param.locale;
				this.toggleShow(false);
				
				//remove locale param after setting locale
				const newUrlParam = { ...this.route.snapshot.queryParams };
		        delete newUrlParam.locale;
		        this.router.navigate([], { queryParams: newUrlParam });
			}
		});

		if (!this.cookie.check('FSMOne') || this.cookie.get('FSMOne') == 'false') 
		{
			if(this.detectRobot(navigator.userAgent)){
				this.isAgreementModalVisible = false;
			}
			else {
				this.isAgreementModalVisible = true;
				this.globalDataStorage.setStorage('noticeBannerVisible', 'true');
			}
		}
		else
		{
			this.isAgreementModalVisible = false;
		}
		
		this.router.events.subscribe(event => 
		{
			if (event instanceof NavigationEnd) 
			{
				NProgress.configure({ showSpinner: false, minimum: 0.8, easing: 'ease', speed: 300 });
				NProgress.inc();
				
				$(document).ready(function() 
				{
					NProgress.done(true);
				});
			}
		})
		
		this.idle.setIdle(1);
        this.idle.setTimeout((29*60)-1); 
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        if(this.store.selectSnapshot(AuthState.getToken)){
            this.idle.watch();
        }

		this.idle.onTimeout.subscribe(() => {					
			this.timeoutwarning = true;

			const modal = this.modal.confirm({
				nzTitle: this.translate.instant('your.session.will.expire.soon.') + ' ' + this.translate.instant('please.respond.if.you.would.like.to.continue.or.logout') + ' ' + this.translate.instant('do.you.wish.to.continue.'),
				nzClosable: false,
				nzOkText: this.translate.instant('yes'),
				nzCancelText: this.translate.instant('logout'),
				nzOnOk: () => this.stayLoggedIn(),
				nzOnCancel: () => this.logout()
			});
			
			setTimeout(() => modal.destroy(), 60000);
			
			setTimeout(() => {
				if(this.timeoutwarning){
					this.modal.warning({
				    nzTitle: this.translate.instant('fsmone.account.idle'),
					nzClosable: false,
					});
					this.logout();	
				} 
			}, 60000);	
       	});

	}

	stayLoggedIn(){
		this.timeoutwarning = false;
		if(this.store.selectSnapshot(AuthState.getToken)){
            this.idle.watch();
        }
	}
	
	logout(){
		this.timeoutwarning = false;
		const actions: Array<any> = [Logout];
        this.store.dispatch(actions.map((action => new action())));
		this.broadcastChannel.postMessage('Logout');
		this.router.navigate(['/login']);
		this.idle.stop();
	}
	
	detectRobot = (userAgent: string): boolean => {
		const robots = new RegExp(([
			/bot/,/spider/,/crawl/,                               // GENERAL TERMS
			/APIs-Google/,/AdsBot/,/Googlebot/,                   // GOOGLE ROBOTS
			/mediapartners/,/Google Favicon/,
			/FeedFetcher/,/Google-Read-Aloud/,
			/DuplexWeb-Google/,/googleweblight/,
			/bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,           // OTHER ENGINES
			/ecosia/,/ia_archiver/,
			/facebook/,/instagram/,/pinterest/,/reddit/,          // SOCIAL MEDIA
			/slack/,/twitter/,/whatsapp/,/youtube/,
			/semrush/,                                            // OTHER
		] as RegExp[]).map((r) => r.source).join("|"),"i");     // BUILD REGEXP + "i" FLAG

		return robots.test(userAgent);
	};

	acceptTerms(): void
	{
		this.cookie.set('FSMOne', 'true');
		this.isAgreementModalVisible = false;
	}

	close(): void 
	{
		window.location.reload();
	}

	initDefaultOptions(): void 
	{
		this.langOptions = [
			{
				label: 'EN',
				value: 'en',
			},
			{
				label: '繁',
				value: 'ch',
			},
			{
				label: '简',
				value: 'zh',
			}
		];
	}

	changeLanguage(lang: string) 
	{
		console.log("[Locale] Change language : " + lang);
		
		this.locale = lang;
		this.toggleShow(true);
	}

	toggleShow(refresh: boolean) 
	{
		this.translate.use(this.locale);
		this.globalDataStorage.setStorage('locale', this.locale);
		
		if ("en" === this.locale) 
		{
			registerLocaleData(localeEn);
			this.i18n.setLocale(en_US);
		}
		else if ("ch" === this.locale) 
		{
			registerLocaleData(localeCh, 'zh-tw');
			this.i18n.setLocale(zh_TW);
		}
		else if ("zh" === this.locale) 
		{
			registerLocaleData(localeZh, 'zh-cn');
			this.i18n.setLocale(zh_CN);
		}
		
		console.log("[Locale] Toggle language : " + this.locale);
		this.pageService.changeLangFsmone(this.locale);
		
		if (refresh)
		{
			window.location.reload();
		}
	}
}
