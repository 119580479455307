import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FinancialStatementRebalancingPipe } from 'src/app/share/pipes/financial-statement-rebalancing.pipe';
import { MillionDividerFactsheetPipe } from './million-divider-factsheet.pipe';

@Pipe({
    name: 'dynamicFactsheet'
})

@Injectable({
    providedIn: 'root'
})

export class DynamicFactsheetPipe implements PipeTransform {

    constructor(
        private millionDividerFactsheetPipe: MillionDividerFactsheetPipe, 
        private financialStatementRebalancingPipe: FinancialStatementRebalancingPipe
    ) {}

    transform(value: any, pipes: any, index: number): any {
        var pipeToken = pipes.split('|');
        var pipeArg1 = pipeToken[0].split(':');
        var pipeArg2 = pipeToken[1]? pipeToken[1].split(':') : null;
        if (!pipeToken) {
            return value;
        }
        else {
            if ('millionDivider' == pipeArg1[0].trim()) {
                var withWord = (pipeArg1[1] == "true");
                var includeBillion = (pipeArg1[2] == "true");
                return this.millionDividerFactsheetPipe.transform(value, withWord, includeBillion);
            }
            if ('number' == pipeArg1[0].trim()) {
                return value.toFixed(2);
            }
            if ('financialStatementRebalancing' == pipeArg1[0].trim() && 'millionDivider' == pipeArg2[0].trim()) {
                var withWord = (pipeArg2[1] == "true");
                var includeBillion = (pipeArg2[2] == "true");
                return this.millionDividerFactsheetPipe.transform(this.financialStatementRebalancingPipe.transform(value, index, JSON.parse(pipeArg1[2].replace(/'/g, '"'))), withWord, includeBillion);
            } else if ('financialStatementRebalancing' == pipeArg1[0].trim()) {
                return this.financialStatementRebalancingPipe.transform(value, index, JSON.parse(pipeArg1[2]));
            }
        }
    }
}