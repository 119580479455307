<h4 >1. <span [innerHTML]="'fsmone.imptinfo.personalinfo.1.1' | translate"></span></h4>
<ol type="a">
	<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.1.1' | translate"></span></li>
	<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.1.2' | translate"></span></li>
</ol>
<h4>2. <span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2' | translate"></span></h4>
<div class="pl-3">
	<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1' | translate"></p>
	<ol type="a">
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.1' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.2' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.3' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.4' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.5' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.6' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.7' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.8' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.9' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.10' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.2.1.11' | translate"></span></li>
	</ol>
</div>
<h4>3. <span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3' | translate"></span></h4>
<div class="pl-3">
	<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1' | translate"></p>
	<ol type="a">
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.1' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.2' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.3' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.4' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.5' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.6' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.7' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.8' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.3.1.9' | translate"></span></li>
	</ol>
</div>
<h4>4. <span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4' | translate"></span></h4>
<ol type="a">
	<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1' | translate"></span>
		<ol type="i">
			<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.1' | translate"></span></li>
			<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.2' | translate"></span>
				<ol style="list-style-type: circle;">
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.2.1' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.2.2' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.2.3' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.2.4' | translate"></span></li>
				</ol>
			</li>
			<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.3' | translate"></span>
				<ol style="list-style-type: circle;">
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.3.1' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.3.2' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.3.3' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.3.4' | translate"></span></li>
					<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.3.5' | translate"></span></li>
				</ol>
			</li>
			<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.1.4' | translate"></span></li>
		</ol>
	</li>
	<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.4.2' | translate"></span></li>
</ol>
<h4>5. <span [innerHTML]="'fsmone.imptinfo.personalinfo.1.5' | translate"></span></h4>
<div class="pl-3">
	<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.5.1' | translate"></p>
	<ol type="a">
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.5.1.1' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.5.1.2' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.5.1.3' | translate"></span></li>
		<li><span [innerHTML]="'fsmone.imptinfo.personalinfo.1.5.1.4' | translate"></span></li>
	</ol>
</div>
<h4>6. <span [innerHTML]="'fsmone.imptinfo.personalinfo.1.6' | translate"></span></h4>
<div class="pl-3">
	<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.1' | translate"></p>
	<div class="pl-3">
		<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.1.1' | translate"></p>

		<p class="mb-0" [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.1.2' | translate"></p>
		<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.1.3' | translate"></p>

		<p class="mb-0" [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.1.4' | translate"></p>
		<p [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.1.5' | translate"></p>
	</div>
	<p class="mb-0" [innerHTML]="'fsmone.imptinfo.personalinfo.1.6.2' | translate"></p>
</div>