import {
  Injector,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {MillionDividerPipe} from "../pipes/million-divider.pipe";
import {FinancialStatementRebalancingPipe} from "../pipes/financial-statement-rebalancing.pipe";

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, pipeToken: any, pipeArgs: any): any {
    if (!pipeToken) {
      return value;
    }
    else {
	  pipeToken = pipeToken.trim();
	  pipeArgs = pipeArgs.trim();

	  let nextPipeTokenStr = null;
	  if(pipeArgs.indexOf('|')>0){
	    nextPipeTokenStr = pipeArgs.substr(pipeArgs.indexOf("|")+1);
	    pipeArgs = pipeArgs.split('|')[0];
	  }
	
	  if (pipeToken == 'number') {
	    pipeToken = DecimalPipe;
	    if(!pipeArgs && pipeArgs !== 0){
	      pipeArgs = 2;
	    }
	    pipeArgs = this.getDigitsInfo(pipeArgs);
	  } else if (pipeToken == 'millionDivider') {
	    pipeToken = MillionDividerPipe;
	    pipeArgs = pipeArgs.split(':');
	    pipeArgs[0] = (pipeArgs[0].trim() =="true");
	    pipeArgs[1] = (pipeArgs[1].trim() =="true");
	  } else if (pipeToken == 'financialStatementRebalancing') {
	    pipeToken = FinancialStatementRebalancingPipe;
	    pipeArgs = pipeArgs.split(':');
	    pipeArgs[1] = (pipeArgs[1].replace(/[^,a-zA-Z0-9]/g, '')).split(',');
	  }
	  let pipe = this.injector.get(pipeToken);
	  if (Array.isArray(pipeArgs)) {
	    if(nextPipeTokenStr != null){
	      const nextPipeToken = nextPipeTokenStr.split(':')[0];
	      const nextPipeTokenArg = nextPipeTokenStr.substr(nextPipeTokenStr.indexOf(":")+1);
	      return this.transform(pipe.transform(value, ...pipeArgs), nextPipeToken, nextPipeTokenArg);
	    }
	    return pipe.transform(value, ...pipeArgs);
	  } else {
	    if(nextPipeTokenStr != null){
	      const nextPipeToken = nextPipeTokenStr.split(':')[0];
	      const nextPipeTokenArg = nextPipeTokenStr.substr(nextPipeTokenStr.indexOf(":")+1);
	      return this.transform(pipe.transform(value, pipeArgs), nextPipeToken, nextPipeTokenArg);
	    }
	    return pipe.transform(value, pipeArgs);
	  }
    }
  }

  getDigitsInfo(decimalPlaces: number): string{
    return '1.' + decimalPlaces.toString() + '-' + decimalPlaces.toString()
  }

}
