import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthConstant} from 'src/app/constant';
import {Store} from '@ngxs/store';
import {AuthState} from '@auth-module/store';
import {ApiConstant} from '@api-module/api.endpoint.constant';
import {catchError, tap} from 'rxjs/operators';
import {RefreshTokenService} from '@auth-module/service/refresh-token.service';
import {AuthService} from '@auth-module/service/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store, private refreshTokenService: RefreshTokenService, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const header = {};
    const token: string = this.store.selectSnapshot(AuthState.getToken);

    if (token) {
      header[AuthConstant.X_AUTH_HEADER] = req?.headers?.get(AuthConstant.X_AUTH_HEADER) || token;
    }

    const request = req.clone({
      setHeaders: header
    })

    if (req.url.endsWith(ApiConstant.LOGOUT)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((err, _) => {
        if (err instanceof HttpErrorResponse) {
          switch(err.status) {
            case AuthConstant.UNAUTHORIZED:
              // refresh token
              return this.refreshTokenService.tryRefreshToken(err, request, next);
            case AuthConstant.FORBIDDEN_ERROR:
              return throwError(err).pipe(tap(() => this.authService.logout()));
            default:
              return throwError(err);
          }
        }
        else {
          return throwError(err);
        }
      })
    );
  }
}
