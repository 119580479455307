import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-bond-factsheet-important-note',
  styleUrls: ['./bond-factsheet-important-note.component.scss'],
  templateUrl: './bond-factsheet-important-note.component.html'
})

export class BondFactsheetImportantNoteComponent implements OnInit {

  ngOnInit(): void {
  }
  
  constructor() {
  }
}
 