import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AuthenticationRoutingModule } from './authentication.routing.module';
import { LogoutPageComponent } from './logout/logout.component'
import { AuthState } from './store';
import { SharedCommonModule } from 'src/app/share/common.module';
import {BasicCardModule} from '@share/ui-components/basic-card/basic-card.module';
import {UicHorizontalListItemModule} from '../uic/uic-horizontal-list-item/uic-horizontal-list-item.module';
import {UiMobileFullScreenModule} from '@share/ui-components/ui-mobile-full-screen/ui-mobile-full-screen.module';

@NgModule({
    imports: [
        AuthenticationRoutingModule,
        SharedCommonModule,
        NgxsModule.forFeature([AuthState]),
        BasicCardModule,
        UicHorizontalListItemModule,
        UiMobileFullScreenModule
    ],
    declarations: [
        LogoutPageComponent
    ],
    providers: []
})
export class AuthenticationModule {}
