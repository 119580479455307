import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { ITwoFaModel } from '@api-module/model/authority/i-two-fa.model';
import {AuthConstant} from "src/app/constant";


@Injectable({
  providedIn: 'root'
})
export class WebcastRestService {
  
  readonly BASE_URL: string = ApiConstant.WEBCAST;
  
  constructor(private http: HttpClient, private paramService: HttpParamsService) {}

  findWebcastList(quantity?:number) {
    const params = this.paramService.buildHttpParams({quantity});
    return this.http.post(this.BASE_URL + ApiConstant.FIND_WEBCAST_LIST, {}, {params});
  }

	findLatestWebcastList(quantity?:number) {
		const params = this.paramService.buildHttpParams({quantity});
		return this.http.post(this.BASE_URL + ApiConstant.FIND_LATEST_VIDEO_LIST,{},{params});
	}
	
	findMostViewedWebcastList(quantity?:number) {
		const params = this.paramService.buildHttpParams({quantity});
		return this.http.post(this.BASE_URL + ApiConstant.FIND_MOST_VIEWED_VIDEO_LIST,{},{params});
	}
	
	findVideoByCategorySubHeaderId(subHeaderId: number) {
		const params = this.paramService.buildHttpParams({subHeaderId});
		return this.http.post(this.BASE_URL + ApiConstant.FIND_VIDEO_BY_CATEGORY_SUB_HEADER_ID,{},{params});
	}
	
	findVideoByTags(iArticleSimpleCriteriaModel: any) {
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_VIDEO_BY_TAGS, iArticleSimpleCriteriaModel);
	}
	
	findSubHeaderName(){
		return this.http.post(this.BASE_URL + ApiConstant.FIND_SUB_HEADER_NAME,{});
	}
	
	getVideoDetails(articleno: string){
		const params = this.paramService.buildHttpParams({articleno});
		return this.http.post(this.BASE_URL + ApiConstant.GET_VIDEO_DETAILS,{},{params});
	}
	
}