/**
 * given a range, returns a random integer
 * @param min min integer
 * @param max max integer
 */
export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + 1) + min;
}