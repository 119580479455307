import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService } from '@api-module/service/common/util.service';
import { RouteConstant } from 'src/app/constant/route.constant';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
	selector: 'related-article-sticky-bar',
	templateUrl: './related-article-floating.component.html',
	styleUrls: ['./related-article-floating.component.scss'],
	providers: [UtilService]
})

export class RelatedArticleFloatingComponent {
	
	@Input() articleList: any[] = [];
	
	activePanel: boolean = false;
	link: string = '/' + RouteConstant.ARTICLE +'/' +RouteConstant.ARTICLE_DETAILS + '/';
	localeOption: string = "zh-TW";
	
	constructor(private utilService: UtilService,
									private globalDataStorage: GlobalDataStorage){
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}
	
	ngOnChanges(changes: SimpleChanges) {
		
	}
	
	changeActive(event) {
		this.activePanel = event;
	}
	
	encodeTitle(title: string): string {
		return title.replace(/[^\u4E00-\u9FFFp{L}a-zA-Z0-9]+/gi, "-");
	}
	
	
}