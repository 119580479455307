import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { IResponse } from '@api-module/model/common/i-response';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HoldingRestService {
  readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.HOLDING;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {}

	getAllAccountListForViewHoldingDetails(){
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ACCT_LIST_VIEW_HOLDING,{});
	}

  getAccountDetailHoldings(paramRefno: string,paramProduct: string) {
    const params = this.paramService.buildHttpParams({paramRefno,paramProduct});
    return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ACCOUNT_DETAIL_HOLDINGS,{}, {params});
  }

	getAccountHistoricalTransaction(object: any, refno?: string){
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_HISTORICAL_TRANSACTION,object,{params});
	}
	
	getAccountPendingTransaction(object: any, refno?: string){
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_PENDING_TRANSACTION,object,{params});
	}
	
	getPayoutHistory(paramRefno: string){
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_PAYOUT_HISTORY,{},{params});
	}
	
	getAllAccountHoldingsSummary(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ALL_ACCT_HOLDINGS_SUMMARY,{},{});
	}
	
	getClientHoldingsPerformance(paramStartDateMilSec: string){
		const params = this.paramService.buildHttpParams({paramStartDateMilSec});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_CLIENT_HOLDINGS_PERFORMANCE,{},{params});
	}
	
	getDpmsPerformanceForHoldingsComparison(paramStartDateMilSec: string, paramPortfolioId: string){
		const params = this.paramService.buildHttpParams({paramStartDateMilSec, paramPortfolioId});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_DPMS_PERFORMANCE_FOR_HOLD_COMPARE,{},{params});
	}
	
	getAllAcctHoldingsAssetAllocation(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_HOLD_ASSET_ALLOC,{},{});
	}
	
	getAllAcctHoldingsGeoAllocation(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_HOLD_GEO_ALLOC,{},{});
	}

	viewValuationReport(paramRefno: string, paramMonthSelected: string, paramYearSelected: string) {
		const params = this.paramService.buildHttpParams({paramRefno, paramMonthSelected, paramYearSelected});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.VIEW_VALUATION_REPORT, {}, {params});
	}

	getAccountProfitAndLossSummary(paramRefno: string, productList: string[]) {
  		const params = this.paramService.buildHttpParams({paramRefno, productList});
  		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ACCOUNT_PROFIT_AND_LOSS_SUMMARY, {}, {params});
	}

	getAccountDetailsForViewHoldingDetails(paramRefno: string): Observable<any> {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get(this.PROTECTED_BASE_URL + ApiConstant.GET_ACCOUNT_DETAILS_FOR_VIEW_HOLDING_DETAILS, {params});
	}

  	getHoldingsProdAllocation(paramRefno: string) {
		if(paramRefno && paramRefno!='all'){
		    const params = this.paramService.buildHttpParams({paramRefno});
		    return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_HOLDINGS_PROD_ALLOCATION,{}, {params});
		} else {
		    return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_HOLDINGS_PROD_ALLOCATION,{}, {});
		}
  	}

	getFundInvestmentList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_FUND_INVESTMENT_LIST, {}, {params});
	}

	getFundWithdrawalList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_FUND_WITHDRAWAL_LIST, {}, {params});
	}

	getFundDividendList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_FUND_DIVIDEND_LIST, {}, {params});
	}

	getFundSwitchList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_FUND_SWITCH_LIST, {}, {params});
	}

	findFundPendingBuyTransactions(paramRefno: string) {
  		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_FUND_PENDING_BUY_TRANSACTIONS_FOR_VOID, {params});
	}

	findFundPendingSellTransactions(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_FUND_PENDING_SELL_TRANSACTIONS_FOR_VOID, {params});
	}

	getBondInvestmentList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_BOND_INVESTMENT_LIST, {}, {params});
	}

	getBondWithdrawalList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_BOND_WITHDRAWAL_LIST, {}, {params});
	}

	getBondDividendList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_BOND_DIVIDEND_LIST, {}, {params});
	}

	getStockInvestmentList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_STOCK_INVESTMENT_LIST, {}, {params});
	}

	getStockWithdrawalList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_STOCK_WITHDRAWAL_LIST, {}, {params});
	}

	getStockDividendList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_STOCK_DIVIDEND_LIST, {}, {params});
	}

	getEtfInvestmentList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ETF_INVESTMENT_LIST, {}, {params});
	}

	getEtfWithdrawalList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ETF_WITHDRAWAL_LIST, {}, {params});
	}

	getEtfDividendList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ETF_DIVIDEND_LIST, {}, {params});
	}

	findUsEtfPending(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_US_ETF_PENDING, {params});
	}

	getPortfolioPnlList(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_PORTFOLIO_PNL_LIST, {}, {params});
	}

	findBondPendingBuyTransactions(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_BOND_PENDING_BUY_TRANSACTIONS, {params});
	}

	findBondPendingSellTransactions(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_BOND_PENDING_SELL_TRANSACTIONS, {params});
	}

	findBondReceivedBuyTransactions(paramRefno: string) {
  		const params = this.paramService.buildHttpParams({paramRefno});
  		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_BOND_RECEIVED_BUY_TRANSACTIONS, {params});
	}

	findBondReceivedSellTransactions(paramRefno: string) {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_BOND_RECEIVED_SELL_TRANSACTIONS, {params});
	}

	findPrelaunchBondSubsriptionPendingTransactions(paramRefno: string) {
  		const params = this.paramService.buildHttpParams({paramRefno});
  		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_PRELAUNCH_BOND_SUBSCRIPTION_PENDING_TRANSACTIONS, {params});
	}

	getAccountBondHoldings(paramRefno: string): Observable<any> {
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.get(this.PROTECTED_BASE_URL + ApiConstant.GET_ACCOUNT_BOND_HOLDINGS, {params});
	}
	
	voidTransaction(paramContractNo,paramProductType, paramRefno, paramTransactionStatus){
		const params = this.paramService.buildHttpParams({paramContractNo,paramProductType, paramRefno, paramTransactionStatus});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.VOID_TRANSACTION, {}, {params});
	}
	
	voidCashAccountTransaction(transactionId: string){
		const params = this.paramService.buildHttpParams({transactionId});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.VOID_CASH_ACCOUNT_TRANSACTION, {}, {params});
	}
	
	getTierTotalAum() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_TIER_TOTAL_AUM,{}, {});
	}
	
	getNextTierTotalAum() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_NEXT_TIER_TOTAL_AUM,{},{});
	}
	
	getAccountAutoSweepDetailsList() {
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_ACCOUNT_AUTO_SWEEP_DETAILS_LIST, {})
	}
}
