export function normaliseUrl(url: string): string {
  if (url === undefined || url === null || url?.length === 0) {
    return url;
  }

  let ret: string = '';
  ret = url.trim();
  ret = ret.toLowerCase();
  ret = ret.replace(/\(|\)|\&|\%|\/|\?|\./gm, '');
  ret = ret.replace('\'', '');
  ret = ret.trim();
  
  let segments = ret.split(' ');

  if (segments && segments.length > 0) {
    segments = segments.filter((segment: string) => {
      return segment && segment.length > 0 && segment !== '-'
    });
  }

  // SEO path recommmends url length need to be < 80
  return segments.join('-').slice(0, 70);
}