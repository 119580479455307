export enum IProductEnum {
  UNIT_TRUST = 'ut',
  DPMS = 'dpms',
  BOND = 'bond',
  INSURANCE = 'insurance',
  RSP = 'rsp',
  RDP = 'rdp',
  TRUST_ACCOUNT = 'trustAccount',
  TRUST_NOMINATION = 'trustNomination',
  STOCK_AND_ETF = 'stockAndEtf',
  STOCK = 'stock',
  ETF = 'etf',
  STOCK_UPPER_CASE = 'STOCK',
  ETF_UPPER_CASE = 'ETF',
}

export enum ICompanyAnnProductEnum{
  STOCK = 'STOCK',
  ETF = 'ETF'
}
