import { Directive, ElementRef, Renderer2 } from '@angular/core'
import { Store } from '@ngxs/store';
import { GlobalState } from 'src/app/core/store';

@Directive({
  selector: 'div [modalContentWrapper]'
})
export class ModalContentContainerDirective {
  constructor(private element: ElementRef, private renderer: Renderer2, private store: Store) {
    const nativeElement: any = this.element.nativeElement;

    this.renderer.addClass(nativeElement, 'overflow-y-auto');
    this.renderer.addClass(nativeElement, 'pb-3');

    const context = this.store.selectSnapshot(GlobalState.getViewportContext);

    this.renderer.setStyle(nativeElement, 'height', `${context.modalContentHeight * 0.80}px`);
  }
}
