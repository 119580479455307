import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import {Observable, of} from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { ITwoFaModel } from '@api-module/model/authority/i-two-fa.model';
import {AppConstant, AuthConstant} from "src/app/constant";

@Injectable({
  providedIn: 'root'
})
export class SecurityRestService {
  readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.SECURITY;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {}

  isModuleEnabled(module: string){
    const okResp:IResponse<Boolean> = {
      status: 'SUCCESS',
      data: true,
      message: null,
      errorMessage: null
    };
    if(AppConstant.MODULE_CHK_LEGACY.includes(module)){
      return of(okResp);
    } else {
      const params = this.paramService.buildHttpParams({module})
      return this.http.post<IResponse<Boolean>>(this.PROTECTED_BASE_URL + ApiConstant.IS_MODULE_ENABLED, {}, {params});
    }
  }

  getPresession(): Observable<object | Array<string>> {
	  return this.http.post(ApiConstant.SECURITY + ApiConstant.GET_PRESESSION , {});
  }

  requestOtpSmsByType(action:string,paramRefno:string): Observable<IResponse<String>> {
	  const params = this.paramService.buildHttpParams({ action,paramRefno});
	  return this.http.post<IResponse<String>>(ApiConstant.SECURITY+ ApiConstant.PROTECTED + ApiConstant.OTP+ ApiConstant.REQUEST , {},{params});
  }

  getAuthentication(token?: string): Observable<object | Array<string>>{
    let headers = new HttpHeaders();
    if (token != null) {
      headers = headers.set(AuthConstant.X_AUTH_HEADER, token);
    }
    return this.http.get(ApiConstant.SECURITY + ApiConstant.PROTECTED + ApiConstant.GET_AUTHENTICATION, {
      headers,
    });
  }

  requestPublicOtp(actionKey:string, telHp:string, telHpCountryCode:string): Observable<IResponse<ITwoFaModel>> {
    const params = this.paramService.buildHttpParams({ telHp, actionKey,  telHpCountryCode});
    return this.http.post<IResponse<ITwoFaModel>>(ApiConstant.SECURITY + ApiConstant.OTP + ApiConstant.PUBLIC + ApiConstant.REQUEST , {},{params});
  }

  authenticatePublicOtp(actionKey: string, e2eeRandom: string, encryptedOtp: string, telHp: string, telHpCountryCode: string): Observable<IResponse<ITwoFaModel>> {
    const params = this.paramService.buildHttpParams({ actionKey, e2eeRandom, encryptedOtp, telHp, telHpCountryCode});
    return this.http.post<IResponse<ITwoFaModel>>(ApiConstant.SECURITY + ApiConstant.OTP + ApiConstant.PUBLIC + ApiConstant.AUTHENTICATE , {},{params});
  }

  login(username: string, password: string) {
    const params = this.paramService.buildHttpParams({
      username,
      password
    });

    return this.http.post(ApiConstant.FSMONE_LOGIN, {}, { params });
  }

  logout() {
    return this.http.post(ApiConstant.BASE_API_URL + ApiConstant.LOGOUT, {});
  }

  resetPassword(newPassword: string) {
    const params = this.paramService.buildHttpParams({
        newPassword
    });

    return this.http.post(ApiConstant.LOGIN, {}, { params });
  }
	
	getFsmUrl(){
		return this.http.get(ApiConstant.BASE_API_URL + ApiConstant.SECURITY + "/get-fsm-url" , {});
	}
	
	getFsmRawUrl(){
		return this.http.get(ApiConstant.BASE_API_URL + ApiConstant.SECURITY + "/get-fsm-raw-url" , {});
	}
}