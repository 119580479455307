import { Component , Input } from '@angular/core';

@Component({
	selector: 'share-buttons-modal',
	styleUrls: ['./share.buttons.modal.scss'],
	templateUrl: './share.buttons.modal.html'
})

export class ShareButtonsModal {
	
	@Input() isVisible: boolean = false;
	
	@Input() url: string;
	
	@Input() description: string;
	
	@Input() include: string[] =['facebook','linkedin','telegram','twitter','whatsapp', 'email'];
	
	@Input() title: string;
	
	constructor(){}
	
}