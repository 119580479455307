import { RouteConstant } from '@constant';
import { genRouterLink } from '@util/route';
import { HeaderMetaModel } from 'src/app/share/models/header/header.model';

export const PostNewMenu: Array<HeaderMetaModel> = [
	{
		title: 'Setting',
		titleKey: 'fsmone.setting',
		url: '',
		clickable: true,
		active: false,
		icon: 'fsmicon-setting-o',
		activeIcon: 'fsmicon-setting',
		childrenExpanded: false,
		children: [
			{
				title: 'Activated Products',
				titleKey: 'fsmone.activated.products',
				url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.ACTIVATED_PRODUCTS),
				active: false
			},
			{
				title: 'Account Settings',
				titleKey: 'fsmone.account.settings',
				url: genRouterLink(RouteConstant.ACCOUNT_SETTING),
				active: false
			},
			{
				title: 'Account Updates',
				titleKey: 'fsmone.account.updates',
				url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.ACCOUNT_UPDATE),
				active: false
			},
			{
				title: 'Open Additional Account',
				titleKey: 'fsmone.open.addtional.account',
				url: genRouterLink(RouteConstant.ACCOUNT_OPENING),
				active: false
			},
			{
				title: 'Account Password',
				titleKey: 'fsmone.account.password',
				url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.LOGIN_AND_SECURITY,RouteConstant.CHANGE_ACCOUNT_PASSWORD),
				active: false
			},
			{
				title: 'Login & Security',
				titleKey: 'fsmone.login.&.security',
				url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.LOGIN_AND_SECURITY),
				active: false
			}
		]
	},
	{
		title: 'My Account',
		titleKey: 'fsmone.my.account',
		url: '',
		clickable: true,
		active: false,
		icon: 'fsmicon-acc-update-o',
		activeIcon: 'fsmicon-acc-update',
		childrenExpanded: false,
		children: [
			{
				title: 'Account Summary',
				titleKey: 'fsmone.account.summary',
				url: '/holdings',
				active: false,
				value: 'accountSummary',
			},
			{
				title: 'Investment Holdings',
				titleKey: 'fsmone.investment.holdings',
				url: '/holdings',
				active: false,
				value: 'investment',
			},
			{
				title: 'Cash Account',
				titleKey: 'fsmone.cash.account',
				url: '/holdings',
				active: false,
				value: 'cashAccount',
			},
			{
				title: 'FSMOne Auto-Sweep',
				titleKey: 'auto.sweep.b2c',
				url: '/account-summary/auto-sweep-enrolment',
				active: false
			},
			{
				title: 'Margin Summary',
				titleKey: 'fsmone.margin.summary',
				url: '/margin/margin-summary',
				active: false
			},
			{
				title: 'Regular Saving Plan',
				titleKey: 'fsmone.regular.saving.plan',
				url: '/holdings',
				active: false,
				value: 'myRsp',
			},
			{
				title: 'Transaction History',
				titleKey: 'fsmone.historical.transaction',
				url: '/transaction-record/historical',
				active: false,
			},
			{
				title: 'Processing/Pending Transaction',
				titleKey: 'fsmone.processing.pending.transaction',
				url: 'transaction-record/pending',
				active: false
			},
			{
				title: 'Monthly Statement',
				titleKey: 'fsmone.monthly.statement',
				url: '/monthly-statement',
				active: false
			},
			{
				title: 'DPMS Valuation Report',
				titleKey: 'fsmone.valuation.report',
				url: '/account-summary/dpms-valuation-report',
				active: false
			},
			
			
			// {
			// 	title: 'P&L Snapshot',
			// 	titleKey: 'profit.loss.snapshot',
			// 	url: '/holding-details/pl-snapshot',
			// 	active: false
			// }
		]
	},
	{
		title: 'Trade',
		titleKey: 'fsmone.trade',
		url: '',
		clickable: true,
		active: false,
		icon: 'fsmicon-trade-o',
		activeIcon: 'fsmicon-trade',
		childrenExpanded: false,
		children: [
			{
				title: 'Funds',
				titleKey: 'funds',
				url: '/trade/funds',
				active: false
			},
			{
				title: 'Bonds',
				titleKey: 'bonds',
				url: '/trade/bonds',
				active: false
			},
			{
				title: 'Managed Portfolios',
				titleKey: 'fsmone.managed.portfolios',
				url: '/trade/managed-portfolio',
				active: false
			},
			{
				title: 'Stocks & ETFs',
				titleKey: 'stock.and.etf',
				url: '/trade/stocks',
				active: false
			}
		]
	},
	{
		title: 'Cash Account',
		titleKey: 'fsmone.cash.account',
		url: '',
		clickable: true,
		icon: 'fsmicon-holding-o',
		activeIcon: 'fsmicon-holding',
		childrenExpanded: false,
		children: [
			{
				title: 'Electronic Direct Debit Authorization',
				titleKey: 'fsmone.electronic.direct.debit.authorization',
				url: '/trade/cash-account/edda-application',
				active: false
			},
			{
				title: 'Direct Debit Authorisation',
				titleKey: 'direct.debit.authorisation',
				url: '/trade/cash-account/dda-application',
				active: false
			},
			{
				title: 'Deposit with eDDA',
				titleKey: 'fsmone.deposti.with.edda',
				url: '/trade/cash-account/edda-deposit',
				active: false
			},
			{
				title: 'FPS',
				titleKey: 'fsmone.deposit.fps.payment',
				url: '/trade/cash-account/fps-qr-deposit',
				active: false
			},
			{
				title: 'Cash Transfer',
				titleKey: 'fsmone.cash.account.transfer',
				url: '/trade/cash-account/cash-transfer',
				active: false
			},
			{
				title: 'FX Conversion',
				titleKey: 'fsmone.fx.conversion',
				url: '/trade/cash-account/fx-conversion',
				active: false
			},
			{
				title: 'Upload Payment Proof',
				titleKey: 'fsmone.upload.payment.proof',
				url: '/trade/cash-account/upload-payment-proof',
				active: false
			},
			{
				title: 'Withdrawal',
				titleKey: 'withdrawal',
				url: '/trade/cash-account/withdrawal',
				active: false
			}
		]
	},
	{
		title: 'Watchlist and Alert',
		titleKey: 'fsmone.watchlist.and.alert',
		url: '/watchlist',
		clickable: false,
		active: false,
		icon: 'fsmicon-watchlist-o',
		activeIcon: 'fsmicon-watchlist'
	},
	{
		title: 'User Guide',
		titleKey: 'fsmone.menu.user.guide',
		url: '/support/client-support/user-guide',
		clickable: false,
		active: false,
		icon: 'fsmicon-guides-o',
		activeIcon: 'fsmicon-guides'
	},
	{
		title: 'Notification',
		titleKey: 'notification',
		url: '/notification/main',
		clickable: false,
		active: false,
		icon: 'fsmicon-bell-o',
		activeIcon: 'fsmicon-bell'
	}
]

export const MobilePostMenu: Array<HeaderMetaModel> = [
	{
		title: 'Discover',
		titleKey: 'discover',
		url: '/',
		clickable: false,
		active: false,
		icon: 'fsmicon-discover-o',
		activeIcon: 'fsmicon-discover'
	},
	{
		title: 'Watchlist',
		titleKey: 'watchlist',
		url: '/watchlist',
		clickable: false,
		active: false,
		icon: 'fsmicon-watchlist-o',
		activeIcon: 'fsmicon-watchlist'
	},
	{
		title: 'Trade',
		titleKey: 'fsmone.trade',
		url: '/trade',
		clickable: true,
		active: false,
		icon: 'fsmicon-trade-o',
		activeIcon: 'fsmicon-trade',
		childrenExpanded: false,
		children: [
			{
				title: 'Funds',
				titleKey: 'funds',
				url: '/trade/funds',
				clickable: true,
				active: false,
				children: [
					{
						titleKey: 'fsmone.buy.apply.rsp',
						url: '/trade/funds/buy',
						active: false,
						icon: 'fsmicon-buy-o',
					},
					{
						titleKey: 'fsmone.sell.switch',
						url: '/trade/funds/sell',
						active: false,
						icon: 'fsmicon-sell-switch-o'
					},
					{
						titleKey: 'fsmone.rsp.amendment.suspension',
						url: '/holdings',
						active: false,
						icon: 'fsmicon-rsp-o',
						value: 'myRsp'						
					},
					{
						titleKey: 'fsmone.transfer.in',
						url: '/trade/transfer-in-out/transfer-in-fund',
						active: false,
						icon: 'fsmicon-transfer-in'
					}
				]
			},
			{
				title: 'Bonds',
				titleKey: 'bonds',
				url: '/trade/bonds',
				clickable: true,
				active: false,
				children: [
					{
						titleKey: 'fsmone.prices',
						url: '/trade/bonds/prices',
						active: false,
						icon: 'fsmicon-buy-o'
					},
					{
						titleKey: 'fsmone.orderbook',
						url: '/trade/bonds/orderbook',
						active: false,
						icon: 'fsmicon-sell-switch-o'
					},
					{
						titleKey: 'fsmone.holdings',
						url: '/trade/bonds/holdings',
						active: false,
						icon: 'fsmicon-holding-o'
					},
					{
						titleKey: 'fsmone.ipo.subscription.bond',
						url: '/trade/ipo/current-ipos',
						active: false,
						icon: 'fsmicon-new-bond-o',
						value: 'BOND'
					},
					{
						titleKey: 'fsmone.bond.corporate.action',
						url: '/trade/bonds/corporate-action',
						active: false,
						icon: 'fsmicon-corp-action-o'
					},
					{
						titleKey: 'fsmone.transfer.in',
						url: '/trade/transfer-in-out/transfer-in-bond',
						active: false,
						icon: 'fsmicon-transfer-in'
					}
				]
			},
			{
				title: 'Managed Portfolios',
				titleKey: 'fsmone.managed.portfolios',
				url: '/trade/managed-portfolio',
				clickable: true,
				active: false,
				children: [
					{
						titleKey: 'fsmone.buy.or.top.up',
						url: '/trade/managed-portfolio/buy',
						active: false,
						icon: 'fsmicon-buy-o'
					},
					{
						titleKey: 'fsmone.termination.and.partial.redemption',
						url: '/trade/managed-portfolio/sell',
						active: false,
						icon: 'fsmicon-sell-switch-o'
					},
					{
						titleKey: 'fsmone.rsp.amendment.suspension',
						url: '/holdings',
						active: false,
						icon: 'fsmicon-rsp-o',
						value: 'myRsp'
					},
					{
						titleKey: 'fsmone.factsheets',
						url: '/trade/managed-portfolio/factsheet',
						active: false,
						icon: 'fsmicon-factsheet-o'
					}
				]
			},
			{
				title: 'Stocks & ETFs',
				titleKey: 'stock.and.etf',
				url: '/trade/stocks',
				clickable: true,
				active: false,
				children: [
					{
						titleKey: 'fsmone.quick.trade',
						url: '/trade/stocks/quick-trade',
						active: false,
						icon: 'fsmicon-quick-trade-o'
					},
					{
						titleKey: 'fsmone.prices',
						url: '/trade/stocks/prices',
						active: false,
						icon: 'fsmicon-buy-o'
					},
					{
						titleKey: 'fsmone.submitted-orders',
						url: '/trade/stocks/submitted-orders',
						active: false,
						icon: 'fsmicon-sell-switch-o'
					},
					{
						titleKey: 'fsmone.portfolio',
						url: '/trade/stocks/portfolio',
						active: false,
						icon: 'fsmicon-holding-o'
					},
					{
						titleKey: 'fsmone.ipo.subscription.stocks',
						url: '/trade/ipo/current-ipos',
						active: false,
						icon: 'fsmicon-new-fund',
						value: 'STOCK'
					},
					{
						titleKey: 'fsmone.transfer.in',
						url: '/trade/transfer-in-out/transfer-in-stock',
						active: false,
						icon: 'fsmicon-transfer-in'
					}
				]
			},
			{
				title: 'Cash Account',
				titleKey: 'fsmone.cash.account',
				url: '',
				clickable: true,
				icon: 'fsmicon-holding-o',
				activeIcon: 'fsmicon-holding',
				childrenExpanded: false,
				children: [
					{
						title: 'Electronic Direct Debit Authorization',
						titleKey: 'fsmone.electronic.direct.debit.authorization',
						url: '/trade/cash-account/edda-application',
						active: false,
						icon: 'fsmicon-fee-o'
					},
					{
						title: 'Direct Debit Authorisation',
						titleKey: 'direct.debit.authorisation',
						url: '/trade/cash-account/dda-application',
						active: false,
						icon: 'fsmicon-debit-card-o'
					},
					{
						title: 'Deposit with eDDA',
						titleKey: 'fsmone.deposti.with.edda',
						url: '/trade/cash-account/edda-deposit',
						active: false,
						icon: 'fsmicon-deposit-o'
					},
					{
						title: 'FPS',
						titleKey: 'fsmone.deposit.fps.payment',
						url: '/trade/cash-account/fps-qr-deposit',
						active: false,
						icon: 'fsmicon-buy-o'
					},
					{
						title: 'Cash Transfer',
						titleKey: 'fsmone.cash.account.transfer',
						url: '/trade/cash-account/cash-transfer',
						active: false,
						icon: 'fsmicon-cash-transfer-o'
					},
					{
						title: 'FX Conversion',
						titleKey: 'fsmone.fx.conversion',
						url: '/trade/cash-account/fx-conversion',
						active: false,
						icon: 'fsmicon-fx-o'
					},
					{
						title: 'Upload Payment Proof',
						titleKey: 'fsmone.upload.payment.proof',
						url: '/trade/cash-account/upload-payment-proof',
						active: false,
						icon: 'fsmicon-order-detail-o'
					},
					{
						title: 'Withdrawal',
						titleKey: 'withdrawal',
						url: '/trade/cash-account/withdrawal',
						active: false,
						icon: 'fsmicon-withdrawal-o'
					}
				]
			},
		]
	},
	{
		title: 'Holdings',
		titleKey: 'fsmone.holdings',
		url: '/holdings',
		clickable: false,
		active: false,
		icon: 'fsmicon-holding-o',
		activeIcon: 'fsmicon-holding',
		value: 'investment'
	},
	{
		title: 'Menu',
		titleKey: 'fsmone.menu',
		url: '',
		clickable: true,
		active: false,
		icon: 'fsmicon-menu-o',
		activeIcon: 'fsmicon-menu',
		childrenExpanded: false,
		children: [
			{
				title: 'Setting',
				titleKey: 'fsmone.setting',
				url: '',
				clickable: false,
				children: [
					{
						title: 'Activated Products',
						titleKey: 'fsmone.activated.products',
						url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.ACTIVATED_PRODUCTS),
						active: false,
						icon: 'fsmicon-activated-prod-o'
					},
					{
						title: 'Settings',
						titleKey: 'fsmone.account.settings',
						url: genRouterLink(RouteConstant.ACCOUNT_SETTING),
						active: false,
						icon: 'fsmicon-edit-personal-o'
					},
					{
						title: 'Account Updates',
						titleKey: 'fsmone.account.updates',
						url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.ACCOUNT_UPDATE),
						active: false,
						icon: 'fsmicon-acc-update-o'
					},
					{
						title: 'Open Additional Account',
						titleKey: 'fsmone.open.addtional.account',
						url: genRouterLink(RouteConstant.ACCOUNT_OPENING),
						active: false,
						icon: 'fsmicon-add-acc-o'
					},
					{
						title: 'Account Password',
						titleKey: 'fsmone.account.password',
						url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.LOGIN_AND_SECURITY,RouteConstant.CHANGE_ACCOUNT_PASSWORD),
						active: false,
						icon: 'fsmicon-lock-o'
					},
					{
						title: 'Login & Security',
						titleKey: 'fsmone.login.&.security',
						url: genRouterLink(RouteConstant.ACCOUNT_SETTING, RouteConstant.LOGIN_AND_SECURITY),
						active: false,
						icon: 'fsmicon-lock-o'
					}
					
				]
			},
			{
				title: 'My Account',
				titleKey: 'fsmone.my.account',
				url: '',
				clickable: false,
				children: [
					{
						title: 'Account Summary',
						titleKey: 'fsmone.account.summary',
						url: '/holdings',
						active: false,
						value: 'accountSummary',
						icon: ' fsmicon-account-reactivation-o',
						activeIcon: ' fsmicon-account-reactivation'
					},
					{
						title: 'Investment Holdings',
						titleKey: 'fsmone.investment.holdings',
						url: '/holdings',
						active: false,
						value: 'investment',
						icon: 'fsmicon-holding-o',
						activeIcon: 'fsmicon-holding'
					},
					{
						title: 'Cash Account',
						titleKey: 'fsmone.cash.account',
						url: '/holdings',
						active: false,
						value: 'cashAccount',
						icon: 'fsmicon-deposit-o',
						activeIcon: 'fsmicon-deposit'
					},
					{
						title: 'Regular Saving Plan',
						titleKey: 'fsmone.regular.saving.plan',
						url: '/holdings',
						active: false,
						value: 'myRsp',
						icon: 'fsmicon-rsp-o',
						activeIcon: 'fsmicon-rsp'
					},
					{
						title: 'Transaction History',
						titleKey: 'fsmone.historical.transaction',
						url: '/transaction-record/historical',
						active: false,
						icon: 'fsmicon-history-o',
						activeIcon: 'fsmicon-history'
					},
					{
						title: 'Processing/Pending Transaction',
						titleKey: 'fsmone.processing.pending.transaction',
						url: 'transaction-record/pending',
						active: false,
						icon: 'fsmicon-order-detail-o',
						activeIcon: 'fsmicon-order-detail'
					},
					{
						title: 'Monthly Statement',
						titleKey: 'fsmone.monthly.statement',
						url: '/monthly-statement',
						active: false,
						icon: 'fsmicon-statement-o',
						activeIcon: 'fsmicon-statement'
					},
					{
						title: 'DPMS Valuation Report',
						titleKey: 'fsmone.valuation.report',
						url: '/account-summary/dpms-valuation-report',
						active: false,
						icon: 'fsmicon-statement-o',
						activeIcon: 'fsmicon-statement'
					},
					{
						title: 'Margin Summary',
						titleKey: 'fsmone.margin.summary',
						url: '/margin/margin-summary',
						active: false,
						icon: 'fsmicon-statement-o',
						activeIcon: 'fsmicon-statement'
					},
					{
						title: 'Auto-Sweep Enrolment',
						titleKey: 'fsmone.auto.sweep.enrolment',
						url: '/account-summary/auto-sweep-enrolment',
						active: false,
						icon: 'fsmicon-auto-sweep-o',
						activeIcon: 'fsmicon-auto-sweep'
					},
					// {
					// 	title: 'P&L Snapshot',
					// 	titleKey: 'profit.loss.snapshot',
					// 	url: '/holding-details/pl-snapshot',
					// 	active: false,
					// 	icon: 'fsmicon-profit-loss',
					// 	activeIcon: 'fsmicon-profit-loss',
					// }
				]
			},
			{
				title: 'New to FSM',
				titleKey: 'new.to.fsm',
				url: '',
				clickable: false,
				children: [
					{
						title: 'User Guide',
						titleKey: 'fsmone.menu.user.guide',
						url: '/support/client-support/user-guide',
						icon: 'fsmicon-guides-o',
						activeIcon: 'fsmicon-guides',
						active: false
					}
				]
			},
			{
				title: 'Notification Centre',
				titleKey: 'notification.centre',
				url: '',
				clickable: false,
				children: [
					{
						title: 'Notification',
						titleKey: 'notification',
						url: '/notification/main',
						icon: 'fsmicon-bell-o',
						activeIcon: 'fsmicon-bell',
						active: false
					}
				]
			}
		]
	},
]
