import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilService } from "@api-module/service/common/util.service";
import { IInterestRateModel } from "@api-module/model/cash-account/i-interest-rate-info.model";
import { genRouterLink } from "@util/route";
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AuthState } from "@auth-module/store";
import { Store } from "@ngxs/store";
import { CashAccountService } from 'src/app/api/rest/cash-account.rest.service';
import { IResponse } from "@api-module/model/common/i-response";
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import {getEnv, goToCashAccFx} from '@share/services/product-search.service';

@Component({
  selector: 'app-fx-rate-table',
  templateUrl: './fx-table.component.html',
  providers: [UtilService]
})
export class FxRateTableComponent implements OnInit, OnChanges{
  isAuthenticated: boolean = this.store.selectSnapshot(AuthState.isAuthenticated);
  isModalVisible: boolean = false;
  BUTTON_ICON_IMAGES_PATH = 'assets/images/general/icons/';
  currency_list: string[] = [];
  liveFxRateMap:any;
  nonLiveFxRateMap:any;
  lastUpdatedTime:Date;
  isLoading : boolean = false;

  readonly watchListLink = genRouterLink(RouteConstant.APP, RouteConstant.WATCHLIST);
  localeOption:string = "zh-TW";

  constructor(private router: Router, private store: Store, private route: ActivatedRoute, private utilService: UtilService, private cashAccountService: CashAccountService,private globalDataStorage:GlobalDataStorage) {
  }

  ngOnInit(): void {
	  this.getFxRate();
    this.setLocale();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.interestRateMap){
      
    }
  }

 
  handleCancel(): void {
    //redirect to login page
    this.isModalVisible = false;
  }

  navigateToBondBuy(issueCode){
	
  }

  getFxRate(){
    this.isLoading = true;
    this.cashAccountService.findAllFxRate(true).subscribe((res: IResponse<any>) => {
        if (AppConstant.RESPONSE_SUCCESS === res.status && res.data!="") {
          this.currency_list = res.data.currencyList;
          this.liveFxRateMap = res.data.live;
          this.lastUpdatedTime = new Date();
        }
      }).add(()=>{
        this.isLoading = false;
      });
  }

  navigateToFx(){
    goToCashAccFx();
  }

  setLocale(){
	let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
  }
}
