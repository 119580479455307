import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "note",
    templateUrl: "./note.component.html",
    styleUrls: ["./note.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NoteComponent {
    
}
