import { Injectable } from '@angular/core';
import { HttpParamsService } from '../service/http-params.service';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { ISectorPerformanceModel } from '@api-module/model/funds/i-performance.model';
import { IFundManagerModel } from '@api-module/model/funds/i-fund-manager.model';
import { IFundInfoModel } from '@api-module/model/funds/i-fund-info-model';
import { IFundSelectorResultModel } from '@api-module/model/funds/i-fund-selector.result.model';
import { IDropdownModel } from '@api-module/model/common/i-dropdown.model';
import { ICashManagementModel } from '@api-module/model/funds/i-cash-management.model';
import { IFundHolidayModel } from '@api-module/model/funds/i-fund-holiday.model';
import { IPhCalendarBeanModel } from '@api-module/model/funds/i-ph-calendar-bean.model';
import { IDistributionHistoryModel } from '@api-module/model/funds/i-distribution-history.model';
import { ISwitchingCalculatorModel } from '@api-module/model/product/i-switching-calculator.model';
import { IMainsectorcodeLocModel } from '@api-module/model/funds/i-mainsectorcode-loc.model';
import { IFundSwitchingLagModel } from '@api-module/model/funds/i-fund-switching-lag.model';
import { IFundSelectorModel } from '@api-module/model/funds/i-fund-selector.model';

import { IFundSelectorResult } from '@api-module/model/funds/i-fund-selector-result.model';

@Injectable({
  providedIn: 'root'
})
export class FundRestService {
	readonly BASE_URL: string = ApiConstant.FUND;
  readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.FUND;
	readonly FEED_URL: string = "feed";

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }

  getFactsheet(paramSedolnumber: string) {
    const params = this.paramService.buildHttpParams({paramSedolnumber});

    return this.http.post(this.BASE_URL + ApiConstant.GET_FACTSHEET, {}, {params});
  }

  getPriceHistoryAdditionalInfo(paramSedolnumber: string, paramMonths: number) {
    const params = this.paramService.buildHttpParams({paramSedolnumber, paramMonths});

    return this.http.post(this.BASE_URL + ApiConstant.FIND_PRICE_HISTORY, {}, {params});
  }

  getFactsheetFundReturns(paramSedolnumber: string, paramFromDate: any, paramToDate: any) {
    const params = this.paramService.buildHttpParams({paramSedolnumber, paramFromDate, paramToDate});

    return this.http.post(this.BASE_URL + ApiConstant.FACTSHEET_FUND_RETURNS, {}, {params});
  }

  findFundDistributionHistory(paramSedolnumber: string) {
    const params = this.paramService.buildHttpParams({paramSedolnumber});

    return this.http.post(this.BASE_URL + ApiConstant.FIND_FUND_DISTRIBUTION_HISTORY, {}, {params});
  }

  findAllActiveFundHapeeningHeader() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_ALL_ACTIVE_FUND_HAPPENING_HEADER, {}, {});
  }

  findAllNonActiveFundHapeeningHeader() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_ALL_NON_ACTIVE_FUND_HAPPENING_HEADER, {}, {});
  }

  getFundFinderRequireData(risk: string): Observable<IResponse<ISectorPerformanceModel[]>> {
    const params = this.paramService.buildHttpParams({risk});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(
      this.BASE_URL + ApiConstant.GET_FUND_FINDER_DATA,
      {},
      {params}
    );
  }

  getFundHouseData(calculateTotalFunds: boolean, needPerformanceData: boolean) {
    const params = this.paramService.buildHttpParams({calculateTotalFunds, needPerformanceData});
    return this.http.post<IResponse<IFundManagerModel[]>>(
      this.BASE_URL + ApiConstant.GET_FUND_HOUSE_DATA,
      {},
      {params}
    );
  }

  getFundHouseDetailsData(paramManagercode: string): Observable<IResponse<IFundInfoModel[]>> {
    const params = this.paramService.buildHttpParams({paramManagercode});
    return this.http.post<IResponse<IFundInfoModel[]>>(
      this.BASE_URL + ApiConstant.GET_FUND_HOUSE_DETAILS_DATA,
      {},
      {params}
    );
  }

  findFundHappeningRegister(eventId: string, name: string) {
    const params = this.paramService.buildHttpParams({eventId, name});
    return this.http.post(this.BASE_URL + ApiConstant.FIND_FUND_HAPPENING_REGISTER, {}, {params});
  }

  validateAndCreateFundHappeningRegister(fundHappeningModel) {
    return this.http.post(
      this.BASE_URL + ApiConstant.VALIDATE_AND_CREATE_FUND_HAPPENING_REGISTER,
      fundHappeningModel
    );
  }

  getTotalInvestmentProduct() {
    return this.http.post(this.BASE_URL + ApiConstant.GET_TOTAL_INVESTMENT_PRODUCT, {});
  }

  getTotalTransactionAmount() {
    return this.http.post(this.BASE_URL + ApiConstant.GET_TOTAL_TRANSACTION_AMOUNT, {});
  }

  findForm(formType) {
    const params = this.paramService.buildHttpParams({formType});
    return this.http.post(this.BASE_URL + ApiConstant.TRANSACTION + ApiConstant.FORM + ApiConstant.READ, {}, {params});
  }

  requestForm(formType, email, name, contactNo, remark, mailingAddress, formRequestModelList) {
    const params = this.paramService.buildHttpParams({formType, email, name, contactNo, remark, mailingAddress});
    return this.http.post(this.BASE_URL + ApiConstant.TRANSACTION + ApiConstant.FORM + ApiConstant.REQUEST,
      formRequestModelList, {params});
  }

  requestBusinessReplyEnvolopes(enquiryFormModel) {
    return this.http.post(this.BASE_URL + ApiConstant.TRANSACTION + ApiConstant.BUSINESS_REPLY_ENVELOPES + ApiConstant.REQUEST,
      enquiryFormModel, {});
  }

  getFundHappeningHeader(eventId: string) {
    const params = this.paramService.buildHttpParams({eventId});
    return this.http.post(this.BASE_URL + ApiConstant.GET_FUND_HAPPENING_HEADER, {}, {params});
  }


  getTopVolumeFundRanking(period: string) {
    const params = this.paramService.buildHttpParams({period});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_TOP_VOLUME_FUND_RANKING,
      {},
      {params});
  }

  getBestPerformanceFundRanking(period: string, sectorMainCode: string) {
    const params = this.paramService.buildHttpParams({period, sectorMainCode});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_BEST_PERFORMANCE_FUND_RANKING,
      {},
      {params});
  }

  getWorstPerformanceFundRanking(period: string, sectorMainCode: string) {
    const params = this.paramService.buildHttpParams({period, sectorMainCode});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_WORST_PERFORMANCE_FUND_RANKING,
      {},
      {params});
  }

  getBidPriceChartData(paramId: string, paramPeriod: string): Observable<IResponse<Array<number[]>>> {
    const params = this.paramService.buildHttpParams({paramId, paramPeriod});
    return this.http.post<IResponse<Array<number[]>>>(this.BASE_URL + ApiConstant.GET_BID_PRICE_CHART_DATA,
      {},
      {params});

  }

  getTopPerformance(sectorMainCode?: string, isBestPerformance?:Boolean, targetPeriod?:string, limit?:number, chartPeriod?:string) {
    const params = this.paramService.buildHttpParams({sectorMainCode, isBestPerformance, targetPeriod, limit, chartPeriod});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_BEST_WORST_PERFORMANCE_FUNDS,
      {},
      {params});
  }

  getTopPerformanceFund(sectorMainCode?: string) {
    const params = this.paramService.buildHttpParams({sectorMainCode});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_BEST_PERFORMANCE,
      {},
      {params});
  }

  getWorstPerformance(sectorMainCode: string) {
    const params = this.paramService.buildHttpParams({sectorMainCode});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_WORST_PERFORMANCE,
      {},
      {params});
  }

  getTopVolume(period: string, chartPeriod:string, sector:string) {
    const params = this.paramService.buildHttpParams({period,chartPeriod,sector});
    return this.http.post<IResponse<ISectorPerformanceModel[]>>(this.BASE_URL + ApiConstant.GET_TOP_VOL_FUNDS,
      {},
      {params});
  }

  findFundSelectorOptions() {
    return this.http.post<IResponse<IFundInfoModel[]>>(this.BASE_URL + ApiConstant.FIND_FUND_SELECTOR_OPTIONS, {})
  }

  getFundSelectorResultAdditionalInfo(fundSelectorModel) {
    return this.http.post<IResponse<IFundSelectorResultModel[]>>(
      this.BASE_URL + ApiConstant.GET_FUND_SELECTOR_RESULT_ADDITIONAL_INFO,
      fundSelectorModel,
      {})
  }

  findPrsCategory(fundSelectorModel) {
    return this.http.post<IResponse<IFundSelectorResultModel[]>>(
      this.BASE_URL + ApiConstant.FIND_PRS_CATEGORY,
      fundSelectorModel,
      {})
  }

  getRecommendedFundList() {
    return this.http.post<IResponse<IFundSelectorResultModel[]>>(this.BASE_URL + ApiConstant.GET_RECOMMENDED_FUND_LIST, {})
  }

  findNewFunds() {
    return this.http.post<IResponse<IFundInfoModel[]>>(this.BASE_URL + ApiConstant.FIND_NEW_FUNDS, {});
  }

  getAllFundManager() {
    return this.http.post<IResponse<IFundManagerModel[]>>(this.BASE_URL + ApiConstant.GET_ALL_FUND_MANAGER, {});
  }

  getAllFundManagerDisplay() {
    return this.http.post<IResponse<IFundManagerModel[]>>(this.BASE_URL + ApiConstant.FIND_ALL_FUND_MANAGER, {});
  }

  getAllActiveFundListForChartCenter(): Observable<IResponse<Map<string, any[]>>> {
    return this.http.post<IResponse<Map<string, any[]>>>(
      this.BASE_URL + ApiConstant.GET_ACTIVE_FUND_LIST,
      {},
      {}
    );
  }

  getAllActiveFundList(): Observable<IResponse<IFundInfoModel[]>> {
    return this.http.post<IResponse<IFundInfoModel[]>>( this.BASE_URL + ApiConstant.GET_ALL_ACTIVE_FUND_LIST, {}, {});
  }

  getMainSectorList(paramAreasectorcode: string, paramSpecsectorcode: string) {
    const params = this.paramService.buildHttpParams({paramAreasectorcode, paramSpecsectorcode});
    return this.http.post<IResponse<IMainsectorcodeLocModel>>(
      this.BASE_URL + ApiConstant.GET_MAIN_SECTOR_LIST,
      {},
      {params}
    );
  }

  getSpecSectorList(paramMainsectorcode: string, paramAreasectorcode: string) {
    const params = this.paramService.buildHttpParams({paramMainsectorcode, paramAreasectorcode});
    return this.http.post<IResponse<IDropdownModel[]>>(
      this.BASE_URL + ApiConstant.GET_SPEC_SECTOR_LIST,
      {},
      {params}
    );
  }

  getGeoSectorList(paramMainsectorcode: string, paramSpecsectorcode: string) {
    const params = this.paramService.buildHttpParams({paramMainsectorcode, paramSpecsectorcode});
    return this.http.post<IResponse<IDropdownModel[]>>(
      this.BASE_URL + ApiConstant.GET_AREA_SECTOR_LIST,
      {},
      {params}
    );
  }

  getFundHousesList() {
    return this.http.post<IResponse<IDropdownModel[]>>(
      this.BASE_URL + ApiConstant.GET_FUND_HOUSES_LIST,
      {},
      {}
    );
  }

  getPRSList() {
    return this.http.post<IResponse<IDropdownModel[]>>(
      this.BASE_URL + ApiConstant.GET_PRS_LIST,
      {},
      {}
    );
  }

  getChartTableData(paramId: string, paramPeriod: string) {
    const params = this.paramService.buildHttpParams({paramId, paramPeriod});
    return this.http.post<IResponse<Array<number[]>>>(this.BASE_URL + ApiConstant.GET_CHART_TABLE_DATA,
      {},
      {params});

  }

  getBidPriceChartDataMovingAverage(paramId: string, paramPeriod: string) {
    const params = this.paramService.buildHttpParams({paramId, paramPeriod});
    return this.http.post<IResponse<Array<number[]>>>(this.BASE_URL + ApiConstant.GET_BID_PRICE_CHART_DATA_MOVING_AVERAGE,
      {},
      {params});

  }

  getCashManagementFundDetails() {
    return this.http.post<IResponse<ICashManagementModel>>(this.BASE_URL + ApiConstant.GET_CASH_MANAGEMENT_FUND_DETAILS, {});
  }

  getFundManagersAndFundsTotalList() {
    const params = this.paramService.buildHttpParams({calculateTotalFunds: true});
    return this.http.post<IResponse<IFundManagerModel[]>>(
      this.BASE_URL + ApiConstant.GET_FUND_MANAGERS_AND_FUNDS_TOTAL_LIST
      , {}
      , {params}
    );
  }

  findFundHolidays(managerCode,date) {
      const params = this.paramService.buildHttpParams({managercode: managerCode, date:date});
      return this.http.post<IResponse<IFundHolidayModel[]>>(
        this.BASE_URL + ApiConstant.FIND_FUND_HOLIDAYS,
        {},
        {params}
      );
  }

  findPublicHolidays() {
    return this.http.post<IResponse<IPhCalendarBeanModel[]>>(this.BASE_URL + ApiConstant.FIND_PUBLIC_HOLIDAYS, {});
  }

  findRecommendedFundsAndCategoryList() {
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_RECOMMENDED_FUNDS_AND_CATEGORY_LIST, {});
  }

  findDividendAnnouncement(managerCode: string, startDate: any, endDate: any) {
    const params = this.paramService.buildHttpParams({managerCode, startDate, endDate});
    return this.http.post<IResponse<IDistributionHistoryModel[]>>(this.BASE_URL + ApiConstant.FIND_DIVIDEND_ANNOUNCEMENTS,
      {},
      {params});
  }

  findLatestFundHappeningRegister() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_LATEST_FUND_HAPPENING_HEADER, {}, {});
  }

  getCashManagementFundInfo() {
    return this.http.post<IResponse<ICashManagementModel>>(this.BASE_URL + ApiConstant.GET_CASH_MANAGEMENT_FUND_INFO, {});
  }

  getDataFeedByType(mid) {
		const params = this.paramService.buildHttpParams({mid});
    	return this.http.post(this.FEED_URL + ApiConstant.GET_DATA_FEED, {},{params});
  }

  getMarketPerformanceTable() {
	return this.http.post(this.FEED_URL + ApiConstant.GET_DATA_MARKET_PERFORMANCE, {});
  }

  getFSMIndicesData() {
	return this.http.post(this.BASE_URL + ApiConstant.GET_FSM_INDICES_DATA, {});
  }

  getFundIndicesData() {
	return this.http.post(this.BASE_URL + ApiConstant.GET_FUND_INDICES_DATA, {});
  }


  getIndexFundInfo() {
    return this.http.post(this.BASE_URL + ApiConstant.GET_INDEX_FUND_INFO, {});
  }

  getFundInfo(sedolnumber:string) :Observable<IResponse<IFundInfoModel>>{
    const params = this.paramService.buildHttpParams({sedolnumber});
    return this.http.post<IResponse<IFundInfoModel>>(this.BASE_URL + ApiConstant.GET_FUND_INFO ,
      {},
      {params});
  }

  getIndexFundByCountryRegion() {
    return this.http.post(this.BASE_URL + ApiConstant.GET_INDEX_FUND_BY_COUNTRY_REGION, {});
  }

  getMarketIndicesData(sedolnumber: string) {
    const params = this.paramService.buildHttpParams({sedolnumber});
    return this.http.post(this.BASE_URL + ApiConstant.GET_MARKET_INDICES, {}, {params});
  }

  getFSMIndicesDetailsData(sedolnumber: string) {
    const params = this.paramService.buildHttpParams({sedolnumber});
    return this.http.post(this.BASE_URL + ApiConstant.GET_FSM_INDICES_DETAILS, {}, {params});
  }

  getFundBuyDetailInfo(refno:string, sedolnumber: string){
    const params = this.paramService.buildHttpParams({refno,sedolnumber});
    return this.http.post<IResponse<IFundInfoModel>>(this.BASE_URL + ApiConstant.PROTECTED + ApiConstant.DETAILS_INFO + ApiConstant.READ, {}, {params});
  }

  findAllActiveFunds() {
    return this.http.post<IResponse<IFundInfoModel[]>>(this.BASE_URL + ApiConstant.GET_ALL_FUND_LIST, {});
  }

  getMarketIndicesChartData(sedolnumber: string, period: string) {
    const params = this.paramService.buildHttpParams({sedolnumber, period});
    return this.http.post(this.BASE_URL + ApiConstant.GET_MARKET_INDICES_CHART, {}, {params});
  }

  getFundHappeningHeaderByArticleNo(articleNo: string) {
	 const params = this.paramService.buildHttpParams({articleNo});
	 return this.http.post(this.BASE_URL + ApiConstant.GET_FUND_HAPPENING_HEADER_BY_ARTICLE_NO+ApiConstant.READ, {}, {params});
  }
  
  findSwitchSellFunds() {
    return this.http.post<IResponse<IFundInfoModel[]>>(this.BASE_URL + ApiConstant.FIND_SWITCH_SELL_FUNDS, {})
  }

  findSwitchBuyFunds(sedolnumber: string, fundType: string) {
    const params = this.paramService.buildHttpParams({sedolnumber, fundType});
    return this.http.post<IResponse<IFundInfoModel[]>>(this.BASE_URL + ApiConstant.FIND_SWITCH_BUY_FUNDS, {},{params})
  }

  getSwitchingCalculatorResult(iSwitchingCalculatorModel:ISwitchingCalculatorModel) {
    return this.http.post<IResponse<ISwitchingCalculatorModel>>(this.BASE_URL + ApiConstant.SWITCHING_CALCULATOR_RESULT+ApiConstant.READ, iSwitchingCalculatorModel,{})
  }

  findDefaultCurrencyRate(currFrom:string,currTo:string) {
	const params = this.paramService.buildHttpParams({currFrom, currTo});
    return this.http.post<IResponse<Number>>(this.BASE_URL + ApiConstant.DEFAULT_CURRENCY_RATE+ApiConstant.READ, {},{params})
  }

	getFundRiskMappingMap() {
		return this.http.post(this.BASE_URL+ApiConstant.FUND_RISK_MAPPING,{});
	}
	
	findActiveFundCurrencyCodeList() {
		return this.http.post(this.BASE_URL+ApiConstant.FUND_CURRENCY_CODE_LIST,{});
	}
	
	getTop10HidhgestDividendFunds(homepage:boolean) {
		const params = this.paramService.buildHttpParams({homepage});
		return this.http.post(this.BASE_URL+ApiConstant.GET_TOP10_HIGHEST_DIVIDEND_FUNDS,{},{params});
	}
	
	getTop10HidhgestDividendFundsExcFi(homepage:boolean, excludeFIFund?: boolean) {
		const params = this.paramService.buildHttpParams({homepage, excludeFIFund});
		return this.http.post(this.BASE_URL+ApiConstant.GET_TOP10_HIGHEST_DIVIDEND_FUNDS_EXC_FI,{},{params});
	}
	
	findSingleCountrySectorReview() {
		return this.http.post(this.BASE_URL+ApiConstant.FIND_SINGLE_COUNTRY_SECTOR_REVIEW,{},{});
	}
	
	findRegionalMarketSectorReview() {
		return this.http.post(this.BASE_URL+ApiConstant.FIND_REGIONAL_MARKET_SECTOR_REVIEW,{},{});
	}

	getFundRestrictionCountry() {
    	return this.http.post<IResponse<IDropdownModel[]>>(this.BASE_URL + ApiConstant.GET_FUND_RESTRICTION_COUNTRY, {});
  	}

	findFundRestrictionResult(countryId:string) {
		const params = this.paramService.buildHttpParams({countryId});
    	return this.http.post<IResponse<IDropdownModel[]>>(this.BASE_URL + ApiConstant.FIND_FUND_RESTRICTION_RESULT, {},{params});
  	}

	getFundSwitchLagSellList() {
    	return this.http.post<IResponse<IDropdownModel[]>>(this.BASE_URL + ApiConstant.GET_FUND_SWITCH_LAG_SELL_LIST, {});
  	}

	getFundSwitchLagBuyList(targetId:string) {
		const params = this.paramService.buildHttpParams({targetId});
    	return this.http.post<IResponse<IDropdownModel[]>>(this.BASE_URL + ApiConstant.GET_FUND_SWITCH_LAG_BUY_LIST, {},{params});
  	}

	getFundSwitchLagResult(sellId:string,buyId:string) {
		const params = this.paramService.buildHttpParams({sellId,buyId});
    	return this.http.post<IResponse<IFundSwitchingLagModel>>(this.BASE_URL + ApiConstant.FIND_FUND_SWITCH_LAG_RESULT, {},{params});
  	}

	getFundSelectorResult(searchForm:IFundSelectorModel) {
		return this.http.post<IResponse<IFundSelectorResult>>(this.BASE_URL + ApiConstant.GET_FUND_SELECTOR_RESULTS,searchForm);
	}
	
	getDataFeedCharts(type) {
		const params = this.paramService.buildHttpParams({type});
		return this.http.post<IResponse<any>>(this.FEED_URL + ApiConstant.GET_DATA_FEED_CHARTS,{},{params});
	}
	
	getFSMDataFeedCharts(sedolnumber,type) {
		const params = this.paramService.buildHttpParams({sedolnumber,type});
		return this.http.post<IResponse<any>>(this.FEED_URL + ApiConstant.GET_FSM_DATA_FEED_CHARTS,{},{params});
	}
	
	getFundAssetClass(){
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.GET_FUND_ASSET_CLASS,{});
	}
	
	findFundByMainFilterCategoryMap(mainFilter){
		const params = this.paramService.buildHttpParams({mainFilter});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_FUND_BY_MAIN_FILTER,{},{params});
	}
	
	getFsmIndicesFactsheet(sedolnumber: string, chartPeriod: string) {
		const params = this.paramService.buildHttpParams({sedolnumber, chartPeriod});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_FSM_INDICES_FACTSHEET,{},{params});
		
	}
	
	getFundIndexFactsheet(sedolnumber: string, chartPeriod: string) {
		const params = this.paramService.buildHttpParams({sedolnumber, chartPeriod});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_FUND_INDEX_FACTSHEET,{},{params});
	}
	
	getFundsLoc(sedolnumber: string){
		const params = this.paramService.buildHttpParams({sedolnumber});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_FUNDS_LOC,{},{params});
	}
	
	getSectorPerformanceBySedolList(sedolList: string[]){
		const params = this.paramService.buildHttpParams({sedolList});
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_SECTOR_PERFORMANCE_BY_SEDOL_LIST,{},{params});
	}
	
	findExchangeHolidays(exchange?: string) {
		const params = this.paramService.buildHttpParams({exchange})
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.FIND_EXCHANGE_HOLIDAYS,{},{params});
	}
	
	getDistinctExchangeHolidayType(){
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_DISTINCT_EXCHANGE_HOLIDAY_TYPE,{});
	}
	
	getFundUpdateDate(){
		return this.http.post<IResponse<Date>>(this.BASE_URL+ApiConstant.GET_FUND_UPDATE_DATE,{});
	}

	findAllBuyableOrRspableProductId(accountNo: string){
      const params = this.paramService.buildHttpParams({accountNo});
      return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.FIND_ALL_BUYABLE_OR_RSPABLE_PRODUCT_ID, {}, {params});
    }

    getFundDetails(accountNo: string, productId: string){
        const params = this.paramService.buildHttpParams({accountNo, productId});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_FUND_DETAILS, {}, {params});
    }

    // getFundDetailsByIds(productIds: string[]){
    //     const params = this.paramService.buildHttpParams({productIds});
    //     return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_FUND_DETAILS_BY_IDS, {}, {params});
    // }

    getAmgLocList(){
        const params = this.paramService.buildHttpParams({});
        return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_AMG_LOC_LIST, {}, {params});
    }

    getAllBuyRspBuyList(paramRefno: string, paramAmg?: string){
        const params = this.paramService.buildHttpParams({paramRefno, paramAmg});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_ALL_BUY_RSP_FUND_LIST, {}, {params});
    }

    getBuyRspBuyList(search: any, paramRefno: string){
        const params = this.paramService.buildHttpParams({paramRefno});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_BUY_RSP_FUND_LIST, search, {params});
    }

    findAllSellSwitchFund(accountNo: string){
        const params = this.paramService.buildHttpParams({accountNo});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.FIND_ALL_SELL_SWITCH_FUND, {}, {params});
    }

    findAllSwitchableFund(accountNo: string, productId: string, productIds: string){
        const params = this.paramService.buildHttpParams({accountNo, productId,productIds});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.FIND_ALL_SWITCHABLE_FUND, {}, {params});
    }

    findSellSwitchFundByProductId(accountNo: string, productId: string){
        const params = this.paramService.buildHttpParams({accountNo, productId});
        return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.FIND_SELL_SWITCH_FUND_BY_PRODUCT_ID, {}, {params});
    }

    getSwitchTypeDetails(paramAccountNo, paramSellSedol, paramBuySedol, paramBuyDivOption){
      const params = this.paramService.buildHttpParams({paramAccountNo, paramSellSedol, paramBuySedol, paramBuyDivOption});
      return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_SWITCH_TYPE_DETAILS, {}, {params});
    }

    findAllSwitchableProductId(accountNo: string, productId: string){
      const params = this.paramService.buildHttpParams({accountNo, productId});
      return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.FIND_ALL_SWITCHABLE_PRODUCT_ID, {}, {params});
    }

    getMaxAmfRebateStatement(sedolnumber: string){
      const params = this.paramService.buildHttpParams({sedolnumber});
      return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_MAX_AMF_REBATE_STATEMENT, {}, {params});
    }
    getAllCurrencyMmfHighestYield(){
		return this.http.post<IResponse<any>>(this.BASE_URL+ApiConstant.GET_ALL_CURRENCY_MMF_HIGHEST_YIELD,{});
	}
	
	getBidPriceChartDataInPercentage(paramId: string, paramPeriod: string): Observable<IResponse<Array<number[]>>> {
    const params = this.paramService.buildHttpParams({paramId, paramPeriod});
    return this.http.post<IResponse<Array<number[]>>>(this.BASE_URL + ApiConstant.GET_BID_PRICE_CHART_DATA_IN_PERCENTAGE,
      {},
      {params});
  }
	
}
