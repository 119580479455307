import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({selector: '[moveNextByMaxLength]'})
export class AutoMoveNextByMaxlengthDirective {
  @Output() onPatchValueEvent = new EventEmitter();
  constructor(private _el: ElementRef) { }
  NUMBER_ARRAY: Array<string> = ['0','1','2','3','4','5','6','7','8','9'];

  @HostListener('input', ['$event']) onInput(e: any) {
    if ((e.target || e.srcElement).maxLength === (e.target || e.srcElement).value.length) {
      if(e.target.value.length && this.NUMBER_ARRAY.includes(e.data)){
        e.target.value = e.data;
        this.onPatchValueEvent.emit(e.data);
      }
      let nextControl: any = (e.target || e.srcElement).nextElementSibling;
      while (true) {
        if (nextControl) {
          if (nextControl.type === (e.target || e.srcElement).type
              && (e.target || e.srcElement).value
              && e.data !== 'Space'){
            nextControl.focus();
            return;
          } else {
            nextControl = nextControl.nextElementSibling;
          }
        } else { return; }
      }
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(e: any) {
    if (e.key === 'Backspace') {
      e.preventDefault();
      let prevControl: any = (e.target || e.srcElement).previousElementSibling;
      while (true) {
        if (prevControl) {
          if (prevControl.type === (e.target || e.srcElement).type) {
            prevControl.focus();
            prevControl.value = '';
            return;
          } else {
            prevControl = prevControl.nextElementSibling;
          }
        } else { return; }
      }
    }
  }
}
