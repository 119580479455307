<div id="readme-container" [ngStyle]="isCollapsed ? {'height': showTextHeight + 'px', 'overflow': 'hidden'} : {}">
  <ng-content></ng-content>
</div>
<div class="mt-3" *ngIf="isTextOverflow" [class.text-center]="position === 'center'">
  <ng-container *ngIf="isCollapsed; else readLessTemplate">
    <a (click)="onCollapsedClick()" class="text-primary font-weight-bold">
      <span class="mr-2">{{'fsmone.read.more'|translate}}</span><i nz-icon nzType="down" nzTheme="outline"></i>
    </a>
  </ng-container>
</div>

<ng-template #readLessTemplate>
  <a (click)="onCollapsedClick()" class="text-primary font-weight-bold">
    <span class="mr-2">{{'fsmone.close'|translate}}</span><i nz-icon nzType="up" nzTheme="outline"></i>
  </a>
</ng-template>
