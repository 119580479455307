import { 
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter
	} from '@angular/core';
import {genRouterLink} from '@util/route';
import {RouteConstant} from 'src/app/constant/route.constant';

@Component({
	selector: 'app-manager-list-common',
	templateUrl: './manager-list-common.component.html'
})

export class ManagerListCommonComponent implements OnInit {
	
	PRODUCTS_ICON_IMAGES_PATH = 'assets/images/general/icons/product/';
	selected: string;
	etfManager = genRouterLink(RouteConstant.ETFS,RouteConstant.ETF_INFO,RouteConstant.MANAGER_LISTING);
	fundManager = genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_HOUSE);
	
	ngOnInit(){
		this.selected = this.product;
	}
	readonly FUNDS: string = 'FUNDS';
    readonly ETF: string = 'ETF';
	@Input() product: string;
	@Output() navigate: EventEmitter<any> = new EventEmitter<any>();
	
	changeCategory(cat){
		this.selected = cat;
		switch(cat){
			case 'FUNDS':
				this.navigate.emit(this.fundManager);
				break;
			case 'ETF':
				this.navigate.emit(this.etfManager);
				break;
		}
  }

}
