<ng-container>
	<nz-row>
		<nz-col nzMd="12" nzSm="24">
			<nz-row>
				<nz-col nzMd="12" nzSm="24" >
					<nz-card [ngStyle]="activeCurrencyTab('hkd')" class="m-2 text-center" >
						<div >
<!-- 							<nz-row> -->
<!-- 								<span class="float-right"> -->
<!-- 									<i></i> -->
<!-- 								</span> -->
<!-- 							</nz-row> -->
							<nz-row>
								<b2c-ui-icon class="btn pointer" (click)="changeAsCurr('hkd')" name="hkFlagIcon"></b2c-ui-icon>
								<span class="float-right" nz-icon nzType="info-circle" nz-tooltip [nzTooltipTitle]="toolTipTemplate" nzTheme="outline"></span>
							</nz-row>
							<nz-row class="btn pointer" (click)="changeAsCurr('hkd')">
								<span>{{data['HKD'].portfolioName}}</span>
							</nz-row>
							<nz-row class="btn pointer" (click)="changeAsCurr('hkd')">
								<span>{{data['HKD'].annualisedReturn}}{{'fsmone.percent.p.a'|translate}}</span>
							</nz-row>
							</div>
					</nz-card>
				</nz-col>
				<nz-col nzMd="12" nzSm="24">
					<nz-card [ngStyle]="activeCurrencyTab('usd')" class="m-2 text-center" (click)="changeAsCurr('usd')"  >
						<div >
<!-- 							<nz-row> -->
<!-- 								<span class="float-right"> -->
<!-- 									<i></i> -->
<!-- 								</span> -->
<!-- 							</nz-row> -->
							<nz-row>
								<b2c-ui-icon class="btn pointer" (click)="changeAsCurr('usd')" name="usFlagIcon"></b2c-ui-icon>
								<span class="float-right" nz-icon nzType="info-circle" nz-tooltip [nzTooltipTitle]="toolTipTemplate" nzTheme="outline"></span>
							</nz-row>
							<nz-row class="btn pointer" (click)="changeAsCurr('usd')">
								<span>{{data['USD'].portfolioName}}</span>
							</nz-row>
							<nz-row class="btn pointer" (click)="changeAsCurr('usd')">
								<span>{{data['USD'].annualisedReturn}}{{'fsmone.percent.p.a'|translate}}</span>
							</nz-row>
							</div>
					</nz-card>
				</nz-col>
			</nz-row>
			
			<form nz-form [formGroup]="form">
				<nz-row >
					<nz-col nzSpan="24">
						<span translate="fsmone.cash.deposit.amount"></span>
					</nz-col>
					<nz-col nzSpan="24">
						<nz-form-item>
							<nz-form-control>
								<ui-input-group>
									<input type="number" ui-input [formControl]="depositAmount" [step]="0.01" min="0" (blur)="onBlurDepositAmount($event)">
								</ui-input-group>
							</nz-form-control>
						</nz-form-item>
					</nz-col>
					<nz-col nzSpan="24">
						<span translate="fsmone.dpms.period.time" translate-default="Period"></span>&nbsp;{{getPunctuation('left')}}<span translate="month" translate-default="Months"></span>{{getPunctuation('right')}}
					</nz-col>
					<nz-col nzSpan="24">
						<nz-form-item>
							<nz-form-control>
								<ui-select [formControl]="period">
								
									<ui-option *ngFor="let option of monthOptions" nzLabel="{{option}} {{'fsmone.bonds.yield.curve.month'|translate}}" [nzValue]="option">
									</ui-option>
								</ui-select>
<!-- 								<ui-input-group> -->
<!-- 									<input type="number" ui-input [formControl]="depositAmount" [step]="0.01" min="0" (ngModelChange)="changeDepositAmount($event)"> -->
<!-- 								</ui-input-group> -->
							</nz-form-control>
						</nz-form-item>
					</nz-col>
				</nz-row>
			</form>
			
		</nz-col>
		
<!-- 		<nz-col nzMd="12" nzSm="24"> -->
<!-- 			<ui-pie-chart *ngIf="!calculating" [nzSimplifiedView]="true" [nzAllocationDataList]="pieChartData" [nzMarginView]="true"></ui-pie-chart> -->
<!-- 		</nz-col> -->
		<nz-col nzMd="12" nzSm="24">
			<as-calculator-pie-chart *ngIf="!calculating" [nzAllocationDataList]="pieChartData" ></as-calculator-pie-chart>
		</nz-col>
	</nz-row>
</ng-container>

<ng-template #toolTipTemplate>
<span translate="fsmone.updated.as.of"></span>
{{ updateDate | hkDate}}
<span translate="fsmone.auto.sweep.updated.as.of.desc"></span>
</ng-template>