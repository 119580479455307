import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant, RouteConstant } from '../../constant';
import { tap } from 'rxjs/operators';
import { getEnv } from '@share/services/product-search.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthState, LoginSuccess } from '@auth-module/store';
import { Store } from '@ngxs/store';
import { AuthService } from '@auth-module/service/auth.service';
import { SyncAuthService} from '@auth-module/service/sync-auth.service';
import { NzModalService} from "ng-zorro-antd";
import { TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss']
})
export class LoginPageComponent implements OnInit {
	
	LOGIN_IMG_PATH = 'assets/images/login/';
	isDev:boolean = false;
	currentEnv:string = ' [TEST]';

	errorMessage: any = null;
 	loginForm: FormGroup;
	passwordVisible = false;
	auth: boolean = false;
	currentTime: string;
	isLoading: boolean = false;
	
	constructor(private authService: AuthService,
				private route: ActivatedRoute,
   				private router: Router,
				private store: Store,
				private fb: FormBuilder,
				private syncAuthService: SyncAuthService,
				private modalService: NzModalService,
    			private translate: TranslateService				
				) {
		if (window.location.href.indexOf('hktrial')>-1 || window.location.href.indexOf('hktest')>-1 || window.location.href.indexOf('localhost')>-1) {
			this.isDev = true;
			if (window.location.href.indexOf('hktrial')>-1) {
				this.currentEnv = ' [TRIAL]';
			}
		}
		this.route.queryParams.subscribe(params => {
			if (params['redirectTo']) {
				this.authService.redirectTo = params['redirectTo'];
			} else {
				this.authService.redirectTo = null;
			}
		});	
	}

	readonly storedUsername: string = this.syncAuthService.getUsername();

	ngOnInit(): void {
		this.setLoginBackground();
		this.buildForm();
		this.store.select(AuthState.isAuthenticated).pipe(
			tap((auth: boolean) => this.auth = auth)
		).subscribe();
	}

	setLoginBackground() {
		var currentTime = new Date().getTime();
		var morningStartTime = new Date().setHours(5, 0, 0);
		var afternoonStartTime = new Date().setHours(12, 0, 0);
		var eveningStartTime = new Date().setHours(17, 0, 0);
		var nightStartTime = new Date().setHours(21, 0, 0);
		if (currentTime >= morningStartTime && currentTime < afternoonStartTime) {
			this.currentTime = 'morning';
		} else if (currentTime >= afternoonStartTime && currentTime < eveningStartTime) {
			this.currentTime = 'afternoon';
		} else if (currentTime >= eveningStartTime && currentTime < nightStartTime) {
			this.currentTime = 'evening';
		} else {
			this.currentTime = 'night';
		}
	}

	buildForm() {
		this.loginForm = this.fb.group
		({
			clientName: [null, Validators.required],
			clientPassword: [null, Validators.required]
	    });
	}
	
	submit() {
    	this.loginForm.markAllAsTouched();
		if (this.loginForm.valid) {
			this.errorMessage = null;
			this.isLoading = true;
			let userName = this.loginForm.get('clientName')?.value;
			let userPass = this.loginForm.get('clientPassword')?.value;
			
            if (this.isDev) 
			{
                if (userName.indexOf(this.authService.TEST_PREFIX) !== 0) 
				{
		      		this.modalService.error({nzTitle: this.translate.instant('fsmone.error'), nzContent: this.translate.instant('fsmone.missing.test.prefix'), nzOkType: 'theme'});
                    this.isLoading = false;
					return;
                } 
				else
				{
                    userName = userName.substring(this.authService.TEST_PREFIX.length);
                }
            }			
			
			this.authService.login(userName, userPass).pipe(
				tap((res: any) => {
					if (res && res.status != AppConstant.RESPONSE_SUCCESS) {
						if(res.errorMessage){
							if(res.errorMessage =="invalidPassword" || res.errorMessage =="accountLocked" || res.errorMessage =="dormant" || res.errorMessage =="notApproved" || res.errorMessage =="contactCs"){
								this.errorMessage =  res.errorMessage;
							}else{
								this.errorMessage =  "systemError";
							}
						}else if(res.data.message){
							if(res.data.message =="invalidPassword" || res.data.message =="accountLocked" || res.data.message =="dormant" || res.data.message =="notApproved" || res.data.message =="contactCs"){
								this.errorMessage =  res.data.message;
							}else{
								this.errorMessage =  "systemError";
							}
						}
					}
					this.isLoading = false;
				})
			).subscribe();
		}
	}

	capitalize(event, formControl) {
		if (event) {
			const key = event.toUpperCase();
			if (key !== this.loginForm.controls[formControl].value) {
				this.loginForm.patchValue({
                  [formControl]: key
				});
			}
		}
	}

	goToHomepage() {
		this.router.navigate(['/']);
	}

	navigateByUrl(action:string) {
		let fullUrl = getEnv() + '/fsm';
		if(action == 'forgetID') 
		{
			fullUrl = fullUrl + '/account/forget-login-id';
		} 
		else if (action == 'forgetPassword') 
		{
			fullUrl = fullUrl + '/account/forget-password';
		}
		else if (action == 'accountOpening') 
		{
			fullUrl = fullUrl + '/account-opening/main';
		} 
		else if (action == 'viewHoldings') 
		{
			fullUrl = fullUrl + '/holdings/investment';
		} 
		else if (action == 'cashIn') 
		{
			fullUrl = fullUrl + '/cash-account/cash-account-deposit';
		} 
		else if (action == 'accountUpdate') 
		{
			fullUrl = fullUrl + '/accountupdate/account-update-menu/';
		}
		else if (action == 'corpFirstTimeLogin') 
		{
			fullUrl = fullUrl + '/account/corp-first-time-login';
		}

		window.location.assign(fullUrl);
	}

	navigateForgotService(action: string) {
		if (action == 'forgetID') {
			this.router.navigate([RouteConstant.FORGOT_USERNAME])
		} else if (action == 'forgetPassword') {
			this.router.navigate([RouteConstant.FORGOT_PASSWORD])
		} else if (action == 'corpFirstTimeLogin') {
			this.router.navigate([RouteConstant.CORP_FIRST_LOGIN]);
		}
	}
}
