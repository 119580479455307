import {Injectable} from '@angular/core';
import {HttpParamsService} from '../service/http-params.service';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@api-module/api.endpoint.constant';
import {Observable} from "rxjs";
import {IResponse} from "@api-module/model/common/i-response";
import {IStockSearchModel} from "@api-module/model/stock/i-stock-search.model";
import {ITickerInfoModel} from "@api-module/model/stock/i-ticker-info.model";
import {IActiveGainerLoserModel} from "@api-module/model/stock/i-active-gainer-loser.model";
import {IPageableModel} from "@api-module/model/common/i-pageable.model";
import {ITickerDistributionSetupDetailModel} from "@api-module/model/stock/i-ticker-distribution-setup-detail.model";

@Injectable({
  providedIn: 'root'
})
export class StocksInfoRestService {
  readonly BASE_URL: string = ApiConstant.STOCKS;
  readonly BASE_URL_STOCK_INFO: string = this.BASE_URL + ApiConstant.STOCK_INFO;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }

  getStockQuote(paramTickerNo) {
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.get<IResponse<any>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_GET_PRICE_QUOTE, {params});
  }

  getStockQuoteByCodeExchange(paramStockCode, paramExchange) {
    const params = this.paramService.buildHttpParams({paramStockCode, paramExchange});
    return this.http.get<IResponse<any>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_GET_PRICE_QUOTE, {params});
  }

  getStockTickerInfo(tickerNo) {
    const params = this.paramService.buildHttpParams({tickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL + ApiConstant.STOCKS_TICKER_INFO + ApiConstant.READ, {}, {params});
  }

  getTickerPriceByStockCode(exchange, stockCode) {
    const params = this.paramService.buildHttpParams({exchange, stockCode});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_TICKER_PRICE_BY_STOCK_CODE, {}, {params});
  }

  getFeeDetails(tickerNo) {
    const params = this.paramService.buildHttpParams({tickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_FEE_DETAILS, {}, {params});
  }

  getStockCalculatorResult(tickerNo, transactionType, quantity, price) {
    const params = this.paramService.buildHttpParams({tickerNo, transactionType, quantity, price});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_GET_CALCULATOR_RESULT, {}, {params});
  }

  getStockFactsheetDetail(paramStockCode, paramExchange){
    const params = this.paramService.buildHttpParams({paramStockCode, paramExchange});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_GET_STOCK_FACTSHEET_DETAIL, {params});
  }

  getValuationRatio(paramStockCode, paramExchange){
    const params = this.paramService.buildHttpParams({paramStockCode, paramExchange});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_VALUATION_RATIO, {params});
  }

  getChartData(paramTickerNo, paramFrom, paramTo, paramChartType){
    const params = this.paramService.buildHttpParams({paramTickerNo, paramFrom, paramTo, paramChartType});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_GET_CHART_DATA,{}, {params});
  }

  getInstitutionalHoldingsDetailByTickerNo(paramTickerNo){
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_INSTITUTIONAL_HOLDING_DETAILS, {}, {params});
  }

  getFundHoldingsDetailByTickerNo(paramTickerNo){
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_FUND_HOLDING_DETAILS, {}, {params});
  }

  getFinancialTemplateMappingByExchange(exchange, stockCode){
    const params = this.paramService.buildHttpParams({exchange, stockCode});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_FINANCIAL_TEMPLATE_CONFIG, {params});
  }

  getFinancialInformationData(tickerNo){
    const params = this.paramService.buildHttpParams({tickerNo});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_GET_FINANCIAL_INFORMATION_DATA, {params});
  }

  getTenYearGovernmentBondYieldPercentByCountry(countryCode){
    const params = this.paramService.buildHttpParams({countryCode});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_10_YEAR_GOVERNMENT_BOND_YIELD_PERCENT, {params});
  }

  getReturnStatisticsByTickerNo(paramTickerNo){
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCKS_RETURN_STATISTICS, {params});
  }

  getDividendDataV2ByTickerNo(tickerNo){
    const params = this.paramService.buildHttpParams({tickerNo});
    return this.http.post<IResponse<any>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_DIVIDEND_DATA_V2,{}, {params});
  }

  getCompanyGeneralInformation(paramTickerNo){
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_COMPANY_GENERAL_INFORMATION, {}, {params});
  }

  getBusinessDescription(paramTickerNo){
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_BUSINESS_DESCRIPTION, {}, {params});
  }

  getIncomeStatement(paramTickerNo){
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_INCOME_STATEMENT, {}, {params});
  }

  getDefaultStockList(type: string): Observable<any> {
    const params = this.paramService.buildHttpParams({type});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_DEFAULT_STOCK_LIST, {params});
  }

  getTaChartBars(tickerNo: string, from: string, to: string, resolution: string, countBack: string, unadjusted: string): Observable<any> {
    const params = this.paramService.buildHttpParams({tickerNo, from, to, resolution, unadjusted, countBack});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_BARS, {params});
  }

  getRealizedPnlDetails(refno: string, stockCode: string, startDate?: string, endDate?: string): Observable<any> {
    const params = this.paramService.buildHttpParams({refno, stockCode, startDate, endDate});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_REALIZED_PNL_DETAILS, {}, {params});
  }

  getSearchStocksByKeywordFromTaChart(searchQuery: string, maxResult: string): Observable<any> {
    const params = this.paramService.buildHttpParams({searchQuery, maxResult});
    return this.http.get<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_SEARCH_FROM_TA_CHART, {params});
  }

  getTaChartBarUpdate(tickerNo: string, exchange: string, resolution: string, isLive: string, unadjusted: string): Observable<any> {
    const params = this.paramService.buildHttpParams({tickerNo, exchange, isLive, resolution, unadjusted});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_UPDATE, {}, {params});
  }

  getSymbolInfoDetails(paramTickerNo): Observable<any> {
    const params = this.paramService.buildHttpParams({paramTickerNo});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_SYMBOL_INFO_DETAILS, {}, {params});
  }

  findPriceSpread(paramStockCode: string, paramExchange: string): Observable<any> {
    const params = this.paramService.buildHttpParams({paramStockCode,paramExchange});
    return this.http.post<IResponse<ITickerInfoModel>>(this.BASE_URL_STOCK_INFO + ApiConstant.STOCK_GET_PRICE_SPREAD, {}, {params});
  }
}
