<nz-card class="h-100" nzSize="small">
  <div *ngIf="fundRiskRating" class="text-right mb-2">
    <nz-tag class="bg-white mb-0"><i nz-icon nzType="warning" nzTheme="fill"
        [ngClass]="[fundRiskRatingClass]"></i><span class="ml-1">{{'risk.rating' | hkTranslate:'Risk Rating'}} :
        <ng-container *nzStringTemplateOutlet="fundRiskRating">{{fundRiskRating}}</ng-container></span></nz-tag>
  </div>
  <ng-container *ngIf="highLight">
    <h2 *ngIf="(isNumberValue(highLight) && (productType.class !== 'stock' && productType.class !== 'etf')) || fundRiskRating"
        class="font-weight-normal mb-0 pb-3" [ngClass]="performanceColor && highLight !== 999999 ? (highLight | pnl:'text') : ''"
    >
      <span *ngIf="highLightPrefix  && highLight !== 999999">
        <ng-container *nzStringTemplateOutlet="highLightPrefix">{{ highLightPrefix }}</ng-container>
      </span>
      <span>{{ highLight === 999999 ? '-' : (highLight | number: '1.2-2') }}</span>
      <span *ngIf="highLightSuffix && highLight !== 999999">
        <ng-container *nzStringTemplateOutlet="highLightSuffix">{{ highLightSuffix }}</ng-container>
      </span>
    </h2>
  </ng-container>
	
  <span class="text-sm font-weight-normal mb-0" *ngIf="highlightIndicator">
    <ng-container *nzStringTemplateOutlet="highlightIndicator">{{ highlightIndicator }}</ng-container>
  </span>
  <div *ngIf="productName" [ngClass]="{'d-flex justify-content-between': watchlist, 'mt-2': productName}">
    <span class="font-weight-bold" (click)="emitProductNameEvent()" role="button">
      <ng-container *nzStringTemplateOutlet="productName">{{ productName }} </ng-container>
    </span>
    <!-- <ng-template #productNameLink>
        <a external [link]="'//' + productNameUrl" class="font-weight-bold text-black-7">
          <ng-container *nzStringTemplateOutlet="productName">{{ productName }} </ng-container>
        </a>
    </ng-template> -->
    <a (click)="goToWatchList()" class="product-card-watchlist" *ngIf="watchlist" nz-tooltip
       [nzTooltipTitle]="'Add to watchlist'">
      <b2c-ui-icon
        name="addToWatchlistIcon">
      </b2c-ui-icon>
    </a>
  </div>
  <div class="text-sm font-weight-normal mt-1" *ngIf="productSubtitle">
    <ng-container *nzStringTemplateOutlet="productSubtitle">{{ productSubtitle }}</ng-container>
  </div>
  <ng-content></ng-content>
  <div class="mt-4" *ngIf="isNumberValue(productChange)" [ngClass]="performanceColor ? (productChange | pnl:'text') : ''">
    <i nz-icon [nzType]="productChange | pnl:'caret'" [nzTheme]="'fill'"></i>
    <span>{{ productChange }} </span>
    <span *ngIf="productChangeSuffix">
      <ng-container *nzStringTemplateOutlet="productChangeSuffix">{{ productChangeSuffix }} </ng-container>
    </span>
  </div>
  <div *ngIf="isNumberValue(productVolume)">
    Vol: {{ productVolume | number:'0.2'}}
  </div>
  <!-- <div class="d-flex align-items-end mt-3 justify-content-between" *ngIf="productRanking > 0 || buyButton">
    <div>
      <button *ngIf="buyButton" (click)="buy.emit()" class="btn-buy mb-2" [ngClass]="{'mt-4': !productRanking > 0}"
              nz-button nzType="success"
              nzSize="small">Buy
      </button>
    </div>
    <div class="b2c-ranking-container">
      <div class="b2c-ranking">
        <h2 class="mb-0 font-weight-bold b2c-ranking-num">{{ productRanking }}</h2>
        <b2c-ui-icon name="rankingBgIcon"></b2c-ui-icon>
      </div>
    </div>
  </div> -->
  <nz-tag *ngIf="productType.name" class="b2c-product-card-tag product-type" [ngClass]="'bg-' + productType.class">
    {{ productType.name | translate}}
  </nz-tag>
  <!-- <b2c-fund-risk-rating
    *ngIf="productTag"
    class="b2c-product-card-risk-rating"
    [currency]="'MY'"
    [rating]="productTag"
    [fundType]="'balanced'"
  >
  </b2c-fund-risk-rating> -->
  <div *ngIf="insuranceDesc">
    <hr class="p-2">
    <span class="text-sm">
      <ng-container *nzStringTemplateOutlet="insuranceDesc">{{ insuranceDesc }} </ng-container>
    </span>
  </div>
</nz-card>

