import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import {DateConstant} from '@constant';

@Pipe({ name: 'hkDate' })
export class HkDateFormatPipe implements PipeTransform {

	transform(value: any,locale?:string,dateFormat?:string) {
		let _locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : 'ch';
    if (!value || value=='-') {
			return '-';
    }
		
		if(locale == null){
			if ("en" === _locale) {
            locale = "en-US";
        } else if ("ch" === _locale) {
            locale = "zh-TW";
        } else if ("zh" === _locale) {
            locale = "zh-CN";
        }
		}
		
		
		let calFormat:string = dateFormat?dateFormat:'mediumDate';
		
		value = DateConstant.bypassTimeZone(new Date(value));
		
  	return formatDate(value, calFormat, locale);
  }
}