export class RouteConstant {
  public static readonly APP: string = 'app';
  public static readonly PUBLIC: string = 'public';
  public static readonly SUPPORT: string = 'support';
  public static readonly CONTACT_US: string = 'contact-us';
  public static readonly FAQ: string = 'frequently-asked-questions';
  public static readonly ENQUIRY: string = 'enquiry';
  public static readonly APPOINTMENT: string = 'appointment';
  public static readonly GET_INVESTMENT_ADVICE: string = 'get-investment-advice';
  public static readonly LOGIN: string = 'login';
  public static readonly TOKEN_LOGIN: string = 'token-login';
  public static readonly MOBILE_APP_LOGOUT: string = 'mobile-app-logout';
  public static readonly LOGOUT: string = 'logout';
  public static readonly FSM_LOGOUT: string = 'fsmLogout';
  public static readonly AUTH: string = 'auth';
  public static readonly ABOUT_US: string = 'about-us';
  public static readonly FUNDS: string = 'funds';
  public static readonly BONDS: string = 'bonds';
  public static readonly DPMS: string = 'dpms';
  public static readonly INSURANCE: string = 'insurance';
  public static readonly STOCKS: string = 'stocks';
  public static readonly ETFS: string = 'etfs';
  public static readonly ETFS_IDEAS: string = 'etfs-ideas';
  public static readonly ETFS_HUBS: string = 'etfs-hubs';
  public static readonly ETFS_101: string = 'etfs-101';
  public static readonly FIND_MY_ETFS: string = 'find-my-etfs';
  public static readonly ETFS_SELECTOR: string = 'etfs-selector';
  public static readonly ETFS_CALCULATOR: string = 'etfs-calculator';
  public static readonly ETFS_FOCUS_LIST: string = 'etfs-focus-list';
  public static readonly ETFS_FOCUS_LIST_METHODOLOGY: string = 'etfs-focus-list-methodology';
  public static readonly ETFS_SELECTION_METHODOLOGY: string = 'etfs-selection-methodology';
  public static readonly ETFS_BEST_AND_WORST_PERFORMERS: string = 'etfs-best-and-worst-performers';
  public static readonly ETFS_FACTSHEET: string = 'etfs-factsheet';
  public static readonly STOCKS_FACTSHEET: string = 'stocks-factsheet';
  public static readonly STOCKS_SCREENER: string = 'stocks-screener';
  public static readonly STOCKS_PORTFOLIO: string = 'portfolio';
  public static readonly STOCKS_ORDERBOOK: string = 'order-book';
	public static readonly STOCKS_SELECTOR: string = 'stocks-selector';
  public static readonly RESEARCH: string = 'research';
  public static readonly TOOLS: string = 'tools';
  public static readonly CORPORATE_ACTION: string = 'corporate-action'
  public static readonly STOCKS_CORPORATE_ACTION: string = 'stocks/corporate-action'
  public static readonly BOND_TOOLS: string = 'bond-tools';
  public static readonly CHART_CENTRE: string = 'chart-centre';
  public static readonly FACTSHEET: string = 'factsheet';
  public static readonly DISCLAIMER: string = 'disclaimer';
  public static readonly PERSONAL_INFO_COLLECTION_STATEMENT: string = 'personal-info-collection-statement';
  public static readonly MORNINGSTAR_DISCLAIMER: string = 'morningstar-disclaimer';
  public static readonly PRIVACY_POLICY: string = 'privacy-policy';
  public static readonly S_P_DISCLAIMER: string = 's-p-disclaimer';
  public static readonly CUSIP_DISCLAIMER: string = 'cusip-disclaimer';
  public static readonly HKEX_DISCLAIMER: string = 'hkex-disclaimer';
  public static readonly IMPORTANT_NOTES_FOR_TRANSACTING_STOCK_ETF: string = 'important-notes-for-transacting-stock-etf';
  public static readonly CAREERS: string = 'careers';
  public static readonly CAREER_DETAILS: string = 'career-details';
  public static readonly GET_STARTED: string = 'get-started';
  public static readonly FUND_101 = 'fund-101';
  public static readonly PAGE_NOT_FOUND: string = 'page-not-found';
  public static readonly EVENTS: string = 'events';
  public static readonly UNIT_TRUST_FEE: string = 'unit-trust-fee';
  public static readonly REWARDS_PROGRAM: string = 'rewards-program';
  public static readonly STAR_RATING: string = 'star-rating';
  public static readonly FUND_HOUSE: string = 'fund-house';
  public static readonly FUND_HOUSE_DETAILS: string = 'fund-house-details';
	public static readonly FUND_HOUSE_INSIGHT: string = 'fund-house-insight';
  public static readonly PRS: string = 'prs';
  public static readonly PAYMENT_METHOD: string = 'payment-method';
  public static readonly TRANSFER_IN_PROGRAM: string = 'transfer-in-program';
  public static readonly FUND_SELECTOR: string = 'fund-selector';
  public static readonly FUND_RANKING: string = 'fund-ranking';
  public static readonly PORTFOLIO_SIMULATOR: string = 'portfolio-simulator';
  public static readonly FORMS: string = 'forms';
  public static readonly EXTERNAL_TRANSFER_FORMS: string = 'external-transfer-forms';
  public static readonly ARTICLE = "article";
  public static readonly ARTICLE_LIST = "article-list";
  public static readonly ARTICLE_DETAILS: string = 'article-details';
  public static readonly ARTICLE_SEARCH: string = 'article-search';
  public static readonly ARTICLE_AUTHORS_LIST: string = 'article-authors-list';
  public static readonly AUTHOR_DETAILS: string = 'author-details';
  public static readonly EVENT_DETAILS: string = 'event-details';
  public static readonly BOND_FEES: string = 'bond-fees';
  public static readonly BOND_SELECTOR: string = 'bond-selector';
  public static readonly MANAGED_PORTFOLIOS: string = 'dpms';
  public static readonly MANAGED_PORTFOLIO: string = 'managed-portfolio';
  public static readonly PORTFOLIO_INSIGHTS: string = 'portfolio-insights';
  public static readonly PORTFOLIO_PLANNER: string = 'portfolio-planner';
  public static readonly MONTHLY_COMMENTARY: string = 'monthly-commentary';
  public static readonly TOOLS_SELECTOR: string = 'tools-selector';
  public static readonly TRANSACTION_SELECTOR: string = 'transaction-selector';
  public static readonly PORTFOLIO_ARTICLE: string = 'portfolio-articles';
  public static readonly ANNOUNCEMENTS: string = 'announcements';
  public static readonly NEW_FUNDS_ON_BOARD: string = 'new-funds-on-board';
  public static readonly RECOMMENDED_FUNDS: string = 'recommended-funds';
  public static readonly RECOMMENDED_FUND_METHODOLOGY: string = 'recommended-fund-methodology';
  public static readonly INCOME_DISTRIBUTION: string = 'income-distribution';
  public static readonly DEMO: string = 'demo';
  public static readonly EXPLORE_BONDS: string = 'explore-bonds';
  public static readonly BOND_MGS: string = 'bond-mgs';
  public static readonly BOND_CORPORATE: string = 'bond-corporate';
  public static readonly BOND_LATEST: string = 'bond-latest';
  public static readonly BOND_CALCULATOR: string = 'bond-calculator';
  public static readonly BOND_GET_STARTED: string = 'bond-get-started';
  public static readonly BOND_101: string = 'bond-101';
  public static readonly CASH_MANAGEMENT_FUND = 'cash-management-fund';
  public static readonly FUND_FINDER: string = 'fund-finder';
  public static readonly FIND_MY_FUNDS: string = 'find-my-funds';
  public static readonly FIND_MY_STOCK: string = 'find-my-stock';
  public static readonly FUND_CALENDAR: string = 'fund-calendar';
  public static readonly BOND_PAYMENT_METHOD: string = 'payment-method';
  public static readonly BOND_EXPRESS: string = 'bond-express';
  public static readonly BOND_RESEARCH: string = 'bond-research';
  public static readonly BOND_IDEAS: string = 'bond-ideas';
  public static readonly BOND_IPO_NOTIFICATION: string = 'bond-ipo-notification';
  public static readonly RETAIL_BONDS: string = 'retail-bonds';
  public static readonly WHOLESALE_BONDS: string = 'wholesale-bonds';
  public static readonly EMAIL_UNVERIFIED: string = 'email-unverified';
  public static readonly EMAIL_UPDATE: string = 'email-update';
  public static readonly FORCE_EMAIL_UPDATE: string = 'force-email-update';
    public static readonly FORCE_RESET_PASSWORD: string = 'force-reset-password';
  public static readonly FORGET_USERNAME: string = 'forget-username';
  public static readonly FORGOT_USERNAME: string = 'forgot-username';
  public static readonly FIND_MY_PORTFOLIO: string = 'find-my-portfolio';
  public static readonly FUND_MANAGER_INTERVIEW: string = 'fund-manager-interview';
  public static readonly FORGOT_PASSWORD: string = 'forgot-password';
  public static readonly RESET_PASSWORD: string = 'reset-password';
  public static readonly CHANGE_ACCOUNT_PASSWORD: string = 'change-account-password';
  public static readonly CHANGE_PDF_PASSWORD: string = 'change-pdf-password';
  public static readonly ACCOUNT_BLOCKED: string = 'account-blocked';
  public static readonly MANAGED_PORTFOLIO_FEES: string = 'fees';
  public static readonly TRADE: string = 'trade';
  public static readonly PORTFOLIO_FACTSHEET: string = 'portfolio-factsheet';
  public static readonly MANAGED_PORTFOLIO_101: string = 'managed-portfolio-101';
  public static readonly PORTFOLIO_RANKING: string = 'portfolio-ranking';
  public static readonly MANAGED_PORTFOLIO_PAYMENT_METHOD: string = 'payment-method';
  public static readonly FIND_MY_BOND: string = 'find-my-bond';
  public static readonly ACCOUNT_OPENING: string = 'account-opening';
  public static readonly ACCOUNT_OPENING_PERSONAL: string = 'personal';
  public static readonly ACCOUNT_OPENING_BENE: string = 'bene';
  public static readonly ACCOUNT_OPENING_CORP: string = 'corp';
  public static readonly ACCOUNT_OPENING_DISCRET: string = 'discret';
  public static readonly ACCOUNT_OPENING_SMART: string = 'smart';
  public static readonly ACCOUNT_INFO: string = 'account-info';
  public static readonly INVESTMENT_METHODOLOGY: string = 'investment-methodology';
  public static readonly FEEDBACK: string = 'feedback';
  public static readonly TAX_INFO: string = 'tax-info';
  public static readonly BASIC_INFO: string = 'basic-info';
  public static readonly BOND_FACTSHEET: string = 'bond-factsheet';
  public static readonly INSURANCE_PLAN_FACTSHEET: string = 'insurance-plan-factsheet';
  public static readonly FINANCIAL_INFO: string = 'financial-info';
  public static readonly CHECK_OUT: string = 'check-out';
  public static readonly ORDER_PLACED: string = 'order-placed';
  public static readonly SUPPORTING_DOC: string = 'supporting-doc';
  public static readonly DECLARATION: string = 'declaration';
  public static readonly CDS_OPENING: string = 'cds-opening';
  public static readonly ACKNOWLEDGEMENT: string = 'acknowledgement'
  public static readonly YIELD_CURVE: string = 'yield-curve';
  public static readonly INDEX_DATA: string = 'index-data';
  public static readonly BEST_AND_WORST_PERFORMERS: string = 'best-and-worst-performing';
  public static readonly TOP_TURNOVER: string = 'top-turnover';
  public static readonly LANDING_GETTING_STARTED: string = 'getting-started';
  public static readonly TRADE_NOT_ALLOWED: string = 'trade-not-allowed';
  public static readonly REDIRECT: string = 'redirect';
  public static readonly CASH_ACCOUNT: string = 'cash-account';
  public static readonly CASH_ACCOUNT_TRANSACTION_RECORD: string = 'cash-account-transaction-record';
  public static readonly CASH_ACCOUNT_TRANSACTION: string = 'cash-account-transaction';
  public static readonly SEARCH: string = 'search';
  public static readonly FPX: string = 'fpx';
  public static readonly FSMONE: string = 'fsmone';
  public static readonly BUY: string = 'buy';
  public static readonly SELL: string = 'sell';
  public static readonly SWITCH: string = 'switch';
  public static readonly BOND_CHECK_OUT: string = 'bond-check-out';
  public static readonly BOND_SELL_CHECK_OUT: string = 'bond-sell-check-out';
  public static readonly ACCOUNT_UPDATE: string = 'account-update';
  public static readonly ACCOUNTUPDATE: string = 'accountupdate';
  public static readonly ACCOUNT_UPDATE_MENU: string = 'account-update-menu';
  public static readonly ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU: string ='accountupdate/account-update-menu';
  public static readonly DKA: string = 'dka';
  public static readonly PERSONAL_INFO: string = 'personal-information';
  public static readonly BENEFICIARY_INFO: string = 'beneficiary-information';
  public static readonly EPF_PRS_INFO: string = 'epf-prs-information';
  public static readonly BANK_ACCOUNT: string = 'bank-information';
  public static readonly RISK_PROFILE: string = 'risk-profile';
  public static readonly EMIS_UPDATE: string = 'emis-update';
  public static readonly SECURITY_INFO: string = 'security-information';
  public static readonly STAY_IN_TOUCH: string = 'stay-in-touch';
  public static readonly W8_BEN_FORM: string = 'w8-ben-form';
  public static readonly NOTICE: string = 'notice';
  public static readonly TERMS_AND_CONDITIONS: string = 'terms-and-conditions';
  public static readonly INSURE_NOW: string = 'insure-now';
  public static readonly GET_QUOTE: string = 'get-quote';
  public static readonly TERM_INSURANCE: string = 'terms-insurance';
  public static readonly MEDICAL_INSURANCE: string = 'medical-insurance';
  public static readonly TRAVEL_INSURANCE: string = 'travel-insurance';
  public static readonly INSURANCE_GET_STARTED: string = 'insurance-get-started';
  public static readonly GROUP_INSURANCE: string = 'group-insurance';
  public static readonly MCIS_IKTHIARI: string = 'mcis-ikthiari';
  public static readonly MCIS_IKTHIARI_APPLICATION_FORM: string = 'mcis-ikthiari-application-form';
  public static readonly MCIS_IKTHIARI_POST_APPLICATION: string = 'mcis-ikthiari-post-application';
  public static readonly INSURANCE_101: string = 'insurance-101';
  public static readonly FIND_MY_INSURANCE: string = 'find-my-insurance';
  public static readonly INSURANCE_TOOLS: string = 'insurance-tools';
  public static readonly LIFE_PROTECTION_CALCULATOR: string = 'life-protection-calculator';
  public static readonly CLAIM_PROCESS_AND_FORMS: string = "claim-process-and-forms";
  public static readonly COMPARE_INSURANCE_LANDING: string = "compare-insurance-landing";
  public static readonly COMPARE_INSURANCE_RESULTS: string = "compare-insurance-results";
  public static readonly INSURER_INFORMATION: string = "insurer-information";
  public static readonly PLAN_INFORMATION: string = "plan-information";
  public static readonly MANU_PROTECT_PERSONAL_DETAILS: string = "manu-protect-personal-details";
  public static readonly MCIS_LEVEL_TERM_PERSONAL_DETAILS: string = "mcis-term-personal-details";
  public static readonly MANU_EZ_PERSONAL_DETAILS: string = "manu-ez-personal-details";
  public static readonly INSURANCE_ARTICLE: string = "insurance-article";
  public static readonly HOLDINGS: string = 'holdings';
  public static readonly MARKET_INDICES: string = 'market-indices';
  public static readonly MARKET_INDICES_LIST: string = 'market-indices-list';
  public static readonly MARKET_INDICES_DETAILS: string = 'market-indices-details';
  public static readonly MAJOR_INDICES: string = 'major-indices';
  public static readonly FSMONE_INDICES: string = 'fsmone-indices';
  public static readonly MARKET_PE_EARNINGS_GROWTH: string = 'market-pe-earnings-growth';
  public static readonly EARNINGS_TREND: string = 'earnings-trend';
  public static readonly TRANSACTION_RECORD: string = 'transaction-record';
  public static readonly PENDING: string = 'pending';
  public static readonly HISTORICAL: string = 'historical';
  public static readonly STATEMENTS: string = 'statements';
  public static readonly RSP: string = 'rsp';
  public static readonly RSP_FUND_LIST: string = 'rsp-fund-list';
  public static readonly RSP_DPMS_LIST: string = 'rsp-dpms-list';
  public static readonly DDA_FPX_LIST: string = 'dda-fpx-list';
  public static readonly CREATE_RSP: string = 'create-rsp';
  public static readonly STOCKS_CALCULATOR: string = 'stocks-calculator';
  public static readonly ABOUT_THE_COMPANY: string = 'about-the-company';
  public static readonly WATCHLIST: string = 'watchlist';
  public static readonly ALERTS: string = 'alerts';
  public static readonly TECHNICAL_ANALYSIS: string = 'technical-analysis';
  public static readonly HOLDING_HISTORICAL_DETAILS: string = 'holding-historical-details';
  public static readonly EMAIL_VERIFICATION_RESULT: string = 'email-verification-result';
  public static readonly INSURANCE_DETAILS: string = 'insurance-details';
  public static readonly REFERRAL_PROGRAM: string = 'referral-program';
  public static readonly OPT_IN_OTP: string = 'otp-in-otp';
  public static readonly RSP_LIST: string = 'rsp-list';
  public static readonly EMANDATE_DONE_PAYMENT: string = 'emandate-done-payment';
  public static readonly ANALYSE_MY_PORTFOLIO: string = 'holdings-analyse-portfolio';
  public static readonly SETUP_OTP: string = 'setup-otp';
  public static readonly HOLIDAY_CALENDAR: string = 'holiday-calendar';
  public static readonly TRADING_HOURS: string = 'trading-hours';
  public static readonly COMPANY_ANNOUNCEMENT: string = 'company-announcement';
  public static readonly COMPANY_ANNOUNCEMENT_DETAILS: string = 'announcement-details';
  public static readonly MARKET_PERFORMANCE: string = 'market-performance';
  public static readonly MARKET_PERFORMANCE_DETAIL: string = 'market-performance-detail';
  public static readonly SECTOR_PERFORMANCE: string = 'sector-performance';
  public static readonly STOCK_FEES: string = 'stock-fees';
  public static readonly ETF_101: string = 'etf-101';
  public static readonly STOCK_101: string = 'stock-101';
  public static readonly HK_STOCK: string = 'hk-stock';
  public static readonly INSTRINSIC_VALUE_CALCULATOR: string = 'intrinsic-value-calculator';
  public static readonly STOCKS_ACTIVE_GAINERS_LOSERS: string = 'stocks-active-gainers-losers';
  public static readonly ETFS_ACTIVE_GAINERS_LOSERS: string = 'etfs-active-gainers-losers';
  public static readonly GETTING_STARTED: string = 'getting-started';
  public static readonly EVENT_REGISTER_FORM: string = 'event-register-form';
  public static readonly WEBCAST: string = 'webcast';
  public static readonly WEBCAST_LIST: string = 'webcast-list';
  public static readonly WEBCAST_DETAILS: string = 'webcast-details';
  public static readonly ENEWSLETTER_UNSUBSCRIBE: string = 'enewsletter-unsubscribe';
  public static readonly REFERRAL_PARTNER: string = 'referral-partner';
  public static readonly HELPER: string = 'helper';
  public static readonly MOBILE_VIEW_NRIC_UPLOADER: string = 'mobile-view-nric-uploader';
  public static readonly INVALID_QR_CODE: string = 'invalid-qr-code';
  public static readonly SESSION_EXPIRED: string = 'session-expired';
  public static readonly SWITCHING_CALCULATOR: string = 'switching-calculator';
  public static readonly CASH_SOLUTION: string = 'cash-solution';
  public static readonly MULTI_CURRENCIES: string = 'multi-currencies';
  public static readonly INTEREST_RATE: string = 'interest-rate'; 
  public static readonly FX_CONVERSION: string = 'fx-conversion';
  public static readonly MAKING_PAYMENT: string = 'making-payment';
  public static readonly PROMOTION: string = 'promotion';
  public static readonly BUZZ: string = 'buzz';
  public static readonly BOND_IPO: string = 'newly-issued-bond';
  public static readonly BOND_NEW_BOADS_ON_BOARD: string = 'new-bonds';
  public static readonly IPO: string = 'initial-public-offering';
  public static readonly IPOS: string = 'ipo';
  public static readonly FEE_SCHEDULE: string = 'fee-schedule';
  public static readonly USER_GUIDE: string = 'user-guide';
  public static readonly IMPORTANT_DOC: string = 'important-documents';
  public static readonly CONNECT_WITH_FSM: string = 'connect-with-fsm';
  public static readonly SUPPORT_TRANSFER_IN: string = 'transfer-in';
  public static readonly ETF_HOUSE: string = 'etf-house';
  public static readonly FUND_PICKS: string = 'fund-picks';
  public static readonly STOCKS_IPO: string = 'stocks-ipo';
  public static readonly SUPPORT_REGULAR_SAVINGS_PLAN: string = 'regular-savings-plan';
  public static readonly DPMS_STAR_RATINGS: string = 'star-ratings';
  public static readonly DPMS_INTRO_REBALANCING: string = 'rebalancing-intro';
  public static readonly DPMS_TRACK_RECORD: string = 'track-record'
  public static readonly FUND_RESTRICTION: string = 'fund-restriction';
  public static readonly FUND_INFO: string = 'fund-info';
  public static readonly FUND_SWITCH_LAG_TIME: string = 'fund-switching-lag-time';
  public static readonly DPMS_ROOKIE: string = 'rookie';
  public static readonly BASIC_101: string = 'basic-101';
  public static readonly MANAGER_LISTING: string = 'managers-listing';
  public static readonly MANAGER_DETAILS: string = 'manager-details';
  public static readonly EXPERIENCED: string = 'experienced';
	public static readonly HIGHEST_DIVIDEND_YIELD = 'highest-dividend-yield';
	public static readonly FSMONE_RESEARCH_INSIGHT = 'fsmone-research-insight';
	public static readonly EXPLORE = 'explore';
	public static readonly IPO_RESEARCH = 'ipo-research';
	public static readonly INSIGHT = 'insight';
	public static readonly MACRO_SECTOR_ANALYSYS = 'macro-sector-analysis';
	public static readonly HOT_INVESTMENT_IDEA = 'hot-investment-ideas';
	public static readonly VIDEOS = 'videos';
	public static readonly PORTFOLIO_TEAM_INSIGHT = 'portfolio-team-insight';
	public static readonly FUND_PICK_METHODOLOGY = 'fund-picks-methodology';
	public static readonly FSMI_FACTSHEET = 'fsmi-factsheet';
	public static readonly MARKET_INDICES_FACTSHEET = 'market-indices-factsheet';
  public static readonly GLOSSARY: string = 'glossary';
	public static readonly TOP_VOLUME: string = 'top-volume';
  public static readonly CALCULATOR: string = 'calculator';
  public static readonly SELECTOR: string = 'selector';
	public static readonly INVESTMENT_SERVICES : string = 'investment-services';
	public static readonly CASH_SOLUTIONS : string = 'cash-solutions';
	public static readonly FEES_CHARGES : string = 'fees-charges';
	public static readonly PROMO_EVENTS : string = 'promotion-events';
	public static readonly CLIENT_SUPPORT : string = 'client-support';
  public static readonly MARKET_TREND: string = 'market-trend';
  public static readonly INVEST_101: string = 'invest-101';
  public static readonly MARKET_DATA: string = 'market-data';
  public static readonly ETF_INFO: string = 'etf-info';
	public static readonly SOVEREIGN_BONDS: string = 'sovereign-bonds';
  public static readonly CHECKOUT: string = 'checkout';
  public static readonly US_INCIDIA_INFO: string = 'us-indicia-info';
  public static readonly OTHER_DETAILS: string = 'other-details';
  public static readonly ACCOUNT_SETTING: string = 'account-setting';
  public static readonly ACTIVATED_PRODUCTS: string = 'activated-products';
  public static readonly LOGIN_AND_SECURITY: string = 'login-and-security';
  public static readonly UPDATE_MOBILE_NUMBER_OTP: string = 'update-mobile-number-otp';
  public static readonly MOBILE_SMS_OTP: string = 'mobile-sms-otp';
  public static readonly DONE: string = 'done';
  public static readonly BENEFICIAL: string = 'beneficial';
  public static readonly PREVIEW: string = 'preview';
  public static readonly AGREEMENT: string = 'agreement';
  public static readonly FSM_SMART: string = 'fsm-smart';
  public static readonly SMART_ACCOUNT: string = 'smart-account';
  public static readonly ADDITIONAL_PERSONAL_ACCOUNT: string = 'additional-personal-account';
  public static readonly ADDITIONAL_BENEFICIARY_ACCOUNT: string = 'additional-beneficiary-account';
  public static readonly ADDITIONAL_DISCRETIONARY_ACCOUNT: string = 'additional-discretionary-account';
  public static readonly ADDITIONAL_SMART_ACCOUNT: string = 'additional-smart-account';
  public static readonly NOTIFICATION: string = 'notification/main';
  public static readonly MONTHLY_STATEMENT: string = 'monthly-statement';
  public static readonly COMPLETE: string = 'complete';
  public static readonly REGISTRATION: string = 'registration';
  public static readonly MAIN: string = 'main';
  public static readonly TWO_FA: string = 'two-fa';
  public static readonly TRANSFER_IN_OUT: string = "transfer-in-out";
  public static readonly TRANSFER_IN_FUND: string = "transfer-in-fund";
  public static readonly TRANSFER_IN_BOND: string = "transfer-in-bond";
  public static readonly TRANSFER_IN_STOCK: string = "transfer-in-stock";
  public static readonly INTERNAL_TRANSFER: string = "internal-transfer";
  public static readonly TRANSFER_OUT: string = "transfer-out";
  public static readonly ACCOUNT_SUMMARY: string = "account-summary";
  public static readonly FURTHER_ENQUIRY: string = 'further-enquiry';
  public static readonly GENERAL_INFO: string = 'general-info';
  public static readonly RISK_CAPACITY_ASSESSMENT: string = 'risk-capacity-assessment';
  public static readonly RISK_CAPACITY_ASSESSMENT_RESULT: string = 'risk-capacity-assessment-result';
  public static readonly DIRECT_CREDIT_INSTRUCTION_UPDATE: string = 'direct-credit-instruction-update';
  public static readonly EDDA_APPLICATION: string = 'edda-application';
  public static readonly DDA_APPLICATION: string = 'dda-application';
  public static readonly WITHDRAWAL: string = 'withdrawal';
  public static readonly FPS_QR_DEPOSIT: string = 'fps-qr-deposit';
  public static readonly FPS_QR_DEPOSIT_DONE: string = 'fps-qr-deposit-done';
  public static readonly QR_SCAN_PAGE: string = 'qr-scan-page';
  public static readonly CORP_FIRST_LOGIN: string = 'corp-first-login';
  public static readonly SET_DETAILS: string = 'set-details';
  public static readonly CASH_TRANSFER: string = 'cash-transfer';
  public static readonly CASH_TRANSFER_DONE: string = 'cash-transfer-done';
  public static readonly UPLOAD_PAYMENT_PROOF: string = 'upload-payment-proof';
  public static readonly EDDA_DEPOSIT: string = 'edda-deposit';
  public static readonly EDDA_DEPOSIT_DONE: string = 'edda-deposit-done';
  public static readonly WITHDRAWAL_DONE: string = 'withdrawal-done';
  public static readonly ACCOUNT: string = 'account';
  public static readonly INVEST_ACCT_CLIENT_AGREEMENT: string = 'investment-account-client-agreement';
  public static readonly ACCOUNT_AGREEMENT: string = 'account-agreement';
  public static readonly US_STOCK_ETF_LIVE_STREAMING_DATA: string = 'us-stock-etf-live-streaming-data';
  public static readonly PRO_INVESTOR_DECLARATION: string = 'pro-investor-declaration';
  public static readonly DERIVATIVE_KNOWLEDGE_ASSESSMENT: string = 'derivative-knowledge-assessment';
	public static readonly VIRTUAL_ASSET_KONOWLEDGE_ASSESSMENT: string = 'virtual-asset-knowledge-assessment';
  public static readonly INVESTMENT: string = 'investment';
  public static readonly SUBMIT_CA_INSTRUCTION: string = 'submit-ca-instruction';
	public static readonly US_STOCK_ACTIVATION: string = 'us-stock-activation';
	public static readonly SG_STOCK_ACTIVATION: string = 'sg-stock-activation';
	public static readonly CHINA_A_SHARES_CONNECT_ACTIVATION: string = 'china-a-shares-connect-activation';
	public static readonly HK_STOCK_ACTIVATION: string = 'hk-stock-activation';
	public static readonly STOCK_ACTIVATION: string = 'stock-activation';
  public static readonly DPMS_VALUATION_REPORT: string = 'dpms-valuation-report';
  public static readonly HOLDING_DETAILS: string = 'holding-details';
  public static readonly PL_SNAPSHOT: string = 'pl-snapshot';
  public static readonly FPS: string = 'fps';
  public static readonly FPS_DETAILS: string = 'fps-details';
  public static readonly HK_STOCKS_IDR: string = 'hk-stocks-idr';
  public static readonly FUND_FACTSHEET_URL: string = `${RouteConstant.FUNDS}/${RouteConstant.FUND_INFO}/${RouteConstant.FACTSHEET}`;
  public static readonly BOND_FACTSHEET_URL: string = `${RouteConstant.BONDS}/${RouteConstant.BOND_FACTSHEET}`;
  public static readonly ETF_FACTSHEET_URL: string = `${RouteConstant.ETFS}/${RouteConstant.TOOLS}/${RouteConstant.ETFS_FACTSHEET}`;
  public static readonly STOCK_FACTSHEET_URL: string = `${RouteConstant.STOCKS}/${RouteConstant.TOOLS}/${RouteConstant.STOCKS_FACTSHEET}`;
  public static readonly DOWNLOAD: string = 'download';
  public static readonly ADDRESS_PROOF: string = 'address-proof';
  public static readonly CA_DOCUMENTS: string = 'ca-documents';
  public static readonly MARGIN: string = 'margin';
  public static readonly MARGIN_SUMMARY: string = 'margin-summary';
  public static readonly LOAN_HISTORY: string = 'loan-history';
  public static readonly TREASURY_NOW: string = 'treasury-now';
  public static readonly MARGIN_ACCOUNT_ENROLMENT: string = 'margin-account-enrolment';
  public static readonly MARGIN_OPT_IN: string = 'margin-opt-in';
  public static readonly MARGIN_OPT_OUT: string = 'margin-opt-out';
  public static readonly US_STOCK: string = 'us-stock';
  public static readonly A_STOCK: string = 'a-stock';
  public static readonly SG_STOCK: string = 'sg-stock';
  public static readonly MARGIN_CALL_ALERT: string = 'margin-call-alert';
  public static readonly CYBER_SECURITY: string = 'cyber-security';
  public static readonly SECURITY_TIPS: string = 'security-tips';
  public static readonly AUTO_SWEEP: string = 'auto-sweep';
  public static readonly AUTO_SWEEP_ENROLMENT: string = 'auto-sweep-enrolment';
  public static readonly AUTO_SWEEP_ACCOUNT_FACTSHEET: string = 'auto-sweep-account-factsheet';
  public static readonly OPT_IN_AUTO_SWEEP: string = 'opt-in-auto-sweep';
	public static readonly MARGIN_LANDING_PAGE: string ='margin-financing';
  public static readonly TRADING_VIEW_DISCLAIMER: string = 'trading-view-disclaimer';
}

//fundsupermart route
export class FundsupermartRouteConstant {
	public static readonly FACTSHEET = 'factsheets';
	public static readonly NEW_TO_FSM = 'new-to-fsm';
	public static readonly ABOUT_FUNDSUPERMART = 'about-fundsupermart';
	public static readonly INVESTOR_ACADEMY = 'fsm-investor-academy';
	public static readonly SCHOOL = 'school';
	
}
