import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import {IEtfSelectorQueryModel} from '@api-module/model/etf/i-etf-selector-query.model';
import { IResponse } from '@api-module/model/common/i-response';
import {IEtfBestWorstQuery} from '@api-module/model/etf/i-etf-best-worst-model';


@Injectable({
  providedIn: 'root'
})

export class EtfRestService {
  readonly BASE_URL: string = ApiConstant.ETF;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }

  initEtfSelector() {
    return this.http.post(this.BASE_URL + ApiConstant.ETF_INIT_SELECTOR, {});
  }

  loadEtfSelectorResult(model:IEtfSelectorQueryModel) {
    return this.http.post(this.BASE_URL + ApiConstant.ETF_SELECTOR_RESULT, model);
  }

	getEtfInfoByStockCodeAndExchangeCode(stockCode:string, exchangeCode:string){
		const params = this.paramService.buildHttpParams({stockCode, exchangeCode});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_INFO_BY_STOCK_CODE_EXCHANGE_CODE,{},{params});
	}
	
	getEtfBidPriceChartData(productId:string, period:string){
		const params = this.paramService.buildHttpParams({productId, period});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_BID_PRICE_CHART,{},{params});
	}
	
	getEtfPerformanceChartData(productId:string,period:string){
		const params = this.paramService.buildHttpParams({productId, period});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_PERFORMANCE_CHART,{},{params});
	}
	
	getEtfChartTableData(productId:string, period:string){
		const params = this.paramService.buildHttpParams({productId, period});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_CHART_TABLE,{},{params});
	}
	
	getStockQuote(tickerNo:string){
		const params = this.paramService.buildHttpParams({tickerNo});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_STOCK_QUOTE,{},{params});
	}
	
	findListOfActiveEtfs() {
    	return this.http.post(this.BASE_URL + ApiConstant.ETF_ACTIVE_LIST, {});
    }
	
	getSgxEtfFeeDetailsByCurrency(currency:string){
		const params = this.paramService.buildHttpParams({currency});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_SGX_FEE_DETAILS,{},{params});
	}
	
	getEtfFeeRate(currency:string){
		const params = this.paramService.buildHttpParams({currency});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_FEE_RATE,{},{params});
	}
	
	getDefaultEtfFeeDetails(exchange:string){
		const params = this.paramService.buildHttpParams({exchange});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_DEFAULT_FEE_DETAILS,{},{params});
	}
	
	getCalendarYearReturn(tickerNo:string){
		const params = this.paramService.buildHttpParams({tickerNo});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_CALENDAR_YEAR_RETURN,{},{params});
	}
	
	getDividendData(tickerNo:string){
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_DIVIDEND_DATA + tickerNo,{});
	}
	
	getHistoricalPrice(tickerNo:string){
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_HISTORY_PRICE + tickerNo,{});
	}
	
	getTopTickerEtfPerformanceList(period:string){
		const params = this.paramService.buildHttpParams({period});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_TOP_TICKER_ETF_PERFORMANCE,{},{params});
	}
	
	getEtfReturn(tickerNo:string, fromDate:string, toDate:string){
		const params = this.paramService.buildHttpParams({fromDate,toDate});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ETF_RETURN + tickerNo,{},{params});
	}
	
	findListOfEtfsManager() {
    	return this.http.post(this.BASE_URL + ApiConstant.ETF_MANAGER_LIST, {});
    }

	findEtfManagerDetails(id) {
		const params = this.paramService.buildHttpParams({id});
    	return this.http.post(this.BASE_URL + ApiConstant.ETF_MANAGER_DETAILS, {},{params});
    }

	findEtfBestWorst(model:IEtfBestWorstQuery,paramChartPeriod) {
		const params = this.paramService.buildHttpParams({paramChartPeriod});
    	return this.http.post(this.BASE_URL + ApiConstant.ETF_BEST_WORST, model,{params});
    }

	findEtfBestWorstFilterList() {
    	return this.http.post(this.BASE_URL + ApiConstant.ETF_BEST_WORST_FILTER_LIST, {});
    }
}