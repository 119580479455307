<ng-template #chartTemplate>
	<highcharts-chart (chartInstance)="getChartInstance($event)" [Highcharts]="highcharts" [oneToOne]="true" [options]="chartOption" [runOutsideAngular]="true">
	</highcharts-chart>
</ng-template>

<ng-template #legendTemplate>
	<div *ngFor="let asset of nzAllocationDataList" class="px-5 pt-3">
			<nz-row [nzGutter]="24" class="text-base">
				<nz-col [nzXs]="12" [nzLg]="12">
					<span class="chart-legend legend-box" [ngStyle]="{'background': asset.color}"></span> 
					{{asset.msgKey != null? (asset.msgKey|translate): asset.name}}
				</nz-col>
				<nz-col [nzXs]="12" [nzLg]="12" class="text-right">
					{{asset.currency | translate}} {{asset.realValue | number: '1.2-2' }}
				</nz-col>
			</nz-row>
		</div>
</ng-template>

<div class="align-center">
	<ng-container *ngTemplateOutlet="chartTemplate"></ng-container>
	<div *ngIf="showLegend" class="align-center">
		<ng-container *ngTemplateOutlet="legendTemplate">
		</ng-container>
	</div>
</div>