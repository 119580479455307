import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'popup-full-screen-wrapper',
  templateUrl: './popup-full-screen-wrapper.component.html',
  styleUrls: ['./popup-full-screen-wrapper.component.scss']
})
export class PopupFullScreenWrapperComponent implements OnInit {

  @Input() isVisible: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
