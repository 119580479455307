import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Commonly used NG-ZORRO UI component modules will be imported directly here for easier development
import { TranslateModule } from '@ngx-translate/core';
import { B2cUiIconModule } from 'b2c-ui-icon/';
import { B2CArticlesModule } from 'b2c-ui/articles';
import { B2CHeaderModule } from 'b2c-ui/header';
import { I18nModule } from 'b2c-ui/i18n';
import { B2CInfiniteScrollModule } from 'b2c-ui/infinite-scroll';
import { B2CMobileDrawerModule } from "b2c-ui/mobile-drawer";
import { B2CMobileSelectModule } from 'b2c-ui/mobile-select';
import { B2CProductCardModule } from 'b2c-ui/product-card';
import { B2CProductTypeModule } from 'b2c-ui/product-type';
import { B2CShareModule } from 'b2c-ui/share';
import { B2CSubHeaderModule } from 'b2c-ui/subheader';
import { B2CUserProfileModule } from 'b2c-ui/user-profile';
import { UiInputModule } from 'ifast-ui-ng-tools/components/input';
import { UiSelectModule } from 'ifast-ui-ng-tools/components/select';
import { NzAutocompleteModule, NzDatePickerModule, NzEmptyModule, NzTagModule } from "ng-zorro-antd";
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzAddOnModule } from 'ng-zorro-antd/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ArticleHighlightContainerComponent } from './components/article/article-highlight-container/article-highlight-container.component';
import { BondExpressTableComponent } from './components/bond/bond-express-table/bond-express-table.component';
import { BondIpoProductCardComponent } from './components/bond/bond-ipo-product-card/bond-ipo-product-card.component';
import { BondProductCardComponent } from './components/bond/bond-product-card/bond-product-card.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { HideFromMobileAppComponent } from './components/hide-from-mobile-app/hide-from-mobile-app.component';
import { MobileScaffoldComponent } from './components/mobile-scaffold/mobile-scaffold.component';
import { NumberWrapperComponent } from './components/number-wrapper/number-wrapper.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { RoundedIconComponent } from './components/rounded-icon/rounded-icon.component';
import { RouteSubheaderComponent } from './components/route-subheader/route-subheader.component';
import { SharedAnimatedCounterComponent } from './components/shared-animation-counter/shared-animation-counter.component';
import { WebScaffoldComponent } from './components/web-scaffold/web-scaffold.component';
import { WebcastContainerComponent } from './components/webcast/webcast-container/webcast-container.component';
import { WebcastComponent } from './components/webcast/webcast/webcast.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SearchHitPipe } from './pipes/search-hit.pipe';
import { UnescapeHTMLPipe } from './pipes/unescape-html.pipe';
//import { BondIpoTableComponent } from './components/bond/bond-ipo-table/bond-ipo-table.component';
import { B2CSharedPipeModule, SharedDirectiveModule } from 'b2c-ui/core';
import { NzCollapseModule, NzDropDownModule } from 'ng-zorro-antd';
import { ArticleDetailsComponent } from './components/article/article-details/article-details.component';
import { ArticleRcmsContainerComponent } from './components/article/article-rcms-container/article-rcms-container.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { ProductSearchComponent } from './components/header-search/product-search.component';
import { PdfWrapperComponent } from './components/pdf-wrapper/pdf-wrapper.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { AppointmentSideButtonComponent } from './components/side-button/appointment/appointment-button.component';
import { DepositSideButtonComponent } from './components/side-button/deposit/deposit-button.component';
import { EnquirySideButtonComponent } from './components/side-button/enquiry/enquiry-button.component';
import { FsmAppSideButtonComponent } from './components/side-button/fsm-app/fsm-app-button.component';
import { LiveChatComponent } from './components/side-button/live-chat/live-chat.component';
import { WatchlistSideButtonComponent } from './components/side-button/watchlist/watchlist-button.component';
import { ModalContentContainerDirective } from './directives/modal-content-container.directive';
import { SwipeDirective } from './directives/swipe.directive';
import { HkDateFormatPipe } from './pipes/hk-date-format.pipe';
import { HkTranslatePipe } from './pipes/hk-translation.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
//import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DecimalPipe } from '@angular/common';
import { MatSliderModule, MatTooltipModule } from '@angular/material';
import { B2CEventCardModule } from 'b2c-ui';
import { B2CMenuDrawerModule } from 'b2c-ui/menu-drawer';
import { B2CProductRankingModule } from 'b2c-ui/product-ranking';
import { HighchartsChartModule } from 'highcharts-angular';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ArticleContainerComponent } from './components/article/article-container/article-container.component';
import { ArticleComponent } from './components/article/article/article.component';
import { ProductResearchComponent } from './components/article/product-research/product-research.component';
import { SchoolArticlesComponent } from './components/article/school-articles/school-article.component';
import { BondChartCentreSelectorComponent } from './components/bond/bond-chart-centre/bond-chart-centre-selector/bond-chart-centre-selector.component';
import { BondChartSharedComponent } from './components/bond/bond-chart-centre/bond-chart-centre-shared/bond-chart-centre-shared.component';
import { BondChartCentreResultTableComponent } from './components/bond/bond-chart-centre/bond-chart-centre-table/bond-chart-centre-table.component';
import { BondFactsheetImportantNoteComponent } from './components/bond/bond-disclosure-note/factsheet/important-note/bond-factsheet-important-note.component';
import { BondFactsheetRiskDisclosureComponent } from './components/bond/bond-disclosure-note/factsheet/risk-disclosure/bond-factsheet-risk-disclosure.component';
import { BondIndexDataChartComponent } from './components/bond/bond-index-data-chart/bond-index-data-chart.component';
import { BondIndexDataTableComponent } from './components/bond/bond-index-data-table/bond-index-data-table.component';
import { BondRankingTabComponent } from './components/bond/bond-ranking-tab/bond-ranking-tab.component';
import { BondSelectorFilterComponent } from './components/bond/bond-selector-filter/bond-selector-filter.component';
import { BondSelectorTableComponent } from './components/bond/bond-selector-table/bond-selector-table.component';
import { BondYieldCurveChartComponent } from './components/bond/bond-yield-curve-chart/bond-yield-curve-chart.component';
import { BondYieldCurveMaturityChartComponent } from './components/bond/bond-yield-curve-maturity-chart/bond-yield-curve-maturity-chart.component';
import { FxRateTableComponent } from './components/cash-account/fx-table/fx-table.component';
import { InterestRateTableComponent } from './components/cash-account/interest-rate-table/interest-rate-table.component';
import { ChartTemplateComponent } from './components/chart-centre/template/chart-centre-template.component';
import { CheckboxListComponent } from './components/check-box/check-box.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { OpenAccountModalComponent } from './components/open-account/modal/open-account-modal.component';
import { SelectorComponent } from './components/selector/selector.component';
import { LiveChatModalComponent } from './components/side-button/live-chat/modal/live-chat-modal.component';
import { MobileExtraSideButtonComponent } from './components/side-button/mobile-button/mobile-side-button.component';
import { StockExchangeTagModule } from './components/stock/stock-exchange-tag/stock-exchange-tag.module';
import { MillionDividerPipe } from './pipes/million-divider.pipe';
import { StockPricePipe } from './pipes/stock-price.pipe';
//import {EtfSelectorFilterComponent} from './components/etf/etf-selector-filter/etf-selector-filter.component';
//import {EtfSelectorTableComponent} from './components/etf/etf-selector-table/etf-selector-table.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AutoMoveNextByMaxlengthDirective } from "@share/directives/auto-prev.directive";
import { NumberOnlyDirectives } from "@share/directives/number-only.directives";
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { B2CFloatingBarModule } from 'b2c-ui/floating-bar';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ArticleVideoComponent } from './components/article/article-video/article-video.component';
import { HkEventCardComponent } from './components/article/hk-event-card/hk-event-card.component';
import { RelatedArticleFloatingComponent } from './components/article/related-article/related-article-floating.component';
import { BestWorstPerformersCommonComponent } from './components/best-worst-performers/best-worst-performers-common/best-worst-performers-common.component';
import { BondFeatureDescriptionComponent } from './components/bond/bond-feature-description/bond-feature-description.component';
import { BuyWatchlistIconComponent } from './components/buy-watchlist/buy-watchlist-icon.component';
import { EtfChartCentreSelectorComponent } from './components/etf/etf-chart-centre/etf-chart-centre-selector/etf-chart-centre-selector.component';
import { EtfChartSharedComponent } from './components/etf/etf-chart-centre/etf-chart-centre-shared/etf-chart-centre-shared.component';
import { EtfChartCentreResultTableComponent } from './components/etf/etf-chart-centre/etf-chart-centre-table/etf-chart-centre-table.component';
import { ManagerListCommonComponent } from './components/manager-list/manager-list-common.component';
import { NoteComponent } from './components/note/note.component';
import { PicsDisclaimerComponent } from './components/pics-disclaimer/pics-disclaimer.component';
import { PriceAlertButtonComponent } from './components/price-alert/price-alert-button.component';
import { ProductRankingComponent } from './components/product-ranking/product-ranking.component';
import { ProductViewHistoryComponent } from './components/product-view-history/product-view-history.component';
import { DownloadAppQrcodeComponent } from './components/qrcode/download-app-qrcode.component';
import { ShareButtonsComponent } from './components/share-buttons/share.buttons.component';
import { ShareButtonsModal } from './components/share-buttons/share.buttons.modal';
import { TextAlignComponent } from './components/text-align/text-align.component';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { FinancialStatementRebalancingPipe } from './pipes/financial-statement-rebalancing.pipe';
import { GetExDatePipe } from './pipes/get-ex-date.pipe';
import { NegativeToBracketPipe } from './pipes/negative-to-bracket.pipe';

import { ClickOutsideDirective } from "@share/directives/click-outside.directive";
import { PrintDirective } from "@share/directives/print.directive";
import { ImportantNoteModule } from "@share/ui-components/important-note/important-note.module";
import { UiPieChartModule } from '@share/ui-components/pie-chart/pie-chart.module';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { SwiperModule } from 'swiper/angular';
import { AutoSweepCalculatorPieChartComponent } from './components/auto-sweep/auto-sweep-calculator/auto-sweep-calculator-pie-chart/auto-sweep-calculator-pie-chart.component';
import { AutoSweepCalculatorComponent } from './components/auto-sweep/auto-sweep-calculator/auto-sweep-calculator.component';
import { AppFaqComponent } from './components/faq/app-faq.component';
import { IgvLoginIconComponent } from './components/igv-login-icon/igv-login-icon.component';
import { SupportSideButtonComponent } from './components/side-button/support-side-button/support-side-button.component';

import { UicSwitchV1WrapperModule } from '../uic/uic-switch-v1-wrapper/uic-switch-v1-wrapper.module';
import { ValidateKeyExistsPipe } from './pipes/is-key-exists.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { MillionDividerFactsheetPipe } from './pipes/million-divider-factsheet.pipe';
import { DynamicFactsheetPipe } from './pipes/dynamic-factsheet.pipe';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { AddToWatchListDirective } from './directives/add-to-watch-list.directive';
import { StockTaChartComponent } from '../trades/stocks/components/stock-ta-chart/stock-ta-chart.component';
import { AutoAddWatchlistModalComponent } from './components/auto-add-watchlist-modal/auto-add-watchlist-modal.component';


const EXPORTING_MODULES = [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, TranslateModule, B2cUiIconModule, B2CHeaderModule, B2CMobileSelectModule, I18nModule, B2CInfiniteScrollModule, B2CSharedPipeModule, SharedDirectiveModule, StockExchangeTagModule, SlickCarouselModule, IvyCarouselModule, UiPieChartModule];

const COMMON_UI_MODULES = [
  NzGridModule,
  NzFormModule,
  NzInputModule,
  NzRadioModule,
  NzCardModule,
  NzTableModule,
  NzSwitchModule,
  NzSkeletonModule,
  NzSpinModule,
  NzBackTopModule,
  NzDividerModule,
  NzButtonModule,
  NzCheckboxModule,
  NzModalModule,
  NzToolTipModule,
  NzNotificationModule,
  NzSelectModule,
  UiInputModule,
  UiSelectModule,
  NzIconModule,
  NzDatePickerModule,
  B2CMobileDrawerModule,
  NzAutocompleteModule ,
  NzEmptyModule,
  NzTagModule,
  NzTabsModule,
  B2CArticlesModule,
  B2cUiIconModule,
  NzDropDownModule,
  NzCollapseModule,
  B2CProductTypeModule,  
  NzTimePickerModule,
  B2CShareModule,
  B2CUserProfileModule,
  NzAddOnModule,
  MatSliderModule,
  NzDrawerModule,
  NzSliderModule,
  MatTooltipModule,
  B2CEventCardModule,
  HighchartsChartModule,
  B2CMenuDrawerModule,
  NzAffixModule,
  B2CProductRankingModule,
  NzPopoverModule,
  B2CFloatingBarModule,
  NgxSliderModule,
	ImportantNoteModule,
	ShareButtonsModule,
	ShareIconsModule,
	ShareButtonModule,
	Ng2SearchPipeModule,
	NgAddToCalendarModule,
	SwiperModule,
	UicSwitchV1WrapperModule
];   

const EXPORTING_COMPONENTS = [
 	MobileScaffoldComponent,
 	WebScaffoldComponent,
 	HideFromMobileAppComponent,
	NumberWrapperComponent,
	RouteSubheaderComponent,
	RoundedIconComponent,
  	SharedAnimatedCounterComponent,
  	BondExpressTableComponent,
  	ReadMoreComponent,
  	BondProductCardComponent,
  	BondIpoProductCardComponent,
  	WebcastComponent,
  	WebcastContainerComponent,
	HeaderSearchComponent,
  	ArticleHighlightContainerComponent,
//  	BondIpoTableComponent,
	GlobalSearchComponent,
	ArticleDetailsComponent,
	ArticleRcmsContainerComponent,
	PdfWrapperComponent,
	LiveChatComponent,
	GlobalSearchComponent,
	ProductCardComponent,
	EnquirySideButtonComponent,
	DepositSideButtonComponent,
	AppointmentSideButtonComponent,
	FsmAppSideButtonComponent,
	WatchlistSideButtonComponent,
	ProductSearchComponent,
	CheckboxListComponent,
	ProductResearchComponent,
	InterestRateTableComponent,
	SchoolArticlesComponent,
	SelectorComponent,
	BondIndexDataTableComponent,
	BondIndexDataChartComponent,
	BondYieldCurveChartComponent,
	BondYieldCurveMaturityChartComponent,
	ChartTemplateComponent,
	BondChartSharedComponent,
	BondChartCentreSelectorComponent,
	BondRankingTabComponent,
	BondChartCentreResultTableComponent,
	BondFactsheetRiskDisclosureComponent,
	BondFactsheetImportantNoteComponent,
	OpenAccountModalComponent,
	ArticleContainerComponent,
	LiveChatModalComponent,
	CountrySelectorComponent,
	ArticleComponent,
	MobileExtraSideButtonComponent,
	SupportSideButtonComponent,
	FxRateTableComponent,
	BondSelectorFilterComponent,
	BondSelectorTableComponent,
//	EtfSelectorFilterComponent,
//	EtfSelectorTableComponent,
	EtfChartCentreResultTableComponent,
	EtfChartSharedComponent,
	EtfChartCentreSelectorComponent,
	BestWorstPerformersCommonComponent,
	ManagerListCommonComponent,
	NoteComponent,
	BondFeatureDescriptionComponent,
	TextAlignComponent,
	ProductRankingComponent,
	ArticleVideoComponent,
	HkEventCardComponent,
	PicsDisclaimerComponent,
	ShareButtonsComponent,
	ProductViewHistoryComponent,
	ShareButtonsModal,
	BuyWatchlistIconComponent,
	RelatedArticleFloatingComponent,
	PriceAlertButtonComponent,
	DownloadAppQrcodeComponent,
	AutoSweepCalculatorComponent,
	AutoSweepCalculatorPieChartComponent,
	AppFaqComponent,
	IgvLoginIconComponent,
  StockTaChartComponent,
  AutoAddWatchlistModalComponent
];

const EXPORTING_DIRECTIVES = [
	SwipeDirective,
	ModalContentContainerDirective,
	AutoMoveNextByMaxlengthDirective,
	NumberOnlyDirectives,
	ClickOutsideDirective,
	PrintDirective,
  AddToWatchListDirective
];

const EXPORTING_SERVICE = [
	DecimalPipe
];

const EXPORTING_PIPES = [
	UnescapeHTMLPipe,
	SearchHitPipe,
	StandardDatePipe,
	YesNoPipe,
	HkDateFormatPipe,
	HkTranslatePipe,
	SafeHtmlPipe,
	SafeUrlPipe,
	MillionDividerPipe,
	StockPricePipe,
	GetExDatePipe,
	DynamicPipe,
	NegativeToBracketPipe,
	FinancialStatementRebalancingPipe,
	ValidateKeyExistsPipe,
    PercentagePipe,
	MillionDividerFactsheetPipe,
	DynamicFactsheetPipe,
  HighlighterPipe,

];

@NgModule({
  declarations: [...EXPORTING_COMPONENTS, ...EXPORTING_DIRECTIVES, ...EXPORTING_PIPES, ],
	imports: [B2CProductCardModule, B2CSubHeaderModule, ...EXPORTING_MODULES, ...COMMON_UI_MODULES],
  exports: [
    ...EXPORTING_COMPONENTS,
    ...EXPORTING_MODULES,
    ...COMMON_UI_MODULES,
    ...EXPORTING_DIRECTIVES,
    ...EXPORTING_PIPES
  ],
  providers: [
    ...EXPORTING_SERVICE,
    MillionDividerPipe, DynamicPipe, DecimalPipe, FinancialStatementRebalancingPipe, NegativeToBracketPipe
  ]
})
export class SharedCommonModule {}
