import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentage'
})

export class PercentagePipe implements PipeTransform {

    constructor() {
    }

    transform(value: any): any {
        return isNaN(value) ? '-' : (value*100).toFixed(2);
    };
}