import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";

@Component({
  selector: 'app-product-chart-template',
  templateUrl: './chart-centre-template.component.html',
  styles: [],
  providers: [SubHandlingService]
})

export class ChartTemplateComponent implements OnInit {
  
  BOND_LANDING_IMG_PATH = 'assets/images/bond/bond-landing/';
  PRODUCTS_ICON_IMAGES_PATH = 'assets/images/general/icons/product/';
  readonly FUNDS: string = 'FUNDS';
  readonly BONDS: string = 'BONDS';
  readonly ETF: string = 'ETF';
  @Input() type: string;
  selected= this.BONDS;


  constructor(private subHandlingService: SubHandlingService,private router: Router) {}

  ngOnInit(): void {
  this.identifyType();

  }

  identifyType(){
    this.selected = this.type;
  }
  
  changeCategory(cat){
  	this.selected = cat;
		if(this.selected == this.FUNDS){
			this.router.navigate([RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE], {});
		}else if(this.selected == this.BONDS){
			this.router.navigate([RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE], {});
		}else if(this.selected == this.ETF){
			this.router.navigate([RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE], {});
		}
  }
}
