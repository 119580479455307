import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'financialStatementRebalancing'})
export class FinancialStatementRebalancingPipe implements PipeTransform {
  public constructor(){}

  transform(feedList: any[], index, rebalancingArray: any[]): any {
    let feed = feedList[index];
    let sumOfOthers = 0;

    if(rebalancingArray.length > 0) {
      sumOfOthers = feed[rebalancingArray[0]];
      for(let i=1; i< rebalancingArray.length; i++) {
        try {
          if(typeof feed[rebalancingArray[i]] !=='undefined'){
            sumOfOthers = sumOfOthers - feed[rebalancingArray[i]];
          } else {
          }
        } catch (e) {
          return 0;
        }
      }
    }
    return sumOfOthers;
  }

}
