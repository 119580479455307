<div nz-row nzGutter="24" class="d-flex flex-wrap px-3 bg-black-1 py-4" *ngIf="'STOCK'===productType && stockList && stockList.length > 0">
	<div nz-col nzXs="24" nzSm="12" nzXl="6" *ngFor="let stock of stockList">
		<nz-card class="h-100">
			<h4><a routerLink="/stocks/tools/stocks-factsheet" [queryParams]="{exchange: stock.exchange, stock: stock.stockCode}">{{stock.stockName}}</a></h4>
			<h4>{{stock.tradingCurrency && stock.lastPrice ? (stock.tradingCurrency.toLowerCase() | translate) : ''}} {{stock.lastPrice | number: '1.2-4'}}</h4>
			<h4 *ngIf="stock.priceChangePercent > 0" [ngClass]="{'text-profit': true, 'text-md m-0 line-height-normal': (isMobile$ | async)}">
				+{{stock.priceChange | number: '1.3-3'}} (+{{stock.priceChangePercent | number: '1.3-3'}}%)
			</h4> 
			<h4 *ngIf="stock.priceChangePercent < 0" [ngClass]="{'text-loss': true, 'text-md m-0 line-height-normal': (isMobile$ | async)}">
				{{stock.priceChange | number: '1.3-3'}} ({{stock.priceChangePercent | number: '1.3-3'}}%)
			</h4>
			<h4 *ngIf="stock.priceChangePercent == 0" [ngClass]="{'text-md m-0 line-height-normal': (isMobile$ | async)}">
				{{stock.priceChange | number: '1.3-3'}} ({{stock.priceChangePercent | number: '1.3-3'}}%)
			</h4>
		</nz-card>
	</div>
</div>