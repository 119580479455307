import {AxisTypeValue, LegendOptions, ChartOptions, LangOptions} from 'highcharts';
import {ColorConstant} from './highchart-color.constant';
import {DateConstant} from './date.constants';

export class HighchartsConstant {
  // AxisTypeValue
  public static readonly LINEAR: AxisTypeValue = 'linear';
  public static readonly LOGARITHMIC: AxisTypeValue = 'logarithmic';
  public static readonly DATETIME: AxisTypeValue = 'datetime';
  public static readonly CATEGORY: AxisTypeValue = 'category';
  public static readonly TREEGRID: AxisTypeValue = 'treegrid';


  // Charts
  public static readonly PRIMARY_COLOR: string = '#004f6f';

  // Legends
  public static readonly LEGEND_STYLE: LegendOptions = {
    itemStyle: {
      fontFamily: 'Lato'
    }

  };

  // Zoom
  public static readonly ZOOM_OPTIONS: ChartOptions = {
    zoomType: 'x',
    resetZoomButton: {
      position: {
        align: 'center'
      },
      theme: {
        r: '6px',
        states: {
          hover: {
            fill: '#012c5d',
            style: {
              color: 'white'
            }
          }
        }
      }
    }
  };

  // Language
  public static readonly LANG: LangOptions = {
    resetZoom: 'Reset Zoom'
  };

  // PLOT_COLORS
  public static PLOT_COLORS: string[] = ColorConstant.HIGHCHART_COLOR;

  // PORTFOLIO_PLANNER_PLOT_COLORS
  public static PORTFOLIO_PLANNER_PLOT_COLORS: string[] = ColorConstant.PORTFOLIO_PLOT_COLOR;

  // CrossHairs
  public static readonly ENABLE_CROSSHAIR: object = {
    crosshair: true
  };

  // Exports
  public static readonly EXPORT_CONFIG: object = {
    enabled: true,
    formAttributes: {
      target: '_blank'
    },
    buttons: {
      contextButton: {
        menuItems: ['printChart', 'downloadPDF', 'downloadJPEG', 'downloadPNG', 'downloadSVG']
      }
    }
  };
  // Credits
  public static readonly CREDIT_TEXT: string = 'FSMOne';
  public static readonly CREDIT_HREF: string = '';
  public static readonly CREDIT_CONFIG: object = {
    href: HighchartsConstant.CREDIT_HREF,
    text: HighchartsConstant.CREDIT_TEXT,
    position: {
      align: 'center',
      verticalAlign: 'middle',
      x: 0
    },
    style: {
      'font-size': '1.5rem',
      color: 'rgba(0, 0, 0, 0.3)',
      'z-index': '-1000',
      width: '100%',
      'text-align': 'center',
      'font-weight': '600',
      'font-family': 'Lato'
    }
  };

  public static BOND_FACTSHEET_PERIODS: any[] = [
    {
      label: '1M', value: 'ONE_MONTH', key: 'ONE_MONTH',
      dateRange: [DateConstant.ONE_MONTH_AGO, DateConstant.TODAY]
    },
    {
      label: '6M', value: 'SIX_MONTH', key: 'SIX_MONTH',
      dateRange: [DateConstant.SIX_MONTHS_AGO, DateConstant.TODAY]
    },
    {
      label: '1Y', value: 'ONE_YEAR', key: 'ONE_YEAR',
      dateRange: [DateConstant.ONE_YEAR_AGO, DateConstant.TODAY]
    },
    {
      label: '3Y', value: 'THREE_YEAR', key: 'THREE_YEAR',
      dateRange: [DateConstant.THREE_YEARS_AGO, DateConstant.TODAY]
    },
    {label: 'Since Inception', value: 'SINCE_INCEPTION', key: 'SINCE_INCEPTION'},
  ];

  public static STOCK_FACTSHEET_PERIODS: any[] = [
    {
      label: '1M', value: 'ONE_MONTH', key: 'ONE_MONTH', shortValue: '1m',
      dateRange: [DateConstant.ONE_MONTH_AGO, DateConstant.TODAY]
    },
    {
      label: '3M', value: 'THREE_MONTH', key: 'THREE_MONTH', shortValue: '3m',
      dateRange: [DateConstant.THREE_MONTHS_AGO, DateConstant.TODAY]
    },
    {
      label: '6M', value: 'SIX_MONTH', key: 'SIX_MONTH', shortValue: '6m',
      dateRange: [DateConstant.SIX_MONTHS_AGO, DateConstant.TODAY]
    },
    {
      label: '1Y', value: 'ONE_YEAR', key: 'ONE_YEAR', shortValue: '1y',
      dateRange: [DateConstant.ONE_YEAR_AGO, DateConstant.TODAY]
    },
    {
      label: '3Y', value: 'THREE_YEAR', key: 'THREE_YEAR', shortValue: '3y',
      dateRange: [DateConstant.THREE_YEARS_AGO, DateConstant.TODAY]
    },
  ];

	public static STOCK_PRICE_CHART_PERIOD: any[] = [
		{label: 'general.tab.period.three.months', value: '3m', active: true},
    	{label: 'general.tab.period.six.months', value: '6m'},
    	{label: 'general.tab.period.one.year', value: '1y'},
    	{label: 'stock.tab.period.three.years', value: '3y'}
  	];

  public static PERFORMANCE: string = 'PERFORMANCE';
}
