import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth-module/service/auth.service';
import { AuthState } from '@auth-module/store';
import { ResponsiveService } from '@core/service/responsive.service';
import { Store } from '@ngxs/store';
import { GlobalDataStorage } from '@share/storages/global-data.storage';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: './post-login-layout.component.html',
})
export class PostLoginLayoutComponent implements OnInit {
  auth = false;
  authCas = false;
  auth2FA: boolean = false;
  isPostloginMenuCollapsed = true;
  isMobileView: boolean = false;
  fsmPostLoginWhitelist = false;

  constructor(
    private store: Store,
    private responsiveService: ResponsiveService,
    private globalDataStorage: GlobalDataStorage,
    private authService: AuthService
  ){}

  ngOnInit(): void {
    this.isMobileView = this.responsiveService.isMobileView();
		this.responsiveService.mobileViewEvent.subscribe(state => { this.isMobileView = state; });
    this.store.select(AuthState.isAuthenticated).pipe(
      tap((auth: boolean) => {
        this.auth = auth;
        this.fsmPostLoginWhitelist = auth;
      })
    ).subscribe();
		this.store.select(AuthState.isCasAuthenticated).pipe(
			tap((authCas: boolean) => this.authCas = authCas)
		).subscribe();
      const isValid = this.authService.is2FAValid();
		if (isValid || this.authCas) {
			this.auth2FA = true;
		} else {
			this.auth2FA = false;
		}
  }
}
