import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {IBondYieldCurveChartModel} from '@api-module/model/bond/i-bond-yield-curve-chart.model';
import * as Highcharts from "highcharts";
import exporting from 'highcharts/modules/exporting';
import {Chart} from "highcharts";
import {AppConstant, HighchartsConstant} from 'src/app/constant';
import {formatDate} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
exporting(Highcharts);

@Component({
  selector: 'app-bond-yield-curve-chart',
  templateUrl: './bond-yield-curve-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BondYieldCurveChartComponent implements OnChanges {

  @Input() yieldCurveList: IBondYieldCurveChartModel;
  @Input() maturityMonth: Array<any>;
  @Input() selectedCountry: string;
  @Input() selectedShowDate: string;
  Highcharts = Highcharts;
  chart: Highcharts.Chart;
  updateFromInput = false;
  country: string = "";
  date: string = "";
  locale:string;
  localeOption: string;

  chartOptions = {
	chart: {
	  plotBorderWidth: null,
	  plotShadow: false,
	  spacingTop: 20
	},
    credits: HighchartsConstant.CREDIT_CONFIG,
	lang: {
		printChart   : this.translateService.instant('fsmone.bond.yield.curve.print.chart'),
    	downloadPNG  : this.translateService.instant('fsmone.bond.yield.curve.download.png.image'),
        downloadJPEG : this.translateService.instant('fsmone.bond.yield.curve.download.jpeg.image'),
        downloadPDF  : this.translateService.instant('fsmone.bond.yield.curve.download.pdf.document'),
        downloadSVG	 : this.translateService.instant('fsmone.bond.yield.curve.download.svg.vector.image')
	},
    exporting: {
		allowHTML: true,
		enabled: true,
		buttons: {
	        contextButton: {
	            menuItems: [
	                'printChart',
	                'separator',
	                'downloadPNG',
	                'downloadJPEG',
	                'downloadPDF',
	                'downloadSVG'
	            ]
	        }
	    }
	},
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      ...HighchartsConstant.LEGEND_STYLE
    },
    plotOptions: {
      series: {marker: {enabled: false},pointPlacement: 'on'}
    },
    title: {
      text:undefined
    },
    xAxis: {
      tickmarkPlacement: 'on',
      startOnTick: true,
      categories: undefined,
      title: {
        text: "<b>"+this.translateService.instant('fsmone.bond.yield.curve.time.to.maturity')+"</b>"
      }
    },
    yAxis: {
      title: {
    	align: 'high',
        offset: 0,
        text: "<b>"+this.translateService.instant('fsmone.bond.market.chart.yield.axis.y')+"</b>",
        rotation: 0,
        y: -10
      }
    },
    tooltip: {
      shared: true,
      valueDecimals: 4
    },
    series: []
  };

  constructor(
    private cdRef: ChangeDetectorRef, 
    private translateService: TranslateService,
    private globalDataStorage: GlobalDataStorage) {
		this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
	}

  ngOnChanges() {
	 if (this.selectedCountry != null && this.selectedShowDate != null) {
	   this.populateChart();
	 }
  }

  populateChart() {
	 let seriesData: Array<any> = [];
	 let xAxisText: Array<any> = [];
	 const period = ["LATEST", "ONE_WEEK", "ONE_MTH", "ONE_YEAR"];

	 this.maturityMonth.forEach(item => {
		let cat = item[2];
		if (cat >= 12) {
			xAxisText.push(item[1] + (((cat / 12) > 1 && this.locale == 'en') ? "s" : ""));
		} else {
			xAxisText.push(item[1] + ((cat > 1 && this.locale == 'en') ? "s" : ""));
		}
	 });

	 this.chartOptions.series = [];
	 if (this.yieldCurveList.data) {
	   for (let i = 0; i < 4; i++) {
	     let date = new Date(this.selectedShowDate);
	     let nameSuffix = "";
	     let series = {
	       "name": null,
	       "data": null
	     };
	     let key = Object.keys(this.yieldCurveList.data)[i];

	     if (key == period[1]) {
	       date.setDate(date.getDate() - 7);
         nameSuffix = ' ' + this.translateService.instant('fsmone.bond.yield.1.week.ago');
	     } else if (key == period[2]) {
	       date.setMonth(date.getMonth() - 1);
         nameSuffix = ' ' + this.translateService.instant('fsmone.bond.yield.1.month.ago');
	     } else if (key == period[3]) {
	       date.setFullYear(date.getFullYear() - 1);
         nameSuffix = ' ' + this.translateService.instant('fsmone.bond.yield.1.year.ago');
	     }

       series.name = formatDate(date, AppConstant.DATE_MEDIUM_FORMAT, this.localeOption) + nameSuffix;
	     series.data = Object.values(this.yieldCurveList.data)[i];
	     seriesData.push(series);
	   }
	   this.chartOptions.series = seriesData;
	 }

	 this.chartOptions.xAxis.categories = xAxisText;
	 this.updateFromInput = true;
	 this.cdRef.markForCheck();
	 this.chart.reflow()
  }

  ngAfterViewInit(): void {
	  this.cdRef.detach();
	  this.cdRef.reattach();
  }

  getChartInstance(chart: Chart) {
	  this.chart = chart;
  }

  ngOnInit() {
    let locale = this.globalDataStorage.getStorage('locale') || 'ch';
    if ("en" === locale) {
      this.localeOption = "en-US";
    } else if ("ch" === locale) {
      this.localeOption = "zh-TW";
    } else if ("zh" === locale) {
      this.localeOption = "zh-CN";
    }
  }
}
