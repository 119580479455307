import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isKeyExists'
})

export class ValidateKeyExistsPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any, keyToMatch: String): any {
        var filtered : any = [];

        if(value){
            value.forEach((json:any) => {
                for (var key in json) {
                    if (key == keyToMatch) {
                        filtered.push(json[key]);
                    }
                 }
            });
        }
       
        return filtered;
    };
}