import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Select } from '@ngxs/store';
import { GlobalState } from 'src/app/core/store';
import { Observable } from 'rxjs';
import { ITabModel } from '@api-module/model/common/i-tab.model';
import { AppConstant } from 'src/app/constant';
import { IDropdownModel } from '@api-module/model/common/i-dropdown.model';
import { ActivatedRoute } from '@angular/router';
import { IResponse } from '@api-module/model/common/i-response';
import { Chart } from 'highcharts';
import { NzModalService } from 'ng-zorro-antd';
import { ChartCenterBase } from '../../../chart-centre/base/chart-centre-base'
import { TranslateService } from '@ngx-translate/core';

import { EtfRestService } from '@api-module/rest/etf.rest.service';
import { formatDate, formatNumber } from '@angular/common';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
	selector: 'app-etf-chart-centre-shared',
	templateUrl: './etf-chart-centre-shared.component.html'
})

export class EtfChartSharedComponent extends ChartCenterBase implements OnInit {

	constructor(private route: ActivatedRoute,
		private modalService: NzModalService, private translateService: TranslateService, private etfRest: EtfRestService,
		private globalDataStorage: GlobalDataStorage) {
		super();
		this.locale = this.globalDataStorage.getStorage('locale') || 'ch';

		if ("en" === this.locale) {
			this.localeOption = "en-US";
		} else if ("ch" === this.locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === this.locale) {
			this.localeOption = "zh-CN";
		}

		this.initHighchartsOption(this.localeOption);
	}

	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	selectedTickerNo: string;
	@Input() onlyChart: boolean;

	selectedETFList: any[] = [];
	fullscreen = false;
	isEmpty = true;
	isLoading = true;
	isClear = false;
	colorIndex = 0;
	updateFlag = false;
	chart: Highcharts.Chart;
	readonly BOND_CHART_CENTRE_IMG_PATH = 'assets/images/bond/bond-chart-centre/';
	chartPeriod = '6m';
	marketType: string;
	indexMarketType: string;
	allETFOptions: any[] = [];
	etfOptions: IDropdownModel[] = [] as IDropdownModel[];
	graphType: string;
	loadingEtf = true;

	loadingChart: boolean = false;

	locale: string = 'ch';
	localeOption: string = 'zh-TW';

	readonly tabPeriodOptions: ITabModel[] = [
		{
			label: 'YTD',
			langKey: 'ytd',
			value: 'ytd',
			chartPeriod: 'ytd',
		},
		{
			label: 'Last Year',
			langKey: 'last.year',
			value: 'last',
			chartPeriod: 'last',
		},
		{
			label: '1 week',
			langKey: '1.week',
			value: '1w',
			chartPeriod: '1w',
		},
		{
			label: '1 mth',
			langKey: '1.mth',
			value: '1m',
			chartPeriod: '1m',
		},
		{
			label: '3 mth',
			langKey: '3.mth',
			value: '3m',
			chartPeriod: '3m',
		},
		{
			label: '6 mth',
			langKey: '6.mth',
			value: '6m',
			chartPeriod: '6m',
			active: true,
		},
		{
			label: '1 yr',
			langKey: '1.yr',
			value: '1y',
			chartPeriod: '1y',
		},
		{
			label: '2 yr',
			langKey: '2.yr',
			value: '2y',
			chartPeriod: '2y',
		},
		{
			label: '3 yr',
			langKey: '3.yr',
			value: '3y',
			chartPeriod: '3y',
		},
		{
			label: '5 yr',
			langKey: '5.yr',
			value: '5y',
			chartPeriod: '5y',
		},
		{
			label: '10 yr',
			langKey: '10.yr',
			value: '10y',
			chartPeriod: '10y',
		}
	];

	ngOnInit(): void {
		this.selectedTickerNo = this.route.snapshot.queryParamMap.get('tickerNos');
		if (this.selectedTickerNo != null && this.selectedTickerNo != undefined) {
			this.loadingChart = true;
			this.isEmpty = false;
		}

		this.Highcharts.setOptions({
			lang: {
				noData: this.translateService.instant('fsmone.no.result'),
				months: [this.translateService.instant('fsmone.bonds.factsheet.highcharts.january'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.febuary'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.march'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.apr'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.may'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.june'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.july'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.aug'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.september'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.october'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.november'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.december')],
				shortMonths: [this.translateService.instant('fsmone.bonds.factsheet.highcharts.jan'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.feb'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.mar'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.apr'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.may'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.jun'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.jul'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.aug'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.sep'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.oct'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.nov'), this.translateService.instant('fsmone.bonds.factsheet.highcharts.dec')],
				weekdays: [this.translateService.instant('bond.factsheet.highcharts.sunday'), this.translateService.instant('bond.factsheet.highcharts.monday'), this.translateService.instant('bond.factsheet.highcharts.tuesday'), this.translateService.instant('bond.factsheet.highcharts.wednesday'), this.translateService.instant('bond.factsheet.highcharts.thursday'), this.translateService.instant('bond.factsheet.highcharts.friday'), this.translateService.instant('bond.factsheet.highcharts.saturday')],
				shortWeekdays: [this.translateService.instant('bond.factsheet.highcharts.sunday'), this.translateService.instant('bond.factsheet.highcharts.monday'), this.translateService.instant('bond.factsheet.highcharts.tuesday'), this.translateService.instant('bond.factsheet.highcharts.wednesday'), this.translateService.instant('bond.factsheet.highcharts.thursday'), this.translateService.instant('bond.factsheet.highcharts.friday'), this.translateService.instant('bond.factsheet.highcharts.saturday')],
				resetZoom: this.translateService.instant('reset.zoom'),
				resetZoomTitle: this.translateService.instant("reset.zoom.level1"),
				printChart: this.translateService.instant('fsmone.bond.yield.curve.print.chart'),
				downloadPNG: this.translateService.instant('fsmone.bond.yield.curve.download.png.image'),
				downloadJPEG: this.translateService.instant('fsmone.bond.yield.curve.download.jpeg.image'),
				downloadPDF: this.translateService.instant('fsmone.bond.yield.curve.download.pdf.document'),
				downloadSVG: this.translateService.instant('fsmone.bond.yield.curve.download.svg.vector.image')
			}
		});
		this.findAllActiveEtf();
	}

	initHighchartsOption(locale: string) {
		this.chartConfiguration.tooltip = {
			shared: true,
			formatter: function() {
				return this.points.reduce((s, point) => {
					return s + '<br/> ' + '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' +
						formatNumber(point.y, AppConstant.LOCALE_EN, '1.2-2') + '</b>';
				}, '<b>' + formatDate(this.x, AppConstant.DATE_MEDIUM_FORMAT, locale) + '</b>');
			}
		};



		this.chartConfiguration.xAxis = {
			labels: {
				formatter: function() {
					return formatDate(this.value, AppConstant.DATE_MEDIUM_FORMAT, locale);
				}
			}
		}
	}

	findAllActiveEtf() {
		this.loadingEtf = true;
		this.etfRest.findListOfActiveEtfs().subscribe((res: any) => {
			if (AppConstant.RESPONSE_SUCCESS !== res.status) {
				console.error('Something has went wrong');
				return;
			}
			this.allETFOptions = res.data;
			for (const data of this.allETFOptions) {
				this.etfOptions.push({
					label: data.etfName,
					value: data.tickerNo
				});
			}

			if (this.selectedTickerNo) {
				this.initQueryParamEtfList();
			}
		});
		this.loadingEtf = false;
	}

	onChangeEtfList($event) {
		for (const data of this.selectedETFList) {
			if (data.tickerNo === $event.tickerNo) {
				return;
			}
		}
		if (this.selectedETFList.length > 6) {
			this.modalService.error({ 
				nzTitle: this.translateService.instant('fsmone.error'), 
				nzContent: this.translateService.instant('fsmone.etf.selector.max.limit.reached'),
				nzOkType: 'theme'
			})
			return;
		}

		for (let i = 0; i < this.allETFOptions.length; i++) {
			if (this.allETFOptions[i].tickerNo === $event.tickerno) {
				this.selectedETFList.push(
					{
						tickerNo: this.allETFOptions[i].tickerNo,
						etfName: this.allETFOptions[i].etfName,
						stockCode: this.allETFOptions[i].stockCode,
						exchange: this.allETFOptions[i].exchange,
						color: this.plotColors[this.colorIndex],
						currencyCode: null,
						data: []
					}
				);
				this.colorIndex = this.colorIndex + 1;
			}
		}
		this.getEtfChartData();
		this.getEtfTableData();
		this.isEmpty = false;
		this.isLoading = false;
	}

	getChartInstance(chart: Chart) {
		this.chart = chart;
	}

	getEtfTableData(): void {
		for (const data of this.selectedETFList) {
			this.etfRest.getEtfChartTableData(data.tickerNo, this.chartPeriod).subscribe((response: IResponse<any>) => {
				if (AppConstant.RESPONSE_SUCCESS === response.status) {
					data.minBidPrice = response.data.minNav;
					data.maxBidPrice = response.data.maxNav;
					data.currentBidPrice = response.data.currentNav;
					data.returns = response.data.returns;
					data.currentNavDate = response.data.currentNavDate;
				}
			})
		}
	}

	getEtfChartData(): void {
		this.chartConfiguration.series = [];
		for (const data of this.selectedETFList) {
			this.etfRest.getEtfBidPriceChartData(data.tickerNo, this.chartPeriod).subscribe((response: IResponse<any>) => {
				if (AppConstant.RESPONSE_SUCCESS === response.status) {
					const seriesData: any[] = [];
					response.data.forEach(chartData => {
						seriesData.push(
							[
								chartData[0],
								chartData[1]
							]
						)
					})

					data.data = seriesData;
					this.drawChart(data);
				}
			})
		}
	}

	drawAllChart(listToDraw: any[]): void {
		this.chartConfiguration.series = [];
		for (const data of listToDraw) {
			this.chartConfiguration.series.push({
				name: data.etfName,
				data: data.data,
				type: 'line',
				color: data.color,
			});
		}
		this.updateFlag = true;
	}

	drawChart(data: any): void {
		this.chartConfiguration.series.push({
			name: data.etfName,
			data: data.data,
			type: 'line',
			color: data.color,
		});
		if (this.selectedETFList.length <= 1) {
			this.updateFlag = true;
		} else if (this.selectedETFList.length > 1) {
			this.updateFlag = this.selectedETFList.length === this.chartConfiguration.series.length;
		}
	}

	onClearAllETF($event): void {
		this.isClear = $event.clear;
		if (this.isClear) {
			this.selectedETFList.splice(0, this.selectedETFList.length);
			this.drawChart(this.selectedETFList);
			this.isEmpty = true;
			this.colorIndex = 0;
		}
	}

	onClearChartEtf($event) {
		for (let i = 0; i < this.selectedETFList.length; i++) {
			if (this.selectedETFList[i].tickerNo === $event.tickerNo) {
				this.selectedETFList.splice(i, 1);
			}
		}
		this.drawAllChart(this.selectedETFList);
		if (this.selectedETFList.length === 0) {
			this.isEmpty = true;
			this.colorIndex = 0;
		}
	}

	onPeriodChange(value: { rankPeriod: string, chartPeriod: string }) {
		this.chartConfiguration.series = [];
		this.chartPeriod = value.chartPeriod;
		this.changeActivePeriod(this.chartPeriod);
		this.getAllEtfChartData();
		this.getAllEtfTableData();
		this.updateFlag = true;
	}

	getAllEtfChartData(): void {
		this.isEmpty = false;
		this.isLoading = true;
		this.getEtfChartData();
		this.isEmpty = false;
		this.isLoading = false;
	}

	getAllEtfTableData(): void {
		this.getEtfTableData();
	}

	changeActivePeriod(period: string) {
		for (const option of this.tabPeriodOptions) {
			option.chartPeriod == period
				? option.active = true
				: option.active = false;
		}
	}

	onZoomChange($event) {
		this.fullscreen = $event.fullscreen;
		this.drawAllChart(this.selectedETFList);
	}

	getMarketType($event) {
		this.indexMarketType = $event;
	}

	changeChartDataOnGraphType(selectedGraphType: string) {
		this.chartConfiguration.series = [];
		this.graphType = selectedGraphType;
		if (this.selectedETFList.length > 0) {
			this.getEtfChartData();
		}
	}

	private initQueryParamEtfList() {
		this.isLoading = true;
		let fromSelectorList = this.selectedTickerNo.split(',');
		for (let fromSelector of fromSelectorList) {
			for (let i = 0; i < this.allETFOptions.length; i++) {
				if (this.allETFOptions[i].tickerNo === fromSelector) {
					this.selectedETFList.push(
						{
							tickerNo: this.allETFOptions[i].tickerNo,
							etfName: this.allETFOptions[i].etfName,
							stockCode: this.allETFOptions[i].stockCode,
							exchange: this.allETFOptions[i].exchange,
							color: this.plotColors[this.colorIndex],
							currencyCode: null,
							data: []
						}
					);
					this.colorIndex = this.colorIndex + 1;
				}
			}
		}

		this.getEtfChartData();
		this.getEtfTableData();
		this.isEmpty = false;
		this.isLoading = false;
		this.loadingChart = false;
	}
}
