import {Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import {SubHandlingService} from 'src/app/share/services/subscription-handling.service';
import {ProductSearchService} from 'src/app/share/services/product-search.service';
import {IProductSearchModel} from '@api-module/model/product/i-product-search.model';
import {B2CProductSearchQueryModel} from 'b2c-ui/header';
import {tap, take} from 'rxjs/operators';
import {ProductViewHistoryDataStorage} from 'src/app/share/storages/product-view-history-data.storage';

@Component({
  selector: 'header-search',
  templateUrl: './header-search.component.html',
  host: {
    class: 'w-100'
  },
  providers: [SubHandlingService]
})
export class HeaderSearchComponent implements OnInit {
	
	@Input() ut: boolean = false;
	@Input() bond: boolean = false;
	@Input() stock: boolean = false;
	@Input() etf: boolean = false;
	@Input() returnProduct? :boolean = false;
	@Output() selectedProduct = new EventEmitter();
	
  searching: boolean = false;
  loading: boolean = false;
  searchResults: Array<IProductSearchModel> = [];
  page: number = 0;
  queryData: B2CProductSearchQueryModel;
  end: boolean = false;
  searchString:string;
  apiCalledCount:number=0;
  apiResultReturnCount:number=0;
  constructor(
	private productSearchService: ProductSearchService, private subHandler: SubHandlingService, 
	private cdRef: ChangeDetectorRef, private prodHistoryStorage: ProductViewHistoryDataStorage
	) {

  }

  search(data: B2CProductSearchQueryModel) {
    this.queryData = data;
    this.end = false;
    this.searchProduct(false);
  }

  loadNext() {
    if (this.queryData && !this.end) {
      this.searchProduct(true);
    }
  }

  openProduct(data: IProductSearchModel) {
	if(this.returnProduct){
		this.selectedProduct.emit(data);
	} else {
    	this.productSearchService.openProduct(data);
	}
	
	if(data.productType==='STOCK'){
		this.prodHistoryStorage.addStock(data);
	}
  }


  private searchProduct(concat?: boolean) {
	if(this.queryData.query && this.queryData.query!=='' && this.queryData.query.length>=1 && (this.queryData.query!==this.searchString || concat)){
		this.searchString=this.queryData.query;	 
		if (concat) {
			if(this.queryData.query!==this.searchString){
				this.page = 0;
		    	this.searchResults = [];
				this.searchProductResult(false);
			}else if(this.searchResults.length>0){
				this.page = this.page + 1;
				this.searchProductResult(true);
			}			
        
		} else {
		      this.page = 0;
		      this.searchResults = [];
			  this.searchProductResult(false);  
		}				    
	}
  }

  private searchProductResult(loading:boolean){
		this.apiCalledCount=this.apiCalledCount+1; 
		if(loading){
			this.loading = true;
		}else{
			this.searching = true;
		}
		this.cdRef.markForCheck();  
		this.subHandler.once(this.productSearchService.search(this.queryData.category, this.queryData.query, this.page, 20).pipe(
				tap((results: Array<IProductSearchModel>) => {
					if(loading){
						this.searchResults = this.searchResults.concat(results);					
					}else{
						this.searchResults = results;
					}
					this.apiResultReturnCount=this.apiResultReturnCount+1;   
					if(this.apiCalledCount===this.apiResultReturnCount){
						this.searching = false;
					    this.loading = false;
					}											
								
					if (results.length < 20) {
					     this.end = true;
					}
					
					this.cdRef.markForCheck();
				}),
				take(1)
		));		
  }

  ngOnInit(): void {
  }
}
