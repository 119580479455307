import {ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {NgxsStoragePluginModule, StorageOption} from '@ngxs/storage-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';

import en from '@angular/common/locales/en';
import {NgxsModule} from '@ngxs/store';
import {states} from './store';
import {environment} from '@env';
import {StateConstant} from 'src/app/constant';
import {PreLoginLayoutComponent, PostLoginLayoutComponent} from '../layout';
import {B2CFooterModule} from 'b2c-ui/footer';
import {B2CHeaderModule} from 'b2c-ui/header';
import {
    FsmoneHeaderComponent,
    FsmoneFooterComponent,
    WebHeaderComponent,
    MenuImgComponent,
    MobileHeaderComponent,
    PostLoginMenuComponent
} from '../template/fsmone/';
//import {TemplateModule} from '../template/template.module';
import {B2CAnnouncementBannerModule} from 'b2c-ui/announcement-banner';
import {HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {
    NzAvatarModule,
    NzBadgeModule,
    NzDrawerModule,
    NzDropDownModule,
    NzListModule,
    NzProgressModule
} from 'ng-zorro-antd';
import {IPreloadingStrategy} from './preloading';
import {B2CTimeOutModule} from 'b2c-ui/time-out';
import {B2CSafeAreaModule} from 'b2c-ui/safe-area';

import {AppHammerConfig} from './config/hammer-config';
import {httpInterceptorProviders} from './interceptor';
import {AppErrorHandler} from './error/error.service';

import {SharedCommonModule} from 'src/app/share/common.module';
import {AuthenticationModule} from 'src/app/authentication/authentication.module';


/*import { UpgradeModule } from '@angular/upgrade/static';
import * as angular from 'angular';
*/
registerLocaleData(en);

import {
    B2cUiIconModule,
    B2CUiIconService,
    FacebookIcon,
    FooterYoutubeIcon,
    DiamondIcon
} from 'b2c-ui-icon';
import {NotificationBoxComponent} from "../template/fsmone/component/notification-box/notification-box.component";
import {AvatarProfileComponent} from '../template/fsmone/component/avatar-profile/avatar-profile.component';
import { UiMobileFullScreenModalModule } from '@share/ui-components/ui-mobile-full-screen-modal/ui-mobile-full-screen-modal.module';
import { UicCardModule } from '../uic/uic-card/uic-card.module';
import {UiMobileFullScreenModule} from '@share/ui-components/ui-mobile-full-screen/ui-mobile-full-screen.module';
import {PopupFullScreenWrapperComponent} from '../template/fsmone/component/post-login-menu/component/popup-full-screen-wrapper/popup-full-screen-wrapper.component';
import {UicFsmiconWrapperModule} from '../uic/uic-fsmicon-wrapper/uic-fsmicon-wrapper.module';

const ICONS = [
    FacebookIcon,
    FooterYoutubeIcon,
    DiamondIcon
]

const CORE_COMPONENTS = [
    PreLoginLayoutComponent,
    PostLoginLayoutComponent,
    FsmoneHeaderComponent,
    FsmoneFooterComponent,
    WebHeaderComponent,
    MenuImgComponent,
    MobileHeaderComponent,
    PostLoginMenuComponent,
    NotificationBoxComponent,
    AvatarProfileComponent,
    PopupFullScreenWrapperComponent
];

@NgModule({
    declarations: [...CORE_COMPONENTS],
    imports: [
        BrowserAnimationsModule,
        HammerModule,
        HttpClientModule,
        NgxsModule.forRoot(states, {developmentMode: environment.dev}),
        NgxsStoragePluginModule.forRoot({
            key: [
                StateConstant.AUTH,
                StateConstant.ACCOUNT,
                StateConstant.TRANSACTION_BUY,
                StateConstant.TRANSACTION_SELL,
                StateConstant.TRANSACTION_SWITCH,
                StateConstant.TRADE,
                StateConstant.TRANSACTION_BUY_DPMS,
                StateConstant.TRUST_ACCOUNT,
                StateConstant.TRANSACTION_SELL_DPMS,
                StateConstant.RSP,
                StateConstant.HOLDING,
                StateConstant.SELECTED_STOCK,
                StateConstant.ETF_SELECTOR,
                StateConstant.STOCK_SCREENER,
                StateConstant.RISK_PROFILE_UPDATE,
            ],
            storage: StorageOption.SessionStorage
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            name: `HKFSM (${environment.testMessage})`
        }),
        AuthenticationModule,
        B2CFooterModule,
        B2CHeaderModule,
        B2CAnnouncementBannerModule,
        B2CTimeOutModule,
        NzDropDownModule,
        NzDrawerModule,
        NzBadgeModule,
        B2CSafeAreaModule,
        SharedCommonModule,
        B2cUiIconModule,
        NzListModule,
        NzAvatarModule,
        NzProgressModule,
        UiMobileFullScreenModalModule,
        UicCardModule,
        UiMobileFullScreenModule,
        UicFsmiconWrapperModule
    ],
    providers: [
        // provider at forRoot only.
    ],
    entryComponents: [],
    exports: [
        // add here only if it's make sense to appModule, else add it in sharedModule
        ...CORE_COMPONENTS
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule, private b2cIconService: B2CUiIconService) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }

        this.b2cIconService.registerIcons([...ICONS]);

    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                httpInterceptorProviders,
                {
                    provide: HAMMER_GESTURE_CONFIG,
                    useClass: AppHammerConfig
                },
                {
                    provide: ErrorHandler,
                    useClass: AppErrorHandler
                },
                IPreloadingStrategy
            ]
        };
    }
}
