import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@api-module/api.endpoint.constant';
import {HttpParamsService} from '@api-module/service/http-params.service';

@Injectable({
  providedIn: 'root'
})
export class BannerRestService {

  readonly BASE_URL: string = ApiConstant.BANNER;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }

  findBannerList(){
    return this.http.post(this.BASE_URL + ApiConstant.GET_BANNER_LIST, {});
  }

  findFundNoticeBoardList(){
    return this.http.post(this.BASE_URL + ApiConstant.FIND_FUND_NOTICE_BOARD_LIST, {});
  }

  findDpmsNoticeBoardList(){
    return this.http.post(this.BASE_URL + ApiConstant.FIND_DPMS_NOTICE_BOARD_LIST, {});
  }

  findBondNoticeBoardList(){
    return this.http.post(this.BASE_URL + ApiConstant.FIND_BOND_NOTICE_BOARD_LIST, {});
  }

  findStockNoticeBoardList(){
    return this.http.post(this.BASE_URL + ApiConstant.FIND_STOCK_NOTICE_BOARD_LIST, {});
  }

  findMenuBannerList() {
    return this.http.post(this.BASE_URL + ApiConstant.FIND_MENU_BANNER_LIST, {});
  }

  getMaintenanceAlert() {
    return this.http.post(this.BASE_URL + ApiConstant.GET_MAINTENANCE_ALERT, {});
  }
}
