<nz-card class="h-100" *ngIf="!bondFeatureList || bondFeatureList.length <= 0">
	<h5 translate="n.a." translate-default="N.A."></h5>
</nz-card>

<div *ngIf="bondFeatureList">
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isCallable">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.bonds.direct.bond.callable" translate-default="Callable"></span></h4>
				<p class="text-underline bold" translate="fsmone.bonds.special.feature.callable.title" translate-default="This is a callable bond. Investors should note that:"></p>
				<ul>
					<span [innerHtml]="callableDescription"></span>
				</ul>
			</nz-card>
		</nz-col>
	</nz-row>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isPerpetual">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.bonds.bond.perpetual" translate-default="Perpetual"></span></h4>
				<p class="text-underline bold" translate="fsmone.bonds.special.feature.perpetual.title" translate-default="This is a perpetual bond (with an embedded call option). Investors should note that:"></p>
				<ul>
					<span [innerHtml]="perpetualDescription"></span>
				</ul>
			</nz-card>
		</nz-col>
	</nz-row>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isPuttable">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.bonds.direct.bond.puttable" translate-default="Putable"></span></h4>
				<p class="text-underline bold" translate="fsmone.bonds.special.feature.puttable.title" translate-default="This is a putable bond. Investors should note that:"></p>
				<ul>
					<span [innerHtml]="puttableDescription"></span>
				</ul>
			</nz-card>
		</nz-col>
	</nz-row>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isMakeWholeCall">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.bonds.direct.bond.make.whole.call" translate-default="Make-Whole Call"></span></h4>
				<p class="text-underline bold" translate="fsmone.bonds.special.feature.make.whole.call.title" translate-default="This is a bond with a make-whole call option. Investors should note that:"></p>
				<ul>
					<span [innerHtml]="wholeCallDescription"></span>
				</ul>
			</nz-card>
		</nz-col>
	</nz-row>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isLossAbsorption">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.bonds.selector.lossAbsorption" translate-default="Loss Absorption"></span></h4>
				<p class="text-underline bold" translate="fsmone.bonds.special.feature.loss.absorption.title" translate-default="This is a bond with loss absorption feature. Investors should note that:"></p>
				<ul>
					<span [innerHtml]="lossAbsorptionDescription"></span>
				</ul>
			</nz-card>
		</nz-col>
	</nz-row>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isCertificateDeposit">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.bonds.features.certificate.deposit" translate-default="Certificate of Deposit"></span></h4>
				<p class="text-underline bold" translate="fsmone.bonds.special.feature.certificate.deposit.title" translate-default="This is a Certificate of Deposit. Investors should note that:"></p>
				<ul>
					<span [innerHtml]="certificateDepositDescription"></span>
				</ul>
			</nz-card>
		</nz-col>
	</nz-row>
</div>

<div *ngIf="additionalFeature && additionalFeature.length>0">
	<nz-row class="d-flex flex-wrap pt-2">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.direct.bond.additional.features" translate-default="Additional Features"></span></h4>
				<div *ngFor="let item of additionalFeature">
					<span [innerHtml]="item.desc"></span>
				</div>
			</nz-card>
		</nz-col>
	</nz-row>
</div>

<div *ngIf="bondFeatureList.isCallable && callableList || bondFeatureList.isMakeWholeCall && makeWholeCallList" class="pt-5">
	<h3 class="font-weight-bold"><span translate="fsmone.bonds.feature.details" translate-default="Feature Details"></span></h3>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isCallable && callableList && callableList.length>0">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100 w-100">
				<h4><span translate="fsmone.direct.bond.factsheet.call.schedule" translate-default="Call Schedule"></span></h4>
				<nz-table [nzData]="['']" [nzShowPagination]="false">
					<thead>
						<tr>
							<th translate="fsmone.direct.bond.factsheet.call.date" translate-default="Call Date"></th>
							<th translate="fsmone.direct.bond.factsheet.call.price" translate-default="Call Price"></th>
							<th translate="fsmone.bonds.net.yield.to.call" translate-default="Net Yield to Call"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let callable of callableList">
							<td>{{callable.date}}</td>
							<td *ngIf="callable.price && callable.price!='-'">{{callable.price | number : '1.3-3'}}</td>
							<td *ngIf="!callable.price || callable.price=='-'">-</td>
							<td *ngIf="callable.nytm && callable.nytm!='-'">{{callable.nytm | number : '1.3-3'}}</td>
							<td *ngIf="!callable.nytm || callable.nytm=='-'">-</td>
						</tr>
					</tbody>
				</nz-table>
			</nz-card>
		</nz-col>
	</nz-row>
	<nz-row class="d-flex flex-wrap pt-2" *ngIf="bondFeatureList.isMakeWholeCall && makeWholeCallList && makeWholeCallList.length>0">
		<nz-col nzXs="24" nzMd="24" nzLg="24">
			<nz-card class="h-100">
				<h4><span translate="fsmone.direct.bond.factsheet.make.whole.call" translate-default="Make Whole Call"></span></h4>
				<nz-table [nzData]="['']" [nzShowPagination]="false">
					<thead>
						<tr>
							<th translate="fsmone.bonds.make.whole.call.valid.until" translate-default="Make Whole Call Valid Until"></th>
							<th translate="fsmone.bonds.description" translate-default="Description"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let makeWholeCall of makeWholeCallList">
							<td>{{makeWholeCall.date}}</td>
							<td [innerHtml]="makeWholeCall.desc"></td>
						</tr>
					</tbody>
				</nz-table>
			</nz-card>
		</nz-col>
	</nz-row>
</div>