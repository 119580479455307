import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {IBondYieldCurveChartModel} from '@api-module/model/bond/i-bond-yield-curve-chart.model';
import * as Highcharts from "highcharts";
import exporting from 'highcharts/modules/exporting';
import {Chart} from "highcharts";
import {AppConstant, HighchartsConstant, DateConstant} from 'src/app/constant';
import {BondService} from "@api-module/rest/bond.rest.service";
import {Observable} from 'rxjs';
import {GlobalState} from '../../../../core/store';
import {Select} from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { formatDate } from '@angular/common';
exporting(Highcharts);

@Component({
  selector: 'app-bond-yield-curve-maturity-chart',
  templateUrl: './bond-yield-curve-maturity-chart.component.html',
  styleUrls: ['./bond-yield-curve-maturity-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BondYieldCurveMaturityChartComponent implements OnChanges {

  @Input() yieldCurveList: IBondYieldCurveChartModel;
  @Input() maturityMonth: Array<any>;
  @Input() selectedCountry: string;
  @Input() selectedCountryName:string;
  @Input() type:string;
  @Input() fullScreen:boolean;
  @Output() readonly zoom=new EventEmitter<any>();
  @Output() readonly exitZoom=new EventEmitter<any>();
  yieldCurveMaturityList: Array<any> = [];
  months: Array<string> = [];
  selectedMonth: string = "";
  selectedMonthText: string = "";
  updateFromInput = false;
  Highcharts = Highcharts;
  chart: Highcharts.Chart;
  country: string = "";
  date: string = "";
  activeButton:string="";
  loading:boolean=false;
  locale:string;
  localeOption: string;
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  chartOptions = {
	chart: {
	  type: 'area',
	  plotBorderWidth: null,
	  plotShadow: false,
	  spacingTop: 20
	},
    credits: HighchartsConstant.CREDIT_CONFIG,
	lang: {
		printChart   : this.translateService.instant('fsmone.bond.yield.curve.print.chart'),
    	downloadPNG  : this.translateService.instant('fsmone.bond.yield.curve.download.png.image'),
        downloadJPEG : this.translateService.instant('fsmone.bond.yield.curve.download.jpeg.image'),
        downloadPDF  : this.translateService.instant('fsmone.bond.yield.curve.download.pdf.document'),
        downloadSVG	 : this.translateService.instant('fsmone.bond.yield.curve.download.svg.vector.image')
	},
    exporting: {
		enabled: true,
		buttons: {
			contextButton: {
				menuItems: [
					'printChart',
					'separator',
					'downloadPNG',
					'downloadJPEG',
					'downloadPDF',
					'downloadSVG'
				]
			}
		}
	},
    legend: {
    	enabled: false
    },
    plotOptions: {
      series: {marker: {enabled: false}},
      area: {
          lineColor: '#004BAD',
          fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
             [0, 'rgba(144, 175, 214, 0.4)'],
             [1, 'rgba(255, 255, 255, 0.5)']
            ]
          },
      }
    },
    title: {
      text: undefined
    },
    xAxis: {
      type: HighchartsConstant.DATETIME,
      title: {
        text: "<b>"+this.translateService.instant('fsmone.bond.yield.curve.time.to.maturity')+"</b>"
      }
    },
    yAxis: {
      title: {
        text: "<b>"+this.translateService.instant('fsmone.bond.market.chart.yield.axis.y')+"</b>",
        align: 'high',
        y: -10,
        rotation: 360,
        offset:-50,
        margin:100
      }
    },
    tooltip: {
      xDateFormat: '%e-%m-%Y',
        shared: true,
        valueDecimals: 4
    },
    series: []
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private bondService: BondService, 
    private translateService: TranslateService,
    private globalDataStorage: GlobalDataStorage) {
      this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
    }


  ngOnChanges() {
	console.log("HELLO " + this.selectedCountry)
	 if (this.selectedCountry != null &&  this.maturityMonth.length>0) {
	   if('firstChart'===this.type){
		if(this.selectedCountry === 'US') {
		   this.selectedMonth = this.maturityMonth[4][2];
		   this.selectedMonthText = this.maturityMonth[4][1];
		   this.activeButton= this.maturityMonth[4][0];
		} else {
		   this.selectedMonth = this.maturityMonth[0][2];
		   this.selectedMonthText = this.maturityMonth[0][1];
		   this.activeButton= this.maturityMonth[0][0];
		}
	   }else{
		if(this.selectedCountry === 'US') {
		   this.selectedMonth = this.maturityMonth[8][2];
		   this.selectedMonthText = this.maturityMonth[8][1];
		   this.activeButton= this.maturityMonth[8][0];
		} else {
		   this.selectedMonth = this.maturityMonth[1][2];
		   this.selectedMonthText = this.maturityMonth[1][1];
		   this.activeButton= this.maturityMonth[1][0];
		}
	   } 
	   this.getYieldCurveMaturityChartData();
	 }
  }

  getYieldCurveMaturityChartData() {
	  	this.loading=true;
	    const countryCode = this.selectedCountry;
	    this.bondService.findBondYieldCurveMaturityChart(countryCode, this.selectedMonth).subscribe((res: any) =>{
	      if (AppConstant.RESPONSE_SUCCESS == res.status) {
	        this.yieldCurveMaturityList = res.data["xAxis"];
	        this.populateChart();
	        this.loading=false;
	        this.chart.reflow()
	      }
	    });
  }

  populateChart() {
	let seriesData: any[] = [];

  	if (this.yieldCurveMaturityList != undefined) {
	    this.yieldCurveMaturityList.forEach(item => {
        item[0] = this.convertDate(item[0]);
	    });

	    seriesData.push({
        name: this.selectedCountryName + " " + this.getMaturityMonthInText(this.selectedMonth),
	      data: this.yieldCurveMaturityList
	    });

	    this.chartOptions.series = seriesData;
	  } else {
	    this.chartOptions.series = [];
	  }

	  this.updateFromInput = true;
	  this.cdRef.markForCheck();
  }

  ngAfterViewInit(): void {
	  this.cdRef.detach();
	  this.cdRef.reattach();
  }

  getChartInstance(chart: Chart) {
	  this.chart = chart;
  }

  convertDate(date: number | Date): number {
	   if (!(date instanceof Date)) {
	      date = new Date(date);
	    }
			let hktDate = DateConstant.bypassTimeZone(date);
			return Date.UTC(hktDate.getFullYear(), hktDate.getMonth(), hktDate.getDate());
  }

  onClickMonth(data){
	  this.selectedMonth = data[2];
	  this.selectedMonthText = data[1];
	  this.activeButton= data[0];
	  this.getYieldCurveMaturityChartData();
  }

  onZoomChange(){
	  this.zoom.emit({fullscreen:true,type:this.type});
  }

  onExitZoom(){
	  this.exitZoom.emit({exitZoom:true});
  }

  getMaturityMonthInText(maturityMonth) {
    var cat = parseInt(maturityMonth);
      var dateText = this.translateService.instant('fsmone.bonds.yield.curve.month');
    if (cat >= 12) {
      dateText = this.translateService.instant('year');
      dateText = (cat / 12) + ' ' + dateText + (((cat / 12) > 1 && this.locale == 'en') ? "s" : "");
    } else {
      dateText = cat + ' ' + dateText + ((cat > 1 && this.locale == 'en') ? "s" : "");
    }
    return dateText;
  }

  ngOnInit() {
    let locale = this.globalDataStorage.getStorage('locale') || 'ch';
    if ("en" === locale) {
      this.localeOption = "en-US";
    } else if ("ch" === locale) {
      this.localeOption = "zh-TW";
    } else if ("zh" === locale) {
      this.localeOption = "zh-CN";
    }
  }
}
