import {Component, Input, OnInit} from "@angular/core";
import {SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {Select} from '@ngxs/store';
import {GlobalState} from 'src/app/core/store';
import {Observable} from 'rxjs';
import {IBondInfoAndPerformanceModel} from '@api-module/model/bond/i-bond-info-and-performance.model';
import {ITabModel} from '@api-module/model/common/i-tab.model';
import {BondService} from '@api-module/rest/bond.rest.service';
import { AppConstant,DateConstant } from 'src/app/constant';
import {IDropdownModel} from '@api-module/model/common/i-dropdown.model';
import {IResponse} from '@api-module/model/common/i-response';
import {Chart} from 'highcharts';
import {NzModalService} from 'ng-zorro-antd';
import {ChartCenterBase} from '../../../chart-centre/base/chart-centre-base'
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-bond-chart-centre-shared',
  templateUrl: './bond-chart-centre-shared.component.html',
  providers: [SubHandlingService]
})

export class BondChartSharedComponent extends ChartCenterBase implements OnInit {

  constructor(private bondService: BondService,
              private modalService: NzModalService,private translateService: TranslateService) {
    super();
	this.chartConfiguration.yAxis = {
      	title: {
        	text: this.translateService.instant('fsmone.bond.market.chart.performance.axis.y')
      	}
    }
	this.chartConfiguration.subtitle.text = this.translateService.instant('fsmone.chart.zoom.tips');
	this.chartConfiguration.subtitle.align = 'center';
  }

  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  @Input() selectedIssueCode: string;
  @Input() onlyChart: boolean;

  selectedBondList: any[] = [];
  fullscreen = false;
  isEmpty = true;
  isLoading = true;
  isClear = false;
  colorIndex = 0;
  updateFlag = false;
  chart: Highcharts.Chart;
  readonly BOND_CHART_CENTRE_IMG_PATH = 'assets/images/bond/bond-chart-centre/';
  chartPeriod = 'ONE_MTH';
  GRAFT_TYPE_RETURN = 'RETURN';
  GRAFT_TYPE_YIELD = 'YIELD';
  marketType: string;
  indexMarketType: string;
  allBondOptions: any[] = [];
  bondOptions: IDropdownModel[] = [] as IDropdownModel[];
  graphType: string;
  loadingBond = true;

    readonly tabOptions: ITabModel[] = [
	    {
	      label: this.translateService.instant('fsmone.1.month'),
		  chartPeriod: 'ONE_MTH',
	      value: 'w4',
		  active: true
	    },
	    {
	      label: this.translateService.instant('fsmone.bonds.three.month'),
	      value: 'w12',
          chartPeriod: 'THREE_MTH',
	    },
	    {
	      label: this.translateService.instant('fsmone.6.months'),
	      value: 'w52Cum',
		  chartPeriod: 'SIX_MTH'
	    },
	    {
	      label: this.translateService.instant('fsmone.1.year'),
	      value: 'w162Cum',
		  chartPeriod: 'ONE_YEAR'
	    },
	    {
	      label:  this.translateService.instant('fsmone.3.years'),
	      value: 'fiveYrCum',
		  chartPeriod: 'THREE_YEAR'
	    },
	    {
	      label:  this.translateService.instant('fsmone.since.inception'),
	      value: 'sinceInception',
		  chartPeriod: 'SINCE_INCEPTION'
	    }
	  ];

  ngOnInit(): void {
	  this.Highcharts.setOptions({
		lang: {   
			noData: this.translateService.instant('fsmone.no.result'),
			months: [this.translateService.instant('fsmone.bonds.factsheet.highcharts.january'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.febuary'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.march'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.apr'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.may'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.june'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.july'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.aug'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.september'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.october'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.november'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.december')],
			shortMonths: [this.translateService.instant('fsmone.bonds.factsheet.highcharts.jan'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.feb'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.mar'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.apr'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.may'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.jun'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.jul'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.aug'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.sep'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.oct'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.nov'),this.translateService.instant('fsmone.bonds.factsheet.highcharts.dec')],
			weekdays: [this.translateService.instant('bond.factsheet.highcharts.sunday'),this.translateService.instant('bond.factsheet.highcharts.monday'),this.translateService.instant('bond.factsheet.highcharts.tuesday'),this.translateService.instant('bond.factsheet.highcharts.wednesday'),this.translateService.instant('bond.factsheet.highcharts.thursday'),this.translateService.instant('bond.factsheet.highcharts.friday'),this.translateService.instant('bond.factsheet.highcharts.saturday')],
			shortWeekdays: [this.translateService.instant('bond.factsheet.highcharts.sunday'),this.translateService.instant('bond.factsheet.highcharts.monday'),this.translateService.instant('bond.factsheet.highcharts.tuesday'),this.translateService.instant('bond.factsheet.highcharts.wednesday'),this.translateService.instant('bond.factsheet.highcharts.thursday'),this.translateService.instant('bond.factsheet.highcharts.friday'),this.translateService.instant('bond.factsheet.highcharts.saturday')],
			resetZoom: this.translateService.instant('reset.zoom'),
			resetZoomTitle: this.translateService.instant("reset.zoom.level1"),
	    	printChart   : this.translateService.instant('fsmone.bond.yield.curve.print.chart'),
	    	downloadPNG  : this.translateService.instant('fsmone.bond.yield.curve.download.png.image'),
	        downloadJPEG : this.translateService.instant('fsmone.bond.yield.curve.download.jpeg.image'),
	        downloadPDF  : this.translateService.instant('fsmone.bond.yield.curve.download.pdf.document'),
	        downloadSVG	 : this.translateService.instant('fsmone.bond.yield.curve.download.svg.vector.image')
		}
	  });
    this.findAllActiveBonds();
    this.graphType = this.GRAFT_TYPE_RETURN;
  }

  findAllActiveBonds() {
    this.loadingBond = true;

	this.bondService.getBondMarketList().subscribe((marketRes: any) =>{
		if (AppConstant.RESPONSE_SUCCESS !== marketRes.status) {
	        console.error('Something has went wrong');
	        return;
      	}
		
		var temp = marketRes.data;
		temp.sort((a, b) => (a.bondName > b.bondName) ? 1 : ((b.bondName > a.bondName) ? -1 : 0));

		for (const data of temp) {
			this.allBondOptions.push(data);
		}
		
		this.bondService.findAllActiveBonds().subscribe((res: any) =>{
	      	if (AppConstant.RESPONSE_SUCCESS !== res.status) {
		        console.error('Something has went wrong');
		        return;
	      	}
			
			var temp = res.data;
			temp.sort((a, b) => (a.bondName > b.bondName) ? 1 : ((b.bondName > a.bondName) ? -1 : 0));
			for (const data of temp) {
				this.allBondOptions.push(data);
			}

	      	for (const data of this.allBondOptions) {
		        this.bondOptions.push({
		          label: data.bondName,
		          value: data.issueCode
		        });
	      	}
	      
		  	if (this.selectedIssueCode) {
	        	this.initQueryParamBondList();
	      	}

      		this.loadingBond = false;
    	});
	});
  
  }

  onChangeBondList($event) {
    for (const data of this.selectedBondList) {
      if (data.issueCode === $event.issueCode) {
        return;
      }
    }
    if (this.selectedBondList.length > 6) {
      this.modalService.error({nzTitle: 'Error', nzContent: 'Maximum 7 Bonds', nzOkType: 'theme'})
      return;
    }

    for (let i = 0; i < this.allBondOptions.length; i++) {
      if (this.allBondOptions[i].issueCode === $event.issueCode) {
        this.selectedBondList.push(
          {
            issueCode: this.allBondOptions[i].issueCode,
            bondName: this.allBondOptions[i].bondName,
            type: this.allBondOptions[i].type,
            color: this.plotColors[this.colorIndex],
            bondCurrencyCode: null,
            data: []
          }
        );
        this.colorIndex = this.colorIndex + 1;
      }
    }

    if (this.indexMarketType === 'MKT') {
      this.selectedBondList.push(
        {
          issueCode: $event.issueCode,
          bondName: $event.bondName,
          color: this.plotColors[this.colorIndex],
          bondCurrencyCode: null,
          data: []
        }
      );
      this.colorIndex = this.colorIndex + 1;
    }
    this.getBondChartData();
    this.getBondTableData();
    this.isEmpty = false;
    this.isLoading = false;
  }

  getChartInstance(chart: Chart) {
    this.chart = chart;
  }

  getBondTableData(): void {
	var issueCodeList :string[] = [];
	for (const data of this.selectedBondList) {
		issueCodeList.push(data.issueCode);
	}
	
    this.bondService.findBondPerformance(issueCodeList, this.chartPeriod).subscribe((response: IResponse<any>) =>
	{
          if (response.status !== AppConstant.RESPONSE_SUCCESS) {
            this.isEmpty = true;
            return;
          }
          if (response.data) {
		  	this.selectedBondList.forEach((data, index) => 
			{
                data.minBidPrice = response.data[index].minPrice;
                data.maxBidPrice = response.data[index].maxPrice;
                data.currentBidPrice = response.data[index].currentPrice;
                data.returns = response.data[index].totalReturn;
                //data.currentNavDate = response.data.priceDate;
               // data.bondCurrencyCode = response.data.bondCurrencyCode;
			});
          }
        });
  }
 
  getBondChartData(): void {
    if (this.indexMarketType === 'MKT') {
      this.marketType = 'MKT';
    } else {
      this.marketType = 'TR';
    }
	var issueCodeList :string[] = [];
	for (const data of this.selectedBondList) {
		issueCodeList.push(data.issueCode+'-'+data.type);
	}
	this.chartConfiguration.series = [];
	
    this.bondService.findBondChart(issueCodeList, this.graphType, this.chartPeriod,"bond").subscribe((res: IResponse<any>) =>
	{
          if (res.status !== AppConstant.RESPONSE_SUCCESS) {
            this.isEmpty = true;
            return;
          }

		if (res.data)
		{
			this.selectedBondList.forEach((value, index) => 
			{
						
	        const seriesData: any[] = [];
	        
		    res.data[index].forEach(chartData => {
		   		seriesData.push([
                    this.convertDate(chartData[0]),
		            chartData[1]
		        ]);
		    });
		    
			value.data = seriesData;
		    this.drawChart(value);
	        });
            }
        });
  }

  convertDate(date: number | Date): number {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
		let hktDate = DateConstant.bypassTimeZone(date);
		return Date.UTC(hktDate.getFullYear(), hktDate.getMonth(), hktDate.getDate());
  }

  drawAllChart(listToDraw: IBondInfoAndPerformanceModel[]): void {
    this.chartConfiguration.series = [];
    for (const data of listToDraw) {
      this.chartConfiguration.series.push({
        name: data.bondName,
        data: data.data,
        type: 'line',
        color: data.color,
      });
    }
    this.updateFlag = true;
  }

  drawChart(data: any): void {
    this.chartConfiguration.series.push({
      name: data.bondName,
      data: data.data,
      type: 'line',
      color: data.color,
    });
    if (this.selectedBondList.length <= 1) {
      this.updateFlag = true;
    } else if (this.selectedBondList.length > 1) {
      this.updateFlag = this.selectedBondList.length === this.chartConfiguration.series.length;
    }
  }

  onClearAllBonds($event): void {
    this.isClear = $event.clear;
    if (this.isClear) {
      this.selectedBondList = [];
      this.chartConfiguration.series = [];
      this.isEmpty = true;
      this.colorIndex = 0;
    }
  }

  onClearChartBond($event) {
    for (let i = 0; i < this.selectedBondList.length; i++) {
      if (this.selectedBondList[i].issueCode === $event.issueCode) {
        this.selectedBondList.splice(i, 1);
      }
    }
    this.drawAllChart(this.selectedBondList);
    if (this.selectedBondList.length === 0) {
      this.isEmpty = true;
      this.colorIndex = 0;
    }
  }

  onPeriodChange(value: { rankPeriod: string, chartPeriod: string }) {
    this.chartConfiguration.series = [];
    this.chartPeriod = value.chartPeriod;
    this.changeActivePeriod(this.chartPeriod);
    this.getAllBondChartData();
    this.getAllBondTableData();
    this.updateFlag = true;
  }

  getAllBondChartData(): void {
    this.isEmpty = false;
    this.isLoading = true;
    this.getBondChartData();
    this.isEmpty = false;
    this.isLoading = false;
  }

  getAllBondTableData(): void {
      this.getBondTableData();
  }

  changeActivePeriod(period: string) {
	console.log(period);
    for (const option of this.tabOptions) {
      option.chartPeriod == period
        ? option.active = true
        : option.active = false;
    }
  }

  onZoomChange($event) {
    this.fullscreen = $event.fullscreen;
    this.drawAllChart(this.selectedBondList);
  }

  getMarketType($event) {
    this.indexMarketType = $event;
  }

  changeChartDataOnGraphType(selectedGraphType: string) {
	if(selectedGraphType===this.GRAFT_TYPE_RETURN){
		this.chartConfiguration.yAxis = {
	      	title: {
	        	text: this.translateService.instant('fsmone.bond.market.chart.performance.axis.y')
	      	}
	    }
	} else if (selectedGraphType===this.GRAFT_TYPE_YIELD){
		this.chartConfiguration.yAxis = {
	      	title: {
	        	text: this.translateService.instant('fsmone.bond.chart.centre.yield')
	      	}
	    }
	}
	
    this.chartConfiguration.series = [];
    this.graphType = selectedGraphType;
    if (this.selectedBondList.length > 0) {
        this.getBondChartData();
    }
  }

  private initQueryParamBondList() {
      for (let i = 0; i < this.allBondOptions.length; i++) {
        if (this.allBondOptions[i].issueCode === this.selectedIssueCode) {
          this.selectedBondList.push(
            {
              issueCode: this.allBondOptions[i].issueCode,
              bondName: this.allBondOptions[i].bondName,
              color: this.plotColors[this.colorIndex],
              bondCurrencyCode: null,
              data: []
            }
          );
          this.colorIndex = this.colorIndex + 1;
        }
      }
      this.getBondChartData();
      this.getBondTableData();
      this.isEmpty = false;
      this.isLoading = false;
  }
}
