import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {StockConstant} from "src/app/constant";

@Pipe({
  name: 'stockPrice'
})

export class StockPricePipe implements PipeTransform {
  public constructor(private decimalPipe: DecimalPipe){}

  transform(value: any,  exchange: string, dependentPrice?: any): any {
    if(!value || typeof value === 'undefined') {
      if (isNaN(value) || value == null) {
        return value;
      }
    }
    let oriValue = value;
    if(!exchange || typeof exchange === 'undefined') {
      if (exchange == null && !StockConstant.EXCHANGE_LIST.some(item => item.label === exchange)) {
        return value;
      }
    }
    if (dependentPrice && typeof dependentPrice !== 'undefined') {
      if (!isNaN(dependentPrice)) {
        value = dependentPrice;
      }
    }

    if ((StockConstant.NASDAQ === exchange || StockConstant.AMEX === exchange || StockConstant.NYSE === exchange || StockConstant.BATS === exchange) && value < 1) {
      return this.decimalPipe.transform(oriValue, this.getDigitsInfo(4));
    } else {
      return this.decimalPipe.transform(oriValue, this.getDigitsInfo(3));
    }
  }

  getDigitsInfo(decimalPlaces: number): string{
    return '1.' + decimalPlaces.toString() + '-' + decimalPlaces.toString();
  }
}
