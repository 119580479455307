import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {RouteConstant} from "@constant";
import {Store} from "@ngxs/store";
import {AuthState} from "@auth-module/store";
import {tap} from "rxjs/operators";
import {AuthService} from "@auth-module/service/auth.service";

@Injectable({
    providedIn: 'root'
})
export class TwoFaGuard implements CanActivate{
    private auth: boolean = false;

    constructor(private router: Router,
                private store: Store,
                private authService: AuthService,) {}

     canActivate(): boolean {

         let isAcctBlocked: boolean = this.store.selectSnapshot(AuthState.getIsAcctBlocked);
         if (isAcctBlocked) {
             this.authService.logout();
             return false;
         }

         this.store.select(AuthState.isAuthenticated).pipe(
             tap((auth: boolean) => this.auth = auth)
         ).subscribe();

         const isValid = this.authService.is2FAValid();

         let isSMSVerified: boolean = this.store.selectSnapshot(AuthState.getReRoute2FA);

         const route: string[] = isSMSVerified ?
             [RouteConstant.TWO_FA, RouteConstant.MAIN] :
             [RouteConstant.TWO_FA, RouteConstant.REGISTRATION];

         if (this.auth && !isValid) {
             this.router.navigate(route);
         }

         return true;
    }

}
