import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { GlobalDataStorage } from "src/app/share/storages/global-data.storage";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class CommonService {
    moduleInfoList = new BehaviorSubject<Array<any>>([]);
    pageList = new BehaviorSubject<Array<string>>([]);

    currentModuleInfoList = this.moduleInfoList.asObservable();
    currentPageList = this.pageList.asObservable();

    constructor(
        private globalDataStorage: GlobalDataStorage,
        private router: Router
    ) {}

    notifySubscribers(): void {
        this.moduleInfoList.next(this.globalDataStorage.moduleInfoList);
        this.pageList.next(this.globalDataStorage.pageList);
    }

    getPostLoginCurrentPageId(): string {
        const url: string = this.router.url;
        if (!url || url.length == 0) {
            return "";
        }
        const urlSegments: string[] = url.split("/");
        if (urlSegments.length < 4 || urlSegments[1] != "post-login") {
            return "";
        }

        return urlSegments[3];
    }
}
