import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'read-more',
  templateUrl: './read-more.component.html',
})
export class ReadMoreComponent implements OnInit {

  @Input() position: 'center' | 'default' = 'default';
  @Input() showTextHeight: number = 350;

  isCollapsed: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  get isTextOverflow(): boolean {
    const elem: HTMLElement = document.getElementById('readme-container');
    if (elem) {
      return (elem.offsetHeight < elem.scrollHeight);
    }
    else {
      return false;
    }
  }

  onCollapsedClick(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
