import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate} from '@angular/router';
import {AuthState} from '@auth-module/store/state/auth.state';
import {Store} from "@ngxs/store";
import { AppConstant } from '@constant';
import { LoginRestService } from '@api-module/rest/login.rest.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SecurityRestService } from '@api-module/rest/security.rest.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
        private securityRestService: SecurityRestService
    ) { }

    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        const isAuth = this.store.selectSnapshot(AuthState.isAuthenticated);
        if(isAuth) {
            return this.securityRestService.isModuleEnabled("FSMONE_POSTLOGIN").pipe(
                map((res: any) => {
                    if (res && res.status === AppConstant.RESPONSE_SUCCESS && res.data === true) {
                        return true;
                    }else{
                        this.router.navigate(['login'], {queryParams: {redirectTo: state.url}});
                        return false;
                    }
                })
            )
            //return true;    
        }else {
            this.router.navigate(['login'], {queryParams: {redirectTo: state.url}});
            return false;
        }
    }
}