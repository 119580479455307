<h5 class="font-weight-bold text-md mb-3">{{ 'fsmone.automatically.add.purchased.stocks.to.watchlist' | translate }}</h5>

<nz-radio-group class="mb-2" nzButtonStyle="solid" [(ngModel)]="stockAutoAddWatchlist">
  <label nz-radio-button [nzValue]="'Y'" class="px-3 py-0">{{ 'yes' | translate }}</label>
  <label nz-radio-button [nzValue]="'N'" class="px-3 py-0">{{ 'no' | translate }}</label>
</nz-radio-group>
<p class="text-sm">{{ 'fsmone.you.can.update.it.in.order.pad.setting' | translate }}</p>

<div *ngIf="stockAutoAddWatchlist == 'Y'" class="mt-2">
  <nz-select class="ui-orderpad-select full" style="width: 300px;" nzLabelPlaceholder [(ngModel)]="stockDefaultWatchlist" [nzPlaceHolder]="'fsmone.watchlist.folder' | translate" [nzAutoFocus]="true">
    <nz-option *ngIf="isLoadingWatchlist" nzDisabled nzCustomContent>
      <i class="anticon anticon-loading anticon-spin loading-icon"></i> {{ 'loading' | translate }}...
    </nz-option>
    <ng-container *ngIf="!isLoadingWatchlist">
      <nz-option *ngFor="let option of watchlist" [nzValue]="option.listId" [nzLabel]="option.listName"></nz-option>
      <nz-option [nzCustomContent]="true" [nzValue]="stockDefaultWatchlist">
        <a class="d-inline-flex align-items-center" (click)="toggleCreateNewWatchlist(true)"><i class="b2b b2b-e-add-1_filled font-size-sm mr-1"></i>{{ 'fsmone.watchlist.add.watchlist' | translate }}</a>
      </nz-option>
    </ng-container>
  </nz-select>
</div>

<div class="text-right mt-4">
  <button nz-button nzType="default" class="mr-2" (click)="closeModal()" [disabled]="isLoading">{{ 'cancel' | translate }}</button>
  <button nz-button nzType="theme" (click)="save()" [nzLoading]="isLoading" [disabled]="isLoading || stockAutoAddWatchlist == 'null'">{{ 'fsmone.watchlist.save.setting' | translate }}</button>
</div>

<nz-modal [(nzVisible)]="createNewWatchlistVisible" [nzTitle]="'fsmone.watchlist.add.watchlist' | translate" nzClosable="false" [nzFooter]="createWatchlistFooter">
  <nz-input-group class="ui-orderpad-input border-bottom rounded">
    <input type="text" nz-input placeholder=" {{'fsmone.watchlist.add.watchlist' | translate}}" [(ngModel)]="newWatchlistName"/>
  </nz-input-group>
  <ng-template #createWatchlistFooter>
    <button nz-button nzType="default" (click)="toggleCreateNewWatchlist(false)">{{'cancel' | translate}}</button>
    <button nz-button nzType="theme" [nzLoading]="isCreateNewWatchlistLoading" (click)="createNewWatchlist()">{{'create' | translate}}</button>
  </ng-template>
</nz-modal>
