import { Component, OnInit } from '@angular/core';
import { RouteConstant } from 'src/app/constant';
import { Router } from '@angular/router';
import { AuthService } from '@auth-module/service/auth.service';
import { genRouterLink } from "@util/route";
import { ResponsiveService } from '@core/service/responsive.service';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataStorage } from '@share/storages/global-data.storage';
import { tap } from 'rxjs/operators';
import { AuthState } from '@auth-module/store';
import { Idle } from '@ng-idle/core';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutPageComponent implements OnInit 
{
	loginLink: any = genRouterLink(RouteConstant.LOGIN);		
	readonly RouteConstant = RouteConstant;

	currentDate: Date = new Date(); // dummy date only
	loginTime: Date = new Date();
	isMobileView: boolean = false;

	constructor(
		private router: Router,
		private store: Store,
		private authService: AuthService,
		private responsiveService: ResponsiveService,
		private translateService : TranslateService,
		private idle: Idle,
		private globalDataStorage: GlobalDataStorage
  	) {
	}

	ngOnInit(): void {
		this.isMobileView = this.responsiveService.isMobileView();
    	this.responsiveService.mobileViewEvent.subscribe(state => this.isMobileView = state);
		this.loginTime = window.history.state?.loginTime;
		if(("/"+RouteConstant.FSM_LOGOUT) === this.router.url){
			this.logoutFromFsm();
		}
		this.autoRedirectHome();
	}

  	refresh() {
    	window.location.replace(`/${RouteConstant.LOGIN}`);
  	}

	logoutFromFsm(){
		this.authService.logout();
	}
	
	goToHomepage()
	{
		this.router.navigate(['/']);
	}

	autoRedirectHome() {
		setTimeout(() => {
			this.router.navigate(['/']);
			this.idle.stop();
		}, 5000);
	}

	convertMillisecondsToHoursMinutesSeconds = (milliseconds) => {
		var locale = this.globalDataStorage.getStorage('locale');
		const hours = Math.floor(milliseconds / 3600000);
		const minutes = Math.floor((milliseconds % 3600000) / 60000);
		const seconds = Math.floor(((milliseconds % 360000) % 60000) / 1000);
		const hoursText = this.translateService.instant('hours');
		const minutesText = this.translateService.instant('fsmone.minutes');
		const secondsText = this.translateService.instant('fsmone.seconds');
		return locale === 'en' ? `${hours} ${hoursText} ${minutes} ${minutesText} ${seconds} ${secondsText}` : `${hours}${hoursText} ${minutes}${minutesText} ${seconds}${secondsText}`;
	}
}
