import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import { genRouterLink } from '@util/route';
import { Observable } from "rxjs";
import { IResponse } from "@api-module/model/common/i-response";
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import { AuthState } from "@auth-module/store";
import { unescapeHtml } from "@util/html";
import { environment } from "@env";
import {ArticleCategory} from '@api-module/enum/i-fund-article-category.enum';
import {ArticleRestService} from 'src/app/api/rest/article.rest.service';
import {IArticleRcmsTwModel} from '@api-module/model/article/i-article-rcms-tw.model';
import {ArticleHighlightContainerComponent} from '../article-highlight-container/article-highlight-container.component';
import {Select} from '@ngxs/store';
import {GlobalState} from '../../../../core/store';
import { getEnv } from '@share/services/product-search.service';

@Component({
  selector: 'article-product-research',
  templateUrl: './product-research.component.html',
  styles: [],
  providers: [SubHandlingService]
})

export class ProductResearchComponent implements OnInit {
  
  BOND_LANDING_IMG_PATH = 'assets/images/bond/bond-landing/';
  PRODUCTS_ICON_IMAGES_PATH = 'assets/images/general/icons/product/';
  readonly FUNDS: string = 'FUNDS';
  readonly BONDS: string = 'BONDS';
  readonly STOCK: string = 'STOCK';
  readonly ETF: string = 'ETF';
  readonly DPMS: string = 'DPMS';
  @Input() type: string;
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  selected= this.FUNDS;
  articleCategory = ArticleCategory;
  loading:boolean = false;
  main_article :any;
  category_map: Map<string, any> = new Map<string, any>([]);
  article_fund: IArticleRcmsTwModel[] = [];
  title_1:string;
  article_bond: IArticleRcmsTwModel[] = [];
  title_2:string;
  title_3:string;
  article_etf: IArticleRcmsTwModel[] = [];
  title_4:string;
  latest_stock: IArticleRcmsTwModel[] = [];
  market_stock: IArticleRcmsTwModel[] = [];
  article_dpms: IArticleRcmsTwModel[] = [];
  title_5:string;

  constructor(private subHandlingService: SubHandlingService,
			  private articleRestService: ArticleRestService,
				private router: Router,) {}

  ngOnInit(): void {
	this.identifyType();
  }

  findResearchInsight()
  {
	/*this.loading = true;
	this.articleRestService.findProductLandingPageArticleList().subscribe((response: any) =>{
	
		if (response.status !== AppConstant.RESPONSE_SUCCESS) {
            return;
         }
		this.main_article = response.data;
		this.assignArticles();
		this.loading = false;
	})*/
  }

  identifyType(){
	if(this.type=="fund"){
		this.selected = this.FUNDS;
	}else if(this.type=="bond"){
		this.selected = this.BONDS;		
	}else if(this.type=="etf"){
		this.selected = this.ETF;
	}else if(this.type=="stock"){
		this.selected = this.STOCK;
	}else if(this.type=="dpms"){
		this.selected = this.DPMS;
	}
	this.findResearchInsight();
  }
  
  assignArticles()
  {
		this.article_fund = this.main_article.funds[1];
		this.article_bond = this.main_article.bonds[1];
		this.article_etf = this.main_article.etf[1];
		this.latest_stock = this.main_article.stock.latest;
		this.market_stock = this.main_article.stock.market;
		this.article_dpms = this.main_article.dpms[1];
  }

  changeCategory(cat){
	this.selected = cat;
	switch(cat){
		case this.FUNDS:
			console.log('fund');
			this.router.navigate([RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT]);
			break;
		case this.BONDS:
			console.log('bond')
			this.router.navigate([RouteConstant.BONDS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT]);
			break;
		case this.STOCK:
			this.router.navigate([RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT]);
			break;
		case this.DPMS:
			this.router.navigate([RouteConstant.DPMS, RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT]);
			break;
		case this.ETF:
			this.router.navigate([RouteConstant.ETFS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT]);
			break;
		default:
			break;
	}
	
  }

  redirectToArticleDetails(articleno){
	
	let url = genRouterLink(RouteConstant.ARTICLE,RouteConstant.ARTICLE_DETAILS,articleno);
	window.open(url.toString(),'_blank');
	
	//this.router.navigate([RouteConstant.ARTICLE,RouteConstant.ARTICLE_DETAILS,articleno,title]);
	/*var data = genRouterLink('article','view','rcms'+articleno);
	let FSM = '/fsm';
	window.open(getEnv()+FSM+data);*/
  }

  redirectToMore(){
	
  }
}
