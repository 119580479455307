import { Injectable } from '@angular/core';
import {
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivate
} from '@angular/router';

import { CommonService } from 'src/app/share/services/common.service';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import {AuthState} from '@auth-module/store/state/auth.state';
import {Store} from "@ngxs/store";
import {AuthService} from "@auth-module/service/auth.service";

@Injectable({
    providedIn: 'root'
})

export class PageAccessGuard implements CanActivateChild, CanActivate {

    private pageList: Array<string> = [];

    private defaultPageList: Array<string> = [
        'dashboard',
        'change-password',
        'privacy-statement',
        'disclaimer',
        'sp-disclaimer',
        'theme-configuration'
    ];

    //  Remark: Some pages may open a page not stored in module info
    //  Example: module-page-a and module-page-b will open page-xyz, then this array will have {pageId: 'page-xyz', pageList: ['module-page-a', 'module-page-b']}
    private dependentPageList: Array<any> = [
        {
            pageId: '',
            pageList: []
        }
    ];

    constructor(
        private commonService: CommonService,
        private globalDataStorage: GlobalDataStorage,
        private store: Store,
        private router: Router,
        private authService: AuthService,
    ) {
        this.commonService.pageList.subscribe(
            pageList => {
                this.pageList = pageList;
            }
        )
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;
        if (!url || url.length == 0) {
            return true;
        }

        //  Some default pages are allowed by access by everyone after login
        let defaultPageList: Array<string> = this.defaultPageList;
        defaultPageList = defaultPageList.filter(page => url == '/post-login/' + page);
        if (defaultPageList.length != 0) {
            return true;
        }

        //  Try to load page list from global data storage if empty
        if (this.pageList.length == 0) {
            if (this.globalDataStorage.pageList && this.globalDataStorage.pageList.length != 0) {
                this.pageList = this.globalDataStorage.pageList;
            }
        }

        //  Check current page with backend return page list
        if (this.isPermittedPage(url)) {
            return true;
        }

        //  Check dependent pages
        let dependentPageList: Array<any> = this.dependentPageList;
        for (let dependentPage of dependentPageList) {
            if (url.indexOf(dependentPage.pageId) == -1) {
                continue;
            }
            for (let page of dependentPage.pageList) {
                if (this.isPermittedPage(page)) {
                    return true;
                }
            }
        }

        // check for force update
        const authData = this.store.selectSnapshot(AuthState.getAuthData);
        const isAuth = this.store.selectSnapshot(AuthState.isAuthenticated);
        return this.authService.checkLoginRedirect(authData, isAuth, state);
    }

    canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const authData = this.store.selectSnapshot(AuthState.getAuthData);
        const isAuth = this.store.selectSnapshot(AuthState.isAuthenticated);
        return this.authService.checkLoginRedirect(authData, isAuth, state);
    }

    //  Remark: Check occurence instead of exact match, cause some url may have dynamic parameter
    private isPermittedPage(page: string): boolean {
        let pageList: Array<string> = this.pageList;
        pageList = pageList.filter(pageId => page.indexOf(pageId) !== -1);

        return (pageList.length != 0);
    }

}