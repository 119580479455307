export enum IFundArticleCategoryEnum {
  ALL = "all",
  RESEARCH = 'research',
  FIXED_INCOME = 'fixed_income',
  FUND_HOUSE_DEPOSITORY = 'fund_house_depository',
  FUNDS_AND_PERSONAL_FINANCE = 'funds_and_personal_finance',
  FSM_BUZZ = 'fsm_buzz',
  DPMS = 'dpms_restricted_portfolio_materials',
  BONDS = 'bonds',
  DEPOSITORY = 'depository',
  FUNDS = 'funds',
  CHINESE = 'chinese',
  STOCKS = 'stocks',
  STOCK_IDEAS = 'stock_ideas',
  STOCK_SCHOOL = 'stock_school',
  ETFS = 'etfs',
  ETF_IDEAS = 'etf_ideas',
  ETF_SCHOOL = 'etf_school',
  INSURANCE = 'insurance',
  GUIDE = 'guide',
  WEBCAST = 'videos',
}

export class ArticleCategory {
  public static ALL = new ArticleCategory("all",
    [
      IFundArticleCategoryEnum.FUNDS,
      IFundArticleCategoryEnum.RESEARCH,
      IFundArticleCategoryEnum.FIXED_INCOME,
      IFundArticleCategoryEnum.FUND_HOUSE_DEPOSITORY,
      IFundArticleCategoryEnum.FSM_BUZZ,
      IFundArticleCategoryEnum.FUNDS_AND_PERSONAL_FINANCE,
      IFundArticleCategoryEnum.GUIDE,
      // IFundArticleCategoryEnum.DPMS,
      IFundArticleCategoryEnum.BONDS,
      //Exclude insurance as it's not from RCMS
      IFundArticleCategoryEnum.STOCKS,
      IFundArticleCategoryEnum.STOCK_SCHOOL,
      IFundArticleCategoryEnum.ETFS,
      IFundArticleCategoryEnum.ETF_SCHOOL,
    ], false, IFundArticleCategoryEnum.ALL);

  public static FUNDS = new ArticleCategory(IFundArticleCategoryEnum.FUNDS,
    [
      IFundArticleCategoryEnum.FUNDS,
      IFundArticleCategoryEnum.RESEARCH,
      IFundArticleCategoryEnum.FIXED_INCOME,
      IFundArticleCategoryEnum.FSM_BUZZ,
      IFundArticleCategoryEnum.FUNDS_AND_PERSONAL_FINANCE,
    ],
    false,
    IFundArticleCategoryEnum.FUNDS);

  public static RESEARCH = new ArticleCategory(IFundArticleCategoryEnum.RESEARCH,
    [IFundArticleCategoryEnum.RESEARCH],
    false,
    IFundArticleCategoryEnum.RESEARCH);

  public static FIXED_INCOME = new ArticleCategory(IFundArticleCategoryEnum.FIXED_INCOME,
    [IFundArticleCategoryEnum.FIXED_INCOME],
    false,
    IFundArticleCategoryEnum.FIXED_INCOME);

  public static FUND_HOUSE_DEPOSITORY = new ArticleCategory(IFundArticleCategoryEnum.FUND_HOUSE_DEPOSITORY,
    [IFundArticleCategoryEnum.FUND_HOUSE_DEPOSITORY],
    false,
    IFundArticleCategoryEnum.FUND_HOUSE_DEPOSITORY);

  public static FUNDS_AND_PERSONAL_FINANCE = new ArticleCategory(IFundArticleCategoryEnum.FUNDS_AND_PERSONAL_FINANCE,
    [IFundArticleCategoryEnum.FUNDS_AND_PERSONAL_FINANCE],
    false,
    IFundArticleCategoryEnum.FUNDS_AND_PERSONAL_FINANCE);

  public static FSM_BUZZ = new ArticleCategory(IFundArticleCategoryEnum.FSM_BUZZ,
    [IFundArticleCategoryEnum.FSM_BUZZ],
    false,
    IFundArticleCategoryEnum.FSM_BUZZ);

  public static CHINESE = new ArticleCategory("中文文萃",
    [],
    true,
    IFundArticleCategoryEnum.CHINESE);

  public static DPMS = new ArticleCategory(IFundArticleCategoryEnum.DPMS,
    [IFundArticleCategoryEnum.DPMS],
    false,
    IFundArticleCategoryEnum.DPMS);

  public static MP_MARKET_ANALYSIS = new ArticleCategory("mp_market_analysis",
    [IFundArticleCategoryEnum.DPMS],
    false,
    IFundArticleCategoryEnum.DPMS,
    ["Market Analysis"]);

  public static MP_PERFORMANCE_REPORT = new ArticleCategory("mp_performance_report",
    [IFundArticleCategoryEnum.DPMS],
    false, IFundArticleCategoryEnum.DPMS,
    ["Performance Report"]);

  public static BONDS = new ArticleCategory(IFundArticleCategoryEnum.BONDS,
    [IFundArticleCategoryEnum.BONDS],
    false,
    IFundArticleCategoryEnum.BONDS);

  public static WEBCAST = new ArticleCategory(IFundArticleCategoryEnum.WEBCAST,
    [IFundArticleCategoryEnum.WEBCAST],
    false,
    IFundArticleCategoryEnum.WEBCAST);
  public static DEPOSITORY = new ArticleCategory(IFundArticleCategoryEnum.DEPOSITORY,
    [IFundArticleCategoryEnum.DEPOSITORY],
    false,
    IFundArticleCategoryEnum.DEPOSITORY);

  public static MP_FUND_MANAGER_INTERVIEW = new ArticleCategory("mp_fund_manager_interview",
    [IFundArticleCategoryEnum.DPMS],
    false,
    IFundArticleCategoryEnum.DPMS, ["Fund Manager Interview"]);

  public static STOCKS = new ArticleCategory(IFundArticleCategoryEnum.STOCKS,
    [IFundArticleCategoryEnum.STOCKS,
    IFundArticleCategoryEnum.STOCK_SCHOOL],
    false,
    IFundArticleCategoryEnum.STOCKS);

  public static STOCK_IDEAS = new ArticleCategory(IFundArticleCategoryEnum.STOCK_IDEAS,
    [IFundArticleCategoryEnum.STOCKS],
    false,
    IFundArticleCategoryEnum.STOCK_IDEAS);

  public static STOCK_SCHOOL = new ArticleCategory(IFundArticleCategoryEnum.STOCK_SCHOOL,
    [IFundArticleCategoryEnum.STOCK_SCHOOL],
    false,
    IFundArticleCategoryEnum.STOCK_SCHOOL);

  public static ETFS = new ArticleCategory(IFundArticleCategoryEnum.ETFS,
    [IFundArticleCategoryEnum.ETFS,
      IFundArticleCategoryEnum.ETF_SCHOOL],
    false,
    IFundArticleCategoryEnum.ETFS);

  public static ETF_IDEAS = new ArticleCategory(IFundArticleCategoryEnum.ETF_IDEAS,
    [IFundArticleCategoryEnum.ETFS],
    false,
    IFundArticleCategoryEnum.ETF_IDEAS);

  public static ETF_SCHOOL = new ArticleCategory(IFundArticleCategoryEnum.ETF_SCHOOL,
    [IFundArticleCategoryEnum.ETF_SCHOOL],
    false,
    IFundArticleCategoryEnum.ETF_SCHOOL);

  public static INSURANCE = new ArticleCategory(IFundArticleCategoryEnum.INSURANCE,
    [IFundArticleCategoryEnum.INSURANCE],
    false,
    IFundArticleCategoryEnum.INSURANCE);

  static articleCategories = [
    ArticleCategory.ALL,
    ArticleCategory.FUNDS,
    ArticleCategory.RESEARCH,
    ArticleCategory.FIXED_INCOME,
    ArticleCategory.FUND_HOUSE_DEPOSITORY,
    ArticleCategory.FUNDS_AND_PERSONAL_FINANCE,
    ArticleCategory.FSM_BUZZ,
    ArticleCategory.CHINESE,
    ArticleCategory.DPMS,
    ArticleCategory.MP_MARKET_ANALYSIS,
    ArticleCategory.MP_PERFORMANCE_REPORT,
    ArticleCategory.BONDS,
    ArticleCategory.DEPOSITORY,
    ArticleCategory.MP_FUND_MANAGER_INTERVIEW,
    ArticleCategory.STOCKS,
    ArticleCategory.STOCK_IDEAS,
    ArticleCategory.STOCK_SCHOOL,
    ArticleCategory.ETFS,
    ArticleCategory.ETF_IDEAS,
    ArticleCategory.ETF_SCHOOL,
    ArticleCategory.INSURANCE,
    ArticleCategory.WEBCAST
  ];

  readonly key: string;
  readonly fundArticleCategoryEnum: IFundArticleCategoryEnum;
  readonly categories: string[];
  readonly includeChinese: boolean;
  readonly tags: string[];

  constructor(key: string, categories: string[],
              includeChinese: boolean, fundArticleCategoryEnum: IFundArticleCategoryEnum,
              tags?: string[]) {
    this.key = key;
    this.categories = categories;
    this.fundArticleCategoryEnum = fundArticleCategoryEnum;
    this.includeChinese = includeChinese;
    this.tags = tags;
  }

  static of = (fundArticleCategoryEnum: IFundArticleCategoryEnum): ArticleCategory =>
    ArticleCategory.articleCategories
      .find(articleCategory => articleCategory.fundArticleCategoryEnum === fundArticleCategoryEnum);

  public static readonly MONTHLY_COMMENTARY = new ArticleCategory('',[IFundArticleCategoryEnum.DPMS], false, IFundArticleCategoryEnum.DPMS,[]);
}
