import { IProductEnum } from 'src/app/api/enum/i-product.enum';
import { RouteConstant } from 'src/app/constant/route.constant';
import { normaliseUrl } from '@util/url';

/**
 * get a factsheet route based on product
 * @param prod factsheet product type, e.g. funds, bonds, dpms, stock
 * @param code product code
 * @param src source of the origin (for Google Analytics purpose)
 * @param name product name (for SEO purpose)
 */
export function getRoute(prod: IProductEnum, code: string, src: string, name?: string, exchange?: string) {
  const ret: any = {};

  switch(prod) {
    case IProductEnum.UNIT_TRUST:
      ret.route = [RouteConstant.FUNDS, RouteConstant.FUND_INFO, RouteConstant.FACTSHEET,code];

	  ret.params = {
        fund: code
      };

      if (name) {
        ret.route.push(normaliseUrl(name));
      }
      break;
    case IProductEnum.BOND:
      ret.route = [RouteConstant.BONDS, RouteConstant.BOND_FACTSHEET,code];
      /*ret.params = {
        issueCode: code
      };*/

      if (name) {
        ret.route.push(normaliseUrl(name));
      }
      break;
    case IProductEnum.DPMS:
        ret.route = [RouteConstant.MANAGED_PORTFOLIOS, RouteConstant.PORTFOLIO_INSIGHTS, RouteConstant.PORTFOLIO_FACTSHEET];
      ret.params = {
        portfolioId: code
      };
      break;
    case IProductEnum.STOCK_AND_ETF:
	  var stockCode = '';
	  if(code.includes('.')){
		stockCode = code.split('.')[2];
	  } else {
		stockCode = code;
	  }
      if(name == undefined){
        name = 'stock'
      }
      name = name.replace(/[^0-9a-zA-Z']/gi, '-')
      ret.route = [RouteConstant.STOCKS,RouteConstant.TOOLS,RouteConstant.STOCKS_FACTSHEET];
      ret.params = {
        exchange: exchange,
        stock: stockCode
      }
      break;
    case IProductEnum.ETF:
	  var etfCode = '';
	  if(code.includes('.')){
		etfCode = code.split('.')[2];
	  } else {
		etfCode = code;
	  }
      if(name == undefined){
        name = 'stock'
      }
      if(name == undefined){
        name = 'etf'
      }
      name = name.replace(/[^0-9a-zA-Z']/gi, '-')
      ret.route = [RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.ETFS_FACTSHEET];
      ret.params = {
        exchange: exchange,
        stockCode: etfCode
      }
      break;
  }

  return {
    ...ret,
    params: {
      ...ret.params,
      
    }
  };
}
