import {Injectable} from "@angular/core";
import {ApiConstant} from "@api-module/api.endpoint.constant";
import {HttpClient} from "@angular/common/http";
import {HttpParamsService} from "@api-module/service/http-params.service";
import {IResponse} from "@api-module/model/common/i-response";
import {IValidationResultModel} from "@api-module/model/display/i-validation-result.model";
import {IForgotLoginRequestModel} from "@api-module/model/account/i-forgot-login-request.model";

@Injectable({
    providedIn: 'root'
})
export class CorpFirstLoginRestService {
    readonly BASE_URL: string = ApiConstant.CORP_FIRST_LOGIN;

    constructor(private http: HttpClient, private paramService: HttpParamsService) {
    }

    corpFirstLoginCheck(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.BASE_URL + ApiConstant.CORP_FIRST_LOGIN_CHECKING,forgotLoginRequestModel);
    }

    corpFirstLoginOtp(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.BASE_URL + ApiConstant.CORP_FIRST_LOGIN_OTP, forgotLoginRequestModel);
    }

    corpFirstLoginSubmit(forgotLoginRequestModel: IForgotLoginRequestModel){
        return this.http.post<IResponse<IValidationResultModel>>(this.BASE_URL + ApiConstant.CORP_FIRST_LOGIN_SUBMIT, forgotLoginRequestModel);
    }
}