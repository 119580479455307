import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UicHorizontalListItemComponent } from './uic-horizontal-list-item.component';



@NgModule({
  declarations: [UicHorizontalListItemComponent],
  exports: [UicHorizontalListItemComponent],
  imports: [
    CommonModule
  ]
})
export class UicHorizontalListItemModule { }
