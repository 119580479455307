<div *ngIf="type === 'Vertical'">
  <a class="webcast-thumb" (click)="clickIntoWebcast()">
    <div class="webcast-img">
      <div [ngStyle]="{ 'background-image': 'url(' + image + ')' }" [class]="((isMobile$ | async)?'square-xs':'square')">
      <!--<img [src]="image" class="img-responsive" [alt]="'FSM webcast - ' + title">-->
        <div [class]="(titleInVideoAlwaysDisplay? 'webcast-overlay-display text-center' : 'webcast-overlay text-center')">
          <i class="fa fa-play-circle-o-lg webcast-play-icon" style="content: url('assets/images/general/icons/lg-play-btn.svg')"> </i>
          <div *ngTemplateOutlet="(titleInVideo? titleTemplate : null)"></div>
        </div>
      </div>
      <div *ngTemplateOutlet="(!titleInVideo? titleTemplate : null)"></div>
    </div>
  </a>
</div>

<div *ngIf="type === 'Horizontal'">
    <a class="webcast-thumb-xs" (click)="clickIntoWebcast()">
      <div nz-row [nzGutter]="20" nzType="flex" [nzJustify]="((isMobile$ | async)?'space-between':'start')" nzJustify="space-between" nzAlign="middle" class="grid">
        <div nz-col nzSpan="12" [nzOrder]="(isMobile$|async)? 2 : 1" class="column w-100 h-100 ">
          <div class="webcast-img">
            <img [src]="image" class="img-responsive" [alt]="'FSM webcast - ' + title">
            <div class="webcast-overlay text-center">
              <i class="fa fa-play-circle-o-xs webcast-play-icon" style="content: url('assets/images/general/icons/xs-play-btn.svg')"> </i>
              <div *ngTemplateOutlet="(titleInVideo? titleTemplate : null)"></div>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12" [nzOrder]="(isMobile$|async)? 1 : 2" class="column h-100 w-100 space-between-vertical">
          <div class="webcast-title">
            <p class="mb-1">{{title}}</p>
          </div>
          <div>
            <div><span class="webcast-subtitle" *ngIf="author">{{'fsmone.author.by' | translate}} {{author}}</span></div>
            <div><span class="webcast-subtitle">{{ date | hkDate:localeOption }} • {{videoLength }}</span></div>
          </div>
        </div>
      </div>
    </a>
</div>

<ng-template #titleTemplate>
  <!--<div><span class="webcast-subtitle text-sm"> {{category}}</span></div>-->
  <div class="text-left webcast-caption">
    <div class="webcast-title">
      <p class="mb-1">{{title}}</p>
    </div>
    <div>
      <div><span class="webcast-subtitle" *ngIf="author">{{'fsmone.author.by' | translate}} {{author}}</span></div>
      <div><span class="webcast-subtitle">{{ date | hkDate:localeOption  }} • {{videoLength }}</span></div>
    </div>
  </div>
</ng-template>
