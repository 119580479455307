import { Injectable } from '@angular/core';
import { ArticleCategory } from '@api-module/enum/i-fund-article-category.enum';
import { IArticleSearchModel } from '@api-module/model/article/i-article-search.model';
import {FundRestService} from "@api-module/rest/fund.rest.service";
import {map} from "rxjs/operators";
import {IResponse} from "@api-module/model/common/i-response";
import {AppConstant} from "src/app/constant";
import {HttpBackend, HttpClient, HttpHandler, HttpHeaders} from "@angular/common/http";
import {Router} from '@angular/router';
import {RouteConstant} from 'src/app/constant';

@Injectable({
  providedIn: 'any'
})
export class ArticleService {

  constructor(private fundRestService: FundRestService, private http:HttpClient, private httpBackend: HttpBackend, private router: Router) {}

  getArticleSearchModel(categories: string[], includeChinese: boolean,
                        tags: string[], pageIndex: number, pageSize: number,
                        keyword?: string, order?: string, authorId?: string, includeEnZh?: boolean): Partial<IArticleSearchModel>{
    let categoryList: string [] = categories;
    if(!categoryList){
      categoryList = ArticleCategory.ALL.categories;
    }
    return {
      categoryList: [
        ...categoryList
      ],
      keyword: keyword,
      tags: tags,
      includeChinese: includeChinese,
      order: order,
      page: pageIndex - 1,
      limit: pageSize,
      authorId: authorId,
      includeEnZh: includeEnZh,
      webcast: 0
    };
  }

  getArticleSearchModelByArticleCategory(articleCategory: ArticleCategory, pageIndex: number, pageSize: number): Partial<IArticleSearchModel>{
    if(!articleCategory){
      return;
    }
    return this.getArticleSearchModel(articleCategory.categories, articleCategory.includeChinese, articleCategory.tags, pageIndex, pageSize);
  }

  checkIsEvent(articleNo: string) {
    return this.fundRestService.getFundHappeningHeaderByArticleNo(articleNo).pipe(
      map((response:IResponse<any>) => {
        if(response?.status === AppConstant.RESPONSE_SUCCESS && response?.data?.id) {
          return response?.data?.id;
        }
        return null;
      })
    )
  }

  downloadArticle(url){
    this.http = new HttpClient(this.httpBackend);
    const header = new HttpHeaders({
      Accept: 'application/pdf'
    });
    return this.http.get(url, {
      headers:header,
      responseType: 'arraybuffer'
    });
  }

  transformNavigateArticleUrl(url){
		if(url.includes('article/view/rcms')){
			var articleIdTitle = url.substring(url.indexOf('rcms')).split('/');
			
			this.router.navigate([RouteConstant.ARTICLE, RouteConstant.ARTICLE_DETAILS, articleIdTitle[0]], {});
		} else if(url.includes('article/view/')){
			var articleIdTitle = url.substring(url.indexOf('article/view/')+13).split('/');
			
			this.router.navigate([RouteConstant.ARTICLE, RouteConstant.ARTICLE_DETAILS, 'd'+articleIdTitle[0]], {});
		} else {
			if(url.indexOf('https://')<0) {
				url = 'https://' + url;
			}
			window.open(url, '_blank','noreferrer');
		}
  }
}
