import { Component,Input} from '@angular/core';

@Component({
	selector : 'download-app-qrcode',
	templateUrl: './download-app-qrcode.component.html',
	styleUrls : []
})

export class DownloadAppQrcodeComponent{
	
	@Input() item: string;
	
	GENERAL_QR_IMG_PATH = 'assets/images/general/qr-code/';
	
	constructor(){
		
	}
}