<div class="ui-pop-message-box mx-3 py-2">
	<div *ngIf="type" class="text-center text-loss text-icon"><!-- icon here -->
		<!-- <i class="fsmicon-close-circle"></i> -->
		<i nz-icon *ngIf="type == 'info'" class="text-info" nzType="info-circle" nzTheme="fill"></i>
		<i nz-icon *ngIf="type == 'warning'" class="text-warning" nzType="exclamation-circle" nzTheme="fill"></i>
		<i nz-icon *ngIf="type == 'error'" class="text-loss" nzType="close-circle" nzTheme="fill"></i>
		<i nz-icon *ngIf="type == 'success'" class="text-profit" nzType="check-circle" nzTheme="fill"></i>
	</div>
	<div class="mt-2" [ngClass]="textAlignment"><!-- title here-->
		<div *ngIf="titleText" 
			class="text-lg font-weight-bold">{{ titleText | translate }}</div>
		<div *ngIf="titleHtml" 
			class="text-lg font-weight-bold"><span [innerHTML]=" titleHtml "></span></div>
	</div>
	<div class="mt-2" [ngClass]="textAlignment"><!-- body here-->
		<div *ngIf="bodyText" class="text-default">{{ bodyText | translate}}</div>
		<div *ngIf="bodyHtml" class="text-default"><span [innerHTML]=" bodyHtml"></span></div>
	</div>

	<div class="mt-4 text-right" [ngClass]="buttonAlignment"> <!-- footer for buttons here-->
		<button *ngIf="cancelBtn" class="btn-base btn-outline-primary my-1" [ngClass]="{ 'w-100': (isMobile$ | async) }" nzType="default" nz-button nzSize="small" (click)="emitData({cancel: true})">
			<span [innerHTML]=" cancelBtnHtml ? (cancelBtnHtml | translate | uppercase) : ('cancel' | translate | uppercase) "></span>
		</button>
		<button *ngIf="confirmBtn" class="btn-base my-1" [ngClass]="{'ml-2': cancelBtn && !(isMobile$ | async), 'w-100': (isMobile$ | async)}" nzType="theme" nzSize="small" nz-button (click)="emitData({confirm: true})">
			<span [innerHTML]=" confirmBtnHtml ? (confirmBtnHtml | translate | uppercase) : ('ok' | translate | uppercase) "></span>
		</button>
	</div>
</div>