import { Component, Input, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-wrapper',
  templateUrl: './pdf-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfWrapperComponent implements OnChanges {
  @Input() url: string;
  @Input() height: string = '500px';

  safeUrl: SafeResourceUrl;
  renderHTML: boolean = false;

  constructor(private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) {

  }

  ngOnChanges(): void {
    this.renderHTML = false;
    this.cdRef.detectChanges();

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.renderHTML = true;
    this.cdRef.detectChanges();
  }
}
