import {Component, Input, OnChanges, OnInit, SimpleChanges,EventEmitter , Output} from '@angular/core';
import {IArticleBasicModel} from '@api-module/model/article/i-article-basic-model';
import {Select} from '@ngxs/store';
import {GlobalState} from '../../../../core/store';
import {Observable} from 'rxjs';
import {AppConstant, FundConstant, ImagePathConstant, RouteConstant} from 'src/app/constant';
import {Router} from '@angular/router';
import {ArticleCategory} from '@api-module/enum/i-fund-article-category.enum';
import {genRouterLink} from "@util/route";
// import {getRandomInt} from '@util/random';
// import {getArticleRoute, setArticleFeaturedImageLink} from "@util/article";
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {ArticleRestService} from 'src/app/api/rest/article.rest.service';
import { getEnv } from '@share/services/product-search.service';

import {tap} from "rxjs/operators";
import {IProductEnum} from "@api-module/enum/i-product.enum";
import {openNewTab} from "@util/new-tab";

@Component({
  selector: 'article-highlight-container',
  templateUrl: './article-highlight-container.component.html',
  styleUrls: [],
  providers: [SubHandlingService]
})
export class ArticleHighlightContainerComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() articleList: IArticleBasicModel[] = [];
  @Input() viewMoreCategory: ArticleCategory;
  @Input() hideViewMoreLink = false;
  @Input() hideViewMoreButton = false;
  @Input() linkToArticleDetails = genRouterLink(RouteConstant.FUNDS, RouteConstant.RESEARCH, RouteConstant.ARTICLE_DETAILS);
  @Input() linkToInsuranceDetails = genRouterLink(RouteConstant.INSURANCE, RouteConstant.INSURANCE_ARTICLE);
  @Input() includeInsurance: boolean = false;
  @Input() fromMonthlyCommentary:boolean = false;
  @Input() appendTitleToLink: boolean = false;
  @Output() moreArticles: EventEmitter<any> = new EventEmitter<any>();

	@Input() loading: boolean = false;
	@Input() tags: string;

  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  readonly RouteConstant = RouteConstant;
  readonly FundConstant = FundConstant;
  readonly AppConstant = AppConstant;
  highlightArticle: IArticleBasicModel;
  topArticles: IArticleBasicModel[] = [];
  bottomArticles: IArticleBasicModel[] = [];

  ARTICLE_IMAGE_PATH = 'assets/images/article/';
  constructor(private router: Router, private subHandlingService: SubHandlingService, private articleService: ArticleRestService) {
  }

  ngOnInit(): void {
		
  }

  initArticlesList(): void {
    if (this.articleList.length <= 0) {
      return;
    }
    const tempArticles = this.articleList.slice();
    this.highlightArticle = tempArticles.shift();
    
    if (tempArticles.length > 4) {
      this.bottomArticles = tempArticles.splice(4);
    }
    this.topArticles = tempArticles.splice(0, 4);
  }

  redirectToArticlelist(): void {
		let route = RouteConstant.ARTICLE;
		if( this.tags && this.tags?.length >0){
			this.router.navigate([route],{queryParams:{category: this.viewMoreCategory, tags: this.tags}});
		} else {
			this.router.navigate([route],{queryParams:{category: this.viewMoreCategory}});
		}
  }

  redirectToArticleDetails(articleNo): void {
	let route = RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + articleNo;
	window.open(route, '_blank', 'noreferrer');
	//var data = genRouterLink('article','view','rcms'+articleNo);
	//let FSM = '/fsm';
	//window.open(getEnv()+FSM+data);
  }

  formArticleDetailsUrl(articleNo, isWebcast: boolean): string {
	if (isWebcast) {
		return RouteConstant.WEBCAST + '/' + RouteConstant.WEBCAST_DETAILS + '/' + articleNo;
	}
    return RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS + '/' + articleNo;
    // window.open(route, '_blank', 'noreferrer');
    //var data = genRouterLink('article','view','rcms'+articleNo);
    //let FSM = '/fsm';
    //window.open(getEnv()+FSM+data);
  }

  redirectToAuthor(authorId: string): void {
    //TODO link to author page once completed
    this.router.navigate([RouteConstant.AUTHOR_DETAILS], {queryParams:{author: authorId}});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.articleList) {
      //this.setDefaultImage();
      this.initArticlesList();
    }
  }

  redirectToMonthlyCommentaly() {
    this.router.navigate([RouteConstant.MANAGED_PORTFOLIOS, RouteConstant.PORTFOLIO_INSIGHTS, RouteConstant.MONTHLY_COMMENTARY]);
  }

  navigateMoreArticle(){
	
  }
}
