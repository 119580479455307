<!-- <app-web-scaffold> -->
  <nz-table [nzData]="interestRateList" #intList nzShowPagination= "false">
    <thead (nzSortChange)="sort($event)">
      <tr>
        <th class="py-2" nzShowSort nzSortKey="currencyCode"><span translate="fsmone.new.interest.table.currency" translate-default="Cash Account Currency"></span></th>
        <th class="py-2" nzShowSort nzSortKey="rateDate"><span translate="fsmone.new.interest.table.date" translate-default="Rate Date"></span></th>
        <th class="py-2" nzShowSort nzSortKey="clientTrustRate"><span translate="fsmone.new.interest.table.rate" translate-default="Interest Rate *"></span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rate of intList.data" class="py-2">
        <td class="py-2">{{ rate.currencyCode | lowercase |translate}}</td>
        <td class="py-2">{{ rate.rateDate | hkDate:localeOption}}</td>
        <td class="py-2">
          <ng-container *ngIf="rate.clientTrustRate.indexOf('-')>=0">{{ rate.clientTrustRate | number: '1.3-3'}} % **</ng-container>
          <ng-container *ngIf="rate.clientTrustRate.indexOf('-')<0">{{ rate.clientTrustRate | number: '1.3-3'}} %</ng-container>
        </td>
      </tr>
    </tbody>

    <ng-template #rangeTemplate let-range="range" let-total> <span translate="show" translate-default="show"></span> {{ range[0] }}-{{ range[1] }} , <span translate="fsmone.fsm.smart.total" translate-default="Total"></span> {{ total }} <span translate="fsmone.results" translate-default="Results"></span></ng-template>
    
    <ng-template #prefixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    
    <ng-template #suffixIconClose>
      <i nz-icon nzType="close" nzTheme="outline" ></i> 
    </ng-template>
  </nz-table>
<!-- </app-web-scaffold> -->