
	<nz-collapse nzGhost [nzBordered]="false">
	 	<nz-collapse-panel [nzHeader]="header" [nzExtra]="extra" [nzShowArrow]="false" [nzActive]="activePanel" (nzActiveChange)="changeActive($event)">
	 		<div class="related-article-section">
		 		<div class="row d-flex flex-wrap">
		 			<div class="col" *ngFor="let item of articleList">
			 			<nz-card class="product-card">
			 				<img class="object-fit-contain" [src]="item?.featuredImage" />
			 				<div><small>{{item?.category}}</small></div>
			 				<div>
			 					<a [routerLink]="link + item?.articleId" target="_blank"><strong>{{item?.title}}</strong></a>
			 				</div>
			 				<h6>{{item?.author}}</h6>
			 				<h6>{{item?.logDate  | hkDate:localeOption}} • {{item?.hits}}</h6>
			 			</nz-card>
		 			</div>
		 		</div>
			</div>
	 	</nz-collapse-panel>
	</nz-collapse>


<ng-template #header>
	<span class="container text-md text-white" translate="related.articles" transate-default="Related Articles"></span>
</ng-template>

<ng-template #extra>
	<span class="container text-md text-white">{{ activePanel ? ( 'hide'|translate ) : ( 'show' | translate ) }}</span>
</ng-template>