import {AppConstant} from 'src/app/constant/app.constant';
import {ImagePathConstant} from 'src/app/constant/image-path.constant';
import {getRandomInt} from "@util/random";
import { IProductEnum } from '@api-module/enum/i-product.enum';
import { RouteConstant } from 'src/app/constant/route.constant';
import { normaliseUrl } from 'src/app/util/url';

export function setArticleFeaturedImageLink(featuredImage: string, fromRcms: string) {
  if (featuredImage) {
    if (featuredImage.startsWith('/insurance')) {
      return `${AppConstant.LIVE_URL}${featuredImage}`;
    } else if (featuredImage.startsWith('http') === false) {
      if (fromRcms === AppConstant.NO_FLAG) {
        return `${AppConstant.LIVE_URL}/articleFiles/${featuredImage}`;
      } else {
        if (featuredImage.startsWith('ext/feature')) {
          return `${AppConstant.LIVE_URL}/${featuredImage}`;
        } else {
          return `${AppConstant.LIVE_URL}/ext/feature/${featuredImage}`;
        }
      }
    }
  } else {
    return `${ImagePathConstant.ARTICLES_IMG_PATH}fsm-article-${getRandomInt(1, 30)}.jpg`;
  }
  return featuredImage;
}


/**
 * get an article route based on product
 * @param prod article product type, e.g. funds, bonds, dpms, stock
 * @param articleNo product code
 * @param isRcms article is from rcms or not
 * @param includeInsurance article include insurance or not
 * @param name product name (for SEO purpose)
 * @param src source of the origin (for Google Analytics purpose)

 */
export function getArticleRoute(prod: IProductEnum, articleNo: string, isRcms: string, includeInsurance:boolean, name?: string, src?:string) {
  const ret: any = {};

  switch(prod) {
    case IProductEnum.UNIT_TRUST:
      ret.route = [RouteConstant.FUNDS, RouteConstant.RESEARCH, RouteConstant.ARTICLE_DETAILS];

      break;
    case IProductEnum.BOND:
      ret.route = [RouteConstant.BONDS, RouteConstant.BOND_RESEARCH, RouteConstant.ARTICLE_DETAILS];

      break;
    case IProductEnum.DPMS:
      ret.route = [RouteConstant.MANAGED_PORTFOLIOS, RouteConstant.ARTICLE_DETAILS];

      break;
    case IProductEnum.INSURANCE:
      ret.route = [RouteConstant.INSURANCE, RouteConstant.INSURANCE_ARTICLE];
      break;
    case IProductEnum.STOCK_AND_ETF:
      ret.route = [RouteConstant.STOCKS, RouteConstant.RESEARCH, RouteConstant.ARTICLE_DETAILS];
      break;
    case IProductEnum.ETF:
      ret.route = [RouteConstant.ETFS, RouteConstant.RESEARCH, RouteConstant.ARTICLE_DETAILS];
      break;
  }
  if(articleNo){
    ret.route.push(articleNo);
  }
  if (name) {
    ret.route.push(normaliseUrl(name));
  }
  return {
    ...ret,
    params: {
      ...ret.params,
      src
    }
  };
}

export function getWebcastUrl(articleNo: string, fromRcms?: string, title?: string, includeInsurance?: boolean) {
  let route = RouteConstant.WEBCAST + '/' + RouteConstant.RESEARCH  + '/' + RouteConstant.ARTICLE_DETAILS;

  route += '/' + articleNo;

  if (title) {
    route += '/' + normaliseUrl(title);
  }
  return route + '?src=' + RouteConstant.WEBCAST;
}

export function getEventUrl(eventNo: string, title?: string) {
  let route = RouteConstant.EVENTS + '/' + RouteConstant.EVENT_DETAILS;

  route += '/' + eventNo;

  if (title) {
    route += '/' + normaliseUrl(title);
  }
  return route;
}
