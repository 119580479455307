import {NgModule} from '@angular/core';
import {StockExchangeTagComponent} from "./stock-exchange-tag.component";
// import {SharedCommonModule} from "@shared-lib/common/common.module";

const EXPORT_DECLARATION = [
  StockExchangeTagComponent
];

// const IMPORT_MODULES = [
//   SharedCommonModule
// ];

@NgModule({
  declarations: [...EXPORT_DECLARATION],
  // imports: [...IMPORT_MODULES],
  exports: [...EXPORT_DECLARATION]
})
export class StockExchangeTagModule {
}
