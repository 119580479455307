<div class="overlap-subheader-margin ">
	<nz-row nzGutter="16" class="mb-3 d-flex flex-wrap">
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
    	<ng-container  *ngTemplateOutlet="Template; context: { method: FUNDS, text: 'fsmone.fee.funds', path: 'fund-2x' , selectorurl: fundPerformers}"></ng-container>
    </nz-col>
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
      <ng-container *ngTemplateOutlet="Template; context: { method: BONDS, text: 'fsmone.fee.bonds', path: 'bond-2x' , selectorurl: bondPerformers}"></ng-container>
		</nz-col>
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
    	<ng-container *ngTemplateOutlet="Template; context: { method: ETF, text: 'fsmone.general.etf', path: 'etf-2x' , selectorurl: etfPerformers}"></ng-container>
    </nz-col>
    <nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
    	<ng-container *ngTemplateOutlet="Template; context: { method: STOCK, text: 'fsmone.stocks', path: 'stock-2x' , selectorurl: stockPerformers}"></ng-container>
    </nz-col>
        
  </nz-row>
</div>

<ng-template #Template let-method="method" let-text="text" let-path="path" let-selectorurl="selectorurl">
    <nz-card class="h-100 card-select-box" [ngClass]="{'active': selected == method}" nzHoverable role="button" (click)="changeCategory(method)">
      <a [routerLink]="selectorurl"><div class="text-center" >
        <img height="50" [src]="PRODUCTS_ICON_IMAGES_PATH + path + '.png'" [alt]="text + ' Icon'">
        <h5 class="mt-2 mb-0 font-weight-bold">{{ text | translate }}</h5>
      </div></a>
    </nz-card>
</ng-template>