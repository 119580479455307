import {Component, EventEmitter, Input, Output,OnInit} from "@angular/core";
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {Select} from "@ngxs/store";
import {GlobalState} from "../../../../core/store";
import {Observable} from "rxjs";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { TranslateService } from '@ngx-translate/core';
import {ResponsiveService} from "@core/service/responsive.service";

@Component({
  selector: 'article',
  templateUrl: './article.component.html',
  providers: [SubHandlingService],
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  isMobileView: boolean = false;
  @Input() horizontal: boolean= true;
  @Input() highLight: boolean;
  @Input() title: string;
  @Input() desc: string;  
  @Input() category: string;
  @Input() level: string;
  @Input() image: string;
  @Input() author: string;
  @Input() authorClickable: boolean = false;
  @Input() view: string;
  @Input() date: Date;
  @Input() articleid: string;
  @Input() articleSaved: boolean;
  @Input() authState: boolean;
  @Input() isWebcast: boolean;
  @Output() articleClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() authorClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleClickEventEmitter = new EventEmitter<any>();
  @Output() articleSavedClickEventEmitter = new EventEmitter<any>();
  @Output() articleDeleteClickEventEmiiter = new EventEmitter<any>();
  @Output() articleShareClickEventEmitter = new EventEmitter<any>();
  localeOption:string = "zh-TW";
  dateFormat = this.globalDataStorage.getStorage('locale') === 'ch' || this.globalDataStorage.getStorage('locale') === 'zh' ? "yyyy年MM月dd日" : "dd MMM yyyy";


  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  constructor(private globalDataStorage:GlobalDataStorage,
	private translate: TranslateService,
	private responsiveService: ResponsiveService) {
  }

  ngOnInit(): void {
	this.setLocale();
	this.setDefaultValue();
	this.isMobileView = this.responsiveService.isMobileView();
    this.responsiveService.mobileViewEvent.subscribe(state => this.isMobileView = state);
  }

  setDefaultValue(){
	if(this.image==null || this.image==''){
		this.image = 'assets/images/article/default-image.jpg';
	}
  }

  setLocale(){
	let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
  }
  

  clickfromTitle(): void{
	 this.articleClickEventEmitter.emit(true);
  }

  clickfromImage(){
	this.articleClickEventEmitter.emit(true);
  }

  shareLink(): void {
	this.articleShareClickEventEmitter.emit(true);
  }

  checkSavedArticle(): void {
    if(this.articleSaved){
      this.articleDeleteClickEventEmiiter.emit(true);
	  this.articleSaved = false;
    }
    else {
      this.articleSavedClickEventEmitter.emit(true);
	  this.articleSaved = true;
    }
  }

}
