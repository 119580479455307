import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchHit' })
export class SearchHitPipe implements PipeTransform {
  transform(searchString: string, query: string) {
    if (!query || (query && query.length === 0)) {
      return searchString;
    }

	var newQuery=query.replace(/[\+\-\=\&\|\<\>\!\(\)\{\}\[\]\^\"\~\*\?\:\\\/)]/g,'\\$&' );
    var trimmedKeyword= newQuery.trim();
    var phraseWords:string[] = trimmedKeyword.split(' ');
    if(phraseWords && phraseWords.length>1){
	    return searchString.replace((new RegExp(`(${newQuery.trim().split(/\s/).join('|')})`, 'gi')), match =>  `<strong>${match}</strong>`);
	} else{
		return searchString.replace(new RegExp(`(${newQuery})`, 'i'), `<span class='hit'>$1</span>`);
	}
  }
}
