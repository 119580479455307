import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '@core/service/responsive.service';
import {NgClassType} from 'ng-zorro-antd';
import {WindowResizeService} from '@share/services/window-resize/window-resize.service';

@Component({
  selector: 'ui-mobile-full-screen',
  templateUrl: './ui-mobile-full-screen.component.html',
  styleUrls: ['./ui-mobile-full-screen.component.scss'],
})
export class UiMobileFullScreenComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isVisible: boolean = false;
  @Input() zIndex: number = 1100;
  @Input() headerClass: NgClassType;
  @Input() footerClass: NgClassType;
  @ViewChild('header', { static: false }) header!: ElementRef;
  @ViewChild('body', { static: false }) body!: ElementRef;
  @ViewChild('footer', { static: false }) footer!: ElementRef;

  isMobileView: boolean = false;

  constructor(
      private responsiveService: ResponsiveService,
      private cdr: ChangeDetectorRef,
      private windowResizeService: WindowResizeService
  ) { }

  ngOnInit(): void {
    this.isMobileView = this.responsiveService.isMobileView();
    this.responsiveService.mobileViewEvent.subscribe(state => this.isMobileView = state);
  }

  ngAfterViewInit(): void {
    this.calcFullScreenView();
    this.windowResizeService.windowResizeEvent.subscribe(() => {
      this.calcFullScreenView();
    });
  }

  ngOnDestroy(): void {
    this.windowResizeService.windowResizeEvent.unsubscribe();
  }

  calcFullScreenView() {
    if (this.isVisible) {
      const headerHeight = this.header.nativeElement.offsetHeight;
      const footerHeight = this.footer.nativeElement.offsetHeight;
      this.body.nativeElement.style.paddingTop = `${headerHeight}px`;
      this.body.nativeElement.style.paddingBottom = `${footerHeight}px`;
    }
  }

  triggerCalcMobileFullScreenView() {
    setTimeout(() => {
      this.calcFullScreenView();
    }, 100);
  }

}
