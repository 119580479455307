import {Injectable} from "@angular/core";
import {NotificationConstant} from "../../../constant/notification.constant";
import {RouteConstant} from "@constant";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private router: Router) {

    }

    getRedirectionState(redirection: string): string | [string, string] {
        if (redirection) {
            let redirectionObject = JSON.parse(redirection);
            let moduleId = redirectionObject.module;
            if (NotificationConstant.ARTICLE.indexOf(moduleId) > -1) {
                return [RouteConstant.ARTICLE + '/' + RouteConstant.ARTICLE_DETAILS, redirectionObject.articleNo];
            } else if (NotificationConstant.FACTSHEET.indexOf(moduleId) > -1) {
                if (NotificationConstant.BOND.indexOf(moduleId) > -1) {
                    return [RouteConstant.BONDS + '/' + RouteConstant.BOND_FACTSHEET, redirectionObject.factsheetNo];
                }
                if (NotificationConstant.FUND.indexOf(moduleId) > -1) {
                    return [RouteConstant.FUNDS + '/' + RouteConstant.FUND_INFO + '/' + RouteConstant.FACTSHEET, redirectionObject.factsheetNo];
                }
            } else if (NotificationConstant.NORMAL_MESSAGE.indexOf(moduleId) > -1) {
                return '' + redirectionObject.url + '';
            } else {
                return null;
            }
        }
    }

    navigate(redirectionState: string | [string, string]) {
        if (redirectionState != 'null' && redirectionState != null) {
            if (redirectionState instanceof Array) {
                this.router.navigateByUrl(redirectionState[0] + '/' + redirectionState[1]);
            } else {
                window.open(redirectionState, "_blank");
            }
        }
    }
}
