import { AppConstant, HighchartsConstant } from 'src/app/constant';
import * as Highcharts from 'highcharts';
import { formatDate, formatNumber } from '@angular/common';

export class ChartCenterBase {
  public readonly Highcharts = Highcharts;
  public readonly plotColors = HighchartsConstant.PLOT_COLORS;

  public chartConfiguration: Highcharts.Options = {
    title: {
      text: '',
      align: 'left',
      y: -55,
      x: 30,
    },
	caption:{
		
	},
    subtitle: {
      text: '',
      align: 'left',
      x: 30,
    },
    xAxis: {
      type: HighchartsConstant.DATETIME,
    },
    yAxis: {
      title: {
        text: '<div class="text-right d-inline-flex text-black font-weight-bold">'
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      },
    },
    lang: {
      noData: 'No Data To Display',
    },
    noData: {},
    series: [],
    chart: {
      zoomType: 'x',
      height: 400,
      spacingTop: 30,
      backgroundColor: '#fff',
      events: {
        render() {
          setTimeout(() => {
            this.reflow();
          }, 0);
        }
      },
    },
    credits: HighchartsConstant.CREDIT_CONFIG,
    colors: [HighchartsConstant.PRIMARY_COLOR],
    legend: {
      x: 20,
      layout: 'horizontal',
      enabled: false,
      itemStyle: {
        fontSize: '16px',
      },
    },
    exporting: {
      ...HighchartsConstant.EXPORT_CONFIG,
    },
    tooltip: {
      shared: true,
      valueDecimals: 3
    },
  };
}
