import { Component, OnInit,ChangeDetectionStrategy, ChangeDetectorRef,OnChanges,Input ,EventEmitter,Output} from '@angular/core';
import { IBondResearchIndexDataModel } from '@api-module/model/bond/i-bond-research-index-data.model';
import {IBondResearchIndexDataDetailsModel} from "@api-module/model/bond/i-bond-research-index-data-details.model";
import {IBondResearchIndexDataIndexes} from "@api-module/model/bond/i-bond-research-index-data-indexes.model";

@Component({
  selector: 'app-bond-index-data-table',
  templateUrl: './bond-index-data-table.component.html'
})
export class BondIndexDataTableComponent implements OnChanges {  
  @Input() selectedCategory: string;
  @Input() selectedMarket: string;
  @Input() indexData: IBondResearchIndexDataModel;
  marketNameList: IBondResearchIndexDataDetailsModel[] = [];
  marketIndexes: IBondResearchIndexDataIndexes;

  constructor() {}
  

  ngOnChanges() {	 
	 
	  if(this.indexData)
	  {
		  if (this.selectedCategory == "performance") {
		      this.marketNameList = this.indexData.bondMarketReturnCountryModel;
		      this.marketIndexes = this.indexData.marketReturnIndexs;
		    } else if (this.selectedCategory == "spread") {
		      this.marketNameList = this.indexData.bondMarketSpreadCountryModel;
		      this.marketIndexes = this.indexData.marketSpreadIndexs;
		    } else {
		      this.marketNameList = this.indexData.bondMarketYieldCountryModel;
		      this.marketIndexes = this.indexData.marketYieldIndexs;
		    }
	  }	  
  }
 
}
