import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'text-align',
  templateUrl: './text-align.component.html',
  styleUrls: ['./text-align.component.scss']
})
export class TextAlignComponent implements OnInit {
  @Input() nzTitle: string;
  @Input() nzValue: string;
  @Input() isCustomTooltip: boolean = false;
  @Input() customTitle: string;
  constructor() { }

  ngOnInit(): void {
  }

}
