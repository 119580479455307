import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UicSwitchV1WrapperComponent } from './uic-switch-v1-wrapper.component';
import {NzSwitchModule} from 'ng-zorro-antd';



@NgModule({
  declarations: [UicSwitchV1WrapperComponent],
  exports: [UicSwitchV1WrapperComponent],
  imports: [
    CommonModule,
      NzSwitchModule
  ]
})
export class UicSwitchV1WrapperModule { }
