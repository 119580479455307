import { Component, Input } from '@angular/core';

@Component({
  selector: 'rounded-icon',
  templateUrl: './rounded-icon.component.html',
  styleUrls: ['./rounded-icon.component.scss']
})
export class RoundedIconComponent{

  @Input() imgPath: string = '';
  @Input() position: 'default' | 'center' = 'default';
  @Input() description: string = '';
  @Input() details: string='';
  @Input() shadow: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
