import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: 'hkTranslate' })
export class HkTranslatePipe implements PipeTransform {

	constructor(private translateService: TranslateService) {}
	
	transform(value: any,defaultValue?:string) {
    	if (!value) {
      		return '-';
    	}
		
		let ret: string = this.translateService.instant(value);
		if(ret && ret.indexOf("[MISSING-") == -1){
			return ret;
		}
		
		return defaultValue;
  	}
}