<nz-row *ngIf="hideHeader==false" nzType="flex" nzAlign="middle" nzJustify="space-between" class="mb-3">
    <nz-col><h3 class="mb-0 text-white mb-1">{{title | translate}}</h3></nz-col>
    <nz-col><a class="font-weight-bold text-sm text-white"
               [href]="routeConstant.WEBCAST" target="_blank">{{'fsmone.view.more' | translate}}</a></nz-col>
</nz-row>

<nz-row [nzGutter]="24" nzType="flex" *ngIf="mainArticle">
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12" [nzXl]="14">
        <!--<b2c-articles
          (articleClick)="redirectToArticleDetails(mainArticle?.articleNo.toString(), mainArticle?.fromRcms, mainArticle?.title)"
          [horizontal]="(isMobile$ | async)"
          [title]="mainArticle?.title"
          [category]="mainArticle?.articleCategory"
          [image]="mainArticle?.featuredImage"
          [author]="mainArticle?.author"
          [shareAble]="false"
          [authorClickable]="!includeInsurance && mainArticle?.authorId"
          (authorClick)="redirectToAuthorPage(mainArticle?.authorId)"
          [view]="mainArticle?.hits"
          [date]="mainArticle.logdate"></b2c-articles>-->
        <a [href]="formWebcastUrl(mainArticle?.articleNo?.toString())" target="_blank">
            <!--    <webcast-->
            <!--      (videoDetailsUrl)="setWebcastUrl(mainArticle?.articleNo.toString(), mainArticle?.title)"-->
            <webcast
                    [title]="mainArticle?.title"
                    [category]="mainArticle?.articleCategory"
                    [image]="mainArticle?.featuredImageWebcast? mainArticle?.featuredImageWebcast : mainArticle?.featuredImage"
                    [author]="mainArticle?.author"
                    [authorClickable]="!includeInsurance && mainArticle?.authorId"
                    (authorClick)="redirectToAuthorPage(mainArticle?.authorId)"
                    [view]="mainArticle?.hits"
                    [date]="mainArticle.logdate"
                    [videoLength]="mainArticle?.videoLength"
                    [type]="'Vertical'"
                    [titleInVideo]="!(isMobile$|async)"
                    [titleInVideoAlwaysDisplay]="!(isMobile$|async)"
            >
            </webcast>
        </a>
        <nz-divider class="webcast-divider hide-in-lg" *ngIf="subArticleList.length>0"></nz-divider>
    </nz-col>
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12" [nzXl]="10">
        <div *ngFor="let article of subArticleList; last as isLast;">
            <a [href]="formWebcastUrl(article?.articleNo?.toString())" target="_blank">
                <!--      <webcast-->
                <!--        (videoDetailsUrl)="setWebcastUrl(article?.articleNo.toString(), article?.title)"-->
                <webcast
                        [title]="article?.title"
                        [category]="article?.articleCategory"
                        [image]="article?.featuredImage"
                        [author]="article?.author"
                        [authorClickable]="!includeInsurance && article?.authorId"
                        (authorClick)="redirectToAuthorPage(article?.authorId)"
                        [view]="article?.hits"
                        [date]="article.logdate"
                        [videoLength]="article?.videoLength"
                        type="Horizontal"
                ></webcast>
            </a>
            <nz-divider class="webcast-divider" *ngIf="subArticleList.length>0 && !isLast"></nz-divider>
        </div>
    </nz-col>
</nz-row>

