import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartingLibraryWidgetOptions, IChartingLibraryWidget, widget } from 'src/assets/charting_library_v2/charting_library';
import { ResolutionString } from 'src/assets/charting_library_v2/datafeed-api';
import { StockTaChartDatafeed } from '@share/services/stock/stock.ta.chart.datafeed';
import { GlobalDataStorage } from '@share/storages/global-data.storage';
import { LightStreamerService } from '@share/services/stock/light-streamer.service';
import { StockTransactionRestService } from '@api-module/rest/stock.transaction.rest.service';
import { StocksInfoRestService } from '@api-module/rest/stocks-info.rest.service';
import { StockService } from '@share/services/stock/stock.service';
import { LocalStorageConstant } from 'src/app/constant/local.storage.constant';
import { getStorage, removeItemFromStorage } from '@util/local-storage';

@Component({
  selector: 'stock-ta-chart',
  templateUrl: './stock-ta-chart.component.html',
  styleUrls: ['./stock-ta-chart.component.scss']
})
export class StockTaChartComponent implements OnInit {
  @Input() tickerNo: string;
  @Input() isStockOrderpad: boolean;
  @Input() selectedAccountNo: string;

  protected _symbol: ChartingLibraryWidgetOptions['symbol'] = 'AAPL';
  protected _interval: ChartingLibraryWidgetOptions['interval'] = 'D' as ResolutionString;
  protected _libraryPath: ChartingLibraryWidgetOptions['library_path'] = 'assets/charting_library_v2/';
  protected _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
  protected _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
  protected _user_id: ChartingLibraryWidgetOptions['user_id'];
  protected _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  protected _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  protected _tvWidget: IChartingLibraryWidget | null = null;
  protected _timeFrames: ChartingLibraryWidgetOptions['time_frames'];

	dateFormat = this.globalDataStorage.getStorage('locale') === 'ch' || this.globalDataStorage.getStorage('locale') === 'zh' ? "yyyy年MM月dd日" : "dd MMM yyyy";
  lastUpdatedDate: any;
  localeOption: string = "zh-TW";

  constructor(
    private stockService: StockService,
    private stocksInfoRestService: StocksInfoRestService,
    private stockTransactionRestService: StockTransactionRestService,
    private globalDataStorage: GlobalDataStorage,
    private translateService: TranslateService,
    private lightStreamerService: LightStreamerService
  ) { }

  ngOnInit() {
    this.setLocale();
  }

  ngAfterViewInit() {
    this.loadChartData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isStockOrderpad) {
      this.loadChartData();
    }
  }

  ngOnDestroy(): void {
    if (this.isStockOrderpad) {
      removeItemFromStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE);
    }
  }

  setLocale(){
    let locale = this.globalDataStorage.getStorage('locale') || 'ch';
    if ("en" === locale) {
      this.localeOption = "en-US";
    } else if ("ch" === locale) {
      this.localeOption = "zh-TW";
    } else if ("zh" === locale) {
      this.localeOption = "zh-CN";
    }
  }

  loadChartData() {
    if (this.tickerNo) {
      const tickerNo = this.tickerNo.toString();
      if (this._tvWidget && !this.isStockOrderpad) {
        this._tvWidget.setSymbol(tickerNo.toString(),this._interval,() => null);
      } else {
        const _containerId: ChartingLibraryWidgetOptions['container_id'] = this.isStockOrderpad ? 'orderpad_ta_chart_container' : 'stock_ta_chart_container';
        if (!document.getElementById(_containerId)) {
          return;
        }
        const module = this.isStockOrderpad ? 'ORDERPAD_TA_CHART' : 'TA_CHART';
        const disabled_features_config = [
          'header_symbol_search',
          'symbol_search_hot_key',
          'display_market_status'
        ]
        if (!this.isStockOrderpad) {
          disabled_features_config.push('left_toolbar');
        }
        this._timeFrames = [
          { text: '1d', resolution: '1' as ResolutionString, description: this.setIntradayText() },
          { text: '1m', resolution: '1D' as ResolutionString, description: this.translateService.instant('1.month') },
          { text: '3m', resolution: '1D' as ResolutionString, description: this.translateService.instant('3.months') },
          { text: '6m', resolution: '1D' as ResolutionString, description: this.translateService.instant('6.months') },
          { text: '1y', resolution: '1D' as ResolutionString, description: this.translateService.instant('1.year') },
          { text: '3y', resolution: '1D' as ResolutionString, description: this.translateService.instant('3.years') },
          { text: '5y', resolution: '1W' as ResolutionString, description: this.translateService.instant('5.years') },
          { text: '10y', resolution: '1W' as ResolutionString, description: this.translateService.instant('10.years') },
          { text: '20y', resolution: '1W' as ResolutionString, description: this.translateService.instant('20.years') }
        ];

        let datafeed = new StockTaChartDatafeed(
          module,
          tickerNo,
          this.selectedAccountNo,
          this.lightStreamerService,
          this.stocksInfoRestService,
          this.stockTransactionRestService,
          this.translateService,
          this.globalDataStorage,
          this.stockService
        );

        const settings_overrides = {
          'mainSeriesProperties.candleStyle.barColorsOnPrevClose': true,
          'mainSeriesProperties.haStyle.barColorsOnPrevClose': true,
          'mainSeriesProperties.barStyle.barColorsOnPrevClose': true,
          'mainSeriesProperties.highLowAvgPrice.highLowPriceLabelsVisible': true,
          'paneProperties.topMargin': 27,
          'paneProperties.bottomMargin': 10,
          'scalesProperties.fontSize': 11,
          'scalesProperties.showSymbolLabels': true,
          'scalesProperties.showStudyLastValue': true,
          'scalesProperties.showSeriesLastValue': true,
          'scalesProperties.showFundamentalLastValue': true
        }

        for (let timeFrame of this._timeFrames) {
          timeFrame.title = timeFrame.description;
        }
        const widgetOptions: ChartingLibraryWidgetOptions = {
          symbol: tickerNo,
          datafeed: datafeed,
          interval: this._interval,
          container: _containerId,
          library_path: this._libraryPath,
          locale: this.setlocale(),
          symbol_search_request_delay: 500,
          disabled_features: disabled_features_config,
          enabled_features: ['two_character_bar_marks_labels'],
          client_id: this._clientId,
          user_id: this.selectedAccountNo,
          load_last_chart: false,
          auto_save_delay: 3,
          fullscreen: this._fullscreen,
          autosize: this._autosize,
          timezone: 'exchange',
          debug: false,
          settings_overrides: settings_overrides,
          time_frames: this._timeFrames,
          header_widget_buttons_mode: 'compact',
          context_menu: {},
          theme: 'Light'
        };

        this._tvWidget = new widget(widgetOptions);
        this._tvWidget.applyOverrides(settings_overrides);

        this._tvWidget.onChartReady(() => {
          datafeed.onChartWidgetReady(this._tvWidget);
        });
        this._tvWidget.headerReady().then(() => {
          datafeed.onToolbarReady(this._tvWidget);
        })
      }
    }
  }

  setlocale() {
    let locale = this.globalDataStorage.getStorage('locale') || 'ch';
    if ("en" === locale) {
      return 'en';
    } else if ("ch" === locale) {
      return 'zh_TW';
    } else if ("zh" === locale) {
      return 'zh';
    }
  }

  setIntradayText() {
    let locale = this.globalDataStorage.getStorage('locale') || 'ch';
    if ("en" === locale) {
      return 'Intraday';
    } else if ("ch" === locale || "zh" === locale) {
      return '盘中';
    }
  }

  get lastUpdatedDateDisplay() {
    var time = getStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE);
    return time ? Number(time)*1000 : null;
  }
}
