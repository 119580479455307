import {Component, Input, OnInit} from '@angular/core';
import { I18nTransKey } from 'b2c-ui/i18n';
import { B2CHeaderMetaModel, B2CHeaderCountryMetaModel } from 'b2c-ui/header';
import { defaultCountries, OVERLAY_ID } from 'b2c-ui/header';
import { HeaderMetaModel } from 'src/app/share/models/header/header.model';
import {defaultCountry} from 'src/app/constant/country.constant'

@Component({
  selector: 'country-selector',
  templateUrl: 'country-selector.component.html',
  	styleUrls: ['country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
	@Input() set country(country: HeaderMetaModel) {
    this._countries = defaultCountry.filter(({ title }) => title !== country.title);
    this._country = country;
  }
	
	readonly I18nTransKey = I18nTransKey;
	
	_country: HeaderMetaModel;
  	_countries: Array<HeaderMetaModel>;

  	constructor() {
  	}

  	ngOnInit(): void {
    
  	}


}
