import {Component, OnInit} from '@angular/core';
import {genRouterLink} from '@util/route';
import {RouteConstant} from 'src/app/constant';
import {Select} from '@ngxs/store';
import {GlobalState} from 'src/app/core/store';
import {Observable} from 'rxjs';

@Component({
  template: `
      <app-mobile-scaffold>
          <app-route-subheader title="Page Not Found"></app-route-subheader>
      </app-mobile-scaffold>
      <div class="py-5">
          <div class="container">
              <b2c-empty-status
                      [title]="title"
                      [description]="description"
                      [type]="type"
                      [isMobile]="(isMobile$ | async)">
              </b2c-empty-status>
          </div>
      </div>
  `
})
export class PageNotFoundComponent implements OnInit {
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  portfolioLink = genRouterLink(RouteConstant.DPMS, RouteConstant.GET_STARTED, RouteConstant.MANAGED_PORTFOLIO_101);
  bond101 = genRouterLink(RouteConstant.BONDS, RouteConstant.BOND_GET_STARTED, RouteConstant.BOND_101);
  fundFinder = genRouterLink(RouteConstant.FUNDS, RouteConstant.TOOLS, RouteConstant.FUND_FINDER);
  fund101 = genRouterLink(RouteConstant.FUNDS, RouteConstant.EXPLORE, RouteConstant.FUND_101);
  stock101 = genRouterLink(RouteConstant.STOCKS, RouteConstant.GET_STARTED, RouteConstant.STOCK_101);
  ins101 = genRouterLink(RouteConstant.INSURANCE, RouteConstant.INSURANCE_GET_STARTED, RouteConstant.INSURANCE_101);
  title = 'We\'re sorry';
  description = 'Your patience is appreciated while we put things back together.';
  contentTitle = 'Meanwhile, you may want to check out…';
  type = 'notFound';
  insta = 'www.instagram.com';
  email = 'www.fsmone.com.my/support/enquiry';

  ngOnInit(): void {
  }
}

