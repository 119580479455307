import { coerceBooleanProperty, _isNumberValue } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ProductTypeEnum, ProductTypeType } from 'b2c-ui/util';
import { goToWatchList } from '@share/services/product-search.service';


export interface ProductTagInterface {
  name: string;
  class: string;
}

export const productTypeModel = [
  { type: ProductTypeEnum.UNIT_TRUST, name: 'fsmone.general.unit.trusts', class: 'fund' },
  { type: ProductTypeEnum.BOND, name: 'fsmone.bond', class: 'bond' },
  { type: ProductTypeEnum.STOCK, name: 'fsmone.stocks', class: 'stock' },
  { type: ProductTypeEnum.ETF, name: 'fsmone.etf', class: 'etf' },
  { type: ProductTypeEnum.INSURANCE, name: 'Term Life', class: 'insurance' },
  { type: ProductTypeEnum.DPMS, name: 'fsmone.managed.portfolios', class: 'map' }
];

/**
 * ##### Import this component individually to your module
 * `import { B2CProductCardModule } from 'b2c-ui/product-card';`
 */
@Component({
  selector: 'product-card',
  exportAs: 'ProductCard',
  templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .product-ranking-img {
        height: 67px;
        width: 66px;
      }

      .product-ranking-img > * {
        line-height: 67px;
      }
    `
  ]
})
export class ProductCardComponent {
  /**  @ignore */
  private _highLight: number;

  /** @ignore */
  private _watchlist = false;

  /** @ignore */
  private _buyButton = false;

  /** @ignore */
  private _productType: ProductTagInterface = this.getProductTagProperties();

  /** @ignore */
  private _productTag: ProductTypeType | number;

  /** @ignore */
  private _productChange: number;

  /** Set Product Highlight */
  @Input()
  set highLight(value: number) {
    if (typeof value !== 'number') {
      throw new Error(`You provided ${typeof value} to highLight where a 'number' was expected`);
    } else {
      this._highLight = value;
    }
  }

  get highLight(): number {
    return this._highLight;
  }

  /** Set highlight prefix */
  @Input() highLightPrefix: string | TemplateRef<void>;

  /** Set highlight suffix */
  @Input() highLightSuffix: string | TemplateRef<void>;

  /** Set highlight indicator */
  @Input() highlightIndicator: string | TemplateRef<void>;

  /** Set product name */
  @Input() productName: string | TemplateRef<void>;

  /** Product name click event */
  @Output() readonly productNameEvent = new EventEmitter<void>();

  /** Set product subtitle */
  @Input() productSubtitle: string | TemplateRef<void>;

  /** Set product change */
  // @Input() productChange: number;
  @Input() set productChange(value: number) {
    this._productChange = value;
  }

  get productChange(): number {
    return this._productChange;
  }

  @Input() productChangeSuffix: string | TemplateRef<void>;

  /** Set product volume */
  @Input() productVolume: number;

  /** Set Fund Type for  UTs */
  @Input() fundType: string;

  /** Set Currency for UTs */
  @Input() currency: string;

  /** Set insurance description */
  @Input() insuranceDesc: string | TemplateRef<void>;

  /** Set watchlist */
  @Input()
  set watchlist(value: boolean) {
    this._watchlist = coerceBooleanProperty(value);
  }

  get watchlist(): boolean {
    return this._watchlist;
  }

  /** Set performance color */
  @Input() performanceColor = true;

  /** Set buy button */
  @Input()
  set buyButton(value: boolean) {
    this._buyButton = coerceBooleanProperty(value);
  }

  get buyButton(): boolean {
    return this._buyButton;
  }

  /** Set added watch list */
  @Input() isWatchListAdded = false;

  /** Set product ranking */
  @Input() productRanking: number;

  /** Setter for productTag `ut | dpms | bond | insurance | stock | etf` */
  @Input()
  set productTag(value: ProductTypeType | number) {
    if (typeof value !== 'number') {
      this._productType = this.getProductTagProperties(value);
    } else {
      this._productTag = value;
    }
  }

  @Input() fundRiskRating: string;
  fundRiskRatingClass = '';

  get productTag() {
    return this._productTag;
  }

  get productType() {
    return this._productType;
  }

  /** Watchlist Change */
  @Output() watchListChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Buy button Change */
  @Output() buy = new EventEmitter<void>();

  /** @ignore */
  private getProductTagProperties(value?: ProductTypeType | number): ProductTagInterface {
    const productType = value;
    const tagProperty: ProductTagInterface = {
      name: '',
      class: ''
    };
    if (typeof productType !== 'number') {
      for (const item of productTypeModel) {
        switch (productType) {
          case item.type:
            tagProperty.name = item.name;
            tagProperty.class = item.class;
            break;
        }
      }
    }
    return tagProperty;
  }

  /** @ignore */
  onWatchListChange(value: boolean) {
    this.watchListChange.emit(value);
  }

  /** @ignore */
  isNumberValue(value: number): boolean {
    return _isNumberValue(value);
  }

  /** @ignore */
  emitProductNameEvent() {
    this.productNameEvent.emit();
  }

  ngOnInit() {
    this.fundRiskRatingClass = '';
    const lowRiskRating = ['1', '2', '3', '4'];
    const mediumRiskRating = ['5', '6', '7'];
    const highRiskRating = ['8', '9', '10'];
    if (lowRiskRating.includes(this.fundRiskRating)) {
      this.fundRiskRatingClass = 'text-profit';
    } else if (mediumRiskRating.includes(this.fundRiskRating)) {
      this.fundRiskRatingClass = 'text-bond';
    } else if (highRiskRating.includes(this.fundRiskRating)) {
      this.fundRiskRatingClass = 'text-loss';
    }
  }

	goToWatchList()
	{
		goToWatchList();
	}
}
