import { Injectable } from "@angular/core";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, ActivationEnd } from '@angular/router';
import { PageConstant } from 'src/app/constant';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { HttpParamsService } from '@api-module/service/http-params.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  isMetaTagsSet = false;

  private readonly DEFAULT_TITLE: string = PageConstant.PAGE_TITLE;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private translateService: TranslateService,
    private http: HttpClient,
    private paramService: HttpParamsService
  ) { }

  listen() {
    let isMetaTagsSet = false;
    this.router.events.subscribe((event) => {
        if (event instanceof ActivationEnd) {
            if(!isMetaTagsSet && event.snapshot) {
              if (event.snapshot.data['nav']) {
				let currentURL: string = this.router.url;
				const [titleKey, descriptionKey] = this.changeURLtoKeys(currentURL);
                if (titleKey){
                  this.setTitle(this.translateService.instant(titleKey));
				  this.setDescription([{name: 'description', content: this.translateService.instant(descriptionKey)}]);
                } else {
				  this.setTitle("One-Stop Online Investment Platform | Fund, Bond, Stock, ETF");
				  this.setDescription([{name: 'description', content: "Incorporated in the year 2007 in Hong Kong, FSMOne\'s mission is \"to help investors around the world invest globally and profitably\". We provides a comprehensive range of investment products and services, including funds, exchange traded funds (ETFs), bonds, stocks and FSMOne Managed Portfolios. Our regional research team has also gathered the investment experts from Hong Kong, China, Singapore, Malaysia and India."}]);
				}
              } else {
                this.setTitle(null);
              }
              isMetaTagsSet = true;
            }
        }
        if (event instanceof NavigationEnd) {
            this.isMetaTagsSet = isMetaTagsSet;
            isMetaTagsSet = false;
        }
    });
  }

    // PMS 25537 - SEO Meta Title & Description Display fix
	changeURLtoKeys(url: string): [string, string] {		
		let title: string;
		let description: string;
		
		if (url.endsWith("/about-us")) {
			title = 'about.us';
		} else if (url.endsWith("/initial-public-offering")) {
			title = 'ipo';
		} else if (url.endsWith("/regular-savings-plan")) {
			title = 'rsp';
		} else if (url.endsWith("/bond-express")) {
			title = 'bond.express';
		} else if (url.endsWith("/treasury-now")) {
			title = 'treasury.now';
		} else if (url.endsWith("/multi-currencies")) {
			title = 'cash.account';
		} else if (url.endsWith("/events")) {
			title = 'events';
		} else if (url.endsWith("/buzz")) {
			title = 'buzz';
		} else if (url.endsWith("/rewards-program")) {
			title = 'rewards.program';
		} else if (url.endsWith("/transfer-in")) {
			title = 'transfer.in';
		} else if (url.endsWith("/connect-with-fsm")) {
			title = 'connect.fsm';
		} else if (url.endsWith("/funds/market-trend/best-and-worst-performing")) {
			title = 'funds.performing';
		} else if (url.endsWith("/bonds/market-trend/best-and-worst-performing")) {
			title = 'bonds.performing';
		} else if (url.endsWith("/etfs/market-trend/best-and-worst-performing")) {
			title = 'etfs.performing';
		} else if (url.endsWith("/stocks/market-trend/best-and-worst-performing")) {
			title = 'stocks.performing';
		} else if (url.endsWith("/research/star-rating")) {
			title = 'star.rating';
		} else if (url.endsWith("/fund-101")) {
			title = 'fund.101';
		} else if (url.endsWith("/fsmone-indices")) {
			title = 'market.indices';
		} else if (url.endsWith("/funds/tools/chart-centre")) {
			title = 'funds.chart.centre';
		} else if (url.endsWith("/bonds/tools/chart-centre")) {
			title = 'bonds.chart.centre';
		} else if (url.endsWith("/etfs/tools/chart-centre")) {
			title = 'etfs.chart.centre';
		} else if (url.endsWith("/funds")) {
			title = 'funds.page';
		} else if (url.endsWith("/bonds")) {
			title = 'bonds.page';
		} else if (url.endsWith("/etfs/tools/etfs-calculator")) {
			title = 'etf.calculator';
		} else if (url.endsWith("/bonds/tools/bond-calculator")) {
			title = 'bond.calculator';
		} else if (url.endsWith("/stocks/tools/stocks-calculator")) {
			title = 'stock.calculator';
		} else if (url.endsWith("/etfs")) {
			title = 'etfs.page';
		} else if (url.endsWith("/market-performance-detail")) {
			title = 'market.performance';
		} else if (url.endsWith("/dpms")) {
			title = 'dpms';
		} else if (url.endsWith("/dpms/star-ratings")) {
			title = 'dpms.star.rating';
		} else if (url.includes("auto-sweep")) {
			title = 'auto.sweep';
		}
		
		if (title) { // if title found already, return first
			description = 'fsmone.seo.' + title + '.description';
			title = 'fsmone.seo.' + title + '.title';
			return [title, description];			
		}
		
		// title & description different 
		if (url.endsWith("/frequently-asked-questions")) {
			title = 'faq';
			description = 'faq.top.description';
		} else if (url.endsWith("/user-guide")) {
			title = 'user.guide';
			description = 'userguide.top.description';
		} else if (url.endsWith("/fund-selector")) {
			title = 'fund.selector';
			description = 'selector.fund.top.description';			
		} else if (url.endsWith("/bond-selector")) {
			title = 'bond.selector';
			description = 'selector.bond.top.description';			
		} else if (url.endsWith("/etfs-selector")) {
			title = 'etf.selector';
			description = 'selector.stock.top.description';			
		} else if (url.endsWith("/stocks-selector")) {
			title = 'stock.selector';
			description = 'selector.stock.top.description';			
		} else if (url.endsWith("/fund-restriction")) {
			title = 'fund.restriction';
			description = 'fund.restriction.top.description';			
		} else if (url.endsWith("/fund-calendar")) {
			title = 'fund.calendar';
			description = 'fund.calendar.top.description';			
		} else if (url.endsWith("/index-data")) {
			title = 'index.data';
			description = 'index.data.top.description';			
		} else if (url.endsWith("/new-bonds")) {
			title = 'new.bonds';
			description = 'new.bond.top.description';			
		} else if (url.endsWith("/top-turnover")) {
			title = 'top.turnover';
			description = 'turnover.stock.top.description';			
		} else if (url.endsWith("/glossary")) {
			title = 'glossary.title';
			description = 'glossary.top.description';			
		} else if (url.endsWith("/highest-dividend-yield")) {
			title = 'highest.dividend.yield';
			description = 'highest.dividend.yield.intro.desc.1';			
		} else if (url.endsWith("/stocks")) {
			title = 'stock';
			description = 'market.performance.top.description';
		}
		
		if (title) {
			title = 'fsmone.seo.' + title + '.title';
			description = 'fsmone.' + description;
			return [title, description];
		} else {                                
			return [title, description];      // return null
		}
	}

  setTitle(title: string) {
    this.title.setTitle(title ? `${this.DEFAULT_TITLE} | ${title}` : `${this.DEFAULT_TITLE} | ` + this.translateService.instant('fsmone.meta.title'));
  }

  setDescription(metaTags: MetaDefinition[]) {
    metaTags.forEach((m) => {
      if (m.content) {
        this.meta.updateTag(m)
      }
    });
  }

	changeLangFsmone(paramLocale: string)
	{
		const params = this.paramService.buildHttpParams({paramLocale});
		return this.http.post(ApiConstant.BASE_API_URL + ApiConstant.PROTECTED + ApiConstant.LANG + ApiConstant.CHANGE_LANG_FSMONE, {}, {params});
	}
}
