import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { UtilService } from "@api-module/service/common/util.service";
import {IInterestRateModel} from "@api-module/model/cash-account/i-interest-rate-info.model";
import {genRouterLink} from "@util/route";
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {AuthState} from "@auth-module/store";
import { Store } from "@ngxs/store";
import { CashAccountService } from 'src/app/api/rest/cash-account.rest.service';
import { IResponse } from "@api-module/model/common/i-response";
import { AppConstant, FundConstant, RouteConstant } from 'src/app/constant';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';


@Component({
  selector: 'app-interest-rate-table',
  templateUrl: './interest-rate-table.component.html',
  providers: [UtilService]
})
export class InterestRateTableComponent implements OnInit, OnChanges{
  isAuthenticated: boolean = this.store.selectSnapshot(AuthState.isAuthenticated);
  isModalVisible: boolean = false;
  BUTTON_ICON_IMAGES_PATH = 'assets/images/general/icons/';
  @Input() interestRateList : IInterestRateModel[];
  readonly watchListLink = genRouterLink(RouteConstant.APP, RouteConstant.WATCHLIST);
  localeOption:string = "zh-TW";

  constructor(private router: Router, private store: Store, private route: ActivatedRoute, private utilService: UtilService, private cashAccountService: CashAccountService,private globalDataStorage:GlobalDataStorage) {
  }

  ngOnInit(): void {
    this.setLocale();
  }

  ngOnChanges(changes: SimpleChanges): void {
	if(changes.interestRateMap){
		
	}
  }

  sort(sort:{key:string; value:any}):void{
    this.interestRateList = this.utilService.sort(sort, this.interestRateList);
  }
 
  handleCancel(): void {
    //redirect to login page
    this.isModalVisible = false;
  }

  navigateToBondBuy(issueCode){
	
  }
 setLocale(){
	let locale = this.globalDataStorage.getStorage('locale') || 'ch';
	
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
  }
}
