export class AccountTypeConstant{
    public static readonly PERSONAL_TRANSLATE= 'fsmone.account.type.personal';

    public static readonly DISCRETIONARY_TRANSLATE = "fsmone.account.type.discretionary";

    public static readonly BENEFICIAL_TRANSLATE = "fsmone.account.type.beneifical";

    public static readonly CORPORATE_TRANSLATE = "fsmone.account.type.corporate";

    public static readonly WRAP_CORPORATE_TRANSLATE = "wrap.corporate";

    public static readonly SMART_TRANSLATE = "fsmone.account.type.smart";

    public static readonly PERSONAL_TYPE = "Personal";

    public static readonly DISCRETIONARY_TYPE = "Discretionary";

    public static readonly BENEFICIARY_TYPE = "Beneficiary";

    public static readonly CORPORATE_TYPE = "Corporate";

    public static readonly WRAP_CORPORATE_TYPE = "Wrap Corporate";

}

export enum ACCOUNT_TYPE {
	PERSONAL = 'non.wrap.personal',
	SMART = 'wrap.personal',
	BENEFICIARY = 'non.wrap.beneficiary',
	DISCRETIONARY = 'discretionary',
	JOINT = 'non.wrap.joint',
	CORPORATE = 'corporate',
	CORP_DISCRETIONARY = 'fsmone.corporate.discretionary',
	CORP_SMART = 'wrap.corporate',
}
