import { TaChartSubscription, TaChartUpdater } from '@share/models/stocks/stock-ta-chart.model';
import { LightStreamerCommandConfig, LightStreamerService } from './light-streamer.service';
import { SubscribeBarsCallback, Bar } from 'src/assets/charting_library_v2/datafeed-api';
import { LocalStorageConstant } from 'src/app/constant/local.storage.constant';
import { getStorage, setStorage } from '@util/local-storage';

export class StockTaChartUpdaterIntradayStreaming implements TaChartUpdater {

  private msInstrumentId: string;
  private onTick: SubscribeBarsCallback;
  private lastBar: Bar;
  private level: string;
  private lsMarketDataService: LightStreamerService;

  constructor(subscription: TaChartSubscription, lastBar: Bar, isLive: boolean, lsMarketDataService: LightStreamerService) {
    this.msInstrumentId = subscription.symbolInfo.msInstrumentId || '';
    this.onTick = subscription.onTick;
    this.lastBar = lastBar;
    this.level = isLive ? 'L1' : 'DELAY';
    this.lsMarketDataService = lsMarketDataService;
  }

  subscribeBars(): void {
    if (!this.msInstrumentId || !this.onTick || !this.lsMarketDataService) {
      return;
    }
    var self = this;

    this.lsMarketDataService.subscribeMinuteBar(this.msInstrumentId, this.level, new class implements LightStreamerCommandConfig{
      checkChangesCallback(oldData, itemUpdate) {
          return true;
      }
      addCallback(data) {
        self.updateMinuteBar(data);
      }
      updateCallback(oldData, newData) {
        self.updateMinuteBar(newData);
      }
      deleteCallback(data) { }
    });
  }

  private updateMinuteBar(rawBar: any): void {
      // Will not update if existing last bar is newer
      let time = parseInt(rawBar.key || '0');

      if (this.lastBar && this.lastBar.time && this.lastBar.time > time) {
        return;
      }
      // Adding missing bars between last bar and current bar
      if (this.lastBar.time) {
        let closePrice = this.lastBar.close || 0;
        for (let currTime = this.lastBar.time + 60000; currTime < time; currTime += 60000) {
          this.onTick({
            time: currTime,
            volume: 0,
            open: closePrice,
            close: closePrice,
            low: closePrice,
            high: closePrice
          });
        }
      }

        // Check price
        let closePrice = parseFloat(rawBar.closePrice || '0');
        if (!closePrice) {
          return;
        }
        // Set current bar
        let bar: Bar = {
          time: time,
          volume: parseInt(rawBar.volume || '0'),
          open: parseFloat(rawBar.openPrice || '0') || closePrice,
          close: closePrice,
          low: parseFloat(rawBar.lowPrice || '0') || closePrice,
          high: parseFloat(rawBar.highPrice || '0') || closePrice
        };

        var lastUpdateDate = (time/1000).toString();
        if (getStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE) !== lastUpdateDate){
          setStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE, lastUpdateDate)
        }
        this.onTick(bar);
        this.lastBar = bar;
  }

  unsubscribeBars(): void {
    if (!this.msInstrumentId || !this.lsMarketDataService) {
      return;
    }

    this.lsMarketDataService.unsubscribeMinuteBar(this.msInstrumentId);
  }
}
