import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { Store } from '@ngxs/store';
import { AuthState, Logout } from '@auth-module/store';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private globalDataStorage: GlobalDataStorage, private store: Store, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestUrl: string = request.url;

    if (requestUrl.slice(0, 1) !== '/') {
      // append "/" if the caller forgot to add
      requestUrl = '/' + requestUrl;
    }
	
	if(requestUrl.indexOf("/assets/") != -1){
		return next.handle(request);
	}
	
	if(requestUrl.indexOf("chat.fundsupermart.com") != -1 || requestUrl.indexOf("shrewdchat-backend-dev.ap-southeast-1.elasticbeanstalk") != -1) {
		return next.handle(request);
	}

	if (requestUrl.indexOf("/rest-api/fsmoneLogin") != -1 || requestUrl.indexOf("/rest-api/") != -1){
		return next.handle(request);
	}

	let headers;
	let prefix = environment.serverUrl+'/web-api';
	if(requestUrl.indexOf("/validate-password") != -1){
		headers = new HttpHeaders({'X-REFRESH-TOKEN-VALIDITY': '300'})
	}else{
		headers = new HttpHeaders({'locale': this.globalDataStorage.getStorage('locale') || 'ch'})
		this.store.select(AuthState.isAuthenticated).subscribe(
			res => {
				if(res){
					const helper = new JwtHelperService();
					let token = this.store.selectSnapshot(AuthState.getToken);
					if(requestUrl.indexOf(ApiConstant.PROTECTED) != -1 && helper.isTokenExpired(token)){
						const actions: Array<any> = [Logout];
        				this.store.dispatch(actions.map((action => new action())));
						this.router.navigate(['login']);
					}else{
						headers = new HttpHeaders({'X-AUTH-TOKEN': token, 'locale': this.globalDataStorage.getStorage('locale') || 'ch'})
					}
				}
			}
		)
	}
	request = request.clone({
		headers: headers,
      	url: prefix + requestUrl
    });
    return next.handle(request);
  }
}