import { IFsmPostLoginModel } from '@api-module/model/authority/i-fsm-post-login.model';
import { AuthPendingActionStateModel, AuthStateModel } from '../state/auth.state';

export class Login {
  static readonly type: string = '[Auth] Login';
}

export class LoginSuccess {
  static readonly type: string = '[Auth] Login Success';

  constructor(public user: IFsmPostLoginModel) {

  }
}

export class SetAuthToken {
  static readonly type: string = '[Auth] Set Auth Token';

  constructor(public token: string, public refreshToken: string) {

  }
}

export class ResumeAuth {
  static readonly type: string = '[Auth] Resume Auth Session';

  constructor(public context: AuthStateModel) {

  }
}

export class SetResetPasswordUpdated {
  static readonly type: string = '[Auth] Set Reset Password Updated';

  constructor(public passwordUpdate: boolean) {

  }
}

export class SetForceEmailUpdated {
  static readonly type: string = '[Auth] Set Force Email Updated';

  constructor(public forceEmailUpdate: boolean) {

  }
}

export class SetOptInOtp {
  static readonly type: string = '[Auth] Set Otp In Otp';

  constructor(public otpInOtp: boolean) {

  }
}

export class SetShowFpx {
  static readonly type: string = '[Auth] Set Show Fpx';

  constructor(public showFpx: boolean) {

  }
}

export class SetFepFlag {
  static readonly type: string = '[Auth] Set Fep Flag';

  constructor(public fepFlag: boolean) {

  }
}

export class Logout {
  static readonly type: string = '[Auth] Logout';
}

export class SetAccountDetailsUpdate {
  static readonly type: string = '[Auth] Set Account Details Update';

  constructor(public updateFlag: boolean) {

  }

}

export class SetRiskProfileUpdate {
  static readonly type: string = '[Auth] Set Risk Profile Update';

  constructor(public updateFlag: boolean) {

  }
}

export class SetDkaUpdate {
  static readonly type: string = '[Auth] Set Derivative Knowledge Assessment Update';

  constructor(public updateFlag: boolean) {

  }
}

export class AccountAcceptAiDeclaration {
  static readonly type: string = '[Auth] Declare AI Status';

  constructor(public refno: string) {

  }
}

export class SetFpxBypass {
  static readonly type: string = '[Auth] Set Fpx Navigation';

  constructor(public fpxBypass: boolean) {

  }
}

export class SetPendingActions {
  static readonly type: string = '[Auth] Set Pending Actions';

  constructor(public pendingActions: Partial<AuthPendingActionStateModel>) {

  }
}

export class SetEmailUpdated {
  static readonly type: string = '[Auth] Set Email Updated';

  constructor(public emailUpdate: boolean) {
  }
}


export class SetPersonalCreated {
  static readonly type: string = '[Auth] Set Personal Created';

  constructor(public isPersonalCreated: boolean) {
  }
}

export class SetEmisAcctUpdate {
  static readonly type: string = '[Auth] Set Emis Account Update';

  constructor(public updateFlag: boolean) {

  }
}

export class SetCdsEnabled {
  static readonly type: string = '[Auth] Set Cds Enabled';

  constructor(public cdsEnabled: boolean) {

  }
}

export class SetCdsAccountNo {
  static readonly type: string = '[Auth] Set Cds Account No';

  constructor(public cdsAccountNo: string) {

  }
}

export class SetMarkAsRead {
  static readonly type: string = '[Auth] Mark all as read';

  constructor(public markAsRead: string) {

  }
}

export class SetNum2FA {
  static readonly type: string = '[Auth] Set 2FA Num';

  constructor(public num2FA: string) {

  }
}

export class SetReRoute2FA {
  static readonly type: string = '[Auth] Set 2FA Re-route';

  constructor(public reRoute2FA: boolean) {

  }
}

export class SetRpqExpiry {
  static readonly type: string = '[Auth] Set Rpq Expiry';

  constructor(public rpqExpiryReminder: boolean) {

  }
}

export class SetPwdExpReminded {
  static readonly type: string = '[Auth] Set Pwd Expiry';
  constructor (public passwordExpiryReminded: boolean){}
}

export class SetIsAcctBlocked {
  static readonly type: string = '[Auth] Set Account Temporarily Blocked Flag';

  constructor(public isAcctBlocked: boolean) {

  }
}
