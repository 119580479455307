<div>
    <div *ngIf="title!=''">
        <nz-row nzType="flex" nzAlign="middle" nzJustify="space-between" class="mb-3">
            <nz-col><h3 class="mb-2"><span [innerHtml]="title"></span></h3></nz-col>
            <nz-col *ngIf="hideViewMoreLink==false"><a class="font-weight-bold text-sm"
                                                       (click)="redirectToArticlelist()">{{'fsmone.more' | translate}}</a>
            </nz-col>
        </nz-row>
    </div>

    <div *ngIf="!loading;else loadingTemplate">
        <div *ngIf="articleList.length<=0" class="text-center">
            <span translate="personalised.content.no.related.articles" translate-default="No related articles."></span>
        </div>
    </div>

    <ng-template #loadingTemplate>
        <nz-skeleton [nzActive]="true" [nzLoading]="loading">
        </nz-skeleton>
    </ng-template>


    <nz-row [nzGutter]="(isMobile$ | async) ? 0 : 16" nzType="flex">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12" *ngIf="highlightArticle!==undefined">
            <a [href]="formArticleDetailsUrl(highlightArticle.articleId.toString(), highlightArticle.isWebcast)" target="_blank">
<!--                <article-->
<!--                        highLight-->
<!--                        (articleClick)="redirectToArticleDetails(highlightArticle.articleId.toString()"-->
                <article
                        highLight
                        [title]="highlightArticle.title"
                        [image]="highlightArticle.featuredImage"
                        [author]="highlightArticle.author"
                        [date]="highlightArticle.logDate"
                        [view]="highlightArticle?.hits"
                        [desc]="highlightArticle.synopsis"
                        [isWebcast]="highlightArticle?.isWebcast"
                ></article>
            </a>
            <nz-divider *ngIf="(isMobile$ | async)"></nz-divider>
        </nz-col>
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
            <nz-row [nzGutter]="16" nzType="flex">
                <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12"
                        *ngFor="let article of topArticles; last as isLast" [ngClass]="{'mb-4': !(isMobile$ | async)}">
                    <a [href]="formArticleDetailsUrl(article.articleId.toString(), article.isWebcast)" target="_blank">
                        <article
                            [horizontal]="(isMobile$ | async)"
                            [title]="article.title"
                            [image]="article.featuredImage"
                            [author]="article.author"
                            [date]="article.logDate"
                            [view]="article?.hits"
                            [isWebcast]="article?.isWebcast"
                        ></article>
                    </a>
                    <nz-divider [ngClass]="{'mb-0' : isLast}" *ngIf="(isMobile$ | async)"></nz-divider>
                </nz-col>
            </nz-row>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="(isMobile$ | async) ? 0 : 16" nzType="flex">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="6" *ngFor="let article of bottomArticles; last as isLast"
                [ngClass]="{'mb-3': !(isMobile$ | async)}">
            <a [href]="formArticleDetailsUrl(article.articleId.toString(), article.isWebcast)" target="_blank">
            <article
                    [horizontal]="(isMobile$ | async)"
                    [title]="article.title"
                    [image]="article.featuredImage"
                    [author]="article.author"
                    [date]="article.logDate"
                    [view]="article?.hits"
                    [isWebcast]="article?.isWebcast"
            ></article></a>
            <nz-divider [ngClass]="{'mb-0' : isLast}" *ngIf="(isMobile$ | async) && !isLast"></nz-divider>
        </nz-col>
    </nz-row>
    <nz-row nzType="flex" nzJustify="center" class="mt-4 text-center" *ngIf="hideViewMoreButton==false">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
            <button nz-button nzType="outline" (click)="redirectToArticlelist()"><span
                    translate="fsmone.more.articles"></span></button>
        </nz-col>
    </nz-row>
</div>

