import { IDropdownModel } from '@api-module/model/common/i-dropdown.model';
import { ImagePathConstant } from './image-path.constant';
import { AppConstant } from './app.constant';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export class FundConstant {
  public static readonly PERIOD_DEFAULT: string = '1Y';
  public static readonly RISK_DEFAULT: string = 'Low';
  public static readonly INVESTMENT_AMT_DEFAULT: number = 3000;
  public static readonly FUND_ARTICLE_SORT: IDropdownModel[] = [
    {label: 'Most Views', value: 'hits'},
    {label: 'Latest', value: 'logdate'},
  ];

  public static readonly ANNUAL_CHARGEABLE_INCOME: any[] = [
    {min: 5001, max: 20000, taxRelief: 0.01},
    {min: 20001, max: 35000, taxRelief: 0.03},
    {min: 35001, max: 50000, taxRelief: 0.08},
    {min: 50001, max: 70000, taxRelief: 0.14},
    {min: 70001, max: 100000, taxRelief: 0.21},
    {min: 100001, max: 250000, taxRelief: 0.24},
    {min: 250001, max: 400000, taxRelief: 0.245},
    {min: 400001, max: 600000, taxRelief: 0.25},
    {min: 600001, max: 1000000, taxRelief: 0.26},
    {min: 1000001, max: 9999999, taxRelief: 0.28},
  ];

  public static readonly MONTHLY_COMMENTARY_SORT: IDropdownModel[] = [
    {label: 'Date', value: 'logdate'},
    {label: 'Views', value: 'hits'},
  ];
  public static readonly CHINESE_TAG = "ä¸­æ–‡æ–‡è�ƒ";
  public static readonly ARTICLES_LIVE_URL: string = AppConstant.LIVE_URL + "/articleFiles/";
  public static readonly DEFAULT_ARTICLE_ICON: string = ImagePathConstant.ARTICLES_IMG_PATH + "research-article-default.jpg";
  public static readonly DEFAULT_ARTICLE_AUTHOR_ICON: string = ImagePathConstant.ARTICLES_IMG_PATH + "articleAuthorDefaultIcon.svg";


public static readonly fundSlideAnimation = trigger('scrollY', [
  state('void', style({
    transform: 'translateY(-100%)',
    opacity: 0,

  })),
  state('initialNext', style({
    transform: 'translateY(100%)',
    opacity: 0,
  })),
  state('initialBack', style({
    transform: 'translateY(-100%)',
    opacity: 0,
  })),
  state('initial', style({
    transform: 'translateY(0%)',
    opacity: 1,
  })),
  state('next', style({
    transform: 'translateY(-100%)',
    opacity: 0,
  })),
  state('back', style({
    transform: 'translateY(100%)',
    opacity: 0,

  })),
  transition( '* => initial', animate('300ms ease-in')),
  transition( '* => back', animate('400ms ease-out', keyframes([
      style({transform: 'translateY(-30%)'}),
    style({transform: 'translateY(0%)'}),

    style({transform: 'translateY(100%)', opacity: 0})
    ]))),
  transition( '* => next', animate('300ms ease-out', keyframes([
    style({transform: 'translateY(30%)'}),
    style({transform: 'translateY(0%)'}),
    style({transform: 'translateY(-100%)', opacity: 0})
  ]))),


]);
  public static readonly AM_MANAGER_NAME: string = "AmInvestment Services Berhad";
  public static readonly MANULIFE_MANAGER_NAME: string = "Manulife Asset Management Services Berhad";
  public static readonly RHB_MANAGER_NAME: string = "RHB Asset Management Sdn Bhd";
  public static readonly AFFIN_MANAGER_NAME: string = "Affin-Hwang Asset Management Berhad";
  public static readonly PRINCIPAL_MANAGER_NAME: string = "Principal Asset Management Berhad";
  public static readonly KENANGA_MANAGER_NAME: string = "Kenanga Investors Berhad";
  public static readonly AIA_FORM_NAME: string = "AIA Pension and Asset Management Sdn. Bhd.";
  public static readonly AFFIN_HWANG_MANAGER_CODE: string = 'MYHWDBS'
  public static readonly KENANGA_MANAGER_CODE: string = 'MYKENANGA';
  public static readonly AIA_MANAGER_CODE: string = 'MYAIA';
  public static readonly AM_MANAGER_CODE: string = 'MYAMISB';
  public static readonly RHB_MANAGER_CODE: string = 'MYRHBIM';
  public static readonly PRINCIPAL_MANAGER_CODE: string = 'MYCIMBPRI';
  public static readonly MANULIFE_MANGER_CODE: string = 'MYMLIFE';
  public static readonly NEW_PRS_APPLICATION_ENABLED_MANAGER_CODES = [
    'MYCIMBPRI',
    'MYHWDBS',
    'MYKENANGA',
    'MYRHBIM',
  ];
  public static readonly INVESTMENT_AMOUNT: IDropdownModel[] =
    [
      {
        label: 'MYR 3,000',
        value: FundConstant.INVESTMENT_AMT_DEFAULT
      },
      {
        label: 'MYR 6,000',
        value: 6000
      },
      {
        label: 'MYR 9,000',
        value: 9000
      }
    ];

  public static readonly IFAST_MANAGERCODE = 'MYIFSG';
  public static readonly BOS_MANAGERCODE = 'MYPACIFIC';

}
