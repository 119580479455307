<div id="header-mobile">
  <div class="cd-dropdown-wrapper">
    <div class="float-left">
      <div class="menu-item">
        <span *ngIf="!showMenu" role="button" (click)="openMenu()">
          <i nz-icon nzType="menu" nzTheme="outline"></i>
          <span class="ml-1 menu hidden-sm-down">{{'fsmone.menu' | translate}}</span>
        </span>
      </div>
    </div>
    <div class="float-right">
      <div class="d-inline-block">
        <div class="menu-item d-inline-block">
          <span role="button" (click)="openSearch()"><i nz-icon nzType="search" nzTheme="outline"></i></span>
        </div>
        <ng-container *ngTemplateOutlet="langTemplates">
        </ng-container>
        <div class="menu-item d-inline-block login" role="button" [routerLink]="loginLink" *ngIf="!(auth||authCas)">
          <i class="mr-1" nz-icon nzType="lock" nzTheme="outline"></i>{{'fsmone.login' | translate}}
        </div>

        <div class="menu-item d-inline-block" role="button" (click)="openPostLoginMenu()" *ngIf="(auth||authCas)">
          <i nz-icon nzType="bell" nzTheme="fill"></i>
        </div>

        <div class="menu-item d-inline-block" role="button" (click)="openPostLoginMenu()" *ngIf="(auth||authCas)">
          <i nz-icon nzType="user" nzTheme="outline"></i>
          <span class="ml-1 hidden-sm-down">{{'fsmone.my.account' | translate}}</span>
          <i class="ml-1 hidden-sm-down" nz-icon nzType="caret-down" nzTheme="outline"></i>
        </div>
      </div>
    </div>

    <div *ngIf="showMenu || showSubList">
      <div class="cd-dropdown" *ngIf="showMenu">
        <div class="menu-row">
          <div class="fsm-one-mobile-logo">
              <a>
                <img alt="FSMOne" src="images/icon/fsm-one-mobile.png" height="39">
              </a>
              <span class="cd-close" role="button" (click)="closeMenu()"><i nz-icon nzType="close" nzTheme="outline"></i></span>
            </div>
          </div>
          <ul class="cd-dropdown-content list-unstyled">
            <ng-container *ngFor="let item of menu">
              <li>
                <a class="menu-title menu-row" *ngIf="item?.icon" [href]="item.url" target="_blank">iFAST TV</a>
                <span *ngIf="!item?.children && !item?.icon" class="menu-title" role="button" (click)="navigate(item.url, false)">{{item?.titleKey | translate}}</span>
                <span *ngIf="item?.children && !item?.icon" class="menu-title" role="button" (click)="openSubList(item?.children, item?.titleKey)">{{item?.titleKey | translate}}<i class="menu-arrow" nz-icon nzType="right" nzTheme="outline"></i></span>
              </li>
            </ng-container>
          </ul>
      </div>
      <div class="cd-dropdown" *ngIf="showSubList">
        <div class="menu-row">
          <div class="fsm-one-mobile-logo">
              <a role="button" (click)="navigate('/',false)">
                <img alt="FSMOne" src="images/icon/fsm-one-mobile.png" height="39">
              </a>
              <span class="cd-close" role="button" (click)="closeMenu()"><i nz-icon nzType="close" nzTheme="outline"></i></span>
            </div>
          </div>
          <ul class="cd-dropdown-content list-unstyled">
            <li>
              <span class="menu-title" role="button" (click)="backToMenu()"><i class="mr-2" nz-icon nzType="left" nzTheme="outline"></i>{{ selectedSubListKey | translate }}</span>
            </li>
            <ng-container *ngFor="let menu of subList">
              <li>
                <span class="sub-menu-title" role="button" (click)="navigate(menu.url, menu.postLogin)">{{menu?.titleKey | translate}}
                <i *ngIf="menu.postLogin" nz-icon nzType="lock" nzTheme="fill" class="icon-white"></i></span>
              </li>
            </ng-container>
          </ul>
      </div>
    </div>
    <div *ngIf="postLoginMenu || subPostLoginMenu">
      <div class="cd-dropdown" *ngIf="postLoginMenu">
        <div class="menu-row">
          <div class="fsm-one-mobile-logo">
              <h2>FSM</h2>
              <span class="cd-close" role="button" (click)="closePostMenu()"><i nz-icon nzType="close" nzTheme="outline"></i></span>
            </div>
          </div>
          <ul class="cd-dropdown-content list-unstyled">
            <ng-container *ngFor="let item of postMenu">
              <li *ngIf="item.clickable">
                <span class="menu-title" role="button" (click)="openPostMenuSubList(item?.children, item?.titleKey)">{{item?.titleKey | translate}}<i class="menu-arrow" nz-icon nzType="right" nzTheme="outline"></i></span>
              </li>
              <li *ngIf="!item.clickable">
                <span class="menu-title" role="button" (click)="navigatePostLogin(item.url)">{{item?.titleKey | translate}}</span>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of accountSettingMenu">
              <li>
                <span class="menu-title" role="button" (click)="openPostMenuSubList(item?.children, item?.titleKey)">{{item?.titleKey | translate}}<i class="menu-arrow" nz-icon nzType="right" nzTheme="outline"></i></span>
              </li>
              <li class="mt-3">
                <a (click)="logout()" class="ant-btn ant-btn-danger logout-title"><i class="mr-2" nz-icon nzType="unlock" nzTheme="outline"></i>{{'fsmone.logout' | translate}}</a>
              </li>
            </ng-container>
          </ul>
      </div>
      <div class="cd-dropdown" *ngIf="subPostLoginMenu">
        <div class="menu-row">
          <div class="fsm-one-mobile-logo">
            <h2>FSM</h2>
            <span class="cd-close" role="button" (click)="closePostMenu()"><i nz-icon nzType="close" nzTheme="outline"></i></span>
          </div>
        </div>
        <ul class="cd-dropdown-content list-unstyled">
          <li>
            <span class="menu-title" role="button" (click)="closeSubPostMenu()"><i class="mr-2" nz-icon nzType="left" nzTheme="outline"></i>{{ selectedSubListKey | translate }}</span>
          </li>
          <ng-container *ngFor="let menu of postMenuSubList">
            <li *ngIf="checkMenu(menu?.titleKey)">
              <span class="sub-menu-title" role="button" (click)="navigatePostLogin(menu.url)">{{menu?.titleKey | translate}}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #mobileGlobalSearch>
	  <global-search></global-search>
	  <i (click)="closeSearch()" class="float-right" nz-icon nzType="close" nzTheme="outline"></i>
</ng-template>

<nz-modal
	[nzVisible]="globalSearch"
  [nzFooter]="null"
  (nzOnCancel)="closeSearch()">
  <div class="mt-4">
    <global-search></global-search>
  </div>
</nz-modal>