<nz-card>
  <div class="p-2">
    <h4 *ngIf="title" class="mb-1" [ngClass]="{'mb-1': lists?.length > 1, 'mb-3': lists?.length == 1}">
      <ng-container *nzStringTemplateOutlet="title">{{ title }}</ng-container>
    </h4>
    <div *ngIf="lists?.length > 1 else noTab">
      <ng-container *ngIf="tab?.length === lists?.length">
        <nz-tabset [nzType]="'line'" [nzSelectedIndex]="currentIndex">
          <nz-tab *ngFor="let list of lists; let i = index" [nzTitle]="tab[i]">
            <ng-container *ngTemplateOutlet="content; context: {contentList: list}"></ng-container>
          </nz-tab>
        </nz-tabset>
      </ng-container>
    </div>
    <ng-template #noTab>
      <ng-container *ngTemplateOutlet="content; context: {contentList: lists[0]}"></ng-container>
    </ng-template>
    <ng-template #content let-contentList="contentList">
      <ng-container *ngFor="let item of contentList; let j = index let last = last;  let first = first">
        <div nzTooltipPlacement="bottomLeft" nz-tooltip [nzTooltipTitle]="tooltipTemplate" *ngIf="item?.tooltipTemplate; else containerTemplate">
          <ng-container *ngTemplateOutlet="containerTemplate"></ng-container>
        </div>
        <ng-template #tooltipTemplate>
          <span [innerHTML]="item?.tooltipTemplate"></span>
        </ng-template>
        <ng-template #containerTemplate>
          <div class="d-flex" [ngClass]="{'pt-3': !first}" nz-popover nzType="primary" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right">
            <div b2c-ui-icon name="productRankingBgIcon" class="mr-3 product-ranking-img">
              <span class="text-lg font-weight-bold">{{ j + 1 }}</span>
            </div>
            <div class="d-flex w-100" [ngClass]="last? (indicator? 'border-bottom pb-3' : '') : 'border-bottom pb-3'">
              <div [ngClass]="{'text-sm': horizontal}">
                <div *ngIf="item.managerName"><span [innerHtml]="item.managerName"></span></div>
                <div class="font-weight-bold pointer" (click)="onNav(item.urlParam)"><span [innerHtml]="item.name"></span></div>
                <div [class]="(item.value | pnl:'text') + ' mt-1'" *ngIf="!horizontal">
                  <i nz-icon [nzType]="item.value | pnl:'caret'" [nzTheme]="'fill'"></i>
<!--                   <span>{{ item.value ? (item?.value | number:'1.2-2') + '%' : '-' }}</span> -->
                  <span *ngIf="item.value">{{ item.value == 999999 ? '-' : (item?.value|number:'1.2-2')+'%' }}</span>
                  <span *ngIf="!item.value">'-'</span>
                </div>
              </div>
              <div *ngIf="horizontal" style="min-width: 80px;" [class]="(item.value | pnl:'text') + ' text-right'">
                <div *ngIf="listValuePrefix">
                  <ng-container *nzStringTemplateOutlet="listValuePrefix">{{ listValuePrefix }}</ng-container>
                </div>
                <div>
                  <i nz-icon [nzType]="item.value | pnl:'caret'" [nzTheme]="'fill'"></i>
                   <span *ngIf="item.value">{{ item.value == 999999 ? '-' : (item?.value|number:'1.2-2')+'%' }}</span>
                   <span *ngIf="!item.value">'-'</span>
                </div>
              </div>
            </div>
            <ng-template #contentTemplate>
            	<ng-container *ngIf="'bond'==item.prod">
					<div><h6>{{'fsmone.bonds.years.to.maturity' | translate}}</h6><p>{{item.yearsToMaturity | number:'1.2-2'}}</p></div>
					<div><h6>{{'fsmone.ask.yield' | translate}} <sup>2</sup></h6><p>{{item.fsmOfferNytm ? item.fsmOfferNytm : '-'}}</p></div>
					<div><h6>{{'fsmone.new.bonds.on.board.offer.indicative.price' | translate}} <sup>1</sup></h6><p>{{item.offerPrice}}</p></div>
					<div><h6>{{'fsm.direct.bond.factsheet.factsheet.details.credit.rating' | translate}}</h6><p>{{item.creditRating}}</p></div>
				</ng-container>
            	<ng-container *ngIf="'fund'==item.prod">
					<div><h6>{{'fsmone.risk.rating' | translate}}</h6><p>{{item.riskDisp}}</p></div>
					<div><h6>{{'minimum.initial.investment' | translate}}</h6><p>{{item.initAmount}}</p></div>
					<div><h6>{{'fsmone.fund.size' | translate}}</h6><p>{{item.productSize}}</p></div>
					<div><h6>{{'last.updated' | translate}}</h6><p>{{item.priceDate | hkDate:localeOption:'dd MMM yyyy'}}</p></div>
            	</ng-container>
			</ng-template>
          </div>
        </ng-template>
        <div class="text-black-5 text-xs mt-2" *ngIf="indicator">
          <ng-container *nzStringTemplateOutlet="indicator">{{ indicator }}</ng-container>
        </div>
      </ng-container>
    </ng-template>
  </div>
</nz-card>