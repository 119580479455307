export class AuthConstant {
  public static readonly DELIMITER: string = '--';
  public static readonly X_AUTH_HEADER: string = 'X-AUTH-TOKEN';

  // HTTP Status Codes
  public static readonly BAD_REQUEST: number = 400;
  public static readonly UNAUTHORIZED: number = 401;
  public static readonly FORBIDDEN_ERROR: number = 403;
  public static readonly NOT_FOUND: number = 404;
  public static readonly INTERNAL_SERVER_ERROR: number = 500;
  public static readonly GATEWAY_TIMEOUT: number = 504;

  public static readonly ONE_MINUTE_PULSE_EXPIRY: number = 1000 * 60;
  public static readonly HALF_HOUR_PULSE_EXPIRY: number = 1000 * 60 * 30;

  // Cross Tab BC
  public static readonly CHANNEL_NAME: string = 'FSM_AUTH_CHANNEL';
  public static readonly LOGOUT_ALL_TAB: string = 'LOGOUT_ALL_TAB';
  public static readonly USER_ACTIVITY: string = 'USER_ACTIVITY';
}
