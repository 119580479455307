import { formatNumber, formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { LightStreamerService } from './light-streamer.service';
import { StockTaChartUpdaterDailyStreaming } from './stock.ta.chart.updater.daily.streaming';
import { StockTaChartUpdaterIntradayStreaming } from './stock.ta.chart.updater.intraday.streaming';
import { StockTaChartUpdaterOnInterval } from './stock.ta.chart.updater.on.interval';
import {
  Bar, DatafeedConfiguration, ErrorCallback, GetMarksCallback, HistoryCallback,
  IDatafeedChartApi, IExternalDatafeed, Mark, OnReadyCallback, PeriodParams,
  ResolutionString, ResolveCallback, SearchSymbolsCallback, SubscribeBarsCallback,
  SymbolResolveExtension, TimescaleMark
} from 'src/assets/charting_library_v2/datafeed-api';
import {
  IChartingLibraryWidget, IChartWidgetApi, IActionVariant, ActionId, IDropdownApi
} from 'src/assets/charting_library_v2/charting_library';
import { StockTransactionRestService } from '@api-module/rest/stock.transaction.rest.service';
import { StocksInfoRestService } from '@api-module/rest/stocks-info.rest.service';
import { GlobalDataStorage } from '@share/storages/global-data.storage';
import { SymbolInfo, TaChartSubscription, TaChartUpdater } from '@share/models/stocks/stock-ta-chart.model';
import { StockService } from '@share/services/stock/stock.service';
import { LocalStorageConstant } from 'src/app/constant/local.storage.constant';
import { setStorage } from '@util/local-storage';

/**
 * This class implements interaction with UDF-compatible datafeed.
 * See UDF protocol reference at https://github.com/tradingview/charting_library/wiki/UDF
 */
export class StockTaChartDatafeed implements IExternalDatafeed, IDatafeedChartApi {

  private module: string;
  private tickerNo: string;
  private refno: string;

  private lsMarketDataService: LightStreamerService;
  private stocksInfoRestService: StocksInfoRestService;
  private stockTransactionRestService: StockTransactionRestService;
  private translateService: TranslateService;
  private globalDataStorage: GlobalDataStorage;
  private stockService: StockService;

  private chart: IChartWidgetApi;
  private crossHairPane: any;
  private crossHairPrice: number;
  private unadjusted: boolean = false;
  private unadjustedButton: HTMLElement;
  private markTypeToVisibilityMap: { [ key: string ]: boolean } = {};
  private markOptionsApi: IDropdownApi;
  private tickerNoToMarksMap: { [ key: string ]: Mark[] } = {};
  private symbolInfoMap: { [ key: string ]: SymbolInfo } = {};
  private lastBarMap: { [ key: string ]: Bar } = {};
  private subscriptionMap: { [ key: string ]: TaChartUpdater } = {};
  private resetMap: { [ key: string ]: () => void } = {};

  constructor(
      module: string,
      tickerNo: string,
      refno: string,
      lsMarketDataService: LightStreamerService,
      stocksInfoRestService: StocksInfoRestService,
      stockTransactionRestService: StockTransactionRestService,
      translateService: TranslateService,
      globalDataStorage: GlobalDataStorage,
      stockService: StockService
    ) {
    this.module = module;
    this.tickerNo = tickerNo;
    this.refno = refno;
    this.lsMarketDataService = lsMarketDataService;
    this.stocksInfoRestService = stocksInfoRestService;
    this.stockTransactionRestService = stockTransactionRestService;
    this.translateService = translateService;
    this.globalDataStorage = globalDataStorage;
    this.stockService = stockService;
  }

  onReady(callback: OnReadyCallback): void {
    let configuration: DatafeedConfiguration = {
      exchanges: [], // For search grouping
      symbols_types: [], // For search grouping
      supported_resolutions: [
        '1' as ResolutionString,
        '2' as ResolutionString,
        '3' as ResolutionString,
        '5' as ResolutionString,
        '15' as ResolutionString,
        '30' as ResolutionString,
        '60' as ResolutionString,
        '120' as ResolutionString,
        '1D' as ResolutionString,
        '1W' as ResolutionString,
        '1M' as ResolutionString,
        '3M' as ResolutionString,
        '12M' as ResolutionString
      ],
      supports_marks: true,
      supports_timescale_marks: false,
      supports_time: false
    };
    setTimeout(() => {
      callback(configuration);
    }, 0)
  }

  onChartWidgetReady(chartWidget: IChartingLibraryWidget): void {
    this.chart = chartWidget.activeChart();
    // Record cross hair location when it moves
    this.chart.crossHairMoved().subscribe(null, ({ time, price }) => {
      // Hack to get cross hair pane
      if (this.chart && this.chart['_chartWidget'] && this.chart['_chartWidget']['_model'] && this.chart['_chartWidget']['_model'].crossHairSource()) {
        this.crossHairPane = this.chart['_chartWidget']['_model'].crossHairSource().pane;
      }
      this.crossHairPrice = price;
    });
  }

  searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
    if (!userInput) {
      return;
    }
    this.stocksInfoRestService.getSearchStocksByKeywordFromTaChart(userInput, "30").subscribe((response) => {
      let results = [];
      if(response && response.data){
        for (let i = 0; i < response.data.length; i++) {
          let symbol = response.data[i].symbol;
          let name   = response.data[i].full_name;
          let exchange = response.data[i].exchange;
          let tickerNo = response.data[i].ticker;
          let type = response.data[i].type;

          results.push({
            'symbol': symbol,
            'full_name': undefined,
            'description': name,
            'exchange': exchange,
            'ticker': tickerNo,
            'type': undefined
          });
        }
      }
      onResult(results);
    });
  }

  resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension?: SymbolResolveExtension): void {
    if (this.symbolInfoMap[symbolName]) {
      onResolve(this.symbolInfoMap[symbolName]);
      return;
    }
    this.stocksInfoRestService.getSymbolInfoDetails(symbolName).subscribe((response) => {
      if (response.data) {
        // Not displaying type
        response.data.type = undefined;
        // To reduce server call if tradingview using symbol in SGX:AIY format
        this.symbolInfoMap[response.data.exchange + ':' + response.data.name] = response;

        onResolve(response.data);
      } else {
        onError('Invalid stock');
      }
    });
  }

  getMarks(symbolInfo: SymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<Mark>, resolution: ResolutionString): void {
    // Note that current version of chart has several bugs related to marks
    // tv-transition--fade-in and tv-transition--fade-out does not work properly
    // also sometimes there might be multiple mark tooltips on the canvas
    // See en-tv-chart.e2a841ff.html for temporary fix
    let tickerNo = symbolInfo.tickerNo.toString() || '';
    let exchange = symbolInfo.exchange.toString() || '';
    let stockCode = symbolInfo.stockCode.toString() || '';
    let marks = this.tickerNoToMarksMap[tickerNo]
    if (marks) {
      let displayMarks = [];
      for (let mark of marks) {
        if(mark.id){
          let markType = mark.id.toString().split('_')[0];
          if (this.markTypeToVisibilityMap[markType]) {
            displayMarks.push(mark);
          }
        }
      }
      onDataCallback(displayMarks);
      return;
    }
    // Call server to get data
    let dateFormat = this.globalDataStorage.getStorage('locale') === 'ch' || this.globalDataStorage.getStorage('locale') === 'zh' ? "yyyy年MM月dd日" : "dd MMM yyyy";
    let newMarks: Mark[] = [];
    let serverCalls = [];
    let selectedAccountNo = this.refno;
    // Dividend announcement
    serverCalls.push(new Observable((observer) => {
      this.stocksInfoRestService.getDividendDataV2ByTickerNo(tickerNo).subscribe((response) => {
        if (!response || !response.data) {
          observer.next();
          observer.complete();
          return;
        }
        if(response.data){
          let allDividends = response.data.calendarYearDividends || response.data.dividends;
          for (let i = 0; i < allDividends.length; i++) {
            let dividendGroup = allDividends[i];
            for (let j = 0; j < dividendGroup.data.length; j++) {
              let dividend = dividendGroup.data[j];
              let exDate = new Date(dividend.exDate || 0);
              exDate.setHours(0);
              exDate.setMinutes(0);
              exDate.setSeconds(0);
              exDate.setMilliseconds(0);
              let mark: Mark = {
                id: TAG_DA + '_' + tickerNo + '_' + i + '_' + j,
                time: exDate.getTime() / 1000,
                color: { color: '#ffffff', background: '#000000' },
                text: '<p style="white-space: nowrap;">' + dividend.dividendType + '</p>' +
                  '<p style="white-space: nowrap;">' + dividend.dividendCurrency + ' ' + dividend.dividendAmount + '</p>' +
                  '<p style="white-space: nowrap;">' + this.translateService.instant('ex.date') + ' ' + formatDate(exDate, dateFormat, 'en') + '</p>',
                label: TAG_DA,
                labelFontColor: '#ffffff',
                minSize: 16
              }
              newMarks.push(mark);
              this.markTypeToVisibilityMap[TAG_DA] = true;
            }
          }
        }
        this.renderMarkOptions();
        observer.next();
        observer.complete();
      });
    }));
    // Client's transaction
    serverCalls.push(new Observable((observer) => {
      if (selectedAccountNo) {
        this.stocksInfoRestService.getRealizedPnlDetails(selectedAccountNo, stockCode).subscribe((response) => {
          if(response.data && response.data.allBuySellRealizedPnlList){
            for (let i = 0; i < response.data.allBuySellRealizedPnlList.length; i++) {
              let transaction = response.data.allBuySellRealizedPnlList[i];
              if (!transaction || !['received', 'pending', 'complete', '1', '2'].includes(transaction.status)) {
                continue;
              }
              let isBuy = transaction.transactionType === 'buy';
              let quantityText = '';
              if (transaction.units > 0) {
                quantityText = formatNumber(transaction.units, 'en', '1.0-0') + ' ' + this.translateService.instant('stock.pad.shares');
              }
              let priceText = '';
              if (transaction.transactedPrice > 0) {
                priceText = ' @ ' + transaction.transactionCurrency + ' ' + formatNumber(transaction.transactedPrice, 'en', '1.4-4');
              }
              let amountText = '';
              // For realized P&L API, transacted amount does include fee
              if (transaction.amountGross > 0) {
                amountText = this.translateService.instant('amount') + ' ' + transaction.transactionCurrency + ' ' +
                    formatNumber(transaction.amountGross, 'en', '1.2-2')
              }
              let dateText = '';
              if (transaction.transactionDate > 0) {
                dateText = this.translateService.instant('transaction.date') + ' ' + formatDate(transaction.transactionDate, dateFormat, 'en');
              }
              let text = '<p style="white-space: nowrap;">' + transaction.refno + ' ' + transaction.transactionType + '</p>' +
                  '<p style="white-space: nowrap;">' + quantityText + priceText + '</p>' +
                  '<p style="white-space: nowrap;">' + amountText + '</p>' +
                  '<p style="white-space: nowrap;">' + dateText + '</p>';
              let label = isBuy ? TAG_B : TAG_S;
              let markType = label + transaction.refno;
              let mark: Mark = {
                id: markType + '_' + tickerNo + '_' + transaction.contractNo,
                time: transaction.transactionDate / 1000,
                color: { color: '#ffffff', background: isBuy ? '#009933' : '#FF333A' },
                text: text,
                label: label,
                labelFontColor: '#ffffff',
                minSize: 16
              }
              newMarks.push(mark);
              this.markTypeToVisibilityMap[markType] = true;
            }
          }
          /*
          for (let i = 0; i < response?.data?.clientDividendList?.length; i++) {
            let dividend = response.data.clientDividendList[i]?.corporateActionBean;
            if (!dividend) {
              continue;
            }
            let exDate = new Date(dividend.exDate || 0);
            exDate.setHours(0);
            exDate.setMinutes(0);
            exDate.setSeconds(0);
            exDate.setMilliseconds(0);
            let markType = TAG_DR + dividend.refno;
            let mark: Mark = {
              id: markType + '_' + tickerNo + '_' + dividend.corporateActionId,
              time: exDate.getTime() / 1000,
              color: { color: '#ffffff', background: '#000000' },
              text: '<p style="white-space: nowrap;">' + dividend.refno + ' ' + dividend.corpDescription + '</p>' +
                '<p style="white-space: nowrap;">' + this.translateService.instant('rate') + ' ' + dividend.paidCcy + ' ' + dividend.paidAmt + '</p>' +
                '<p style="white-space: nowrap;">' + this.translateService.instant('amount') + ' ' + dividend.paidCcy + ' ' + dividend.netDividend + '</p>' +
                '<p style="white-space: nowrap;">' + this.translateService.instant('ex.date') + ' ' + formatDate(exDate, dateFormat, 'en') + '</p>' +
                '<p style="white-space: nowrap;">' + this.translateService.instant('stock.intro.dividend.payment.date') + ' ' + formatDate(dividend.postCashDtlDate, dateFormat, 'en') + '</p>',
              label: TAG_DR,
              labelFontColor: '#ffffff',
              minSize: 16
            }
            newMarks.push(mark);
            this.markTypeToVisibilityMap[markType] = true;
          }*/
          this.renderMarkOptions();
          observer.next();
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    }));
    // Display after all calls are done
    forkJoin(serverCalls).subscribe(() => {
      newMarks.sort((item1, item2) => {
        // Note that tradingview will render marks from bottom, so need to order marks in descending order
        // Sort by time in decending order
        if (item1.time !== item2.time) {
          return item2.time - item1.time;
        }
        // Sort by seq in decending order
        return this.getLabelSeq(item2.label) - this.getLabelSeq(item1.label);
      });
      this.tickerNoToMarksMap[tickerNo] = newMarks;
      this.getMarks(symbolInfo, from, to, onDataCallback, resolution);
    });
  }

  private getLabelSeq(label: string): number {
    if(label){
      if (label.startsWith(TAG_DA)) {
        return 1;
      }
      if (label.startsWith(TAG_DR)) {
        return 2;
      }
      if (label.startsWith(TAG_B)) {
        return 3;
      }
      if (label.startsWith(TAG_S)) {
        return 4;
      }
    }
    return 0;
  }

  getTimescaleMarks(symbolInfo: SymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString): void {
    // Not supported
  }

  getBars(symbolInfo: SymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback): void {
    this.stocksInfoRestService.getTaChartBars(
      symbolInfo.tickerNo,
      periodParams.from.toString(),
      periodParams.to.toString(),
      resolution,
      periodParams.countBack.toString(),
      'false',
    ).subscribe((response) => {
      if (!response || !response.data || !response.data.date || !response.data.date.length) {
        onResult([], { noData: true });
        return;
      }
      let bars = [];
      for (let i = 0; i < response.data.date.length; i++) {
        bars.push({
          time: response.data.date[i] * 1000,
          open: response.data.open[i],
          high: response.data.high[i],
          low: response.data.low[i],
          close: response.data.close[i],
          volume: response.data.volume[i]
        });
      }
      if (bars.length > 0) {
        this.lastBarMap[symbolInfo.tickerNo + '_' + resolution] = bars[bars.length - 1];
        setStorage(LocalStorageConstant.STOCK_TA_CHART_LAST_UPDATE_DATE, response.data.date[response.data.date.length-1])
      }
      onResult(bars, { noData: false });
    });
  }

  subscribeBars(symbolInfo: SymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
    let isStreaming = false;
    let isLive = false;
    let exchange = symbolInfo && symbolInfo.exchange ? symbolInfo.exchange.toString(): '';
    let permission = this.lsMarketDataService.getPermissionInfo();
    let isHkexLiveStreaming = permission['HKEX'] && permission['HKEX'].streamL2;
    let cnExchanges = ['SZSE', 'SSE'];
    let usExchanges = ['NYSE', 'NASDAQ', 'AMEX', 'BATS'];
    let isUsLiveStreaming = permission[exchange] && permission[exchange].streamL1Type == 'L1';


    if(this.refno && exchange){
      // Set isStreaming
      if (resolution === '1D') {
          isStreaming = true;
      }
      else{
        isStreaming = (resolution === '1');
      }

      // Set isLive
      if (exchange === 'HKEX') {
        isLive = isHkexLiveStreaming;
      } else if (cnExchanges.includes(exchange)) {
        isLive = false;
      } else if (exchange === 'SGX') {
        isLive = true;
      } else if (usExchanges.includes(exchange)) {
        isLive = isUsLiveStreaming
      }
    }

    let updater;
    let taChartStatus;
    let subscription = new TaChartSubscription();
    subscription.symbolInfo = symbolInfo;
    subscription.resolution = resolution;
    subscription.onTick = onTick;
    subscription.listenerGuid = listenerGuid;
    subscription.onResetCacheNeededCallback = onResetCacheNeededCallback;
    if (isStreaming) {
      if (resolution === '1D') {
        updater = new StockTaChartUpdaterDailyStreaming(this.module, subscription, this.stockService, this.stocksInfoRestService, this.stockTransactionRestService, this.lsMarketDataService, isLive);
        taChartStatus = { statusType: (isLive ? 'LIVE' : 'DELAY') };
      } else {
        let lastBar = this.lastBarMap[symbolInfo.tickerNo + '_' + resolution];
        updater = new StockTaChartUpdaterIntradayStreaming(subscription, lastBar, isLive, this.lsMarketDataService);
        taChartStatus = { statusType: (isLive ? 'LIVE' : 'DELAY') };
      }
    } else {
      let lastBar = this.lastBarMap[symbolInfo.tickerNo + '_' + resolution];
      updater = new StockTaChartUpdaterOnInterval(subscription, lastBar, this.stockService, this.stocksInfoRestService, String(isLive));
      taChartStatus = { statusType: 'TIMER' };
    }
    // Only update status if it is the main stock
    if (taChartStatus && symbolInfo && symbolInfo.tickerNo && symbolInfo.tickerNo.toString() === this.tickerNo) {
      this.stockService.updateTaChartStatus(taChartStatus);
    }
    this.subscriptionMap[listenerGuid] = updater;
    this.resetMap[listenerGuid] = onResetCacheNeededCallback;
    updater.subscribeBars();
  }

  unsubscribeBars(listenerGuid: string): void {
    this.subscriptionMap[listenerGuid].unsubscribeBars();
    delete this.subscriptionMap[listenerGuid];
    delete this.resetMap[listenerGuid];
  }

  onContextMenu(items: IActionVariant[], resolve: (items: IActionVariant[]) => void): void {
    resolve(items);
  }

  onToolbarReady(chartWidget: IChartingLibraryWidget): void {
    // Marks
    chartWidget.createDropdown({
      title: this.translateService.instant('stock.tags'),
      tooltip: this.translateService.instant('stock.transaction.dividend.tags'),
      items: [],
      align: 'left'
    }).then((markOptionsApi) => {
      this.markOptionsApi = markOptionsApi;
      this.renderMarkOptions();
    });
  }

  private renderMarkOptions(): void {
    if (!this.markOptionsApi) {
      return;
    }
    let items = [{
      title: this.translateService.instant('stock.hide.all.tags'),
      onSelect: () => {
        for (let markType in this.markTypeToVisibilityMap) {
          this.markTypeToVisibilityMap[markType] = false;
        }
        this.chart.clearMarks();
        this.renderMarkOptions();
      }
    }, {
      title: this.translateService.instant('stock.show.all.tags'),
      onSelect: () => {
        for (let markType in this.markTypeToVisibilityMap) {
          this.markTypeToVisibilityMap[markType] = true;
        }
        this.chart.clearMarks();
        this.chart.refreshMarks();
        this.renderMarkOptions();
      }
    }];
    let markTypeItems = [];
    for (let markType in this.markTypeToVisibilityMap) {
      let title;
      if (markType === TAG_DA) {
        title = this.translateService.instant('stock.dividend.announcement.tags');
      } else if (markType.startsWith(TAG_DR)) {
        title = this.translateService.instant('stock.dividend.received.tags') + ' ' + markType.substring(TAG_DR.length);
      } else if (markType.startsWith(TAG_B)) {
        title = this.translateService.instant('stock.dividend.buy.transaction.tags') + ' ' + markType.substring(TAG_B.length);
      } else if (markType.startsWith(TAG_S)) {
        title = this.translateService.instant('stock.dividend.sell.transaction.tags') + ' ' + markType.substring(TAG_S.length);
      }
      if (this.markTypeToVisibilityMap[markType]) {
        title = '\u2611 ' + title;
      } else {
        title = '\u2610 ' + title;
      }
      markTypeItems.push({
        key: markType,
        title: title,
        onSelect: () => {
          this.markTypeToVisibilityMap[markType] = !this.markTypeToVisibilityMap[markType];
          this.chart.clearMarks();
          this.chart.refreshMarks();
          this.renderMarkOptions();
        }
      });
    }
    markTypeItems.sort((item1, item2) => {
      let seq1 = this.getLabelSeq(item1.key);
      let seq2 = this.getLabelSeq(item2.key);
      if (seq1 != seq2) {
        return seq1 - seq2;
      }
      return item1.key.localeCompare(item2.key);
    })
    this.markOptionsApi.applyOptions({
      items: items.concat(markTypeItems)
    });
  }

  private renderUnadjustedButton(): void {
    if (this.unadjusted) {
      this.unadjustedButton.textContent = this.translateService.instant('stock.unadjusted', { default: 'Unadjusted' });
      this.unadjustedButton.setAttribute('title', this.translateService.instant('stock.unadjusted.price', { default: 'Unadjusted Price' }));
    } else {
      this.unadjustedButton.textContent = this.translateService.instant('stock.adjusted', { default: 'Adjusted' });
      this.unadjustedButton.setAttribute('title', this.translateService.instant('stock.adjusted.price', { default: 'Adjusted Price' }));
    }
  }
}

const TAG_DA = 'DA'; // Dividend announcement
const TAG_DR = 'DR'; // Dividend received
const TAG_B = 'B'; // Buy
const TAG_S = 'S'; // Sell
declare type MarkType = 'NONE' | 'DIVIDEND' | 'TRANSACTION' | 'ALL';
