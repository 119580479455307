import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {sort, sortFiguresList} from '@util/sort';
import {Select} from '@ngxs/store';
import {GlobalState} from 'src/app/core/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-bond-factsheet-risk-disclosure',
  templateUrl: './bond-factsheet-risk-disclosure.component.html'

})

export class BondFactsheetRiskDisclosureComponent implements OnInit {

  ngOnInit(): void {
  }
  
  constructor() {
  }
}
 