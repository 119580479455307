import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IBondYieldCurveChartModel } from '@api-module/model/bond/i-bond-yield-curve-chart.model';
import * as Highcharts from "highcharts";
import { Chart } from "highcharts";
import { AppConstant, FundConstant, ImagePathConstant, RouteConstant } from 'src/app/constant';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { GlobalState } from 'src/app/core/store';
import { forkJoin, Observable } from 'rxjs';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { UtilService } from "@api-module/service/common/util.service";
import { gotoBuy, goToWatchList } from '@share/services/product-search.service';

@Component({
	selector: 'app-bond-selector-table',
	templateUrl: './bond-selector-table.component.html',
	providers: [UtilService]
})
export class BondSelectorTableComponent implements OnChanges {

	@Input() bondList: any[];
	bondDisplayList: any[] = [];
	searchValue: string = '';
	BUTTON_ICON_IMAGES_PATH = 'assets/images/general/icons/';
	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	GENERAL_ICON_PATH = 'assets/images/general/icons/';
	localeOption: string = "zh-TW";
	basePath: string = ImagePathConstant.FUND_HOUSE_IMG_PATH;
	icon: string = 'Add-to-Watchlist.svg';

	columnDisplayList = [
		{ label: this.translateService.instant("fsmone.bonds.category"), value: 'bondCategory', checked: true },
		{ label: this.translateService.instant("fsmone.currency"), value: 'bondCurrencyCode', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.credit.rating.snp.fitch"), value: 'creditRatingSnp', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.investment.objective"), value: 'bondSuitability', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.years.to.maturity.years"), value: 'maturity', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.annual.coupon"), value: 'couponRate', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.annual.coupon.frequency"), value: 'couponFrequency', checked: true },
		{ label: this.translateService.instant("fsmone.bid.price"), value: 'bidPrice', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.ask.price"), value: 'offerPrice', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.net.bid.yield"), value: 'fsmBidNytm', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.net.offer.yield"), value: 'fsmOfferNytm', checked: true },
		{ label: this.translateService.instant("fsmone.new.bonds.on.board.min.purchase.quantity"), value: 'minInitialAmt', checked: true },
		{ label: this.translateService.instant("fsmone.direct.bond.factsheet.factsheet.details.special.feature"), value: 'specialFeatures', checked: true },
		{ label: this.translateService.instant("fsmone.fund.recommended.add.to.watchlist"), value: 'watchlist', checked: true }
	];

	columnDisplayList2 = [
		{ label: this.translateService.instant("fsmone.currency"), value: 'bondCurrencyCode', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.investor.profile"), value: 'bondSuitability', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.performance.as.of"), value: 'priceDate', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.ytd.return.percent"), value: 'yearToDate', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.1.week.return.percent"), value: 'oneWeek', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.1.month.return.percent"), value: 'oneMonth', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.3.months.return.percent"), value: 'threeMonth', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.1.year.return.percent"), value: 'oneYear', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.3.years.return.percent"), value: 'threeYear', checked: true },
		{ label: this.translateService.instant("fsmone.bonds.since.inception.return.percent"), value: 'fiveYear', checked: true }
	];

	constructor(private cdRef: ChangeDetectorRef, private translateService: TranslateService,
		private globalDataStorage: GlobalDataStorage,
		private utilService: UtilService) { }


	ngOnChanges(changes: SimpleChanges): void {
		this.search();
		this.setLocale();
		if (changes.bondList) {
			for (let element of this.bondList) {
				element.bondCategory = element.bondCategory.toLowerCase().replace(/-|\s/g, ".");
			}
		}
	}

	sort(sort: { key: string; value: any }): void {
		this.bondDisplayList = this.utilService.sort(sort, this.bondDisplayList);
	}

	getIsHideColumn(columnName: string): boolean {
		let column = this.columnDisplayList.find(column => column.value === columnName);
		return column ? !column.checked : true;
	}

	getIsHideColumn2(columnName: string): boolean {
		let column = this.columnDisplayList2.find(column => column.value === columnName);
		return column ? !column.checked : true;
	}

	navigateToBondFactsheet(issueCode: string, bondName: string) {
		let route = RouteConstant.BONDS + '/' + RouteConstant.BOND_FACTSHEET + "/" + issueCode;
		window.open(route, '_blank', 'noreferrer');
	}

	getBondFactsheetURL(issueCode: string, bondName: string):string {
		return '/' + RouteConstant.BONDS + '/' + RouteConstant.BOND_FACTSHEET + "/" + issueCode;
	}

	search(): void {
		if (this.searchValue) {
			this.bondDisplayList = this.bondList.filter(
				(item: any) => item.bondName.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1 || item.bondIssuer.toUpperCase().indexOf(this.searchValue.toUpperCase()) !== -1);
		} else {
			this.bondDisplayList = this.bondList;
		}
	}

	addWatchList(code) {

	}

	clearInput() {
		this.searchValue = '';
		this.search();
	}

	getTranslateFeatures(featuresList) {
		var textArray = [];

		for (let element of featuresList) {
			var translateFeature = this.translateService.instant("fsmone.bonds.selector." + element);
			textArray.push(translateFeature);
		}
		return textArray.join()
	}


	setLocale() {
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}

	bondTransaction(issueCode, buySell){
		if(issueCode){
			if(buySell=='buy'){
				gotoBuy(issueCode, 'bond');
			}
		}
	}
	
	goToWatchList(){
		goToWatchList();
	}
	
}
