import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'negativeToBracket'})
export class NegativeToBracketPipe implements PipeTransform {
  public constructor(){}

  transform(value: any): any {
    if(parseFloat(value) < 0){
      return '(' + value.toString().slice(1)+ ')';
    } else {
      return value;
    }
  }

}
