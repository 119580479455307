import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NgClassType} from 'ng-zorro-antd';

@Component({
  selector: 'uic-fsmicon-wrapper',
  templateUrl: './uic-fsmicon-wrapper.component.html',
  styleUrls: ['./uic-fsmicon-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class UicFsmiconWrapperComponent {

  @Input() nzSize: 'default' | 'md' | 'lg' | 'xl' = 'lg';
  @Input() nzClass: NgClassType = '';
  constructor() { }

}
