<app-route-subheader [isBreadcrumbOnly]="true"></app-route-subheader>

<div class="container" *ngIf="!isLoading; else loadingTemplate">

    <nz-row nzType="flex" nzJustify="center" [nzGutter]="16">
        <nz-col nzLg="24" nzSm="24" nzXs="24">
            <div *ngIf="isEvent">
                <nz-affix [nzOffsetTop]="headerHeight" class="">
                    <nz-card class="action-bar-size justify-content-between">
								<span role="button">
									<a class="float-right mx-1" [href]="eventLink" target="_blank">
										<button nz-button
                                                nzType="theme">{{ eventStatus == 'concluded' ? ('fsmone.events.replay'|translate) : ('fsmone.event.join.now' | translate) }}</button>
									</a>
								</span>

                        <span role="button">
									<a class="float-right mx-1" [href]="addToCalendarUrl" target="_blank">
										<button nz-button [disabled]="eventStatus == 'concluded' " nzType="theme"> Add To Calendar </button>
									</a>
								</span>
                    </nz-card>
                </nz-affix>
            </div>
            <div id="article" class="article-view">
                <nz-row nzGutter="24">
                    <nz-col nzLg="18" nzMd="24" nzSm="24" nzXs="24">

                        <h5 class="text-black-6" [innerHtml]="articleDisplayModel.translatedCategory"></h5>
                        <h1 class="article-title text-xl" [innerHtml]="articleDisplayModel.articleTitle"></h1>

                        <h4 class="article-synopsis font-weight-bold" *ngIf="articleDisplayModel.articleSynopsis"
                            [innerHtml]="articleDisplayModel.articleSynopsis  | safeHtml"></h4>
                        <div >
                        	<div nz-col nzSpan="12" class="text-xs pt-3 pb-4">
								<b2c-user-profile [title]="articleDisplayModel.articleLogDate | hkDate:localeOption"
	                                              [horizontal]="horizontal"
	                                              [name]="articleDisplayModel.articleAuthor"
	                                              [image]="articleDisplayModel.articleAuthorImage">
	                            </b2c-user-profile>							
							</div>
							
      						<div nz-col nzSpan="12">
      							<div class="text-right pt-4 pb-4">
      								<ng-container >
			                        	<i *ngIf="auth" (click)="checkSavedArticle(articleDisplayModel.articleNo)" class="ml-2 text-lg align-middle fsmicon-bookmart-o" [ngClass]="{'fsmicon-bookmart-o': !articleSaved,'fsmicon-bookmart': articleSaved}"></i>			     								     						
		     							<i (click)="shareLink(articleDisplayModel.articleTitle)" nz-icon nzType="share-alt" nzTheme="outline" class="ml-3 text-lg align-middle"></i>
		     						</ng-container>
      							</div>								
							</div>                                                 
                        </div>

                        <div class="article-content">
                            <ng-container *ngIf="!isWebcast && articleDisplayModel.featuredImageName!=null">
                                <img class="w-100"
                                     [alt]="articleDisplayModel.articleTitle + ' | Open a FREE FSMOne account and manage all your investments conveniently in ONE place'"
                                     [src]="articleDisplayModel.featuredImageName"/>
                            </ng-container>

                            <ng-container *ngIf="isPdf">
                                <app-pdf-wrapper [url]="articleDisplayModel.articleHtml"
                                                 height="1000px"></app-pdf-wrapper>
                                <button class="mb-2" nz-button nzType="theme" nzSize="small"
                                        (click)="downloadForm(articleDisplayModel.articleHtml)">Download PDF
                                </button>
                            </ng-container>

                            <ng-container *ngIf="!isPdf && !isWebcast">
                                <div *ngIf="articleDisplayModel.articleHtml"
                                     [innerHtml]="articleDisplayModel.articleHtml | safeHtml"></div>
                            </ng-container>

                            <ng-container *ngIf="isWebcast">
                                <iframe class="w-100 " height="405" allow="autoplay; encrypted-media" allowfullscreen
                                        [src]="safeUrl? safeUrl : null"
                                        [title]="articleDisplayModel.articleTitle"></iframe>
                            </ng-container>
                        </div>

                        <div class="py-3" *ngIf="articleDisplayModel.articleTag?.length > 0">
                            <h5><span translate="fsmone.article.tags" translate-default="Tags"></span></h5>
                            <div class="d-inline-flex">
                                <nz-tag class="" (click)="findArticleWithTag(tag)"
                                        *ngFor="let tag of articleDisplayModel.articleTag">{{tag}}</nz-tag>
                            </div>
                        </div>

                        <div class="disclaimer">
                            <div *ngIf="articleDisplayModel.disclaimer">
                                <span [innerHtml]="articleDisplayModel.disclaimer"></span>
                            </div>

                            <div class="text-sm" *ngIf="!articleDisplayModel.disclaimer">
                                <h5><span translate="fsmone.imptinfo.personalinfo.5.5.3.1"
                                          translate-default="All materials and contents found in this Site are strictly for information purposes only and should not be considered as an investment advice or recommendation, an inducement of offer, or solicitation, to deal in any of the funds or products found in this Site by IFHK or any of its relevant third party providers. Any forecasts or projections or forward looking statements made whether by IFHK or any other party in this Site are not necessarily indicative of future or likely performance, future events or future financial performance of products, countries, markets or companies. These statements are only predictions and actual events or results may differ. While certain tools available on the Site may provide general investment or financial analysis based upon your personalized input, such results are not to be construed as our providing investment recommendations or advices. Please make your own assessment of the relevance, accuracy and adequacy of the information contained in this Site."></span>
                                </h5>
                            </div>
                        </div>
                    </nz-col>


                    <!-- related article sidebar -->
                    <nz-col nzLg="6" nzMd="24" nzSm="24" nzXs="24" class=" sticky-top scrollable-side-bar">
                        <a [href]="'/'+RouteConstant.MANAGED_PORTFOLIOS" target="_blank">
                        <div class="dpms-banner text-profit pointer p-4 mb-3"
                             [ngStyle]="{ 'background-image': 'url(' + ARTICLE_IMAGE_PATH + 'dpms-banner-bg.png)' }">
                            <span [innerHTML]="'struggling.to.understand.banner' | translate"></span>
                        </div>
                        </a>

                        <div *ngIf="articleDisplayModel.products && articleDisplayModel.products.length>0" class="bg-black-2 p-4 mb-3">
                            <h3 class="mb-3"><span translate="fsmone.products.in.this.article" translate-default="Our Products"></span></h3>
                            <div *ngFor="let prod of articleDisplayModel.products">
                                <a href="{{prod.url}}" [target]="'_blank'" style="text-decoration: none">
                                    <nz-card class="mb-2">
                                        <h2 *ngIf="prod.type!='DPMS' && prod.type!='Bonds'">{{prod.price | number: '1.3-3'}} {{prod.currency.toUpperCase()}}</h2>
                                        <ng-container *ngIf="prod.type=='Bonds' && prod.askYtm">
                                            <h2>{{prod.askYtm | number: '1.3-3'}}%</h2>
                                            <small translate="fsmone.bonds.ask.net.yield.to.maturity"
                                                   translate-default="Ask Net Yield to Maturity"></small>
                                        </ng-container>
                                        <h5 class="py-3" [innerHTML]="prod.name | safeHtml"></h5>
                                        <ng-container
                                                *ngTemplateOutlet="tags; context: { category: prod.type }">
                                        </ng-container>
                                    </nz-card>
                                </a>
                            </div>
                        </div>                        
                        
                        <div *ngIf="relatedArticleList && relatedArticleList.length>0" class="bg-black-2 p-4 mb-3">
                            <h3 class="mb-3" [innerHtml]="sideRelatedArticleHeader"></h3>
                            <div *ngIf="relatedArticleList && relatedArticleList.length>0">
                                <div *ngFor="let article of relatedArticleList">
                                    <a class="pointer text-black"
                                       [href]="formLastestArticleUrl(article?.articleno)"
                                       target="_blank">
                                        <h5 class="text-weight-bold">{{article.title}}</h5>
                                        <h6 class="pl-1">{{'fsmone.author.by' | translate}} {{article.author}}</h6>
                                        <h6><i class="px-1" nz-icon nzType="calendar"
                                               nzTheme="outline"> </i>{{ article.logDate | hkDate:localeOption }}
                                            <i class="px-1" nz-icon nzType="eye"
                                               nzTheme="outline"> </i> {{article.hits}}</h6>
                                    </a>
                                    <nz-divider class="my-3"></nz-divider>
                                </div>
                            </div>
                        </div>
                        
                    </nz-col>
                </nz-row>
            </div>

            <!-- next related article
            <nz-row>
                <div nz-col nzLg="18" nzMd="24" nzSm="24" nzXs="24" [ngClass]="{'in': isOpen}"
                     *ngIf="relatedArticle && relatedArticle.articleHtml">
                    <hr class="mt-5 mb-5">

                    <h5 class="text-black-6"
                        [innerHtml]="relatedArticle.translatedCategory"></h5>
                    <h1 class="article-title text-xl"
                        [innerHtml]="relatedArticle.articleTitle"></h1>

                    <h4 class="article-synopsis font-weight-bold"
                        *ngIf="relatedArticle.articleSynopsis"
                        [innerHtml]="relatedArticle.articleSynopsis  | safeHtml"></h4>
                    <div class="text-xs pt-3 pb-4">
                        <b2c-user-profile
                                [title]="relatedArticle.articleLogDate | hkDate:localeOption"
                                [horizontal]="horizontal" [name]="relatedArticle.articleAuthor"
                                [image]="relatedArticle.articleAuthorImage">
                        </b2c-user-profile>
                    </div>

                    <div *ngIf="!relatedArticleDisplay" class="pb-5">
                        <div class="article-content" style="max-height: 100px; max-width: 100%; overflow: hidden;">
                            <ng-container
                                    *ngIf="!isWebcast && relatedArticle.featuredImageName!=null">
                                <img class="w-100"
                                     [alt]="relatedArticle.articleTitle + ' | Open a FREE FSMOne account and manage all your investments conveniently in ONE place'"
                                     [src]="relatedArticle.featuredImageName"/></ng-container>

                            <ng-container *ngIf="isPdf2">
                                <app-pdf-wrapper [url]="relatedArticle.articleHtml" height="1000px"></app-pdf-wrapper>
                                <button class="mb-2" nz-button nzType="theme" nzSize="small"
                                        (click)="downloadForm(relatedArticle.articleHtml)">{{'click.here.to.download.'|translate}}</button>
                            </ng-container>

                            <ng-container *ngIf="!isPdf2 && !isWebcast2">
                                <div *ngIf="relatedArticle.articleHtml"
                                     [innerHtml]="relatedArticle.articleHtml | safeHtml"></div>
                            </ng-container>

                            <ng-container *ngIf="isWebcast2">
                                <iframe
                                        class="w-100 " height="405" allow="autoplay; encrypted-media"
                                        allowfullscreen [src]="safeUrl2? safeUrl2 : null"
                                        [title]="relatedArticle.articleTitle"></iframe>
                            </ng-container>
                        </div>

                        <div style="position: absolute; width: 100%; height: 105px; top: 410px; background: -webkit-linear-gradient(top,transparent 0,#fff 100%)"></div>
                        <div class="text-center">
                            <button nz-button nzType="outline"
                                    (click)="showFullRelatedArticle()">{{'fsmone.read.full.article'|translate}}</button>
                        </div>
                    </div>

                    <div *ngIf="relatedArticleDisplay">
                        <div class="article-content">
                            <ng-container
                                    *ngIf="!isWebcast && relatedArticle.featuredImageName!=null">
                                <img class="w-100"
                                     [alt]="relatedArticle.articleTitle + ' | Open a FREE FSMOne account and manage all your investments conveniently in ONE place'"
                                     [src]="relatedArticle.featuredImageName"/></ng-container>

                            <ng-container *ngIf="isPdf2">
                                <button class="mb-2" nz-button nzType="theme" nzSize="small"
                                        (click)="downloadForm(relatedArticle.articleHtml)">{{'click.here.to.download.'|translate}}</button>
                                <app-pdf-wrapper [url]="relatedArticle.articleHtml" height="1000px"></app-pdf-wrapper>
                            </ng-container>

                            <ng-container *ngIf="!isPdf2 && !isWebcast2">
                                <div *ngIf="relatedArticle.articleHtml"
                                     [innerHtml]="relatedArticle.articleHtml | safeHtml"></div>
                            </ng-container>

                            <ng-container *ngIf="isWebcast2">
                                <iframe
                                        class="w-100 " height="405" allow="autoplay; encrypted-media"
                                        allowfullscreen [src]="safeUrl2? safeUrl2 : null"
                                        [title]="relatedArticle.articleTitle"></iframe>
                            </ng-container>
                        </div>

                        <div class="py-3" *ngIf="relatedArticle.articleTag?.length > 0">
                            <h5>
                                <span translate="fsmone.article.tags" translate-default="Tags"></span>
                            </h5>
                            <div class="d-inline-flex">
                                <nz-tag *ngFor="let tag of relatedArticle.articleTag">{{tag}}</nz-tag>
                            </div>
                        </div>

                        <div>

                        </div>

                        <div class="disclaimer">
                            <div *ngIf="relatedArticle.disclaimer">
                                <span [innerHtml]="relatedArticle.disclaimer"></span>
                            </div>

                            <div class="text-sm" *ngIf="!relatedArticle.disclaimer">
                                <h5>
								<span translate="fsmone.imptinfo.personalinfo.5.5.3.1"
                                      translate-default="All materials and contents found in this Site are strictly for information purposes only and should not be considered as an investment advice or recommendation, an inducement of offer, or solicitation, to deal in any of the funds or products found in this Site by IFHK or any of its relevant third party providers. Any forecasts or projections or forward looking statements made whether by IFHK or any other party in this Site are not necessarily indicative of future or likely performance, future events or future financial performance of products, countries, markets or companies. These statements are only predictions and actual events or results may differ. While certain tools available on the Site may provide general investment or financial analysis based upon your personalized input, such results are not to be construed as our providing investment recommendations or advices. Please make your own assessment of the relevance, accuracy and adequacy of the information contained in this Site."></span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-row>-->
        </nz-col>

        <!-- <nz-row>
            <nz-col>
                <ui-share-buttons [vertical]="false"
                                  [roundedIcon]="false"
                                  [showIcon]="true"
                                  [showText]="true"
                                  [url]="urlShared"
                                  [title]="articleDisplayModel.articleTitle"
                                  class="pt-5" [include]="['facebook','linkedin','twitter','whatsapp']">
                </ui-share-buttons>
            </nz-col>
        </nz-row> -->


    </nz-row>

    <nz-row *ngIf="otherRelatedArticle && otherRelatedArticle.length>0">
        <article-rcms-container *ngIf="otherRelatedArticle && otherRelatedArticle.length>0"
                                [title]="('related.articles' | translate)" [columnSpan]="6"
                                [articleList]="otherRelatedArticle">
        </article-rcms-container>
    </nz-row>
</div>

<ng-template #loadingTemplate>
    <div class="container mt-5 mb-5">
        <nz-skeleton [nzActive]="true"></nz-skeleton>
    </div>
</ng-template>

<ng-template #eventActionBar let-event="event">
    <nz-affix class="d-block">
        <div class="w-100">
            <a class="float-right" [href]="eventLink" target="_blank">
                <button nz-button
                        nzType="theme">{{ eventStatus == 'concluded' ? ('fsmone.events.replay'|translate) : ('join.now' | translate) }}</button>
                <!-- <span role="button" class="pointer float-right" >{{ eventStatus == 'concluded' ? ('fsmone.events.replay'|translate) : ('join.now' | translate) }}</span>-->
            </a>
        </div>
    </nz-affix>
</ng-template>

<ng-template #tags let-category="category">
    <div *ngIf="category.toLowerCase()=='bonds'">
        <nz-tag class="b2c-product-card-tag product-type bg-bond"><span translate="fsmone.bonds"
                                                                        translate-default="Bond"></span></nz-tag>
    </div>
    <div *ngIf="category.toLowerCase()=='funds'">
        <nz-tag class="b2c-product-card-tag product-type bg-fund"><span translate="fsmone.general.fund"
                                                                        translate-default="Fund"></span></nz-tag>
    </div>
    <div *ngIf="category.toLowerCase()=='etfs'">
        <nz-tag class="b2c-product-card-tag product-type bg-stock"><span translate="etfs"
                                                                         translate-default="ETFs"></span></nz-tag>
    </div>
    <div *ngIf="category.toLowerCase()=='dpms'">
        <nz-tag class="b2c-product-card-tag product-type bg-etf"><span translate="fsmone.managed.portfolios"
                                                                       translate-default="Managed Portfolios"></span>
        </nz-tag>
    </div>
    <div *ngIf="category.toLowerCase()=='stock'">
        <nz-tag class="b2c-product-card-tag product-type bg-ndpms"><span translate="fsmone.stocks"
                                                                         translate-default="Stocks"></span></nz-tag>
    </div>
</ng-template>

<nz-modal
	[nzVisible]="shareVisibility"
 	[nzClosable]="true"
  	[nzTitle]="'share' | translate"
  	[nzWidth]="900"
  	[nzFooter]="null"
  	nzWrapClassName="vertical-center-modal"
  	[nzClosable]="true"
  	(nzOnCancel)="handleClose()">
 	<share-buttons-modal
		[isVisible]="shareVisibility"
		[url]="shareUrl"
		[description]="description"
		[title]="shareTitle"
		[include]="">
	</share-buttons-modal>
</nz-modal>
