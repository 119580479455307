import { Injectable } from "@angular/core";
import {IProductSearchModel} from '@api-module/model/product/i-product-search.model';
import {BehaviorSubject} from 'rxjs'; 

const KEY_STOCK = '_stockList';

@Injectable({
	providedIn: 'root'
})

export class ProductViewHistoryDataStorage {
	_stockList = new BehaviorSubject(this.stockList);
	
	public get stockList(): IProductSearchModel[] {
        var storageStockList = JSON.parse(localStorage.getItem(KEY_STOCK));
        return storageStockList;
    }
    public set stockList(stockInfoList: IProductSearchModel[]) {
        this._stockList.next(stockInfoList);
        localStorage.setItem(KEY_STOCK, JSON.stringify(this._stockList));
    }

	public addStock(stockInfo: IProductSearchModel){
		var proceedAdd = true;
		var storageStockList = this.stockList;
		
		if(storageStockList){
			for(let item of storageStockList){
				if(item.productCode === stockInfo.productCode && item.exchange === stockInfo.exchange){
					proceedAdd = false;
				}
			}
			
			if(proceedAdd && storageStockList.length >=4){
				storageStockList.splice(0,1);
			}
		} else {
			storageStockList = [] as IProductSearchModel[];
		}
		
		if(proceedAdd){
			storageStockList.push(stockInfo);
			this._stockList.next(storageStockList);
			
	        localStorage.setItem(KEY_STOCK, JSON.stringify(storageStockList));
		}
	}
	
	public clearStorage() {
		localStorage.removeItem(KEY_STOCK);
	}
}