import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
//import {BondSelectorService} from '../../service/bond-selector.service';
import { IDropdownModel } from '@api-module/model/common/i-dropdown.model';
import { tap } from 'rxjs/operators';
import { BondService } from '@api-module/rest/bond.rest.service';
import { IBondChartCenterFilterOptionModel } from '@api-module/model/bond/i-bond-chart-centre.model';
import { AppConstant } from '../../../../../constant';
import { TranslateService } from '@ngx-translate/core';
import { HeaderStorageService } from '@share/services/header/header-storage.service';
import { Subscription } from 'rxjs';

@Component({
	selector: ' app-bond-chart-centre-selector',
	templateUrl: './bond-chart-centre-selector.component.html',
	providers: [SubHandlingService],
})
export class BondChartCentreSelectorComponent implements OnInit, OnChanges {
	@Input() totalBondSelected: any[] = [];
	@Output() selectedBond = new EventEmitter<{ bondName: string, issueCode: string, type: string }>();
	@Input() allBondOptions: any[] = [];
	@Output() clearAllBonds = new EventEmitter<{ clear: boolean }>();
	@Output() selectedMainOption = new EventEmitter<string>();

	chartForm: FormGroup;
	mainOptions: IDropdownModel[] = [] as IDropdownModel[];
	secondOptions: IDropdownModel[] = [] as IDropdownModel[];
	bondOptions: IDropdownModel[] = [] as IDropdownModel[];
	bondFilterOption: IBondChartCenterFilterOptionModel;

	loading = false;
	showExtraOptions = false;
	isLoading = false;
	mobileScrolled = false;
	labelList: string;
	secondValue: string;
	secondList: IDropdownModel[] = [] as IDropdownModel[];
	mainFilterList = [
		{ key: "ALL", value: "fsmone.bond.chart.centre.all.bonds", defaultDisplay: "All Bonds" },
		{ key: "BondMarketData", value: "fsmone.bond.chart.centre.index.data", defaultDisplay: "Bond Market Data" },
		{ key: "BondIssuer", value: "fsmone.bonds.bond.issuer", defaultDisplay: "Bond Issuer" },
		{ key: "BondSector", value: "fsmone.bonds.bond.sector", defaultDisplay: "Bond Sector" },
		{ key: "BondSubSector", value: "fsmone.bond.chart.centre.bond.sector.sub", defaultDisplay: "Bond Sub Sector" },
		{ key: "BondType", value: "fsmone.bonds.type", defaultDisplay: "Bond Type" },
		{ key: "BondCurrency", value: "fsmone.bonds.bond.currency", defaultDisplay: "Bond Currency" },
		{ key: "CouponFrequency", value: "fsmone.bonds.coupon.frequency", defaultDisplay: "Coupon Frequency" },
		{ key: "ExchangeListed", value: "fsmone.bonds.exchange.listed", defaultDisplay: "Exchange Listed" },
		{ key: "CreditRating", value: "fsmone.bonds.credit.rating", defaultDisplay: "Credit Rating" },
		{ key: "GeographicalSector", value: "fsmone.bond.chart.centre.geographical.sectors", defaultDisplay: "Geographical Sectors" }
	];
	
	showFundDrawer = false;
	showMainDrawer = false;
	showSecondDrawer = false;
	bondValue: string;
	bondList: IDropdownModel[] = [] as IDropdownModel[];
	isBondChartCenterInitLoading = false;
	indexBond: any[] = [];

	showIssuer = false;
	showSector = false;
	showSubSector = false;
	showType = false;
	showCurrency = false;
	showFrequency = false;
	showExchange = false;
	showCredit = false;
	showGeo = false;
	
	selectedMainFilterList = ['ALL'];
	selectedIssuer = [];
	selectedSector = [];
	selectedSubSector = [];
	selectedType = [];
	selectedCurrency = [];
	selectedFrequency = [];
	selectedExchange = [];
	selectedCredit = [];
	selectedGeo = [];

	bondSelectorModel : any;
    headerSubscription: Subscription;
    headerHeight = 0;	

	constructor(
		//private bondSelectorService: BondSelectorService,
		private bondService: BondService,
		private fb: FormBuilder,
		private subHandlingService: SubHandlingService,
		private translate:TranslateService,
		private headerStorageService: HeaderStorageService) {
		this.chartForm = this.fb.group({
			mainFilter: [null, null],
			issuer: [null, null],
			sector: [null, null],
			subSector: [null, null],
			type: [null, null],
			currency: [null, null],
			frequency: [null, null],
			exchange: [null, null],
			credit: [null, null],
			geo: [null, null],
			bond: [null, [Validators.required]],
		}); 
		this.headerSubscription = this.headerStorageService.headerHeight.subscribe(height => {
            this.headerHeight = height;
        });
	}

	ngOnInit(): void {
		this.initBondChartCenterSelector();
		this.filterBondList();
	}

	ngOnDestroy(): void {
		this.headerSubscription?.unsubscribe();
	}

	initBondChartCenterSelector() {
		this.isBondChartCenterInitLoading = true;
		
		this.bondService.initBondSelector().subscribe((initRes: any) =>{
			if (AppConstant.RESPONSE_SUCCESS === initRes.status) {
				if(initRes.data){
					this.bondSelectorModel = initRes.data;
					
					for(const [i, freqItem] of this.bondSelectorModel.couponFrequencyModel.entries()){
						if(freqItem.code == 12){
							this.bondSelectorModel.couponFrequencyModel.splice(i, 1);
						}
					}
				}
			}
		});

		this.subscribeFormSelect();
	}

	subscribeFormSelect(): void {
		this.subHandlingService.subscribe(
			this.mainFilter.valueChanges.pipe(
				tap(value => {
					this.selectedMainFilterList = value;

					this.showIssuer = false;
					this.showSector = false;
					this.showSubSector = false;
					this.showType = false;
					this.showCurrency = false;
					this.showFrequency = false;
					this.showExchange = false;
					this.showCredit = false;
					this.showGeo = false;

					for (let item of this.selectedMainFilterList) {
						if (item === 'BondIssuer') {
							this.showIssuer = true;
						}
						if (item === 'BondSector') {
							this.showSector = true;
						}
						if (item === 'BondSubSector') {
							this.showSubSector = true;
						}
						if (item === 'BondType') {
							this.showType = true;
						}
						if (item === 'BondCurrency') {
							this.showCurrency = true;
						}
						if (item === 'CouponFrequency') {
							this.showFrequency = true;
						}
						if (item === 'ExchangeListed') {
							this.showExchange = true;
						}
						if (item === 'CreditRating') {
							this.showCredit = true;
						}
						if (item === 'GeographicalSector') {
							this.showGeo = true;
						}
					}
				})
			)
		);

		let label: string;
		let selectedType: string;
		this.subHandlingService.subscribe(
			this.bond.valueChanges.pipe(
				tap(value => {
					for (const item of this.allBondOptions) {
						if (item.issueCode === value) {
							label = item.bondName;
							selectedType = item.type;
						}
					}

					if (this.bond.value !== null) {
						console.log("emitting");
						this.selectedBond.emit({
							bondName: label,
							issueCode: value,
							type: selectedType
						});
					}
				})
			)
		);
	}

	filterBondList() {
		if(this.selectedMainFilterList.includes('BondMarketData')){
			if(this.selectedMainFilterList.length==1 || (this.selectedMainFilterList.length==2 && this.selectedMainFilterList.indexOf('ALL')>-1)){
				this.isLoading = true;
				this.bondService.getBondMarketList().subscribe((marketRes: any) =>{
					var temp = marketRes.data;
					temp.sort((a, b) => (a.bondName > b.bondName) ? 1 : ((b.bondName > a.bondName) ? -1 : 0));
					
					this.bondOptions = [];
					for (const data of temp) {
						this.bondOptions.push({label: data.bondName, value: data.issueCode});
					}
					this.isLoading = false;
				});
			} else {
				this.bondOptions = [];
			}
		} else if(this.selectedMainFilterList.includes('ALL') && this.selectedMainFilterList.length==1){
			this.bondOptions = this.allBondOptions.map(value => {
				return {
					label: value.bondName,
					value: value.issueCode
				};
			});
		} else {
			var bondSelectorInput = {
				bondIssuers : [],
				bondSector : [],
				bondSectorSub : [],
				bondType : [],
				bondCurrency : [],
				couponFrequency : [],
				bondExchangeListed : [],
				bondCreditRating : [],
				areaSectorCode : [],
			};
			
			if(this.selectedMainFilterList.includes('BondIssuer')){
				if(this.issuer.value){
					var value = this.issuer.value;
					bondSelectorInput.bondIssuers.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('BondSector')){
				if(this.sector.value){
					var value = this.sector.value;
					bondSelectorInput.bondSector.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('BondSubSector')){
				if(this.subSector.value){
					var value = this.subSector.value;
					bondSelectorInput.bondSectorSub.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('BondType')){
				if(this.type.value){
					var value = this.type.value;
					bondSelectorInput.bondType.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('BondCurrency')){
				if(this.currency.value){
					var value = this.currency.value;
					bondSelectorInput.bondCurrency.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('CouponFrequency')){
				if(this.frequency.value){
					var value = this.frequency.value;
					bondSelectorInput.couponFrequency.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('ExchangeListed')){
				if(this.exchange.value){
					var value = this.exchange.value;
					bondSelectorInput.bondCreditRating.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('CreditRating')){
				if(this.credit.value){
					var value = this.credit.value;
					bondSelectorInput.bondCreditRating.push(value);
				}
			}
			if(this.selectedMainFilterList.includes('GeographicalSector')){
				if(this.geo.value){
					var value = this.geo.value;
					bondSelectorInput.areaSectorCode.push(value);
				}
			}
			
			this.bondService.bondChartSelectorQuery(bondSelectorInput).subscribe((queryRes:any) => {
				if (AppConstant.RESPONSE_SUCCESS === queryRes.status) {
					if(queryRes.data){
						var temp = queryRes.data;
						temp.sort((a, b) => (a.bondName > b.bondName) ? 1 : ((b.bondName > a.bondName) ? -1 : 0));
						
						this.bondOptions = [];
						for (const data of temp) {
							this.bondOptions.push({label: data.productName, value: data.productCode});
						}
					}
				}
			});
		}
	}

	findBondMarketTypeList() {
		this.isLoading = true;
	}

	getFilteredBondList(category: string, secondCategory: string): void {
		this.isLoading = true;
		if (category === 'ALL') {
			this.bondOptions = this.allBondOptions.map(value => {
				return {
					label: value.bondName,
					value: value.issueCode
				};
			});
		} else {
			const filterBondList = this.allBondOptions.filter(bond => bond[category] === secondCategory);
			this.bondOptions = filterBondList.map(value => {
				return {
					label: value.bondName,
					value: value.issueCode,
				};
			})
				.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
		}
		if (category === 'indexData') {
			this.selectedMainOption.emit('MKT');
		}
		this.isLoading = false;
	}

	onClickClear(): void {
		this.bond.setValue(null);
		this.clearAllBonds.emit({ clear: true });
	}

	get mainFilter(): AbstractControl {
		return this.chartForm.get('mainFilter');
	}
	
	get issuer(): AbstractControl {
		return this.chartForm.get('issuer');
	}
	
	get sector(): AbstractControl {
		return this.chartForm.get('sector');
	}
	
	get subSector(): AbstractControl {
		return this.chartForm.get('subSector');
	}
	
	get type(): AbstractControl {
		return this.chartForm.get('type');
	}
	
	get currency(): AbstractControl {
		return this.chartForm.get('currency');
	}
	
	get frequency(): AbstractControl {
		return this.chartForm.get('frequency');
	}
	
	get exchange(): AbstractControl {
		return this.chartForm.get('exchange');
	}
	
	get credit(): AbstractControl {
		return this.chartForm.get('credit');
	}
	
	get geo(): AbstractControl {
		return this.chartForm.get('geo');
	}

	get bond(): AbstractControl {
		return this.chartForm.get('bond');
	}

	onScroll(value: boolean) {
		this.mobileScrolled = value;
	}

	openBondDrawer(): void {
		this.bondValue = this.chartForm.get('bond').value;
		this.bondList = this.bondOptions;
		this.showFundDrawer = true;
	}

	onViewChange(value: boolean) {
		this.showMainDrawer = value;
		this.showFundDrawer = value;
		this.showSecondDrawer = value;
	}

	onClassChange(value: string) {
		this.bondValue = value;
		for (const data of value) {
			this.bond.setValue(data);
		}
		return;
	}

	ngOnChanges(changes: SimpleChanges): void {
		//    this.initDefaultOptions();
		this.initBondChartCenterSelector();
		this.getFilteredBondList('ALL', null);
	}
	
	translateBondItem(item, prefix){
		var name = '';
		if(prefix =='fsmone.bonds.subsector.'){
			name = this.translate.instant(prefix + item.code.toLowerCase().replace(/_/g,'.'));
		} else if(prefix  == 'fsmone.bonds.coupon.frequency.'){
			name = this.translate.instant(prefix + item.frequencyName.toLowerCase().replace(/-|\s/g,"."));
		} else if(prefix  == 'fsmone.bonds.exchange.listed.'){
			name = this.translate.instant(prefix + item.code.toLowerCase());
		} else if(prefix == 'fsmone.bonds.direct.bond.all.currency.'){
			name = this.translate.instant(item.code.toLowerCase());
		} else if(prefix == 'fsmone.bond.credit.rating.'){
			name = this.translate.instant(prefix + item.key.toLowerCase().replace(/\s/g,'.').replace(/-/g,'.').replace(/_/g,'.'));
		} else if(prefix == 'bond.'){
			name = this.translate.instant(prefix + item.text.toLowerCase());
		} else{
			name = this.translate.instant(prefix + item.code.toLowerCase());
		}
		return name;
	}

}
