<nz-tabset [nzAnimated]="false">
	<nz-tab nzTitle="{{'fsmone.bonds.bond.info' | translate}}">
    <note>
      <h6 innerHTML="{{'fsmone.bonds.performance.table.note'|translate}}"></h6>
    </note>
    <app-web-scaffold>
        <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-3">
          <nz-col nzSpan="16">
            <nz-row nzAlign="middle" nzJustify="left" nzGutter="20" nzType="flex">
              <nz-col nzSpan="12">
                <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
                  <input type="text" nz-input placeholder="{{'fsmone.search' | translate}}" [(ngModel)]="searchValue" (ngModelChange)="search()"/>
                </nz-input-group>
              </nz-col>
              <nz-col nzSpan="12">
                <label calss="text-xs"><span translate="fsmone.total.bonds" translate-default="Total Bonds:"></span> {{this.bondDisplayList?.length}}</label>
              </nz-col>
            </nz-row>
          </nz-col>
          <nz-col [ngClass]="{'text-right' : isMobile$ | async}">
            <label class="text-primary"><a nz-popover nzTrigger="click" nzPopoverPlacement="bottom" [nzPopoverContent]="columnFilterContent" >
              <b class="align-middle text-primary"><img class="mr-1" [src]="GENERAL_ICON_PATH + 'table_filter.svg'" alt="filter"/>{{'column.visibility'|translate}}</b>
            </a></label>
          </nz-col>
        </nz-row>
    </app-web-scaffold>

    <app-mobile-scaffold>
        <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-3">
          <nz-col nzSpan="24">
            <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
              <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue" (ngModelChange)="search()"/>
            </nz-input-group>
          </nz-col>
          <nz-col nzSpan="12" class="pt-3">
            <label calss="text-xs"><span translate="fsmone.total.bonds" translate-default="Total Bonds:"></span> {{this.bondDisplayList?.length}}</label>
          </nz-col>
          <nz-col nzSpan="12" class="pt-3 text-right">
            <label class="text-primary"><a nz-popover nzTrigger="click" nzPopoverPlacement="bottom" [nzPopoverContent]="columnFilterContent" >
              <b class="align-middle text-primary"><img class="mr-1" [src]="GENERAL_ICON_PATH + 'table_filter.svg'" alt="filter" />{{'column.visibility'|translate}}</b>
            </a></label>
          </nz-col>
        </nz-row>
    </app-mobile-scaffold>

    <nz-table #bondTable 
      [nzData]="bondDisplayList" 
      nzShowSizeChanger nzShowQuickJumper 
      [nzPageSize]="10"
      [nzTotal]="this.bondList?.length" [nzShowTotal]="rangeTemplate"
      [nzScroll]="{ x: '100%' }">
      <thead (nzSortChange)="sort($event)">
        <tr>
          <th nzLeft="0" nzShowSort nzSortKey="bondIssuer" [ngClass]="{'shadow': (isMobile$ | async)}" style="min-width: 200px;">
            <span translate="fsmone.issuer" translate-default="Issuer"></span>
          </th>
          <ng-container *ngFor="let column of columnDisplayList; let i = index">
            <th [hidden]="!column.checked" nzShowSort [nzSortKey]="column.value" class="text-center">{{column.label}}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of bondTable.data; let i = index">
          <td nzLeft="0" [ngClass]="{'shadow': (isMobile$ | async)}">
            <span class="font-size-md"><a [href]="getBondFactsheetURL(item.issueCode,item.bondName)" target="_blank" [innerHTML]= "item.bondName"></a></span><br>
            <span class=" mr-1" [innerHTML] ="item.bondIssuer"></span>
          </td>
          <td [hidden]="getIsHideColumn('bondCategory')" class="text-center">
            {{'fsmone.bonds.bond.type.'+item.bondCategory | lowercase | translate}}
          </td>
          <td [hidden]="getIsHideColumn('bondCurrencyCode')" class="text-center">
        {{item.bondCurrencyCode | lowercase | translate}}
          </td>
          <td class="text-center" [hidden]="getIsHideColumn('creditRatingSnp')" class="text-center">
            <span *ngIf="item.creditRatingSnp" [innerHTML] = "item.creditRatingSnp"></span><span *ngIf="!item.creditRatingSnp">-</span><span>/</span><span *ngIf="item.creditRatingFitch" [innerHTML] = "item.creditRatingFitch"></span><span *ngIf="!item.creditRatingFitch">-</span>      	
          </td>
          <td class="text-center" [hidden]="getIsHideColumn('bondSuitability')" class="text-center">
        {{'fsmone.bonds.bond.suitability.'+ item.bondSuitability | lowercase | translate}}
        
          </td>
          <td class="text-center" [hidden]="getIsHideColumn('maturity')" class="text-center">
            <ng-container *ngIf="item.maturity == 'Perpetual'"><span translate="fsmone.bonds.selector.perpetual" translate-default="Perpetual"></span></ng-container>
            <ng-container *ngIf="item.maturity != 'Perpetual'">{{item.maturity}}</ng-container>
          </td>
          <td class="text-center" [hidden]="getIsHideColumn('couponRate')">
            {{item.couponRate}}
          </td>
          <td [hidden]="getIsHideColumn('couponFrequency')" class="text-center">
            <div *ngIf="item.couponFrequency=='1'">{{'fsmone.annually' | translate}}</div>
            <div *ngIf="item.couponFrequency=='2'">{{'fsmone.biannually' | translate}}</div>
            <div *ngIf="item.couponFrequency=='4'">{{'fsmone.quarterly' | translate}}</div>
            <div *ngIf="item.couponFrequency=='12'">{{'fsmone.monthly' | translate}}</div>
          </td>
          <td [hidden]="getIsHideColumn('bidPrice')" class="text-center">
            {{item.bidPrice | number: '1.3-3'}}
          </td>
          <td [hidden]="getIsHideColumn('offerPrice')" class="text-center">
            {{item.offerPrice | number: '1.3-3'}}
          </td>
          <td [hidden]="getIsHideColumn('fsmBidNytm')" class="text-center">
            <div *ngIf="item.fsmBidNytm">
              {{item.fsmBidNytm | number: '1.3-3'}}
            </div>
            <div *ngIf="!item.fsmBidNytm">
              -
            </div>
          </td>
          <td [hidden]="getIsHideColumn('fsmOfferNytm')" class="text-center">
            <div *ngIf="item.fsmOfferNytm">
              {{item.fsmOfferNytm | number: '1.3-3'}}
            </div>
            <div *ngIf="!item.fsmOfferNytm">
              -
            </div>
          </td>
          <td [hidden]="getIsHideColumn('minInitialAmt')" class="text-center">
            {{item.minInitialAmt |number }}
          </td>
          <td [hidden]="getIsHideColumn('specialFeatures')" class="text-center">
          <div *ngIf="item.specialFeatures.length > 0">
            <span translate="fsmone.events.yes" translate-default="Yes"  nz-tooltip [nzTooltipTitle]="getTranslateFeatures(item.specialFeatures)"><i nz-icon class="text-info px-2" nzType="info-circle" nzTheme="fill"> </i></span>
          </div>
          <div *ngIf="item.specialFeatures.length == 0">
            <span translate="fsmone.events.no" translate-default="No"></span>
          </div>
          </td>
          <td [hidden]="getIsHideColumn('watchlist')" class="text-center">
          <app-buy-watchlist-button product="bond" [buyEnabled]="true" [watchlistEnabled]="true" [productId]="item.issueCode">
						</app-buy-watchlist-button>
<!--           <img [src]="BUTTON_ICON_IMAGES_PATH + 'buy-icon.svg'" nz-tooltip [nzTitle]="('fsmone.buy' | translate)" alt="buy" class="btn-buy pointer" (click)="bondTransaction(item.issueCode, 'buy')"/>&nbsp;&nbsp; -->
<!--           <img class="pointer" [src]="basePath + icon" (click)="goToWatchList()"/> -->
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>

  <nz-tab nzTitle="{{'bond.performance' | translate}}">
    <note>
      <h6 innerHTML="{{'fsmone.bonds.performance.table.note'|translate}}"></h6>
    </note>
    <app-web-scaffold>
      <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-3">
        <nz-col nzSpan="16">
          <nz-row nzAlign="middle" nzJustify="left" nzGutter="20" nzType="flex">
            <nz-col nzSpan="12">
              <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
                <input type="text" nz-input placeholder="{{'fsmone.search' | translate}}" [(ngModel)]="searchValue" (ngModelChange)="search()"/>
              </nz-input-group>
            </nz-col>
            <nz-col nzSpan="12">
              <label calss="text-xs"><span translate="fsmone.total.bonds" translate-default="Total Bonds:"></span> {{this.bondDisplayList?.length}}</label>
            </nz-col>
          </nz-row>
        </nz-col>
        <nz-col [ngClass]="{'text-right' : isMobile$ | async}"> 
          <label class="text-primary"><a nz-popover nzTrigger="click" nzPopoverPlacement="bottom" [nzPopoverContent]="columnFilterContent2" >
            <b class="align-middle text-primary"><img class="mr-1" [src]="GENERAL_ICON_PATH + 'table_filter.svg'" alt="filter" />{{'column.visibility'|translate}}</b>
          </a></label>
        </nz-col>
      </nz-row>
    </app-web-scaffold>

    <app-mobile-scaffold>
        <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-3">
          <nz-col nzSpan="24">
            <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
              <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue" (ngModelChange)="search()"/>
            </nz-input-group>
          </nz-col>
          <nz-col nzSpan="12" class="pt-3">
            <label calss="text-xs"><span translate="fsmone.total.bonds" translate-default="Total Bonds:"></span> {{this.bondDisplayList?.length}}</label>
          </nz-col>
          <nz-col nzSpan="12" class="pt-3 text-right">
            <label class="text-primary"><a nz-popover nzTrigger="click" nzPopoverPlacement="bottom" [nzPopoverContent]="columnFilterContent" >
              <b class="align-middle text-primary"><img class="mr-1" [src]="GENERAL_ICON_PATH + 'table_filter.svg'" alt="filter" />{{'column.visibility'|translate}}</b>
            </a></label>
          </nz-col>
        </nz-row>
    </app-mobile-scaffold>

    <nz-table #bondTable2 
      [nzData]="bondDisplayList" 
      nzShowSizeChanger nzShowQuickJumper 
      [nzPageSize]="10"
      [nzTotal]="this.bondList?.length" [nzShowTotal]="rangeTemplate"
      [nzScroll]="{ x: '100%' }">
      <thead (nzSortChange)="sort($event)">
          <tr>
            <th nzLeft="0" nzShowSort nzSortKey="bondIssuer" [ngClass]="{'shadow': (isMobile$ | async)}" style="min-width: 200px;">
              <span translate="fsmone.bonds.bond.name" translate-default="Bond Name"></span>
            </th>
            <ng-container *ngFor="let column of columnDisplayList2; let i = index">
              <th [hidden]="!column.checked" nzShowSort [nzSortKey]="column.value" class="text-center">{{column.label}}</th>
            </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of bondTable2.data; let i = index">
          <td nzLeft="0" [ngClass]="{'shadow': (isMobile$ | async)}">
            <span class="font-size-md"><a [href]="getBondFactsheetURL(item.issueCode,item.bondName)" target="_blank" [innerHTML]= "item.bondName"></a></span><br>
            <span class=" mr-1" [innerHTML] ="item.bondIssuer"></span>
          </td>
          <td [hidden]="getIsHideColumn2('bondCurrencyCode')" class="text-center">
            {{item.bondCurrencyCode | lowercase | translate}}
          </td>
          <td [hidden]="getIsHideColumn2('bondSuitability')" class="text-center">
        {{'fsmone.bonds.bond.suitability.'+ item.bondSuitability | lowercase | translate}}
          </td>
          <td class="text-center" [hidden]="getIsHideColumn2('priceDate')" class="text-center">
          {{item.priceDate | hkDate:localeOption }}
          </td>
          <td class="text-center" [hidden]="getIsHideColumn2('yearToDate')" class="text-center">
            <div *ngIf="item.yearToDate && item.yearToDate!=999999">
              <div *ngIf="item.yearToDate>0" class="text-profit">
                {{item.yearToDate | number: '1.3-3'}}
              </div>
              <div *ngIf="item.yearToDate<0" class="text-loss">
                {{item.yearToDate | number: '1.3-3'}}
              </div>
            </div>
            <div *ngIf="!item.yearToDate || item.yearToDate==999999">
            -
            </div>
          </td>
          <td class="text-center" [hidden]="getIsHideColumn2('oneWeek')" class="text-center">
          <div *ngIf="item.oneWeek && item.oneWeek!=999999">
          <div *ngIf="item.oneWeek>0" class="text-profit">
              {{item.oneWeek | number: '1.3-3'}}
          </div>
            <div *ngIf="item.oneWeek<0" class="text-loss">
              {{item.oneWeek | number: '1.3-3'}}
            </div>
          </div>
            <div *ngIf="!item.oneWeek || item.oneWeek==999999">
            -
            </div>
          </td>
          <td class="text-center" [hidden]="getIsHideColumn2('oneMonth')" class="text-center">
        <div *ngIf="item.oneMonth && item.oneMonth!=999999">
            <div *ngIf="item.oneMonth >0" class="text-profit">
              {{item.oneMonth  | number: '1.3-3'}}
          </div>
            <div *ngIf="item.oneMonth <0" class="text-loss">
              {{item.oneMonth  | number: '1.3-3'}}
            </div>
          </div>
            <div *ngIf="!item.oneMonth || item.oneMonth==999999">
            -
            </div>
          </td>
          <td class="text-center" [hidden]="getIsHideColumn2('threeMonth')" class="text-center">
            <div *ngIf="item.threeMonth && item.threeMonth!=999999">
            <div *ngIf="item.threeMonth >0" class="text-profit">
              {{item.threeMonth  | number: '1.3-3'}}
          </div>
            <div *ngIf="item.threeMonth <0" class="text-loss">
              {{item.threeMonth  | number: '1.3-3'}}
            </div>
          </div>
            <div *ngIf="!item.threeMonth || item.threeMonth==999999">
            -
            </div>
          </td>
          <td [hidden]="getIsHideColumn2('oneYear')" class="text-center">
              <div *ngIf="item.oneYear && item.oneYear!=999999">
            <div *ngIf="item.oneYear >0" class="text-profit">
              {{item.oneYear  | number: '1.3-3'}}
          </div>
            <div *ngIf="item.oneYear <0" class="text-loss">
              {{item.oneYear  | number: '1.3-3'}}
            </div>
          </div>
            <div *ngIf="!item.oneYear || item.oneYear==999999">
            -
            </div>
          </td>
          <td [hidden]="getIsHideColumn2('threeYear')" class="text-center">
            <div *ngIf="item.threeYear && item.threeYear!=999999">
            <div *ngIf="item.threeYear >0" class="text-profit">
              {{item.threeYear  | number: '1.3-3'}}
          </div>
            <div *ngIf="item.threeYear <0" class="text-loss">
              {{item.threeYear  | number: '1.3-3'}}
            </div>
          </div>
            <div *ngIf="!item.threeYear || item.threeYear==999999">
            -
            </div>
          </td>
          <td [hidden]="getIsHideColumn2('fiveYear')" class="text-center">
              <div *ngIf="item.fiveYear && item.fiveYear!=999999">
            <div *ngIf="item.fiveYear >0" class="text-profit">
              {{item.fiveYear  | number: '1.3-3'}}
          </div>
            <div *ngIf="item.fiveYear <0" class="text-loss">
              {{item.fiveYear  | number: '1.3-3'}}
            </div>
          </div>
            <div *ngIf="!item.fiveYear || item.fiveYear==999999">
            -
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
	</nz-tab>
</nz-tabset>

<ng-template #rangeTemplate let-range="range" let-total> <span translate="show" translate-default="show"></span> {{ range[0] }}-{{ range[1] }} , <span translate="fsmone.fsm.smart.total" translate-default="Total"></span> {{ total }} <span translate="fsmone.results" translate-default="Results"></span></ng-template>

<ng-template #prefixIconSearch>
  <i nz-icon nzType="search"></i>
</ng-template>

<ng-template #suffixIconClose>
    <i class="input-clear" (click)="clearInput()" nz-icon nzType="close" nzTheme="outline" role="button"></i>
</ng-template>

<ng-template #columnFilterContent>
  <div class="py-3 filter-content overflow-y-auto">
    <div *ngFor="let item of columnDisplayList; let i=index">
      <label class="d-flex mb-2" nz-checkbox [(ngModel)]="item.checked">{{item.label}}</label>
    </div>
  </div>
</ng-template>

<ng-template #columnFilterContent2>
  <div class="py-3 filter-content overflow-y-auto">
    <div *ngFor="let item of columnDisplayList2; let i=index">
      <label class="d-flex mb-2" nz-checkbox [(ngModel)]="item.checked">{{item.label}}</label>
    </div>
  </div>
</ng-template>
  
<ng-template #HtmlContent>
  <span innerHTML="{{'contact.us.stock.phone.dealing.details.including.us.stock' |translate}}"></span>
</ng-template>
  