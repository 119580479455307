import {Injectable} from "@angular/core";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AesEncryptionService {

  aesEncrypt(token: string, key: string): string {
    // Convert string key into bytes
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    // Generate random bytes for IV
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(token, keyBytes, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    const ivStr = CryptoJS.enc.Base64.stringify(iv);
    const encryptedStr = encrypted.toString();
    // Concatenate IV and ciphertext
    return ivStr + ':' + encryptedStr;
  }


  aesDecrypt(encryptedToken: string, key: string): string {
    const stringArr = encryptedToken.split(':')
    // Convert string key into bytes
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    // Convert string iv into bytes
    const ivBytes = CryptoJS.enc.Base64.parse(stringArr[0]);
    const decrypted = CryptoJS.AES.decrypt(stringArr[1], keyBytes, {
      iv: ivBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
