<router-outlet></router-outlet>

<nz-modal
  class="disclaimer-modal"
  [nzVisible]="isAgreementModalVisible"
  [nzClosable]="false"
  [nzTitle]="agreementTitle"
  [nzWidth]="900"
  [nzFooter]="agreementFooter"
  nzWrapClassName="vertical-center-modal">
	<ng-template #agreementTitle>
		<div>
			<h3 class="d-inline-block mb-0"><span translate="term.of.use"></span></h3>
			<div class="float-right my-2">
				<span *ngFor="let option of langOptions">
					<span class="mb-2 p-0" *ngIf="option.value===locale">
						<a class="text-sm country-item" rel="noreferrer noopener" (click)="changeLanguage(option.value)">
							{{option.label}}
						</a>
					</span>
				</span>
				<span *ngFor="let option of langOptions">
					<span class="mb-2 p-0" *ngIf="option.value!=locale">
						|<a class="text-sm text-black-7 country-item" rel="noreferrer noopener" (click)="changeLanguage(option.value)">
							{{option.label}}
						</a>
					</span>
				</span>
				<div class="d-inline-block ml-3"><country-selector [country]="{ title: 'Hong Kong', titleKey: 'hong.kong' }"></country-selector></div>
			</div>
		</div>
	</ng-template>
	<p><span translate="popup.page.header"></span></p>
	<p><span [innerHTML]="'popup.page.detail1' | translate"></span></p>
	<p><span [innerHTML]="'popup.page.detail2' | translate"></span></p>
	<p><span [innerHTML]="'popup.page.detail3' | translate"></span></p>
	<p><span [innerHTML]="'popup.page.detail4' | translate"></span></p>
	<p><span [innerHTML]="'popup.page.detail5' | translate"></span></p>
	<ng-template #agreementFooter>
		<button nz-button nzType="default" (click)="close()">{{'fsmone.close' | translate}}</button>
		<button nz-button nzType="theme" (click)="acceptTerms()">{{'popup.page.accept' | translate}}</button>
	</ng-template>
</nz-modal>