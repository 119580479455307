export class DateConstant {

  // DateTime format
  public static readonly DATE_FORMAT = 'dd-MMM-yyyy';
  public static readonly DATE_FORMAT_WITHOUT_DASH = 'dd MMM yyyy';
  public static readonly DATE_FORMAT_WITHOUT_DASH_WITHOUT_YEAR = 'dd MMM';

  // Date object
  public static readonly TODAY: Date = DateConstant.getStartOfDay(new Date());

  private static readonly YEAR: number = DateConstant.TODAY.getFullYear();
  private static readonly MONTH: number = DateConstant.TODAY.getMonth();
  private static readonly DAY: number = DateConstant.TODAY.getDate();

  public static readonly YESTERDAY: Date = DateConstant.getStartOfDay(new Date().setDate(DateConstant.DAY - 1));
  public static readonly ONE_WEEK_AGO: Date = DateConstant.getStartOfDay(new Date().setDate(DateConstant.DAY - 7));
  public static readonly ONE_MONTH_AGO: Date = DateConstant.getStartOfDay(new Date().setMonth(DateConstant.MONTH - 1));
  public static readonly THREE_MONTHS_AGO: Date = DateConstant.getStartOfDay(new Date().setMonth(DateConstant.MONTH - 3));
  public static readonly SIX_MONTHS_AGO: Date = DateConstant.getStartOfDay(new Date().setMonth(DateConstant.MONTH - 6));
  public static readonly ONE_YEAR_AGO: Date = DateConstant.getStartOfDay(new Date().setFullYear(DateConstant.YEAR - 1));
  public static readonly TWO_YEARS_AGO: Date = DateConstant.getStartOfDay(new Date().setFullYear(DateConstant.YEAR - 2));
  public static readonly THREE_YEARS_AGO: Date = DateConstant.getStartOfDay(new Date().setFullYear(DateConstant.YEAR - 3));
  public static readonly FIVE_YEARS_AGO: Date = DateConstant.getStartOfDay(new Date().setFullYear(DateConstant.YEAR - 5));
  public static readonly SEVEN_YEARS_AGO: Date = DateConstant.getStartOfDay(new Date().setFullYear(DateConstant.YEAR - 7));
  public static readonly TEN_YEARS_AGO: Date = DateConstant.getStartOfDay(new Date().setFullYear(DateConstant.YEAR - 10));

  public static readonly FIRST_DAY_OF_CURRENT_MONTH: Date = new Date(DateConstant.YEAR, DateConstant.MONTH, 1);
  public static readonly LAST_DAY_OF_CURRENT_MONTH: Date = new Date(DateConstant.YEAR, DateConstant.MONTH + 1, 0, 23, 59, 59, 999);
  public static readonly FIRST_DAY_OF_CURRENT_YEAR: Date = new Date(DateConstant.YEAR, 0, 1);
  public static readonly LAST_DAY_OF_CURRENT_YEAR: Date = new Date(DateConstant.YEAR, 11, 31, 23, 59, 59, 999);
  public static readonly FIRST_DAY_OF_LASTYEAR: Date = new Date(DateConstant.YEAR - 1, 0, 1);
  public static readonly LAST_DAY_OF_LASTYEAR: Date = new Date(DateConstant.YEAR - 1, 11, 31, 23, 59, 59, 999);

  static readonly MONTH_STR_LIST: Array<string> = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  public static getStartOfDay(input: Date | number): Date {
    const date: Date = input instanceof Date ? input : new Date(input);
    return new Date(date.setHours(0, 0, 0, 0));
  }

  public static getEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 59, 999));
  }

  public static getMonthString(monthNum: number): string {
    return (monthNum || (monthNum > 0 && monthNum <= 12)) ? this.MONTH_STR_LIST[monthNum] : '';
  }

	public static bypassTimeZone(date?: Date): Date { // always show HKT
		if(date == null)
			return date;
		
		let hkDate = new Date(date).getTime() + (date.getTimezoneOffset() + 480 )*60000;
		
//		let hkDate = new Date();
//		hkDate.setFullYear(date.getUTCFullYear());
//		hkDate.setMonth(date.getUTCMonth());
//		hkDate.setDate(date.getUTCDate());
//		hkDate.setHours(date.getUTCHours() + 8 );
//		hkDate.setMinutes(date.getUTCMinutes());
//		hkDate.setMilliseconds(date.getUTCMilliseconds());

		return new Date(hkDate);
	}
}
