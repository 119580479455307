import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {sort, sortFiguresList} from '@util/sort';
import {Select} from '@ngxs/store';
import {GlobalState} from 'src/app/core/store';
import {Observable} from 'rxjs';
import {genRouterLink} from '@util/route';
import {HighchartsConstant, RouteConstant} from '../../../../../constant';


@Component({
  selector: 'app-etf-chart-centre-table',
  templateUrl: './etf-chart-centre-table.component.html',
  providers: [SubHandlingService]
})
export class EtfChartCentreResultTableComponent implements OnInit {
  @Input() tableData: any[];
  @Input() isEmpty: boolean;
  @Input() isLoading: boolean;
  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
  @Output() clearETF = new EventEmitter<{ tickerNo: string }>()
  plotColor = HighchartsConstant.PLOT_COLORS;
  readonly linkToFactsheet = genRouterLink(RouteConstant.ETFS, RouteConstant.TOOLS, RouteConstant.ETFS_FACTSHEET);

  constructor() {
  }

  ngOnInit(): void {
  }

  tableSort(sortBy) {
    this.tableData = sortFiguresList(sortBy, this.tableData);
  }

  onClearETF(selectedTickerNo: string): void {
		console.log('clear etf.');
    this.clearETF.emit({tickerNo: selectedTickerNo});
  }
}
