import { Component, Input } from '@angular/core';

@Component({
	selector: 'menu-image',
  templateUrl: './menu-img.component.html',
	styleUrls: ['./menu-img.component.scss']
})

export class MenuImgComponent{
	@Input() img: String;
	@Input() title: String;
	
}