
	<ui-share-buttons
		[vertical]="false"
		[url]="url"
		[description]="description"
		[roundedIcon]="true"
    [showIcon]="true"
    [showText]="true"
    [title]="title"
    [include]="include">
	</ui-share-buttons>
	
	<div>
	<nz-row>
	<nz-col>
	<label>
	{{url}}
	</label>
	</nz-col>
	<nz-col>
	<share-buttons theme="modern-dark"
    [showIcon]="true"
    [showText]="false"
   	[url]="url"
   	[title]="title"
    [description]="description"
    class="pt-5" [include]="['copy']">
	</share-buttons>
	</nz-col>
	</nz-row>
	</div>


