<nz-table #EtfChartTable
					*ngIf="!(isEmpty)"
          [nzTemplateMode]=true
          [nzLoading]="isLoading"
          class="my-6"
          [nzScroll]="(isMobile$ | async) ? {x: '709px'} : {x: '760px'}"
          >
  <thead (nzSortChange)="tableSort($event)" nzSingleSort>
  <tr>
    <th nzLeft="0px" nzShowSort nzSortKey="etfName" nzWidth="300px"><span translate="etf.selector.etf.name" translate-default="ETF Name"></span></th>
    <th nzShowSort nzSortKey="currentBidPrice" nzWidth="150px"><span translate="fsmone.bond.chart.current.nav" translate-default="Bid Price"></span></th>
    <th nzShowSort nzSortKey="minBidPrice" nzWidth="100px"><span translate="fsmone.bond.chart.min.nav" translate-default="Min Price"></span></th>
    <th nzShowSort nzSortKey="maxBidPrice" nzWidth="100px"><span translate="fsmone.bond.chart.max.nav" translate-default="Max Price"></span></th>
    <th nzShowSort nzSortKey="returns" nzWidth="100px"><span translate="fsmone.returns" translate-default="Returns"></span></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of tableData">
    <td class="text-info" nzLeft="0px">
      <div nz-row>
        <div nz-col nzSpan="2">
          <a class="pl-2 pr-3 text-black text-md" (click)="onClearETF(data.tickerNo)">X</a>
        </div>
        <div nz-col nzSpan="1" class="pt-1 pl-1 pr-2">
          <p [ngStyle]="{'background-color': data.color,'color':data.color} " class="border-radius-1">
            |
          </p>
        </div>
        <div nz-col nzSpan="18">
          <p class="pt-1 d-print-inline-block font-weight-semibold ">
            <a [queryParams]="{stockCode:data?.stockCode, exchange: data?.exchange}" [routerLink]="linkToFactsheet">{{data?.etfName}}</a>
          </p>
        </div>
      </div>
    </td>
    <td *ngIf="data.currentBidPrice >0 else elseBlock">{{data?.currentBidPrice | number:'.4' || '-'}}
      <i
        [nzTooltipTitle]="showDateToolTip"
        class="text-danger"
        nz-icon
        nz-tooltip
        nzTheme="outline"
        nzTooltipPlacement="top"
        nzType="info-circle"
      ></i></td>
    <ng-template #showDateToolTip>
      <p>{{data?.currentNavDate | hkDate }}</p>
    </ng-template>
    <td *ngIf="data.minBidPrice >0 else elseBlock"> {{data?.minBidPrice | number:'.4' || '-'}}  </td>
    <td *ngIf="data.maxBidPrice >0 else elseBlock"> {{data.bondCurrencyCode}} {{data?.maxBidPrice| number:'.4' || '-'}}  </td>
    <td *ngIf="data.returns >0  else elseBlock">
      <number-wrapper [postfix]="'%'" [showArrow]="true" [value]="data.returns"></number-wrapper>
    </td>
    <ng-template #elseBlock>
      <td>N/A</td>
    </ng-template>
  </tr>
  </tbody>
</nz-table>
