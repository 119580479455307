import {Component, Input, OnInit} from '@angular/core';
import {IArticleRcmsTwModel} from '@api-module/model/article/i-article-rcms-tw.model';
import {Select} from '@ngxs/store';
import {GlobalState} from '../../../../core/store';
import {Observable} from 'rxjs';
import {AppConstant, FundConstant, RouteConstant} from 'src/app/constant';
import {Router} from '@angular/router';
import {ArticleCategory} from '@api-module/enum/i-fund-article-category.enum';
import {ArticleService} from "src/app/share/services/article.service";
import {SubHandlingService} from "@api-module/service/common/subscription-handling.service";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
  selector: 'article-rcms-container',
  templateUrl: './article-rcms-container.component.html',
  styleUrls: ['./article-rcms-container.scss'],
  providers: [SubHandlingService]

})
export class ArticleRcmsContainerComponent implements OnInit {

  @Input() title: string;
  @Input() columnSpan: number = 2;
  @Input() articleList: IArticleRcmsTwModel[] = [];
  @Input() viewMoreCategory: ArticleCategory = {} as ArticleCategory;

  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  readonly RouteConstant = RouteConstant;
  readonly FundConstant = FundConstant;
  readonly AppConstant = AppConstant;

  webcast = false;
  isMobile = false;
  highLight = false;
  localeOption = 'zh-TW';

  constructor(private router: Router, private articleService: ArticleService, 
		private globalDataStorage:GlobalDataStorage, private subHandlingService: SubHandlingService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
	this.setLocale();
  }

  redirectToArticleSearch(): void {
    this.router.navigate([RouteConstant.ARTICLE], {
      queryParams: {
        category: this.viewMoreCategory.key,
        tags: this.viewMoreCategory.tags
      }
    });
  }

  redirectToArticleDetails(articleNo: string, fromRcms?: string): void {
	this.router.navigate([RouteConstant.ARTICLE, RouteConstant.ARTICLE_DETAILS, articleNo], {});
  }

  redirectToAuthorPage(authorId) {
    this.router.navigate([RouteConstant.AUTHOR_DETAILS], {queryParams:{author: authorId}});
  }
	
	setLocale(){
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
	
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}
}
