<div class="container">
        <nz-row nzGutter="20" class="mb-3 d-flex flex-wrap">
          <nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
            <ng-container 
              *ngTemplateOutlet="Template; context: { method: FUNDS, text: 'fsmone.fee.funds', path: 'fund-2x' }">
            </ng-container>
    	</nz-col>
    
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
            <ng-container 
              *ngTemplateOutlet="Template; context: { method: BONDS, text: 'fsmone.fee.bonds', path: 'bond-2x' }">
            </ng-container>
		</nz-col>
		
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
            <ng-container 
              *ngTemplateOutlet="Template; context: { method: ETF, text: 'fsmone.general.etfs', path: 'stock-2x' }">
            </ng-container>
        </nz-col>
        <nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
            <ng-container 
              *ngTemplateOutlet="Template; context: { method: STOCK, text: 'fsmone.stocks', path: 'stock-2x' }">
            </ng-container>
        </nz-col>
        <nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
            <ng-container 
              *ngTemplateOutlet="Template; context: { method: DPMS, text: 'dpms.fsm.managed.portfolios', path: 'dpms-2x' }">
            </ng-container>
        </nz-col>
    	</nz-row>
</div>



<ng-template #content>
<div class="container">
	<div *ngIf="selected == STOCK" nz-row nzAlign="top" nzJustify="center" nzGutter="8" nzType="flex" class="py-5">
		<div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
			<article-highlight-container 
	        	title="{{'fsmone.latest.in.stocks' | translate}}"
				[articleList]="latest_stock" [hideViewMoreLink]="true"
				[hideViewMoreButton]="true"> 
			</article-highlight-container>
		</div>
	</div>
</div>
</ng-template>

	
    <!-- <div *ngIf="selected == FUNDS">
		<div *ngIf="loading==false; else elseBlock">
		    <ng-container 
		    	*ngTemplateOutlet="articleList; context: { list: article_fund, title: 'fsmone.fee.funds' }">
		    </ng-container>
	    </div>
    </div> 

    <div *ngIf="selected == BONDS">
		<div *ngIf="loading==false; else elseBlock">
		    <ng-container 
		    	*ngTemplateOutlet="articleList; context: { list: article_bond, title: 'fsmone.fee.bonds' }">
		    </ng-container>
	    </div>
    </div>
    
    <div *ngIf="selected == ETF">
		<div *ngIf="loading==false; else elseBlock">
		    <ng-container 
		    	*ngTemplateOutlet="articleList; context: { list: article_etf, title: 'fsmone.general.etfs' }">
		    </ng-container>
	    </div>
    </div>
    
    <div *ngIf="selected == STOCK">
		<div *ngIf="loading==false; else elseBlock">
		    <ng-container 
		    	*ngTemplateOutlet="articleList; context: { list: market_stock, title: 'fsmone.stocks' }">
		    </ng-container>
	    </div>
    </div>
    
    <div *ngIf="selected == DPMS">
		<div *ngIf="loading==false; else elseBlock">
	    <ng-container 
	    	*ngTemplateOutlet="articleList; context: { list: article_dpms, title: 'fsmone.fee.managed.portfolio' }">
	    </ng-container>
	    </div>
    </div>
</div>
</ng-template> -->

<ng-template #Template let-method="method" let-text="text" let-path="path">
    <nz-card class="card-select-box h-100" [ngClass]="{'active': selected == method}" nzHoverable role="button" (click)="changeCategory(method)">
      <div class="text-center">
        <img height="50" [src]="PRODUCTS_ICON_IMAGES_PATH + path + '.png'" [alt]="text + ' Icon'">
        <h4 class="mt-2 mb-0">{{ text | translate }}</h4>
      </div>
    </nz-card>
</ng-template>

<ng-template #articleList let-list="list" let-title="title">
  <nz-row nzType="flex" nzAlign="middle" nzJustify="space-between" class="mb-3 pt-5">
      <nz-col><h3 class="mb-0">{{ title | translate }}</h3></nz-col>
      <nz-col><a class="font-weight-bold text-sm" (click)="redirectToMore()">{{'fsmone.more' | translate}}</a></nz-col>
  </nz-row> 
  <nz-row [nzGutter]="(isMobile$ | async) ? 0 : 16" nzType="flex">
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="6" *ngFor="let article of list last as isLast" [ngClass]="{'mb-3': !(isMobile$ | async)}">
      <article
            (articleClickEventEmitter)="redirectToArticleDetails(article.articleId.toString())"
            [horizontal]="(isMobile$ | async)"
            [title]="article.title"
            [image]="article.featuredImage"
            [author]="article.author"
            [date]="article.logdate"
            [view]="article?.hits"></article>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #elseBlock>
	<nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
