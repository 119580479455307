import {Component, EventEmitter, Input, Output,OnInit} from "@angular/core";
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import {Select} from "@ngxs/store";
import {GlobalState} from "../../../../core/store";
import {Observable} from "rxjs";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
  selector: 'webcast',
  templateUrl: './webcast.component.html',
  styleUrls: ['./webcast.component.scss'],
  providers: [SubHandlingService]

})
export class WebcastComponent implements OnInit {
  @Input() type: string = 'Vertical';
  @Input() title: string;
  @Input() category: string;
  @Input() image: string;
  @Input() author: string;
  @Input() authorClickable: boolean = false;
  @Input() view: string;
  @Input() date: Date;
  @Output() videoDetailsUrl: EventEmitter<any> = new EventEmitter<any>();
  @Input() videoLength: string;
  @Input() titleInVideo: boolean = false;
  @Input() titleInVideoAlwaysDisplay: boolean = false;
  @Input() dark: boolean = true;
  @Output() authorClick: EventEmitter<any> = new EventEmitter<any>();
  localeOption:string = "zh-TW";

  @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  constructor(private globalDataStorage:GlobalDataStorage) {
  }

  ngOnInit(): void {
	this.setLocale();
  }


  setLocale(){
	let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
  }

  clickIntoWebcast(){
	this.videoDetailsUrl.emit();
  }
}
