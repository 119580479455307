<ng-container *ngIf="fsmPostLoginWhitelist || (fsmPostLoginEnabled$ | async)">
  <div id="new-footer-tools" [ngClass]="{'mobile-menu-padding': isMobileView}">
    <div class="container py-3">
      <div class="d-flex justify-content-start">
        <img class="footer-box logo-col text-center" src="assets/images/footer/fsmlogo-white.svg" alt="fsm logo">
        <p class="footer-background-text" innerHTML="{{ 'fsmone.login.page.slogan' | translate}}"></p>
      </div>
      <nz-divider></nz-divider>
      <div nz-row nzType="flex" nzJustify="space-between" nzAlign="top" [nzGutter]="8">
        <div nz-col [nzXs]="20" [nzSm]="16" [nzXl]="6">
          <h5 class="text-white mb-3">{{ 'fsmone.be.the.first.to.learn.market.analysis' | translate }}</h5>
          <form nz-form [formGroup]="form" (ngSubmit)="submitForm($event, form.value)">
            <nz-form-item class="mb-2">
              <nz-form-control>
                  <ui-input-group [nzLabelPlaceholder]="'name'| translate">
                      <input class="w-80" formControlName="name" ui-input/>
                  </ui-input-group>
                  <ng-container>
                    <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').hasError('required')">
                      <div class="d-flex mt-2 w-100">
                          <i nz-icon nzType="exclamation-circle" class="mr-1" nzTheme="fill"></i>
                          <div class="text-form-explain">{{'fsmone.please.enter.your.name.' | translate}}</div>
                      </div>
                    </nz-form-explain>
                  </ng-container>
              </nz-form-control>
          </nz-form-item>
            <nz-form-item class="mb-1">
              <nz-form-control>
                  <ui-input-group [nzLabelPlaceholder]="'email'| translate">
                      <input class="w-80" formControlName="email" ui-input/>
                  </ui-input-group>
                  <ng-container>
                    <nz-form-explain *ngIf="form.get('email').dirty && form.get('email').hasError('required')">
                      <div class="d-flex mt-2 w-100">
                          <i nz-icon nzType="exclamation-circle" class="mr-1" nzTheme="fill"></i>
                          <div class="text-form-explain">{{'fsmone.error.email.regexp' | translate}}</div>
                      </div>
                    </nz-form-explain>
                  </ng-container>
                  <ng-container>
                    <nz-form-explain *ngIf="form.get('email').dirty && form.get('email').hasError('pattern')">
                        <div class="d-flex mt-2 w-100">
                            <i nz-icon nzType="exclamation-circle" class="mr-1" nzTheme="fill"></i>
                            <div class="text-form-explain">{{'fsmone.error.email.regexp' | translate}}</div>
                        </div>
                    </nz-form-explain>
                  </ng-container>
              </nz-form-control>
          </nz-form-item>
          <nz-form-control>
            <button nz-button nzType="theme" class="mt-2">{{ 'fsmone.subscribe' | translate }}</button>
          </nz-form-control>
          </form>
          <div class="social-medias pt-3 pb-5">
            <a class="social-icon" href="https://www.facebook.com/fsmone.hk"><i class="fsmicon-facebook-o"></i></a>
            <a class="social-icon" href="https://www.instagram.com/fsmone.hk/"><i class="fsmicon-instagram-o"></i></a> 
            <a class="social-icon" href="https://www.youtube.com/channel/UCqpMQhoQLdy9ycSIBCKa4iA"><i class="fsmicon-youtube"></i></a> 
          </div>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzXl]="18" class="text-left">
          <ul class="footer-vertical-links">
            <li [ngClass]="{'pl-0 pb-1': !isMobileView}"><a routerLink="/about-us"><span translate="fsmone.about.us" translate-default="About Us"></span></a></li>
            <li><a routerLink='/careers'><span translate="fsmone.careers" translate-default="Careers"></span></a></li>
            <li><a routerLink="/disclaimer"><span translate="fsmone.disclaimer" translate-default="Disclaimer"></span></a></li>
            <li><a routerLink="/personal-info-collection-statement"><span translate="fsmone.personal.information.collection.statement" translate-default="Personal Information Collection Statement"></span></a></li>
            <li><a routerLink="/morningstar-disclaimer"><span translate="fsmone.morningstar.disclaimer" translate-default="Morningstar Disclaimer"></span></a></li>
            <li><a routerLink="/hkex-disclaimer"><span translate="fsmone.hkex.disclaimer" translate-default="HKEX Disclaimer"></span></a></li>
            <li><a routerLink="/trading-view-disclaimer"><span translate="trading.view.disclaimer" translate-default="TradingView Disclaimer"></span></a></li>
            <li [ngClass]="{'pl-0': !isMobileView}"><a routerLink="/s-p-disclaimer"><span innerHTML="{{ 'fsmone.sp.disclaimer' | translate }}"></span></a></li>
            <li><a routerLink="/cyber-security"><span translate="fsmone.cyber.security" translate-default="Cyber Security"></span></a></li>
          </ul>
          <div class="text-footer text-left pt-2">
            <strong><span translate="fsmone.disclaimer" translate-default="Disclaimer" class="d-block text-default"></span></strong>
            <br>
            <span innerHTML="{{ 'fsmone.disclaimer.for.past.performance' | translate }}"></span>
            <br>
            <br>
            <span innerHTML="{{ 'fsmone.disclaimer.for.sap1' | translate }}"></span> 2021 <span innerHTML="{{ 'fsmone.disclaimer.for.sap2' | translate }}"></span>
            <br>
            <br>
            <span innerHTML="{{ 'fsmone.disclaimer.for.gem' | translate }}"></span>
            <br>
            <br>
            <span id="disclaimerTag" innerHTML="{{ 'fsmone.fsm.web.disclaimer1.new.2' | translate }}"></span>
            <br>
          </div>
              
          <p class="text-left text-footer pt-4">
            <strong><span translate="fsmone.footer.all.rights.reserved" translate-default="All Rights Reserved. Reg. No. 200000231R" class="d-block"></span></strong>
            <br>Copyright @ 2023, Market data provided by Morningstar Research Pte. Ltd. &amp; Morningstar Real-Time Data Ltd. All Rights Reserved.
            <br>Copyright @ 2023, HKEX market data provided by HKEX Information Services Ltd. All Rights Reserved.
            <br>Copyright @ 2023, Cboe One Real-Time Quote provided by Cboe Data Services, LLC. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div id="fsmone-cyber-message-banner" class="py-1" *ngIf="showCyberMessage" #fsmoneCyberMessageBanner>
	<div class="container fsmone-cyber-message">
		<div>
			<i nz-icon nzType="exclamation-circle" nzTheme="fill" style="font-size:16px;"></i>
			<a class="ml-3 text-decoration-none text-black-7" routerLink="/cyber-security">{{'fsmone.disclaimer.beware.of.suspicious.messages.and.websites' | translate}}</a>
		</div>
		<div>
			<button nz-button nzType="default" (click)="closeCyberMessage()" class="font-weight-bold">{{'fsmone.close' | translate}}</button>		
		</div>
	</div>
</div> 

<!-- FSM 2.0 Footer Style -->
<ng-container *ngIf="!(fsmPostLoginWhitelist || (fsmPostLoginEnabled$ | async))">
  <!-- Footer Tools -->
  <div id="footer-tools" class="white-bg">
    <div class="container">
      <div class="footer-box logo-col text-center">
        <b2c-ui-icon name="fsmoneLogoIcon"></b2c-ui-icon>
      </div>
      <div class="footer-box get-advice">
        <div class="footer-box-icon hidden-sm-down">
          <i nz-icon nzType="phone" nzTheme="fill" [nzRotate]="90"></i>
        </div>
        <div class="footer-box-content">
          <h3><span translate="fsmone.need.financial.advice" translate-default="Need Financial Advice?"></span></h3>
          <a (click)="redirectToUrl('/advice-services/cis-appointment-request')">
            <span translate="fsmone.make.an.appointment" translate-default="Make an Appointment"></span>
          </a>
        </div>
      </div>
      <div class="footer-box live-chat">
        <div class="footer-box-icon hidden-sm-down">
          <i nz-icon nzType="message" nzTheme="fill"></i>
        </div>
        <div role="button" (click)="openLiveChat()" class="footer-box-content">
          <h3 translate="fsmone.live.chat" translate-default="Live Chat">
            <small>
              <span translate="fsmone.tim.home.operating.hour" translate-default="8.30am to 5.30pm, Monday to Friday (excluding PH)"></span>
            </small>
          </h3>
        </div>
      </div>
      <div class="footer-box email">
        <div class="footer-box-icon hidden-sm-down">
          <i nz-icon nzType="mail" nzTheme="fill"></i>
        </div>
        <div class="footer-box-content">
          <h3 translate="fsmone.email" translate-default="Email"></h3>
          <a routerLink="/support/enquiry">clienthelp@fsmone.com.hk</a>
        </div>
      </div>
      <div class="footer-box faq">
        <div class="footer-box-icon hidden-sm-down">
          <i nz-icon nzType="mail" nzTheme="fill"></i>
        </div>
        <div class="footer-box-content">
          <h3 translate="fsmone.more.questions" translate-default="More Questions?"></h3>
          <a routerLink="/support/client-support/frequently-asked-questions">
            <span translate="fsmone.read.faqs" translate-default="Read FAQs"></span>
          </a>
        </div>
      </div>
      <div class="footer-box empty"></div>
    </div>
  </div>
  <div id="cyber-message" class="text-center" *ngIf="showCyberMessage">
    <small [innerHTML]="'fsmone.notice.banner.scam.victim' | translate"></small>
    <button nz-button nzType="success" nzSize="small" (click)="closeCyberMessage()">{{'fsmone.close' | translate}}</button>
  </div>

  <!-- Footer Menu -->
  <div id="footer-menu">
    <div class="container">
      <div nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="16" nzLg="16" nzXl="16">
          <div nz-row>
            <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
              <h5>
                <a class="footer-title-link" href="/" translate="fsmone.footer.menu.fsmone" translate-default="FSMOne.com"></a>
              </h5>
              <ul class="list-unstyled footer-page-link">
                <li><a routerLink="/bonds" translate="fsmone.general.bonds" translate-default="Bonds"></a></li>
                <li><a routerLink="/funds" innerHTML="{{'fsmone.general.funds.and.etfs'| translate}}"></a></li>
                <li><a routerLink="/stocks" translate="fsmone.general.stocks" translate-default="Stocks"></a></li>
                <li><a routerLink="/dpms" translate="fsmone.maps.maps.capital" translate-default="Managed Portfolio"></a></li>
              </ul>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
              <h5 translate="fsmone.research" translate-default="Research"></h5>
              <ul class="list-unstyled footer-page-link">
                <li><a routerLink="/bonds/explore/fsmone-research-insight" translate="fsmone.bonds.bonds.insight" translate-default="Bond Research"></a></li>
                <li><a routerLink="/funds/explore/fsmone-research-insight" translate="fsmone.fund.insights" translate-default="Fund Research"></a></li>
                <li><a routerLink="/etfs/explore/fsmone-research-insight" translate="fsmone.menu.etf.research" translate-default="ETFs Research"></a></li>
                <li><a routerLink="/dpms/explore/fsmone-research-insight" translate="fsmone.portfolio.research" translate-default="Portfolio Research"></a></li>
                <li><a routerLink="/stocks/explore/fsmone-research-insight" translate="fsmone.stock.insights" translate-default="Stock Insights"></a></li>
                <li><a routerLink="/funds/explore/fund-house-insight" translate="fsmone.menu.fund.house.hub" translate-default="Fund House Hub"></a></li>
                <li><a (click)="routeToIdeaOfWeek()" translate="fsmone.idea.of.the.week" translate-default="Idea of the Week"></a></li>
                <li><a routerLink="/insight/research/videos" translate="fsmone.webcasts" translate-default="Webcasts"></a></li>
              </ul>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
              <h5 translate="fsmone.useful.links" translate-default="Useful Links"></h5>
              <ul class="list-unstyled footer-page-link">
                <li><a routerLink="/support/client-support/contact-us" translate="fsmone.tim.footer.contact.us" translate-default="Contact Us"></a></li>
                <li><a href="mailto:clienthelp@fsmone.com.hk" translate="fsmone.email.us" translate-default="Email Us"></a></li>
                <li><a (click)="redirectToUrl('/account/forget-password')" translate="fsmone.forgot.your.password" translate-default="Forgot Your Password?"></a></li>
                <li><a (click)="redirectToUrl('/account-opening/main')" translate="fsmone.open.an.account" translate-default="Open an Account"></a></li>
                <li><a routerLink="/support/client-support/frequently-asked-questions" translate="fsmone.faq" translate-default="Frequently Asked Questions"></a></li>
              </ul>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
              <h5 translate="fsmone.menu.stock.tools" translate-default="Tools"></h5>
              <ul class="list-unstyled footer-page-link">
                <li><a routerLink="/stocks/tools/stocks-selector" translate="fsmone.stocks.screener" translate-default="Stock Screener"></a></li>
                <li><a routerLink="/stocks/tools/stocks-calculator" translate="fsmone.stock.calculator" translate-default="Stock Calculator"></a></li>
                <li><a routerLink="/etfs/tools/etfs-selector" translate="fsmone.etf.selector" translate-default="ETF Selector"></a></li>
                <li><a routeLink="/etfs/tools/etfs-calculator" translate="fsmone.etf.calculator" translate-default="ETF Calculator"></a></li>
                <li><a (click)="redirectToUrl('/track/main')" translate="fsmone.track" translate-default="Track"></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
          <h5 translate="fsmone.fsm.mobile.apps" translate-default="FSM Mobile Apps"></h5>
          <div class="pt-3">
            <div class="fsm-app-desc" translate="fsmone.fsm.mobile.apps.desc" translate-default="Fundsupermart&rsquo;s mobile application specially designed for investors of the fast-moving unit trust industry!"></div>
            <div class="fsm-app-links pt-2">
              <div class="pt-2">
                <a class="mr-2" target="_blank" href="https://play.google.com/store/apps/details?id=com.ifast.fsm&hl=zh_HK">
                  <img alt="google play tag" height="40" [src]="GENERAL_QRCODE_IMG_PATH + 'google-play-tag.png'">
                </a>
                <a class="mr-2" target="_blank" href="https://apps.apple.com/app/apple-store/id427129018?pt=497385&ct=fsmonehk-website&mt=8">
                  <img alt="app store tag" height="40" [src]="GENERAL_QRCODE_IMG_PATH + 'app-store-tag.png'">
                </a>
              </div>
              <div class="pt-2">
                <a class="mr-2" target="_blank" href="https://appgallery1.huawei.com/#/app/C102220535">
                  <img alt="huawei tagt" height="40" [src]="GENERAL_QRCODE_IMG_PATH + 'huawei-tag.png'"> 
                </a>
                <a class="mr-2" target="_blank" href="download/apk/Fsm_mobile.apk" download>
                  <img alt="android mainland" height="40" [src]="GENERAL_QRCODE_IMG_PATH + 'android-mainland-'+ locale +'.png'">
                </a>
              </div>
              <div class="pt-3 pb-4 fsm-app-desc small" translate="fsmone.fsm.mobile.apps.china.apk.download.desc.text">
              </div>
            </div>
          </div>
      </div> 
      </div>
    </div>
  </div>

  <!-- Footer Disclaimer -->
  <div id="footer-disclaimer" class="py-4">
    <div class="container text-center">
      <ul class="footer-vertical-links">
        <li><a routerLink="/"><span translate="fsmone.fsm.home" translate-default="FSM Home"></span></a></li>
        <li>|</li>
        <li><a routerLink="/about-us"><span translate="fsmone.about.us" translate-default="About Us"></span></a></li>
        <li>|</li>
        <li><a routerLink='/careers'><span translate="fsmone.careers" translate-default="Careers"></span></a></li>
        <li>|</li>
        <li><a routerLink="/disclaimer"><span translate="fsmone.disclaimer" translate-default="Disclaimer"></span></a></li>
        <li>|</li>
        <li><a routerLink="/personal-info-collection-statement"><span translate="fsmone.personal.information.collection.statement" translate-default="Personal Information Collection Statement"></span></a></li>
        <li>|</li>
        <li><a routerLink="/morningstar-disclaimer"><span translate="fsmone.morningstar.disclaimer" translate-default="Morningstar Disclaimer"></span></a></li>
        <li>|</li>
        <li><a routerLink="/hkex-disclaimer"><span translate="fsmone.hkex.disclaimer" translate-default="HKEX Disclaimer"></span></a></li>
        <li>|</li>
        <li><a routerLink="/s-p-disclaimer"><span innerHTML="{{ 'fsmone.sp.disclaimer' | translate }}"></span></a></li>
        <li>|</li>
        <li><a routerLink="/cyber-security"><span translate="fsmone.cyber.security" translate-default="Cyber Security"></span></a></li>
      </ul>
      <div class="text-footer text-left pt-4">
        <strong><span translate="fsmone.disclaimer" translate-default="Disclaimer"></span>: </strong>
        <span innerHTML="{{ 'fsmone.disclaimer.fundsupermart.com.does.not.guarantee.the.reliability.of.accuracy.of.any.of.the.information' | translate }}"></span>
        <br>
        <span innerHTML="{{ 'fsmone.disclaimer.for.past.performance' | translate }}"></span>
        <br>
        <br>
        <span innerHTML="{{ 'fsmone.disclaimer.for.sap1' | translate }}"></span> 2021 <span innerHTML="{{ 'fsmone.disclaimer.for.sap2' | translate }}"></span>
        <br>
        <br>
        <span innerHTML="{{ 'fsmone.disclaimer.for.gem' | translate }}"></span>
        <br>
        <br>
        <span innerHTML="{{ 'fsmone.disclaimer.for.currency.risk' | translate }}"></span>
        <br>
        <br>
        <span innerHTML="{{ 'fsmone.fsm.web.disclaimer1.new.2' | translate }}"></span>
        <br>
      </div>
          
      <p class="text-footer pt-4">
        <span translate="fsmone.footer.all.rights.reserved" translate-default="All Rights Reserved. Reg. No. 200000231R"></span>
        <br>Copyright @ 2020, Market data provided by Morningstar Research Pte. Ltd. &amp; Morningstar Real-Time Data Ltd. All Rights Reserved.
        <br>Copyright @ 2020, HKEX market data provided by HKEX Information Services Ltd. All Rights Reserved.
        <br>Copyright @ 2020, Cboe One Real-Time Quote provided by Cboe Data Services, LLC. All Rights Reserved.
      </p>
          
      <div class="social-medias pt-3 pb-5">
        <a class="social-icon" href="https://www.facebook.com/fsmone.hk"><i nz-icon nzType="facebook" nzTheme="fill"></i></a>
        <a class="social-icon" href="https://www.youtube.com/channel/UCqpMQhoQLdy9ycSIBCKa4iA"><i nz-icon nzType="youtube" nzTheme="fill"></i></a> 
        <a class="social-icon" href="https://www.instagram.com/fsmone.hk/"><i nz-icon nzType="instagram" nzTheme="fill"></i></a> 
      </div>
    </div>
  </div>

  <div *ngIf="showModal==true">
    <live-chat-modal [visibility]="showModal" (closeModel)="closeLiveChat()"></live-chat-modal>
  </div>
</ng-container>