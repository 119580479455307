<app-route-subheader title="{{'fsmone.menu.chart.center' | translate}}" ></app-route-subheader>

<div class="above-header">
	<div class="container">
   		<nz-row nzGutter="20" class="mb-3 d-flex flex-wrap">
	        <nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
	            <ng-container 
	              *ngTemplateOutlet="Template; context: { method: FUNDS, text: 'fsmone.fee.funds', path: 'fund-2x' }">
	            </ng-container>
	        </nz-col>
			<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
	            <ng-container 
	              *ngTemplateOutlet="Template; context: { method: BONDS, text: 'fsmone.fee.bonds', path: 'bond-2x' }">
	            </ng-container>
			</nz-col>
			
			<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
	            <ng-container 
	              *ngTemplateOutlet="Template; context: { method: ETF, text: 'fsmone.general.etf', path: 'stock-2x' }">
	            </ng-container>
	        </nz-col>
    	</nz-row>
	</div>
</div>

<ng-template #Template let-method="method" let-text="text" let-path="path">
    <nz-card class="h-100" [ngClass]="{'active-selected': selected == method}" nzHoverable role="button" (click)="changeCategory(method)">
      <div class="text-center py-2">
        <img [src]="PRODUCTS_ICON_IMAGES_PATH + path + '.png'" [alt]="text + ' Icon'">
        <h5 class="font-weight-bold mt-2 mb-0">{{ text | translate }}</h5>
      </div>
    </nz-card>
</ng-template>