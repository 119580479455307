import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiBasicCardComponent } from './ui-basic-card/ui-basic-card.component';
import {NzCardModule} from "ng-zorro-antd";



@NgModule({
  declarations: [
    UiBasicCardComponent
  ],
  imports: [
    CommonModule,
    NzCardModule,
  ],
  exports: [
    UiBasicCardComponent
  ]
})
export class BasicCardModule { }
