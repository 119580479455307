import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MobileWebBreakpoint } from '../model/i-breakpoint.model';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetMobileWebAction, SetPlatformAction } from '../store';
import { Platform } from '@angular/cdk/platform';
import { AppConstant } from 'src/app/constant';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService implements OnDestroy {
  private subscription: Subscription;
  private mobileView: boolean;
  mobileViewEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  constructor(private bpObserver: BreakpointObserver, private store: Store, private platform: Platform) {
    const deviceObject = {
      [AppConstant.ANDROID]: this.platform.ANDROID,
      [AppConstant.IOS]: this.platform.IOS,
    };

    let currentDevice: string = AppConstant.WEB;

    Object.keys(deviceObject).forEach((platform: string) => {
      if (deviceObject[platform]) {
        currentDevice = platform;
      }
    });

    const browserObject = {
      'Internet Explorer': this.platform.TRIDENT,
      'Firefox': this.platform.FIREFOX,
      'Safari': this.platform.SAFARI,
      'Chrome': this.platform.BLINK,
      'Microsoft Edge': this.platform.EDGE,
      'Webkit': this.platform.WEBKIT
    };

    let currentBrowser: string = '';

    Object.keys(browserObject).forEach((platform: string) => {
      if (browserObject[platform]) {
        currentBrowser = platform;
      }
    });

    let iPadUser: boolean = false;

    if (this.platform.IOS) {
      iPadUser = this.bpObserver.isMatched('(min-width: 768px) and (max-width: 919.99px)') || false;
    }
	
	console.log("ResponsiveService ");
    this.store.dispatch(new SetPlatformAction(currentDevice, currentBrowser, iPadUser));
  }

  isMobileView() {
		return this.mobileView;
	}

  observe() {
    this.subscription = this.bpObserver
      .observe(MobileWebBreakpoint)
      .pipe(
        map(({ breakpoints }) => breakpoints),
        map(breakpointMap => {
          return breakpointMap[MobileWebBreakpoint];
        }),
        distinctUntilChanged(),
        tap((mobileView: boolean) => {
          this.store.dispatch(new SetMobileWebAction(mobileView));
          this.mobileView = mobileView;
          this.mobileViewEvent.emit(this.mobileView);
          window.scrollTo({
            top: 0,
            left: 0,
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
