<b2c-subheader class="route-subheader" [ngClass]="{'large-banner': size == 'large'}" [coverImage]="coverImage" *ngIf="!isBreadcrumbOnly">
	<ng-container *ngIf="showBreadcrumb">
		<ng-container *ngFor="let item of navigationSteps; last as isLast">
			<ng-container *ngIf="isLast; else stepsTemplate">
				<span *ngIf="item && item.titleKey && !item.routerLink">
					{{ item.titleKey | translate}}
				</span>
				<a *ngIf="item && item.titleKey && item.routerLink" [ngClass]="{'text-info': item.routerLink!==null}" (click)="goTo(item.routerLink)">
					{{ item.titleKey | translate}}
				</a>
				<span *ngIf="!item.titleKey" class="text-sm">
					{{ item?.title }}
				</span>
			</ng-container>
			<ng-template #stepsTemplate>
				<span *ngIf="item && item.titleKey && !item.routerLink"></span>
				<a *ngIf="item && item.titleKey && item.routerLink" [ngClass]="{'text-info': item.routerLink!==null}" (click)="goTo(item.routerLink)">{{ item.titleKey | translate}}</a>
				<span *ngIf="!item.titleKey">{{ item?.title | translate}}</span>
				<span *ngIf="item && item.titleKey && item.routerLink || !item.titleKey" class="ml-1 mr-1">></span>
			</ng-template>
		</ng-container>
	</ng-container>
  <p class="mt-0 mb-0">
	<ng-container *ngIf="meta; else contentTemplate">
      <nz-row>
        <nz-col nzXs="20" nzLg="18">
          <span class="text-white font-weight-normal" [ngClass]="mobile ? 'text-sm text-justify' : 'text-md'">
            <app-web-scaffold>{{ meta }}</app-web-scaffold>
            <app-mobile-scaffold>
              {{ meta | slice:0:300 }}
              <ng-container *ngIf="meta?.length > 300">...</ng-container>
            </app-mobile-scaffold>
          </span>
        </nz-col>
      </nz-row>
    </ng-container>
	<ng-template #contentTemplate>
		<ng-content></ng-content>
	</ng-template>
     <ng-container *ngTemplateOutlet="titleH1"></ng-container>
	</p>
	<ng-template #titleH1>
		<h1 *ngIf="!isH2" class="text-white text-xl mb-4">{{title}}</h1>
		<h2 *ngIf="isH2" class="text-white text-xl">{{title}}</h2>
	</ng-template>

</b2c-subheader>

<div class="b2c-subheader" *ngIf="isBreadcrumbOnly">
	<div class="b2c-subheader-wrap">
    	<div class="container">
      		<p class="d-block" [ngClass]="[mobile ? 'text-sm': 'text-xs']"> 
        		<ng-container *ngFor="let item of navigationSteps; last as isLast">
          			<ng-container *ngIf="isLast; else stepsTemplate">
            			<span *ngIf="item && item.titleKey && !item.routerLink">
	      				  	{{ item.titleKey | translate}}
				        	</span>
            			<a *ngIf="item && item.titleKey && item.routerLink" [ngClass]="{'text-info': item.routerLink!==null}" (click)="goTo(item.routerLink)">
	      					  {{ item.titleKey | translate}}
				      	  </a>
						<span *ngIf="!item.titleKey">
	      					{{ item?.title }}
				      	</span>
          			</ng-container>
          			<ng-template #stepsTemplate>
          				<span *ngIf="item && item.titleKey && !item.routerLink"></span>
			      		<a *ngIf="item && item.titleKey && item.routerLink"(click)="goTo(item.routerLink)" >{{ item.titleKey | translate}}</a>
			      		<span *ngIf="!item.titleKey">{{ item?.title | translate}}</span>
            			<span *ngIf="item && item.titleKey && item.routerLink || !item.titleKey" class="ml-1 mr-1">></span>
          			</ng-template>
        		</ng-container>
			</p>
    	</div>
  	</div>
</div>
