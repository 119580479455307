import { Component, OnInit, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SubHandlingService } from 'src/app/share/services/subscription-handling.service';
import { ProductSearchService } from 'src/app/share/services/product-search.service';
import { ProductRestService } from '@api-module/rest/product.rest.service';
import { IProductSearchModel } from '@api-module/model/product/i-product-search.model';
import { B2CProductSearchQueryModel } from 'b2c-ui/header';
import { Observable, Subject, fromEvent } from 'rxjs';
import { tap, filter, debounceTime, takeUntil, switchMap } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { GlobalState } from 'src/app/core/store';
import { environment } from '@env';
import { GlobalSearchConstant } from 'src/app/constant/global.search.constant';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import {compare, sort} from "@util/sort";
import { ResponsiveService } from '@core/service/responsive.service';
import {AppConstant} from "src/app/constant";

@Component({
	selector: 'global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss'],
	host: {
		class: 'w-100'
	},
	providers: [SubHandlingService]
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
	searching: boolean = false;
	loading: boolean = false;
	searchResults: Array<IProductSearchModel> = [];
	page: number = 0;
	queryData: B2CProductSearchQueryModel;
	end: boolean = false;
	searchString: string;
	searchCategory: string;
	mobile: boolean = false;
	form: FormGroup;
	height = (Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)) * 0.50;
	private readonly destroySubject = new Subject<any>();
	@ViewChild('typeahead', { static: true }) typeaheadInput: ElementRef<HTMLElement>;
	activationCount: number = 0;
	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	selectedIndex: number = 0;
	apiCalledCount: number = 0;
	apiResultReturnCount: number = 0;
	keyword: string;
	productTypeColorList: any = GlobalSearchConstant.PRODUCT_TYPE_COLORS;
	exchangeColorList: any = GlobalSearchConstant.STOCK_ETF_EXCHANGE_COLORS;
	/*listOption=['All','Unit Trust','Stocks','ETFs','Bonds','Managed Portfolio','Articles','FAQ'];*/
	listOption = ['all', 'mutual.funds', 'bonds', 'stock', 'etf', 'articles'];
	selectTabSubject = new Subject<any>();
	category: string = 'All';

	isMobileView: boolean = false;
	
	localeOption: string = "zh-TW";
	lastSearchArticleResult: number = 0;
	articleResultPage: number = 1;
	isArticleSearched: boolean = false;
	
	constructor(private productSearchService: ProductSearchService, private productRestService: ProductRestService, private cdRef: ChangeDetectorRef, private fb: FormBuilder,  private responsiveService: ResponsiveService, private globalDataStorage: GlobalDataStorage) {

	}
	
	@Input() search: boolean;
	
	toggleSearch(){
		this.search = !this.search;
	}

	loadNext() {	
		if (this.keyword && !this.end) {
			this.searchProduct(true);
		} else if(this.isArticleRelatedCategory(this.category) && this.end && (this.lastSearchArticleResult==20 || !this.isArticleSearched)) {
			this.searchArticles(this.articleResultPage);
		}
	}

	openProduct(data: IProductSearchModel) {
		this.productSearchService.openProduct(data);
	}


	private searchProduct(concat?: boolean) {
		if (this.keyword && this.keyword !== '' && this.keyword.length >= 1 && (this.keyword !== this.searchString || this.category !== this.searchCategory || concat)) {
			this.searchString = this.keyword;
			this.searchCategory = this.category;
			if (concat) {
				if (this.keyword !== this.searchString) {
					this.page = 1;
					this.searchResults = [];
					this.searchProductResult(false);
					this.loading = false;
					this.searching = true;
				} else if (this.searchResults && this.searchResults.length > 0) {
					this.page = this.page + 1;
					this.searchProductResult(true);
					this.loading = true;
					this.searching = false;
				}
			} else {
				this.page = 0;
				this.searchResults = [];
				this.searchProductResult(false);
				this.loading = false;
				this.searching = true;
			}
		}
	}

	private searchProductResult(loading: boolean) {
		this.cdRef.markForCheck();
		this.selectTabSubject.next();
	}

	ngOnInit(): void {
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		
		if ("en" === locale) {
    		this.localeOption = "en-US";
	    } else if ("ch" === locale) {
	    	this.localeOption = "zh-TW";
	    } else if ("zh" === locale) {
	    	this.localeOption = "zh-CN";
	    }
		this.isMobileView = this.responsiveService.isMobileView();
		this.responsiveService.mobileViewEvent.subscribe(state => { this.isMobileView = state; });
		this.form = this.fb.group({
			query: ['']
		});

		this.form.valueChanges
			.pipe(
				filter((val: any) => val.query && val.query.length >= 1),
				debounceTime(500),
				tap((val: any) => {
					this.end = false;
					this.keyword = val.query;
					this.searchByProductType(false);
					
					if(this.isArticleRelatedCategory(this.category))
					{
						this.searchResults = [];
						this.resetArticleLoadData();
					}
				}),
				takeUntil(this.destroySubject)
			)
			.subscribe();

		// const searchOverlay: Element = document.getElementById('b2c-search-overlay');

		// if (searchOverlay) {
		// 	fromEvent(searchOverlay, 'click')
		// 		.pipe(
		// 			tap(() => {
		// 				this.form.get('query').reset();
		// 				this.searchResults = [];
		// 			}),
		// 			takeUntil(this.destroySubject)
		// 		)
		// 		.subscribe();
		// }

		this.selectTabSubject.pipe(
			switchMap(() => {
				return this.productSearchService.search(this.category, this.keyword, this.page, 20).pipe(
					tap((results: Array<IProductSearchModel>) => {
						let mostRelatedSearchResults: IProductSearchModel[] = this.sortResultListFollowingByMostRelatedMatched(this.keyword, results, true, null);
						let searchResults: IProductSearchModel[] = this.sortResultListFollowingByMostRelatedMatched(this.keyword, results, false, mostRelatedSearchResults);
						let finalResultList: IProductSearchModel[] = mostRelatedSearchResults.concat(searchResults);
						if (this.loading) {
							this.searchResults = this.searchResults.concat(finalResultList);
						} else {
							this.searchResults = finalResultList;
						}
						//this.searchResults = sort({key: 'productCode', value: 'ascend'}, this.searchResults);
						this.setResultListLabelAndColoraCode(this.searchResults);
						this.setProductTypeDisplay(this.searchResults);
						this.searching = false;
						this.loading = false;

						if (results.length < 20) {
							this.end = true;
							
							if(this.isArticleRelatedCategory(this.category))
							this.searchArticles(this.articleResultPage);
						}
						
						this.filterResultsForCategory(this.category);

						this.cdRef.markForCheck();
					})
				)
			})).subscribe();
	}

	ngOnDestroy(): void {
		this.destroySubject.next();
		this.destroySubject.complete();
	}

	public focusTypeaheadInput() {
		if (this.typeaheadInput) {
			setTimeout(() => {
				this.typeaheadInput.nativeElement.focus();
			}, 0);
		}
	}

	get queryField(): string {
		return this.form ? this.form.get('query').value : '';
	}

	onIndexChange() {
		this.page = 0;
		this.end = false;
		this.searchResults = [];
		this.searchByProductType(false);
	}

	searchByProductType(concat: boolean) {
		switch (this.selectedIndex) {
			case 0:				
				this.category = 'All';
				this.resetArticleLoadData();
				this.searchProduct(concat);
				break;
			case 1:
				this.category = 'UT';
				this.searchProduct(concat);
				break;
			case 2:
				this.category = 'BOND';
				this.searchProduct(concat);
				break;
			case 3:
				this.category = 'STOCK';
				this.searchProduct(concat);
				break;
			case 4:
				this.category = 'ETF';
				this.searchProduct(concat);
				break;
			case 5:
				this.category = 'ARTICLE';
				this.searching = true;
				this.resetArticleLoadData();
				this.searchArticles(this.articleResultPage);
				break;
//			case 6:
//				this.category = 'DPMS';
//				this.searchProduct(concat);
//				break;
//			case 7:
//				this.category = 'INSURANCE';
//				this.searchProduct(concat);
//				break;
//			case 8:
//				this.category = 'Article';
//				this.searchProduct(concat);
//				break;
//			case 9:
//				this.category = 'Faq';
//				this.searchProduct(concat);
//				break;
			default:
				return;
		}

	}

	setResultListLabelAndColoraCode(list: IProductSearchModel[]) {
		for (let data of Object.values(list)) {
			let colorData = this.productTypeColorList.filter(x => x.productType === data.productType)[0];
			data.productTypeDisplay = data.productType === 'UT' ? 'FUND' : data.productType === 'DPMS' ? 'MP' : data.productType.toUpperCase();
			data.colorCode = colorData.color;
			if (data.productType === 'STOCK' || data.productType === 'ETF' || data.productType === 'STOCK_LI') {
				let exchangeColorData = this.exchangeColorList.filter(x => x.exchange === data.exchange)[0];
				if (exchangeColorData) {
					data.exchangeColorCode = exchangeColorData.color;
				}
			}
		}
	}

	sortResultListFollowingByMostRelatedMatched(keyword: string, list: IProductSearchModel[], checkMostRelated: boolean, sortedResultList: IProductSearchModel[]) {
		let sortedList: IProductSearchModel[];
		if (checkMostRelated) {
			sortedList = list.filter(function(item) { return ((item.label && item.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) || (item.subtitle && item.subtitle.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)) ? true : false });
		} else {
			sortedList = list.filter(function(item) {
				let existData: boolean = false;
				sortedResultList.forEach(function(data) {
					if ((data.label && item.label && data.label === item.label) || (data.subtitle && item.subtitle && data.subtitle === item.subtitle)) {
						existData = true;
					}
				})
				return existData ? false : true;
			});
		}
		return sortedList;
	}
	
	setProductTypeDisplay(list: IProductSearchModel[]) {
		for (let data of Object.values(list)) {
			switch (data.productTypeDisplay) {
						case 'FUND':
							data.productTypeDisplay = 'fsmone.products.funds';
							break;
						case 'BOND':
							data.productTypeDisplay = 'bond';
							break;
						case 'STOCK':
							data.productTypeDisplay = 'stock';
							break;
						case 'ETF':
						case 'STOCK_LI':
							data.productTypeDisplay = 'fsmone.general.etfs';
							break;
						case 'ARTICLE':
							data.productTypeDisplay = 'articles';
							break;
					}
		}
	}

	clearSearchField() {
		this.selectedIndex = 0;
		this.form.patchValue({
			query: null
		});
		this.searchResults = [];
	}
	
	async searchArticles(page:number){
		this.searchString = this.keyword;
		this.searchCategory = this.category;
		
		if(!this.searching && this.searchResults.length!=0)
		this.loading = true;
		
		let articleResults:Array<IProductSearchModel> = [];
		
		const getArticleByKeywords = await this.productRestService.getArticlesByKeywords(this.keyword,page,20).toPromise();
		
		if(AppConstant.RESPONSE_SUCCESS === getArticleByKeywords.status)
		{
			getArticleByKeywords.data.forEach((value)=>
			{
				let articleResult: IProductSearchModel = 
				{
					...value,
					label: value.productName,
					publishedDate: value.publishedDate,
				}
				
				articleResults.push(articleResult);
			})																																		
		}
		
		this.lastSearchArticleResult=articleResults.length;
		
		this.setResultListLabelAndColoraCode(articleResults);
		this.setProductTypeDisplay(articleResults);
				
		this.searchResults = this.searchResults.concat(articleResults);
		this.articleResultPage++;
		
		this.filterResultsForCategory(this.category);
		
		this.loading = false;
		this.isArticleSearched = true;
		
		if(this.searching && this.category=="ARTICLE")
		this.searching= false;
	}
	
	resetArticleLoadData(){
		this.articleResultPage=1;
		this.lastSearchArticleResult=0;
		this.isArticleSearched=false;
	}
	
	isArticleRelatedCategory(category:string){
		if(category=="ARTICLE" || category=="All")
		return true;
		
		return false;
	}
	
	filterResultsForCategory(category:string)
	{
		switch(category){
			case 'All':
			break;
			
			case 'ETF':
			this.searchResults = this.searchResults.filter(x => x.productType === category || x.productType === "STOCK_LI");
			break;
						
			default:
			this.searchResults = this.searchResults.filter(x => x.productType === category);
		}
	}
}
