import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@api-module/api.endpoint.constant';
import {IResponse} from '@api-module/model/common/i-response';
import {HttpParamsService} from '@api-module/service/http-params.service';
import {PageModel} from '@api-module/model/pagination/page.model';
import {NotificationDtoModel} from '@api-module/model/notification/notification-dto.model';
import {GlobalDataStorage} from '@share/storages/global-data.storage';

@Injectable({
	providedIn: 'root'
})

export class NotificationRestService {
	readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.NOTIFICATION;
	constructor(private http: HttpClient, private paramService: HttpParamsService, private globalDataStorage:GlobalDataStorage
	) {
  	}

	findNotifications(uid: string) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/find-notifications',{},{params});
	}

	findNotificationsWithPagination(uid: string, pageSize: number, page: number){
		let locale = this.globalDataStorage.getStorage('locale') || 'ch'
		const params = this.paramService.buildHttpParams({uid, pageSize, page, locale});
		return this.http.post<IResponse<PageModel<NotificationDtoModel>>>(this.PROTECTED_BASE_URL + '/find-notifications-with-pagination', {}, {params});
	}

	markAllAsRead(uid: string) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<boolean>>(this.PROTECTED_BASE_URL + '/mark-all-as-read', {}, {params});
	}

	markOneAsRead(notificationId: number, accountId: string, deviceToken: string) {
		const params = this.paramService.buildHttpParams({notificationId, accountId, deviceToken});
		return this.http.post<IResponse<boolean>>(this.PROTECTED_BASE_URL + '/mark-one-as-read', {}, {params});
	}

	findNotificationBadgeByAccountId(){
		return this.http.get<IResponse<number>>(this.PROTECTED_BASE_URL + '/find-notification-badge-by-account-id');
	}

	updateBadge(){
		return this.http.post<IResponse<boolean>>(this.PROTECTED_BASE_URL + '/update-badge', {});
	}
}
