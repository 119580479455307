import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {RouteConstant} from 'src/app/constant';
import {headerSiteMap} from './header.menu';
import {PostMenu, AccountSetting, MobilePostMenu} from './post-login.menu';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '@auth-module/store';
import {tap} from 'rxjs/operators';
import {AuthService} from '@auth-module/service/auth.service';
import {TranslateService} from '@ngx-translate/core';
//import {B2CHeaderMetaModel} from 'b2c-ui/header';
import {HeaderMetaModel} from 'src/app/share/models/header/header.model';
import {IDropdownModel} from '@api-module/model/common/i-dropdown.model';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeCh from '@angular/common/locales/zh-Hant';
import localeZh from '@angular/common/locales/zh-Hans';
import {en_US, zh_TW, zh_CN, NzI18nService } from 'ng-zorro-antd';
import {getEnv} from '@share/services/product-search.service';
import { genRouterLink } from '@util/route';
import { PageService } from 'src/app/core/service/page.service';
import { ResponsiveService } from '@core/service/responsive.service';
import { GlobalState } from '@core/store';
import { Observable } from 'rxjs';

//test directive

@Component({
	selector: 'app-header-fsmone',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'w-100'
	},
})
export class FsmoneHeaderComponent implements OnInit {
	@Output() updatePostloginCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();

	readonly RouteConstant = RouteConstant;
	headerSiteMap = headerSiteMap;
	postMenu = PostMenu;
	accountSettingMenu = AccountSetting;
	openAccountModelVisibility: boolean = false;
	auth: boolean = false;
	auth2FA: boolean = false;
	authCas: boolean = false;
	langOptions: IDropdownModel[] = [] as IDropdownModel[];
	locale: string = 'en';
	localeDisplay: string = 'EN';
	_langOptions = [];
	mobilePostMenu = MobilePostMenu;
	loginLink: any = genRouterLink(RouteConstant.LOGIN);
	openAccountLink = genRouterLink(RouteConstant.ACCOUNT_OPENING);
	
	siteMap: boolean = false;
	search: boolean = false;
	isPostloginMenuCollapsed = true;
	isMobileView: boolean = false;
	fsmPostLoginWhitelist = false;

	@Select(GlobalState.getFsmPostLoginEnabled) fsmPostLoginEnabled$: Observable<boolean>;
	
  	constructor(
		private router: Router, 
		private store: Store,
		private globalDataStorage: GlobalDataStorage, 
		private i18n:NzI18nService, 
		private authService: AuthService, 
		private translateService: TranslateService, 
		private pageService: PageService, 
		private responsiveService: ResponsiveService
	) {
		this.initDefaultOptions();
		this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
		this.toggleShow(false);
		this._langOptions = this.langOptions.filter(({label}) => label !== this.localeDisplay);
	}

	toggleShow(refresh: boolean) {
		this.getLocaleDisplay(this.locale);
		this.translateService.use(this.locale);
		this.globalDataStorage.setStorage('locale', this.locale);
		if ("en" === this.locale) {
			registerLocaleData(localeEn);
			this.i18n.setLocale(en_US);
		} else if ("ch" === this.locale) {
			registerLocaleData(localeCh, 'zh-tw');
			this.i18n.setLocale(zh_TW);
		} else if ("zh" === this.locale) {
			registerLocaleData(localeZh, 'zh-cn');
			this.i18n.setLocale(zh_CN);
		}
		
		console.log("[Locale] Header toggle language : " + this.locale);		
		this.pageService.changeLangFsmone(this.locale);
		
		if (refresh)
			window.location.reload();
	}

	ngOnInit(): void {
		this.isMobileView = this.responsiveService.isMobileView();
		this.responsiveService.mobileViewEvent.subscribe(state => { this.isMobileView = state; });
		this.store.select(AuthState.isAuthenticated).pipe(
			tap((auth: boolean) => {
				this.auth = auth;
				this.fsmPostLoginWhitelist = auth;
			})
		).subscribe();
		this.store.select(AuthState.isCasAuthenticated).pipe(
			tap((authCas: boolean) => this.authCas = authCas)
		).subscribe();

		const isValid = this.authService.is2FAValid();

		if (isValid || this.authCas) {
			this.auth2FA = true;
		} else {
			this.auth2FA = false;
		}
	}

	initDefaultOptions(): void {
	    this.langOptions = [
	      {
	        label: 'EN',
	        value: 'en',
	      },
	      {
	        label: '繁',
	        value: 'ch',
	      },
	      {
	        label: '简',
	        value: 'zh',
	      }
	    ];
  	}

	getLocaleDisplay(target:string){
    	for (let item of this.langOptions) {
        	if (item.value === target) {
          		this.localeDisplay = item.label;
        	}
      	}
	}
	
	changeLanguage(lang:string){
		console.log(lang);	
		this.locale = lang;
		this.toggleShow(true);
	}
	
	activeLocale(locale:string) {
		if(this.locale == locale){
			return true;
		} else {
			return false;
		}
	}

  navigate(data: any) {
    if (!data) {
      return;
    }

    const commands: Array<string> = Array.isArray(data?.url)
      ? data?.url
      : data?.url
        ? [data?.url]
        : Array.isArray(data)
          ? data
          : [data];
    this.router.navigate(commands);
  }

  toggleAccountDialog() {
    this.openAccountModelVisibility = true;
  }

  closeAccountModel(value: boolean) {
    this.openAccountModelVisibility = value;
  }

  logout() {
    this.authService.logout();
  }

	navigateByUrl(path:string) {
		this.router.navigate([path]);
	}
	
	toggleSiteMap(){
		this.siteMap = !this.siteMap;
	}
	
	closeSearch(){
		this.search = false;
	}
	
	goToAccountOpening() 
	{
		let fullUrl = getEnv() + '/fsm' + '/account-opening/main';
		window.location.assign(fullUrl);
	}
		
	togglePostloginMenuCollapsed(isCollapsed): void {
		this.isPostloginMenuCollapsed = isCollapsed;
		this.updatePostloginCollapse.emit(this.isPostloginMenuCollapsed);
	}
}
