import {Component, Input, Output,OnInit} from "@angular/core";
import { SubHandlingService } from "@api-module/service/common/subscription-handling.service";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import {ArticleService} from "src/app/share/services/article.service";
import { getEnv } from '@share/services/product-search.service';
import {RouteConstant, ImagePathConstant} from 'src/app/constant';
import { Select, Store } from '@ngxs/store';
import { GlobalState } from '../../../../core/store';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'hk-event-card',
  templateUrl: './hk-event-card.component.html',
  providers: [SubHandlingService]

})
export class HkEventCardComponent implements OnInit {
	
	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
	
	@Input() event;
	
	showMobileWording = false;
	localeOption:string = "zh-TW";
	hitMaxMsgKey = 'fsmone.upcoming.event.hit.max.mesage';
	
	constructor(
	    private globalDataStorage : GlobalDataStorage,
		private articleService : ArticleService
  	) {
		this.setLocale();
  	}
	
	ngOnInit(): void {
	}
	
	navigateEvent(event){
	  	if (event.urlLink) {
			if(event.registrationStatus.toLowerCase() == 'concluded'){
				if(event.webcastsUrlLink) {
					let webcastId = event.webcastsUrlLink.substring(event.webcastsUrlLink.lastIndexOf('-') + 1);
					let route = RouteConstant.WEBCAST + '/' + RouteConstant.WEBCAST_DETAILS + '/' + webcastId;
					localStorage.setItem('event.link',route);
				}
				localStorage.setItem('event.status','concluded');
			} else {
				localStorage.setItem('event.status','register');
				localStorage.setItem('event.link',event.webinarLink);
			}
			localStorage.setItem('isEvent','true');
			localStorage.setItem('event',JSON.stringify(event));
			this.articleService.transformNavigateArticleUrl(event.urlLink);
	    }
  	}

	replayWebminar(event)
	{
	  	if (event.webcastsUrlLink) 
		{
			let webcastId = event.webcastsUrlLink.substring(event.webcastsUrlLink.lastIndexOf('-') + 1);
			let route = RouteConstant.WEBCAST + '/' + RouteConstant.WEBCAST_DETAILS + '/' + webcastId;
			window.open(route, '_blank', 'noreferrer');
	    }
	}

	registerEvent(event){
	    window.open(getEnv() + '/fsm/fsm-news/seminar-workshops-prelogin-registration/' + event.eventId, '_blank');
	}

	setLocale(){
		let locale = this.globalDataStorage.getStorage('locale') || 'ch';
		if ("en" === locale) {
			this.localeOption = "en-US";
		} else if ("ch" === locale) {
			this.localeOption = "zh-TW";
		} else if ("zh" === locale) {
			this.localeOption = "zh-CN";
		}
	}
}
