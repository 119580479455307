<div *ngIf="isVisible">
    <div class="mobile-full-screen-wrapper"
         [style.zIndex]="zIndex">
        <div #header class="mobile-fixed-top" [ngClass]="headerClass">
            <ng-content select="[header]"></ng-content>
        </div>
        <div #body class="scrollable-body">
            <ng-content select="[body]"></ng-content>
        </div>
        <div #footer class="mobile-fixed-bottom" [ngClass]="footerClass">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>
