<div class="bg-black-8 pb-5">
	<app-route-subheader class="text-white"  [isBreadcrumbOnly]="true"></app-route-subheader>
	<div [ngClass]="(isMobile$ | async)? 'px-5':'container'">
		<ng-container>
			<nz-skeleton [nzLoading]="loading" nzActive="true">
				<iframe class="w-100" 
					frameBorder="0" 
					height="405" allow="autoplay; encrypted-media" allowfullscreen
					[src]="safeUrl? safeUrl : null"
					[title]="articleDisplayModel.articleTitle">
				</iframe>
			</nz-skeleton>
		</ng-container>
	</div>
</div>
<div [ngClass]="(isMobile$ | async)? 'px-5 my-2':'container my-4'"> 
	<div nz-row class="mb-3">
		<div nz-col class="d-block" nzLg="16" nzMd="16" nzSm="24">
			<nz-skeleton [nzLoading]="loading" nzActive="true">
			
				<div class="py-2"><h3 [innerHTML]="articleDisplayModel.articleTitle"></h3></div>
				<div>{{articleDisplayModel.articleSynopsis}}</div>
				<div>
					<h6 class="pl-1" *ngIf="articleDisplayModel.articleAuthor">{{'fsmone.author.by' | translate}} {{articleDisplayModel.articleAuthor}}</h6>
					<h6>
						<i class="px-1" nz-icon nzType="calendar" nzTheme="outline"></i>{{ articleDisplayModel.articleLogDate | hkDate:localeOption }} | {{'video.duration'|translate}} : {{articleDisplayModel.videoLength}}
						<i class="px-1" nz-icon nzType="eye" nzTheme="outline"> </i> {{ articleDisplayModel.hitsFsm }}
					</h6>
			  </div>
				<span class="webcast-watch-video-description" [innerHtml]="articleDisplayModel.articleHtml | safeHtml"></span>
			
			</nz-skeleton>
		</div>
		<div nz-col class="d-block" nzLg="8" nzMd="8" nzSm="24">
			<nz-card class="my-2 mx-3">
				<nz-row>
					<nz-col nzSpan="24" class="mb-2">
						<app-bond-ranking-tab [tabOptions]="tabOptions" (clicked)="onClickPeriod($event)"></app-bond-ranking-tab>
					</nz-col>
				</nz-row>
				<nz-row>
					<nz-col nzSpan="24">
						<div *ngIf="!latest"><div *ngIf="related_res; else loadingTemplate"><ng-container *ngTemplateOutlet="videoArticlesTemplate; context:{articlesList: relatedArticles}"></ng-container></div></div>
						<div *ngIf="latest"><div *ngIf="latest_res; else loadingTemplate"><ng-container *ngTemplateOutlet="videoArticlesTemplate; context:{articlesList: latestArticles}"></ng-container></div></div>
					</nz-col>
				</nz-row>
			</nz-card>
		</div>
	</div>
	<div nz-row>
	 <div class="disclaimer d-block">
						<div *ngIf="articleDisplayModel.disclaimer">
							<span [innerHtml]="articleDisplayModel.disclaimer"></span>
						</div>
			
						<div class="text-sm" *ngIf="!articleDisplayModel.disclaimer">
							<h5>
								<span translate="fsmone.imptinfo.personalinfo.5.5.3.1"
									translate-default="All materials and contents found in this Site are strictly for information purposes only and should not be considered as an investment advice or recommendation, an inducement of offer, or solicitation, to deal in any of the funds or products found in this Site by IFHK or any of its relevant third party providers. Any forecasts or projections or forward looking statements made whether by IFHK or any other party in this Site are not necessarily indicative of future or likely performance, future events or future financial performance of products, countries, markets or companies. These statements are only predictions and actual events or results may differ. While certain tools available on the Site may provide general investment or financial analysis based upon your personalized input, such results are not to be construed as our providing investment recommendations or advices. Please make your own assessment of the relevance, accuracy and adequacy of the information contained in this Site."></span>
							</h5>
						</div>
					</div>
	</div>
</div>

<ng-template #videoArticlesTemplate let-articlesList="articlesList">
	<div *ngFor="let article of articlesList;let i = index" class="m-2">
		<a [routerLink]="gotoWebCast(article.articleno)">
			<nz-row [nzGutter]="12">
				<nz-col nzSpan="12">
					<img class="img-fluid" [src]="article.featuredImage">
				</nz-col>
				<nz-col nzSpan="12">
					<h5 class="font-weight-bold" [innerHtml]="article.title"></h5>
					<div *ngIf="!(isMobile$ | async)"><h6>{{'video.duration'|translate}} | {{article.videoLength}}</h6></div>
				</nz-col>
			</nz-row>
		</a>
		<hr class="my-3" *ngIf="i<articlesList.length-1">
	</div>
</ng-template>

<ng-template #loadingTemplate>
	<nz-skeleton nzLoading="true" nzActive="true"></nz-skeleton>
</ng-template>