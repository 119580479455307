<div class="container mb-5">
	<nz-row nzGutter="20" class="mb-3 d-flex flex-wrap">
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
			<ng-container
				*ngTemplateOutlet="Template; context: { method: FUNDS, text: 'fsmone.fee.funds', path: 'fund-2x' }">
			</ng-container>
		</nz-col>

		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
			<ng-container
				*ngTemplateOutlet="Template; context: { method: BONDS, text: 'fsmone.fee.bonds', path: 'bond-2x' }">
			</ng-container>
		</nz-col>
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
			<ng-container
				*ngTemplateOutlet="Template; context: { method: STOCKS, text: 'fsmone.stocks', path: 'stock-2x' }">
			</ng-container>
		</nz-col>
		<nz-col nzXs="8" nzMd="8" nzLg="4" class="mb-3">
			<ng-container
				*ngTemplateOutlet="Template; context: { method: INVEST, text: 'fsmone.menu.invest.101', path: 'dpms-2x' }">
			</ng-container>
		</nz-col>
	</nz-row>
</div>

<div *ngIf="selected == FUNDS">
	<div class="container">
		<div *ngIf="loading==false && rcmsLoading==false; else elseBlock">
			<div *ngTemplateOutlet="content"></div>
		</div>
	</div>
</div>
<div *ngIf="selected == BONDS">
	<div class="container">
		<div *ngIf="loading==false && rcmsLoading==false; else elseBlock">
			<div *ngTemplateOutlet="content"></div>
		</div>
	</div>
</div>
<div *ngIf="selected == STOCKS">
	<div class="container">
		<div *ngIf="loading==false && rcmsLoading==false; else elseBlock">
			<div *ngTemplateOutlet="content"></div>
		</div>
	</div>
</div>
<div *ngIf="selected == INVEST">
	<div class="container">
		<div *ngIf="loading==false && rcmsLoading==false; else elseBlock">
			<div *ngTemplateOutlet="content"></div>            
			<div *ngTemplateOutlet="investsArticle"></div>
		</div>
	</div> 
</div>

<ng-template #investsArticle>
	<div class="mb-6 pb-3">                                   
		<nz-card class="card-no-padding">
			<div nz-row nzAlign="middle" nzJustify="left" class="px-3 pt-3 pb-2">
				<div nz-col nzSpan="24" class="text-left text-md font-weight-bold mb-2"><span
						translate="fsmone.an.introduction.to.various.investment"
						translate-default="An Introduction To Various Investment Products"></span></div>
			</div>
			<nz-divider class="my-2"></nz-divider>
			<div nz-row nzAlign="middle" nzJustify="left">
				<nz-collapse nzAccordion nzBordered=false>
					<nz-collapse-panel nzHeader="1. {{'fsmone.overview' | translate}}">
						<div class="py-1 px-4">
							<nz-row nzGutter="20" class="py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<h5><span translate="learning.centre.various.investment.overview.text"
											translate-default="Before starting to invest, it would be good to get an idea of the different types of investment products out there! Some of these include stocks, bonds, money market securities, alternatives, ETFs and unit trusts (�funds�). To find out more about each of these investment products, browse their respective sections!"></span>
									</h5>
								</nz-col>
							</nz-row>
							<nz-row nzGutter="20" class="text-center py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<img [src]="SCHOOL_ARTICLE_IMAGES_PATH + 'overview-chart.png'"
										[alt]="'Overview Chart'">
								</nz-col>
							</nz-row>
						</div>
					</nz-collapse-panel>
				</nz-collapse>
			</div>

			<nz-divider class="my-1"></nz-divider>

			<div nz-row nzAlign="middle" nzJustify="left">
				<nz-collapse nzAccordion nzBordered=false>
					<nz-collapse-panel nzHeader="2. {{'general.stocks' | translate}}">
						<div class="py-1 px-4">
							<nz-row nzGutter="20" class="py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<h5><span translate="learning.centre.various.investment.stocks.line1"
											translate-default="Have you ever dreamt of owning a piece of SIA or DBS bank?"></span>
									</h5>
									<h5><span translate="learning.centre.various.investment.stocks.line2"
											translate-default="With stocks you can. Stocks (also known as �shares� or �equities�) are basically units of ownership of a company�you get a piece of every desk, filing cabinet, contract, sale in the company, and better yet, a slice of every dollar of profit that comes through the door. The more shares you buy, the bigger your stake in the company."></span>
									</h5>
									<h5><span translate="learning.centre.various.investment.stocks.line3"
											translate-default="Stocks are one of two traditional classes of investments and are one of the means by which companies obtain financing for their businesses. If a company sells 10 million of its shares at $1 each, then it is able to raise $10 million dollars for itself. Companies raise money for a variety of reasons, mostly to expand their businesses or to pay down debt."></span>
									</h5>

									<h4><span translate="learning.centre.how.is.the.price.of.a.stock.determined"
											translate-default="How Is The Price Of A Stock Determined?"></span></h4>
									<h5><span translate="learning.centre.how.is.the.price.of.a.stock.determined.desc1"
											translate-default="The prices of stocks are driven by the demand for them. If demand is high, stocks will be priced high. Conversely, if demand is low, the respective stocks will be priced low."></span>
									</h5>
									<h5><span translate="learning.centre.how.is.the.price.of.a.stock.determined.desc2"
											translate-default="What then, gives rise to the demand for stocks? Well, a huge factor is company earnings. Take for example, a company which makes $10 in profits every year is looking to be sold for $100 in shares. Would investors buy their shares?"></span>
									</h5>
									<h5><span translate="learning.centre.how.is.the.price.of.a.stock.determined.desc3"
											translate-default="Potential buyers would assess their situation with the question"></span>
									</h5>
									<h5><span translate="learning.centre.how.is.the.price.of.a.stock.determined.desc4"
											translate-default="Aside from opportunity costs, the expected future earnings growth of companies also drives the demand for their shares. Back to the same example, the company may be making $10 today, but it may be expected to make $20 next year. Thus, investors may be willing to pay $150 for the company shares even though that gives them only a 6.6% return on investment this year. This is as they expect to see even greater returns of 13.3% in the following year, and possibly more the year after. This is why it is possible that companies with comparatively lower earnings than their peers can still trade at higher prices due to their higher expected future earnings growth."></span>
									</h5>

									<h4><span translate="learning.centre.what.causes.volatility.in.stock.prices"
											translate-default="What Causes Volatility In Stock Prices?"></span></h4>
									<div class="text-center">
										<img [src]="SCHOOL_ARTICLE_IMAGES_PATH + 'stock-prices-chart.png'"
											[alt]="'Stock Price Chart'">
										<h5><span translate="learning.centre.company.earnings"
												translate-default="Company Earnings"></span></h5>
										<h6><span translate="learning.centre.which.affects.stock.prices"
												translate-default="(which affects Stock Prices)"></span></h6>
									</div>
									<h5><span translate="learning.centre.what.causes.volatility.in.stock.prices.desc1"
											translate-default="Factors that affect a stock's price can generally be separated into more 'macro' factors and more 'micro' factors. Many of these factors affect company earnings which in turn affect stock prices after the market has reacted to the factors."></span>
									</h5>
									<h5><span translate="learning.centre.what.causes.volatility.in.stock.prices.desc2"
											translate-default="'Macro' factors are factors that affect the whole economy or industry. Higher interest rates, inflation, national productivity levels, politics and such can have significant impacts on a company's earnings potential and so affect its share price. On an industry level, factors include a change in industry trends, legislation restrictions, the price and availability of raw materials, the exit or entry of rival companies and the advancement of substitute industries, to name a few."></span>
									</h5>
									<h5><span translate="learning.centre.what.causes.volatility.in.stock.prices.desc3"
											translate-default="'Micro' factors are factors that affect the company itself only. Management change, the company�s upcoming mergers and acquisitions, productivity of workers and such affect that individual company's earnings performance."></span>
									</h5>
									<h5><span translate="learning.centre.what.causes.volatility.in.stock.prices.desc4"
											translate-default="Fund managers and stock investors have to study both macro and micro factors to try and ascertain the profitability of a company, and determine the 'fair price' of its shares."></span>
									</h5>
									<h5><span translate="learning.centre.what.causes.volatility.in.stock.prices.desc5"
											translate-default="What causes volatility in prices is due to the different opinions and views of the future of a company's earnings growth. In a day when more people think that a company's future earnings may head downwards, there could be more selling pressure which leads to lower share prices. Naturally, the reverse happens as well (when increased optimism about a company�s prospects cause an upward movement in share price)."></span>
									</h5>

									<h4><span translate="learning.centre.what.are.stocks.markets"
											translate-default="What Are Stock Markets?"></span></h4>
									<h5><span translate="learning.centre.what.are.stocks.markets.desc1"
											translate-default="Stock markets are places where the stocks of companies are traded."></span>
									</h5>
									<h5><span translate="learning.centre.what.are.stocks.markets.desc2"
											translate-default="Companies which are offering their shares for sale for the first timedo this through an Initial Public Offer or IPO in the primary market. Investors looking to invest in IPOs will ask the same 2 questions outlined above�what are the companies� earnings potential, and whether there are other investments which might give them a better rate of return. If they decide that the companies� earnings potential is good, and can offer a better rate of return, they will invest in them.That is why many companies that offer IPOs usually price their stocks at attractive levels.."></span>
									</h5>
									<h5><span translate="learning.centre.what.are.stocks.markets.desc3"
											translate-default="After the IPO, the investors who have bought the stock can go back to the stock market and sell the stocks to other investors, so 'trading' of the stocks begin. A stock market is simply the clearing house for these 'trades'."></span>
									</h5>

									<h4><span translate="general.product.dividends"
											translate-default="Dividends"></span></h4>
									<h5><span translate="learning.centre.dividends.desc1"
											translate-default="Price increases, or capital appreciation, is not the only way you can make money on stocks. Many companies also pay yearly dividends. These are cash payments that represent a portion of profits. However, it is entirely up to the management of companies whether to pay out dividends or not as they are not obliged to. Often however, they will return a portion to reward their investors."></span>
									</h5>
								</nz-col>
							</nz-row>
						</div>
					</nz-collapse-panel>
				</nz-collapse>
			</div>

			<nz-divider class="my-1"></nz-divider>

			<div nz-row nzAlign="middle" nzJustify="left">
				<nz-collapse nzAccordion nzBordered=false>
					<nz-collapse-panel nzHeader="3. {{'bonds' | translate}}">
						<div class="py-1 px-4">
							<nz-row nzGutter="20" class="py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<h5><span translate="learning.centre.bonds.line1"
											translate-default="Have you ever forgotten to bring enough money and had to borrow from someone? There are many reasons as to why we need to borrow money but all of us have had to borrow money sometime in our lives."></span>
									</h5>
									<h5><span translate="learning.centre.bonds.line2"
											translate-default="Similarly, companies, and even governments, might need to borrow large sums of money from time to time and they do this by issuing bonds."></span>
									</h5>

									<h4><span translate="learning.centre.what.are.bonds"
											translate-default="What Are Bonds?"></span></h4>
									<h5><span translate="learning.centre.what.are.bonds.desc1"
											translate-default="Alongside Stocks, Bonds fall under the other traditional investment class called �Fixed Income�. As the name of their investment class says it, Bonds are a good source of regular/periodic, fixed income. They are basically loans where you are the lender and the companies or governments which issue the bonds (bond issuers) are the borrowers. Aside from promising to return you the borrowed amount (principal) at a certain date (maturity date), they will need to pay you interest (the interest payments are officially called 'coupons'), usually at regular intervals, until the maturity date. Aside from stocks, bonds are also a form of financing for bond issuers."></span>
									</h5>
									<h5><span translate="learning.centre.what.are.bonds.desc2"
											translate-default="The main difference between stocks and bonds is that the company guarantees to pay you back your principal, plus interest. You know exactly how much you are going to get back, and when you are going to get it."></span>
									</h5>
									<h5><span translate="learning.centre.what.are.bonds.desc3"
											translate-default="Many bonds are long-term loans/fixed income investments, with a maturities extending even to 30 years! Bonds with shorter maturities of around 3-5 years are often termed �short duration bonds�. Meanwhile, fixed income investments with less than 1 year to maturity are known as money market instruments."></span>
									</h5>

									<h4><span translate="learning.centre.how.risky.are.bonds"
											translate-default="How Risky Are Bonds?"></span></h4>
									<h5><span translate="learning.centre.how.risky.are.bonds.desc1"
											translate-default="Although the company whom you lent your money to guarantees to pay you back, it does not mean that Bonds are risk-free."></span>
									</h5>
									<h5><span translate="learning.centre.how.risky.are.bonds.desc2"
											translate-default="Companies, and even governments can, and do go bankrupt. However, when that happens, you (the bondholder) will be at the front of the creditors' line, while stockholders would be at the rear of it."></span>
									</h5>
									<h5><span translate="learning.centre.how.risky.are.bonds.desc3"
											translate-default="But perhaps the most risky thing to bondholders is rising inflation rate. When the economy is booming and unemployment rates are falling, that is when bonds and bondholders suffer the most (the reverse applies. Recessions are great for bonds!). Inflation causes prices of things in general to rise. This means that $10 in the future would be able to buy less goods and services than $10 now. So your fixed fee from bonds would buy you less if there was inflation."></span>
									</h5>
									<h5><span translate="learning.centre.how.risky.are.bonds.desc4"
											translate-default="Put into context, this also means that the money which you get back when your bond matures would be worth a lot less than what it is worth today when you loan it out. So the faster inflation rises, the faster your bond loses value."></span>
									</h5>

									<h4><span translate="interest.rates" translate-default="Interest Rates"></span></h4>
									<h5><span translate="learning.centre.interest.rates.desc1"
											translate-default="Interest rate is another thing bond investors watch out for. Rising interest rates cause bond prices to fall, and vice versa. This is because bonds pay a fixed coupon. As interest rates fall, people are willing to pay more money for the bond because its fixed coupon may represent a higher return than interest income. Conversely when interest rates rise, people are less willing to hold on to bonds because interest income would be higher than the fixed coupon, hence leading to a fall in bond prices (or what's called capital depreciation)."></span>
									</h5>

									<h4><span translate="bond.unit.trusts" translate-default="Bond Unit Trusts"></span>
									</h4>
									<h5><span translate="learning.centre.bond.unit.trusts.desc1"
											translate-default="If you see bonds as an essential part of your investment portfolio, but yet lack the time or investment capital to buy into local bonds (which can be very high in value), then you may opt to invest in a Bond Unit Trust instead. Bond unit trusts are basically a collection of different types of bonds. Instead of an individual bond, you are buying into a ready portfolio of bonds."></span>
									</h5>
									<h5><span translate="learning.centre.bond.unit.trusts.desc2"
											translate-default="A lot of people opt for bond unit trusts when they seek to diversify their investments with some fixed-income exposure. Even 'growth' investors invest in bonds as it helps to stabilise their portfolio returns."></span>
									</h5>
								</nz-col>
							</nz-row>
						</div>
					</nz-collapse-panel>
				</nz-collapse>
			</div>

			<nz-divider class="my-1"></nz-divider>

			<div nz-row nzAlign="middle" nzJustify="left">
				<nz-collapse nzAccordion nzBordered=false>
					<nz-collapse-panel nzHeader="4. {{'learning.centre.money.market.securities.title' | translate}}">
						<div class="py-1 px-4">
							<nz-row nzGutter="20" class="py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<h4><span translate="learning.centre.what.are.money.market.securities"
											translate-default="What Are Money Market Securities?"></span></h4>
									<h5><span translate="learning.centre.what.are.money.market.securities.desc1"
											translate-default="The Money Market, despite its name, is not a place where currencies are traded.Rather, it is where low-risk, highly liquid, short term debt issued by governments, financial institutions and corporations are traded.The maturities of these debt securities typically range from one day to one year, but are often less than 90 days. Examples of money market securities include short-term debt backed by governments such as the treasury bills(�T-Bills�), commercial papers, bankers� acceptances, certificates of deposits and repurchase agreements.Due to the ease of transacting and the short-term nature of these instruments, the money market is typically seen as a safe place for investors to park money for a short period of time."></span>
									</h5>

									<h4><span translate="learning.centre.how.they.work"
											translate-default="How They Work"></span></h4>
									<h5><span translate="learning.centre.how.they.work.desc1"
											translate-default="Generally, money market instruments are priced at a 'discount' to their face values and, unlike bonds, typically do not pay out coupons. For example, a U.S. Treasury Bill with 90 days left to maturity may have a face value of $100,000, but is selling in the money market at $98,500. If an investor buys the T-Bill at this price, he would be able to redeem the bill from the U.S. government 90 days later when the bill matures at its face value ($100,000) and make a profit of $1,500 ($1,500 out of an investment of $98,500 over 90 days is equal to an annualised return of about 6% per annum).Such short term money market securities that are issued by large corporations and banksare referred to as commercial papers and bankers� acceptances respectively."></span>
									</h5>
									<h5><span translate="learning.centre.how.they.work.desc2"
											translate-default="Money market securities are generally considered to be very low risk securities.Apart from their short-term nature, the debt issuers of these securities are typically also of high credit quality with low default rates, leading returns on these securities to be usually perceived to be �guaranteed�! The U.S. Treasury Bills which are guaranteed by the U.S. government, are practically perceived to be default free and the 'discount rate' of these T-Bills (in our above example, 6%) are usually used as the proxy for the 'risk-free' rate of return. This is the rate of return frequently used in calculating the return an investment rewards investors for each unit of risk undertaken (such a measure is known as the Sharpe Ratio). In general, most investments are expected to offer a higher rate of return than T-Bills which are risk-free."></span>
									</h5>
									<h5><span translate="learning.centre.how.they.work.desc3"
											translate-default="Given their low risks, money market securities provide one of the lowest potential returns in the investment universe and are thus often known as cash-equivalents. They form a great avenue to deposit money for a short period of time whilst gaining higher rates than that of fixed deposits but without losing the benefits of liquidity. Thus, they are typically great for risk-averse investors who are just starting on their investment journey! That being said, even for investors who are more seasoned, having a portion of one�s investment portfolio allocated to money market funds is still a considerable move given that this can potentially enhancethe stability of overall portfolio returns and provide greater liquidity in times of market uncertainty and volatility and emergencies where a great amount of cash is required."></span>
									</h5>

									<h4><span translate="learning.centre.investing.in.money.markets"
											translate-default="Investing In Money Markets"></span></h4>
									<h5><span translate="learning.centre.investing.in.money.markets.desc1"
											translate-default="With many money market securities trading in very high denominations, they are often difficult for the average investor to gain access to. Thus, one of the easiest ways for an investor to gain access to the money market is to buy a money market unit trust or fund! Fret not, at Fundsupermart, one can find a good range of money market unit trusts to cater to investor preferences towards the money market! Given the stability of the returns offered by money market securities, you will likely find that some of these unit trusts have performed very consistently."></span>
									</h5>
								</nz-col>
							</nz-row>
						</div>
					</nz-collapse-panel>
				</nz-collapse>
			</div>

			<nz-divider class="my-1"></nz-divider>

			<div nz-row nzAlign="middle" nzJustify="left">
				<nz-collapse nzAccordion nzBordered=false>
					<nz-collapse-panel nzHeader="5. {{'fsmone.alternative.investments' | translate}}">
						<div class="py-1 px-4">
							<nz-row nzGutter="20" class="py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<h4><span translate="learning.centre.what.are.alternative.investments"
											translate-default="What Are Alternative Investments?"></span></h4>
									<h5><span translate="learning.centre.what.are.alternative.investments.desc1"
											translate-default="Alternative investments fall outside of the common long-only investments in stocks, bonds, and cash. There are many investments that fall under this category, with the most common being commodities, real estate, hedge funds and private equity funds. These will be covered in detail in"></span><a
											(click)="goToAlternativeInvestment()"><span
												translate="investment.alternative.investments"
												translate-default="Alternative Investments"></span></a></h5>

									<h4><span translate="learning.centre.benefits.and.risks"
											translate-default="Benefits & Risks"></span></h4>
									<h5><span translate="learning.centre.benefits.and.risks.desc1"
											translate-default="The returns on alternative investments have generally shown to state low correlations with that on traditional investments, which may reduce an investor�s overall portfolio volatility. Furthermore, they often offer investors higher returns than that of traditional investments. This however, also means that they are riskier investments. While they tend to be less efficiently priced, thus providingmore opportunities for skilled managers to make a profit, they are also generally less liquid and often employ the use of leverage.There tends to be less data available on alternative investments as well, which may pose a challenge to due diligence."></span>
									</h5>
									<h5><span translate="learning.centre.benefits.and.risks.desc2"
											translate-default="While the potential benefits of including alternative investment products in one�s portfolio are plentiful, determining the appropriate allocations of alternative investments in the portfolio is not as straight-forward as one would like to think. Several considerations such as the historical volatility and maximum drawdowns may need to be assessed in order to better allocate the weights of alternative investments products."></span>
									</h5>

									<h4><span
											translate="learning.centre.how.do.i.gain.access.to.alternative.investments"
											translate-default="How Do I Gain Access To Alternative Investments?"></span>
									</h4>
									<h5><span
											translate="learning.centre.how.do.i.gain.access.to.alternative.investments.desc1"
											translate-default="Given the complexity of alternative investments as well as their high minimum investment amount (particularly hedge funds and private equity funds), alternative investments are often viewed to be suitable only for sophisticated investors. Some of these type of investments however, can be accessed by most investors through synthetic ETFs and alternative investment unit trusts, for which the Fundsupermart platform offers a good selection!"></span>
									</h5>
								</nz-col>
							</nz-row>
						</div>
					</nz-collapse-panel>
				</nz-collapse>
			</div>

			<nz-divider class="my-1"></nz-divider>

			<div nz-row nzAlign="middle" nzJustify="left">
				<nz-collapse nzAccordion nzBordered=false>
					<nz-collapse-panel nzHeader="6. {{'fsmone.etfs.and.unit.trusts' | translate}}">
						<div class="py-1 px-4">
							<nz-row nzGutter="20" class="py-3 d-flex flex-wrap">
								<nz-col nzXs="24" nzMd="24" nzLg="24">
									<h5><span translate="learning.centre.various.investment.etfs.ut.line1"
											translate-default="Despite the wide array of investment instruments available in the investment universe, one may find that it is difficult for him to invest in certain investment products due to capital, accessibility, or time constraints, thus limiting his investable universe. However, with the introduction of ETFs and unit trusts, the impact of this problem has increasingly been reduced. What exactly are ETFs and unit trusts then? Read on to find out!"></span>
									</h5>

									<h4><span translate="learning.centre.what.are.etfs.and.ut"
											translate-default="What Are ETFs & Unit Trusts?"></span></h4>
									<h5><span translate="learning.centre.what.are.etfs.and.ut.desc1"
											translate-default="In the previous sections we have covered various investment products such as stocks, bonds, money markets, and alternative investments. ETFs or unit trusts are simply portfolioscomprised of several of these individual investment products; hence they are also termed �Collective Investment Schemes (CIS)�.Similar to stocks where you own a portion of a company when you purchase some of its stocks, you own a portion of the ETF�s or unit trust�s portfolio when you purchase some of the ETF�s or unit trust�s units! While ETFs trade like stocks on the stock exchange, unit trust units are only bought from and sold to the respective fund managers."></span>
									</h5>
									<h5><span translate="learning.centre.what.are.etfs.and.ut.desc2"
											translate-default="When investors invest in ETFs or unit trusts, their money is pooled together and used to invest in securities in accordance to the funds� objectives. ETFs typically aim to replicate indices and are passively managed, while unit trusts generally aim to outperform indices and are actively managed. Given that markets can be dynamic and unpredictable, there are times when ETFs outperform unit trusts and unit trusts outperform ETFs. Thus, it is reasonable to conclude that neither is a �better� investment product, and that ultimately, an investor�s preference (should he have one) of one product over the other is usually dependent the factors he deems more important given that both types of funds bring different benefits to the table!For an easier comparison between both products, we have listed thebenefits and drawbacks as per the table below!"></span>
									</h5>

									<nz-table [nzData]="['']" nzShowPagination="false" class="py-5">
										<thead>
											<tr>
												<th></th>
												<th width="43%" class="text-white text-center bg-etf"><span
														translate="general.etfs" translate-default="ETFs"></span></th>
												<th width="43%" class="text-white text-center bg-fund"><span
														translate="general.unit.trusts"
														translate-default="Unit Trusts"></span></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><span translate="benefits" translate-default="Benefits"></span></td>
												<td>
													<h5><span translate="lower.costs"
															translate-default="Lower costs"></span></h5>
													<hr class="hr-hr-dashed">
													<h5><span translate="intraday.pricing"
															translate-default="Intraday pricing"></span></h5>
													<hr class="hr-dashed">
													<h5><span translate="learning.centre.benefits.line3"
															translate-default="Risks generally do not significantly exceed that of the underlying index."></span>
													</h5>
													<hr class="hr-dashed">
													<h5><span translate="learning.centre.benefits.line4"
															translate-default="Investors have greater certainty over component securities given that ETFs typically replicate indices. This is unlike unit trusts, where the decision to include or exclude certain securities from the portfolio is usually at the discretion of the fund manager."></span>
													</h5>
												</td>
												<td>
													<h5><span translate="learning.centre.utilise.professional.expertise"
															translate-default="Utilise professional expertise"></span>
													</h5>
													<hr class="hr-dashed">
													<h5><span
															translate="learning.centre.utilise.professional.expertise.line1"
															translate-default="Higher flexibility in selection of portfolio securities"></span>
													</h5>
													<hr class="hr-dashed">
													<h5><span
															translate="learning.centre.utilise.professional.expertise.line2"
															translate-default="Potentially higher returns beyond that of related indices"></span>
													</h5>
													<hr class="hr-dashed">
													<h5><span
															translate="learning.centre.utilise.professional.expertise.line3"
															translate-default="Unit price typically reflects the market value of underlying securities given that it is derived from the NAV of the fund."></span>
													</h5>
												</td>
											</tr>
											<tr>
												<td><span translate="drawbacks" translate-default="Drawbacks"></span>
												</td>
												<td>
													<h5><span translate="learning.centre.drawbacks.line1"
															translate-default="Returns typically do not exceed that of the underlying index"></span>
													</h5>
													<hr class="hr-dashed">
													<h5><span translate="learning.centre.drawbacks.line2"
															translate-default="Unit price may not reflect the market value of underlying securities. Should there be strong demand for the ETF units, unit price may be higher than the NAV of the fund."></span>
													</h5>
												</td>
												<td>
													<h5><span translate="higher.costs"
															translate-default="Higher costs"></span></h5>
													<hr class="hr-dashed">
													<h5><span translate="learning.centre.drawbacks.line4"
															translate-default="Possibility of risks exceeding related indices"></span>
													</h5>
												</td>
											</tr>
										</tbody>
									</nz-table>

									<h4><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors"
											translate-default="Why ETFs & Unit Trusts May Appeal To Investors"></span>
									</h4>
									<h5><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors.text"
											translate-default="Here are 5 reasons as to why ETFs and unit trusts appeal to investors in general:"></span>
									</h5>
									<ol>
										<li><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors.line1"
												translate-default="Diversification benefits. Given that they are portfolios, they essentially provide an investor with an exposure to several instruments at once, thus providing the investor with better diversification through a single investment.This may make forming well-diversified portfolios a more attainable goal given how tedious and time consuming it may be should an investor decide to create a diversified portfolio out of solely individual securities. Aside from time constraints, capital and accessibility constraints may also prevent investors from achieving the same level as ETFs or unit trusts given the number of component securities they can potentially hold."></span>
										</li>
										<li><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors.line2"
												translate-default="Access to a wider range of products that you would not have or may have difficulties gaining access to as an individual investor. Due to the size of trades that ETFs and unit trusts are more capable of carrying out as well as the fact that they are perceived to be more ‘sophisticated’ (particularly for unit trusts) as compared to retail investors, these funds are able to gain access to money market instruments and more complex investment products such as certain alternative assets which are not accessible by retail investors."></span>
										</li>
										<li><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors.line3"
												translate-default="Small minimum investment amount. The minimum investment amount starts from a mere $500 or $1000. You can also start a regular savings plan by investing a smaller fixed amount monthly, starting from just $100 a month.  With unit trust, you can use a small sum to buy into a well-diversified portfolio while stocks cost much more for just one lot."></span>
										</li>
										<li><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors.line4"
												translate-default="Simplifies investing for investors who are unsure of how to select individual securities or do not have the luxury of time to do so. By investing in an ETF, an investor is essentially investing in an entire index. Unit trusts on the other hand offer a more active management, where investment professionals select the securities on behalf of investors. Both investment products are great for investors who are just starting on their investment journey and may be unsure of how to select securities, as well as busy investors who may not have the time to select and invest in individual securities!"></span>
										</li>
										<li><span translate="learning.centre.why.etfs.ut.may.appeal.to.investors.line5"
												translate-default="Lower risks than individual securities. Should you be concerned about volatility, you can choose a fixed income ETF or unit trust that can give pretty stable returns. Generally, over the medium to long term, these still perform better than your fixed deposits."></span>
										</li>
									</ol>
								</nz-col>
							</nz-row>
						</div>
					</nz-collapse-panel>
				</nz-collapse>
			</div>
		</nz-card>
	</div>
</ng-template>


<ng-template #content>
	<nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="pb-3">
    <nz-col [nzXs]="10" [nzSm]="10" [nzMd]="12" [nzLg]="15">
      <h3 class="pt-3"><span translate="articles" translate-default="Articles"></span></h3>
    </nz-col>
    <nz-col  [nzXs]="14" [nzSm]="14" [nzMd]="12" [nzLg]="9">
      <!-- <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
        <input type="text" nz-input placeholder="{{'fsmone.search' | translate}}" [(ngModel)]="searchValue"
          (ngModelChange)="search()" />
      </nz-input-group> -->
		<nz-select class="w-100" [(ngModel)]="selectedCategory" (ngModelChange)="onChangeCategory()">
			<nz-option nzValue="all" [nzLabel]="'fsmone.all' | translate"></nz-option>
			<nz-option nzValue="beginner" [nzLabel]="'fsmone.bonds.school.beginner' | translate"></nz-option>
			<nz-option nzValue="advanced" [nzLabel]="'fsmone.bonds.school.advanced' | translate"></nz-option>
		</nz-select>
    </nz-col>
	</nz-row>

	<nz-row [nzGutter]="(isMobile$ | async) ? 0 : 16" nzType="flex">
		<nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="6" *ngFor="let article of articleDisplay; last as isLast"
			[ngClass]="{'mb-3': !(isMobile$ | async)}">
			<a [href]="getArticleDetailsUrl(article?.articleId?.toString())" target="_blank">
			<article class="pb-5"
				[horizontal]="(isMobile$ | async)" [level]="article.category" [title]="article.title"
				[image]="article.featuredImage" [author]="article.author" [date]=null [view]=null></article>
			</a>
		</nz-col>
	</nz-row>
</ng-template>

<ng-template #Template let-method="method" let-text="text" let-path="path">
	<nz-card class="card-select-box h-100" [ngClass]="{'active': selected == method}" nzHoverable role="button"
		(click)="changeCategory(method)">
		<div class="text-center">
			<img height="50" [src]="PRODUCTS_ICON_IMAGES_PATH + path + '.png'" [alt]="text + ' Icon'">
			<h5 class="mt-2 mb-0 font-weight-bold text-uppercase">{{ text | translate }}</h5>
		</div>
	</nz-card>
</ng-template>

<ng-template #plusCircleIcon>
	<i nz-icon class="text-info px-2" nzType="plus-circle" nzTheme="fill"> </i>
</ng-template>

<ng-template #minusCircleIcon>
	<i nz-icon class="text-info px-2" nzType="minus-circle" nzTheme="fill"> </i>
</ng-template>


<ng-template #prefixIconSearch>
	<i nz-icon nzType="search"></i>
</ng-template>

<ng-template #suffixIconClose>
	<i nz-icon nzType="close" nzTheme="outline"></i>
</ng-template>

<ng-template #elseBlock>
	<nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>