export class PasswordSettingConstant {
  public static readonly PWD_REGEX: RegExp = /^(?=[A-Za-z0-9@!"'\(\)*%^&#\$,+-\.\/:;<=>?@\[\]\\\^_`\{\}|\~]{12,20}$)(?=.*\d)(?=.*[A-Za-z]).*$/m;
  public static readonly SPEC_CHAR_REGEX: RegExp = /[@!"'\(\)*%^&#\$,+-\.\/:;<=>?@\[\]\\\^_`\{\}|\~]/;
  public static readonly PDF_PWD_REGEX: RegExp = /(?=.*[a-zA-Z])(?=.*[0-9])/;
  public static readonly MIN_PASSWORD_LENGTH: number = 12;
  public static readonly MAX_PASSWORD_LENGTH: number = 20;
  public static readonly MIN_PDF_PW_LENGTH: number = 8;
  public static readonly MAX_PDF_PW_LENGTH: number = 20;
  public static readonly NEW_PWD_REGEX: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!"'()*%^&#$,+\-.\/:;<=>?@[\]\\^_`{|}~])[A-Za-z0-9@!"'\(\)*%^&#\$,+-\.\/:;<=>?@\[\]\\\^_`\{\}|\~]{12,20}$/m;
}
