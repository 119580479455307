import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Pipe({
    name: 'millionDividerFactsheet'
})

@Injectable({
    providedIn: 'root'
})

export class MillionDividerFactsheetPipe implements PipeTransform {

    constructor(private translateService: TranslateService, private globalDataStorage: GlobalDataStorage) {
    }

    transform(value: any, withWord: boolean, includeBillion: boolean, shortForm?: boolean): any {
        var selectedLocale = this.globalDataStorage.getSessionStorage('locale') || 'en';
        if (selectedLocale == "en") {
            if (includeBillion) {
                if (value > 1000000000 || value < -1000000000) {
                  if (withWord) {
                    if (shortForm) {
                        return isNaN(value) ? '-' : (value/ 1000000000).toFixed(2) + 'B';
                    } else {
                        return isNaN(value) ? '-' : (value/ 1000000000).toFixed(2) + " " + this.translateService.instant('billion');
                    }
                  } else {
                    return isNaN(value) ? '-' : (value/ 1000000000).toFixed(2);
                  }
                } else {
                    if(withWord) {
                        if (shortForm) {
                            return isNaN(value) ? '-' : (value/ 1000000).toFixed(2) + 'M';
                        } else {
                            return isNaN(value) ? '-' : (value/ 1000000).toFixed(2) + " " + this.translateService.instant('million');
                        }
                    } else {
                        return isNaN(value) ? '-' : (value/ 1000000).toFixed(2);
                    }
                }
            } else {
                if(withWord) {
                    if (shortForm) {
                        return isNaN(value) ? '-' : (value/ 1000000).toFixed(2) + 'M';
                    } else {
                        return isNaN(value) ? '-' : (value/ 1000000).toFixed(2) + " " + this.translateService.instant('million');
                    }
                } else {
                    return isNaN(value) ? '-' : (value/ 1000000).toFixed(2);
                }
            }
        } else {
            if (includeBillion) {
                if (value > 100000000 || value < -100000000) {
                  if (withWord) {
                    if (shortForm) {
                        return isNaN(value) ? '-' : (value/ 100000000).toFixed(2) + "B";
                    } else {
                        return isNaN(value) ? '-' : (value/ 100000000).toFixed(2) + " " + this.translateService.instant('billion');
                    }
                  } else {
                    return isNaN(value) ? '-' : (value/ 100000000).toFixed(2);
                  }
                } else {
                    if(withWord) {
                        if (shortForm) {
                            return (value/ 100000).toFixed(2) + "M";
                        } else {
                            return (value/ 100000).toFixed(2) + " " + this.translateService.instant('million');
                        }
                    } else {
                        return isNaN(value) ? '-' : (value/ 100000).toFixed(2);
                    }
                }
            } else {
                if(withWord) {
                    if (shortForm) {
                        return isNaN(value) ? '-' : (value/ 10000).toFixed(2) + "M";
                    } else {
                        return isNaN(value) ? '-' : (value/ 10000).toFixed(2) + " " + this.translateService.instant('million');
                    }
                } else {
                    return isNaN(value) ? '-' : (value/ 10000).toFixed(2);
                }
            }
        }
        
    };
}