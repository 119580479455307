import { isArray, isObject } from 'util';
import {from, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    constructor() {
    }

    /**
     * Deep copy function
     * Properties: Yes
     * Methods: No
     * Deep Copy: Yes
     * @param obj
     */
    deepCopy(object: any | any[]) {
        return JSON.parse(JSON.stringify(object));
    }

    /**
     * Mapping for dynamic. Pass By Reference
     * @param destination
     * @param source
     * @param matchKey
     * @param mappingKey
     */
    dynamicHeaderMapping(destination: any[], source: any[], matchKey?: string, mappingKey?: string) {
        // Handle the 3 simple types, and null or undefined
        if (null == source || 'object' !== typeof source) {
            return destination;
        }

        if (!(source instanceof Array) && !(destination instanceof Array)) {
            throw new Error('Incompatible objects!');
        } else {
            destination.forEach(A =>
                source.forEach(B => {
                    if (A[matchKey] === B[matchKey]) {
                        A[mappingKey] = B[mappingKey];
                    }
                })
            );

            return destination.sort((a, b) => source.findIndex(item => a.value === item.value) - source.findIndex(item => b.value === item.value));
        }
    }

    /**
     * sort array via single
     * @param sortAttribute {key: property of the object, value: 'ascend' or 'descend'}
     * @param inputData array to be sort
     * @param opts Optional Configuration eg. customOrderByKeys for single column multiple value
     */
    sort(sortAttribute: { key: string; value: string }, inputData: any[], opts?: { customOrderByKeys: Array<string>}) {
        let dataArr = this.deepCopy(inputData);
        if (!sortAttribute || sortAttribute.key === '' || sortAttribute.value === null) {
            return dataArr;
        }

        let outputDataList = dataArr.sort((a, b) => {
            let isAsc = sortAttribute.value === 'ascend';
            switch (sortAttribute.key) {
                case sortAttribute.key:
                    if (opts && opts.customOrderByKeys) {
                        let result = 0;
                        let index = 0;
                        while (result === 0 && (index <= opts.customOrderByKeys.length - 1)) {
                            let currentCompareKey = opts.customOrderByKeys[index];
                            result = this.compare(
                                typeof a[currentCompareKey] !== 'string' ? a[currentCompareKey] : a[currentCompareKey].toUpperCase(),
                                typeof b[currentCompareKey] !== 'string' ? b[currentCompareKey] : b[currentCompareKey].toUpperCase(),
                                isAsc
                            );
                            index++;
                        }
                        return result;
                    } else {
                        return this.compare(
                            typeof a[sortAttribute.key] !== 'string' ? a[sortAttribute.key] : a[sortAttribute.key].toUpperCase(),
                            typeof b[sortAttribute.key] !== 'string' ? b[sortAttribute.key] : b[sortAttribute.key].toUpperCase(),
                            isAsc
                        );
                    }
                default:
                    return 0;
            }
        });
        return outputDataList;
    }

    customSort(sortAttribute: { key: string; value: string }, inputData: any[], customOrder?: any[]){
        if(!customOrder){
            return this.sort(sortAttribute, inputData);
        } else{
            let dataArr = this.deepCopy(inputData);
            if (!sortAttribute || sortAttribute.key === '' || sortAttribute.value === null) {
                return dataArr;
            }
            let isAsc = sortAttribute.value === 'ascend';
            let outputDataList = dataArr.sort((a, b) => {
                return customOrder.indexOf(a[sortAttribute.key])-customOrder.indexOf(b[sortAttribute.key]);
            });
            if(!isAsc){
                outputDataList.reverse();
            }
            return outputDataList;
        }
    }

    sortMappedList(sortAttribute: { key: string; value: string }, inputData: any[]) {
        let dataArr = this.deepCopy(inputData);
        if (!sortAttribute || sortAttribute.key === '' || sortAttribute.value === null) {
            return dataArr;
        }

        let outputDataList = dataArr.sort((a, b) => {
            let isAsc = sortAttribute.value === 'ascend';
            switch (sortAttribute.key) {
                case sortAttribute.key:
                    return this.compare(
                        typeof a[sortAttribute.key].value !== 'string' ? a[sortAttribute.key].value : a[sortAttribute.key].value.toUpperCase(),
                        typeof b[sortAttribute.key].value !== 'string' ? b[sortAttribute.key].value : b[sortAttribute.key].value.toUpperCase(),
                        isAsc
                    );
                default:
                    return 0;
            }
        });
        return outputDataList;
    }

    /**
     * sort array via multiple property, prioritise by whichever comes first in sortAttributes
     * @param sortAttributes {key: property of the object, value: 'ascend' or 'descend' (defaults to asc)}[]
     * @param inputData
     */
    sortMultiField(sortAttributes: { key: string, value?: string, customOrder?: any[] }[], inputData: any[]) {
        let dataArr = this.deepCopy(inputData);
        if (!sortAttributes || sortAttributes.length < 1) {
            return dataArr;
        }

        let outputDataList = dataArr.sort((a, b) => {
            for (let sortAttribute of sortAttributes) {
                let isAsc = sortAttribute.value !== 'descend';
                let compareResult;
                switch (sortAttribute.key) {
                    case sortAttribute.key:
                        if (sortAttribute.customOrder) {
                            compareResult = sortAttribute.customOrder.indexOf(a[sortAttribute.key])-sortAttribute.customOrder.indexOf(b[sortAttribute.key]);
                        } else {
                            compareResult = this.compare(
                                typeof a[sortAttribute.key] !== 'string' ? a[sortAttribute.key] : a[sortAttribute.key].toUpperCase(),
                                typeof b[sortAttribute.key] !== 'string' ? b[sortAttribute.key] : b[sortAttribute.key].toUpperCase(),
                                isAsc
                            );
                        }
                        break;
                    default:
                        compareResult = 0;
                }
                if (compareResult !== 0) {
                    return compareResult;
                }
            }
        });
        return outputDataList;
    }

    sortNestedObject(sortAttribute: { key: string, value?: string }, property: string, inputData: any[]) {
        let dataArr = this.deepCopy(inputData);
        if (!sortAttribute || sortAttribute.key === '' || sortAttribute.value === null || property === null) {
            return dataArr;
        }

        let properties = property.split('.');
        let outputDataList = dataArr.sort((a, b) => {
            let aChild = a;
            let bChild = b;
            properties.forEach(_property => {
                aChild = aChild ? aChild[property] : null;
                bChild = bChild ? bChild[property] : null;
            })
            let isAsc = sortAttribute.value === 'ascend';
            switch (sortAttribute.key) {
                case sortAttribute.key:
                    return this.compare(
                        aChild !== null ? typeof aChild[sortAttribute.key] !== 'string' ? aChild[sortAttribute.key] : aChild[sortAttribute.key].toUpperCase() : null,
                        bChild !== null ? typeof bChild[sortAttribute.key] !== 'string' ? bChild[sortAttribute.key] : bChild[sortAttribute.key].toUpperCase() : null,
                        isAsc
                    );
                default:
                    return 0;
            }
        });
        return outputDataList;
    }

    /**
     * if isAsc is true
     * a > b    = 1
     * a === b  = 0
     * a < b    = -1
     *
     * if isAsc is false
     * a > b    = -1
     * a === b  = 0
     * a < b    = 1
     *
     * @param a
     * @param b
     * @param isAsc
     */
    private compare(a, b, isAsc: boolean) {
        // null value is - (dash)
        if (!a && a != 0) a = '-';
        if (!b && b != 0) b = '-';

        // whole number is date
        // if (typeof a == 'number' && a % 1 == 0) a = formatDate(a, 'yyyy-MM-dd HH:mm:ss', 'en');
        // if (typeof b == 'number' && b % 1 == 0) b = formatDate(b, 'yyyy-MM-dd HH:mm:ss', 'en');
        if (a === b) return 0;
        // nulls sort after anything else
        if (a === '-') return 1;
        if (b === '-')return -1;

        // to avoid comparing between string and number
        if(a !== b && !isNaN(a) && !isNaN(b)) return (parseFloat(a) < parseFloat(b) ? -1 : 1) * (isAsc ? 1 : -1);

        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    preventNull(value, defaultValue?: string): string {
        return value ? value : (defaultValue ? defaultValue : '');
    }

    /* To replace all occurrences */
    replaceAllOccurrences(str: string, find: string, replace: string): string {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    groupBy(xs, key) {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    /**
     * Flatten map by removing all unnecessary 'value' key from map
     * @param obj
     */
    flattenFormValue(obj: any): any {
        if (typeof obj !== 'undefined' && obj != null && typeof obj === 'object') {
            if (isArray(obj)) {
                for (let _obj of obj) {
                    _obj = this.flattenFormValue(_obj);
                }
                return obj;
            } else {
                if (obj.value !== undefined) {
                    return obj.value;
                }
                for (let key of Object.keys(obj)) {
                    let val = obj[key];
                    obj[key] = this.flattenFormValue(val);
                }
                return obj;
            }
        } else {
            return obj;
        }
    }

    getLastDay(monthYear: string){
        let month = this.getMonthFull(monthYear.split(' ')[0].replace(/ /g, ''));
        let year = monthYear.split(' ')[1].replace(/ /g, '');
        let date;

        switch(month){
            case 'January':
                date = 31;
                break;
            case 'February':
                parseInt(year)%4 === 0 ? date = 29 : date = 28;
                break;
            case 'March' || 'Mar':
                date = 31;
                break;
            case 'April':
                date = 30;
                break;
            case 'May':
                date = 31;
                break;
            case 'June':
                date = 30;
                break;
            case 'July':
                date = 31;
                break;
            case 'August':
                date = 31;
                break;
            case 'September':
                date = 30;
                break;
            case 'October':
                date = 31;
                break;
            case 'November':
                date = 30;
                break;
            case 'December':
                date = 31;
                break;
        }

        return date + ' ' + month + ' ' + year;
    }

    getMonthFull(monthAbrv: string){
        switch(monthAbrv){
            case 'Jan':
                return 'January';
            case 'Feb':
                return 'February';
            case 'Mar':
                return 'March';
            case 'Apr':
                return 'April';
            case 'May':
                return 'May';
            case 'Jun':
                return 'June';
            case 'Jul':
                return 'July';
            case 'Aug':
                return 'August';
            case 'Sept':
                return 'September';
            case 'Oct':
                return 'October';
            case 'Nov':
                return 'November';
            case 'Dec':
                return 'December';
            default:
                return monthAbrv;
        }
    }

    getMonthInNumber(monthShort: string){
        switch(monthShort){
            case 'JAN':
                return '01';
            case 'FEB':
                return '02';
            case 'MAR':
                return '03';
            case 'APR':
                return '04';
            case 'MAY':
                return '05';
            case 'JUN':
                return '06';
            case 'JUL':
                return '07';
            case 'AUG':
                return '08';
            case 'SEP':
                return '09';
            case 'OCT':
                return '10';
            case 'NOV':
                return '11';
            case 'DEC':
                return '12';
            default:
                return monthShort;
        }
    }

    roundNumberToDecimal(value : number, decimalPlaces : number) : number {
        return Number((Number(value) + (1 / Math.pow(10, Number(decimalPlaces) + 1))).toFixed(decimalPlaces));
    }

    floatRounding(value: number) {
        return Math.round((value) * 1e12) / 1e12;
    }

    checkMediaUrl(url) {
        let http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        return http.status !== 404 && http.status !== 403 && http.status !== 401;
    }

    blobToFile(theBlob: Blob, fileName: string): File {
        let b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return b as File;
    }

    dataURItoFile = function(dataURI, fileName, type: string): Observable<Blob> {
        let arr = dataURI.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file;
        if (!navigator.msSaveBlob) { // detect if not Edge
            file = new File([u8arr], fileName + type, { type: mime });
        } else {
            file = new Blob([u8arr], { type: mime });
            file = this.blobToFile(file, fileName + type);
        }
        return from([file]);
    }

    base64MimeType(encoded) {
        let result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    }

    /**
     * Flatten array of arrays
     * eg:
     *    arrays = [["$6"], ["$12"], ["$25"], ["$25"], ["$18"], ["$22"], ["$10"]]
     *    return ["$6", "$12", "$25", ...]
     * @param arrays multi dimension array
     */
    flatten<T>(arrays: Array<Array<T>>): Array<T> {
        return [].concat.apply([], arrays);
    }

    /**
     * Compare old and new data with custom mapping
     * @param oriData : original data
     * @param inputData : changes data
     * @param compareAttribute Object : a custom mapping for comparison , refer transact.letant
     */
    customCompare(oriData: any, inputData: any, compareAttribute?: Object) {
        if ((oriData != null && oriData != undefined) && (inputData != null && inputData != undefined)) {
            // Compare two items
            let compare = (val1, val2, attribute?) => {
                if(((isObject(val1) || isArray(val1)) || (isObject(val2) || isArray(val2))))
                    return this.customCompare(val1, val2, attribute);
                else{
                    return val1 == val2;
                }
            };
            // compare value if stamp value is not same with original value , then based on the provided options "values"
            let valCompare = (val1, val2, values) => {
                let valArr = (values && values[val1.toUpperCase()] && !isObject(val1) && !isObject(val2) && !isArray(val1) && !isArray(val2)) ? values[val1.toUpperCase()] : undefined;
                return valArr && valArr.indexOf(val1) >= 0 && valArr.indexOf(val2) >= 0;
            };
            // check match "condition"
            let conditionCheck = (val1, conditionObj) => {
                let conditionCheck = true;
                for (let [condKey, condVal] of Object.entries(conditionObj)) {
                    if(val1[condKey] != condVal){
                        conditionCheck = false;
                        break;
                    }
                }
                return conditionCheck;
            };

            // Array of object
            if(isArray(oriData)){
                if(oriData.length != inputData.length){
                    return false;
                }else{

                    for (let i = 0; i < oriData.length; i++) {
                        let findOri = inputData.find(ii =>
                            (oriData[i].contractNo != null && ii.contractNo === oriData[i].contractNo)
                            || (oriData[i].rspId != null && ii.rspCode === oriData[i].rspId)
                            || (oriData[i].switchBuyFundId != null && ii.switchBuySedolnumber === oriData[i].switchBuyFundId)
                            || (oriData[i].switchSellFundId != null && ii.switchSellSedolnumber === oriData[i].switchSellFundId)
                        );
                        if (findOri == null || findOri === 'undefined'){
                            return false;
                        }

                        let ori = oriData[i];

                        for (let j = 0; j < inputData.length; j++) {
                            if(ori.contractNo != inputData[j].contractNo || ori.rspId != inputData[j].rspCode
                                || ori.switchBuyFundId != inputData[j].switchBuySedolnumber ||  ori.switchSellFundId != inputData[j].switchSellSedolnumber)
                                continue;

                            let inp = inputData[j];
                            //Start compare recursive
                            if (!compare(ori, inp, compareAttribute)) {
                                return false;
                            }

                        }
                    }
                }

            }else if(isObject(oriData)){
                if(compareAttribute == null || compareAttribute === 'undefined' || Object.keys(compareAttribute).length == 0) {
                    for (let key in oriData) {
                        if (oriData.hasOwnProperty(key) && (oriData[key] != null && oriData[key] !== 'undefined')) {
                            if (!compare(oriData[key], inputData[key])){
                                return false;
                            }

                        }
                    }
                }else{
                    let ori = oriData;
                    let inp = inputData;
                    for (let [oldKey, newKey] of Object.entries(compareAttribute)) {
                        if (newKey === "" || newKey === 'undefined' || newKey == null)
                            continue;

                        // NEW
                        if(isObject(newKey)){ // get inner attribute
                            let newKeyName = newKey.name;
                            let condition = newKey.condition ? newKey.condition : undefined;
                            let refer = newKey.refer ? newKey.refer : undefined;
                            let values = newKey.value ? newKey.value : undefined;
                            let newValue = inp[`${newKeyName}`];
                            let proceedCompare = true;

                            if(refer){
                                for(let referKey in refer){
                                    if (inp.hasOwnProperty(referKey) && (inp[referKey] === "" || inp[referKey] === 'undefined' || inp[referKey] == null))
                                        continue;
                                    newValue = inp[referKey][`${refer[referKey]}`];
                                }
                            }

                            if(condition){
                                if(condition.not){
                                    proceedCompare = !conditionCheck(ori,condition.not);
                                }
                                // if is proceed-able only continue to condition.is (if any)
                                if(proceedCompare && condition.is){
                                    proceedCompare = conditionCheck(ori,condition.is);
                                }
                            }

                            if(proceedCompare){
                                if (!compare(ori[oldKey], newValue, compareAttribute)) {
                                    if(!valCompare(ori[oldKey], newValue, values)) {
                                        return false;
                                    }
                                }
                            }

                        }

                    }
                }
            }else{
                if (!compare(oriData, inputData, compareAttribute)) {
                    return false;
                }
            }
        }else{
            return false; // make it updatable
        }

        return true;
    }

}