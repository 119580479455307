import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {MobileAppService} from "@api-module/service/common/mobile-app.service";

@Component({
  selector: 'app-hide-from-mobile-app',
  template: `
    <ng-container *ngIf="!hide">
      <ng-content></ng-content>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class HideFromMobileAppComponent implements OnInit {
  hide: boolean;

  constructor(private mobileAppService: MobileAppService) { }

  ngOnInit(): void {
    this.hide = this.mobileAppService.isFromMobileApp();
  }
}
